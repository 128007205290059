import Shelf from './Shelf'
import componentTypes from '../../types/componentTypes'
import Tiles from '../tiles'

export default class SmartTile extends Shelf {
  override mapItems(items: any) {
    const item = this.obj

    if (item && Object.values(componentTypes).indexOf(item.tile.component) > -1) {
      if ((!items || !items.length) && item && item.tile) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        return [new Tiles[item.tile.component](item.tile, this.shelfData)]
      }
    }
  }

  override get listTitle() {
    return this.obj.label
  }
}
