import { Registry } from '@lightningjs/sdk'
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore'
import { isEpgProgram } from '../../../store/PlayerStore/actions/epg'
import { PlayerStates } from '../../../constants'

export const PlayerLiveToVodStateFactory = (base: any) =>
  class LiveToVod extends base {
    $enter() {
      if (this._liveToVodFixed && isEpgProgram(PlayerStoreSingleton.program)) {
        this._liveToVodFixed.visible = true
        const duration = PlayerStoreSingleton.program?.liveToVodButtonDuration || {}
        this._liveToVodTimeout = Registry.setTimeout(() => {
          if (this._getState() === PlayerStates.LPPlayer_LiveToVod) {
            this._setState(PlayerStates.LPPlayer)
          }
        }, duration)
      }
    }

    $exit() {
      if (this._liveToVodFixed) {
        this._liveToVodFixed.visible = false
        this._liveToVodDismissed = true
      }
      if (this._liveToVodTimeout) {
        Registry.clearTimeout(this._liveToVodTimeout)
      }
    }

    _captureEnter() {
      return false
    }

    _getFocused() {
      return this._liveToVodFixed ?? (this._controls || this)
    }
  }
