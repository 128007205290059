import { Observable, Subscription, filter, merge } from 'rxjs'
import { IPlayerEvent } from '../player/model/event/IPlayerEvent'
import PlayerStoreSingleton, { PlayerStoreEvents } from '../store/PlayerStore/PlayerStore'
import { PLAYER_TYPE, PlayerFactorySingleton } from '../player/core/PlayerFactory'
import { SecondaryPlayerSingleton } from '../lib/SecondaryPlayer'
import { PlatformSubscriptionType } from '../lib/tv-platform/base'
import TVPlatform from '../lib/tv-platform'
import ModalManager, { ModalTypes } from '../lib/ModalManager'
import Announcer from '../lib/tts/Announcer'
import { AuthenticationEvents } from '../authentication/Authentication'
import { ACTIVATION_TYPE } from '../widgets/Modals/activation/constants'
import { EpgGuideController, EpgGuideEvents } from '../components/EpgGuideV2/EpgGuideController'

export enum SubscriptionSources {
  PLAYER_STORE = 'playerStore',
  PLAYER = 'player',
  SECONDARY_PLAYER = 'secondaryPlayer',
  TV_PLATFORM = 'tvPlatform',
  MODAL = 'modal',
  TTS_ANNOUNCER = 'tts',
  AUTHENTICATION = 'authentication',
  LIVE_GUIDE_VIEW = 'liveGuideView',
  LIVE_GUIDE_TAB_DATA = 'liveGuideTabData',
  LIVE_GUIDE_PROGRESS = 'liveGuideProgress',
}

export type SubscriptionConfig =
  | {
      type: Exclude<SubscriptionSources, SubscriptionSources.TV_PLATFORM>
      events?:
        | PlayerStoreEvents[]
        | IPlayerEvent[]
        | (PLAYER_TYPE.BACKGROUND | PLAYER_TYPE.PREVIEW)[]
        | ModalTypes[]
        | ACTIVATION_TYPE
      handler?: (data?: any) => void
    }
  | {
      type: SubscriptionSources.TV_PLATFORM
      event: PlatformSubscriptionType
      handler: (data?: any) => void
    }

const isSubscriptionConfig = (input: any): input is SubscriptionConfig =>
  typeof input === 'object' && 'type' in input

type Sources = SubscriptionSources | SubscriptionConfig

export class SubscriptionBuilder {
  private _subjects?: Observable<any>[] = []
  private _subscriptions?: Subscription[] = []

  with(...sources: Sources[]) {
    sources.forEach((sub) => {
      if (isSubscriptionConfig(sub)) {
        // TV Platform events have to be handled separately since
        // it doesn't use RxJS under the hood
        if (sub.type === SubscriptionSources.TV_PLATFORM) {
          this._subscriptions?.push(
            new Observable((observer) => {
              const subscription = TVPlatform.subscribe(sub.event, (...args) =>
                observer.next(...args)
              )
              return function unsubscribe() {
                subscription?.unsubscribe()
              }
            }).subscribe(sub.handler)
          )
        }
        let subject = this._getSubscriptionSubject(sub.type)
        if (!subject) return
        if ('events' in sub) {
          subject = subject.pipe(this._createFilterCallback(sub.events))
        }
        if (sub.handler) {
          this._subscriptions?.push(subject.subscribe(sub.handler))
        } else {
          this._subjects?.push(subject)
        }
      } else {
        const subject = this._getSubscriptionSubject(sub)
        if (subject) this._subjects?.push(subject)
      }
    })
    return this
  }

  subscribe(callback?: any): Subscription | undefined {
    if (!this._subjects?.length && !this._subscriptions?.length) {
      throw new Error(
        'SubscriptionBuilder:: No subscription sources in builder. Please call with() method first.'
      )
    }
    if (callback && !this._subjects?.length) {
      throw new Error(
        'SubscriptionBuilder:: Received a callback but subscription sources already have a defined handler.'
      )
    }
    let finalSubscription: Subscription | undefined
    if (this._subjects?.length) {
      finalSubscription = merge(...this._subjects).subscribe(callback)
    }
    if (this._subscriptions?.length) {
      let rest: Subscription[] = []
      if (finalSubscription) {
        rest = this._subscriptions
      } else {
        finalSubscription = this._subscriptions[0]
        rest = this._subscriptions.slice(1)
      }
      rest?.forEach((sub) => {
        finalSubscription?.add(sub)
      })
    }
    this._subjects = []
    this._subscriptions = []
    return finalSubscription
  }

  private _getSubscriptionSubject(source: SubscriptionSources): Observable<any> | undefined {
    switch (source) {
      case SubscriptionSources.PLAYER_STORE:
        return PlayerStoreSingleton.events
      case SubscriptionSources.PLAYER:
        return PlayerFactorySingleton.player(PLAYER_TYPE.MAIN)?.events
      case SubscriptionSources.SECONDARY_PLAYER:
        return SecondaryPlayerSingleton.events
      case SubscriptionSources.MODAL:
        return ModalManager._subject
      case SubscriptionSources.TTS_ANNOUNCER:
        return Announcer.events
      case SubscriptionSources.AUTHENTICATION:
        return AuthenticationEvents._subject
      case SubscriptionSources.LIVE_GUIDE_PROGRESS:
        return EpgGuideController.getSubject(EpgGuideEvents.PROGRESS)
      case SubscriptionSources.LIVE_GUIDE_VIEW:
        return EpgGuideController.getSubject(EpgGuideEvents.VIEW_CHANGE)
      case SubscriptionSources.LIVE_GUIDE_TAB_DATA:
        return EpgGuideController.getSubject(EpgGuideEvents.TAB_DATA)
      default:
        return undefined
    }
  }

  private _createFilterCallback(events: any) {
    if (events?.[0] instanceof IPlayerEvent) {
      return filter((value: any) => events.find((evt: any) => value instanceof evt))
    }
    return filter((value: any) => events.includes(value.type))
  }
}
