import { Subject } from 'rxjs'
import TVPlatform from '../tv-platform'
import { ErrorType } from '../tv-platform/types'
import { CMPBannerUiResponse } from './types/bannerUi'
import { CMPPreferenceResponse } from './types/preference'
import { getBannerPreferenceHeaders, getOneTrustTokenHeaders, getSaveApiHeaders } from './headers'
import { Storage } from '@lightningjs/sdk'
import { STORAGE_KEYS, USER_OPT_OUT_PREFERENCE } from '../../constants'
import AppConfigFactorySingleton from '../../config/AppConfigFactory'
import { CMPSaveResponse } from './types/save'
import { createHook } from '../Hook'
import { CMPGroupIds } from '../../pages/Settings/CMPPreferenceCenter/types'
import LaunchDarklySingleton from '../launchDarkly/LaunchDarkly'
import LaunchDarklyFeatureFlags from '../launchDarkly/LaunchDarklyFeatureFlags'

enum CMPEventType {
  BANNER = 'banner_ui',
  PREFERENCE = 'preference',
  SAVE = 'save',
}

type CMPEvent = {
  type: CMPEventType
  response: CMPBannerUiResponse | CMPPreferenceResponse
}

export enum CMPInteractionType {
  BANNER_ALLOW_ALL = 'BANNER_ALLOW_ALL',
  BANNER_REJECT_ALL = 'BANNER_REJECT_ALL',
  BANNER_CLOSE = 'BANNER_CLOSE',
  BANNER_CONTINUE_WITHOUT_ACCEPTING = 'BANNER_CONTINUE_WITHOUT_ACCEPTING',
  PREFERENCE_CENTER_ALLOW_ALL = 'PREFERENCE_CENTER_ALLOW_ALL',
  PREFERENCE_CENTER_REJECT_ALL = 'PREFERENCE_CENTER_REJECT_ALL',
  PREFERENCE_CENTER_CONFIRM = 'PREFERENCE_CENTER_CONFIRM',
  PREFERENCE_CENTER_CLOSE = 'PREFERENCE_CENTER_CLOSE',
  VENDOR_LIST_ALLOW_ALL = 'VENDOR_LIST_ALLOW_ALL',
  VENDOR_LIST_REJECT_ALL = 'VENDOR_LIST_REJECT_ALL',
  VENDOR_LIST_CONFIRM = 'VENDOR_LIST_CONFIRM',
  SDK_LIST_ALLOW_ALL = 'SDK_LIST_ALLOW_ALL',
  SDK_LIST_REJECT_ALL = 'SDK_LIST_REJECT_ALL',
  SDK_LIST_CONFIRM = 'SDK_LIST_CONFIRM',
  SYNC_PROFILE = 'SYNC_PROFILE',
}

export type CMPSaveApiBody = {
  interactionType: CMPInteractionType
  consent?: Partial<{
    purposesStatus: {
      groupId: string
      status: boolean
      liStatus?: boolean
    }[]
    iabVendorsStatus: {
      vId: string
      status: boolean
      liStatus: boolean
    }[]
    googleVendorsStatus: {
      vId: string
      status: boolean
      liStatus: boolean
    }[]
    sdkStatus: {
      sdkId: string
      status: boolean
    }[]
  }>
  userAgent?: string
}

export const AllowSaleHook = createHook(false)

class ConsentManagementPlatform {
  _subject = new Subject<CMPEvent>()
  _preferenceData: CMPPreferenceResponse

  get enabled(): boolean {
    return LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.cmpPreferenceCenter)
  }

  get storageKeys() {
    return Storage.get(STORAGE_KEYS.CMP_STORAGE_KEYS) || {}
  }

  set storageKeys(v) {
    if (!v || (typeof v === 'object' && !Object.keys(v).length)) return
    const consents = v['OT_GroupConsents']
    if (consents && CMPGroupIds.ALLOW_SALE in consents) {
      AllowSaleHook().set(consents[CMPGroupIds.ALLOW_SALE])
    }
    Storage.set(STORAGE_KEYS.CMP_STORAGE_KEYS, v)
    Storage.set(STORAGE_KEYS.CMP_GPP_STRING, v.IABGPP_HDR_GppString)
  }

  get consentString() {
    return Storage.get(STORAGE_KEYS.CMP_CONSENT_STRING) || ''
  }

  set consentString(v) {
    if (v) {
      Storage.set(STORAGE_KEYS.CMP_CONSENT_STRING, v)
    }
  }

  get preferenceData() {
    return this._preferenceData
  }

  set preferenceData(value: any) {
    this._preferenceData = value
  }

  async sync() {
    if (!this.enabled) return
    try {
      await Promise.all([this.fetchBannerUi(), this.fetchPreferenceCenter()])
    } catch (e) {
      TVPlatform.reportError({
        type: ErrorType.NETWORK,
        description: 'Failure loading OneTrust API',
        payload: e,
      })
      // fail silently
    }
  }

  subscribe(cb: (e: CMPEvent) => void) {
    return this._subject.subscribe(cb)
  }

  async save(body: CMPSaveApiBody) {
    if (!this.enabled) return
    try {
      if (!AppConfigFactorySingleton.config.one_trust_id) throw new Error('CMP:: undefined app id')
      const response = await fetch(AppConfigFactorySingleton.config.one_trust_save_endpoint, {
        method: 'POST',
        headers: getSaveApiHeaders(),
        body: JSON.stringify(body),
      })
      const json = await response.json()
      this.storageKeys = json.storageKeys
      this.consentString = json.otConsentString
      return json as CMPSaveResponse
    } catch (e) {
      this.storageKeys = {}
      this.consentString = ''
      TVPlatform.reportError({
        type: ErrorType.NETWORK,
        payload: e,
        description: 'Error saving CMP Save and Log consent data',
      })
      throw e
    }
  }

  async fetchBannerUi() {
    if (!this.enabled) return
    try {
      if (!AppConfigFactorySingleton.config.one_trust_id) throw new Error('CMP:: undefined app id')
      const response = await fetch(AppConfigFactorySingleton.config.one_trust_banner_endpoint, {
        headers: getBannerPreferenceHeaders(this.consentString),
      })
      const json = await response.json()
      this.storageKeys = json.storageKeys
      this.consentString = json.otConsentString
      this._subject.next(json)
      // TODO adjust types to actual response (type is modeled from docs data)
      return json as CMPBannerUiResponse
    } catch (e) {
      this.storageKeys = {}
      this.consentString = ''
      TVPlatform.reportError({
        type: ErrorType.NETWORK,
        payload: e,
        description: 'Error requesting CMP BannerUI API',
      })
      throw e
    }
  }

  async fetchPreferenceCenter() {
    try {
      if (!AppConfigFactorySingleton.config.one_trust_id) throw new Error('CMP:: undefined app id')
      const response = await fetch(AppConfigFactorySingleton.config.one_trust_preference_endpoint, {
        headers: getBannerPreferenceHeaders(this.consentString),
      })
      const json = await response.json()
      this.preferenceData = json
      this.storageKeys = json.storageKeys
      this.consentString = json.otConsentString
      this._subject.next(json)
      // TODO adjust types to actual response (type is modeled from docs data)
      return json as CMPPreferenceResponse
    } catch (e) {
      this.storageKeys = {}
      this.consentString = ''
      TVPlatform.reportError({
        type: ErrorType.NETWORK,
        payload: e,
        description: 'Error requesting CMP Preference Center API',
      })
      throw e
    }
  }

  async ctvOptOutConfirm(override: Record<string, boolean> = {}) {
    const {
      purposeTree: { purposes },
      buttons: { savePreferencesButton },
    } = this.preferenceData?.pcUIData || {}
    const purposesMapped = (purposes || []).map((purpose: any) => ({
      groupId: purpose.groupId,
      status: purpose.consentToggleStatus === 1,
    }))
    const ctvOptOutConfirm = {
      interactionType: savePreferencesButton?.interactionType,
      consent: {
        purposesStatus: Object.entries({
          ...(this.storageKeys?.OT_GroupConsents || {}),
          ...purposesMapped,
          ...override,
        })
          .filter(([groupId]) => ['BG1192', 'OOF'].includes(groupId))
          .map(([groupId, status]) => ({
            groupId,
            status: !!status,
          })),
      },
      userAgent: navigator.userAgent,
    }
    this.save(ctvOptOutConfirm)
  }

  async getOneTrustToken(): Promise<void> {
    if (!this.enabled) return
    try {
      const response = await fetch(AppConfigFactorySingleton.config.one_trust_token_endpoint, {
        headers: getOneTrustTokenHeaders(),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const { onetrust_token } = await response.json()

      if (!onetrust_token) {
        throw new Error('No token received in response')
      }

      Storage.set(STORAGE_KEYS.ONE_TRUST_TOKEN, onetrust_token)
      await this.sync()
    } catch (e) {
      TVPlatform.reportError({
        type: ErrorType.NETWORK,
        description: 'Error fetching OneTrust token',
        payload: e,
      })
    }
  }

  getFWParams(): { usPrivacy: string | null; gpp: string | null; gppSid: number | null } {
    if (!this.enabled) return {
      usPrivacy: null,
      gpp: null,
      gppSid: null,
    }
    const { IABUSPrivacy_String, IABGPP_HDR_GppString, IABGPP_GppSID } = this.storageKeys
    return {
      usPrivacy: IABUSPrivacy_String || null,
      gpp: IABGPP_HDR_GppString || null,
      gppSid: IABGPP_GppSID || null,
    }
  }

  deleteOneTrustToken() {
    Storage.remove(STORAGE_KEYS.ONE_TRUST_TOKEN)
  }

  getCmpOptOut(): USER_OPT_OUT_PREFERENCE {
    if (!this.enabled) return USER_OPT_OUT_PREFERENCE.ALLOW_SALE
    return Number(
      Storage.get(STORAGE_KEYS.CMP_STORAGE_KEYS)?.OT_GroupConsents?.BG1192
    )
      ? USER_OPT_OUT_PREFERENCE.ALLOW_SALE
      : USER_OPT_OUT_PREFERENCE.DISALLOW_SALE
  }
}

export const ConsentManagement = new ConsentManagementPlatform()
