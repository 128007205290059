import getShelvesLoadAttributes from '../attributes/getShelvesLoadAttributes'
import mParticle from '@mparticle/web-sdk'

const getEvent = () => ({
  getAttributes: getShelvesLoadAttributes,
  getCategory: () => mParticle.EventType?.UserPreference || 'UserPreference',
  type: 'Shelves Load',
  injectGlobals: true,
})

export default getEvent
