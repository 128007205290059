import { IPlayerEvent } from './IPlayerEvent'

export class AdEvent extends IPlayerEvent {
  _adBreak
  _ad

  constructor(adBreak: any, ad: any) {
    super()
    this._adBreak = adBreak
    this._ad = ad
  }

  get adBreak() {
    return this._adBreak
  }

  get ad() {
    return this._ad
  }
}
