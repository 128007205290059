import { Language, Router } from '@lightningjs/sdk'

import FocusScaledButton from './buttons/FocusScaledButton'
import BaseCollection from './BaseCollection'
import ItemWrapper from './items/ItemWrapper'
import ModalManager, { ModalTypes } from '../lib/ModalManager'

import Styler from '../lib/Styler'
import { sendMetric } from '../lib/analytics/Analytics'
import { EVENTS } from '../lib/analytics/types'
import { setSmooth, FastImg } from '../helpers'
import ContentPosition, { ScrollElementType } from '../util/contentPosition'
import { COLORS, END_OF_ROW, FLEX_DIRECTION, FONT_FACE, FULL_SCHEDULE } from '../constants'
import { OLY_IMPOLICY } from '../constants/images'
import Announcer from '../lib/tts/Announcer'

export default class List extends BaseCollection {
  _noOfItemsToScroll: any
  _viewAllCta: any
  _itemsHolder: any
  _titleHolder: any

  static override _template() {
    return {
      TitleHolder: {
        Title: {
          alpha: 0.87,
          text: { fontFace: FONT_FACE.light, fontSize: 40 },
        },
      },
      Items: {
        y: 85,
        forceZIndexContext: true,
        boundsMargin: [500, 100, 500, 100],
      },
    }
  }

  override _init() {
    this._index = 0
  }

  override _build() {
    this._itemsHolder = this.tag('Items')
    this._titleHolder = this.tag('TitleHolder')
  }

  override create({ items }: any, initialIndex = 0) {
    const style = this.getStyle()
    if (!style) {
      console.warn(`List.js ${items} missing style. Check ./src/lib/style.js`)
      return
    }
    this.noOfItemsToScroll = style.noOfListItemsToScroll

    return items.map((item: any, index: any) => {
      const indexToRender = index + initialIndex
      return {
        type: ItemWrapper,
        itemType: item.itemType,
        item: item.item,
        index: indexToRender,
        x: indexToRender * style.item.w,
      }
    })
  }

  override _handleLeft(): boolean | undefined {
    if (this._index > 0) {
      this.setIndex(this._index - 1)
    } else {
      return false
    }
  }

  override _handleRight(): boolean | undefined {
    if (this._index < this._itemsHolder.children.length - 1) {
      this.setIndex(this._index + 1)
    } else {
      Announcer.announce(END_OF_ROW)
      return false
    }
  }

  override _handleBack(e: any): boolean | void {
    if (this._index === 0) {
      return false
    } else {
      this.setIndex(0)
      e.preventDefault()
      e.stopPropagation()
    }
  }

  setIndex(index: any) {
    this._index = index
    const style = this.getStyle()
    const noOfItems = this.noOfItemsToScroll || 4

    setSmooth(
      this._itemsHolder,
      'x',
      index > noOfItems - 1 ? (index - (noOfItems - 1)) * -style.item.w : 0,
      {
        duration: 0.3,
        delay: 0,
      }
    )

    this.fireAncestors('$scrolledListItemIndex', { index })
    this._refocus()
  }

  _createViewAllCta() {
    const tag = this.getTag(this)
    const style = Styler.getTag(tag)
    const isFullScheduleText = FULL_SCHEDULE.indexOf(this._viewAllCta.title?.toLowerCase()) > -1
    const label = isFullScheduleText
      ? Language.translate('full-schedule-view-all-cta') || this._viewAllCta.title
      : this._viewAllCta.title

    return {
      x: this._items.length * style.item.w + 30,
      y: 98,
      unfocusHeight: 58,
      unfocusWidth: isFullScheduleText ? 260 : 134,
      focusHeight: 68,
      focusWidth: isFullScheduleText ? 280 : 158,
      type: FocusScaledButton,
      radius: 6,
      fontSize: 30,
      focusFontSize: 35,
      fontFace: FONT_FACE.regular,
      focusFontColor: COLORS.black,
      unfocusFontColor: COLORS.lightGray4,
      focusBackGroundColor: COLORS.white,
      unfocusBackgroundColor: COLORS.transparent,
      focusBorderColor: COLORS.transparent,
      unfocusBorderColor: COLORS.lightGray4,
      padding: 22,
      label,
      enterCallback: () => {
        const { title, listTitle, destination, qrModal } = this._viewAllCta
        sendMetric(EVENTS.CLICK, {
          name: title,
          shelf: {
            listTitle,
          },
        })

        this.trackCTAPosition()

        qrModal?.data
          ? ModalManager.open(ModalTypes.QR_MODAL, qrModal)
          : Router.navigate(destination.replace(/\//, ''), {
              preventClickMetric: true,
              loadAnnounce: this.fireAncestors('$getLoadAnnounce'),
            })
      },
    }
  }

  trackCTAPosition() {
    const position = this.fireAncestors('$getCurrentScrollPosition')
    if (position) {
      position.content = this?.items?.length || 0
      ContentPosition.setPositionForCurrentPage(position)
    }
  }

  override _getFocused() {
    return this.activeItemWrapper || this
  }

  override get activeItemWrapper() {
    return this._itemsHolder.children[this._index]
  }

  override get noOfItems() {
    return this.items.length
  }

  override get index() {
    return this._index
  }

  override set itemType(v: any) {
    this._itemType = v
  }

  override get items() {
    return this._items
  }

  override set items(v) {
    this._itemsHolder.childList.clear()
    this.stage.gc()
    this._items = v
    const itemsToRender = this.create({ items: this._items })

    if (this._viewAllCta) itemsToRender.push(this._createViewAllCta())

    this._itemsHolder.patch({
      children: itemsToRender,
    })
  }

  set viewAllCta(viewAllCta: any) {
    this._viewAllCta = viewAllCta
  }

  get viewAllCta() {
    return this._viewAllCta
  }

  _removeItem(itemToRemove: any) {
    this._itemsHolder.childList.remove(itemToRemove)
    if (this._itemsHolder.childList.length === 0) {
      this.fireAncestors('$onEmptyWrapperList', this)
    }

    this._repositionItems()
    this.setIndex(0)
  }

  _repositionItems() {
    const style = this.getStyle()
    this._itemsHolder.childList.forEach((item: any, index: any) => {
      item.patch({
        x: index * style.item.w,
      })
    })
  }

  set title(v: any) {
    if (v) {
      this.tag('Title').text = v
    } else {
      this._itemsHolder.patch({
        y: 0,
      })
    }
  }

  get title() {
    return this.tag('Title')?.text?.text
  }

  set sponsorLogo(v: any) {
    this.patch({
      TitleHolder: {
        flex: { direction: FLEX_DIRECTION.row },
        Title: {
          flexItem: { marginRight: 15 },
        },
        SponsorLogo: {
          mountY: 1,
          texture: FastImg(v).contain(296, 82, OLY_IMPOLICY.RECT_SMALL_384_216),
        },
      },
    })

    this.tag('Title').on('txLoaded', () => {
      this.tag('SponsorLogo').y = this.tag('Title').renderHeight - 17
    })
  }

  set noOfItemsToScroll(noOfItems) {
    this._noOfItemsToScroll = noOfItems
  }

  get noOfItemsToScroll() {
    return this._noOfItemsToScroll || 4
  }

  $getCurrentScrollPosition() {
    const position = this.fireAncestors('$getCurrentScrollPosition')
    return {
      type: ScrollElementType.LIST,
      row: position?.row || 0,
      instanceID: position?.instanceID,
    }
  }
}
