import { StatelessHook, createStatelessHook } from '../../../../lib/Hook'

export enum ErrorModalAction {
  REDIRECT = 'redirect',
  RETRY = 'retry',
  BACK = 'back',
  LIVE_GUIDE = 'live_guide',
  EXIT = 'exit',
}

export type ErrorModalHook = StatelessHook<ErrorModalAction | undefined>
export const useErrorModal = createStatelessHook<ErrorModalAction>()
