import { Utils } from '@lightningjs/sdk'
import { ProgressHookEvent } from '../hooks/useProgress'

export const AdControlsStateFactory = (base: any) =>
  class AdControls extends base {
    _handleEnter() {
      this.fireAncestors('$playOrPause')
    }

    set playOrPause(v: any) {
      const icon = v ? 'images/player/pause.png' : 'images/player/play.png'
      this.tag('AdControlIcon').src = Utils.asset(icon)
      this._setControlIcon(icon)
    }

    _updateProgress(progress: ProgressHookEvent) {}
  }
