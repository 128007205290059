export default class TrickPlayImageTile {
  _src: any
  _time: any
  constructor(src: any, time: any) {
    this._src = src
    this._time = time
  }

  get src() {
    return this._src
  }

  get time() {
    return this._time
  }
}
