import { Lightning, Router } from '@lightningjs/sdk'

import { setSmooth } from '../../helpers'
import { SectionsSpawner } from '../../api/spawners'
import { COLORS } from '../../constants'
import { RouterPage } from '../../../types/global'
import { useRequest } from '../../lib/useRequest'
import { NetworksPageRequestConfig } from './request'
import { BffPage } from '../../graphql/mappers/bffPage'
import { WithRequestError, WithRequestErrorState } from '../hoc/withRequestError'

class Networks extends Lightning.Component<Lightning.Component.TemplateSpecLoose, RouterPage> {
  static override _template() {
    return {
      Networks: {},
    }
  }

  override _init() {
    this.stage.setClearColor(COLORS.dark)
    this.widgets.loader.alpha = 1
  }

  set networksData(v: any) {
    v.x = 75
    v.y = 180
    this.patch({
      Networks: v,
    })
  }

  async load(): Promise<void> {
    try {
      const data = (await useRequest(NetworksPageRequestConfig()).fetch()) as BffPage
      const networksSections = await SectionsSpawner(this.stage, data.sections)
      this.networksData = networksSections?.[0]
      setSmooth(this.widgets.loader, 'visible', 0)
      this._setState('NetworksList')
    } catch (e) {
      this._setState(WithRequestErrorState)
    }
  }

  static override _states() {
    return [
      class NetworksList extends this {
        override _handleUp() {
          Router.focusWidget('Menu')
        }
        override _captureBack(e: any) {
          if (this.tag('Networks').index === 0) {
            this.widgets.menu.setSmooth('alpha', 1)
            Router.focusWidget('Menu')
          } else {
            this.tag('Networks').setIndex(0)
          }
          e.preventDefault()
          e.stopPropagation()
        }
        override _getFocused() {
          return this.tag('Networks') || this
        }
        $scrolledRow(rowId: any) {
          this.widgets.menu.visible = rowId < 2
        }
      },
    ]
  }
}

export default WithRequestError(Networks)
