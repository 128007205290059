import { Lightning, Router } from '@lightningjs/sdk'

import TextButton from '../components/buttons/TextButton'

import { ALIGN_SELF, COLORS, FLEX_DIRECTION, FONT_FACE, SCREEN_SIZE } from '../constants'

export default class ConfirmationDialog extends Lightning.Component {
  announce: string

  static override _template() {
    return {
      zIndex: 3,
      w: SCREEN_SIZE.width,
      h: SCREEN_SIZE.height,
      rect: true,
      color: COLORS.black4,

      Main: {
        x: 960,
        y: 540,
        w: 588,
        mount: 0.5,
        flex: { direction: FLEX_DIRECTION.column },
        rect: true,
        color: COLORS.onyx,
        Message: {
          flexItem: { marginLeft: 40, marginRight: 40, marginTop: 33 },
          text: {
            textColor: COLORS.white,
            fontFace: FONT_FACE.light,
            fontSize: 50,
            lineHeight: 56,
            wordWrapWidth: 508,
          },
        },
        ButtonWrapper: {
          flexItem: {
            marginLeft: 40,
            marginRight: 40,
            marginTop: 40,
            marginBottom: 33,
            alignSelf: ALIGN_SELF.center,
          },
          flex: { direction: FLEX_DIRECTION.row },
          CancelButton: {
            flexItem: {},
            type: TextButton,
            w: 234,
            h: 60,
            radius: 0,
            fontSize: 25,
            fontFace: FONT_FACE.regular,
            focusFontColor: COLORS.lightGray,
            unfocusFontColor: COLORS.lightGray,
            focusBackGroundColor: COLORS.blue,
            unfocusBackgroundColor: COLORS.dark4,
            autoWidth: false,
            padding: 0,
          },
          ConfirmButton: {
            flexItem: { marginLeft: 40 },
            type: TextButton,
            w: 234,
            h: 60,
            radius: 0,
            fontSize: 25,
            fontFace: FONT_FACE.regular,
            focusFontColor: COLORS.lightGray,
            unfocusFontColor: COLORS.lightGray,
            focusBackGroundColor: COLORS.blue,
            unfocusBackgroundColor: COLORS.dark4,
            autoWidth: false,
            padding: 0,
          },
        },
      },
    }
  }

  _setMessage(message: string, cancel: string, confirm: string) {
    this.announce = message
    this.tag('Main').patch({
      Message: {
        text: {
          text: message,
        },
      },
      ButtonWrapper: {
        CancelButton: {
          label: cancel,
        },
        ConfirmButton: {
          label: confirm,
        },
      },
    })
  }

  override _active() {
    this._setState('Cancel')
  }

  override _inactive() {
    this._setMessage('', '', '')
  }

  override _handleBack() {
    this._cancelBtnClicked()
  }

  override _handleUp() {
    return true
  }

  override _handleDown() {
    return true
  }

  _confirmBtnClicked() {
    const page = Router.getActivePage() as any
    page?.confirmBtnClicked?.()
  }

  _cancelBtnClicked() {
    const page = Router.getActivePage() as any
    page?.cancelBtnClicked?.()
  }

  static override _states() {
    return [
      class Confirm extends this {
        override _getFocused() {
          return this.tag('ConfirmButton') || this
        }

        override _handleLeft() {
          this._setState('Cancel')
        }

        override _handleRight() {
          return true
        }

        override _handleEnter() {
          this._confirmBtnClicked()
        }
      },
      class Cancel extends this {
        override _getFocused() {
          return this.tag('CancelButton') || this
        }

        override _handleLeft() {
          return true
        }

        override _handleRight() {
          this._setState('Confirm')
        }

        override _handleEnter() {
          this._cancelBtnClicked()
        }
      },
    ]
  }
}
