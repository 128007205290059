import PlayerStoreSingleton, { PlayerStoreEvents } from '../../../../store/PlayerStore/PlayerStore'
import { Subscription } from 'rxjs'
import GuideTimeSlotHolder from './GuideTimeSlotHolder'
import { EpgSchedule, setEpgData } from '../../../../store/PlayerStore/actions/epg'
import BaseComponent from '../../../base'
import GuideBrandHolder from './GuideBrandHolder'
import GuideProgramHolder from './GuideProgramHolder'
import GuideShimmerLoader from './GuideShimmerLoader'
import { InverseRoundedRectangleOverlay } from './InverseRoundedRectangleOverlay'
import { EpgGuideController } from '../../EpgGuideController'
import { SectionFragment } from '../../../../graphql/generated/types'
import Variables from '../../../../graphql/Variables'
import { useRequest } from '../../../../lib/useRequest'
import { ComponentsForPlaceholdersConfig } from '../../../../helpers/request'
import TVPlatform from '../../../../lib/tv-platform'
import { ErrorType } from '../../../../lib/tv-platform/types'

export default class ChannelsGuide extends BaseComponent {
  _slotsHolder: GuideTimeSlotHolder
  _brandsHolder: GuideBrandHolder
  _programsHolder: GuideProgramHolder
  itemHeight: number
  itemPadding: number
  itemWidth: number
  programImageWidth: number
  _subscription?: Subscription
  loaded = false

  static override _template() {
    return {
      ShimmerLoader: {
        type: GuideShimmerLoader,
        x: 80,
      },
      Guide: {
        x: 238,
        InverseRoundedRectangleTexture: {
          type: InverseRoundedRectangleOverlay,
          itemHeight: this.bindProp('itemHeight'),
        },
        SlotsHolder: {
          itemHeight: this.bindProp('itemHeight'),
          itemPadding: this.bindProp('itemPadding'),
          itemWidth: this.bindProp('itemWidth'),
          programImageWidth: this.bindProp('programImageWidth'),
          type: GuideTimeSlotHolder,
        },
        ProgramsHolder: {
          itemHeight: this.bindProp('itemHeight'),
          itemPadding: this.bindProp('itemPadding'),
          itemWidth: this.bindProp('itemWidth'),
          programImageWidth: this.bindProp('programImageWidth'),
          type: GuideProgramHolder,
        },
        visible: false,
      },
      BrandsHolder: {
        itemHeight: this.bindProp('itemHeight'),
        itemPadding: this.bindProp('itemPadding'),
        itemWidth: this.bindProp('itemWidth'),
        programImageWidth: this.bindProp('programImageWidth'),
        type: GuideBrandHolder,
        visible: false,
      },
    }
  }

  changeBrandIndex(index: number) {
    this._brandsHolder.changeBrandIndex(index)
  }

  override _init() {
    this._slotsHolder = this.tag('SlotsHolder')
    this._brandsHolder = this.tag('BrandsHolder')
    this._programsHolder = this.tag('ProgramsHolder')
    this._subscription = PlayerStoreSingleton.subscribe(this._onStoreEvent)
  }

  override async _enable() {
    const data = EpgGuideController.currentTab?.data
    if (!data || !('queryVariables' in data)) return
    try {
      const response = await useRequest(
        ComponentsForPlaceholdersConfig({
          ...Variables.streamPageVariables('live', ''),
          ...data?.queryVariables,
        })
      ).fetch()
      const guide = response.components.find((c: any) => c?.component === 'Guide')
      if (guide) {
        await PlayerStoreSingleton.dispatch(
          setEpgData({ sections: response.components as SectionFragment[] })
        )
      }
    } catch (e) {
      TVPlatform.reportError({
        type: ErrorType.NETWORK,
        description: 'Error fetching Live Guide data',
        payload: e,
      })
    }
  }

  override _detach() {
    this._subscription?.unsubscribe()
    this._subscription = undefined
    EpgGuideController.resetProgress()
    this.loaded = false
  }

  _onStoreEvent = (event: any) => {
    const { type } = event
    switch (type) {
      case PlayerStoreEvents.EPG_CHANNEL_UPDATED:
      case PlayerStoreEvents.EPG_OK:
        this.tag('Guide').visible = true
        this.tag('BrandsHolder').visible = true
        this.tag('ShimmerLoader').visible = false
        this.load()
        break
    }
  }

  load() {
    const { slots, streams, schedules, start } = PlayerStoreSingleton.state.epg

    this.slots = slots
    this.streams = streams
    this.schedules = schedules
    EpgGuideController.setupProgress(start)
    this.loaded = true
  }

  get slots() {
    return this._slotsHolder.slots
  }

  set slots(v: string[]) {
    this._slotsHolder.slots = v
  }

  get streams() {
    return this._brandsHolder.streams
  }

  set streams(v: any) {
    this._brandsHolder.streams = v
  }

  set schedules(v: EpgSchedule[]) {
    this._programsHolder.schedules = v
  }

  get schedules() {
    return this._programsHolder.schedules
  }
}
