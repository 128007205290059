import { Language, Lightning, Utils } from '@lightningjs/sdk'

import TextBorderButton from '../../../components/buttons/TextBorderButton'

import { sendMetric } from '../../../lib/analytics/Analytics'
import { EVENTS } from '../../../lib/analytics/types'
import {
  ALIGN_SELF,
  COLORS,
  FLEX_DIRECTION,
  FONT_FACE,
  SCREEN_SIZE,
  TEXT_ALIGN,
  ROUTE,
} from '../../../constants'
import { ErrorModalAction, useErrorModal } from './hooks/useErrorModal'
import { StreamRequest } from '../../../pages/Player/StreamLoader/request'
import ErrorFactory from '../../../components/error/ErrorFactory'
import { StreamLoaderPlayerErrorMap } from '../../../pages/Player/StreamLoader/error'
import { PlayerError } from '../../../components/error/PlayerError'
import Router from '@lightningjs/sdk/src/Router'
import { AnalyticsDelegate } from '../../../pages/Player/delegates/analytics/AnalyticsDelegate'
import BasePlayer from '../../../pages/Player/BasePlayer'
import ModalManager, { ModalTypes } from '../../../lib/ModalManager'

enum ErrorButtonState {
  BACK = 'ButtonContainer.BackButton',
  RETRY = 'ButtonContainer.RetryButton',
  LIVEGUIDE = 'ButtonContainer.LiveGuideButton',
}

export const openErrorModal = (
  e: StreamRequest,
  stream: any,
  program: any,
  liveGuideEnabled = false
) => {
  const errorFactory = new ErrorFactory()
  const code = e.error?.detail
  const playerError = ((code && (StreamLoaderPlayerErrorMap as any)[code]) ??
    PlayerError.UNKNOWN) as PlayerError
  const error = errorFactory.getError(playerError, e.error?.data)
  const analytics = (Router.getActivePage() as any)?._analyticsDelegate as
    | AnalyticsDelegate<BasePlayer>
    | undefined
  const payload = {
    brand: stream?.brandDisplayTitle || program?.brand?.title || stream?.analytics?.brand?.title,
    error,
    analytics: analytics?.getErrorAnalytics(error, e.error?.data),
    liveGuideEnabled,
  }
  ModalManager.open(ModalTypes.PLAYER_ERROR, payload)
}

export default class ErrorModal extends Lightning.Component {
  _analytics: any
  _backBtn: any
  _brand: any
  _buttonContainer: any
  _message: any
  _modalContainer: any
  _retryBtn: any
  _liveGuideBtn: any
  _title: any
  _redirectable = false
  _focused: ErrorButtonState = ErrorButtonState.BACK
  _hook = useErrorModal()

  static override _template() {
    return {
      w: SCREEN_SIZE.width,
      h: SCREEN_SIZE.height,
      rect: true,
      color: COLORS.black,
      ModalContainer: {
        w: SCREEN_SIZE.width,
        y: SCREEN_SIZE.height / 2,
        mountY: 0.5,
        flex: { direction: FLEX_DIRECTION.column },
        Image: {
          flexItem: { margin: 10, alignSelf: ALIGN_SELF.center },
          src: Utils.asset('images/misc/group.png'),
        },
        Title: {
          flexItem: { margin: 10, alignSelf: ALIGN_SELF.center, grow: 1 },
          padding: 22,
          text: {
            text: 'Error',
            fontFace: FONT_FACE.light,
            textAlign: TEXT_ALIGN.center,
            fontSize: 60,
            textColor: COLORS.white,
          },
        },
        Message: {
          flexItem: { margin: 10, alignSelf: ALIGN_SELF.center, grow: 1 },
          padding: 22,
          text: {
            text: Language.translate('oops_error'),
            fontFace: FONT_FACE.light,
            textAlign: TEXT_ALIGN.center,
            fontSize: 40,
            lineHeight: 60,
            textColor: COLORS.white,
            wordWrapWidth: 1380,
          },
        },
        ButtonContainer: {
          flexItem: { margin: 10, alignSelf: ALIGN_SELF.center, grow: 1 },
          flex: { direction: FLEX_DIRECTION.row, padding: 25 },

          RetryButton: {
            flexItem: { margin: 10 },
            h: 63,
            w: 250,
            type: TextBorderButton,
            radius: 31,
            fontSize: 32,
            fontFace: FONT_FACE.regular,
            label: Language.translate('Retry'),
            focusFontColor: COLORS.black,
            unfocusFontColor: COLORS.white,
            focusBackGroundColor: COLORS.white,
            unfocusBackgroundColor: COLORS.black,
            focusBorderColor: COLORS.transparent,
            unfocusBorderColor: COLORS.white,
            padding: 22,
            visible: false,
          },
          BackButton: {
            flexItem: { margin: 10 },
            h: 63,
            w: 250,
            type: TextBorderButton,
            radius: 31,
            fontSize: 32,
            fontFace: FONT_FACE.regular,
            label: Language.translate('back'),
            focusFontColor: COLORS.black,
            unfocusFontColor: COLORS.white,
            focusBackGroundColor: COLORS.white,
            unfocusBackgroundColor: COLORS.black,
            focusBorderColor: COLORS.transparent,
            unfocusBorderColor: COLORS.white,
            padding: 22,
          },
          LiveGuideButton: {
            flexItem: { margin: 10 },
            h: 63,
            w: 250,
            type: TextBorderButton,
            radius: 31,
            fontSize: 32,
            fontFace: FONT_FACE.regular,
            label: Language.translate('view_live_guide'),
            focusFontColor: COLORS.black,
            unfocusFontColor: COLORS.white,
            focusBackGroundColor: COLORS.white,
            unfocusBackgroundColor: COLORS.black,
            focusBorderColor: COLORS.transparent,
            unfocusBorderColor: COLORS.white,
            padding: 22,
            visible: false,
          },
        },
      },
    }
  }

  override _init() {
    this._modalContainer = this.tag('ModalContainer')
    this._buttonContainer = this.tag('ModalContainer.ButtonContainer')
    this._retryBtn = this.tag('ModalContainer.ButtonContainer.RetryButton')
    this._liveGuideBtn = this.tag('ModalContainer.ButtonContainer.LiveGuideButton')
    this._backBtn = this.tag('ModalContainer.ButtonContainer.BackButton')
    this._title = this.tag('ModalContainer.Title')
    this._message = this.tag('ModalContainer.Message')
    this._setState('ButtonContainer')
  }

  _replaceBrand(message: any) {
    const brandKey = '%{brand}'
    return this._brand && message.indexOf(brandKey) > -1
      ? message.replace(brandKey, this._brand)
      : message
  }

  set brand(v: any) {
    this._brand = v
  }

  set error(v: any) {
    this._title.text.text = v.title
    this._message.text.text = this._replaceBrand(v.message)
    this._retryBtn.visible = v.retryable
    if (v.retryable) this._focused = ErrorButtonState.RETRY
    if (v.buttonLabel) this._backBtn.label = v.buttonLabel
    this._redirectable = v.redirectable
  }

  set analytics(a: any) {
    this._analytics = a
  }

  set liveGuideEnabled(v: boolean) {
    this._liveGuideBtn.visible = v
  }

  set modalData(data: Record<string, any>) {
    Object.keys(data).forEach((key) => {
      // @ts-expect-error Assigning values programatically
      this[key] = data[key]
    })
  }

  sendAnalytics() {
    sendMetric(EVENTS.ERROR, this._analytics)
  }

  override _handleKey() {
    return true
  }

  static override _states() {
    return [
      class ButtonContainer extends this {
        override _backBtn: any
        override _retryBtn: any
        override _liveGuideBtn: any

        override _getFocused() {
          this._setState(this._focused)
          let _currentFocus: any
          if (this._focused === ErrorButtonState.BACK) _currentFocus = this._backBtn
          if (this._focused === ErrorButtonState.RETRY) _currentFocus = this._retryBtn
          if (this._focused === ErrorButtonState.LIVEGUIDE) _currentFocus = this._liveGuideBtn
          return _currentFocus || this
        }

        override _handleDown() {
          return true
        }

        override _handleUp() {
          return true
        }

        override _handleLeft() {
          if (this._focused === ErrorButtonState.LIVEGUIDE) {
            this._focused = ErrorButtonState.BACK
          } else if (this._focused === ErrorButtonState.BACK && this._retryBtn.visible) {
            this._focused = ErrorButtonState.RETRY
          }
          this._setState(this._focused)
        }

        override _handleRight() {
          if (this._focused === ErrorButtonState.BACK && this._liveGuideBtn.visible) {
            this._focused = ErrorButtonState.LIVEGUIDE
          } else if (this._focused === ErrorButtonState.RETRY) {
            this._focused = ErrorButtonState.BACK
          }
          this._setState(this._focused)
        }

        static override _states() {
          return [
            class RetryButton extends ButtonContainer {
              override _getFocused() {
                return this._retryBtn || this
              }

              override _handleEnter(e: any) {
                this._hook.set(ErrorModalAction.RETRY)
                e.preventDefault()
                e.stopPropagation()
              }
            },
            class BackButton extends ButtonContainer {
              override _getFocused() {
                return this._backBtn || this
              }

              override _handleEnter() {
                const route = Router.getActiveHash()
                if (route != ROUTE.live) {
                  Router.back()
                } else {
                  if (this._liveGuideBtn.visible) {
                    this._hook.set(ErrorModalAction.EXIT)
                    return
                  }
                  this._hook.set(
                    this._redirectable ? ErrorModalAction.REDIRECT : ErrorModalAction.BACK
                  )
                }
              }

              override _handleBack() {
                this._handleEnter()
              }
            },
            class LiveGuideButton extends ButtonContainer {
              override _liveGuideBtn: any

              override _getFocused() {
                return this._liveGuideBtn || this
              }

              override _handleEnter() {
                this._hook.set(ErrorModalAction.LIVE_GUIDE)
              }
            },
          ]
        }
      },
    ]
  }
}
