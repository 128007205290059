import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants'
import getAdAttributes from './getAdAttributes'

const getAdEndAttributes = (params: any) => {
  const { adSecondsWatched, isAbandoned, video } = params
  // Core Video reports duration as `expectedDuration`
  const duration = params.ad?.duration || params.ad?.expectedDuration || 0
  return {
    ...getAdAttributes(params),
    'Duration Watched': `${isAbandoned ? adSecondsWatched : duration}`,
    '% Complete': `${isAbandoned ? Math.round((adSecondsWatched / duration) * 100) : 100}`,
    'End Type': isAbandoned ? 'Abandon' : 'Complete',
    Sport: video?.sport || MPARTICLE_DEFAULT_ATTR_VALUE,
    League: video?.league || MPARTICLE_DEFAULT_ATTR_VALUE,
  }
}

export default getAdEndAttributes
