import { GqlFetchResult } from '../client/types'
import { OnAirNowShelfPageQuery } from '../generated/types'

const onAirNowShelfMapper = (page: GqlFetchResult<OnAirNowShelfPageQuery>) => {
  const data = page.data?.onAirNowShelf
  if (!data) return null
  return { data }
}

export default onAirNowShelfMapper
