import { GraphQlClient } from '../../graphql/client'
import {
  VideosSectionPageQuery,
  VideosSectionPageQueryVariables,
} from '../../graphql/generated/types'
import { QueryName } from './bffQuery'

export default async (variables: VideosSectionPageQueryVariables) => {
  return await GraphQlClient.query<VideosSectionPageQuery>({
    query: QueryName.videosSection,
    variables,
  })
}
