import get from 'lodash/get'
import SearchOutcomeType from '../model/SearchOutcomeType'

import { ALGOLIA_ENTITY_TYPES, setDefaultValue } from '../../../../helpers'
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants'

const getResultData = (result: any, entityType: any) => {
  switch (entityType) {
    case ALGOLIA_ENTITY_TYPES.SERIES:
    case ALGOLIA_ENTITY_TYPES.SPORTS_SERIES:
      return {
        showName: get(result, 'series.title', ''),
        brand: get(result, 'series.brands[0].title', 'None'),
      }
    case ALGOLIA_ENTITY_TYPES.MOVIES:
      return {
        showName: get(result, 'movie.sortTitle', ''),
        brand: get(result, 'movie.brands[0].title', 'None'),
      }
    case ALGOLIA_ENTITY_TYPES.EPISODES:
    case ALGOLIA_ENTITY_TYPES.SEGMENTS:
    case ALGOLIA_ENTITY_TYPES.SPORTS_VIDEOS:
      return {
        showName: get(result, 'series.title', ''),
        brand: get(result, 'video.brand.title', 'None'),
      }
    default:
      return {}
  }
}

const getSelectionType = (entityType: any, item: any) => {
  // Video selected
  if (entityType !== ALGOLIA_ENTITY_TYPES.SERIES && entityType !== ALGOLIA_ENTITY_TYPES.MOVIES) {
    const programmingType = item?.data?.programmingType
    return (
      (Array.isArray(programmingType) ? programmingType[0] : programmingType) ||
      MPARTICLE_DEFAULT_ATTR_VALUE
    )
  }
  return 'Show'
}

type SearchResultsAttibutesParams = {
  item?: any
  index?: number
  seasonNumber?: any
  searchTerm?: string
  type?: any
  results?: any
}
const getSearchResultsAttributes = (params: SearchResultsAttibutesParams = {}) => {
  const { item } = params
  const entityType = item?.algoliaProperties?.entityType
  const resultData = getResultData(item, entityType)
  const lockup = item?.algoliaProperties?.isEpisode
  const selIndex = params.index || 0

  return setDefaultValue(
    {
      Brand: resultData.brand,
      Show: resultData.showName,
      Season: params.seasonNumber,
      'Video ID': item?.video?.mpxGuid || item?.data?.pid,
      'Content Position': selIndex + 1,
      'Custom Shelf Position': 1,
      'Custom Shelf Title': 'Search Results',
      Lockup: lockup ? 'True' : 'False',
      'Lockup Content Position': lockup ? selIndex + 1 : MPARTICLE_DEFAULT_ATTR_VALUE,
      'Selection Type': getSelectionType(entityType, item),
      'Search Term': params.searchTerm,
      'Search Outcome': params.type || SearchOutcomeType.browse,
      'Result Count': params.results,
      Sport: item?.data?.sport?.title || MPARTICLE_DEFAULT_ATTR_VALUE,
      League: item?.data?.league?.title || MPARTICLE_DEFAULT_ATTR_VALUE,
    },
    MPARTICLE_DEFAULT_ATTR_VALUE
  )
}

export default getSearchResultsAttributes
