import qs from 'qs'
import AppConfigFactorySingleton from '../../../config/AppConfigFactory'
import LaunchDarklySingleton from '../../launchDarkly/LaunchDarkly'

const transformFeatureFlags = (featureFlags: any) => {
  if (Array.isArray(featureFlags)) {
    return featureFlags
  }

  return Object.keys(featureFlags).map((key) => {
    const value = featureFlags[key]
    // Check if the value is an object and has a 'bucket' property, otherwise use the value directly.
    const outputValue =
      typeof value === 'object' && value !== null && 'bucket' in value ? value.bucket : value
    return `${key}::${JSON.stringify(outputValue)}`
  })
}

export const setUserAttributes = (
  userId = '',
  serverFeatureFlags: Record<string, string> | string[]
) => {
  const { identifyUrl: url, apiKey, platform } = AppConfigFactorySingleton.config.amplitude
  // Removing the BFF feature flags for now
  const featureFlagsArray = transformFeatureFlags(LaunchDarklySingleton.getAllFlag())

  return fetch(url, {
    method: 'POST',
    body: qs.stringify({
      api_key: apiKey,
      identification: JSON.stringify({
        user_id: userId,
        user_properties: {
          [`${platform} Feature Flags`]: featureFlagsArray,
        },
      }),
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}
