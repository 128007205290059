import { Lightning, Registry } from '@lightningjs/sdk'
import moment from 'moment-timezone'

import TimerItem from './timerItem'
import Separator from './separator'
import { getTimeDiffAsMilliseconds } from '../../helpers'
import { FLEX_DIRECTION } from '../../constants'

export default class CountdownTimer extends Lightning.Component {
  _intervalId: any
  _timeoutId: any
  _starTime: any
  static override _template() {
    return {
      flex: { direction: FLEX_DIRECTION.row },
      Days: {
        type: TimerItem,
        label: this.bindProp('countdownDayLabel'),
      },
      FirstSeparator: {
        flexItem: { marginLeft: 10, marginRight: 10 },
        type: Separator,
      },
      Hours: {
        type: TimerItem,
        label: this.bindProp('countdownHourLabel'),
      },
      SecondSeparator: {
        flexItem: { marginLeft: 10, marginRight: 10 },
        type: Separator,
      },
      Minutes: {
        type: TimerItem,
        label: this.bindProp('countdownMinLabel'),
      },
    }
  }

  set startTime(starTime: any) {
    this._starTime = starTime
    this._initCountdownTimer()
  }

  _initCountdownTimer() {
    const currentTime = moment()
    const remainingTime = getTimeDiffAsMilliseconds(currentTime, this._starTime)
    let duration = moment.duration(remainingTime, 'milliseconds')
    const secondsRemaining = duration.seconds()

    this._updateTimerValues(duration)

    if (secondsRemaining) {
      this._timeoutId = Registry.setTimeout(() => {
        duration = moment.duration(
          (duration as unknown as number) - secondsRemaining * 1000,
          'milliseconds'
        )
        this._updateTimerValues(duration)
        this._validateEndTimer(duration)
        this._createInterval(duration)
      }, secondsRemaining * 1000)
    } else {
      this._createInterval(duration)
    }
  }

  _validateEndTimer(duration: any) {
    if (Math.floor(duration.asSeconds()) <= 0) {
      this.signal('countdownEnd')
    } else {
      this.signal('checkStreamAvailability')
    }
  }

  _createInterval(duration: any) {
    let newDuration = duration
    const interval = 60000 // 1 MINUTE
    this._intervalId = Registry.setInterval(() => {
      newDuration = moment.duration(newDuration - interval, 'milliseconds')
      this._updateTimerValues(newDuration)
      this._validateEndTimer(newDuration)
    }, interval)
  }

  override _inactive() {
    if (this._intervalId) {
      Registry.clearInterval(this._intervalId)
    }
    if (this._timeoutId) {
      Registry.clearTimeout(this._timeoutId)
    }
  }

  _updateTimerValues(duration: any) {
    const days = duration.days() < 0 ? 0 : duration.days()
    const hours = duration.hours() < 0 ? 0 : duration.hours()
    const minutes = duration.minutes() < 0 ? 0 : duration.minutes()
    const seconds = duration.seconds() < 0 ? 0 : duration.seconds()
    const totalMinutes = seconds > 0 ? minutes + 1 : minutes

    this.patch({
      Days: {
        value: days > 9 ? days : `0${days}`,
      },
      Hours: {
        value: hours > 9 ? hours : `0${hours}`,
      },
      Minutes: {
        value: totalMinutes > 9 ? totalMinutes : `0${totalMinutes}`,
      },
    })
  }
}
