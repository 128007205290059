import { Language, Storage } from '@lightningjs/sdk'
import { STORAGE_KEYS } from '../constants'

export enum AudioLanguageTag {
  en = 'en',
  eng = 'eng',
  spa = 'spa',
  enAd = 'en-ad',
  es = 'es',
}

export const getAudioTrackLabel = (audioLangTag: AudioLanguageTag): string | undefined => {
  switch (audioLangTag) {
    case AudioLanguageTag.en:
    case AudioLanguageTag.eng:
      return Language.translate('english')
    case AudioLanguageTag.enAd:
      return Language.translate('english-ad')
    case AudioLanguageTag.es:
    case AudioLanguageTag.spa:
      return Language.translate('spanish')
  }
}

export const mapAudioLanguageTag = (languageTag: AudioLanguageTag): AudioLanguageTag => {
  switch (languageTag) {
    case AudioLanguageTag.en:
    case AudioLanguageTag.eng:
      return AudioLanguageTag.en
    case AudioLanguageTag.es:
    case AudioLanguageTag.spa:
      return AudioLanguageTag.es
    case AudioLanguageTag.enAd:
      return AudioLanguageTag.enAd
  }
}

export const getPreferredLanguages = (): AudioLanguageTag[] => {
  let preferredLanguage: AudioLanguageTag | AudioLanguageTag[] = Storage.get(
    STORAGE_KEYS.PREFERRED_AUDIO_LANG
  )
  switch (preferredLanguage) {
    case AudioLanguageTag.en:
    case AudioLanguageTag.eng:
      preferredLanguage = [AudioLanguageTag.en, AudioLanguageTag.eng]
      break
    case AudioLanguageTag.es:
    case AudioLanguageTag.spa:
      preferredLanguage = [AudioLanguageTag.es, AudioLanguageTag.spa]
      break
  }
  return Array.from(
    new Set([
      ...(Array.isArray(preferredLanguage) ? preferredLanguage : [preferredLanguage]),
      AudioLanguageTag.en,
      AudioLanguageTag.eng,
      AudioLanguageTag.es,
      AudioLanguageTag.spa,
      AudioLanguageTag.enAd,
    ])
  )
}
