import { Lightning } from '@lightningjs/sdk'
import { COLORS, FLEX_DIRECTION, FONT_FACE } from '../../../constants'
import { CMPPageSelectedTabIndex } from './hook'

export type VerticalTab = {
  key: string
  label: string
}

class Tab extends Lightning.Component {
  index = 0
  _selectedTab = CMPPageSelectedTabIndex(this._onSelectedTabChange.bind(this))

  static override _template() {
    return {
      Bg: {
        rect: true,
        color: COLORS.lightGray13,
        w: 674,
      },
      Label: {
        y: 38,
        x: 72,
        color: COLORS.dark11,
        text: {
          fontFace: FONT_FACE.regular,
          fontSize: 33,
          wordWrapWidth: 570,
          text: this.bindProp('label'),
        },
      },
    }
  }

  override _init() {
    this.tag('Label').on('txLoaded', () => {
      const h = this.tag('Label').renderHeight + 76
      this.patch({ h, Bg: { h } })
    })
  }

  _onSelectedTabChange(index: number) {
    const el = this.tag('Bg')
    if (index !== this.index) {
      el.color = COLORS.lightGray13
    }
  }

  override _focus() {
    this.patch({ Bg: { color: COLORS.dark11 }, Label: { color: COLORS.white } })
  }

  override _unfocus() {
    const current = this._selectedTab.value === this.index
    this.patch({
      Bg: { color: current ? COLORS.lightGray14 : COLORS.lightGray13 },
      Label: { color: COLORS.dark11 },
    })
  }
}

export class VerticalTabs extends Lightning.Component {
  _tabIndex = 0
  _selectedTab = CMPPageSelectedTabIndex()
  _tabs: VerticalTab[] = []

  static override _template() {
    return {
      rect: true,
      color: COLORS.lightGray13,
      w: 674,
      h: 776,
      Content: {
        flex: {
          direction: FLEX_DIRECTION.column,
        },
      },
      Line: {
        x: 674,
        y: 0,
        rect: true,
        w: 2,
        h: 776,
        color: COLORS.dark11,
      },
    }
  }

  set tabs(tabs: VerticalTab[]) {
    if (!tabs.length) return
    this._tabs = tabs
    this.tag('Content').patch(
      tabs.reduce(
        (acc, item, index) => ({
          ...acc,
          [`Tab${index}`]: {
            type: Tab,
            index,
            label: item.label,
          },
        }),
        {}
      )
    )
    this._setState('Ready') // Focus issue fix
  }

  override _handleUp() {
    if (this._tabIndex > 0) this._tabIndex--
  }

  override _handleDown() {
    if (this._tabIndex < this._tabs.length - 1) {
      this._tabIndex++
    } else {
      return false
    }
  }

  override _handleEnter() {
    this._selectedTab.set(this._tabIndex)
  }

  static override _states() {
    return [
      class Ready extends this {
        override _getFocused() {
          return this.tag(`Tab${this._tabIndex}`) || this
        }
      },
    ]
  }
}
