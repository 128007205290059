export default {
  bravo: 'c02',
  nbcnews: 'c05',
  nbc: 'c05',
  nbcentertainment: 'c05',
  nbcolympics: 'c05',
  olympics: 'c05',
  syfy: 'c06',
  usa: 'c07',
  cnbc: 'c14',
  nbcsports: 'c18',
  nbcscalifornia: 'c18',
  nbcswashington: 'c18',
  nbcsphiladelphia: 'c18',
  nbcschicago: 'c18',
  nbcsbayarea: 'c18',
  nbcsboston: 'c18',
  oxygen: 'c19',
  'e!': 'c22',
  telemundo: 'c26',
  msnbc: 'c29',
  universo: 'c56',
}
