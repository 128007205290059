import { Lightning } from '@lightningjs/sdk'

import { sendMetric } from '../../lib/analytics/Analytics'
import { EVENTS } from '../../lib/analytics/types'
import { COLORS, FONT_FACE } from '../../constants'
import { LabelListRenderingStrategy } from './strategies'
import LabelsListHorizontal from './strategies/horizontal'
import LabelsListVertical from './strategies/vertical'
import FilterPosition from '../../util/filterPosition/filterPosition'

export enum LabelsListRenderingType {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}
interface Item {
  active?: boolean
}

export default class LabelsList extends Lightning.Component {
  _strategy?: LabelListRenderingStrategy
  _items: Item[]
  radius = 6
  _sectionSize = 0

  static override _template(): { h?: number; Holder: { y: number; Focus: any; Items: any } } {
    return {
      Holder: {
        y: 0,
        Focus: {
          rect: true,
          color: COLORS.white1,
          alpha: 0,
          h: 72,
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            radius: this.bindProp('radius'),
          },
        },
        Items: {},
      },
    }
  }

  set mode(mode: LabelsListRenderingType) {
    this._strategy =
      mode === LabelsListRenderingType.HORIZONTAL
        ? new LabelsListHorizontal(this, this.sectionSize)
        : new LabelsListVertical(this, this.sectionSize)
  }

  set items(v) {
    this._items = v
    this._strategy?.resetItems()
    this.stage.gc()
    this._strategy?.createItems(v)

    if (
      this._items.findIndex((item) => item?.active === true) ===
      FilterPosition.getPositionForCurrentPage()
    ) {
      this._strategy?.moveToIndex(FilterPosition.getPositionForCurrentPage())
    }
  }

  get items() {
    return this._items
  }

  set sectionSize(sectionSize: number) {
    this._sectionSize = sectionSize
  }

  get sectionSize() {
    return this._sectionSize
  }

  set focusFontFace(font: FONT_FACE) {
    if (this._strategy) this._strategy.fontFace = font
  }

  set activeIndex(index: number) {
    if (this._strategy) this._strategy.activeIndex = index
  }

  get activeIndex() {
    return this._strategy?._activeIndex || 0
  }

  get focusIndex() {
    return this._strategy?._index || 0
  }

  override _init() {
    this._strategy?.init()
  }

  override _focus() {
    this._strategy?.enter()
  }

  override _unfocus() {
    this._strategy?.exit()
  }

  override _handleUp() {
    return this._strategy?.handleUp()
  }

  override _handleDown() {
    return this._strategy?.handleDown()
  }

  override _handleLeft() {
    return this._strategy?.handleLeft()
  }

  override _handleRight() {
    return this._strategy?.handleRight()
  }

  override _handleEnter() {
    this.selectFocused()
    this.trackContentClick()
    return false
  }

  addItems(items: any[]) {
    const offset = this.childList.length
    this._strategy?.createItems(items, offset)
  }

  selectItem(index: number) {
    this._strategy?.moveToIndex(index)
  }

  selectFocused() {
    this._strategy?.moveToFocused()
  }

  trackContentClick() {
    const name = this._strategy?.activeItem?.label
    sendMetric(EVENTS.CLICK, {
      name,
      shelf: { listTitle: 'None' },
    })
  }

  override _getFocused() {
    return this._strategy?.focusItem || this
  }
}
