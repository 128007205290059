import mParticle from '@mparticle/web-sdk'
import getEndCardAttributes from '../attributes/getEndCardAttributes'

const trackEndCard = (type: any) => ({
  getAttributes: getEndCardAttributes,
  getCategory: () => mParticle.EventType?.UserContent,
  type: 'End Card',
  injectGlobals: true,
})

export default trackEndCard
