import Tile from './Tile'

export default class BrandItemLabel extends Tile {
  get instanceID() {
    return this.data.instanceID
  }

  get colorBrandLogo() {
    return this.data.colorBrandLogo || ''
  }

  get whiteBrandLogo() {
    return this.data.whiteBrandLogo || null
  }

  get displayTitle() {
    return this.data.displayTitle
  }

  get darkPrimaryColor() {
    return this.data.darkPrimaryColor ? this.data.darkPrimaryColor('#', '') : 'b78623'
  }

  get lightPrimaryColor() {
    return this.data.lightPrimaryColor ? this.data.lightPrimaryColor('#', '') : 'b78623'
  }

  get machineName() {
    return this.data.machineName
  }
}
