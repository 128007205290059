import getLinearAttributes from '../attributes/getLinearAttributes'
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants'

const getLinearStartAttributes = (params = {}) => ({
  ...getLinearAttributes(params),
  // @ts-expect-error TS(2339): Property 'duration' does not exist on type '{}'.
  'Video Duration': params.duration,
  // @ts-expect-error TS(2339): Property 'shelf' does not exist on type '{}'.
  'Custom Shelf Title': params.shelf?.listTitle || MPARTICLE_DEFAULT_ATTR_VALUE,
  // @ts-expect-error TS(2339): Property 'shelf' does not exist on type '{}'.
  'Custom Shelf Position': params.shelf?.shelfIndex || MPARTICLE_DEFAULT_ATTR_VALUE,
  // @ts-expect-error TS(2339): Property 'shelf' does not exist on type '{}'.
  'Content Position': params.shelf?.tileIndex || MPARTICLE_DEFAULT_ATTR_VALUE,
  // @ts-expect-error TS(2339): Property 'shelf' does not exist on type '{}'.
  'Shelf Machine Name': params.shelf?.machineName || MPARTICLE_DEFAULT_ATTR_VALUE,
  // @ts-expect-error TS(2339): Property 'shelf' does not exist on type '{}'.
  'Custom Shelf Type': params.shelf?.shelfType || 'Regular',
})

export default getLinearStartAttributes
