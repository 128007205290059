import { GqlFetchResult } from '../client/types'
import { PaginatedComponentQuery } from '../generated/types'

const paginatedComponent = (page: GqlFetchResult<PaginatedComponentQuery>) => {
  const data = page.data?.paginatedComponent
  if (!data) return null
  return { data }
}

export default paginatedComponent
