import { createProgressHookEvent } from '../../../components/player/PlayerControls/hooks/useProgress'
import { PlayerStates } from '../../../constants'

export const PlayerSkipButtonStateFactory = (base: any) =>
  class SkipButton extends base {
    _captureUp() {
      this._setState(PlayerStates.LPPlayer_Controls)
      this._controls.setMoreInfoState()
      this._controls._handleUp()
    }

    _captureDown() {
      this._setState(PlayerStates.LPPlayer_Controls)
      this._controls.setPlayPauseState()
      this._controls._handleDown()
    }

    _captureEnter() {
      this._progress.set(createProgressHookEvent(this._skipButton.cuePoint))
      this.$seek()
      return false
    }

    _getFocused() {
      return this._skipButton || this
    }
  }
