import { Language } from '@lightningjs/sdk'
import moment from 'moment-timezone'
import { LANGUAGES } from '../../constants'

const DAYS_TO_VALIDATE_LABEL_BADGE = 3

export const isCurrentDay = (date: any) => {
  const startDateObject = moment(date)
  return startDateObject.isSame(new Date(), 'day')
}

export const getDateFromUnixTimestamp = (unixTimestamp: any) => new Date(unixTimestamp * 1000)

export const getUpcomingBadge = (startTime: any) => {
  const startDateObject = moment(startTime)
  const formatBadge = isCurrentDay(startTime)
    ? `h:mm A [${Language.translate('today').toUpperCase()}]`
    : 'h:mm A ddd MMM DD'

  return startDateObject.format(formatBadge)?.toUpperCase()
}

const formatDate = (datestring: any, isFullYear = false) => {
  const format = isFullYear ? 'MM/DD/YYYY' : 'MM/DD/YY'
  return moment(datestring).format(format)
}

export const getAirDate = (airDate: any) => {
  const timestamp = airDate * 1000
  const now = moment()
  const diff = now.diff(timestamp, 'hours')

  if (diff > 24) {
    // Show the air date in 'MM/DD/YY' format if the difference greater than 24 hours
    return formatDate(timestamp)
  } else if (diff < 1) {
    // Show "Just added" if difference is less than 1 hour
    return Language.translate('just_added')
  } else {
    // In all other cases we use "3h ago" format
    return `${diff}h ${Language.translate('time_ago')}`
  }
}

export const getFERDate = (startDate: any, endDate: any) => {
  const now = moment()
  const startDateObject = moment(startDate)
  const endDateObject = moment(endDate)
  const diff = Math.abs(endDateObject.diff(now, 'hours'))

  return diff < 24
    ? `${diff}h ${Language.translate('time_ago')}`
    : startDateObject.format('MM/DD/YY')
}

export const isLive = (startDate: any, endDate: any) => {
  const nowTime = moment()
  const startTime = moment(startDate)
  const endTime = moment(endDate)

  return nowTime.isBetween(startTime, endTime)
}

export const isUpcoming = (startDate: any) => {
  const nowTime = moment()
  return nowTime.isBefore(startDate)
}

export const showLabelBadge = (endDate: string) => {
  const now = moment()
  const end = moment(endDate)
  return Math.abs(end.diff(now, 'days')) < DAYS_TO_VALIDATE_LABEL_BADGE
}

export enum PROGRAMMING_TYPE {
  singleLiveEvents = 'Single Live Event',
  fullEventReplays = 'Full Event Replay',
}

export const MODAL = 'Modal'

export const getLabelBadge = (labelBadgeDefault: string, labelBadgeEs?: string): string =>
  Language.get() === LANGUAGES.SPANISH && labelBadgeEs ? labelBadgeEs : labelBadgeDefault

export const adjustTimestampByOffset = (timestamp: number, offsetMinutes: number) =>
  moment(timestamp * 1000)
    .add(offsetMinutes, 'm')
    .unix()
