import getModalLoadAttributes from '../attributes/getModalLoadAttributes'
import mParticle from '@mparticle/web-sdk'

const getEvent = () => ({
  getAttributes: getModalLoadAttributes,
  getCategory: () => mParticle.EventType?.Navigation || 'Navigation',
  type: 'Modal Load',
  injectGlobals: true,
})

export default getEvent
