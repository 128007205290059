import { componentsForPlaceholders } from '../../../../api/requests'
import { COMPONENT_TYPES } from '../../../../constants'
import Variables from '../../../../graphql/Variables'
import rsnFilter from '../../../../graphql/filter/rsnFilter'
import { ComponentsForPlaceholdersQueryVariables } from '../../../../graphql/generated/types'
import componentsForPlaceholdersMapper from '../../../../graphql/mappers/componentsForPlaceholders'
import { createRequestConfig } from '../../../../lib/useRequest'

export const FiltersGuideRequestConfig = (variables: ComponentsForPlaceholdersQueryVariables) =>
  createRequestConfig({
    query: componentsForPlaceholders,
    variables: Variables.componentsForPlaceholdersVariables(variables),
    mapper: (data: any) => rsnFilter(componentsForPlaceholdersMapper(data)),
    reject: [(data) => data?.components[0]?.component === COMPONENT_TYPES.MESSAGE],
  })
