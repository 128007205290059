import Router from '@lightningjs/sdk/src/Router'

/**
 * @Class for storing and retrieving Filter Position on a page
 */
class FilterPositionSingleton {
  // filter position simple hash by page
  _filterPositions: { [page: string]: number } = {}

  _getCurrentHash() {
    // Router.getActiveHash() depends on the window hashchange event
    // it's better to poll window directly
    const hash = window.location.hash.replace('#', '')
    if (!hash) {
      // LightningJS stores page hash in current page
      // wrapped in a Symbol object, to retrieve this value
      // we must use the getOwnPropertySymbols util
      const page = Router.getActivePage()
      const symbol = Object.getOwnPropertySymbols(page)[0]
      return (page as any)[symbol as any] || ''
    }
    return hash
  }

  /**
   * Assign positions to the current page.
   * @param {{ type: ScrollElementType, row: number, content: number}} position
   */
  setPositionForCurrentPage(rowIndex: number) {
    this._filterPositions[this._getCurrentHash()] = rowIndex
  }

  /**
   * Get the positions for the current page.
   */
  getPositionForCurrentPage(): number {
    return this._filterPositions?.[this._getCurrentHash()] || 0
  }

  clearPositionForCurrentPage() {
    const hash = this._getCurrentHash()
    if (this._filterPositions?.[hash]) delete this._filterPositions[hash]
  }
}

const FilterPosition = new FilterPositionSingleton()
export default FilterPosition
