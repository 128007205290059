import { doAlgoliaRequest } from './common'
import { dateMapper } from './mappers/dates'
import { assetsMapper } from './mappers/assets'
import { getFormattedTimeZone } from '../../helpers'

export const ALGOLIA_FILTERS = {
  SLE: 'algoliaProperties.entityType:singleLiveEvents',
  FER: 'algoliaProperties.entityType:fullEventReplays',
}

export const ALGOLIA_HITS_PER_PAGE = 20

export const ALGOLIA_ORDER = {
  ASC: 'asc',
  DESC: 'desc',
}

const BASE_END_POINT = 'single-events'
const BASE_DATES = 'dates'
const PLATFORM = 'vizioTV'

const prepareEventDateFilterString = ({ localStartTimestamp, localEndTimestamp }: any) => {
  let filterString = ''
  if (localStartTimestamp) {
    filterString = `event.startDate >= ${localStartTimestamp}`
  }
  if (localEndTimestamp) {
    if (filterString.length > 0) filterString += ' AND '
    filterString += `event.startDate <= ${localEndTimestamp}`
  }
  return filterString
}

export default function ({ hitsPerPage, entityFilter, order }: any) {
  function getDates(fallbackTimezone: any, page = 0) {
    return doAlgoliaRequest(
      `${BASE_END_POINT}-${BASE_DATES}${getFormattedTimeZone(fallbackTimezone)}-${order}`,
      {
        hitsPerPage: hitsPerPage,
        page,
        filters: entityFilter,
      }
    ).then(dateMapper)
  }

  type Options = {
    timeParams?: any
    page?: number
    hitsPerPage?: any
  }
  function getAssets(options: Options = {}) {
    const { timeParams, page = 0 } = options
    const filterByTimeString = timeParams && prepareEventDateFilterString(timeParams)
    return doAlgoliaRequest(`${BASE_END_POINT}-${PLATFORM}-${order}`, {
      hitsPerPage: hitsPerPage,
      page,
      filters: filterByTimeString ? `${filterByTimeString} AND ${entityFilter}` : entityFilter,
    }).then(assetsMapper)
  }

  function getAssetsByIndex(options: Options = {}) {
    const { timeParams, page = 0, hitsPerPage } = options
    const filterByTimeString = timeParams && prepareEventDateFilterString(timeParams)
    return doAlgoliaRequest(`${BASE_END_POINT}-${PLATFORM}-${order}`, {
      hitsPerPage,
      page,
      filters: filterByTimeString ? `${filterByTimeString} AND ${entityFilter}` : entityFilter,
    }).then(assetsMapper)
  }

  return {
    getDates,
    getAssets,
    getAssetsByIndex,
  }
}
