import { isEmpty, cloneDeep } from 'lodash'

import {
  GeoLocation,
  isSingleStream,
  PlayerStoreActions,
  SingleProgram,
  SingleStream,
  updateProgram,
  updateStream,
} from './actions'
import { State } from './types/store'
import BaseStore from '../BaseStore'
import { LemonadeResponse } from '../../lib/lemonade/Lemonade'
import { EpgProgram, EpgStream, filterByChannelId } from './actions/epg'
import { filterEPGStreamsByRSN, filterScheduleByRSN } from '../../graphql/filter/rsnFilter'

const NOT_ALLOWED_CHANNELS = new Set(['nbcswashington'])
const defaultState: State = {
  epg: {
    index: 0,
    programIndex: 0,
    start: '',
    slots: [],
    streams: [],
    schedules: [],
    events: [],
    upcoming: null,
  },
  stream: {},
  program: {},
  lemonade: undefined,
  geo: {},
  horizontalScrollIndex: 0,
  preCheckData: {
    lemonade: undefined,
    stream: undefined,
    program: undefined,
  },
}

export enum PlayerStoreEvents {
  STREAM_OK = 'streamOk',
  EPG_OK = 'epgOk',
  PRE_CHECK_DATA_OK = 'preCheckDataOk',
  EPG_ERROR = 'epgError',
  EPG_CHANNEL_UPDATED = 'epgChannelUpdated',
  LEMONADE_OK = 'lemonadeOk',
  ERROR = 'error',
}

export type Stream = SingleStream | EpgStream | null | undefined
export type Program = SingleProgram | EpgProgram | null | undefined
export type Geo = GeoLocation | null | undefined

class PlayerStore extends BaseStore {
  override state = cloneDeep(defaultState)

  get stream(): Stream {
    const singleStream = this.state.stream
    const epgStream = this.state.epg.streams[this.state.epg.index]
    return isSingleStream(singleStream) ? singleStream : epgStream
  }

  epgData(streamAccessName: string) {
    const index = this.state.epg.streams.findIndex(
      (stream) => stream?.streamAccessName === streamAccessName
    )
    return {
      stream: this.state.epg.streams?.[index] || {},
      program: this.state.epg.schedules?.[index]?.programs?.[this.state.epg.programIndex] || {},
    }
  }

  get streamIndex() {
    return this.state.epg.index || 0
  }

  get program(): Program {
    return this.state.program
  }

  get programIndex() {
    return this.state.epg.programIndex || 0
  }

  get lemonade(): LemonadeResponse | undefined {
    return this.state.lemonade
  }

  get preCheckData() {
    return this.state.preCheckData
  }

  get geo() {
    return this.state.geo
  }

  get epg() {
    return this.state.epg
  }

  async dispatch(action: any): Promise<any> {
    try {
      const result = await this._getActionResult(action)
      const { type, payload } = result

      switch (type) {
        case PlayerStoreActions.CLEAR_STATE: {
          const preCheckData = this.state.preCheckData
          this.state = cloneDeep(defaultState)
          this.state.preCheckData = preCheckData
          break
        }
        case PlayerStoreActions.SET_PRE_CHECK_LEMONADE:
          this.state.preCheckData.lemonade = payload
          break
        case PlayerStoreActions.CLEAR_PRE_CHECK_LEMONADE:
          delete this.state.preCheckData.lemonade
          break
        case PlayerStoreActions.SET_GEO:
          this.state.geo = payload
          return await this.dispatch(
            updateStream({
              authKill: !(payload?.assetInfo?.authRequired ?? true),
            })
          )
        case PlayerStoreActions.SET_PRE_CHECK_DATA:
          this.state.preCheckData.stream = payload.stream
          this.state.preCheckData.program = payload.program
          break
        case PlayerStoreActions.CLEAR_PRE_CHECK_DATA:
          delete this.state.preCheckData.stream
          delete this.state.preCheckData.program
          break
        case PlayerStoreActions.SET_EPG: {
          if (payload.streams.length && payload.start !== this.state.epg.start) {
            const streams = filterEPGStreamsByRSN(
              payload.streams.filter((stream: any) => !NOT_ALLOWED_CHANNELS.has(stream.channelId))
            )
            const schedules = filterScheduleByRSN(
              payload.schedules.filter(
                // @ts-expect-error TS(2339): Property 'channelId' does not exist on type 'never... Remove this comment to see the full error message
                ({ programs = [] }) => !NOT_ALLOWED_CHANNELS.has(programs[0]?.channelId)
              )
            )

            if (streams.length && schedules.length) {
              this.state.epg = {
                ...payload,
                streams,
                schedules,
                index: this.state.epg?.index || payload.index,
              }
              if (!this.state.epg.index) {
                if (streams.length && schedules.length) {
                  this.state.epg.index = <number>(
                    this.state.epg?.streams.findIndex(
                      filterByChannelId(
                        this.state.stream.channelId,
                        this.state.stream.streamAccessName,
                        this.state.stream.machineName || ''
                      )
                    )
                  )

                  this.events.next({
                    type: PlayerStoreEvents.EPG_CHANNEL_UPDATED,
                    payload: {
                      stream: this.stream,
                      program: this.program,
                      streamIndex: this.state.epg.index,
                    },
                  })
                }
              }
              this.events.next({
                type: PlayerStoreEvents.EPG_OK,
                payload,
              })
            } else {
              this.events.next({
                type: PlayerStoreEvents.EPG_ERROR,
                payload: [],
              })
            }
          } else if (!payload.streams.length) {
            this.events.next({
              type: PlayerStoreEvents.EPG_ERROR,
              payload: [],
            })
          }
          break
        }
        case PlayerStoreActions.SET_SINGLE_STREAM: {
          this.state.stream = payload.stream
          if (payload.stream.secondaryTitle !== undefined) {
            this.state.stream.streamSecondaryTitle = payload.stream.secondaryTitle
          }
          this.state.program = payload.program

          if (this.state.epg?.streams?.length) {
            const streamIndexFromResponse = <number>(
              this.state.epg?.streams.findIndex(
                filterByChannelId(
                  this.state.stream.channelId,
                  this.state.stream.streamAccessName,
                  this.state.stream.machineName || ''
                )
              )
            )
            if (streamIndexFromResponse !== this.state.epg.index) {
              this.state.epg.index = streamIndexFromResponse
              this.events.next({
                type: PlayerStoreEvents.EPG_CHANNEL_UPDATED,
                payload: {
                  stream: this.stream,
                  program: this.program,
                  streamIndex: this.state.epg.index,
                },
              })
            }
          }
          this.events.next({
            type: PlayerStoreEvents.STREAM_OK,
            payload,
          })
          break
        }
        case PlayerStoreActions.SET_LEMONADE:
          this.state.lemonade = payload
          if (payload.adCompatibilityEncodingProfile) {
            await this.dispatch(
              updateProgram({
                adCompatibilityEncodingProfile: payload.adCompatibilityEncodingProfile,
              })
            )
          }
          this.events.next({
            type: PlayerStoreEvents.LEMONADE_OK,
            payload,
          })
          break
        case PlayerStoreActions.CLEAR_LEMONADE:
          delete this.state.lemonade
          break
        case PlayerStoreActions.ERROR:
          return Promise.reject(result)
        case PlayerStoreActions.UPDATE_STREAM:
          if (!isEmpty(this.state.stream)) {
            this.state.stream = { ...this.state.stream, ...payload }
          } else if (!isEmpty(this.state.epg.streams[this.state.epg.index])) {
            this.state.epg.streams[this.state.epg.index] = {
              ...this.state.epg.streams[this.state.epg.index],
              ...payload,
            }
          }
          break
        case PlayerStoreActions.UPDATE_PROGRAM:
          if (!isEmpty(this.state.program)) {
            this.state.program = { ...this.state.program, ...payload }
          } else if (
            !isEmpty(
              this.state.epg.schedules[this.state.epg.index]?.programs?.[
                this.state.epg.programIndex
              ]
            )
          ) {
            const programs = this.state.epg?.schedules?.[this.state.epg.index]?.programs
            if (programs) {
              programs[this.state.epg.programIndex] = {
                ...programs?.[this.state.epg.programIndex],
                ...payload,
              }
            }
          }
          break
        case PlayerStoreActions.UPDATE_EPG_CHANNEL_INDEX:
          this.state.epg.index = payload
          this.state.program = {
            ...this.state.epg.schedules[payload]?.programs?.[this.state.epg.programIndex],
          }
          this.state.stream = { ...this.state.epg.streams[payload], ...this.state.program }
          this.events.next({
            type: PlayerStoreEvents.EPG_CHANNEL_UPDATED,
            payload: {
              stream: this.stream,
              program: this.program,
              streamIndex: this.state.epg.index,
            },
          })
          break
        case PlayerStoreActions.NEXT_EPG_SCHEDULE_INDEX: {
          const schedules = this.state.epg.schedules[this.state.epg.index]
          if ((schedules?.programs?.length || 0) > this.state.epg.programIndex + 1) {
            this.state.epg.programIndex += 1
            this.state.program = {
              ...schedules?.programs?.[this.state.epg.programIndex],
            }
            this.state.stream = {
              ...this.state.epg.streams?.[this.state.epg.index],
              ...this.state.program,
            }
            return this.program
          }
          break
        }
        default:
          break
      }
      return result
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

const PlayerStoreSingleton = new PlayerStore()
export default PlayerStoreSingleton
