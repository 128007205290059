import { GraphQlClient } from '../client'
import { BonanzaPage } from '../generated/types'
import Variables from '../Variables'

const getBrandLandingPageCache = (machineName: any) => {
  try {
    const variables = Variables.networkLandingPageVariables(machineName)
    return {
      data: GraphQlClient.readQuery({ query: BonanzaPage, variables }),
      variables,
    }
  } catch (err) {
    return {}
  }
}

export default getBrandLandingPageCache
