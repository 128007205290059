import { Language } from '@lightningjs/sdk'
import { formatSecondsAsHhMmSs } from '../../../../helpers'
import { createProgressHookEvent } from '../hooks/useProgress'
import VODStrategy from './VODStrategy'

export default class FERStrategy extends VODStrategy {
  override parent: any
  override init(stream: any) {
    this._totalTime = stream.duration
    this.parent.tag('PlayerControlsBrandLogoWrapper')?.patch({
      y: 90,
    })
    this.parent._titleContainer.patch({
      y: this.parent.constructor.TITLE_CONTAINER_Y,
    })
    this.parent._title.patch({
      flexItem: {
        marginLeft: 8,
        marginTop: 38,
      },
      text: {
        text: stream.league || stream.sports || Language.translate('program_unavailable'),
        fontSize: 28,
      },
    })
    this.parent._secondaryTitle.patch({
      text: { text: stream.title, fontSize: 58 },
    })

    this.parent._tertiaryTitle.patch({ text: { text: stream.tertiaryTitle } })
    this.parent._description.patch({
      y: 185,
      text: { text: stream.secondaryTitle },
    })
    this.parent._timer.bTime = formatSecondsAsHhMmSs(stream.duration)
    this._progress.set(
      createProgressHookEvent({
        currentTime: 0,
        duration: this._totalTime,
      })
    )
  }
}
