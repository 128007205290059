import { getTimerToEndTile } from '../../../../helpers/metadata'
import { labelBadgeDecorator } from '../../decorators'

export const getUpcomingStateClass = (context: any) =>
  class UpcomingState extends context {
    $enter() {
      if (this._item.upcomingCtaText) this.buttonText = this._item.upcomingCtaText
      if (this._item.upcomingBadge) {
        this._addDecorators([labelBadgeDecorator(this._item?.upcomingBadge)])
      }

      this._timerChangeToLive = getTimerToEndTile(this._item.startTime, () => {
        this._setState('LiveState')
      })
    }
  }
