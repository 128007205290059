import { Storage } from '@lightningjs/sdk'
import { IStorage } from './IStorage'

export class LSStorage extends IStorage {
  override set(key: any, value: any) {
    Storage.set(key, value)
  }
  override get(key: any) {
    return Storage.get(key)
  }
  override remove(key: any) {
    Storage.remove(key)
  }
  override clear() {
    Storage.clear()
  }
}
