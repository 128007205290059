import { GraphQlClient } from '../../graphql/client'
import bffScrollFromSeasonMapper from '../../graphql/mappers/bffScrollFromSeason'
import {
  ScrollFromSeasonQuery,
  ScrollFromSeasonQueryVariables,
} from '../../graphql/generated/types'
import { QueryName } from './bffQuery'

export default async (variables: ScrollFromSeasonQueryVariables) => {
  return await GraphQlClient.query<ScrollFromSeasonQuery>({
    query: QueryName.scrollFromSeason,
    variables,
  }).then(bffScrollFromSeasonMapper)
}
