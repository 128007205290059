import ParkApi, { VERSION } from './ParkApi'

const get = async (mpid: any) => {
  return await ParkApi.get(
    `users/${mpid}/favorites`,
    {
      include: 'favorite.keyArtWithLogo,favorite.earliestFirstEpisode',
    },
    { version: VERSION }
  )
}

const post = async ({ mpid, attributes, dataType, v4ID }: any) =>
  ParkApi.post(`users/${mpid}/favorites`, 'POST', {
    data: {
      type: 'favorites',
      attributes,
      relationships: {
        favorite: {
          data: {
            type: dataType,
            id: v4ID,
          },
        },
        user: {
          data: {
            type: 'users',
            id: mpid,
          },
        },
      },
    },
  })

const deleteFavorite = async (mpid: any, favoriteId: any) =>
  ParkApi.post(`users/${mpid}/favorites/${favoriteId}`, 'DELETE', { meta: { version: VERSION } })

export default { get, post, delete: deleteFavorite }
