import { PlayerStoreActions, throwStoreError } from '.'
import { CHANNEL_IDS, GeoCheckOptions, geoCheck } from '../../../api/Live'

export const fetchGeo = async (
  channelId: string,
  channelName: string,
  options: GeoCheckOptions
) => {
  return geoCheck(channelId as CHANNEL_IDS, channelName, options).then((payload) => {
    if (!payload?.restricted) return { type: PlayerStoreActions.SET_GEO, payload }

    return throwStoreError(options)
  })
}
