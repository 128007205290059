import { get } from 'lodash'

import { Container } from '../models'

import { collectionComponents, createItems } from '../../components/componentMaps'

const createModel = (item: any) => new Container(item)

const createSlideshow = (stage: any, model: any) => {
  return stage.c({
    type: collectionComponents.get(model.component),
    items: createItems(get(model, 'data.items', [])),
  })
}

export default (stage: any, featured: any) => {
  return createSlideshow(stage, createModel(featured))
}
