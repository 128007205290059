import { GraphQlClient } from '../../graphql/client'
import {
  ComponentsForPlaceholdersQuery,
  ComponentsForPlaceholdersQueryVariables,
} from '../../graphql/generated/types'
import { QueryName } from './bffQuery'

export default async (variables: ComponentsForPlaceholdersQueryVariables) => {
  return await GraphQlClient.query<ComponentsForPlaceholdersQuery>({
    query: QueryName.componentsForPlaceholders,
    variables, // todo: maybe merge with default variables for this page
  })
}
