import { Storage } from '@lightningjs/sdk'
import isEmpty from 'lodash/isEmpty'

import { EVENTS } from '../../../lib/analytics/types'
import { ENTITY_TYPES, LiveGuideV2States, STORAGE_KEYS } from '../../../constants'
import { setSmooth } from '../../../helpers'
import PlayerStoreSingleton, { PlayerStoreEvents } from '../../../store/PlayerStore/PlayerStore'
import { sendMetric } from '../../../lib/analytics/Analytics'
import { EpgProgram } from '../../../store/PlayerStore/actions/epg'
import { useLiveGuideXOffset } from '../hooks/useLiveGuideXOffset'
import { Hook } from '../../../lib/Hook'
import { SubscriptionBuilder, SubscriptionSources } from '../../../util/SubscriptionBuilder'
import isUndefined from 'lodash/isUndefined'

const VERTICAL_SCROLL_DIRECTION: {
  UP: number
  DOWN: number
} = Object.freeze({
  UP: 0,
  DOWN: 1,
})

export const ChannelsStateFactory = (base: any) =>
  class Channels extends base {
    _xOffset: Hook

    get streamIndex(): number {
      return this._streamIndex || 0
    }

    set streamIndex(value) {
      this._streamIndex = value
    }

    get programIndex() {
      return this._programIndex || 0
    }

    set programIndex(value) {
      this._programIndex = value
    }

    get slotIndex() {
      return this._slotIndex || 0
    }

    set slotIndex(value) {
      this._slotIndex = value
    }

    get programStartSlot() {
      return this._programStartSlot || 0
    }

    set programStartSlot(value) {
      this._programStartSlot = value
    }

    // Current component reference
    get currentItem() {
      return (
        this._channelsGuide?.schedules?.children[this.streamIndex]?.children?.[this.programIndex] ??
        {}
      )
    }

    //Data
    get streams() {
      return PlayerStoreSingleton.state.epg.streams
    }

    get schedules() {
      return PlayerStoreSingleton.state.epg.schedules
    }

    get currentStream() {
      return this.streams[this.streamIndex]
    }

    get currentStreamPrograms(): EpgProgram[] {
      return this.schedules?.[this.streamIndex]?.programs as EpgProgram[]
    }

    get currentStreamProgram(): EpgProgram {
      return this.currentStreamPrograms?.[this.programIndex] as EpgProgram
    }

    get slotsCount(): number {
      if (this.currentStreamPrograms?.length) {
        const lastItem = this.currentStreamPrograms?.[this.currentStreamPrograms?.length - 1]
        if (lastItem?.endSlot) {
          return lastItem.endSlot
        }
      }
      return 0
    }

    get currentProgramStartSlot(): number {
      return this.currentStreamProgram.startSlot || 0
    }

    get currentProgramEndSlot(): number {
      return this.currentStreamProgram.endSlot || 0
    }

    get currentProgramSlotSpan(): number {
      return this.currentStreamProgram?.slotSpan || 0
    }

    _getFocused() {
      return !isEmpty(this.currentItem) ? this.currentItem : this
    }

    $enter() {
      const { streamIndex } = PlayerStoreSingleton
      if (this._filtersGuide) this._filtersGuide.visible = false
      if (this._channelsGuide) this._channelsGuide.visible = true
      this._channelsGuide.changeBrandIndex(this.streamIndex)
      this._xOffset = useLiveGuideXOffset()

      if (isUndefined(this._streamIndex) && this._channelsGuide?.loaded)
        this.updateStreamIndex(streamIndex)
      this._subscription = new SubscriptionBuilder()
        .with({
          type: SubscriptionSources.PLAYER_STORE,
          events: [PlayerStoreEvents.EPG_CHANNEL_UPDATED, PlayerStoreEvents.EPG_OK],
        })
        .subscribe(this._onEpgChannelUpdated.bind(this))
    }

    $exit() {
      this._subscription?.unsubscribe()
      this._subscription = undefined
    }

    _onEpgChannelUpdated({
      type,
      payload,
    }: {
      type: PlayerStoreEvents
      payload: { streamIndex?: number; index?: number }
    }) {
      if (type === PlayerStoreEvents.EPG_CHANNEL_UPDATED)
        if (isUndefined(this._streamIndex)) this.updateStreamIndex(payload?.streamIndex)
      if (type === PlayerStoreEvents.EPG_OK)
        if (isUndefined(this._streamIndex)) this.updateStreamIndex(payload?.index)
    }

    _handleBack(e: any) {
      if (this.slotIndex > 0) {
        this.updateSlotIndex(0)
        this.programIndex = 0
        this.programStartSlot = this.currentStreamProgram?.startSlot
        e.preventDefault()
        e.stopPropagation()
      } else if (this.streamIndex > 0) {
        this.updateStreamIndex(0, 0)
        this.scrollStreams(0)
        e.preventDefault()
        e.stopPropagation()
      } else {
        // Take user back to the previous screen
        this.fireAncestors('$trueBack')
      }
    }

    trackContentClick(program: any, stream: any) {
      const referringShelf = {
        tileIndex: this.programIndex,
        shelfIndex: this.streamIndex,
        listTitle: 'On Now',
      }
      const params = {
        entity: {
          program,
          stream,
          entityType: ENTITY_TYPES.STREAM,
          analytics: { ...program, customShelfTitle: 'None' },
        },
        shelf: {
          position: this.programIndex + 1,
          customPosition: this.streamIndex + 1,
        },
      }
      Storage.set(STORAGE_KEYS.REFERRING_SHELF, referringShelf)
      sendMetric(EVENTS.CONTENT_CLICK, params)
    }
    _handleEnter() {
      if (this.programIndex === 0) {
        const stream = this.currentStream
        const program = this.currentStreamProgram

        if (!stream || !program) return
        this.trackContentClick(program, stream)
        this.fireAncestors('$streamSelected', stream, program)
      }
    }

    _handleRight() {
      if (this.slotIndex < this.slotsCount - 1) {
        let nextSlot = this.slotIndex + 1
        if (nextSlot < 3 && this.currentProgramEndSlot > nextSlot) {
          if (this.currentProgramEndSlot > 3) {
            nextSlot = 3
          } else {
            nextSlot += 1
          }
        } else if (this.slotIndex > (this.slotsCount - 4)) {
          if (this.currentProgramSlotSpan > 1) {
            nextSlot = this.slotIndex + this.currentProgramSlotSpan
          }
        }
        this.updateSlotIndex(nextSlot)
        if (
          this.currentProgramEndSlot &&
          this.slotIndex + 1 > this.currentProgramEndSlot &&
          this.currentStreamPrograms?.length &&
          this.currentStreamPrograms.length - 1 > this.programIndex
        ) {
          this.programIndex += 1
        }
        this.programStartSlot = this.currentProgramStartSlot
      } else {
        return false
      }
    }

    _handleLeft() {
      if (this.slotIndex > 0) {
        const nextSlotIndex = this.currentProgramSlotSpan > 1 && this.slotIndex > this.slotsCount - 4
          ? this.slotIndex - this.currentProgramSlotSpan
          : this.slotIndex - 1
        this.updateSlotIndex(nextSlotIndex)
        this.programStartSlot = this.currentProgramStartSlot
        if (
          this.currentProgramStartSlot &&
          this.slotIndex < this.currentProgramStartSlot
        ) {
          this.programIndex -= 1
          this.programStartSlot = this.currentProgramStartSlot
        }
      } else {
        if (this.currentStreamPrograms?.length === 1) {
          this.updateSlotIndex(this.programIndex)
          this.programStartSlot = this.currentProgramStartSlot
        } else if (this.programIndex > 0) {
          this.programIndex -= 1
        } else {
          return false
        }
      }
    }

    _handleUp() {
      if (this.streamIndex > 0) {
        this.updateStreamIndex(this.streamIndex - 1, VERTICAL_SCROLL_DIRECTION.UP)
      } else {
        this._setState(LiveGuideV2States.Tabs)
        this._channelsGuide.changeBrandIndex(-1)
      }
    }

    _handleDown() {
      if (this.streamIndex < this.streams.length - 1) {
        this.updateStreamIndex(this.streamIndex + 1, VERTICAL_SCROLL_DIRECTION.DOWN)
      } else {
        return false
      }
    }

    updateSlotIndex(index: any) {
      this.slotIndex = index || 0
      if (this.slotsCount - this.slotIndex > 2) {
        const xValue =
          this.slotIndex >= 3 ? -(this.slotIndex * (this.itemWidth + this.itemPadding)) : 0

        this._xOffset.set([xValue, index])

        setSmooth(this._channelsGuide.schedules, 'x', xValue, {
          duration: 0.3,
          delay: 0,
        })

        this._channelsGuide._slotsHolder.index = index < 3 ? 0 : index
      }
    }

    updateStreamIndex(index: any, direction = VERTICAL_SCROLL_DIRECTION.UP) {
      this.streamIndex = index || 0

      const getNextProgramIndex = (): number => {
        const comparsionValue = this.slotIndex > 3 ? this.slotIndex : this.programStartSlot
        return this.streamIndex < this.schedules.length
          ? this.currentStreamPrograms?.findIndex(
              ({ startSlot, endSlot, component }: any) =>
                component === 'EventTile' ||
                (startSlot <= comparsionValue && endSlot > comparsionValue)
            )
          : 0
      }

      this.programIndex = getNextProgramIndex()

      const topOffSetIndex = direction === VERTICAL_SCROLL_DIRECTION.UP ? 0 : 1

      const bottomOffSetIndex = this.tag('AssetInfo').alpha ? 0 : 1

      if (index > topOffSetIndex && index < this.streams.length - bottomOffSetIndex) {
        const yValue = (index - 1) * -(this.itemHeight + this.itemPadding)
        this.scrollStreams(yValue)
      }

      this._channelsGuide.changeBrandIndex(index)
    }

    scrollStreams(yValue: any) {
      setSmooth(this._channelsGuide.schedules, 'y', yValue, {
        duration: 0.3,
        delay: 0,
      })
      setSmooth(this._channelsGuide.streams, 'y', yValue, {
        duration: 0.3,
        delay: 0,
      })
    }
  }
