import { IPlayerEvent } from './IPlayerEvent'

export class BufferEvent extends IPlayerEvent {
  _metadata

  constructor(metadata: any) {
    super()
    this._metadata = metadata
  }

  get metadata() {
    return this._metadata ?? JSON.parse('{}')
  }
}
