import { Lightning } from '@lightningjs/sdk'
import Styler from '../lib/Styler'

export default class BaseCollection extends Lightning.Component {
  _index: any
  _itemType: any
  _items: any
  announce: any
  create(items: Record<string, any>, index?: number): any {}
  override _getFocused() {
    return this.activeItemWrapper || this
  }

  get activeItemWrapper() {
    return this._getItem(this._index)
  }

  get items() {
    return this._items
  }

  get index() {
    return this._index
  }

  get noOfItems() {
    return this.items.length
  }

  set itemType(v: any) {
    this._itemType = v
  }

  set ariaLabel(ariaLabel: any) {
    this.announce = ariaLabel
  }

  _getItem(index = 0) {
    return this.tag('Items').children[index]
  }

  getChildItem(index: any) {
    return this._getItem(index)?.child
  }

  setChildPropsForIndex(index: any, props: any) {
    return this._getItem(index)?.setChildProps?.(props)
  }

  getTag(child: any) {
    const tags = child.getTags()
    if (!tags.length) {
      throw new Error(`Specify a 'tag' for: ${child}`)
    }
    return tags[0]
  }

  getStyle() {
    const tag = this.getTag(this)

    return Styler.getTag(tag)
  }

  addItems(items: any) {
    const initialIndex = this._items.length
    this._items = [...this._items, ...items]
    this.tag('Items').childList.a(this.create({ items }, initialIndex))
  }
}
