import { Language, Router, Storage, Utils } from '@lightningjs/sdk'

import { LongScroll } from '../../components'
import LongScrollPage from '../LongScrollPage'
import Hero from '../../components/items/Hero/Hero'
import SubMenu from '../../components/submenu'
import ShowAbout from '../../components/showabout'
import { ItemMenuStateFactory } from './states/ItemMenu'
import { FeaturedStateFactory } from './states/Featured'
import { ScrollerStateFactory } from './states/Scroller'
import { AboutStateFactory } from './states/About'

import { SectionsSpawner } from '../../api/spawners'
import { removeHtmlParams, saveLatestCachedItem, setSmooth, splitString } from '../../helpers'
import { sendMetric } from '../../lib/analytics/Analytics'
import { EVENTS } from '../../lib/analytics/types'
import { evict as evictFromCache } from '../../graphql/cache'
import { FavoriteInteractionFactorySingleton } from '../../graphql/modifiers/FavoriteInteractionFactory'
import LaunchDarklyFeatureFlags from '../../lib/launchDarkly/LaunchDarklyFeatureFlags'
import ContentPosition from '../../util/contentPosition'
import {
  ALIGN_SELF,
  COLORS,
  FLEX_DIRECTION,
  FONT_FACE,
  PARK_ENTITY_TYPES,
  ROUTE,
  SCREEN_SIZE,
  VERTICAL_ALIGN,
} from '../../constants'
import LaunchDarklySingleton from '../../lib/launchDarkly/LaunchDarkly'
import UserInteractionsStoreSingleton from '../../store/UserInteractions'
import { addFavorite, removeFavorite } from '../../store/UserInteractions/actions'
import { useRequest } from '../../lib/useRequest'
import { ShowHomeFeaturedRequestConfig, ShowHomeRequestConfig } from './request'
import { WithRequestError, WithRequestErrorState } from '../hoc/withRequestError'

export const ContainerDelegateTypes = {
  videoTile: 'videoTileShowHome',
}
// @ts-expect-error TS(2417): Class static side 'typeof ShowHome' incorrectly ex... Remove this comment to see the full error message
class ShowHome extends LongScrollPage {
  _contentIsNotAvailable: any
  _contentPosition: any
  _favoriteInteraction: any
  _favoriteInteractionCache: any
  _favoriteItem: any
  _initialState: any
  _isFavorite: any
  _removeItem: any
  _removePersonalization: any
  _featured: any
  _analytics: any
  _metadata: any
  brand: any
  sections: any
  series: any

  static override _template() {
    return {
      Featured: {
        type: Hero,
        customPosition: 1,
      },
      Scroller: {
        type: LongScroll,
        scrollFocus: 0.5,
        x: 80,
        y: 900,
        transitions: {
          y: {
            duration: 0.3,
            timingFunction: 'cubic-bezier(0.20, 1.00, 0.80, 1.00)',
          },
        },
      },
      SubNav: {
        type: SubMenu,
        mountX: 1,
        x: 1250,
        y: 843,
      },
      ShowAbout: {
        alpha: 0,
        type: ShowAbout,
        x: 0,
        y: 0,
        w: SCREEN_SIZE.width,
        h: SCREEN_SIZE.height,
      },
      BackReturn: {
        alpha: 0,
        x: 1861,
        y: 1038,
        mount: 1,
        flex: { direction: FLEX_DIRECTION.row },
        Item1: {
          flexItem: { alignSelf: ALIGN_SELF.center },
          text: {
            fontSize: 32,
            fontFace: FONT_FACE.regular,
            textColor: COLORS.mediumGray1,
            verticalAlign: VERTICAL_ALIGN.middle,
            text: Language.translate('press'),
          },
        },
        Item2: {
          flexItem: {
            marginLeft: 12,
            marginRight: 12,
            alignSelf: ALIGN_SELF.center,
          },
          src: Utils.asset('images/return.png'),
        },
        Item3: {
          flexItem: { alignSelf: ALIGN_SELF.center },
          text: {
            fontSize: 32,
            fontFace: FONT_FACE.regular,
            textColor: COLORS.mediumGray1,
            verticalAlign: VERTICAL_ALIGN.middle,
            text: Language.translate('return_to_top'),
          },
        },
      },
    }
  }

  override _init() {
    this._initialState = 'Featured'
    this._removePersonalization = LaunchDarklySingleton.getFeatureFlag(
      LaunchDarklyFeatureFlags.removePersonalization
    )
    this._contentPosition = ContentPosition
    this.stage.setClearColor(COLORS.dark)
    // Favorites class, initialized if needed.
    this._favoriteInteractionCache = null
  }

  set apiData(v: any) {
    if (!v) Router.navigate(ROUTE.error)
    if (!v?.analytics || !v?.metadata || !v?.sections) return
    this.analytics = v.analytics
    this.metadata = v.metadata
    this.sections = v.sections.map((_section: any) => {
      if (_section?.__typename === 'LinksSelectableGroup') {
        _section = { ..._section }
        // Force in a fontsize - 70 for season 40 for category (clips, etc.)
        // Use translation here as spanish won't match otherwise.
        _section.fontSize = _section.data.itemLabelsTitle === Language.translate('season') ? 70 : 40
      }
      return _section
    })
    // @ts-expect-error TS(2339): Property 'urlAlias' does not exist on type 'Stage'... Remove this comment to see the full error message
    this.stage.urlAlias = this.params.urlAlias
    SectionsSpawner(this.stage, this.sections, ContainerDelegateTypes).then((containers) => {
      this.containers = containers
    })
    this.favorite = v ?? {}
    this.brand = v?.analytics?.brand?.title
    this.series = v?.analytics?.series
    // Fire Page Load now that we have show data.
    sendMetric(EVENTS.PAGE_LOAD, this._getMetricsData(v))
    saveLatestCachedItem('bff.showCacheId', v.id)
  }

  get urlAlias(): string {
    return (this.params?.urlAlias || this.params?.itemName) as string
  }

  _getMetricsData(data: any) {
    const meta = {
      ...data.analytics,
      ...data.metadata,
      brand: data?.analytics?.brand,
      path: data?.analytics?.movie ? 'movie/:itemName' : 'show/:urlalias',
      show: this.series,
    }
    // If its a movie w/out shortTitle, getTitle from analtytics.
    if (meta.shortTitle === null && data?.analytics?.movie) {
      meta.title = data.analytics.movie
    }
    return meta
  }

  async load(): Promise<void> {
    try {
      this.apiData = await useRequest(ShowHomeRequestConfig(this.urlAlias)).fetch()
      this.loadFeatured()
      this._setState(this._initialState)
    } catch (e) {
      this._setState(WithRequestErrorState)
    }
  }

  loadFeatured(): void {
    useRequest(ShowHomeFeaturedRequestConfig(this.urlAlias))
      .fetch()
      .then((featured) => {
        this.featured = featured
      })
      .catch(() => this._setState('Scroller'))
      .finally(() => setSmooth(this.widgets.loader, 'alpha', 0))
  }

  set featured(v: any) {
    if (!v) return
    this._featured = {
      featured: v.data ?? {},
    }
    this.about = v.data
    this.patch({
      Featured: {
        item: this._featured,
      },
    })
  }

  get featured(): any {
    return this._featured
  }

  set analytics(analytics) {
    this._analytics = analytics
  }

  get analytics() {
    return this._analytics
  }

  set metadata(metadata) {
    this._metadata = metadata
  }

  get metadata() {
    return this._metadata
  }

  set containers(v: any) {
    this.tag('Scroller').add(v)
  }

  set about(v: any) {
    if (!v) return
    this.patch({
      ShowAbout: {
        data: {
          title: v.title ?? '',
          content: splitString(
            removeHtmlParams(v.aboutOverlay?.data?.content?.data?.description || ''),
            '\n'
          ),
        },
      },
    })
  }

  set favorite({ analytics, featured }: any) {
    const { v4ID } = analytics
    const items = []
    this._isFavorite = UserInteractionsStoreSingleton.isFavorite(v4ID)
    this._favoriteInteraction = featured?.data?.favoriteInteraction
    this._favoriteItem = {
      icon: 'favorite',
      iconWidth: 48,
      label: Language.translate('favorite'),
    }
    this._removeItem = {
      icon: 'remove',
      iconWidth: 48,
      label: Language.translate('remove'),
    }

    items.push(this._isFavorite ? this._removeItem : this._favoriteItem)
    items.push({
      icon: 'more-info',
      iconWidth: 16,
      label: Language.translate('about'),
    })
    items.push({
      icon: 'shows',
      iconWidth: 38,
      label: Language.translate('shows'),
    })

    this.patch({
      SubNav: {
        items,
      },
    })
  }

  async _updateFavorite() {
    if (this._isFavorite) {
      const userFavorite = UserInteractionsStoreSingleton.getUserFavorite(this.analytics?.v4ID)
      await UserInteractionsStoreSingleton.dispatch(removeFavorite(userFavorite))
    } else {
      const dataType = this.metadata?.pageType as keyof typeof PARK_ENTITY_TYPES

      await UserInteractionsStoreSingleton.dispatch(
        addFavorite(
          {
            titleId: this.analytics?.v4ID,
            created: new Date().toDateString(),
            urlAlias: this.params?.urlAlias as string,
          },
          PARK_ENTITY_TYPES[dataType]
        )
      )
      sendMetric(EVENTS.CLICK, {
        name: 'Favorite Click',
        favorite: 'add',
        brand: this.brand,
        series: this.series,
        show: this.series,
      })
      sendMetric(EVENTS.CLICK, {
        name: 'Item Favorited',
        brand: this.brand,
        'Brand Favorited': this._analytics.brand.title,
        'Favorite Type': 'add',
        genre: this._analytics.genre,
        show: this.series,
        'Show Favorited': this.series,
      })
    }
    this.updateFavoritesCache()
  }

  updateFavoritesCache = () => {
    // Initialize Favorites class to update local cache.
    if (!this._favoriteInteractionCache)
      this._favoriteInteractionCache = FavoriteInteractionFactorySingleton.get()

    this._isFavorite = !this._isFavorite
    this.tag('SubNav').activeItem.item = this._isFavorite ? this._removeItem : this._favoriteItem

    // Add/remove/update homepage favorites shelf.
    this._favoriteInteractionCache.addOrRemoveFavorite(this.params?.urlalias, this._isFavorite)

    // Invalidate show cache to re-fetch favorites status.
    const showId = Storage.get('bff.showCacheId')
    if (showId) evictFromCache(showId)
  }

  _resetPosition() {
    this.tag('Featured').setInactive(true)
    this.tag('Scroller').setSmooth('y', 900)
    this.tag('SubNav').setSmooth('x', 1250)
    this.tag('BackReturn').setSmooth('alpha', 0)
    this.tag('Scroller').setIndex(0)
  }

  $contentIsUnavailable() {
    this._contentIsNotAvailable = true
    this.tag('Scroller').patch({
      x: 0,
      y: 910,
    })
    this._setState('ItemMenu')
  }

  $featuredNotAvailable() {
    this._initialState = 'ItemMenu'
  }

  override _handleBack(e: any) {
    ContentPosition.clearPositionForCurrentPage()
    e.preventDefault()
    e.stopPropagation()
    if (Router.getHistory().length < 1) {
      Router.navigate(ROUTE.home)
    } else {
      const historyState = Router.getHistoryState()
      const routerHistory = Router.getHistory()
      const lastHistory = routerHistory[routerHistory.length - 1]

      if (lastHistory && historyState) {
        const newRoute = `${ROUTE.shows}/${historyState.brand}${
          historyState.category ? `/${historyState.category}` : ''
        }`
        routerHistory[routerHistory.length - 1] = { hash: newRoute, state: historyState }
        Router.setHistory(routerHistory)
        Router.back()
      } else Router.back()
    }
  }

  static override _states() {
    return [
      ItemMenuStateFactory(this),
      FeaturedStateFactory(this),
      ScrollerStateFactory(this),
      AboutStateFactory(this),
    ]
  }
}

export default WithRequestError(ShowHome)
