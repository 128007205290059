interface KeplerMessage<T> {
  type: string
  data?: T
}

type KeplerConfig = {
  ttsEnabled: boolean
  adTrackingEnabled: boolean
  advertiserId: string
}

enum OUTGOING_KEPLER_MESSAGE_TYPE {
  EXIT = 'exit',
  LOADED = 'loaded',
  TTS_SPEAK = 'ttsSpeak',
}

enum INCOMING_KEPLER_MESSAGE_TYPE {
  SET_CONFIG = 'setConfig',
}

export { KeplerMessage, KeplerConfig, OUTGOING_KEPLER_MESSAGE_TYPE, INCOMING_KEPLER_MESSAGE_TYPE }
