import { Lightning } from '@lightningjs/sdk'
import { COLORS, FONT_FACE, VERTICAL_ALIGN } from '../../../../constants'

export enum TimerDesign {
  V1 = 'v1',
  V2 = 'v2',
}

export class Timer extends Lightning.Component {
  _aTime = '00:00'
  _bTime = '00:00'
  _design = TimerDesign.V1

  static override _template() {
    return {}
  }

  set design(design: TimerDesign) {
    // Must set w before calling this setter
    this._design = design
    const text = {
      textColor: COLORS.white,
      verticalAlign: VERTICAL_ALIGN.middle,
      fontSize: 28,
      fontFace: FONT_FACE.regular,
      maxLines: 1,
      text: '00:00',
    }

    if (design === TimerDesign.V1) {
      this.patch({
        ATime: {
          text,
        },
        BTime: {
          x: this.w,
          mountX: 1,
          text,
        },
      })
    } else {
      this.patch({
        x: this.w,
        mountX: 1,
        text: {
          textColor: COLORS.white,
          textAlign: 'right',
          fontSize: 28,
          fontFace: FONT_FACE.regular,
          text: '00:00 / 00:00',
        },
      })
    }
  }

  set aTime(text: string) {
    this._aTime = text
    if (this._design === TimerDesign.V1) {
      this.tag('ATime')?.patch({
        text: {
          text,
        },
      })
    } else {
      this.patch({
        text: { text: `${text} / ${this._bTime}` },
      })
    }
  }

  set bTime(text: string) {
    this._bTime = text
    if (this._design === TimerDesign.V1) {
      this.tag('BTime')?.patch({
        text: {
          text,
        },
      })
    } else {
      this.patch({
        text: { text: `${this._aTime} / ${text}` },
      })
    }
  }
}
