import mParticle from '@mparticle/web-sdk'
import getMarketingModuleImpressionAttributes from '../attributes/getMarketingModuleImpressionAttributes'

const trackMarketingModuleImpression = () => ({
  getAttributes: getMarketingModuleImpressionAttributes,
  getCategory: () => mParticle.EventType?.UserContent,
  type: 'Marketing Module Impression',
  injectGlobals: true,
})

export default trackMarketingModuleImpression
