import Announcer from '../../../../lib/tts/Announcer'

export const NewCodeStateFacrory = (base: any) =>
  class NewCode extends base {
    _getFocused() {
      return this.tag('GenerateButton')
    }
    _handleEnter() {
      Announcer.announce(this._getActivationTexts(), { notification: true })
      this.generateCode()
    }
    _handleDown() {
      this._setState('Cancel')
    }
  }
