import mParticle from '@mparticle/web-sdk'
import getDynamicLeadImpressionAttributes from '../attributes/getDynamicLeadImpresionAttributes'

const getEvent = () => ({
  getAttributes: getDynamicLeadImpressionAttributes,
  getCategory: () => mParticle?.EventType?.UserContent || 'UserContent',
  type: 'Dynamic Lead Impression',
  injectGlobals: true,
})

export default getEvent
