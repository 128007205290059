import { SessionController } from '@sky-uk-ott/core-video-sdk-js-core'
import { MutablePlayerEventStream } from '../event/MutablePlayerEventStream'

export class PlayerEventEmitter {
  _player: SessionController | null
  _normalizedPlayerEvents: MutablePlayerEventStream | null

  attach(player: SessionController, events: MutablePlayerEventStream) {
    this._player = player
    this._normalizedPlayerEvents = events
  }

  detach() {
    this._player = null
    this._normalizedPlayerEvents = null
  }
}
