import { Language } from '@lightningjs/sdk'

import getVodAttributes, { getResumeAttributes } from '../attributes/getVodAttributes'
import { setDefaultValue } from '../../../../helpers'
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants'
import { ClosedCaptionsUtils } from '../../../ClosedCaptions/ClosedCaptionsUtils'
import { getSmartTileAttributes } from './getSmartTileAttributes'

const getVideoStartAttributes = (params: any) => {
  const {
    smartTile,
    shelf,
    ccLanguage,
    previousVideo,
    previousVideoType,
    resumeTime,
    durationSinceLaunch,
  } = params

  return setDefaultValue(
    {
      ...getVodAttributes(params, ccLanguage, previousVideo, previousVideoType),
      ...getResumeAttributes({ resumeTime }),
      ...getSmartTileAttributes(smartTile),
      'Episode Credits Left': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Content Position': shelf?.tileIndex,
      'Custom Shelf Position': shelf?.shelfIndex,
      'Custom Shelf Title': shelf?.listTitle,
      Casting: MPARTICLE_DEFAULT_ATTR_VALUE,
      Sponsor: shelf?.sponsorName,
      'CC Language': ClosedCaptionsUtils.getCCTypeLabel(ccLanguage),
      'Playlist Name': shelf?.playlistMachineName,
      'Playlist Position': shelf?.playlistPosition,
      'Custom Shelf Type': shelf?.shelfType || 'Regular',
      'Shelf Machine Name': shelf?.machineName,
      'Video Duration': params.durationInMilliseconds,
      Language: params.language || Language.get(),
      'Duration Since Launch': durationSinceLaunch,
    },
    MPARTICLE_DEFAULT_ATTR_VALUE
  )
}

export default getVideoStartAttributes
