import isUndefined from 'lodash/isUndefined'

import { SkipButtonDelegate, SkipButtonStates } from '../delegates/SkipButtonDelegate'
import SkipButton from '../../../components/buttons/SkipButton'
import { TimeChangeEvent } from '../../../player/model/event/TimeChangeEvent'
import { PlayerStates } from '../../../constants'
import { Lightning } from '@lightningjs/sdk'
import { Subscription } from 'rxjs'
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore'
import { isSingleStream } from '../../../store/PlayerStore/actions'
import SkipButtons from '../../../api/models/SkipButtons'
import VODPlayerControls from '../../../components/player/PlayerControls/VODPlayerControls'
import BasePlayer from '../BasePlayer'

class HasSkipButton extends BasePlayer {
  override _controls: VODPlayerControls
}

export const WithSkipButton = <T extends Lightning.Component.Constructor<HasSkipButton>>(
  component: T
) =>
  class extends component {
    _skipButton: SkipButton | null
    _skipButtons: SkipButtons | null
    _skipButtonDelegate = new SkipButtonDelegate(this)
    _skipButtonEvents: Subscription
    _skipButtonIsShown = false

    static _template() {
      return {
        // @ts-expect-error TODO static property
        ...super._template(),
        SkipButton: {
          type: SkipButton,
          zIndex: 100,
        },
      }
    }

    override _init() {
      super._init()
      this._skipButton = this.tag('SkipButton')
      this._skipButtons = null
    }

    override _detach(): void {
      super._detach()
      this._skipButtonEvents?.unsubscribe()
    }

    override _playerEventsHandler(event: any) {
      super._playerEventsHandler(event)
      if (event instanceof TimeChangeEvent) {
        this._skipButtonDelegate.sync(event.time)
      }
    }

    override async _startStream() {
      await super._startStream()
      const { stream } = PlayerStoreSingleton
      if (!isSingleStream(stream)) return
      const {
        allowSkipButtons,
        startIntroTiming,
        endIntroTiming,
        startRecapTiming,
        startTeaserTiming,
        endTeaserTiming,
        endRecapTiming,
      } = stream
      this._skipButtons = new SkipButtons({
        allowSkipButtons,
        startIntroTiming,
        endIntroTiming,
        startRecapTiming,
        startTeaserTiming,
        endTeaserTiming,
        endRecapTiming,
      })
      this._skipButtonDelegate.reset()
      this._skipButtonEvents = this.$subscribeToSkipButtonDelegate(
        this.onSkipButtonStatusChanged.bind(this)
      )
    }

    _areControlVisible() {
      return this._controls.getSmooth('alpha') === 1
    }

    onSkipButtonStatusChanged({ status }: any) {
      this.skipButtonIsShown = status !== SkipButtonStates.EMPTY_STATE && !isUndefined(status)

      if (!this.skipButtonIsShown) {
        if (this._getState() === PlayerStates.LPPlayer_SkipButton) {
          if (this._areControlVisible()) {
            this._setState(PlayerStates.LPPlayer_Controls)
          } else {
            this._setState(PlayerStates.LPPlayer)
          }
        }
      } else {
        this._setState(PlayerStates.LPPlayer_SkipButton)
      }
    }

    set skipButtonIsShown(skipButtonIsShown: any) {
      this._skipButtonIsShown = skipButtonIsShown
    }
    get skipButtonIsShown() {
      return this._skipButtonIsShown
    }
    _showSkipButton() {
      return this._skipButtonIsShown
    }

    $subscribeToSkipButtonDelegate(cb: any) {
      return this._skipButtonDelegate?.events.subscribe(cb)
    }

    $selectSkipButton() {
      this._setState(PlayerStates.LPPlayer_SkipButton)
    }

    override _hideUI() {
      super._hideUI()
      if (this._skipButton) this._skipButton.y = 0
    }
  }
