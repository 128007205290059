export default class Cta {
  data: any
  constructor(obj: any, listTitle: any) {
    this.data = {
      ...obj?.data,
      listTitle,
    }
  }

  get destination() {
    return this.data.destination
  }

  get destinationType() {
    return this.data.destinationType
  }

  get title() {
    return this.data.title
  }

  get listTitle() {
    return this.data.listTitle
  }

  get qrModal() {
    return this.data?.qrModal || {}
  }
}
