import { Log, Registry, Router } from '@lightningjs/sdk'
import { isNumber } from 'lodash'

import BasePlayer from './BasePlayer'
import { PlayerError } from '../../components/error/PlayerError'
import { getAdjustedReferringShelf, getMpid, isSleLiveGuideAllowed, setSmooth } from '../../helpers'
import UserInteractionsStoreSingleton from '../../store/UserInteractions'
import { fetchUserInteractions, setLiveWatches } from '../../store/UserInteractions/actions'

import {
  ENTITY_TYPES,
  LIVE_PLAYER_TAG,
  PlayerStates,
  PROGRAMMING_TYPES,
  ROUTE,
  ROUTE_ENTITY,
} from '../../constants'
import { LiveToVodButtonStates, LiveToVodDelegate } from './delegates/LiveToVodDelegate'
import * as LiveWatches from '../../api/LiveWatches'
import PlayerStoreSingleton, { PlayerStoreEvents } from '../../store/PlayerStore/PlayerStore'
import { isEpgProgram, setEpgChannel } from '../../store/PlayerStore/actions/epg'
import { LinearSLEAnalyticsDelegate } from './delegates/analytics/LinearSLEAnalyticsDelegate'
import { WithEpg } from './hoc/WithEpg'
import LaunchDarklySingleton from '../../lib/launchDarkly/LaunchDarkly'
import LaunchDarklyFeatureFlags from '../../lib/launchDarkly/LaunchDarklyFeatureFlags'
import { EpgGuide, EpgGuideV2 } from '../../components'
import { WithBackToLive } from './hoc/WithBackToLive'
import { LiveStreamManager } from '../../lib/LiveStreamManager'
import { StreamRequest } from './StreamLoader/request'
import { StreamLoaderErrors } from './StreamLoader/error'
import RouterUtil from '../../util/RouterUtil'
import {
  clearLemonade,
  isSingleProgram,
  clearState as clearStatePlayerStore,
} from '../../store/PlayerStore/actions'
import { PlayerStatus } from '../../player/model/PlayerStatus'
import { CHANNEL_IDS } from '../../api/Live'
import TVPlatform from '../../lib/tv-platform'
import { ErrorType } from '../../lib/tv-platform/types'
import { usePreCheckData } from '../../store/utils/preCheckData'
import { openPlayerLoader } from '../../widgets/Modals/playerLoader/PlayerLoader'
import { FatalErrorEvent } from '../../player/model/event'
import { useProgress } from '../../components/player/PlayerControls/hooks/useProgress'
import LinearPlayerControlsV2 from '../../components/player/PlayerControls/LinearPlayerControlsV2'
import LinearPlayerControlsV1 from '../../components/player/PlayerControls/LinearPlayerControls'
import LiveToVodButton from '../../components/buttons/LiveToVodButton'

const defaultWatchDuration = 30

class LivePlayer extends BasePlayer {
  _analytics: any
  _epg?: EpgGuide | EpgGuideV2
  _liveToVodDismissed: any
  _liveToVodFixed: any
  _programBoundaryTimeout!: NodeJS.Timeout | null
  _fetchBffTimeout!: NodeJS.Timeout | null
  _timeoutRetryIndex = 0
  _progress = useProgress()
  override _log_tag = LIVE_PLAYER_TAG
  override _controls: LinearPlayerControlsV1 | LinearPlayerControlsV2
  _loaded = false

  _liveToVodDelegate = new LiveToVodDelegate(this)
  _programChanged = false
  override _forceExit = false
  _programBoundaryChangeDelta = 20000

  static override _template() {
    const isNewPlayerDesign = LaunchDarklySingleton.getFeatureFlag(
      LaunchDarklyFeatureFlags.newPlayerTest
    )
    if (isNewPlayerDesign) {
      return super._template({
        Controls: { type: LinearPlayerControlsV2 },
      })
    } else {
      return super._template({
        Controls: { type: LinearPlayerControlsV1 },
        LiveToVodFixed: {
          y: 900,
          type: LiveToVodButton,
        },
      })
    }
  }

  override set params(params: any) {
    // Allow deep linking if there's a channelId param
    if (params.channelId)
      LiveStreamManager.set(
        params.channelId,
        params?.streamAccessName || '',
        params?.callSing || ''
      )
  }

  override _init() {
    super._init()
    this._analyticsDelegate = new LinearSLEAnalyticsDelegate(this)
    Log.info('Launch Darkly:: Show SLE shelf', isSleLiveGuideAllowed())
    this._liveToVodFixed = this.tag('LiveToVodFixed')
  }

  protected async fetchData(): Promise<void> {
    this._epg?.fetchData()
  }

  override async _onStoreEvent(data: any) {
    switch (data.type) {
      case PlayerStoreEvents.STREAM_OK: {
        const { program } = PlayerStoreSingleton
        if (
          isSingleProgram(program) &&
          (program?.programmingType === PROGRAMMING_TYPES.SLE ||
            program?.programmingType === PROGRAMMING_TYPES.FER)
        ) {
          RouterUtil.navigateToRoute(
            ROUTE.watch,
            {
              entity: ROUTE_ENTITY.pid,
              value: program.pid,
            },
            { removePreviousRoute: true }
          )
          break
        }
        const { stream } = PlayerStoreSingleton
        const { callSign } = LiveStreamManager.get()
        if (stream?.callSign !== callSign)
          LiveStreamManager.set(
            stream?.channelId || '',
            stream?.streamAccessName || '',
            stream?.callSign || ''
          )
        await this._load()
        break
      }
      default:
    }
  }

  override _resetStream() {
    LiveStreamManager.reset()
    PlayerStoreSingleton.dispatch(setEpgChannel(LiveStreamManager.get().channelId, '')).catch((e) =>
      // This is caught when epg data hasn't been set before
      // No need to report this to TVPlatform
      Log.error(e)
    )
    this.fetchData()
  }

  override set cancelledActivation(value: boolean) {
    if (value) {
      this._resetStream()
      this._cancelledActivation = false
    }
    this._cancelledActivation = value
  }

  override _active() {
    super._active()

    PlayerStoreSingleton.dispatch(clearLemonade())
      .then(() => usePreCheckData())
      .then((streamDataWasPreChecked) => {
        if (!streamDataWasPreChecked) {
          this.fetchData()
        } else {
          openPlayerLoader(PlayerStoreSingleton.stream, PlayerStoreSingleton.program)
        }
      })
      .catch(() => this.fetchData())

    setSmooth(this.widgets.loader, 'visible', 0)
  }

  override async _detach() {
    super._detach()
    this._liveToVodDelegate?.destroy()
    this._clearProgramBoundaryTimeout()
    this._clearFetchBffTimeout()
  }

  _showLiveToVodFixed() {
    const { status } = this._liveToVodDelegate
    const { program } = PlayerStoreSingleton
    if (!isEpgProgram(program)) return false
    return (
      !this._liveToVodDismissed &&
      program?.allowLiveToVodButton &&
      status !== LiveToVodButtonStates.EMPTY_STATE
    )
  }

  $subscribeToLiveToVodDelegate(cb: any) {
    return this._liveToVodDelegate?.events.subscribe(cb)
  }

  $onLiveToVodButtonEnter() {
    const { label = '', route = '' } = this._liveToVodDelegate || {}
    if (route) {
      this._analyticsDelegate?.fireContentClick({
        entity: { entityType: ENTITY_TYPES.LIVE_TO_VOD },
        analytics: { ...PlayerStoreSingleton.program, textVOD: label },
      })
      this._closeMediaPlayer()
      Router.navigate(route)
    }
  }

  loadEpg() {
    if (this._player?.isPlaying() || this._loaded) {
      // Refresh controls and EPG and go to Player state so UI is properly updated
      this._updateProgramAssetInfo()
    } else {
      this._fetchInitialStream().then(() => {
        if (!this._epg) return
        this._epg.sync()
        this._epg.visible = true
      })
    }
  }

  loadStream() {
    if (this.cancelledActivation) {
      this._setState(PlayerStates.Epg)
      this._epg?.setContentState?.()
      return
    }

    const { program, stream } = PlayerStoreSingleton

    if (!isEpgProgram(program)) return

    const referringShelf = getAdjustedReferringShelf()
    this._analytics = {
      brand: stream?.brandDisplayTitle || program.brand?.title,
      show: program.series,
      season: program.seasonNumber,
      video: program.mpxGuid,
      shelfTitle: referringShelf?.listTitle,
    }

    // Arrived from onAirNow Tile or DLS, and not an SLE, set streamAccessName
    const { accessName, channelId } = LiveStreamManager.get()
    if (!accessName && stream && 'streamAccessName' in stream) {
      LiveStreamManager.set(channelId, stream?.streamAccessName || '', stream?.callSign || '')
    }
    if (this._loaded) {
      if (!this._programChanged) {
        this._player?.clearPreviousSession()
        this._analyticsDelegate?.fireSessionEnd()
        this._isBuffering = false
      }
      if (this._hasError) {
        // Recovering from error, re-attach event listeners.
        this._hasError = false
        this._attach()
      }
    } else {
      this._loaded = true
      this._analyticsDelegate?.firePageLoad()
    }
    this._load()
  }

  _fetchInitialStream() {
    return LiveStreamManager.update().catch(() => {
      this._setErrorState(
        PlayerError.UNKNOWN,
        new FatalErrorEvent({
          description: 'Unable to find stream in Episode Guide',
          code: '404',
        })
      )
    })
  }

  $playOrPause() {
    const name = this._player?.isPlaying() ? 'Live Pause' : 'Live Play'
    this._analyticsDelegate?.fireClick(name)
  }

  override async _startStream() {
    await super._startStream()
    this._setProgramBoundaryTimeout()
    if (this._programChanged) {
      // Should be removed when migrate to live guide v2
      this._programChanged = false
      return this._updateProgramAssetInfo()
    }
    this._liveToVodDismissed = false
    this._liveToVodDelegate.reset()
    this._liveToVodDelegate.sync()

    const livePlayerWatchDurationValue = LaunchDarklySingleton.getFeatureFlag(
      LaunchDarklyFeatureFlags.livePlayerWatchDuration
    )

    const timeOutValue = livePlayerWatchDurationValue
      ? livePlayerWatchDurationValue * 1000
      : defaultWatchDuration * 1000

    Registry.setTimeout(async () => {
      try {
        const result = await LiveWatches.post(getMpid(), PlayerStoreSingleton.stream)

        if (result.data) {
          const { attributes, id, relationships } = result.data

          UserInteractionsStoreSingleton.dispatch(
            setLiveWatches({
              dateTimeWatched: attributes?.streamDateTimeWatched,
              watchId: id,
              brandId: relationships?.brand?.data?.id,
              brandMachineName: attributes?.brandMachineName,
              nationalBroadcastType: attributes?.nationalBroadcastType,
            })
          )

          await UserInteractionsStoreSingleton.dispatch(fetchUserInteractions(true))
        }
      } catch (e) {
        TVPlatform.reportError({
          type: ErrorType.NETWORK,
          code: LIVE_PLAYER_TAG,
          description: 'An error occurred fetching live watches',
        })
      }
    }, timeOutValue)
  }

  _clearProgramBoundaryTimeout() {
    if (this._programBoundaryTimeout) {
      Registry.clearTimeout(this._programBoundaryTimeout)
      this._programBoundaryTimeout = null
    }
  }

  _clearFetchBffTimeout() {
    if (this._fetchBffTimeout) {
      Registry.clearTimeout(this._fetchBffTimeout)
      this._programBoundaryTimeout = null
    }
  }

  /*
   * Set a timeout equal to the remaining seconds to check for program boundary if scte35 tags aren't working.
   * This timeout is cleared if the scte35 tags detect a program boundary change.
   */
  _setProgramBoundaryTimeout() {
    this._clearProgramBoundaryTimeout()
    this._clearFetchBffTimeout()
    const { stream, program } = PlayerStoreSingleton
    const startTime = (program as any)?.startTime || (stream as any)?.startTime
    const endTime = (program as any)?.endTime || (stream as any)?.endTime
    if (!startTime || !endTime) return
    const startTimeUnix = isNumber(startTime) ? startTime : Date.parse(startTime)
    const endTimeUnix = isNumber(endTime) ? endTime : Date.parse(endTime)
    if (startTimeUnix === endTimeUnix) return

    const currentTimeUnix = Date.now()
    const duration = endTimeUnix - startTimeUnix
    const millisecondsRemaining = duration - (currentTimeUnix - startTimeUnix)
    if (millisecondsRemaining > 0) {
      const timeout = millisecondsRemaining + this._programBoundaryChangeDelta
      Log.info(this._log_tag, `exec program boundary timeout in ${timeout} ms`)
      this._programBoundaryTimeout = Registry.setTimeout(this._onProgramChange, timeout)
      // Sometimes BFF data is just wrong, keeping internal counter to retry just once
    } else if (this._timeoutRetryIndex === 0) {
      this._timeoutRetryIndex += 1
      this.fetchData()
    } else {
      this._timeoutRetryIndex = 0
    }
  }

  _onProgramChange = async () => {
    Log.info(this._log_tag, 'program boundary change detected')
    this._clearProgramBoundaryTimeout()
    this._clearFetchBffTimeout()
    this._programChanged = true
    this._programOrder += 1
    this._fetchBffTimeout = Registry.setTimeout(() => this.fetchData(), 10000) // Update EPG
  }

  _updateProgramAssetInfo = () => {
    this._controls.setup()
    this._epg?.sync()
    this._analyticsDelegate?.fireProgramChange()
  }

  /**
   Disable ad indicator for live because total number of ads is not included in the live content's ads manifest
   */
  override _updateAdIndicator() {
    this._adIndicator.patch({ alpha: 0 })
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  override _hideAdIndicator() {}

  override async _onErrorModalBack() {
    if (
      LiveStreamManager.get().accessName !== CHANNEL_IDS.nbcnews &&
      this._player?.status === PlayerStatus.UNKNOWN
    ) {
      await PlayerStoreSingleton.dispatch(clearStatePlayerStore())
      Router.back()
    } else if (!this._player?.isPlaying()) {
      this._player?.play()
    }
  }

  override _startNewTrackingSession() {
    if (!this._programChanged) super._startNewTrackingSession()
  }

  override _openErrorModal(res: StreamRequest) {
    if (res?.error?.detail === StreamLoaderErrors.GEO) {
      res.error.data = {
        description: this._programChanged ? 'program change geo restriction' : 'geo code error',
        code: res.error?.data?.code || res.error?.data,
      }
    }
    super._openErrorModal(res)
  }
}

export default WithBackToLive(WithEpg(LivePlayer))
