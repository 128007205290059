export enum PLATFORM_TYPES {
  XCLASS = 'XClass',
  TIZEN = 'tizen',
  VIZIO = 'vizio',
  XBOX = 'xbox',
  XBOXDEV = 'xboxdev',
  KEPLER = 'kepler',
}

export enum PLATFORM_TYPES_OMNITURE {
  XClass = 'XClass',
  tizen = 'Samsung TV',
  vizio = 'Vizio TV',
  xbox = 'Xbox One',
  xboxdev = 'Xbox One',
  kepler = 'AmazonFireTv',
}

export enum PLATFORM_TYPES_ACTIVATION {
  XCLASS = 'XClass',
  TIZEN = 'samsung',
  VIZIO = 'vizio',
  XBOX = 'xbox',
  XBOXDEV = 'xbox',
  KEPLER = 'AmazonFireTv',
}

/**
 * Returns platform type
 */
export const getPlatformName = () => DEVICE_PLATFORM || PLATFORM_TYPES.XCLASS

export const getPlatFromNameOmniture = () =>
  PLATFORM_TYPES_OMNITURE[DEVICE_PLATFORM] || PLATFORM_TYPES_OMNITURE.XClass

/**
 * Check that current user agent has `WPE` (Web Platform for Embedded)
 * @returns {boolean}
 */
export const isXclass = () => / WPE/.test(window.navigator.userAgent)

export const isXbox = () => getPlatformName() === PLATFORM_TYPES.XBOX

export const isSamsung = () => getPlatformName() === PLATFORM_TYPES.TIZEN

export const isVizio = () => getPlatformName() === PLATFORM_TYPES.VIZIO

// To Do get this info when there are other devices
export const getDeviceModel = () => 'None'

export const getProxyPath = (): string => {
  return window.PROXY_PATH || PROXY_PATH || 'static'
}
