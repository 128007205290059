export enum PlayerStatus {
  IDLE = 0,
  INITIALIZING = 1,
  INITIALIZED = 2,
  READY = 3,
  PLAYING = 4,
  PAUSED = 5,
  BUFFERING = 6,
  SEEKING = 7,
  COMPLETE = 8,
  ERROR = 9,
  RELEASED = 10,
  SUSPENDED = 11,
  EXPIRED = 12,
  UNKNOWN = 13,
  FINISHED = 14,
  LOADING = 15,
}
