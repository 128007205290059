import { Lightning, Registry } from '@lightningjs/sdk'
import { Subscription } from 'rxjs'

import { createXumoHoleShader, SecondaryPlayerSingleton } from '../lib/SecondaryPlayer'
import { fadeComponent, isXclass } from '../helpers'
import { COLORS, SCREEN_SIZE } from '../constants'

import { RouterPage } from '../../types/global'
import { PLAYER_TYPE } from '../player/core/PlayerFactory'
import { ScrollRequestedArgs } from '../components/longScroll/types'
import isNumber from 'lodash/isNumber'

export default class LongScrollPage extends Lightning.Component<
  Lightning.Component.TemplateSpecLoose,
  RouterPage
> {
  _featuredTimeout: ReturnType<typeof Registry.setTimeout> | undefined
  _bgPlayerSubscription?: Subscription
  _defaultClearColor!: Lightning.Stage.RGBA | null

  static override _template(): Lightning.Component.Template<Lightning.Component.TemplateSpecLoose> {
    return {
      BG: {
        w: SCREEN_SIZE.width,
        h: SCREEN_SIZE.height,
        rect: true,
        color: COLORS.black,
        shader: null,
      },
    }
  }

  override _init() {
    this._bgPlayerSubscription = SecondaryPlayerSingleton.subscribe(({ activated, type }) => {
      const element = this.tag('BG')
      const fadeEndValue = Number(!activated)
      if (type === PLAYER_TYPE.BACKGROUND && element.alpha !== fadeEndValue) {
        fadeComponent(element, fadeEndValue)
      }
      if (isXclass() && type === PLAYER_TYPE.PREVIEW) {
        element.shader = createXumoHoleShader(activated)
        this._defaultClearColor = this.stage.getClearColor()
        this.stage.setClearColor(COLORS.transparent)
      }
    })
  }

  override _detach() {
    if (this._defaultClearColor) {
      this.stage.setClearColor(this._defaultClearColor)
    }
    this._bgPlayerSubscription?.unsubscribe()
    this._bgPlayerSubscription = undefined
  }

  _setBackgroundColor(color: COLORS) {
    this.tag('BG').color = color
  }

  $scrollRequested({ position, immediate = false }: ScrollRequestedArgs) {
    const scrollElement = this.tag('Scroller')
    const resultEle = this.tag('Results')
    if (scrollElement && isNumber(position.row)) {
      this._setState('Scroller', [{ immediate }])
      const instanceIndex = scrollElement.getIndexByInstanceID(position.instanceID)
      const index = instanceIndex && instanceIndex !== position.row ? instanceIndex : position.row
      // if index is out of bounds pick last index in row
      // otherwise fallback to first element in row
      const hasViewAllCta = !!scrollElement.items[index]?.viewAllCta
      const positionIndex =
        !position?.content || !scrollElement.items[index]?.items
          ? 0
          : (hasViewAllCta && position.content <= scrollElement.items[index].items.length) ||
            (!hasViewAllCta && position.content <= scrollElement.items[index].items.length - 1)
          ? position.content
          : scrollElement.items[index].items?.length - 1

      scrollElement.setIndex(index, immediate)
      scrollElement.items[index]?.setIndex?.(positionIndex)
    } else if (resultEle && isNumber(position.row)) {
      this._setState('Results')
    }
  }
}
