import { Language } from '@lightningjs/sdk'

import BaseAllEvents from './BaseAllEvents'

import assets from '../../api/algolia/spawners/assets'
import { isCurrentDay } from '../../api/algolia/helpers'
import { setSmooth } from '../../helpers'
import { getSLEAssets, getSLEDates } from '../../api/requests/liveAndUpcoming'
import { ROUTE } from '../../constants'

export default class LiveUpcoming extends BaseAllEvents {
  override _parentRoute = ROUTE.sports
  override _pageTitle = Language.translate('live_and_upcoming')

  static override _template() {
    return super._template()
  }

  // @ts-expect-error TS(2416): Property '_assetsFetchFunction' in type 'LiveUpcom... Remove this comment to see the full error message
  _assetsFetchFunction = (timeParams = null, page = 0) => {
    this._timeParams = timeParams
    return getSLEAssets({ timeParams, page })
  }

  // @ts-expect-error TS(2416): Property '_assetsFetchByIndexFunction' in type 'LiveUpcom... Remove this comment to see the full error message
  _assetsFetchByIndexFunction = (index, timeParams = null, page = 0) => {
    return getSLEAssets({ timeParams, page })
  }
  // @ts-expect-error TS(2416): Property '_datesFetchFunction' in type 'LiveUpcomi... Remove this comment to see the full error message
  _datesFetchFunction = getSLEDates

  // @ts-expect-error TS(2611): 'dates' is defined as a property in class 'BaseAll... Remove this comment to see the full error message
  set dates(v: any) {
    if (!v?.dates?.length) return
    this._setDatesPage(v)

    this._dates = v.dates
    let activeIndex = 0

    const todayIndex = this._dates.findIndex(
      ({ localStartTimestamp, value }) => localStartTimestamp && isCurrentDay(value)
    )
    if (todayIndex > 0) {
      // First item is 'All', hence splice starts at 1
      // Then, remove date filters for events that already happened
      this._dates.splice(1, todayIndex - 1)
    }

    if (this._initialDate) activeIndex = this._fillInitialDate()

    this._filters.items = this._createDatesPatchObject(this._dates, activeIndex)
    if (activeIndex !== 0) this._filters.selectItem(activeIndex)
    setSmooth(this.widgets.loader, 'visible', 0)
    this._setState('Filters')
  }

  // @ts-expect-error TS(2611): 'apiData' is defined as a property in class 'BaseA... Remove this comment to see the full error message
  set apiData(v: any) {
    if (!v.assets.length) return
    assets(this.stage, v.assets).then((assets) => {
      this.results = assets
      this._setResultsPage(v)
    })
  }
}
