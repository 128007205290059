import { Language, Lightning, Router } from '@lightningjs/sdk'

import { COLORS, ROUTE, SCREEN_SIZE } from '../constants'
import { RouterPage } from '../../types/global'
import ErrorModal from '../widgets/Modals/errorModal/ErrorModal'
import {
  ErrorModalAction,
  ErrorModalHook,
  useErrorModal,
} from '../widgets/Modals/errorModal/hooks/useErrorModal'

export default class Error extends Lightning.Component<
  Lightning.Component.TemplateSpecLoose,
  RouterPage
> {
  _errorModalHook: ErrorModalHook

  static override _template() {
    return {
      ErrorModalContainer: {
        x: 0,
        y: 0,
        w: SCREEN_SIZE.width,
        h: SCREEN_SIZE.height,
        rect: true,
        color: COLORS.black,
        ErrorModal: {
          y: 180,
          type: ErrorModal,
        },
      },
    }
  }

  override _init() {
    this.tag('ErrorModal').error = {
      title: Language.translate('bffError_title'),
      message: Language.translate('bffError_info'),
      retryable: false,
    }
    this._errorModalHook = useErrorModal((action: ErrorModalAction) => {
      if (action === ErrorModalAction.BACK) {
        if (this.params?.reBoot) {
          // Force hard reload to reboot the app
          window.location.hash = `#${ROUTE.home}`
          window.location.reload()
        } else {
          Router.back()
        }
      } else if (action === ErrorModalAction.REDIRECT) {
        Router.navigate(ROUTE.live)
      }
    })
  }

  override _getFocused() {
    return this.tag('ErrorModal') || this
  }
}
