import { TrickPlayImageList, TrickPlayImageTile } from '../../components'
import TrickPlayImageTileModel from '../models/tiles/TrickPlayImageTile'

export default class TrickPlayImages {
  _data: any
  constructor(data: any) {
    this._data = data
    this.createModels()
  }

  createTrickPlayImagesList(stage: any) {
    if (!this._data.thumbnails) {
      return
    }

    return stage.c({
      type: TrickPlayImageList,
      tag: 'trickPlayImageTile',
      items: this.createTrickPlayImageItems(),
      title: '',
    })
  }

  createTrickPlayImageItems() {
    const thumbnails = [{}, {}, {}, ...this._data.thumbnails]
    return thumbnails.map((item) => {
      return {
        itemType: TrickPlayImageTile,
        item,
      }
    })
  }

  createModels() {
    if (!this._data || !this._data.thumbnails || this._data.endTime < 1) {
      this._data.thumbnails = null
      return
    }

    // endTime and startTime are in ms, convert to seconds.
    const interval = Math.floor(this._data.endTime / 1000) / this._data.imageCount

    this._data.thumbnails = this._data.thumbnails.map(
      (thumbnail: any, i: any) => new TrickPlayImageTileModel(thumbnail, i * interval)
    )
  }

  getTrickPlayImageIndex(seekTime: any) {
    if (this._data.thumbnails) {
      return this.findTrickPlayImageIndex(seekTime, 0, this._data.thumbnails.length - 1)
    }

    return -1
  }

  // Find the closest matching thumbnail to the seekTime using recursive binary search.
  findTrickPlayImageIndex(seekTime: number, startIndex: number, endIndex: number): number {
    if (endIndex - startIndex > 1) {
      const midpoint = Math.floor(startIndex + (endIndex - startIndex) / 2)
      if (this._data.thumbnails[midpoint].time > seekTime) {
        return this.findTrickPlayImageIndex(seekTime, startIndex, midpoint)
      }
      return this.findTrickPlayImageIndex(seekTime, midpoint, endIndex)
    }
    const high = this._data.thumbnails[endIndex].time - seekTime
    const low = seekTime - this._data.thumbnails[startIndex].time

    if (high > low) {
      return startIndex + 3
    }

    return endIndex + 3
  }
}
