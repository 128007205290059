import { cloneDeep } from 'lodash'
import { Subject, take } from 'rxjs'

enum PlaceholderRequestStatus {
  SUCCESS = 'success',
  REJECT = 'reject',
}

type PlaceholderResponse = { status: PlaceholderRequestStatus; payload: any }

const UPDATE_DELTA = 50

export class PlaceholderRequestController {
  _subject = new Subject<PlaceholderResponse>()
  _data: any
  _client: any

  constructor(client: any) {
    this._client = client
  }

  fetch = (data: any) => {
    const config = data.variables.componentConfigs.join(',')
    if (!this._data) {
      this._data = data
      this._setTimeout()
    }
    const index = this._data.variables.componentConfigs.findIndex((c: any) => c === config)
    if (index === -1) {
      this._data.variables.componentConfigs.push(config)
      return fetchPlaceholder(this, this._data.variables.componentConfigs.length - 1)
    }
    return fetchPlaceholder(this, index)
  }

  private _setTimeout() {
    setTimeout(() => {
      const data = cloneDeep(this._data)
      this._data = undefined
      this._client
        .handleQuery(data)
        .then((res: any) => {
          this._subject.next({ status: PlaceholderRequestStatus.SUCCESS, payload: res })
        })
        .catch((res: any) => {
          this._subject.next({ status: PlaceholderRequestStatus.REJECT, payload: res })
        })
    }, UPDATE_DELTA)
  }
}

const fetchPlaceholder = (controller: PlaceholderRequestController, index: number) => {
  const transformResponse = (r: any) => {
    const response = r.data.componentsForPlaceholders
    const range = response.placeholderRanges[index]
    const [start, end] = range.range
    const components = response.components.slice(start, end)
    return {
      data: {
        componentsForPlaceholders: {
          components,
          placeholderRanges: {
            ...range,
            range: [0, components.length],
          },
        },
      },
    }
  }

  return new Promise((res, rej) => {
    controller._subject.pipe(take(1)).subscribe((r) => {
      if (r.status === PlaceholderRequestStatus.SUCCESS) {
        res(transformResponse(r.payload))
      }
      rej(r.payload)
    })
  })
}
