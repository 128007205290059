import { Lightning } from '@lightningjs/sdk'
import BasePlayerControls from '../BasePlayerControls'

export const MoreInfoStateFactory = (base: Lightning.Component.Constructor<BasePlayerControls>) =>
  class MoreInfo extends base {
    override _getFocused() {
      return this.tag('MoreInfo') || this
    }

    override _handleEnter() {
      this._showDescription()
    }
  }
