import { Lightning } from '@lightningjs/sdk'

type MaybeState<T> = { state: T; if: () => boolean }
const isMaybeState = <T>(input: any): input is MaybeState<T> =>
  typeof input === 'object' && 'state' in input
type States<T> = T | MaybeState<T>
type Center<T> = States<T> | [entry: States<T>, ...fallbacks: States<T>[]]

class Row<T> {
  value: (States<T> | Center<T>)[] = []
  _center = 0

  constructor(left: States<T>[], center: Center<T>, right: States<T>[]) {
    this.value = [...left, center, ...right]
    this._center = left.length
  }

  get center() {
    return this.value[this._center]
  }

  get centerIndex() {
    return this._center
  }

  get length() {
    return this.value.length
  }

  getIndexMap(row: number): Record<string, [number, number]> {
    return this.value.reduce(
      (acc, item, index) => {
        if ((item as any).length) {
          const key = Array.isArray(item) ? item[0] : isMaybeState(item) ? item.state : item
          acc[key as string] = [row, index]
        }
        return acc
      },
      {} as Record<string, [number, number]>
    )
  }
}

export class KeyMapState<T> {
  _rows: Row<T>[]
  _indexMap: Record<string, [number, number]>
  _ctx: Lightning.Component
  _rowIndex?: number
  _itemIndex?: number

  constructor(ctx: Lightning.Component, rows: Row<T>[]) {
    this._ctx = ctx
    this._rows = rows
    this._createIndexMap()
  }

  get currentRow() {
    return typeof this._rowIndex === 'number' ? this._rows[this._rowIndex] : undefined
  }

  private _createIndexMap() {
    // Creating an index map of the state positions
    // so we avoid recalculating at focus
    this._indexMap = this._rows.reduce(
      (acc, row, index) => ({
        ...acc,
        ...row.getIndexMap(index),
      }),
      {}
    )
  }

  private _updateIndexes() {
    const current = this._ctx._getState()
    const data = this._indexMap[current]
    if (data) {
      this._rowIndex = data[0]
      this._itemIndex = data[1]
    }
  }

  private _check(state?: States<T> | Center<T>): T | false {
    if (!state) return false
    let result: boolean | States<T> = false
    if (Array.isArray(state)) {
      for (let i = 0, n = state.length; i < n; i++) {
        const temp = this._check(state[i])
        if (temp) {
          result = temp
          break
        }
      }
    } else if (typeof state === 'string') {
      const component = this._ctx.tag(state)
      // It doesn't make sense to change the state to another one in which there is no element to focus on, or it is hidden.
      if (component && component.visible && component.active) {
        result = state
      }
    } else if (isMaybeState(state) && state.if()) {
      result = state.state
    }
    if (result) this._ctx._setState(result as string)
    return result
  }

  private _checkRowIndex() {
    if (this._rowIndex === undefined) this._updateIndexes()
  }

  private _loop(direction: 1 | -1, horizontal: boolean) {
    this._checkRowIndex()
    const target = horizontal ? this.currentRow?.value : this._rows
    if (!target) return
    const start = ((horizontal ? this._itemIndex : this._rowIndex) || 0) + direction
    const end = direction === -1 ? 0 : target?.length || 0
    const check = (i: number) =>
      this._check(horizontal ? (target as any)?.[i] : (target as any)?.[i]?.center)
    const setIndex = (index: number) => {
      if (!horizontal) this._rowIndex = index
      this._itemIndex = (!horizontal ? this.currentRow?.centerIndex : index) || 0
    }
    for (let i = start, n = end; direction === 1 ? i < n : i >= n; direction === 1 ? i++ : i--) {
      if (check(i)) return setIndex(i)
    }
  }

  reset() {
    this._rowIndex = undefined
    this._itemIndex = undefined
  }

  insertAt(index: number, row: [left: States<T>[], center: Center<T>, right: States<T>[]]): number {
    let insertedAt = index
    if (index >= 0 && this._rows.length >= index) {
      this._rows.splice(index, 0, new Row(...row))
    } else {
      insertedAt = this._rows.push(new Row(...row)) - 1
    }
    this._createIndexMap()
    return insertedAt
  }

  removeAt(index: number) {
    this._rows.splice(index, 1)
    this._createIndexMap()
    this.reset()
  }

  left() {
    this._loop(-1, true)
  }

  right() {
    this._loop(1, true)
  }

  up() {
    this._loop(-1, false)
  }

  down() {
    this._loop(1, false)
  }
}

export const KeyMap = <T>(
  ctx: Lightning.Component,
  rows: [left: States<T>[], center: Center<T>, right: States<T>[]][]
) =>
  new KeyMapState(
    ctx,
    rows.map(([left, center, right]) => new Row(left, center, right))
  )
