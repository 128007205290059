import Tile from './Tile'
export default class BrandTile extends Tile {
  get shortTitle() {
    return this.data.displayTitle || ''
  }

  get whiteBrandLogo() {
    return this.data.whiteBrandLogo || ''
  }

  get colorBrandLogo() {
    return this.data.colorBrandLogo || null
  }

  get machineName() {
    return this.data.machineName
  }
}
