import { Track } from '@sky-uk-ott/core-video-sdk-js-core'

import { IPlayerEvent } from './IPlayerEvent'

export class AudioTracksEvent extends IPlayerEvent {
  private readonly _data: Track[]
  constructor(data: Track[]) {
    super()
    this._data = data
  }

  get data(): Track[] {
    return this._data
  }
}
