import { GraphQlClient } from '../client'
import Variables from '../Variables'
import { BonanzaPage } from '../generated/types'

const getVideoDetailCache = (videoId: any) => {
  try {
    const variables = Variables.videoPageVariables(videoId)
    return {
      data: GraphQlClient.readQuery({ query: BonanzaPage, variables }),
      variables,
    }
  } catch (err) {
    return {}
  }
}

export default getVideoDetailCache
