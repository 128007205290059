import { Log } from '@lightningjs/sdk'

import LaunchDarklyFeatureFlags from '../../lib/launchDarkly/LaunchDarklyFeatureFlags'
import LaunchDarklySingleton from '../../lib/launchDarkly/LaunchDarkly'

export default class SkipButtons {
  _skipButtons: any
  _skipButtonsDuration: any
  static SKIP_TAG = 'Skip Buttons'
  static Type = {
    Intro: 0,
    Recap: 1,
    Teaser: 2,
  }

  constructor(data: any) {
    this._skipButtons = []
    this._skipButtonsDuration = LaunchDarklySingleton.getFeatureFlag(
      LaunchDarklyFeatureFlags.skipTimer
    )

    this._buildSkipButtons(data)
  }

  _addSkipButton(start: any, cuePoint: any, type: any) {
    const end = start + this._skipButtonsDuration
    this._skipButtons.push({
      start,
      end,
      cuePoint: cuePoint < end ? end : cuePoint,
      type,
    })
  }

  _buildSkipButtons(data: any) {
    const { allowSkipButtons, startIntroTiming, endIntroTiming, startRecapTiming } = data
    const { startTeaserTiming, endTeaserTiming, endRecapTiming } = data

    const isSkipIntroEnabled = !!(typeof startIntroTiming === 'number' && endIntroTiming)
    const isSkipRecapEnabled = !!(typeof startRecapTiming === 'number' && endRecapTiming)
    const isSkipTeaserEnabled = !!(typeof startTeaserTiming === 'number' && endTeaserTiming)

    const isSkipEnabled =
      allowSkipButtons &&
      this._skipButtonsDuration &&
      (isSkipIntroEnabled || isSkipRecapEnabled || isSkipTeaserEnabled)

    Log.info(
      SkipButtons.SKIP_TAG,
      `is skip enabled: ${isSkipEnabled} with a duration of ${this._skipButtonsDuration}s`
    )

    if (isSkipEnabled) {
      if (isSkipIntroEnabled)
        this._addSkipButton(startIntroTiming, endIntroTiming, SkipButtons.Type.Intro)
      if (isSkipRecapEnabled)
        this._addSkipButton(startRecapTiming, endRecapTiming, SkipButtons.Type.Recap)
      if (isSkipTeaserEnabled)
        this._addSkipButton(startTeaserTiming, endTeaserTiming, SkipButtons.Type.Teaser)

      Log.info(SkipButtons.SKIP_TAG, this._skipButtons)
    }
  }

  getSkipButton(currentTime: any) {
    if (this._skipButtons) {
      return this._skipButtons.find(
        (skipButton: any) =>
          Math.floor(skipButton.start) < Math.floor(currentTime) &&
          Math.floor(skipButton.end) > Math.floor(currentTime)
      )
    }
  }

  isSkipButtonCompleted(skipButton: any, currentTime: any) {
    return currentTime > skipButton.end || currentTime < skipButton.start
  }
}
