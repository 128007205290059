import { Lightning } from '@lightningjs/sdk'

export default class Bullet extends Lightning.Component {
  static override _template() {
    return {
      texture: Lightning.Tools.getRoundRect(18, 18, 9),
    }
  }

  setFocus(v: any) {
    this.patch({
      smooth: { scale: v ? 1 : 0.66, alpha: v ? 1 : 0.4 },
    })
  }
}
