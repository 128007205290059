import mParticle from '@mparticle/web-sdk'
import getContentClickAttributes from '../attributes/getContentClickAttributes'

const getEvent = () => ({
  getAttributes: getContentClickAttributes,
  getCategory: () => mParticle.EventType?.UserContent || 'UserContent',
  type: 'Content Click',
  injectGlobals: true,
})

export default getEvent
