import { Utils } from '@lightningjs/sdk'
import Button from './Button'

export default class ImageButton extends Button {
  static override _template() {
    return {
      ...super._template(),
      Icon: {
        mount: 0.5,
      },
    }
  }

  set icon(icon: any) {
    this.patch({
      Icon: {
        src: Utils.asset(icon),
      },
    })

    if (this._autoWidth) {
      this._explicitWidth = this.tag('Icon').renderWidth
    } else {
      this._explicitWidth = this.w
    }
    this._calculateButtonWidth()
  }

  override _focus() {
    super._focus()

    this.tag('Icon').patch({
      color: this._focusBackGroundColor,
    })
  }

  override _unfocus() {
    super._unfocus()

    this.tag('Icon').patch({
      color: this._unfocusBackgroundColor,
    })
  }
}
