import { Storage } from '@lightningjs/sdk'

import {
  findInCache,
  getCacheFromQuery,
  getCacheIndex,
  getNamespacedId,
  setInCache,
  updateCacheIndex,
} from './cache'
import { getHash, storeHash } from '../../api/requests/bffQuery'
import { GqlFetchResult, QueryData } from './types'
import {
  getBff,
  getExtensions,
  getHeaders,
  getQueryName,
  makeGetUrl,
  persistedQueryUnavailable,
  postFallback,
} from './helpers'
import { PlaceholderRequestController } from './placeholder'

class GraphQl {
  uri = ''
  placeholder = new PlaceholderRequestController(this)
  async setBffUrl(): Promise<void> {
    try {
      const bff = await getBff()
      this.uri = `${bff.url}${bff.version}${bff.endpoint}`
    } catch (e) {
      return Promise.reject()
    }
  }
  handleResponse(options: any, data: QueryData, hash?: string) {
    return (result: GqlFetchResult<any>) => {
      if (options?.middleware) {
        options?.middleware?.forEach((middleware: (result: any) => any) => middleware(result))
      }
      // Only store the hash if the query is successful
      storeHash(data.query, hash)
      return result
    }
  }
  async query<T>(
    data: QueryData,
    options?: {
      middleware?: ((res: Response) => any)[]
    }
  ): Promise<GqlFetchResult<T>> {
    if (!this.uri) await this.setBffUrl()
    if (data.variables.componentConfigs) {
      return this.placeholder.fetch(data) as GqlFetchResult<T>
    }
    return this.handleQuery(data, options)
  }
  handleQuery<T>(
    data: QueryData,
    options?: {
      middleware?: ((res: Response) => any)[]
    }
  ): Promise<GqlFetchResult<T>> {
    const { query, variables } = data

    const hash = getHash(query)
    const headers = getHeaders()
    const extensions = getExtensions(hash)

    variables.queryName = getQueryName(query, variables)

    // Make GET call for persisted query.
    return fetch(makeGetUrl(this.uri, variables, extensions), {
      method: 'GET',
      headers,
    })
      .then((response) => response.json())
      .then((json) => {
        // If PersistedQueryNotFound, POST it and return response.
        if (persistedQueryUnavailable(json)) {
          return postFallback(this.uri, headers, query, variables, extensions)
        }
        return json
      })
      .then(this.handleResponse(options, data, hash))
  }
  readQuery(data: any) {
    return getCacheFromQuery(data)?.value?.data
  }
  cache = {
    clear() {
      const cache = getCacheIndex()
      for (const key in cache) {
        const isInStorage = Storage.get(key)
        if (isInStorage) {
          Storage.remove(key)
        }
      }
      const cacheKey = getNamespacedId('cache')
      Storage.set(cacheKey, {})
    },
    gc() {
      const now = Date.now()
      const cache = getCacheIndex()
      const startCacheLength = Object.keys(cache).length

      for (const key in cache) {
        const expired = cache[key] < now
        const isInStorage = Storage.get(key)

        if (!isInStorage) {
          delete cache[key]
        } else if (expired) {
          if (isInStorage) {
            Storage.remove(key)
          }
          delete cache[key]
        }
      }

      if (Object.keys(cache).length !== startCacheLength) {
        updateCacheIndex(cache)
      }
    },
    evict(id: any) {
      Storage.remove(getNamespacedId(id))
      this.gc()
    },
    modify(data: any) {
      const { key, value } = findInCache(data.id)
      if (value) {
        const newValue = data.fields?.(value)
        setInCache(key, newValue)
      }
    },
  }
}

export const GraphQlClient = new GraphQl()
