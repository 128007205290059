import { Lightning } from '@lightningjs/sdk'
import { Subscription } from 'rxjs'
import { COLORS, EPG_SLOT_WIDTH } from '../../../../constants'
import { EpgGuideController, EpgGuideEvents } from '../../EpgGuideController'

export default class GuideProgressBar extends Lightning.Component {
  static activeColor = COLORS.lightGray11
  static inactiveColor = COLORS.mediumGray6

  static getWidth(fillPercent?: number) {
    return EPG_SLOT_WIDTH * ((fillPercent || EpgGuideController.progress) / 100)
  }
  _subscription?: Subscription

  constructor(stage: Lightning.Stage) {
    super(stage)
    this._subscription = EpgGuideController.subscribe({
      type: EpgGuideEvents.PROGRESS,
      handler: this._onProgress,
    })
  }

  static override _template() {
    return {
      rect: true,
      h: (h: number) => h,
      w: () => GuideProgressBar.getWidth(),
      color: GuideProgressBar.inactiveColor,
      shader: {
        type: Lightning.shaders.RoundedRectangle,
        radius: [6.375, 0, 0, 6.375],
      },
    }
  }

  private _onProgress = (progress: number) => {
    this.patch({ w: GuideProgressBar.getWidth(progress) })
  }

  override _focus() {
    this.patch({ color: GuideProgressBar.activeColor })
  }

  override _unfocus() {
    this.patch({ color: GuideProgressBar.inactiveColor })
  }

  override _detach() {
    super._detach()
    this._subscription?.unsubscribe()
  }
}
