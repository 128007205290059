import { Language, Lightning } from '@lightningjs/sdk'
import { Subscription } from 'rxjs'

import {
  COLORS,
  EPG_VIEWS,
  FLEX_DIRECTION,
  FONT_FACE,
  JUSTIFY_CONTENT,
  LiveGuideV2States,
  OLY_IMPOLICY,
  PROGRAMMING_TYPES,
  SCREEN_SIZE,
  VERTICAL_ALIGN,
} from '../../constants'

import { FastImg, getStreamData } from '../../helpers'
import PlayerStoreSingleton, { PlayerStoreEvents } from '../../store/PlayerStore/PlayerStore'
import { AssetInfoStateFactory } from './states/AssetInfoStateFactory'
import { ChannelsStateFactory } from './states/ChannelsStateFactory'
import { TabsStateFactory } from './states/TabsStateFactory'
import { FiltersStateFactory } from './states/FiltersStateFactory'
import { SportsStateFactory } from './states/SportsStateFactory'
import { isEpgProgram, isEpgStream } from '../../store/PlayerStore/actions/epg'

import { GuideTabs } from './subcomponents'
import ChannelsGuide from './subcomponents/ChannelsGuide/ChannelsGuide'
import FiltersGuide from './subcomponents/FiltersGuide'
import { getSubtitleForLive } from '../player/helpers/metadata'
import RouterUtil from '../../util/RouterUtil'
import TVPlatform from '../../lib/tv-platform'
import { SubscriptionBuilder, SubscriptionSources } from '../../util/SubscriptionBuilder'
import { EpgGuideController, isNestedLinksSelectableGroup } from './EpgGuideController'
import { ComponentFragment, ItemLabelsConfigItemFragment } from '../../graphql/generated/types'
import { Maybe } from 'graphql/jsutils/Maybe'

export default class EpgGuideV2 extends Lightning.Component {
  _programIndex: any
  _streamIndex: number
  _tabs: GuideTabs
  announce: any
  itemHeight: number
  itemPadding: any
  itemWidth: number
  programImageWidth: number
  _channelsGuide: ChannelsGuide
  _filtersGuide: FiltersGuide
  _isError = false
  _subscription?: Subscription
  _guideSubscription?: Subscription

  static override _template() {
    return {
      w: SCREEN_SIZE.width,
      h: SCREEN_SIZE.height,
      rect: true,
      color: COLORS.transparent,
      Gradient: {
        rect: true,
        w: (w: number) => w,
        h: (h: number) => h,
        flex: {
          direction: FLEX_DIRECTION.column,
        },
        color: COLORS.transparent,
        Top: {
          rect: true,
          w: (w: number) => w,
          h: 390,
          colorTop: COLORS.transparent,
          colorBottom: COLORS.black,
        },
        Bottom: {
          rect: true,
          w: (w: number) => w,
          h: 690,
          color: COLORS.black,
        },
      },
      Holder: {
        y: 253,
        w: SCREEN_SIZE.width,
        h: 985,
        clipping: true,
        rect: true,
        color: COLORS.transparent,
        GuideTabs: {
          x: 65,
          y: 10,
          type: GuideTabs,
        },
        GuideHolder: {
          y: 110,
          type: ChannelsGuide,
          itemHeight: this.bindProp('itemHeight'),
          itemPadding: this.bindProp('itemPadding'),
          itemWidth: this.bindProp('itemWidth'),
          programImageWidth: this.bindProp('programImageWidth'),
        },
        FiltersHolder: {
          visible: false,
          y: 90,
          type: FiltersGuide,
          itemHeight: this.bindProp('itemHeight'),
          itemPadding: this.bindProp('itemPadding'),
          itemWidth: this.bindProp('itemWidth'),
          programImageWidth: this.bindProp('programImageWidth'),
        },
      },
      AssetInfo: {
        alpha: 0,
        x: 90,
        y: 165,
        h: 64,
        w: 892,
        Focus: {
          alpha: 0,
          mountY: 0.5,
          h: (h: number) => h + 20,
          y: (h: number) => h / 2,
          w: (w: number) => w,
          rect: true,
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            stroke: 6,
            fillColor: COLORS.transparent,
            strokeColor: COLORS.white,
          },
        },
        BrandLogo: {
          x: 77,
          y: (h: any) => h / 2,
          mount: 0.5,
        },
        TitleContainer: {
          x: 172,
          h: (h: any) => h,
          flex: {
            direction: FLEX_DIRECTION.column,
            justifyContent: JUSTIFY_CONTENT.center,
          },
          Title: {
            flexItem: {},
            text: {
              textColor: COLORS.white,
              verticalAlign: VERTICAL_ALIGN.middle,
              fontSize: 25,
              fontFace: FONT_FACE.regular,
              fontWeight: 600,
              maxLines: 1,
              wordWrapWidth: 700,
            },
          },
          SecondaryTitle: {
            text: {
              textColor: COLORS.white,
              verticalAlign: VERTICAL_ALIGN.middle,
              fontSize: 25,
              fontFace: FONT_FACE.regular,
              fontWeight: 400,
              maxLines: 1,
              wordWrapWidth: 700,
            },
          },
        },
      },
    }
  }

  set isError(isError: boolean) {
    this._isError = isError

    if (isError) {
      const prevRoute = RouterUtil.getPrevRoute()
      const isComingFromOlympics = /ol|para/.test(prevRoute || '')

      if (this._tabs && isComingFromOlympics) this._tabs.focusIndex = 1
      else this._tabs.focusIndex = 0

      this._tabs?.selectCurrent()
    }
  }

  get isError() {
    return this._isError
  }

  override _init() {
    this._tabs = this.tag('GuideTabs')
    this._filtersGuide = this.tag('FiltersHolder')
    this._channelsGuide = this.tag('GuideHolder')
    EpgGuideController.setupTabIndex()
    this._subscription = new SubscriptionBuilder()
      .with({
        type: SubscriptionSources.PLAYER_STORE,
        events: [PlayerStoreEvents.STREAM_OK],
      })
      .with({
        type: SubscriptionSources.LIVE_GUIDE_VIEW,
        handler: this._changeView,
      })
      .subscribe(() => this.sync())
  }

  override _detach() {
    this._subscription?.unsubscribe()
  }

  override _active() {
    super._active()
    if (TVPlatform.getForceGC()) this.stage.gc()
  }

  override _focus() {
    this._setInitialState()
  }

  sync() {
    const { stream, program, streamIndex } = PlayerStoreSingleton
    if (!isEpgStream(stream) || !isEpgProgram(program)) return
    this._tabs?.selectCurrent()
    const title = program.programTitle || Language.translate('program_unavailable')
    const secondaryTitle =
      program.programmingType === PROGRAMMING_TYPES.SLE
        ? program.videoTitle
        : getSubtitleForLive(program, stream)
    this.tag('AssetInfo').patch({
      alpha: 1,
      BrandLogo: {
        texture: FastImg(stream?.whiteBrandLogo).contain(56, 56, OLY_IMPOLICY.SQUARE),
      },
      TitleContainer: {
        Title: {
          text: {
            text: title,
          },
        },
        SecondaryTitle: {
          visible: secondaryTitle,
          text: {
            text: secondaryTitle,
          },
        },
      },
    })

    this._channelsGuide.changeBrandIndex(streamIndex)
  }

  load() {
    this._programIndex = 0
    this._setInitialState()
  }

  async fetchData(): Promise<void> {
    await getStreamData()
  }

  _setInitialState() {
    if (this._channelsGuide.loaded || this._filtersGuide.items?.length) {
      const view = EpgGuideController.currentEpgView
      this._setState(
        view === EPG_VIEWS.channels ? LiveGuideV2States.Channels : LiveGuideV2States.Filters
      )
    } else this._setState(LiveGuideV2States.Tabs)
  }

  _changeView = (view: EPG_VIEWS) => {
    if (view === EPG_VIEWS.channels) {
      this._setState(LiveGuideV2States.Channels)
    } else {
      this._setState(LiveGuideV2States.Filters)
      const component = EpgGuideController.currentTab
      if (isNestedLinksSelectableGroup(component)) {
        this._filtersGuide.items = component?.data.items as Maybe<ComponentFragment>[]
        this._filtersGuide.filters = component?.data.itemLabelsConfig?.filter(
          Boolean
        ) as ItemLabelsConfigItemFragment[]
      }
    }
  }

  setContentState() {
    this._setState(LiveGuideV2States.Tabs)
  }

  static override _states() {
    return [
      TabsStateFactory(this),
      ChannelsStateFactory(this),
      SportsStateFactory(this),
      FiltersStateFactory(this),
      AssetInfoStateFactory(this),
    ]
  }
}
