import { Log } from '@lightningjs/sdk'

import mParticleInterface from './mParticle/mParticleInterface'

import { EVENTS, AnalyticsSingletonEvents } from './types'
import { IMarketingModuleVideoPreviewParams } from './mParticle/attributes/getMarketingModuleVideoPreviewAttributes'
import { ContentClickParams } from './mParticle/attributes/getContentClickAttributes'

type EventsMap = Record<AnalyticsSingletonEvents, (params: any) => void>

class Analytics implements EventsMap {
  _currentVideo: any = null
  _isBuffering = false
  _isLive = false
  _isSeeking = false
  _mParticle = new mParticleInterface()

  initialize = async () => {
    const successCallback = (payload: any) => {
      Log.info('mParticle Initialization Success ', payload)
    }
    await this._mParticle.initialize(successCallback)
  }

  setUserAttribute(key: string, value: string | string[]) {
    this._mParticle.setUserAttribute(key, value)
  }

  updateUserDefaultAttributes(tempPassTokenName?: string) {
    this._mParticle.updateDefaultUserAttributes(tempPassTokenName)
  }

  onAppClosed = () => {}

  cleanUpSession = () => {
    this._currentVideo = null
    if (this._isBuffering) this._isBuffering = false
    if (this._isSeeking) this._isSeeking = false
  };

  // #region Events
  // General
  [EVENTS.ERROR] = (params: any) => {
    this._mParticle.trackError(params)
  };
  [EVENTS.EXIT] = (params: any) => {
    this._mParticle.trackExit(params)
  };

  // Ads
  [EVENTS.AD_BREAK_START] = (adBreak: any) => {
    this._mParticle.trackAdBreakStart(this._currentVideo, adBreak, this._isLive)
  };
  [EVENTS.AD_BREAK_END] = (adBreak: any) => {
    this._mParticle.trackAdBreakEnd(this._currentVideo, adBreak, this._isLive)
  };
  [EVENTS.AD_START] = (ad: any) => {
    this._mParticle.trackAdStart(this._currentVideo, ad, this._isLive)
  };
  [EVENTS.AD_END] = (ad: any) => {
    this._mParticle.trackAdEnd(this._currentVideo, ad, this._isLive)
  };

  // Seeking
  [EVENTS.SEEK_START] = () => {
    if (this._isSeeking) return
    this._isSeeking = true
  };
  [EVENTS.SEEK_END] = () => {
    if (!this._isSeeking) return
    this._isSeeking = false
  };

  // Live
  [EVENTS.LIVE_SESSION_INITIALIZED] = () => {
    this._mParticle.trackLivePlayerLoad()
  };
  [EVENTS.LIVE_PROGRAM_CHANGE] = (metadata: any) => {
    this._mParticle.trackLiveProgramChange(metadata, this._currentVideo)
    this._currentVideo = metadata
  };
  [EVENTS.LIVE_SESSION_START] = (metadata: any) => {
    this._isLive = true
    this._currentVideo = metadata
    this._mParticle.trackLiveSessionStart(metadata)
  };
  [EVENTS.LIVE_SESSION_END] = (metadata: any) => {
    if (this._currentVideo) {
      this._mParticle.trackLiveSessionEnd({
        ...metadata,
        _currentVideo: this._currentVideo,
      })
      this.cleanUpSession()
    }
  };

  // VOD
  [EVENTS.VOD_SESSION_START] = (metadata: any) => {
    this._isLive = false
    this._currentVideo = metadata
    this._mParticle.trackVodSessionStart(metadata)
  };
  [EVENTS.VOD_SESSION_END] = (metadata: any) => {
    this._mParticle.trackVodSessionEnd(metadata)
    this.cleanUpSession()
  };

  // Player
  [EVENTS.ON_BUFFER_START] = () => {
    this._isBuffering = true
  };
  [EVENTS.ON_BUFFER_COMPLETE] = () => {
    if (!this._isBuffering) return
    this._isBuffering = false
  };
  [EVENTS.PLAYER_READY] = () => {
    this._mParticle.trackPlayerLoadTime(this._currentVideo)
  };

  // Auth
  [EVENTS.AUTHZ_START] = () => {
    this._mParticle.trackAuthZStart()
  };
  [EVENTS.AUTHZ_COMPLETE] = () => {
    this._mParticle.trackAuthZComplete()
  };
  [EVENTS.AUTH_SUCCESS_MVPD] = (params: any) => {
    this._mParticle.trackMvpdAuthSuccess(params)
  };
  [EVENTS.AUTH_SUCCESS_NBC] = (params: any) => {
    this._mParticle.trackNbcAuthSuccess(params)
  };
  [EVENTS.IDM_CONVERSION] = () => {
    this._mParticle.trackIDMConversion()
  };
  [EVENTS.MVPD_PAGE_ABANDONED] = (params: any) => {
    this._mParticle.trackMvpdPageAbandoned(params)
  };
  [EVENTS.NBC_PROFILE_SIGN_OUT] = () => {
    this._mParticle.trackNbcProfileSignOut()
  };

  // User actions
  [EVENTS.PAGE_LOAD] = (routeData: {
    [params: string]: any
    brand?: { title?: string | undefined | null } | string | null
    category?: string
    hash?: string // Path (i.e.: "networks/bravo")
    path?: string // Route as in ROUTE enum (i.e.: "networks/:machineName")
  }) => {
    this._mParticle.trackPageLoad(routeData)
  };
  [EVENTS.CLICK] = (params: any) => {
    this._mParticle.trackClick(params)
  };
  [EVENTS.CONTENT_CLICK] = (params: ContentClickParams) => {
    this._mParticle.trackContentClick(params)
  };
  [EVENTS.SEARCH_RESULT] = (metadata: any) => {
    this._mParticle.trackSearchResult(metadata)
  };
  [EVENTS.SHELF_IMPRESSION] = (params: any) => {
    this._mParticle.trackShelfImpression(params)
  };
  [EVENTS.MARKETING_MODULE_IMPRESSION] = (params: any) => {
    this._mParticle.trackMarketingModuleImpression(params)
  };
  [EVENTS.MARKETING_MODULE_VIDEO_PREVIEW] = (params: IMarketingModuleVideoPreviewParams) => {
    this._mParticle.trackMarketingModuleVideoPreview(params)
  };
  [EVENTS.SHELVES_LOAD] = (params: any) => {
    this._mParticle.trackShelvesLoad(params)
  };
  [EVENTS.MODAL_LOAD] = (params: any) => {
    this._mParticle.trackModalLoad(params)
  };
  [EVENTS.DYNAMIC_LEAD_IMPRESSION] = (params: any) => {
    this._mParticle.trackDynamicLeadImpression(params)
  };
  [EVENTS.END_CARD_IMPRESSION] = (params: any) => {
    this._mParticle.trackEndCardImpression(params)
  };
  [EVENTS.END_CARD] = (params: any) => {
    this._mParticle.trackEndCard(params)
  };
  [EVENTS.LIVE_TO_VOD_IMPRESSION] = (params: any) => {
    this._mParticle.trackLiveToVodImpression(params)
  }
  // #endregion
}
const AnalyticsSingleton = new Analytics()

export function sendMetric<T extends AnalyticsSingletonEvents>(
  key: T,
  ...params: Parameters<(typeof AnalyticsSingleton)[T]>
) {
  // @ts-expect-error We are spreading a tuple
  AnalyticsSingleton[key](...params)
}
export default AnalyticsSingleton
