import { GqlFetchResult } from '../client/types'
import { ComponentsForPlaceholdersQuery } from '../generated/types'

const componentsForPlaceholdersMapper = (page: GqlFetchResult<ComponentsForPlaceholdersQuery>) => {
  const data = page.data?.componentsForPlaceholders
  if (!data) return null
  return data
}

export default componentsForPlaceholdersMapper
