import { Lightning } from '@lightningjs/sdk'

import { ALIGN_SELF, COLORS, FLEX_DIRECTION, TEXT_ALIGN } from '../../constants'

export default class LetterSpacingText extends Lightning.Component {
  _letters: any
  fontFace: any
  fontSize: any
  space: any
  textColor: any
  static override _template() {
    return {
      flex: { direction: FLEX_DIRECTION.row },
      flexItem: { alignSelf: ALIGN_SELF.center },
      Letters: {
        flex: { direction: FLEX_DIRECTION.row },
        flexItem: { alignSelf: ALIGN_SELF.center },
      },
    }
  }

  override _construct() {
    this.fontSize = 20
    this.textColor = COLORS.white
    this.fontFace = 'Regular'
    this.space = 1
  }

  override _build() {
    this._letters = this.tag('Letters')
  }

  set value(value: any) {
    const characters = value ? value.split('') : []

    this._letters.patch({
      children: characters.map((letter: any) => {
        return {
          flexItem: { marginRight: this.space },
          text: {
            fontSize: this.fontSize,
            fontFace: this.fontFace,
            textAlign: TEXT_ALIGN.center,
            text: letter,
            textColor: this.textColor,
          },
        }
      }),
    })
  }
}
