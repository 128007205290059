import { GraphQlClient } from '../client'
import { BonanzaPage, EntityPageType } from '../generated/types'
import Variables from '../Variables'

const getVideoHomePageCache = (urlalias: string, type: EntityPageType) => {
  const variables = Variables.showPageVariables(type, urlalias)
  try {
    return {
      data: GraphQlClient.readQuery({
        query: BonanzaPage,
        variables,
      }),
    }
  } catch (err) {
    return {}
  }
}

export default getVideoHomePageCache
