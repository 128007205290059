import { SCREEN_SIZE } from '../../../constants'
import LabelItem from '../partials/LabelItem'
import { setSmooth } from '../../../helpers'
import { LabelListRenderingStrategy } from '.'

const SECTION_HEIGHT = SCREEN_SIZE.height * 0.5
const GAP = 10

export default class LabelsListVertical extends LabelListRenderingStrategy {
  override get isScrollable() {
    return this._ctx.h >= SCREEN_SIZE.height
  }

  // #region Lifecycle
  override init() {
    this._ctx.patch({
      Holder: {
        Focus: {
          x: 0,
        },
        Items: {
          x: 80,
        },
      },
    })
  }
  // #endregion

  // #region Labels
  override _mapItem = (item: any, index: number) => {
    const isScrollable = this.isScrollable
    if (item.isDivider && index === 0) {
      this._index += 1
      this._activeIndex = this._index
    }
    return {
      w: 376,
      h: 68,
      autoWidth: item.autoWidth || true,
      type: LabelItem,
      focusFontFace: this._fontFace,
      item,
      index,
      isVertical: true,
      isDivider: item.isDivider,
      scrollable: isScrollable,
    }
  }

  override positionItems() {
    const yCoordinates = this._items.children.reduce(
      (acc: any, { h }: any) => [...acc, acc.slice(-1)[0] + h + GAP],
      [0]
    )
    this._ctx.h = yCoordinates.slice(-1)[0]
    const isScrollable = this.isScrollable
    this._items.children.forEach((child: any, i: any) => {
      child.alpha = 1
      child.y = yCoordinates[i]
      child.scrollable = isScrollable
    })
    this._items.h = this._ctx.h
  }
  // #endregion

  // #region Events
  override handleUp() {
    if (this._index > 0) {
      const nextItem = this.getChildItem(this._index - 1)
      if (nextItem?.isDivider && this._index === 1) {
        setSmooth(this._items, 'y', 0, { duration: 0.3, delay: 0 })
        this.isScrolled = false
        return false
      }
      nextItem?.isDivider ? this.moveWithDelta(-2) : this.moveWithDelta(-1)
    } else {
      return false
    }
  }

  override handleDown() {
    if (this._index < this._items.children.length - 1) {
      const nextItem = this.getChildItem(this._index + 1)
      nextItem?.isDivider ? this.moveWithDelta(2) : this.moveWithDelta(1)
    } else {
      this.announceEndOfRow()
    }
  }

  override moveToFocused() {
    this.setChildItem(this._activeIndex, { active: false })
    this._activeIndex = this._index
    this.setChildItem(this._activeIndex, { active: true })
  }

  override moveToActive() {
    this._scrollTo(this._index)
  }
  // #endregion

  // #region Scrolling
  override _changeListOffsetToFocused() {
    let scrolledDistance = 0
    const currentY = this._items.y
    const focusItemY = this.focusItem?.y || 0
    const sectionHeight = this._sectionSize || SECTION_HEIGHT

    if (focusItemY === 0) {
      // Jump to start of the list
      setSmooth(this._items, 'y', 0, { duration: 0.3, delay: 0 })
      this.isScrolled = false
    } else {
      // Calculate list scroll
      const absoluteCurrentY = Math.abs(currentY)
      const lesserThanCurrentY = focusItemY <= absoluteCurrentY
      const greaterThanCurrentY = focusItemY > absoluteCurrentY + sectionHeight

      if (lesserThanCurrentY || greaterThanCurrentY) {
        const focusItemHeight = (this.focusItem?.h || 0) + GAP
        const totalItemsInScreen = Math.ceil(sectionHeight / focusItemHeight)
        //adjustScrollIndex and nextFocusHeight ensure we also visibly show the next item (focused + 1) if we are moving down
        const nextFocusableItem = this._items.children[this._index + 1]
        const adjustScrollIndex = nextFocusableItem ? 1 : 0
        const nextFocusHeight = nextFocusableItem ? nextFocusableItem.h + GAP : 0
        const positionToScrollBottom =
          this._index + adjustScrollIndex > totalItemsInScreen
            ? focusItemHeight * (this._index - totalItemsInScreen) + nextFocusHeight
            : 0
        const positionToScrollTop = -((this._index - 1) * focusItemHeight)
        const distance = lesserThanCurrentY
          ? positionToScrollTop
          : greaterThanCurrentY
          ? -positionToScrollBottom
          : currentY

        scrolledDistance = distance
        this.isScrolled = distance !== 0
        setSmooth(this._items, 'y', distance, { duration: 0.3, delay: 0 })
      }
      if (Math.abs(scrolledDistance) + sectionHeight > this._items.finalW) {
        this.onLabelsPageEnd()
      }
    }
  }
  // #endregion
}
