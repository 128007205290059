import { Language } from '@lightningjs/sdk'
import { ReplaySubject } from 'rxjs'

import SkipButtons from '../../../api/models/SkipButtons'

export const SkipButtonStates = {
  EMPTY_STATE: 'EmptyState',
  INTRO: SkipButtons.Type.Intro,
  RECAP: SkipButtons.Type.Recap,
  TEASER: SkipButtons.Type.Teaser,
}
export class SkipButtonDelegate {
  parent: any
  skipButton: any
  _timeout = null
  status = undefined
  events = new ReplaySubject(1)

  constructor(ctx: any) {
    this.parent = ctx
  }

  reset() {
    this.status = undefined
  }

  get label() {
    const labels = {
      [SkipButtonStates.EMPTY_STATE]: '',
      [SkipButtonStates.INTRO]: Language.translate('skip_intro'),
      [SkipButtonStates.RECAP]: Language.translate('skip_recap'),
      [SkipButtonStates.TEASER]: Language.translate('skip_teaser'),
    }

    // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
    return labels[this.status] || labels[SkipButtonStates.EMPTY_STATE]
  }

  sync(currentTime: any) {
    const previousStatus = this.status

    if (this.parent._skipButtons) {
      this.skipButton = this.parent._skipButtons.getSkipButton(currentTime)

      if (this.skipButton) {
        this.status = this.skipButton?.type
        this.show({ label: this.label, cuePoint: this.skipButton.cuePoint })
      } else {
        // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'undefined... Remove this comment to see the full error message
        this.status = SkipButtonStates.EMPTY_STATE
        this.hide()
      }
    }

    if (previousStatus !== this.status) this.events.next({ status: this.status, label: this.label })
  }

  show({ label, cuePoint }: any) {
    this.parent._skipButton.label = label
    this.parent._skipButton.cuePoint = cuePoint
    this.parent._skipButton.alpha = 1
  }

  hide() {
    this.parent._skipButton.label = ''
    this.parent._skipButton.cuePoint = null
    this.parent._skipButton.alpha = 0
  }

  dismiss() {
    this.parent._skipButton.alpha = 0
  }
}
