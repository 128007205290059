import { Language } from '@lightningjs/sdk'
import { createFlexText } from '../../../helpers'
import {
  FLEX_DIRECTION,
  FONT_FACE,
  TEXT_ALIGN,
  VERTICAL_ALIGN,
  ALIGN_ITEMS,
  JUSTIFY_CONTENT,
} from '../../../constants'
import Activation from './Activation'

class TempPassActivation extends Activation {
  static override _template() {
    return super._template({
      ActivationScreen: {
        Content: {
          y: 228,
          CodeContent: {
            flex: {
              direction: FLEX_DIRECTION.column,
              alignItems: ALIGN_ITEMS.center,
              justifyContent: JUSTIFY_CONTENT.center,
            },
            Line2: {
              y: 5,
              textAlign: TEXT_ALIGN.center,
              text: {
                textAlign: TEXT_ALIGN.center,
                text: Language.translate('tempPass-activation_line2'),
              },
            },
            CodeBackground: {
              y: 120,
            },
            GenerateButton: {
              y: 227,
              label: Language.translate('tempPass-activation_refresh_code'),
            },
          },
          DividerContent: {
            x: 720,
          },
          QRContent: {
            flex: {
              direction: FLEX_DIRECTION.column,
              alignItems: ALIGN_ITEMS.center,
              justifyContent: JUSTIFY_CONTENT.center,
            },
            QRText: {
              text: {
                text: Language.translate('tempPass-activation_qr_text'),
              },
            },
            QRPlaceHolder: undefined,
            UsePhone: undefined,
          },
        },
      },
    })
  }

  override _drawText() {
    const activationTexts = [
      Language.translate('tempPass-activation_line1'),
      this._getActivationUrl(),
    ]
    this.tag('Line1').children = createFlexText(
      [
        activationTexts[0],
        {
          text: activationTexts[1],
          fontFace: FONT_FACE.semiBold,
        },
      ],
      {
        fontSize: 34,
        verticalAlign: VERTICAL_ALIGN.middle,
      }
    )
    // @ts-expect-error TODO: fix announce
    this.announce = [
      ...this._strategy.drawText(),
      ...activationTexts,
      this.tag('Line2')?.text?.text,
      this.tag('Code')?.text?.text,
    ].filter((item) => !!item)
  }

  override _setTranslation = () => {
    this.tag('Content.Line2')?.patch({
      text: { text: Language.translate('tempPass-activation_line2') },
    })
    this.tag('Content.GenerateButton')?.patch({
      label: Language.translate('tempPass-activation_refresh_code'),
    })
  }
}

export default class extends TempPassActivation {}
