import { DebugControllerSingleton } from '../../util/debug/DebugController'
import { GqlFetchResult } from '../client/types'
import debugSection from '../debug/debugSection'
import { BonanzaPageQuery } from '../generated/types'

export type BffPage = {
  analytics?: NonNullable<BonanzaPageQuery['bonanzaPage']>['analytics']
  featured: NonNullable<BonanzaPageQuery['bonanzaPage']>['data']['featured']
  sections: NonNullable<BonanzaPageQuery['bonanzaPage']>['data']['sections']
  metadata?: NonNullable<BonanzaPageQuery['bonanzaPage']>['metadata']
  id?: NonNullable<BonanzaPageQuery['bonanzaPage']>['id']
  ldStateFlag?: NonNullable<BonanzaPageQuery['ldStateFlag']>
}

const bffPageMapper = (page: GqlFetchResult<BonanzaPageQuery>): BffPage | null => {
  const bonanzaPage = page.data?.bonanzaPage
  const data = bonanzaPage?.data
  if (!data) return null
  if (DebugControllerSingleton.hardCodedShelfEnabled) {
    addDebugSection(data.sections)
  }
  return {
    analytics: bonanzaPage?.analytics,
    featured: data.featured,
    sections: data.sections,
    metadata: bonanzaPage?.metadata,
    id: bonanzaPage?.id,
    ldStateFlag: page.data?.ldStateFlag,
  }
}

type Sections = NonNullable<BonanzaPageQuery['bonanzaPage']>['data']['sections']
const addDebugSection = (sections: Sections) => {
  if (sections.length === 0) return sections
  sections.splice(1, 0, debugSection())
}

export default bffPageMapper
