import { Router } from '@lightningjs/sdk'
import moment from 'moment-timezone'

import LongScrollPage from '../LongScrollPage'

import { setSmooth, templateDeepMerge } from '../../helpers'
import { createItems } from '../../components/componentMaps'
import TileDataModels from '../../api/models/tiles'
import { ResultsStateFactory } from './states/Results'
import { FiltersStateFactory } from './states/Filters'
import { COLORS, ROUTE } from '../../constants'
import { AlgoliaMappedResults } from '../../api/algolia/types'
import ContentPosition from '../../util/contentPosition'
import FilterLabelsList from '../../components/labelsList/FiltersLabelsList'
import { LabelsListRenderingType } from '../../components/labelsList/LabelsList'
import TVPlatform from '../../lib/tv-platform'
import { ErrorType } from '../../lib/tv-platform/types'

// @ts-expect-error TS(2417): Class static side 'typeof BaseAllEvents' incorrect... Remove this comment to see the full error message
export default class BaseAllEvents extends LongScrollPage {
  _filters: any
  _initialDate: any
  _results: any
  apiData: any
  indexApiData: any
  dates: any
  _isFetchingResults = false
  _isFetchingDates = false
  _canFetchMoreDates = false
  _timeParams = null
  _resultsPage = 0
  _datesPage = 0
  _dates: [any?] = []

  _parentRoute = ''
  _pageTitle = ''

  _assetsFetchFunction = () => Promise.resolve()
  _datesFetchFunction = () => Promise.resolve()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _assetsFetchByIndexFunction = (_index?: number) => Promise.resolve()

  static override _template(override?: any) {
    return templateDeepMerge(
      {
        Filters: {
          x: 75,
          y: 184,
          type: FilterLabelsList,
          mode: LabelsListRenderingType.HORIZONTAL,
          focusFontFace: 'Regular',
          radius: 6,
        },
        Results: {},
      },
      override
    )
  }

  override async _init() {
    const position = ContentPosition.getPositionForCurrentPage()
    this._filters = this.tag('Filters')
    try {
      this.dates = await this._datesFetchFunction()
      if (position && position?.content && position?.content >= 16) {
        // Fetch enough data to load a saved a position
        this.indexApiData = await this._assetsFetchByIndexFunction(position?.content)
      } else if (!this.initialDateParams) {
        this.apiData = await this._assetsFetchFunction()
      } else {
        this.fetchByFilter(this.initialDateParams)
      }
    } catch {
      //fail silently
    }
  }

  override _attach() {
    this.stage.setClearColor(COLORS.dark)
  }

  set results(v) {
    if (v) {
      this._results = v
      v.x = 75
      v.y = 267
      this.patch({
        Results: v,
      })
    } else {
      this.patch({
        Results: undefined,
      })
    }
  }

  get results() {
    return this._results
  }

  override set params(params: any) {
    const mmddyyyy = params?.mmddyyyy || this.getDateFromLocationHash()
    if (mmddyyyy) {
      const date = moment(mmddyyyy, 'MMDDYYYY')
      this._initialDate = {
        value: date.format('YYYY-MM-DD'),
        startUnix: date.endOf('day').unix(),
        endUnix: date.startOf('day').unix(),
      }
    }
  }

  get initialDateParams() {
    if (!this._initialDate) return null
    return {
      localEndTimestamp: this._initialDate.endUnix,
      localStartTimestamp: this._initialDate.startUnix,
    }
  }

  getDateFromLocationHash(): string | void {
    const date = window.location.hash.split('/')?.[1]
    if (date && /(^\d{8}$)/.test(date)) return date
  }

  override _onMounted() {
    this.stage.setClearColor(COLORS.dark)
    this.widgets.menu.collapse({
      parentRoute: this._parentRoute,
      subCategoryItem: this._pageTitle,
      hideProfile: true,
    })
  }
  override _disable() {
    this.widgets.menu.expand()
  }

  _createDatesPatchObject(dates: any, activeIndex: any) {
    return dates.map(({ label }: any, index: any) => {
      return {
        active: typeof activeIndex === 'number' && index === activeIndex,
        label,
        fontSize: 35,
      }
    })
  }

  _createResultsPatchObject(assets: any) {
    return createItems(assets).reduce((acc: any, v: any) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const model = TileDataModels[v.item?.component || '']
      return model
        ? [
            ...acc,
            {
              ...v,
              item: new model(v.item),
            },
          ]
        : acc
    }, [])
  }

  _setDatesPage(v: any) {
    this._datesPage = v.page
    this._canFetchMoreDates = v.totalPages > v.page
  }

  _setResultsPage(v: any) {
    this._resultsPage = v.page
    this.results.moreItems = v.totalPages > v.page
  }

  $getMoreItems() {
    if (this._isFetchingResults) return
    this._isFetchingResults = true
    // @ts-expect-error TS(2554): Expected 0 arguments, but got 2.
    this._assetsFetchFunction(this._timeParams, this._resultsPage + 1).then(
      (results: AlgoliaMappedResults | void) => {
        this._isFetchingResults = false
        const { assets } = results || {}
        if (assets && assets.length > 0) {
          this._setResultsPage(results)
          this.results.addItems(this._createResultsPatchObject(assets))
        }
      }
    )
  }

  $onLabelsPageEnd() {
    if (this._isFetchingDates || !this._canFetchMoreDates) return
    this._isFetchingDates = true
    // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
    this._datesFetchFunction(this._datesPage + 1)?.then((v) => {
      this._isFetchingDates = false
      this._setDatesPage(v)
      // @ts-expect-error TS(2339): Property 'dates' does not exist on type 'void'.
      this._dates = this._dates.concat(v.dates)
      // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
      this._filters.addItems(this._createDatesPatchObject(v.dates))
    })
  }

  $scrolledRow(rowId: any) {
    const hideElements = rowId < 2
    this.widgets.menu.visible = hideElements
    this.tag('Filters').patch({ visible: hideElements })
    if (this.tag('Results').children.length) this._setState('Results')
  }

  fetchByFilter = (timeParams: any) => {
    const keys = ['localStartTimestamp', 'localEndTimestamp']
    const isSameFilter = keys.every((key) => this._timeParams?.[key] === timeParams?.[key])
    // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
    this._assetsFetchFunction(isSameFilter ? null : timeParams)
      .then((v) => {
        // @ts-expect-error TS(2339): Property 'assets' does not exist on type 'void'.
        if (v.assets.length) {
          this.apiData = v
          setSmooth(this.tag('Results'), 'visible', 1)
          if (isSameFilter) {
            this._filters.selectItem(0)
          }
        }
        setSmooth(this.widgets.loader, 'visible', 0)
      })
      .catch((err) => {
        TVPlatform.reportError({
          type: ErrorType.NETWORK,
          code: 'All Events Page',
          description: 'Error fetching search results',
          payload: err,
        })
        Router.navigate(ROUTE.error)
      })
  }

  _fillInitialDate() {
    const findDate = ({ value }: any) => value === this._initialDate.value
    const datesIndex = this._dates.findIndex(findDate)
    if (datesIndex > -1) {
      const { localEndTimestamp, localStartTimestamp } = this._dates[datesIndex]
      this._initialDate.endUnix = localEndTimestamp
      this._initialDate.startUnix = localStartTimestamp
      return datesIndex
    }
    return 0
  }

  static override _states() {
    return [ResultsStateFactory(this), FiltersStateFactory(this)]
  }
}
