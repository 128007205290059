import { GqlFetchResult } from '../client/types'
import { BrandTitleCategoriesQuery } from '../generated/types'

const brandTitleCategories = (page: GqlFetchResult<BrandTitleCategoriesQuery>) => {
  const data = page.data?.brandTitleCategories
  if (!data) return null
  return { data }
}

export default brandTitleCategories
