import getLinearAttributes from './getLinearAttributes'

const getLinearProgramStartAttributes = (params = {}) => ({
  ...getLinearAttributes(params),
  // @ts-expect-error TS(2339): Property 'duration' does not exist on type '{}'.
  'Video Duration': params.duration,
  // @ts-expect-error TS(2339): Property 'programOrder' does not exist on type '{}... Remove this comment to see the full error message
  'Program Order': params.programOrder,
})

export default getLinearProgramStartAttributes
