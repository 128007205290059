export const isNumberLike = (input: any) =>
  typeof input === 'number' || (![undefined, null].includes(input) && !Number.isNaN(Number(input)))

export const looseStringCompare = (...arr: (string | null | undefined)[]) => {
  for (let i = 0, previous: string | null | undefined; i < arr.length; i++) {
    const current = arr[i]
    const equal = previous?.toLowerCase() === current?.toLowerCase()
    if (i > 0 && !equal) return false
    previous = current
  }
  return true
}
