import { GqlFetchResult } from '../client/types'
import { ScrollFromSeasonQuery } from '../generated/types'

const bffScrollFromSeasonMapper = (page: GqlFetchResult<ScrollFromSeasonQuery>) => {
  const data = page.data?.scrollFromSeason
  if (!data) return null
  return { data }
}

export default bffScrollFromSeasonMapper
