import { Lightning, Registry } from '@lightningjs/sdk'
import {
  createProgressHookEvent,
  useProgress,
} from '../../../components/player/PlayerControls/hooks/useProgress'
import { inRange } from 'lodash'
import { HasSeeking, SeekDirection } from './WithSeeking'

export const WithSeekingV2 = <T extends Lightning.Component.Constructor<HasSeeking>>(
  component: T
) =>
  class extends component {
    TIME_STEPS = 10
    multiplier = 0
    _lastTime = 0
    _timeout?: NodeJS.Timeout | number
    _seekDirection = SeekDirection.NONE

    override _init() {
      super._init()
      this._progress = useProgress()
    }

    _handleTimeChange(direction: SeekDirection) {
      const factor = direction === SeekDirection.FF ? 1 : -1
      if (this._seekDirection === SeekDirection.NONE) {
        this._lastTime = this._progress.value[0]
        this.multiplier = 0
        this._analyticsDelegate?.fireClick(direction === SeekDirection.FF ? 'Forward' : 'Rewind')
        this._analyticsDelegate?.fireSeekStart()
        if ('seekIconV2' in this._controls) this._controls.seekIconV2 = this.TIME_STEPS * factor
      } else {
        if (this._seekDirection !== direction) {
          this.multiplier = factor
        } else if (Math.abs(this.multiplier) !== 3) {
          this.multiplier += factor
        }
        if ('seekIconV2' in this._controls) this._controls.seekIconV2 = this.multiplier
      }
      // Recalculate with updated multiplier - only reset timeout if we haven't reached max speed
      if (Math.abs(this.multiplier) !== 3) this._updateTimeOffset(factor, this.multiplier)
      this._seekDirection = direction
    }

    $forward() {
      this._handleTimeChange(SeekDirection.FF)
    }

    $rewind() {
      this._handleTimeChange(SeekDirection.REW)
    }

    $seek() {
      if (this._timeout) Registry.clearTimeout(this._timeout)
      const currentTime = this._progress.value[0]
      // seek adjust is used for SLEs currently.
      // add time required to get the player in line with the seek bar (seconds).
      const seekAdjust = this._progress.value[3] || 0
      this._player?.seek(currentTime + seekAdjust)
    }

    /**
     * Rewinds content 5 seconds before the end.
     * Used in non-production environments.
     */
    $seekToTheEnd() {
      this._player?.pause()
      if ('seekIconV2' in this._controls) this._controls.seekIconV2 = 0
      const duration = this._progress.value[1]
      this._progress.set(createProgressHookEvent(duration - 5))
      this.$seek()
    }

    _updateTimeOffset = (offset: 1 | -1, recurseFactor: number) => {
      if (this._timeout) Registry.clearTimeout(this._timeout)
      if (this._player?.isPlaying()) this._player?.pause()
      const [current, duration, seekableRange] = this._progress.value
      const time = current + this.TIME_STEPS * offset
      if (!inRange(time, seekableRange?.[0] || 0, seekableRange?.[1] || duration)) {
        this.multiplier = 0
        if (time !== 0) {
          // Start playing if we have reached live position
          this.$seek()
          this._player?.play()
        }
        return
      }
      this._progress.set(createProgressHookEvent(time))
      if (recurseFactor !== 0) {
        if ('seekIconV2' in this._controls) this._controls.updateSeekBarTimerV2()
        this._timeout = Registry.setTimeout(
          () => this._updateTimeOffset(offset, recurseFactor),
          1000 / Math.abs(recurseFactor)
        )
      }
    }

    override _onSeekEnd() {
      super._onSeekEnd()
      if ('seekIconV2' in this._controls) this._controls.seekIconV2 = 0
      this._seekDirection = SeekDirection.NONE
      this._analyticsDelegate?.fireSeekEnd()
    }

    override _handleBack(e: any) {
      switch (this._seekDirection) {
        case SeekDirection.NONE:
          return super._handleBack(e)
        case SeekDirection.REW:
          if (this._timeout) Registry.clearTimeout(this._timeout)
          this._progress.set(createProgressHookEvent(this._lastTime))
          this.$seek()
          this._player?.play()
          break
        case SeekDirection.FF:
          if (this._timeout) Registry.clearTimeout(this._timeout)
          this.$rewind()
          break
        default:
          break
      }
    }
  }
