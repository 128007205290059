export default class Tile {
  obj: any
  shelf: any
  constructor(obj = {}, shelf: any = {}, tileIndex = 0) {
    this.obj = {
      ...obj,
      index: tileIndex,
    }
    this.shelf = {
      ...shelf,
      tileIndex,
    }
  }

  get shelfData() {
    return this.shelf
  }

  get analytics() {
    return this?.obj?.analytics || null
  }

  get component() {
    return this.obj.component || null
  }

  get data() {
    return this.obj.data || {}
  }

  get meta() {
    return this.obj.meta || null
  }

  get treatments() {
    return this.obj.treatments || null
  }

  get onAirNowTileData() {
    return this.obj?.onAirNowTileData || this.obj?.data || {}
  }

  get upcomingLiveTileData() {
    return this.obj?.upcomingLiveTileData || this.obj?.data || {}
  }

  get eventTileData() {
    return this.obj?.eventTileData || this.obj?.data || {}
  }

  get ariaLabel() {
    return this.data?.ariaLabel
  }

  get title() {
    return this.data.title
  }

  get v4ID(): string {
    return this.data.v4ID && this.data.v4ID !== 'N/A' ? this.data.v4ID : this.data.videoV4ID
  }

  get slideHeader() {
    return this.data.slideHeader || this.data.title
  }
}
