import AnalyticsSingleton, { sendMetric } from '../../../../lib/analytics/Analytics'
import { EVENTS } from '../../../../lib/analytics/types'
import BasePlayer from '../../BasePlayer'
import { AnalyticsWithEndCardDelegate } from './AnalyticsWithEndCardDelegate'

export class FERAnalyticsDelegate extends AnalyticsWithEndCardDelegate<BasePlayer> {
  override fireSessionInit() {
    // no op
  }
  updateUserDefaultAttributes(tempPassTokenName: any) {
    AnalyticsSingleton.updateUserDefaultAttributes(tempPassTokenName)
  }
  override fireSessionStart() {
    sendMetric(EVENTS.VOD_SESSION_START, this._getMetricsData())
  }

  override fireSessionEnd() {
    sendMetric(EVENTS.VOD_SESSION_END, this._getMetricsData())
  }
}
