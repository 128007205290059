export const AboutStateFactory = (base: any) =>
  class About extends base {
    $enter() {
      this.tag('ShowAbout').setSmooth('alpha', 1)
      // Speed up featured Transition (.6) @see PL-203.
      this.tag('Featured').setInactive(false, true, 0.6)
      this.tag('Scroller').setSmooth('alpha', 0)
      this.tag('SubNav').setSmooth('x', 3000)
    }

    $exit() {
      this.tag('ShowAbout').setSmooth('alpha', 0)
      this.tag('Featured').setInactive(true, false)
      this.tag('Scroller').setSmooth('alpha', 1)
      this.tag('SubNav').setSmooth('x', 1250)
    }

    $hideAbout() {
      this.tag('SubNav').activeIndex = 1
      this._setState('ItemMenu')
    }

    _getFocused() {
      return this.tag('ShowAbout') || this
    }
  }
