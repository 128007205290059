import { GqlFetchResult } from '../client/types'
import { PaginatedShelfQuery } from '../generated/types'

const paginatedShelfMapper = (
  page: GqlFetchResult<PaginatedShelfQuery>
): { data: PaginatedShelfQuery['paginatedShelf'] } | null => {
  const data = page.data?.paginatedShelf
  if (!data) return null
  return { data }
}

export default paginatedShelfMapper
