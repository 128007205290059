import { Lightning, Settings } from '@lightningjs/sdk'
import TVPlatform from '../../lib/tv-platform'

export default class ItemWrapper extends Lightning.Component {
  _index: any
  _item: any
  _itemIsCreated: any
  _itemType: any
  _notifyOnItemCreation: any
  _lazyProps = null
  static FIRST_CREATED = false

  static override _template() {
    return {
      clipbox: true,
      w: 320,
      h: 320,
    }
  }

  set item(obj) {
    this._item = obj
  }

  get item() {
    return this._item
  }

  set index(v: any) {
    this._index = v
  }

  get child() {
    return this.children[0]
  }

  set itemType(v: any) {
    this._itemType = v
  }

  setChildProps(props: any) {
    if (this.child) {
      this.child.patch?.(props)
    } else {
      this._lazyProps = props
    }
  }

  $getCurrentScrollPosition() {
    const result = { ...this.fireAncestors('$getCurrentScrollPosition') }
    if (!result.content) result.content = this._index || 0
    return result
  }

  create() {
    const item = this._item
    const index = this._index
    this.children = [
      {
        type: this._itemType,
        item: item,
        index,
        ...(this._lazyProps || {}),
      },
    ]
    if (this._lazyProps) this._lazyProps = null
    this._itemIsCreated = true

    if (this._notifyOnItemCreation && this.hasFocus()) {
      this._refocus()
    }
  }

  override _firstActive() {
    this.create()

    if (!ItemWrapper.FIRST_CREATED) {
      this.fireAncestors('$firstItemCreated')
      ItemWrapper.FIRST_CREATED = true
    }
  }

  override _inactive() {
    this.childList.clear()
    this._itemIsCreated = false
    if (Settings.get('app', 'gcList') || TVPlatform.getForceGC()) {
      this.stage.gc()
    }
  }

  override _active() {
    if (!this._itemIsCreated) {
      this.create()
    }
  }

  // @ts-expect-error TS(2416): Property '_getFocused' in type 'ItemWrapper' is no... Remove this comment to see the full error message
  _getFocused() {
    // due to lazy creation there is the possibility that
    // an component receives focus before the actual item
    // is created, therefore we set a flag
    if (!this.child) {
      this._notifyOnItemCreation = true
    }
    return this.child || this
  }
}
