import mParticle from '@mparticle/web-sdk'
import getVideoStartAttributes from '../attributes/getVideoStartAttributes'

const trackVideoStart = () => ({
  getAttributes: getVideoStartAttributes,
  getCategory: () => mParticle?.EventType?.UserContent || 'UserContent',
  type: 'Video Start',
  injectGlobals: true,
})

export default trackVideoStart
