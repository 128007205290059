import { Subject } from 'rxjs'
import { AdobeAuthenticationService } from './adobe/AdobeAuthenticationService'
import { clearPollingTimeOuts, pollIDM } from '../api/Identity'
import UserInteractionsStoreSingleton from '../store/UserInteractions'
import { fetchUserInteractions } from '../store/UserInteractions/actions'
import { ACTIVATION_TYPE } from '../widgets/Modals/activation/constants'

const AuthenticationSingleton = new AdobeAuthenticationService()

class AuthenticationEventsManager {
  _totalPolls = 0
  _subject = new Subject<ACTIVATION_TYPE>()
  _listeners: Record<ACTIVATION_TYPE, (() => void)[]> = {
    [ACTIVATION_TYPE.NBC]: [],
    [ACTIVATION_TYPE.MVPD]: [],
    [ACTIVATION_TYPE.TEMPPASS]: [],
  }

  /**
   * Adds an event listener which will be automatically tear down once its executed
   * @param type ACTIVATION_TYPE enum
   * @param cb Callback
   */
  addSingleListener(type: ACTIVATION_TYPE, cb: () => void) {
    if (this._listeners[type]) {
      this._listeners[type].push(cb)
    }
  }

  /**
   * Removes single event listener for a given ACTIVATION_TYPE enum
   * @param type ACTIVATION_TYPE enum
   * @param cb Callback
   */
  removeSingleListener(type: ACTIVATION_TYPE, cb: () => void) {
    if (this._listeners[type]) {
      this._listeners[type] = this._listeners[type].filter((c) => c === cb)
    }
  }

  get totalPolls() {
    return this._totalPolls
  }

  set totalPolls(value: number) {
    this._totalPolls = value < 0 ? 0 : value
  }

  async pollIDM(deviceCode: string) {
    try {
      this.totalPolls++
      await pollIDM(deviceCode, this._totalPolls > 1)
      this.totalPolls--
      await UserInteractionsStoreSingleton.dispatch(fetchUserInteractions(true))
      this._subject.next(ACTIVATION_TYPE.NBC)
      if (this._listeners.NBC.length) {
        this._listeners.NBC.forEach((cb) => cb())
        this._listeners.NBC = []
      }
    } catch (error) {
      this.totalPolls--
      console.warn('error', error)
    }
  }

  async pollAdobe() {
    try {
      this.totalPolls++
      await AuthenticationSingleton.pollStatus()

      if (this._listeners.NBC.length) {
        this._listeners.NBC.forEach((cb) => cb())
        this._listeners.NBC = []
      }
      const type = AuthenticationSingleton.isMvpdTempPass()
        ? ACTIVATION_TYPE.TEMPPASS
        : ACTIVATION_TYPE.MVPD
      this._subject.next(type)
      if (this._listeners[type].length) {
        this._listeners[type].forEach((cb) => cb())
        this._listeners[type] = []
      }
    } catch (error) {
      this.totalPolls--
      console.warn('error', error)
    }
  }

  stopPolling(type?: ACTIVATION_TYPE) {
    if (!type || type === ACTIVATION_TYPE.NBC) {
      clearPollingTimeOuts()
      this.totalPolls--
    }
    if (!type || type === ACTIVATION_TYPE.MVPD) {
      AuthenticationSingleton._clearPollingTimeOuts()
      this.totalPolls--
    }
  }

  subscribe(cb: (type: ACTIVATION_TYPE) => void) {
    return this._subject.subscribe(cb)
  }
}

export const AuthenticationEvents = new AuthenticationEventsManager()
export default AuthenticationSingleton
