import { Log } from '@lightningjs/sdk'
import { GraphQlClient } from '../client'

import getVideoHomePage from './getVideoHomePage'
import getVideoDetail from './getVideoDetail'
import getHomepage from './getHomepage'
import getBrandLandingPage from './getBrandLandingPage'
import { EntityPageType } from '../generated/types'

const CACHE_TAG = 'Cache'

export const getAllCaches = (video: any) =>
  [
    getVideoHomePage(
      video.urlAlias,
      video.programmingType === 'Movie' ? EntityPageType.Title : EntityPageType.Series
    ),
    getVideoDetail(video.mpxGuid),
    getHomepage(),
    getBrandLandingPage(video.channelId),
  ].filter((query) => query.data)

export { getVideoHomePage, getVideoDetail, getHomepage, getBrandLandingPage }

export const evict = (id: any) => {
  const cacheId = `BonanzaPage:${id}`
  try {
    Log.info(`${CACHE_TAG} removed ${cacheId} from cache`)
    GraphQlClient.cache.evict({ id: cacheId })
    GraphQlClient.cache.gc()
  } catch (error) {
    // Won't TVPlatform.reportError this one since it will send a lot of unneeded events
    Log.error(`${CACHE_TAG} error clearing ${cacheId} from cache`)
  }
}

export const modify = (cacheId: any, modifier = () => {}, params: any) => {
  try {
    Log.info(CACHE_TAG, `cache modified for ${cacheId}`)
    GraphQlClient.cache.modify({
      id: `BonanzaPage:${cacheId}`,
      broadcast: false,
      fields: {
        data(cachedData: any) {
          return {
            ...cachedData,
            // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
            ...modifier(cachedData, params),
          }
        },
      },
    })
  } catch (error) {
    // Won't TVPlatform.reportError this one since it will send a lot of unneeded events
    Log.error(CACHE_TAG, `error modifying cache ${error}`)
  }
}
