import { Lightning } from '@lightningjs/sdk'
import { COLORS, FLEX_DIRECTION, FONT_FACE } from '../../../../constants'

export default (tile: any) => {
  if (tile._item?.showMovieData) {
    tile.patch({
      Content: {
        TitleCard: {
          VideoTitle: {
            text: {
              text: tile._item?.description,
            },
          },
          SecondaryTitle: {
            text: { text: '' },
            flex: { direction: FLEX_DIRECTION.row },
            flexItem: { marginTop: 8, marginBottom: 8 },
            Icon: {
              flexItem: { marginRight: 8, marginTop: 3 },
              texture: Lightning.Tools.getRoundRect(
                70,
                32,
                0,
                1,
                COLORS.white,
                false,
                COLORS.transparent
              ),
              Label: {
                mount: 0.5,
                x: 35,
                y: 18,
                text: {
                  text: tile._item.rating,
                  fontFace: FONT_FACE.regular,
                  fontSize: 26,
                  textColor: COLORS.lightGray8,
                },
              },
            },
            MovieTitle: {
              text: {
                text: tile._item.secondaryTitle,
                fontFace: FONT_FACE.regular,
                fontSize: 26,
                textColor: COLORS.lightGray8,
              },
            },
          },
        },
      },
    })
  }
}
