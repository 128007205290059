import { StorageFactorySingleton } from '../storage/StorageFactory'

const keyPrefix = 'debug'

export class DebugModelController {
  _storage: any
  constructor() {
    this._storage = StorageFactorySingleton.get()
  }

  get(key: any) {
    return this._getFromStorage(key)
  }

  set(key: any, value: any) {
    this._setInStorage(key, value)
  }

  _getFromStorage(key: any) {
    return this._storage.get(keyPrefix + '.' + key)
  }

  _setInStorage(key: any, value: any) {
    this._storage.set(keyPrefix + '.' + key, value)
  }
}

export const DebugModelControllerSingleton = new DebugModelController()
