import { IPlayerEvent } from './IPlayerEvent'

export class DebugEvent extends IPlayerEvent {
  _data: any
  _name: any
  constructor(event: any, data: any) {
    super()
    this._name = event
    this._data = data
  }

  get data() {
    return this._data
  }

  get name() {
    return this._name
  }
}
