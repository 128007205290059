enum PlayerError {
  GEO = 0,
  AUTHZ = 1,
  LEMONADE = 2,
  MISSING_LIVE_DATA = 3,
  PARENTAL_CONTROLS = 4,
  UNKNOWN = 5,
  RETRANS = 6,
  EXPIRED = 7,
  SLE_ENDED = 8,
}

enum RestrictionCodes {
  geo = '321',
  blacklist = '345',
  blackout = '338',
  retrans = '354',
}

const RESTRICTION_MAP = {
  [RestrictionCodes.geo]: PlayerError.GEO,
  [RestrictionCodes.blacklist]: PlayerError.AUTHZ,
  [RestrictionCodes.blackout]: PlayerError.AUTHZ,
  [RestrictionCodes.retrans]: PlayerError.RETRANS,
}

const getRestrictionError = (restrictionCode: RestrictionCodes) =>
  RESTRICTION_MAP[restrictionCode] || PlayerError.UNKNOWN

const hasGeoError = (restrictionCode: RestrictionCodes) => restrictionCode === RestrictionCodes.geo

export { PlayerError, RestrictionCodes, getRestrictionError, hasGeoError }
