import { IWhiteLabelBrand, WHITE_LABEL_BRANDS } from '../constants'
import { getBrandName } from './config'

export enum ENV {
  PRODUCTION = 'production',
  STAGE = 'stage',
  ACC = 'acc',
  DEV = 'dev',
}

export const isDevelopmentMode = () => [ENV.STAGE, ENV.DEV].includes(NODE_ENV)

export const isProduction = () => NODE_ENV === ENV.PRODUCTION
/**
 * Get current app brand
 * @return {import('../constants').WhiteLabelBrand} App brand name and analytics data
 */
export const getWhiteLabelBrand = (brand = getBrandName()): IWhiteLabelBrand =>
  WHITE_LABEL_BRANDS[brand]

export const getBrowser = () => {
  const userAgentMappings = {
    Chrome: 'chrome',
    AppleWebkit: 'safari',
    Gecko: 'firefox',
  }
  const mappingsAsEntries = Object.entries(userAgentMappings)
  const agent = window.navigator.userAgent
  for (let i = 0, n = mappingsAsEntries.length; i < n; i++) {
    // @ts-expect-error TS(2488): Type '[string, string] | undefined' must have a '[... Remove this comment to see the full error message
    const [key, result] = mappingsAsEntries[i]
    if (agent.includes(key)) {
      return result
    }
  }
  return userAgentMappings.Chrome
}

export const getCMPIntakeParam = () => {
  const intake = getWhiteLabelBrand()?.cmpName
  return intake ? `&intake=${intake}` : ''
}
