import { Router } from '@lightningjs/sdk'
import { formatDate, isAClip, getBrandName } from '../../../helpers'

import Tile from './Tile'
import { BRAND, ROUTE } from '../../../constants'

export default class Slide extends Tile {
  get titleLogo() {
    return this.data.titleLogo
  }

  get image() {
    return this.data.image
  }

  get whiteBrandLogo() {
    return this.data.whiteBrandLogo
  }

  get tuneIn() {
    return this.data.tuneIn
  }

  get labelBadge() {
    return this.data.labelBadge
  }

  get videoTitle() {
    return this.data.videoTitle
  }

  get secondaryTitle() {
    return this.data.secondaryTitle
  }

  get buttonText() {
    return this.data.cta?.data?.text
  }

  get isLive() {
    return this.data.cta?.data?.isLive
  }

  get destinationType() {
    return this.data.cta?.data?.destinationType
  }

  get destination() {
    return this.data.cta?.data?.destination
  }

  get playlistMachineName() {
    return this.data.cta?.data?.playlistMachineName
  }

  get urlAlias() {
    return this.data.cta?.data?.urlAlias
  }

  get buttonColor() {
    const currRoute = Router.getActiveRoute() as ROUTE
    const isOlyPage = ([ROUTE.olympics, ROUTE.paralympics] as Array<ROUTE>).includes(currRoute)
    const isHomePage = ([ROUTE.home, ROUTE.boot] as Array<ROUTE>).includes(currRoute)
    const isNBCSportsBrand = getBrandName() === BRAND.nbcsports
    const isOlympicsSlide = this?.data?.isOlympics

    return isOlympicsSlide && (isOlyPage || (isNBCSportsBrand && isHomePage))
      ? this.olyButtonColor
      : this.regularButtonColor
  }

  get regularButtonColor() {
    return this.data.gradientStart ? this.data.gradientStart.replace('#', '') : '#B78623'
  }

  get olyButtonColor() {
    return this.data.cta?.data?.color || '#B4A074'
  }

  get isLocked() {
    return this.data.locked
  }

  get showClipData() {
    return isAClip(this.data.programmingType)
  }

  get programmingType() {
    return this.data.programmingType
  }

  get showMovieData() {
    return this.data.programmingType === 'Movie'
  }

  get rating() {
    return this.data.rating
  }

  get date() {
    return formatDate(this.data.airDate)
  }

  get dynamicSmartLeadLogic() {
    return this?.analytics?.dynamicallyGenerated === false
      ? 'None'
      : this?.meta?.dynamicSmartLead?.logic
  }

  get ctaAnalytics() {
    return this?.data?.cta?.analytics
  }

  get isSportsVideoSlide() {
    return this.data.cta?.data?.isSportsVideoSlide
  }

  get sponsorName() {
    return this.data.sponsorName
  }

  get sponsorLogo() {
    return this.data.sponsorLogo
  }

  get sponsorLogoAltText() {
    return this.data.sponsorLogoAltText
  }

  get tertiaryTitle() {
    return this.data.tertiaryTitle
  }

  get description() {
    return this.data.description
  }

  get pid() {
    return this.data.pid || this?.data?.cta?.data?.pid
  }

  get contentType() {
    return this.data.contentType || this?.data?.cta?.data?.contentType
  }

  get isMedalSession() {
    return this.data.isMedalSession
  }

  get isOlympics() {
    return this.data.isOlympics
  }

  get language() {
    return this.data.language
  }
}
