import { Lightning } from '@lightningjs/sdk'
import { Subscription } from 'rxjs'
import { Maybe } from 'graphql/jsutils/Maybe'

import { FLEX_DIRECTION } from '../../../../constants'
import Tab from './Tab'
import TextButton from '../../../buttons/TextButton'
import { EpgGuideController, EpgGuideEvents } from '../../EpgGuideController'
import {
  ComponentFragment,
  LinksSelectableGroupFragment,
} from '../../../../graphql/generated/types'

export default class GuideTabs extends Lightning.Component {
  _subscription?: Subscription
  selectedIndex = 0
  focusIndex = 0
  _items: Array<Maybe<ComponentFragment>> | undefined = []

  static override _template() {
    return {
      w: (w: number) => w,
      h: (h: number) => h,
      Links: {
        flex: {
          direction: FLEX_DIRECTION.row,
          paddingLeft: 16,
        },
        w: (w: number) => w,
      },
    }
  }

  override _init() {
    this._subscription = EpgGuideController.subscribe({
      type: EpgGuideEvents.TAB_DATA,
      handler: this._load,
    })
  }

  override _detach() {
    this._subscription?.unsubscribe()
    this._subscription = undefined
  }

  override _getFocused() {
    return this.currentItem
  }

  get selectedItem() {
    return this.tag('Links').children?.[this.selectedIndex]
  }

  get currentItem() {
    return this.tag('Links').children?.[this.focusIndex] || this
  }

  _load = async ({ data }: LinksSelectableGroupFragment) => {
    const links = this.tag('Links')?.children
    const isAlreadyLoaded =
      links.length &&
      links?.every(
        (item: TextButton, index: number) =>
          item?.label?.toLowerCase() === data.itemLabels?.[index]?.toLowerCase()
      )
    if (!data || isAlreadyLoaded) return
    this.focusIndex = data?.initiallySelected || 0
    this._items = data.items as ComponentFragment[]
    this.tag('Links').children = data.itemLabels.map((item, index) => ({
      type: Tab,
      current: index === this.focusIndex,
      label: item,
    }))
    // this.queueAction()
  }

  selectCurrent(): void {
    this.selectedIndex = this.focusIndex
    EpgGuideController.setActiveTab(this.selectedIndex)
    this.tag('Links').children?.forEach((child: any, index: number) => {
      child.current = index === this.selectedIndex
    })
  }

  getCurrentItem() {
    return this._items ? this._items[this.focusIndex] : null
  }

  changeIndex(delta: 1 | -1) {
    const newIndex = this.focusIndex + delta
    const isInRange = newIndex >= 0 && newIndex < this.tag('Links').children.length
    if (!isInRange) return
    this.focusIndex = newIndex
  }
}
