import { Registry, Language } from '@lightningjs/sdk'
import { LiveBadgeV1 } from './LiveBadgeV1'

import { LiveControlsStateFactory } from './states/LiveControls'

import SLEStrategy from './strategies/SLEStrategy'
import TempPassCTA from './TempPassCTA'
import LiveControlsV1 from './LiveControlsV1'

import { EVENTS } from '../../../lib/analytics/types'
import { sendMetric } from '../../../lib/analytics/Analytics'
import { TEMP_PASS_REDIRECT } from '../../../pages/Player/BasePlayer'
import { PlayerControlsStates, LANGUAGES, ALIGN_SELF } from '../../../constants'
import { KeyMap } from '../../../lib/KeyMap'
import IPlayerControlsStrategy from './strategies/IPlayerControlsStrategy'
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore'
import { TimeChangeEvent } from '../../../player/model/event'
import { ProgressHookEvent } from './hooks/useProgress'
import { activateTempPassRedirect } from '../../../pages/Player/StreamLoader/actions/redirect'
import { useRouterBackDisabled } from '../../../widgets/Modals/activation/hooks/useRouterBackDisabled'
import SeekablePlayerControlsV1 from './SeekablePlayerControlsV1'

const TIMEOUT_HIDE_TEMPPASS_TIMER = 5000

export default class SLEPlayerControls extends SeekablePlayerControlsV1 {
  _tempPassControlsOverlay: any
  _liveControls: any

  override _strategy: IPlayerControlsStrategy = new SLEStrategy(this)
  override _keyMap = KeyMap(this, [
    [
      [PlayerControlsStates.QOSPanel, PlayerControlsStates.MoreInfo],
      [PlayerControlsStates.MediaOptions, PlayerControlsStates.MoreInfo],
      [PlayerControlsStates.TempPass, PlayerControlsStates.QOSPanel],
    ],
    [[], PlayerControlsStates.AdControls, []],
    [[], PlayerControlsStates.LiveControls, []],
    [[], PlayerControlsStates.PlayOrPause, []],
  ])

  static override TITLE_CONTAINER_Y = 650
  static override _template() {
    return super._template({
      PlayerControls: {
        TitleContainer: {
          HeadingContainer: {
            y: 80,
            flex: undefined,
            Title: {
              x: 80,
              y: 80,
            },
          },
          SecondaryTitle: {
            x: Language.get() === LANGUAGES.SPANISH ? 210 : 170,
          },
        },
      },
    })
  }

  override _init() {
    super._init()
    this.tag('PlayerControls.TitleContainer').childList.addAt(
      { ref: 'Live', x: 60, y: 96, type: LiveBadgeV1 },
      1
    )
    this.showLiveControls()
  }

  override get seekingAllowed() {
    return PlayerStoreSingleton.lemonade?.pvrControls || false
  }

  override getTitleContainerY() {
    return SLEPlayerControls.TITLE_CONTAINER_Y
  }

  setTitle(text: string) {
    this._title.patch({
      flexItem: {
        alignSelf: ALIGN_SELF.center,
      },
      text: {
        text,
        fontSize: 28,
      },
    })
  }

  setSecondaryTitle(text: string) {
    this._secondaryTitle.patch({ text: { text, fontSize: 58 } })
  }

  setDescription(text: string) {
    this._description.patch({ y: 185, text: { text } })
  }

  showLiveControls() {
    this.tag('PlayerControls').patch({
      LiveControls: {
        type: LiveControlsV1,
      },
    })
    this._liveControls = this.tag('LiveControls')
  }

  patchTempPassTemplate() {
    this.patch({
      TempPass: {
        type: TempPassCTA,
        alpha: 1,
      },
    })
    this._tempPassControlsOverlay = this.tag('TempPass')
    this.tempPassCtaClicked = false
  }

  createTimeOut() {
    Registry.setTimeout(() => {
      this.fireAncestors('$hideTempPassTimer')
    }, TIMEOUT_HIDE_TEMPPASS_TIMER)
  }

  updateTempPassCountdown(secondsLeft: any) {
    if (secondsLeft <= 30 && this._getState() !== PlayerControlsStates.TempPass) {
      this.fireAncestors('$showTempPassTimer', { pin: true })
    }
    this._tempPassControlsOverlay.update(secondsLeft)
  }

  override _updateProgress([currentTime, duration, seekableRange]: ProgressHookEvent) {
    if (!duration) return
    const totalWidth = this.tag('TotalBar').w
    const lessThan1MinLeft =
      this._getState() !== PlayerControlsStates.Seeking && duration - currentTime <= 60
    const position = lessThan1MinLeft ? totalWidth : (currentTime * totalWidth) / duration
    const seekEnd =
      seekableRange && !lessThan1MinLeft ? (seekableRange?.[1] * totalWidth) / duration : position
    this.tag('Progress').patch({
      CurrentBarBg: { w: seekEnd },
      CurrentBar: { w: position },
      SeekBar: { x: position },
    })
  }

  override _playerSubscriptionHandler(event: any) {
    super._playerSubscriptionHandler(event)
    if (event instanceof TimeChangeEvent) {
      //toggle "back to live" button based on isAtLiveEdge property from player
      this._liveControls.toggleBackToLive(!event._isAtLiveEdge)
    }
  }

  static override _states() {
    return [
      ...super._states(),
      class TempPass extends this {
        override _handleEnter() {
          sendMetric(EVENTS.CLICK, {
            name: 'TempPass - Link TV Provider',
          })

          this.tempPassCtaClicked = true
          useRouterBackDisabled().set(true)
          activateTempPassRedirect(TEMP_PASS_REDIRECT.TEMP_PASS_MVPD)
        }
        override _getFocused() {
          return this.tag('TempPass') || this
        }
      },
      LiveControlsStateFactory(this, 'PlayerControls.LiveControls'),
    ]
  }
}
