import algoliaSearch from 'algoliasearch'

import AppConfigFactorySingleton from '../../config/AppConfigFactory'
import { getMpid } from '../../helpers'
import { mapSearchResults } from './mappers/search'

export default function () {
  const { application_id, api_key, index_name } = AppConfigFactorySingleton.get().config.algolia
  const client = algoliaSearch(application_id, api_key)
  const algoliaIndex = client.initIndex(index_name)
  const mpid = getMpid()
  function doSearch({ query, page }: any) {
    return algoliaIndex
      .search(query, {
        page,
        // $FlowFixMe
        facetFilters: [
          'algoliaProperties.forConnectedTvDevice:true',
          [
            'algoliaProperties.entityType:episodes',
            'algoliaProperties.entityType:fullEventReplays',
            'algoliaProperties.entityType:movies',
            'algoliaProperties.entityType:segments',
            'algoliaProperties.entityType:series',
            'algoliaProperties.entityType:singleLiveEvents',
            'algoliaProperties.entityType:sportsVideos',
            'algoliaProperties.entityType:sportsSeries',
          ],
        ],
        clickAnalytics: true,
        userToken: mpid,
      })
      .then(mapSearchResults)
  }

  return {
    doSearch,
  }
}
