export class IStorage {
  /**
   * Saves a key-value combination in storage.
   * @param key - The key of type String.
   * @param value - The value.
   */
  set(key: any, value: any) {}
  /**
   * Retrieves previously stored data from storage.
   * @param key - The key of type String.
   * @return {any} - Object to be returned.
   */
  get(key: any) {}
  /**
   * Removes a sepcific key from storage.
   * @param key -The key of type String.
   */
  remove(key: any) {}
  /**
   * Removes all data from Storage.
   */
  clear() {}
}
