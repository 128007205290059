import Tiles from '../tiles'
import Tile from './Tile'
import { COMPONENT_TYPES } from '../../../constants'

export default class EndCardAlternate extends Tile {
  _groupTagLine: any
  _isDefault: boolean
  _tile: any
  constructor(obj = {}, groupTagLine = null, isDefault = false) {
    super(obj)
    if (!this.tileComponent) return
    this._groupTagLine = groupTagLine
    this._isDefault = isDefault
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    this._tile = new Tiles[this.tileComponent](this.data.tile)
  }

  get tagLine() {
    return this._groupTagLine || this.data.tagLine || ''
  }

  get tileComponent() {
    return this.data.tile.component || ''
  }

  get tile() {
    return this._tile
  }

  get isDefault() {
    return this._isDefault
  }

  override get component() {
    return this.tileComponent === COMPONENT_TYPES.UPCOMING_LIVE_TILE
      ? COMPONENT_TYPES.END_CARD_ALTERNATE_SLE
      : this.obj.component
  }

  override get analytics() {
    return this?.obj?.analytics || this?.obj?.data?.tile?.analytics || null
  }
}
