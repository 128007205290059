import WatchProgress from './watchProgress/WatchProgress'
import { IWatchProgressFactory } from './IWatchProgressFactory'

/**
 * This creates an instance of the WatchProgress class.
 */
class WatchProgressFactory extends IWatchProgressFactory {
  _watchProgress: any
  constructor() {
    super()
    this._watchProgress = new WatchProgress()
  }

  override get() {
    return this._watchProgress
  }
}

export const WatchProgressFactorySingleton = new WatchProgressFactory()
