import getVODAttributes from './getVodAttributes'
import getLinearAttributes, { getLinearDuration } from './getLinearAttributes'
import { setDefaultValue } from '../../../../helpers/analytics'
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants'

const getAdBreakAttributes = ({
  video = {},
  adBreak = {},
  ccLanguage = '',
  previousVideo = '',
  previousVideoType = '',
  isLive,
}: any) => {
  const shelf = video?.shelf || {}
  const { position } = adBreak
  return setDefaultValue(
    {
      ...(isLive
        ? getLinearAttributes(video)
        : getVODAttributes(video, ccLanguage, previousVideo, previousVideoType)),
      'Video Duration': getLinearDuration(video.duration),
      'Ad Pod Type': isLive
        ? 'midroll'
        : typeof position === 'number'
        ? position === 0
          ? 'preroll'
          : 'midroll'
        : position,
      'Ad Pod Duration': adBreak.duration,
      'Ad Pod Quantity': adBreak.ads?.length,
      Casting: MPARTICLE_DEFAULT_ATTR_VALUE,
      Sponsor: shelf?.sponsorName,
      'Playlist Name': shelf?.playlistMachineName,
      'Playlist Position': shelf?.playlistPosition,
    },
    MPARTICLE_DEFAULT_ATTR_VALUE
  )
}

export default getAdBreakAttributes
