import { PLAYER_TYPE } from '../core/PlayerFactory'
import { PlayerFactorySingleton } from '../core/PlayerFactory'

export class CoreVideoLightningMediaPlayerAdapter {
  get watchId() {
    return ''
  }
  close() {
    PlayerFactorySingleton.player(PLAYER_TYPE.MAIN)?.close()
  }
  _setVideoArea([x, y, w, h]: any[]) {
    PlayerFactorySingleton.player(PLAYER_TYPE.MAIN)?.setVideoSize(x, y, w, h)
  }
}
