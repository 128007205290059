import { getAddMetadata } from '../../api/Api'

const parseAdExtensions = (extensions = []) => {
  const freeWheel = extensions.find(({ type }) => type === 'FreeWheel')

  if (!freeWheel) {
    return {}
  }
  // @ts-expect-error TS(2339): Property 'parameters' does not exist on type 'neve... Remove this comment to see the full error message
  return freeWheel.parameters?.reduce((prev: any, cur: any) => {
    if (cur.name === 'Conviva Ad Insights') {
      return {
        ...prev,
        ...JSON.parse(cur.value),
      }
    }
    if (cur.name === 'hulu_ccr') {
      return {
        ...prev,
        ...cur.value
          ?.split(';')
          .map((kv: any) => kv.split('='))
          .reduce(
            (p: any, c: any) => ({
              ...p,
              [c[0]]: c[1],
            }),
            {}
          ),
      }
    }
    return prev
  }, {})
}

// Normalize ad data for mparticle's getAdAttributes
export const getAdParams = async (ad: any) => {
  if (!ad) {
    return {}
  }
  const extensions = parseAdExtensions(ad.extensions)
  const adMetadata = await getAddMetadata({
    renditionId: extensions.renditionID,
    adId: extensions.id,
    csid: extensions.csid,
  }).catch(() => ({}))

  return {
    ...ad,
    duration: ad.expectedDuration,
    extensions: {
      ...extensions,
      ...adMetadata,
    },
  }
}

// Normalize ad data for mparticle's getAdBreakAttributes
export const getAdPodParams = (adBreak: any) =>
  adBreak
    ? {
        ...adBreak,
        duration: adBreak.expectedDuration,
      }
    : {}
