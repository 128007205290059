import { CoreVideoSdk } from '@sky-uk-ott/core-video-sdk-js'
import CryptoJS from 'crypto-js'
import { isXbox } from '../helpers'
import AppConfigFactorySingleton from '../config/AppConfigFactory'

export const getProxyDrmHmac = (secret: any, drmType: any) => {
  const { isDrmSecurityElevated } = AppConfigFactorySingleton.config.core_video_sdk
  const time = new Date().getTime()
  const value =
    time.toString() +
    drmType.toLowerCase() +
    (isXbox() && !isDrmSecurityElevated ? CoreVideoSdk.deviceInfo.uniqueDeviceId : '')
  const hash = CryptoJS.HmacSHA256(value, secret)
  return { hash, time }
}
