import CryptoJS from 'crypto-js'
import { DocumentNode, print } from 'graphql'

import {
  BonanzaPage,
  BrandTitleCategories,
  ComponentsForPlaceholders,
  EndCardPage,
  FeaturedSection,
  FeaturedShowsSection,
  GlobalNavigation,
  LdStateflag,
  MixedEditorialShelf,
  OnAirNowShelfPage,
  PaginatedComponent,
  PaginatedShelf,
  ScrollFromSeason,
  UserInteractions,
  VideosSectionPage,
} from '../../graphql/generated/types'
import { featureTransformer } from '../transformers/feature'

export enum QueryName {
  scrollFromSeason = 'scrollFromSeason',
  brandTitleCategories = 'brandTitleCategories',
  componentsForPlaceholders = 'componentsForPlaceholders',
  endCard = 'endCard',
  featuredSection = 'featuredSection',
  ldStateFlag = 'ldStateFlag',
  bonanzaPage = 'bonanzaPage',
  globalNavigation = 'globalNavigation',
  mixedEditorialShelf = 'mixedEditorialShelf',
  onAirNowShelf = 'onAirNowShelf',
  paginatedComponent = 'paginatedComponent',
  paginatedShelf = 'paginatedShelf',
  featuredShowsSection = 'featuredShowsSection',
  userInteractions = 'userInteractions',
  videosSection = 'videosSection',
}

const BffScheme: Partial<{ [key in QueryName]: DocumentNode }> = {
  [QueryName.scrollFromSeason]: ScrollFromSeason,
  [QueryName.brandTitleCategories]: BrandTitleCategories,
  [QueryName.componentsForPlaceholders]: ComponentsForPlaceholders,
  [QueryName.endCard]: EndCardPage,
  [QueryName.featuredSection]: FeaturedSection,
  [QueryName.bonanzaPage]: BonanzaPage,
  [QueryName.globalNavigation]: GlobalNavigation,
  [QueryName.mixedEditorialShelf]: MixedEditorialShelf,
  [QueryName.onAirNowShelf]: OnAirNowShelfPage,
  [QueryName.paginatedComponent]: PaginatedComponent,
  [QueryName.paginatedShelf]: PaginatedShelf,
  [QueryName.featuredShowsSection]: FeaturedShowsSection,
  [QueryName.userInteractions]: UserInteractions,
  [QueryName.videosSection]: VideosSectionPage,
  [QueryName.ldStateFlag]: LdStateflag,
}

export const getQuerySchema = (queryName: QueryName): string => {
  return print(BffScheme[queryName] as DocumentNode)
}

const hashMap = new Map<QueryName, string>()

export const getHash = (query: QueryName): string | undefined => {
  const querySchema = getQuerySchema(query)
  if (hashMap.has(query)) return hashMap.get(query)

  if (querySchema) {
    const hash = CryptoJS.SHA256(querySchema).toString()
    return hash
  }
}

export const storeHash = (query: QueryName, hash?: string) => {
  if (hash) hashMap.set(query, hash)
}

export const syncQueries = (override?: boolean) => {
  // Refresh queries
  BffScheme[QueryName.featuredSection] = featureTransformer(FeaturedSection, override)
  BffScheme[QueryName.bonanzaPage] = featureTransformer(BonanzaPage, override)
  BffScheme[QueryName.paginatedShelf] = featureTransformer(PaginatedShelf, override)
  BffScheme[QueryName.endCard] = featureTransformer(EndCardPage, override)
  BffScheme[QueryName.featuredShowsSection] = featureTransformer(FeaturedShowsSection, override)
  BffScheme[QueryName.brandTitleCategories] = featureTransformer(BrandTitleCategories, override)
  BffScheme[QueryName.paginatedComponent] = featureTransformer(PaginatedComponent, override)
  BffScheme[QueryName.componentsForPlaceholders] = ComponentsForPlaceholders
  hashMap.clear()
}
