import Variables from '../../graphql/Variables'
import { EntityPageType } from '../../graphql/generated/types'
import { createRequestConfig } from '../../lib/useRequest'
import { featuredRequest, mainRequest } from '../../api/requests'

export const ShowHomeRequestConfig = (urlAlias: string) =>
  createRequestConfig({
    query: mainRequest,
    variables: Variables.showPageVariables(EntityPageType.TitleV2, urlAlias),
    reject: [(data) => !data?.analytics || !data?.metadata || !data?.sections],
  })

export const ShowHomeFeaturedRequestConfig = (urlAlias: string) =>
  createRequestConfig({
    query: featuredRequest,
    variables: Variables.showPageVariables(EntityPageType.TitleV2, urlAlias),
    reject: [(data) => !data],
  })
