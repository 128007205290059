import { GqlFetchResult } from '../client/types'
import { FeaturedSectionQuery, FeaturedSectionShelfFragment } from '../generated/types'

const featuredSectionMapper = (page: GqlFetchResult<FeaturedSectionQuery>) => {
  const data = page.data?.featuredSection as FeaturedSectionShelfFragment
  if (!data) return null
  return data
}

export default featuredSectionMapper
