import mParticle from '@mparticle/web-sdk'
import getShelfImpressionAttributes from '../attributes/getShelfImpressionAttributes'

const trackShelfImpression = () => ({
  getAttributes: getShelfImpressionAttributes,
  getCategory: () => mParticle?.EventType?.Navigation,
  type: 'Shelf Impression',
  injectGlobals: true,
})

export default trackShelfImpression
