import { BehaviorSubject, Subject, Subscription } from 'rxjs'
import cloneDeep from 'lodash/cloneDeep'

export type Hook<T = any> = {
  set(value: T): void
  value: T
  unsubscribe(destroySubject?: boolean): void
}

export type StatelessHook<T = any> = {
  set(value: T): void
  unsubscribe(): void
}

export const createStatelessHook = <T>() => {
  const createObservable = () => new Subject<T>()
  let _subject: Subject<T> | null = null

  return (cb?: (value: T) => void): StatelessHook<T> => {
    let _subscription: Subscription | null

    if (!_subject) {
      _subject = createObservable()
    }
    if (cb) {
      _subscription = _subject.subscribe(cb)
    }
    return {
      set(value: T) {
        if (_subject) {
          _subject.next(value)
        } else {
          _subject = createObservable()
        }
      },
      unsubscribe() {
        if (_subscription) {
          _subscription.unsubscribe()
          _subscription = null
        }
        if (!_subject?.observed) {
          _subject = null
        }
      },
    }
  }
}

export const createHook = <T>(defaultValue: T) => {
  const createObservable = () => new BehaviorSubject<T>(cloneDeep(defaultValue))
  let _subject: BehaviorSubject<T> | null = null

  return (cb?: (value: T) => void): Hook<T> => {
    let _subscription: Subscription | null

    if (!_subject) {
      _subject = createObservable()
    }
    if (cb) {
      _subscription = _subject.subscribe(cb)
    }
    return {
      get value(): T {
        return _subject?.value || defaultValue
      },
      set(value: T) {
        if (_subject) {
          _subject.next(value)
        } else {
          _subject = createObservable()
        }
      },
      unsubscribe(destroySubject = true) {
        if (_subscription) {
          _subscription.unsubscribe()
          _subscription = null
        }
        if (!_subject?.observed && destroySubject) {
          _subject = null
        }
      },
    }
  }
}

type HookGetter<T> = () => Hook<T>

export const createHookEvent = <U, T = any>(
  hook: HookGetter<T>,
  setter: (reference: Hook<T>) => (data: U) => T
): ((data: U) => T) => {
  return setter(hook())
}
