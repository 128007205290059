import Collection from './Collection'
import Tiles from '../tiles'

export default class EndTiles extends Collection {
  constructor(obj = {}) {
    super(obj)
    this.obj.items = []
    if (this.obj.tileOne) {
      this.obj.items.push(
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        new Tiles[this.obj.tileOne?.component](this.obj.tileOne, this.obj.groupTagLine, true)
      )
    }
    if (this.obj.tileTwo) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      this.obj.items.push(new Tiles[this.obj.tileTwo?.component](this.obj.tileTwo))
    }
  }
}
