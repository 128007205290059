import Item from '../../../items/Item'
import { COLORS, FONT_FACE, VERTICAL_ALIGN } from '../../../../constants'
import { Lightning } from '@lightningjs/sdk'
import { setSmooth } from '../../../../helpers'

const extraMargin = 158

enum Visibility {
  INVISIBLE,
  COMPACTED,
}

const VisibilityWidthMap = {
  [Visibility.COMPACTED]: 154,
  [Visibility.INVISIBLE]: 0,
} as const

const isVisibility = (input: any): input is Visibility => typeof input === 'number'

export default class GuideTimeSlotHolder extends Item {
  _slots: string[]
  itemHeight: number
  itemPadding: number
  itemWidth: number
  programImageWidth: number

  _page = 0

  static override _template() {
    const template = super._template()
    return {
      ...template,
      x: -extraMargin,
      w: 1840,
      h: 212,
      clipping: true,
      Slots: {
        boundsMargin: [500, 100, 500, 100],
      },
    }
  }

  // #region Lifecycle
  override _init() {
    this._renderInitialSlots()
  }
  // #endregion

  // #region Getters/setters
  set index(index: number) {
    this._renderPage(index)
  }

  set slots(v: string[]) {
    this._slots = (v || []).map((slot) => slot.toUpperCase())
    this._renderPage(0)
  }
  // #endregion

  _renderInitialSlots = () => {
    this.children = Array(4)
      .fill(null)
      .map((_, index) => ({
        rect: true,
        w: 200,
        x: 200 * index,
        h: 55,
        color: COLORS.dark7,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 6,
        },
        Text: {
          y: 2,
          text: {
            text: '',
            fontFace: FONT_FACE.light,
            fontSize: 30,
            lineHeight: 38,
            verticalAlign: VERTICAL_ALIGN.middle,
          },
        },
      }))
  }

  _getSlots = (startIndex: number) => {
    const slotsPerPage = 3
    const endIndex = startIndex + slotsPerPage
    const slots = this._slots.slice(startIndex, endIndex)
    return startIndex === 0 ? [...slots, Visibility.INVISIBLE] : [Visibility.COMPACTED, ...slots]
  }

  _getWidth = (slot: number, index: number) => {
    return slot === 0 ? (index > 0 ? this.itemWidth : this.itemWidth + extraMargin) : this.itemWidth
  }

  _renderPage = (slotIndex: number) => {
    let x = 0
    const slots = this._getSlots(slotIndex)

    this.children.forEach((child: Lightning.Element, i) => {
      const current = slots[i] || Visibility.INVISIBLE
      const width = isVisibility(current)
        ? VisibilityWidthMap[current]
        : this._getWidth(slotIndex, i)
      setSmooth(child, 'w', width)
      setSmooth(child, 'x', x)
      child.tag('Text').patch({
        x: slotIndex === 0 && i === 0 ? 39 : 30,
        text: {
          text: typeof current === 'string' ? current : '',
        },
      })
      x += width + 3
    })
  }
}
