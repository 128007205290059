import AppConfigFactorySingleton from '../../config/AppConfigFactory'
import { DebugControllerSingleton } from '../../util/debug/DebugController'
import { CONTENT_TYPE, WEB_PLATFORM } from '../../constants'
import { getBrowser } from '../../helpers'
import TvPlatform from '../tv-platform'
import TVPlatform from '../tv-platform'
import { ErrorType } from '../tv-platform/types'

const LEMONADE_TAG = 'Lemonade'

export type PlaybackUrls = {
  url: string
  cdn: string
}

export type LemonadeResponse = {
  type?: string
  pid?: number
  status?: string
  resourceId?: string
  playbackUrl: string
  playbackUrls: Array<PlaybackUrls>
  adCompatibilityEncodingProfile?: string
  adServerContentId?: string
  adInstructions?: {
    dai_PreRollEnabled?: boolean
    dai_MidRollEnabled?: boolean
  }
  offerType?: string
  protectionKey?: string
  keyType?: string
  pvrControls?: boolean
}

export type LemonadeError = {
  errors: {
    [key: string]: any
    message?: string
  }
}

export const fetchLemonadeData = async (
  sessionItem: any
): Promise<LemonadeResponse | LemonadeError> => {
  const headers = new Headers({
    Accept: 'application/json',
  })
  const { contentType } = sessionItem ?? {}
  if (contentType === CONTENT_TYPE.PLAYMAKER) {
    return sessionItem.streamUrl
  }
  const url = getLemonadeUrl(sessionItem)
  try {
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const response = await fetch(url, { headers })
    if (!response.ok) {
      return {
        errors: Error(`Request failed with status ${response.status}: ${response.statusText}`),
      }
    }
    return await response.json()
  } catch (errors: any) {
    TVPlatform.reportError({
      type: ErrorType.NETWORK,
      code: LEMONADE_TAG,
      description: 'Error reporting to lemonade',
      payload: errors,
    })
    return { errors }
  }
}

const getLemonadeUrl = (sessionItem: any) => {
  const { host, endpoint } = AppConfigFactorySingleton?.config?.lemonade || {}
  if (!host || !endpoint) {
    TVPlatform.reportError({
      type: ErrorType.NETWORK,
      code: LEMONADE_TAG,
      description: 'Error generating lemonade url. Host or endpoint not found',
      payload: {
        host,
        endpoint,
      },
    })
    return ''
  }
  const { pid, stationId, mpxGuid, mpxAccountId, programmingType } = sessionItem ?? {}
  const platform = DebugControllerSingleton.videoProtocol
    ? WEB_PLATFORM
    : TvPlatform.lemonadePlatform
  if (pid) {
    return `${host}${endpoint.sle}${pid}?platform=${platform}`
  } else if (stationId) {
    return `${host}${endpoint.linear}${stationId}?platform=${platform}`
  } else if (mpxGuid) {
    return `${host}${
      endpoint.vod
    }${mpxAccountId}/${mpxGuid}?programmingType=${programmingType}&platform=${platform}${
      platform === WEB_PLATFORM ? `&browser=${getBrowser()}` : ''
    }`
  }
}
