import { Language } from '@lightningjs/sdk'

import UpcomingModal from '../../models/UpcomingModal'
import {
  getAirDate,
  getDateFromUnixTimestamp,
  getFERDate,
  getUpcomingBadge,
  isLive,
  isUpcoming,
  MODAL,
  PROGRAMMING_TYPE,
  showLabelBadge,
} from '../helpers'
import { ALGOLIA_ENTITY_TYPES, formatDate } from '../../../helpers'
import { CONTENT_TYPE } from '../../../constants'

const getEventData = (searchResult: any) => {
  const { algoliaProperties, brand = {}, event = {}, league = {}, sport = {} } = searchResult
  const { entityType }: { entityType: keyof (typeof CONTENT_TYPE | typeof PROGRAMMING_TYPE) } =
    algoliaProperties

  const {
    countdownDayLabel,
    countdownHourLabel,
    countdownMinutesLabel,
    title,
    dismissText,
    pid,
    expirationDate,
    visibilityDate,
    labelBadge,
    locked,
  } = event
  const startDate = getDateFromUnixTimestamp(event?.startDate)
  const endDate = getDateFromUnixTimestamp(event?.endDate)
  const customerPlayableDate = getDateFromUnixTimestamp(event?.customerPlayableDate)
  const isUpcomingValue = isUpcoming(startDate)
  const { staticPreviewImage, channelId, machineName, displayTitle } = brand

  return {
    ...event,
    startDate: startDate,
    endDate: endDate,
    visibilityDate: getDateFromUnixTimestamp(visibilityDate),
    expirationDate: getDateFromUnixTimestamp(expirationDate),
    customerPlayableDate: customerPlayableDate,
    league: { ...league },
    sport: { ...sport },
    brand: { ...brand },
    labelBadge:
      entityType === ALGOLIA_ENTITY_TYPES.SLE
        ? isUpcomingValue
          ? getUpcomingBadge(startDate)
          : ''
        : showLabelBadge(endDate.toDateString())
        ? labelBadge
        : '',
    isLive: isLive(startDate, endDate),
    isUpcoming: isUpcomingValue,
    locked: entityType === ALGOLIA_ENTITY_TYPES.REPLAYS ? locked : false,
    tertiaryTitle:
      entityType === ALGOLIA_ENTITY_TYPES.REPLAYS ? getFERDate(startDate, endDate) : '',
    upcomingModal: new UpcomingModal(
      {
        analytics: {
          modalType: `${PROGRAMMING_TYPE[entityType]} ${MODAL}`,
          programTitle: title,
          modalName: Language.translate('continueToLive'),
          dismissText: dismissText,
          brand: {
            title: displayTitle,
          },
        },
        data: {
          backgroundImage: staticPreviewImage?.path,
          channelId: channelId,
          contentType: CONTENT_TYPE[entityType],
          countdownDayLabel: countdownDayLabel,
          countdownHourLabel: countdownHourLabel,
          countdownMinLabel: countdownMinutesLabel,
          customerPlayableDate: customerPlayableDate,
          ctaText: Language.translate('continueToLive'),
          description: `${title} ${Language.translate('willBegin')}`,
          dismissText: dismissText,
          lastMinuteModalLifespan: 5,
          machineName: machineName,
          pid: pid,
          resourceId: channelId,
          startTime: startDate,
          title: league?.title || sport?.title,
        },
      },
      false
    ),
  }
}

const getSFVodData = (searchResult: any) => {
  const { episegment = {}, sport = {}, league = {}, video = {}, series = {} } = searchResult
  return {
    subTitle: episegment.title,
    league,
    sport,
    series,
    brand: video.brand,
    video,
    image: video.keyframes,
    tertiaryTitle: getAirDate(video.airDate || episegment.airDate),
    description: video.description || episegment.longDescription,
    programmingType: episegment.programmingType,
  }
}

const mapSearchResult = (searchResult: any) => {
  const entityType = searchResult.algoliaProperties.entityType
  let data

  switch (entityType) {
    case ALGOLIA_ENTITY_TYPES.MOVIES:
      data = {
        ...searchResult.movie,
        image: { ...searchResult.movie.keyArt },
        brand: { ...searchResult.movie.brands[0] },
      }
      break
    case ALGOLIA_ENTITY_TYPES.SEGMENTS:
    case ALGOLIA_ENTITY_TYPES.EPISODES: {
      const str = []
      if (searchResult.season?.seasonNumber && searchResult.episegment?.episodeNumber) {
        str.push(`S${searchResult.season.seasonNumber} E${searchResult.episegment?.episodeNumber}`)
      }
      if (searchResult.episegment?.airDate) {
        str.push(formatDate(searchResult.episegment.airDate))
      }
      data = {
        ...searchResult.episegment,
        series: { ...searchResult.series },
        season: { ...searchResult.season },
        image: { ...searchResult.video.keyframes },
        brand: { ...searchResult.video.brand },
        tertiaryTitle: str.join(' | '),
      }
      break
    }
    case ALGOLIA_ENTITY_TYPES.SERIES:
    case ALGOLIA_ENTITY_TYPES.SPORTS_SERIES:
      data = {
        ...searchResult.series,
        image: { ...searchResult.series.keyArt },
        brand: { ...searchResult.series.brands[0] },
      }
      break
    case ALGOLIA_ENTITY_TYPES.REPLAYS:
    case ALGOLIA_ENTITY_TYPES.SLE:
      data = getEventData(searchResult)
      break
    case ALGOLIA_ENTITY_TYPES.SPORTS_VIDEOS:
      data = getSFVodData(searchResult)
      break
  }

  return {
    algoliaProperties: searchResult.algoliaProperties,
    video: searchResult.video,
    data,
  }
}

export const mapSearchResults = (searchResults: any) => {
  return {
    nbHits: searchResults.nbHits,
    nbPages: searchResults.nbPages,
    page: searchResults.page,
    hits: searchResults.hits.map(mapSearchResult),
  }
}
