import { Language } from '@lightningjs/sdk'

import { ROUTE } from '../constants'
import { useRequest, OKResponse } from '../lib/useRequest'
import { globalNavigationRequest } from '../api/requests'
import Variables from '../graphql/Variables'

class GlobalNavigation {
  _consumer: any
  _items: any
  _initiallySelected = 0

  consumer(consumer: any) {
    this._consumer = consumer
  }

  get items() {
    return this._items
  }

  set items(v: any) {
    this._items = v
    this._consumer.items = this._items
  }

  set initiallySelected(v: any) {
    this._initiallySelected = v
    this._consumer.itemsBar.initiallySelected = v
  }

  async load() {
    try {
      const data = await useRequest({
        query: globalNavigationRequest,
        variables: Variables.getGlobalNavigationVariables(),
        reject: [(data) => !data || !data.items.length],
      }).fetch()
      const index = data?.initiallySelected
      const indexChanged = typeof index === 'number' && index !== this._initiallySelected
      if (indexChanged) this.initiallySelected = index
      this._bffNavigationAdapter(data)
    } catch (e) {
      this._setDefaultNavItems()
    }
  }

  _setDefaultNavItems = () => {
    this.items = [
      {
        label: Language.translate('home'),
        route: ROUTE.home,
        ariaLabel: `${Language.translate('home')}, Page`,
      },
      {
        label: Language.translate('shows_movies'),
        route: ROUTE.shows,
        ariaLabel: `${Language.translate('shows_movies')}, Page`,
      },
      {
        label: Language.translate('sports'),
        route: ROUTE.sports,
        ariaLabel: `${Language.translate('sports')}, Page`,
      },
      {
        label: Language.translate('live'),
        route: ROUTE.live,
        ariaLabel: `${Language.translate('live')}, Page`,
      },
      {
        label: Language.translate('networks'),
        route: ROUTE.networks,
        ariaLabel: `${Language.translate('networks')}, Page`,
      },
      {
        label: Language.translate('more'),
        route: ROUTE.settings,
        ariaLabel: `${Language.translate('more')}, Page`,
      },
      {
        icon: 'search.png',
        active: 'search-active.png',
        route: ROUTE.search,
        ariaLabel: `${Language.translate('search')}, Page`,
      },
    ]
  }

  _bffNavigationAdapter(data: OKResponse<typeof globalNavigationRequest>) {
    this.items = data?.items.map((response) => {
      if (!response || !('data' in response)) return
      const { title, defaultLogo, focusedLogo, selectedLogo, destination, ariaLabel } =
        response.data
      return {
        icon: defaultLogo,
        active: selectedLogo,
        focused: focusedLogo,
        route: destination,
        label: title,
        ariaLabel,
      }
    })
  }
}

const GlobalNavigationSingleton = new GlobalNavigation()

export default GlobalNavigationSingleton
