import { Language, Lightning } from '@lightningjs/sdk'
import { Subscription } from 'rxjs'

import { SectionsSpawner } from '../api/spawners'
import TransparentShader from '../shaders/TransparentShader'

import {
  COLORS,
  END_CARD_TYPES,
  FONT_FACE,
  PROGRAMMING_TYPES,
  SCREEN_SIZE,
  TEXT_ALIGN,
  VERTICAL_ALIGN,
} from '../constants'
import { FastImg, getEndCardAnalytics } from '../helpers'
import { RequestReturnType, useRequest } from '../lib/useRequest'
import { EndCardConfig, EndCardSleConfig } from '../helpers/request'
import TextButton from './buttons/TextButton'

type ValueOf<T> = T[keyof T]
const getEndCardTypeFromProgrammingType = (
  type: PROGRAMMING_TYPES
): ValueOf<typeof END_CARD_TYPES> =>
  type in END_CARD_TYPES
    ? END_CARD_TYPES[type as keyof typeof END_CARD_TYPES]
    : END_CARD_TYPES.DEFAULT

export default class EndCard extends Lightning.Component {
  _endCardsData: any
  _subscription?: Subscription
  _useBlankEndcard = false
  videoConcludedText = Language.translate('video_concluded_endcard')
  videoConcludedTextSLE = Language.translate('video_concluded_endcard_sle')
  videoConcludedCTASLE = Language.translate('video_concluded_endcard_sle_cta')

  static override _template() {
    return {
      ImageHolder: {
        Image: {},
        Gradient: {
          w: SCREEN_SIZE.width,
          h: SCREEN_SIZE.height,
          rect: true,
          colorLeft: this.bindProp('colorLeft'),
          colorRight: this.bindProp('colorRight'),
        },
      },
      TransparentRect: {
        x: 101,
        y: 90,
        rect: true,
        color: COLORS.transparent,
        w: 419,
        h: 235,
        alpha: 0,
      },
      TransparentBGRect: {
        x: 0,
        y: 0,
        rect: true,
        colorTop: COLORS.transparent,
        colorBottom: COLORS.black,
        w: 1920,
        h: 1080,
        alpha: 0,
      },
      SFVOD_BGRect: {
        x: 0,
        y: 0,
        rect: true,
        colorTop: COLORS.dark,
        colorBottom: COLORS.black,
        w: 1920,
        h: 1080,
        alpha: 0,
        Description: {
          alpha: 1,
          x: 960,
          y: 540,
          mount: 0.5,
          text: {
            textColor: COLORS.white,
            verticalAlign: VERTICAL_ALIGN.middle,
            textAlign: TEXT_ALIGN.center,
            fontSize: 40,
            fontFace: FONT_FACE.light,
            color: COLORS.white,
            text: this.bindProp('videoConcludedText'),
          },
        },
      },
      EMPTY_SLE_BGRect: {
        x: 0,
        y: 0,
        rect: true,
        colorTop: COLORS.dark,
        colorBottom: COLORS.black,
        w: 1920,
        h: 1080,
        alpha: 0,
        Description: {
          alpha: 1,
          x: 960,
          y: 540,
          mount: 0.5,
          text: {
            textColor: COLORS.white,
            verticalAlign: VERTICAL_ALIGN.middle,
            textAlign: TEXT_ALIGN.center,
            lineHeight: 60,
            fontSize: 40,
            fontFace: FONT_FACE.light,
            color: COLORS.white,
            text: this.bindProp('videoConcludedTextSLE'),
          },
        },
        ButtonHolder: {
          w: 260,
          h: 60,
          x: 960,
          y: 640,
          mount: 0.5,
          ButtonPrimary: {
            h: 58,
            type: TextButton,
            radius: 29,
            fontSize: 28,
            fontFace: FONT_FACE.regular,
            focusFontColor: COLORS.black,
            unfocusFontColor: COLORS.black,
            focusBackGroundColor: COLORS.white,
            unfocusBackgroundColor: COLORS.white,
            strokeWidth: 1,
            strokeColor: COLORS.white,
            autoWidth: true,
            padding: 22,
            label: this.bindProp('videoConcludedCTASLE'),
          },
        },
      },
      EndCards: {},
    }
  }

  override _detach() {
    this._subscription?.unsubscribe()
    this._subscription = undefined
  }

  override _init() {
    super._init()
    this.videoConcludedText = Language.translate('video_concluded_endcard')
    this.videoConcludedTextSLE = Language.translate('video_concluded_endcard_sle')
    this.videoConcludedCTASLE = Language.translate('video_concluded_endcard_sle_cta')
  }

  async queryEndCards(videoId: any, programmingType: PROGRAMMING_TYPES) {
    const type = getEndCardTypeFromProgrammingType(programmingType)
    return useRequest(EndCardConfig(type as any, videoId))
      .fetch()
      .then(this._handleResponse)
  }

  async queryEndCardsSLE(videoId: any, programmingType: PROGRAMMING_TYPES, source: string) {
    const type = getEndCardTypeFromProgrammingType(programmingType)
    return useRequest(EndCardSleConfig(type as any, videoId, source))
      .fetch()
      .then(this._handleResponse)
  }

  /**
   * Handle EndCardRequest response
   * @param data EndCardRequest response
   * @returns true if end cards are available
   */
  _handleResponse = async (
    data: RequestReturnType<ReturnType<typeof EndCardConfig>>
  ): Promise<boolean> => {
    try {
      if (!data?.endCardData) return false
      const sections = await SectionsSpawner(this.stage, [data?.endCardData] as any)
      if (sections) {
        this.endCardsData = sections[0]
        return true
      }
    } catch {
      // fail silently
    }
    return false
  }

  set endCardsData(v) {
    if (v) {
      this._endCardsData = v.items
      v.x = v.noOfItems === 2 ? 900 : 1380
      v.y = 640
      this.patch({
        EndCards: v,
      })
    } else {
      this.patch({ EndCards: null })
    }
  }

  get endCardsData() {
    return this._endCardsData
  }

  getAnalyticsData() {
    if (this._endCardsData === undefined) return

    return {
      recommendation: getEndCardAnalytics(this._endCardsData[0]),
      recommendationAlternate1: getEndCardAnalytics(this._endCardsData[1]),
    }
  }

  _reset() {
    this._setState('')
    this._endCardsData = null
  }

  set transparent(v: any) {
    this.patch({
      TransparentRect: {
        alpha: v ? 1 : 0,
        shader: v ? { type: TransparentShader } : null,
      },
    })
  }

  set transparentBG(v: any) {
    this.patch({
      TransparentBGRect: {
        alpha: v ? 1 : 0,
        shader: v ? { type: TransparentShader } : null,
      },
    })
  }

  set SFVOD_BG(v: any) {
    this.patch({
      SFVOD_BGRect: {
        alpha: v ? 1 : 0,
      },
    })
  }

  set EMPTY_SLE_BG(v: any) {
    this.patch({
      EMPTY_SLE_BGRect: {
        alpha: v ? 1 : 0,
      },
    })
  }

  set SHOW_HIDE_TILES(shouldShow: any) {
    this.patch({
      EndCards: {
        alpha: shouldShow ? 1 : 0,
      },
    })
  }

  override _focus() {
    this._setState('EndCards')
  }

  static override _states() {
    return [
      class EndCards extends this {
        override $enter() {
          this._setImage(0)
        }

        override $exit() {
          this.tag('ImageHolder').patch({
            Image: {
              texture: null,
            },
          })
        }

        override _getFocused() {
          return this.tag('EndCards') || this
        }

        $scrolledListItemIndex({ index }: any) {
          this._setImage(index)
        }

        _setImage(index: any) {
          const item = this.tag('EndCards.Items')?.children?.[index]?.item?.tile
          if (item) {
            // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'number'.
            this.colorLeft = `0xE6${item.gradientStart}`
            // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'number'.
            this.colorRight = `0xE6${item.gradientEnd}`
            this.tag('ImageHolder').patch({
              Image: {
                texture: FastImg(item.image).contain(SCREEN_SIZE.width, SCREEN_SIZE.height),
              },
            })
          }
        }
      },
    ]
  }
}
