export default {
  grid: 'Grid',
  seriesTile: 'SeriesTile',
  videoTile: 'VideoTile',
  replayTile: 'ReplayTile',
  smartTile: 'SmartTile',
  slideShow: 'Slideshow',
  brandTile: 'BrandTile',
  upcomingLiveTile: 'UpcomingLiveTile',
  //FIXME: need to implement the support
  //potentialShelf: 'PotentialShelf',
  shelf: 'Shelf',
  lazyShelf: 'LazyShelf',
  lazyOnAirShelf: 'LazyOnAirNowShelf',
  onAirShelf: 'OnAirNowShelf',
  movieTile: 'MovieTile',
  slideTile: 'SlideTile',
  upcomingLiveSlideTile: 'UpcomingLiveSlideTile',
  onAirTile: 'OnAirNowTile',
  //FIXME: need to implement the support
  //featureTile: 'FeatureTile',
  //FIXME: need to implement the support
  //playlistTile: 'PlaylistTile',
  brandItemLabel: 'BrandItemLabel',
  linksSelectableGroup: 'LinksSelectableGroup',
  lazyLinksSelectableGroup: 'LazyLinksSelectableGroup',
  eventTile: 'eventTile',
  groupedContinuousScroll: 'GroupedContinuousScroll',
  stack: 'Stack',
  nestedLinkSelectableGroup: 'NestedLinksSelectableGroup',
  placeHolder: 'Placeholder',
}
