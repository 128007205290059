import ShimmerLoader, { Block } from '../../../items/ShimmerLoader'

export default class GuideShimmerLoader extends ShimmerLoader {
  override _setup() {
    super._setup()
    const headerHeight = 55
    const rowHeight = 190
    const module1Width = 558
    const header: Block[] = [
      { w: 720, h: headerHeight },
      { w: module1Width, h: headerHeight },
      { w: module1Width, h: headerHeight },
    ]
    const channelBlock: Block = { w: 154, h: rowHeight }
    const rowVariation1: Block[] = [
      channelBlock,
      { w: 564, h: rowHeight },
      { w: 1118, h: rowHeight },
    ]
    const rowVariation2: Block[] = [
      channelBlock,
      { w: 1124, h: rowHeight },
      { w: module1Width, h: rowHeight },
    ]

    this.item = [header, rowVariation1, rowVariation1, rowVariation2, rowVariation2]
  }
}
