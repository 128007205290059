import { COLORS } from '../../../constants'

import OlympicMedalBadge from '../../badges/OlympicMedalBadge'

export default (marginTop: number) => {
  return (item: any) => {
    if (item._item?.isMedalSession) {
      item.olympicsMedalContainer.patch({
        MedalHolder: {
          flexItem: { marginLeft: 15, marginTop },
          type: OlympicMedalBadge,
          color: COLORS.transparent,
        },
      })
    }
  }
}
