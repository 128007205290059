import { getIsOlympics, setDefaultValue } from '../../../../helpers'
import { MPARTICLE_DEFAULT_ATTR_VALUE, OLYMPICS_FALLBACK_VALUE } from '../../../../constants'
import { IMarketingModuleAnalytics } from '../../../../api/types/bffTypes'

interface IMarketingModuleImpressionParams extends IMarketingModuleAnalytics {
  customShelfPosition: number
  itemClickedName: string
  itemClickedType: string
  destinationType: string
  series?: string
  league?: string
}

const getMarketingModuleImpressionAttributes = (params: IMarketingModuleImpressionParams) => {
  const {
    customShelfPosition,
    listTitle,
    sponsorName,
    itemClickedName,
    itemClickedType,
    destinationType,
    series,
    league,
  } = params

  const isOlympics = getIsOlympics(params)
  return setDefaultValue(
    {
      Brand: MPARTICLE_DEFAULT_ATTR_VALUE,
      Sponsor: sponsorName,
      Show: MPARTICLE_DEFAULT_ATTR_VALUE,
      'Content Position': 1,
      'Custom Shelf Title': 'Marketing Module',
      'Custom Shelf Position': customShelfPosition,
      'Destination Type': destinationType || MPARTICLE_DEFAULT_ATTR_VALUE,
      'Destination URL': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Notification Title': listTitle,
      'Item Clicked Brand': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Item Clicked Entitlement': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Item Clicked League': isOlympics
        ? OLYMPICS_FALLBACK_VALUE
        : league || MPARTICLE_DEFAULT_ATTR_VALUE,
      'Item Clicked Name': itemClickedName || MPARTICLE_DEFAULT_ATTR_VALUE,
      'Item Clicked Season': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Item Clicked Show': isOlympics
        ? OLYMPICS_FALLBACK_VALUE
        : series || MPARTICLE_DEFAULT_ATTR_VALUE,
      'Item Clicked Sport': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Item Clicked State': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Item Clicked Type': itemClickedType || MPARTICLE_DEFAULT_ATTR_VALUE,
      'Item Clicked Video ID': MPARTICLE_DEFAULT_ATTR_VALUE,
    },
    MPARTICLE_DEFAULT_ATTR_VALUE
  )
}

export default getMarketingModuleImpressionAttributes
