import LaunchDarklyFeatureFlags from '../../lib/launchDarkly/LaunchDarklyFeatureFlags'
import LaunchDarklySingleton from '../../lib/launchDarkly/LaunchDarkly'
import { EndCardAlternate } from '../index'
import { END_CARD_SLE_AUTOPLAY_TIME, BASE_FPS, CONTENT_TYPE } from '../../constants'

export default class EndCardAlternateSLE extends EndCardAlternate {
  override _isSLE = true
  override _countdownInterval =
    LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.sleBingeTimer) ||
    END_CARD_SLE_AUTOPLAY_TIME
  override _fps = BASE_FPS / this._countdownInterval
  override _animEndDuration = this._countdownInterval * this._fps

  static override _template() {
    const template = super._template()

    return {
      ...template,
      mount: 0.5,
    }
  }

  override _focusAnimation(v: boolean) {
    super._focusAnimation(v)
    this.patch({
      smooth: {
        scale: v ? 1.03 : 1,
      },
    })
  }

  override get announce() {
    const announce = [
      `Watch next in ${this._countdownInterval} seconds`,
      `${this._item.analytics.title}, ${this._item.analytics.sport}`,
    ]
    announce.push(
      this._item.contentType === CONTENT_TYPE.SLE
        ? `LIVE on ${this._item.analytics.brand.title}`
        : this._item.tertiaryTitle
    )
    if (this.parent?.parent && this.parent.parent.children?.length > 1) {
      announce.push(
        `On item ${this._index + 1} of ${
          this.parent.parent.children.length
        } in the row of available content`
      )
    }

    return announce
  }
}
