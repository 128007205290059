import { Lightning } from '@lightningjs/sdk'
import LabelItem from '../partials/LabelItem'
import { END_OF_ROW, FONT_FACE } from '../../../constants'
import { debounce } from 'lodash'
import Announcer from '../../../lib/tts/Announcer'

export class LabelListRenderingStrategy {
  _ctx: Lightning.Component
  _items: Lightning.Component

  // Refers to the item index that's currently focused
  _index = 0
  // Refers to the item index that's currently active
  _activeIndex = 0

  _isScrolled = false
  _sectionSize = 0

  // The item focused font face
  _fontFace = FONT_FACE.semiBold

  constructor(ctx: Lightning.Component, sectionSize: number) {
    this._ctx = ctx
    this._items = ctx.tag('Items')
    this._sectionSize = sectionSize
  }

  get isScrollable() {
    return false
  }

  get isScrolled() {
    return this._isScrolled
  }

  set isScrolled(isScrolled: boolean) {
    if (this._isScrolled !== isScrolled) this._ctx.signal('changeScrollState', isScrolled)
    this._isScrolled = isScrolled
  }

  set fontFace(font: FONT_FACE) {
    this._fontFace = font
  }

  get focusItem(): LabelItem | undefined {
    return this.getChildItem(this._index)
  }

  get activeItem(): LabelItem | undefined {
    return this.getChildItem(this._activeIndex)
  }

  set activeIndex(activeIndex: number) {
    this._activeIndex = activeIndex
    //When setting active index, make sure we upate index to match
    if (this._index !== activeIndex) {
      this._index = activeIndex
    }
  }

  // #region Lifecycle
  // Called on component init
  init() {}

  // Called when list is focused
  enter() {}

  // Called when list is unfocused
  exit() {}
  // #endregion

  getChildItem(index = 0): LabelItem | undefined {
    return this._items.children[index] as LabelItem | undefined
  }

  setChildItem(index: number, patch: Record<string, any>) {
    this.getChildItem(index)?.patch(patch)
  }

  announceEndOfRow() {
    Announcer.announce(END_OF_ROW)
  }

  onLabelsPageEnd = debounce(() => this._ctx?.fireAncestors('$onLabelsPageEnd'), 500)

  // #region Labels
  resetItems() {
    this._activeIndex = 0
    this._index = 0
    this._items.childList.clear()
  }

  createItems(items: any[], offset?: number) {
    if (offset) {
      this._items.childList.a(
        items.map((item: any, index: number) => this._mapItem(item, index + offset))
      )
    } else {
      this._items.children = items.map(this._mapItem)
    }
    this.positionItems()
  }

  _mapItem(item: any, index: number) {
    return {}
  }

  positionItems() {
    // Control rendering of the elements
  }
  // #endregion

  // #region Events
  handleLeft(): void | boolean {
    return false
  }

  handleRight(): void | boolean {
    return false
  }

  handleUp(): void | boolean {
    return false
  }

  handleDown(): void | boolean {
    return false
  }
  // #endregion

  // #region Scrolling
  moveToIndex(index: number) {
    this.setChildItem(this._activeIndex, { active: false })
    this._activeIndex = index
    this.setChildItem(this._activeIndex, { active: true })
    this._scrollTo(index)
  }

  moveWithDelta(direction: number, immediate?: boolean) {
    this._scrollTo(this._index + direction, immediate ? 0 : 0.3)
  }

  moveToFocused() {
    this.moveToIndex(this._index)
  }

  moveToActive() {
    this.moveToIndex(this._activeIndex !== this._index ? this._activeIndex : 0)
  }

  _scrollTo(index = 0, duration = 0) {
    if (this._items.children.length - 1 < index) {
      return
    }
    const oldIndex = this._index
    this._index = index

    // If the entire items list is inside the viewport
    // the focus element is just a rectangle below the list
    if (!this.isScrollable) {
      this._patchFocusElement(duration)
      return
    }
    // If there are items outside the viewport
    // we toggle a rectangle behind each element
    this.getChildItem(oldIndex)?._setHighlighted(false)
    this.focusItem?._setHighlighted(true, this.focusItem.width)
    this._changeListOffsetToFocused()
  }

  _changeListOffsetToFocused() {}

  _patchFocusElement(duration = 0) {}
  // #endregion
}
