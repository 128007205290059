import { Lightning } from '@lightningjs/sdk'

export default class Section extends Lightning.Component {
  static override _template() {
    return {}
  }

  set innerContent(content: any) {
    this.patch(content)
  }
}
