import { SCREEN_SIZE } from '../constants'

export default {
  tags: {
    slide: {
      w: SCREEN_SIZE.width,
      h: 880,
      item: {
        w: SCREEN_SIZE.width,
      },
    },
    videoTile: {
      w: SCREEN_SIZE.width,
      h: 537,
      noOfListItemsToScroll: 4,
      item: {
        w: 450,
      },
    },
    videoTileShowHome: {
      w: SCREEN_SIZE.width,
      h: 620,
      noOfListItemsToScroll: 4,
      item: {
        w: 450,
      },
    },
    videoTileOlympicsContentHub: {
      w: SCREEN_SIZE.width,
      h: 537,
      itemsPerGridRow: 3,
      noOfGridRowsToScroll: 2,
      noOfListItemsToScroll: 4,
      sideFilter: true,
      item: {
        w: 450,
        h: 400,
      },
    },
    replayTile: {
      w: SCREEN_SIZE.width,
      h: 537,
      itemsPerGridRow: 4,
      noOfGridRowsToScroll: 2,
      noOfListItemsToScroll: 4,
      item: {
        w: 450,
        h: 400,
      },
    },
    replayTileOlympicsContentHub: {
      w: SCREEN_SIZE.width,
      h: 537,
      itemsPerGridRow: 3,
      noOfGridRowsToScroll: 2,
      noOfListItemsToScroll: 4,
      sideFilter: true,
      item: {
        w: 450,
        h: 400,
      },
    },
    onAirNowTile: {
      w: SCREEN_SIZE.width,
      h: 487,
      noOfListItemsToScroll: 4,
      item: {
        w: 450,
      },
    },
    upcomingLiveTile: {
      w: SCREEN_SIZE.width,
      h: 500,
      itemsPerGridRow: 4,
      noOfGridRowsToScroll: 2,
      noOfListItemsToScroll: 4,
      item: {
        w: 450,
        h: 400,
      },
    },
    sportsLiveGuideTile: {
      w: SCREEN_SIZE.width,
      h: 400,
      itemsPerGridRow: 4,
      noOfGridRowsToScroll: 2,
      noOfListItemsToScroll: 4,
      item: {
        w: 1268,
        h: 310,
      },
    },
    endCardAlternate: {
      w: SCREEN_SIZE.width,
      h: 537,
      noOfListItemsToScroll: 4,
      item: {
        w: 480,
      },
    },
    endCardAlternateSLE: {
      w: SCREEN_SIZE.width,
      h: 537,
      noOfListItemsToScroll: 4,
      item: {
        w: 480,
      },
    },
    seriesTile: {
      w: SCREEN_SIZE.width,
      h: 680,
      itemsPerGridRow: 5,
      noOfGridRowsToScroll: 1,
      noOfListItemsToScroll: 4,
      item: {
        w: 360,
        h: 575,
      },
    },
    brandTile: {
      w: SCREEN_SIZE.width,
      h: 500,
      itemsPerGridRow: 4,
      noOfGridRowsToScroll: 2,
      noOfListItemsToScroll: 4,
      item: {
        w: 456,
        h: 350,
      },
    },
    movieTile: {
      w: SCREEN_SIZE.width,
      h: 680,
      itemsPerGridRow: 5,
      noOfGridRowsToScroll: 1,
      noOfListItemsToScroll: 4,
      item: {
        w: 360,
        h: 575,
      },
    },
    slideTile: {
      w: SCREEN_SIZE.width,
      h: 721,
      noOfListItemsToScroll: 2,
      item: {
        w: 767,
      },
    },
    upcomingLiveSlideTile: {
      w: SCREEN_SIZE.width,
      h: 721,
      noOfGridRowsToScroll: 2,
      noOfListItemsToScroll: 2,
      item: {
        w: 767,
      },
    },
    brandItemLabel: {
      w: SCREEN_SIZE.width,
      h: 150,
      noOfListItemsToScroll: 10,
      item: {
        w: 182,
      },
    },
    settingsTile: {
      w: SCREEN_SIZE.width,
      h: 810,
      noOfListItemsToScroll: 3,
      item: {
        w: 460 + 20,
      },
    },
    trickPlayImageTile: {
      w: SCREEN_SIZE.width,
      h: 141,
      noOfListItemsToScroll: 4,
      item: {
        w: 305,
      },
    },
    ItemLabelItem: {
      w: SCREEN_SIZE.width,
      h: 150,
      noOfListItemsToScroll: 7,
      item: {
        w: 72,
      },
    },
    message: {
      w: SCREEN_SIZE.width,
      h: 180,
    },
    Geo: {
      w: SCREEN_SIZE.width,
      h: 810,
      noOfListItemsToScroll: 3,
      item: {
        w: 460 + 20,
      },
    },
    Config: {
      w: SCREEN_SIZE.width,
      h: 810,
      noOfListItemsToScroll: 3,
      item: {
        w: 460 + 20,
      },
    },
    viewAllCta: {
      w: SCREEN_SIZE.width,
      h: 537,
      noOfListItemsToScroll: 1,
      preventScroll: true,
      margin: 104,
    },
  },
}

export interface StyleType {
  w?: number
  h?: number
  noOfListItemsToScroll?: number
  item?: StyleItem
  itemsPerGridRow?: number
  noOfGridRowsToScroll?: number
  margin?: number
}

export interface StyleItem {
  w?: number
  h?: number
}
