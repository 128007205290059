import qs from 'qs'

import isEmpty from 'lodash/isEmpty'
import AppConfigFactorySingleton from '../config/AppConfigFactory'
import { isSamsung } from '../helpers'
import TVPlatform from '../lib/tv-platform'
import { ErrorType } from '../lib/tv-platform/types'

interface ParkApi {
  get?: any
  post?: any
  getUrl?: any
  apiServiceUrl?: any
}

// TODO: Migrate TIMEOUT to Config
const TIMEOUT = 60000

export const VERSION = 'v4.32.0'
export const USERS_VERSION = 'v3.15'

const compareAlpha = { sort: (a: any, b: any) => a.localeCompare(b) }

const parkAPI: ParkApi = {}

const getParkRequestor = () => (isSamsung() ? 'nbcAppSamsung' : 'nbcAppVizio')

parkAPI.getUrl = (resource: any, params: any, version: any) => {
  if (!parkAPI.apiServiceUrl) {
    parkAPI.apiServiceUrl = AppConfigFactorySingleton.config.api_service_url
  }
  const query = isEmpty(params) ? '' : `?${qs.stringify(params, compareAlpha)}`
  return `${parkAPI.apiServiceUrl}/${version}/${resource}${query}`
}

/**
 * ParkAPI POST/PATCH/DELETE method.
 *
 * @param {string} resourceName
 *   The relative API path to use for the request.
 * @param {string} method
 *   The method to use for the request. Either POST (default), PATCH or DELETE.
 *   We funnnel PATCH requests via POST with a "X-HTTP-Method-Override" header.
 * @param {object} data
 *   The POST body data, as JSON.
 * @param {object} options
 *   Override options.
 *
 * @return {promise}
 *   Resolves when the request is fulfilled, rejects if there's an error.
 */
type OPTIONS_TYPE = {
  version?: any
  timeout?: any
}
parkAPI.post = (resourceName: any, method = 'POST', data = {}, options: OPTIONS_TYPE = {}) => {
  if (!data) console.warn('Data must be included for POST/PATCH requests.')

  const url = parkAPI.getUrl(
    resourceName,
    {},
    options?.version || AppConfigFactorySingleton.config.ParkAPIVersion || VERSION
  )
  const headers = new Headers({
    'Content-Type': 'application/vnd.api+json',
    'x-park-requestor': getParkRequestor(),
    ...(method === 'PATCH' ? { 'X-HTTP-Method-Override': 'PATCH' } : {}),
  })
  const timeout = options.timeout || TIMEOUT

  return fetch(url, {
    timeout,
    headers,
    method,
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  } as RequestInit)
    .then((response) => response.json())
    .catch((err) =>
      TVPlatform.reportError({
        type: ErrorType.NETWORK,
        description: 'PARK API fetch error',
        payload: err,
      })
    )
}

/**
 * ParkAPI GET method.
 *
 * @param {string} resourceName
 *   The relative path to fetch from the API.
 * @param {object} params
 *   GET arguments to send with the request.
 * @param {object} options
 *   Override options.
 *
 * @return {promise}
 *   Resolves when the request is fulfilled, rejects if there's an error.
 */

parkAPI.get = (resourceName: any, data: { derivatives?: any } = {}, options: OPTIONS_TYPE = {}) => {
  const url = parkAPI.getUrl(resourceName, data, options.version || VERSION)
  const headers = {
    accept: 'application/vnd.api+json' + (data?.derivatives ? '; ext="park/derivatives"' : ''), // derivatives parameter requires a JSON-API extension
    'x-park-requestor': getParkRequestor(),
  }

  return fetch(url, { timeout: options.timeout || TIMEOUT, headers } as RequestInit)
    .then((response) => response.json())
    .catch((err) => Promise.reject(err))
}

export default parkAPI
