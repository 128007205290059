import getSlideAttributes, { getSlideType } from './getSlideAttributes'
import {
  ALGOLIA_ENTITY_TYPES,
  getIsOlympics,
  getTileState,
  setDefaultValue,
  setSLEDefaultParams,
} from '../../../../helpers'
import {
  CONTENT_TYPE,
  CUSTOM_SHELF_TYPE,
  ENTITLEMENT,
  ENTITY_TYPES,
  MPARTICLE_DEFAULT_ATTR_VALUE,
  OLYMPICS_FALLBACK_VALUE,
  PROGRAMMING_TYPES,
} from '../../../../constants'
import { Program, Stream } from '../../../../store/PlayerStore/PlayerStore'
import { TrackParams } from '../../../../components/types'

const getSmartAttributes = (analytics: any) => {
  const isSmartTile = !!analytics.smartTile
  const smartTile = analytics.smartTile || {}
  return {
    'Smart Tile Title': smartTile.smartTileLabel,
    'Smart Tile Episode Title': isSmartTile && analytics.title,
    'Smart Tile Video ID': isSmartTile && analytics.mpxGuid,
    'Smart Tile Scenario': smartTile.smartTileScenario,
    'Smart Tile Logic': smartTile.smartTileLogic,
  }
}

const getBrand = (analytics: any) =>
  analytics.brand?.title ||
  analytics?.brandDisplayTitle ||
  analytics.referrer?.routes?.brand ||
  analytics.data?.brand?.title ||
  MPARTICLE_DEFAULT_ATTR_VALUE

const getNetworkAttributes = (params: any) => {
  const brand = params.analytics?.brand?.title
  return {
    'Item Clicked Name': brand,
    'Item Clicked Type': 'Brand',
    'Item Clicked Brand': brand,
  }
}

const getTileAttributes = (
  entityType: ENTITY_TYPES,
  analytics: any,
  shelf: any,
  tileParams: any
) => {
  const searchResultType = analytics.algoliaProperties?.entityType
  switch (entityType) {
    case ENTITY_TYPES.SLIDE:
    case ENTITY_TYPES.UPCOMING_LIVE_SLIDE:
      return getSlideAttributes(analytics, shelf, tileParams)
    case ENTITY_TYPES.MARKETING_MODULE:
      return {
        'Custom Shelf Title': shelf.listTitle,
        'Custom Shelf Type': shelf.customShelfType || CUSTOM_SHELF_TYPE.regular,
        'Item Clicked Playlist Name': analytics.playlistTitle,
        'Item Clicked Season': analytics.seasonNumber,
        'Item Clicked Show': analytics.series,
        'Item Clicked Sport': analytics.sport,
        'Item Clicked State': getTileState(analytics),
        'Item Clicked Type': getSlideType(analytics),
        'Item Clicked Brand': analytics.brand,
        'Item Clicked Entitlement': analytics.locked ? ENTITLEMENT.ENTITLED : ENTITLEMENT.FREE,
        'Item Clicked League': analytics.league,
        'Item Clicked Name': analytics.itemClickedName,
        'Item Clicked Video ID': analytics.mpxGuid || analytics.pid,
        'Shelf Machine Name': shelf.machineName,
      }
    case ENTITY_TYPES.SERIES:
    case ENTITY_TYPES.SHOWS:
      return {
        'Item Clicked Name': analytics.series,
        'Item Clicked Type': 'Show',
        'Item Clicked Show': analytics.series,
        'Item Clicked Season': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Video ID': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Entitlement': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Brand': analytics.brand?.title,
        'Custom Shelf Title': shelf.listTitle,
        'Custom Shelf Type': analytics.shelfType || 'Regular',
        'Shelf Machine Name': shelf.machineName,
      }
    case ENTITY_TYPES.VIDEOS:
    case ENTITY_TYPES.UPCOMING_LIVE_TILE:
    case ENTITY_TYPES.REPLAY:
    case ENTITY_TYPES.VIDEO: {
      const isOlympicsUpcoming =
        entityType === ENTITY_TYPES.UPCOMING_LIVE_TILE && analytics.isOlympics
      const playlistAttributes =
        analytics.playlistMachineName || analytics.treatment === 'playlist'
          ? {
              'Item Clicked Playlist Name': analytics.playlistMachineName || shelf.listTitle,
              'Item Clicked Playlist Position': shelf.position,
            }
          : {}
      if (!tileParams.programmingType && analytics.programmingType === PROGRAMMING_TYPES.FER)
        tileParams.programmingType = PROGRAMMING_TYPES.FER
      return {
        'Item Clicked Name': isOlympicsUpcoming ? analytics.secondaryTitle : analytics.title,
        'Item Clicked Type':
          analytics.programmingType ||
          analytics.programType ||
          (isOlympicsUpcoming && PROGRAMMING_TYPES.SLE),
        'Item Clicked Show': analytics.series || (isOlympicsUpcoming && OLYMPICS_FALLBACK_VALUE),
        'Item Clicked Season': analytics.seasonNumber,
        'Item Clicked Video ID': analytics.mpxGuid || analytics.pid,
        'Item Clicked Entitlement':
          analytics.programmingType === PROGRAMMING_TYPES.FER
            ? analytics.liveEntitlement
            : analytics.locked
            ? ENTITLEMENT.ENTITLED
            : ENTITLEMENT.FREE,
        'Item Clicked Brand': analytics.brand?.title,
        'Custom Shelf Title': shelf.listTitle,
        'Item Clicked Playlist Name': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Custom Shelf Type': analytics.shelfType || 'Regular',
        'Shelf Machine Name': shelf.machineName,
        'Item Clicked Sport': analytics.sport,
        'Item Clicked League': analytics.league,
        'Item Clicked State': getTileState(tileParams),
        'Dynamic Lead Logic': MPARTICLE_DEFAULT_ATTR_VALUE,
        ...(isOlympicsUpcoming ? { Show: OLYMPICS_FALLBACK_VALUE } : {}),
        ...playlistAttributes,
      }
    }
    case ENTITY_TYPES.MOVIE: {
      const movieName = analytics.movie
      return {
        'Item Clicked Name': movieName,
        'Item Clicked Type': 'Movie Home',
        'Item Clicked Show': movieName,
        'Item Clicked Season': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Video ID': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Entitlement': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Brand': analytics.brand?.title,
        'Custom Shelf Title': shelf.listTitle,
        'Custom Shelf Type': analytics.shelfType || 'Regular',
        'Shelf Machine Name': shelf.machineName,
      }
    }
    case ENTITY_TYPES.ON_AIR: {
      const onAirBrand = analytics.brand?.title
      return {
        'Item Clicked Name': analytics.episodeTitle,
        'Item Clicked Type': 'Live',
        'Item Clicked Show': analytics.programTitle,
        'Item Clicked Season': analytics.seasonNumber,
        'Item Clicked Video ID': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Entitlement': analytics.liveEntitlement,
        'Item Clicked Brand': onAirBrand,
        'Item Clicked Sport': analytics.sport,
        'Item Clicked League': analytics.league,
        'Item Clicked State': 'Live',
        'Item Clicked Playlist Name': analytics.isPlaylist && analytics.playlistMachineName,
        'Dynamic Lead Logic': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Custom Shelf Title': shelf.listTitle,
        'Custom Shelf Type': analytics.shelfType || 'Regular',
        'Smart Tile Logic': analytics.smartTileLogic,
        'Shelf Machine Name': shelf.machineName,
        Brand: onAirBrand,
      }
    }
    case ENTITY_TYPES.SMART_TILES:
    case ENTITY_TYPES.CTA_SMART_TILES:
      return {
        'Item Clicked Name': analytics.title,
        'Item Clicked Type': analytics.programmingType,
        'Item Clicked Show': analytics.series,
        'Item Clicked Season': analytics.seasonNumber,
        'Item Clicked Entitlement': analytics.locked ? ENTITLEMENT.ENTITLED : ENTITLEMENT.FREE,
        'Item Clicked Brand': analytics.brand?.title,
        'Custom Shelf Title': 'Smart Tile',
        'Shelf Machine Name': shelf?.machineName,
        'Item Clicked Video ID': analytics.mpxGuid,
        'Custom Shelf Type': analytics.shelfType || 'Regular',
        'Item Clicked Sport': analytics.sport || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked League': analytics.league || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked State': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Playlist Name': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Content Position': shelf.position || shelf.tileIndex,
        'Custom Shelf Position': shelf.customPosition || shelf.shelfIndex,
      }
    case ENTITY_TYPES.SEARCH_RESULTS: {
      const commonAttributes = {
        'Custom Shelf Title': 'Search Results',
        'Shelf Machine Name': shelf.machineName,
        Show: analytics.data?.series?.title || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Show': analytics.data?.series?.title || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Name': analytics.data?.title,
        'Item Clicked Brand': analytics.data?.brand?.title,
        'Item Clicked Sport': analytics.data?.sport?.title || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked League': analytics.data?.league?.title || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked State': getTileState(analytics.data) || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Season': analytics.data?.season?.seasonNumber || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Video ID': analytics.video?.mpxGuid || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Entitlement': analytics.data?.locked
          ? ENTITLEMENT.ENTITLED
          : ENTITLEMENT.FREE,
        'Item Clicked Playlist Name': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Custom Shelf Type': MPARTICLE_DEFAULT_ATTR_VALUE,
      }
      if (
        searchResultType === ALGOLIA_ENTITY_TYPES.SEGMENTS ||
        searchResultType === ALGOLIA_ENTITY_TYPES.EPISODES
      ) {
        const entitled = analytics.video?.authAccess?.ConnectedTvDevice
        return {
          ...commonAttributes,
          'Item Clicked Type': analytics.video?.fullEpisode
            ? 'Full Episode'
            : analytics.data?.programmingType?.[0],
          'Item Clicked Entitlement': entitled ? ENTITLEMENT.ENTITLED : ENTITLEMENT.FREE,
        }
      } else if (searchResultType === 'series') {
        return {
          ...commonAttributes,
          'Item Clicked Type': 'Show',
          'Item Clicked Entitlement': MPARTICLE_DEFAULT_ATTR_VALUE,
        }
      } else if (
        searchResultType === ALGOLIA_ENTITY_TYPES.REPLAYS ||
        searchResultType === ALGOLIA_ENTITY_TYPES.SLE
      ) {
        return {
          ...commonAttributes,
          'Item Clicked Type': analytics.data?.programmingType,
          'Item Clicked Video ID': analytics.data?.pid,
        }
      } else if (searchResultType === ALGOLIA_ENTITY_TYPES.SPORTS_VIDEOS) {
        return {
          ...commonAttributes,
          'Item Clicked Name': analytics.data?.subTitle,
          'Item Clicked Type': analytics.data?.programmingType,
        }
      }

      return {}
    }
    case ENTITY_TYPES.RECOMMENDED: {
      const seriesName = analytics.series
      return {
        'Item Clicked Name': seriesName,
        'Item Clicked Type': 'Show',
        'Item Clicked Show': seriesName,
        'Item Clicked Season': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Video ID': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Entitlement': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Brand': analytics.brand?.title,
        'Custom Shelf Title': shelf.listTitle,
        'Shelf Machine Name': shelf.machineName,
      }
    }
    case ENTITY_TYPES.NETWORK:
      return {
        ...getNetworkAttributes(analytics),
        'Custom Shelf Title': 'All Brands',
        'Shelf Machine Name': shelf.machineName,
        Brand: MPARTICLE_DEFAULT_ATTR_VALUE,
      }
    case ENTITY_TYPES.BRAND:
      return {
        ...getNetworkAttributes(analytics),
        'Custom Shelf Title': shelf.listTitle,
        'Shelf Machine Name': shelf.machineName,
        'Item Clicked Entitlement': analytics.locked ? ENTITLEMENT.ENTITLED : ENTITLEMENT.FREE,
      }
    case ENTITY_TYPES.STREAM:
      return {
        'Item Clicked Show': analytics.programTitle || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Season': analytics.seasonNumber || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Video ID': analytics.tmsId || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Entitlement': analytics.locked ? ENTITLEMENT.ENTITLED : ENTITLEMENT.FREE,
        'Item Clicked Name': analytics.videoTitle || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Type':
          analytics.contentType === CONTENT_TYPE.SLE ? 'Single Live Event' : 'Live',
        'Item Clicked State': 'Live',
        'Item Clicked Brand': analytics.brand?.title || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Sport': analytics.sport || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked League': analytics.league || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Custom Shelf Title': analytics.customShelfTitle || 'On Now',
        'Shelf Machine Name': shelf.machineName || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Custom Shelf Type': analytics.shelfType || 'Regular',
        'Item Clicked Playlist Name': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Smart Tile Logic': MPARTICLE_DEFAULT_ATTR_VALUE,
      }
    case ENTITY_TYPES.PLAYLIST:
      return {
        'Item Clicked Playlist Name': analytics.title,
        'Item Clicked Playlist Position': shelf.position,
        'Item Clicked Brand': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Show': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Season': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Video ID': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Name': analytics.listTitle,
        'Item Clicked Entitlement': analytics.locked ? ENTITLEMENT.ENTITLED : ENTITLEMENT.FREE,
        'Custom Shelf Title': shelf.listTitle,
        'Shelf Machine Name': shelf.machineName,
        'Item Clicked Type': 'Playlist',
      }
    case ENTITY_TYPES.LIVE_TO_VOD:
      return {
        'Item Clicked Name': `LIVE to VOD: ${analytics.textVOD || ''}`,
        'Item Clicked Video ID': analytics.mpxGuid || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Brand': analytics.brand?.title || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Show': analytics.programTitle || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Season': analytics.seasonNumber || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked Sport': analytics.sport || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Item Clicked League': analytics.league || MPARTICLE_DEFAULT_ATTR_VALUE,
      }
    default:
      return {}
  }
}

export type ContentClickParams = {
  entity: {
    program?: Program
    stream?: Stream
    entityType?: ENTITY_TYPES
    analytics?: Program & { customShelfTitle?: string }
    notificationTitle?: string
    dynamicSmartLeadLogic?: string
  }
  shelf: {
    machineName?: string
    tileIndex?: number
    shelfIndex?: number
    listTitle?: string
    position?: number
    customPosition?: number
    isUpcoming?: boolean
    isLive?: boolean
  }
  tileParams?: TrackParams
}

export const getContentClickAttributes = (params: ContentClickParams) => {
  const entityType = params.entity?.entityType
  const analytics = params.entity?.analytics || {}
  const shelf = params.shelf || {}
  const tileParams = params.tileParams || {}
  const show = analytics.series === 'None' ? analytics?.programTitle : analytics?.series
  const isOlympics = getIsOlympics(analytics)

  let defaultValueParams = {
    Brand: getBrand(analytics),
    Show: isOlympics ? OLYMPICS_FALLBACK_VALUE : show,
    'Content Position': shelf.position || shelf.tileIndex,
    'Custom Shelf Position': shelf.customPosition || shelf.shelfIndex,
    Sponsor: 'sponsorName' in analytics ? analytics.sponsorName : '',
    ...getSmartAttributes(analytics),
    ...(entityType ? getTileAttributes(entityType, analytics, shelf, tileParams) : {}),
    'Dynamic Lead Logic':
      params?.entity?.dynamicSmartLeadLogic ||
      ('dynamicGenerationLogic' in analytics && analytics?.dynamicGenerationLogic) ||
      '',
    'Item Clicked Url': MPARTICLE_DEFAULT_ATTR_VALUE,
  }

  if (isOlympics) {
    defaultValueParams = {
      ...defaultValueParams,
      'Item Clicked Show': OLYMPICS_FALLBACK_VALUE,
      'Item Clicked League': OLYMPICS_FALLBACK_VALUE,
    }
  }

  if (analytics.programmingType === PROGRAMMING_TYPES.SLE)
    defaultValueParams = setSLEDefaultParams(defaultValueParams)

  return setDefaultValue(defaultValueParams, MPARTICLE_DEFAULT_ATTR_VALUE)
}

export default getContentClickAttributes
