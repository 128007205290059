export class PlayerError {
  _fatal
  _type
  _details

  constructor(fatal: any, type: any, details: any) {
    this._fatal = fatal
    this._type = type
    this._details = details
  }

  get fatal() {
    return this._fatal
  }

  get type() {
    return this._type
  }

  get details() {
    return this._details
  }
}
