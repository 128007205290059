import moment from 'moment-timezone'
import 'moment/locale/es'

const initMoment = () => {
  moment.updateLocale('es', {
    weekdaysShort: ['Dom', 'Lun', 'Mar', 'Miérc', 'Juev', 'Vier', 'Sáb'],
  })
}

export const updateLanguage = (language: any) => {
  moment.locale(language)
}

export default initMoment()
