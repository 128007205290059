import getLinearEndAttributes from '../attributes/getLinearEndAttributes'
import mParticle from '@mparticle/web-sdk'

const getEvent = () => ({
  getAttributes: getLinearEndAttributes,
  getCategory: () => mParticle.EventType.UserContent,
  type: 'Linear End',
  injectGlobals: true,
})

export default getEvent
