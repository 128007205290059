export class IErrorViewModel {
  _message: any
  _retryable: any
  _title: any
  constructor(title: any, message: any, retryable: any) {
    this._title = title
    this._message = message
    this._retryable = retryable
  }

  get title() {
    return this._title
  }

  get message() {
    return this._message
  }

  get retryable() {
    return this._retryable
  }
}
