class TVPlatformModel {
  _deepLink: any
  _deepLinkRedirected: any
  constructor() {
    this._deepLink = null
    this._deepLinkRedirected = false
  }

  setDeepLink(route: any, params: any) {
    this._deepLink = {
      route,
      params,
    }
  }

  getDeepLink() {
    return this._deepLink
  }

  setDeepLinkRedirected(value: any) {
    this._deepLinkRedirected = value
  }

  getDeepLinkRedirected() {
    return this._deepLinkRedirected
  }
}

export default TVPlatformModel
