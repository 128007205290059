import { DebugModelControllerSingleton } from './DebugModelController'
import { isProduction } from '../../helpers'
import AuthenticationSingleton from '../../authentication/Authentication'
import { GraphQlClient } from '../../graphql/client'

class DebugController {
  _enabled: any
  _hardcodedStreamUrl: any
  _storage: any
  constructor() {
    this._storage = DebugModelControllerSingleton
    this._enabled = !isProduction() || this._getDebugFromUrl()
  }

  get enabled() {
    return this._enabled
  }

  set enabled(v) {
    this._enabled = v
  }

  get qosEnabled() {
    return this.enabled && this._storage.get('qosEnabled')
  }

  set qosEnabled(v) {
    this._storage.set('qosEnabled', v)
  }

  set userOptOut(v) {
    this._storage.set('userOptOut', v)
  }

  get userOptOut() {
    return this.enabled && this._storage.get('userOptOut')
  }

  set liveGuideV2(v) {
    this._storage.set('liveGuideV2', v)
  }

  get liveGuideV2() {
    return this.enabled && this._storage.get('liveGuideV2')
  }

  get videoProtocol() {
    return this._getVideoProtocolFromUrl()
  }

  set videoProtocol(_) {
    // noop
  }

  get allProgramsTempPassEligible() {
    return this.enabled && this._storage.get('allProgramsTempPassEligible')
  }

  set allProgramsTempPassEligible(v) {
    this._storage.set('allProgramsTempPassEligible', v)
  }

  get tempPassName(): [string, string] {
    const name = (this.enabled && this._storage.get('tempPassName'))?.split?.('/')
    if (name && name.length === 2) {
      return name
    }
    return ['', '']
  }

  set tempPassName(v: string | null) {
    this._storage.set('tempPassName', v)
  }

  get adsDisabled() {
    return this.enabled && this._storage.get('adsDisabled')
  }

  set adsDisabled(v) {
    this._storage.set('adsDisabled', v)
  }

  get env() {
    return this.enabled &&
      this._storage.get('env') &&
      this._storage.get('env').toLowerCase() !== 'default'
      ? this._storage.get('env').toLowerCase()
      : NODE_ENV
  }

  set env(v) {
    this._storage.set('env', v)
    GraphQlClient.cache.clear()
  }

  get geo() {
    return this.enabled && this._storage.get('geo') ? this._storage.get('geo') : null
  }

  set geo(v) {
    this._storage.set('geo', v)
  }

  get geoZip() {
    if (!this.enabled) {
      return null
    }
    switch (this.geo) {
      case 'Bay Area':
        return '94027'
      case 'Chicago':
        return '60007'
      case 'Washington':
        return '20001'
      case 'Boston':
        return '02108'
      case 'Philadelphia':
        return '19019'
      default:
        return null
    }
  }

  set geoZip(_) {
    // noop
  }

  get hardCodedShelfEnabled() {
    return this.enabled && this._storage.get('hardCodedShelfEnabled')
  }

  set hardCodedShelfEnabled(v) {
    this._storage.set('hardCodedShelfEnabled', v)
  }

  get streamUrl() {
    return this.enabled ? this._getStreamUrlFromUrl() || this._hardcodedStreamUrl : null
  }

  set streamUrl(v) {
    this._hardcodedStreamUrl = v
  }

  _setGenericValue = (k: any, v: any) => {
    this._storage.set(k, v)
  }

  _getGenericValue = (k: any) => {
    return this._storage.get(k)
  }

  _getDebugFromUrl = () => {
    const url = window.location.href
    return url.indexOf('debug=true') > 1
  }
  _getStreamUrlFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams?.get?.('streamUrl')
  }

  _getVideoProtocolFromUrl() {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams?.get?.('protocol')?.toUpperCase()
  }

  _getAdTrackingPreferenceFromUrl() {
    const urlParams = new URLSearchParams(window.location.search)
    const lmt = urlParams?.get?.('lmt')
    return lmt ? Number(lmt) : null
  }

  _resetTempPass(tempPassKey: any) {
    if (!tempPassKey) return
    return AuthenticationSingleton.resetTempPass(tempPassKey)
  }
}

export const DebugControllerSingleton = new DebugController()
