// Todo: Investigate whether profile sessions can expire on time.
// In that case, set sign out type to 'Expired Session'.
import MParticleInterface from '../mParticleInterface'
import mParticle from '@mparticle/web-sdk'

const postTrackEvent = () => () => {
  MParticleInterface.setConversionAttribute(false)
}

const trackNbcProfileSignOut = () => ({
  getAttributes: () => ({ 'Sign Out Type': 'Manual' }),
  getCategory: () => mParticle.EventType.UserPreference,
  type: 'NBC Sign Out',
  injectGlobals: true,
  postTrackEvent: postTrackEvent(),
})

export default trackNbcProfileSignOut
