import getAdBreakAttributes from '../attributes/getAdBreakAttributes'
import mParticle from '@mparticle/web-sdk'

const trackAdBreakEnd = (type: any) => ({
  getAttributes: getAdBreakAttributes,
  getCategory: () => mParticle.EventType?.UserContent || 'UserContent',
  type,
  injectGlobals: true,
})

export default trackAdBreakEnd
