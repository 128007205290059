import { Language, Lightning } from '@lightningjs/sdk'
import { COLORS, FONT_FACE, TEXT_ALIGN } from '../../../constants'
export class Confirm extends Lightning.Component {
  static override _template() {
    return {
      w: 1920,
      h: 152,
      Line: {
        w: 1920,
        h: 2,
        rect: true,
        color: COLORS.dark11,
      },
      Button: {
        y: 76,
        x: 960,
        h: 80,
        w: 1720,
        mount: 0.5,
        rect: true,
        color: COLORS.dark11,
      },
      Label: {
        x: 960,
        y: 80,
        mount: 0.5,
        color: COLORS.white,
        text: {
          textAlign: TEXT_ALIGN.center,
          fontSize: 35,
          fontFace: FONT_FACE.roboto,
        },
      },
    }
  }

  override _init() {
    this.tag('Label').patch({ text: Language.translate('confirm_my_choice') })
  }

  override _unfocus() {
    this.tag('Button').patch({ color: COLORS.dark11 })
  }

  override _focus() {
    this.tag('Button').patch({ color: COLORS.nbc })
  }

  get announce() {
    return Language.translate('confirm_my_choice')
  }
}
