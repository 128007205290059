import { GraphQlClient } from '../../graphql/client'
import { getMpid } from '../../helpers'

import bffPageMapper from '../../graphql/mappers/bffPage'
import { QueryName } from './bffQuery'
import rsnFilter from '../../graphql/filter/rsnFilter'
import { BonanzaPageQuery, BonanzaPageQueryVariables } from '../../graphql/generated/types'
import { AsyncRequestOptions } from '../../lib/useRequest'

const mainRequest = async (variables: BonanzaPageQueryVariables, options?: AsyncRequestOptions) => {
  if (!variables.userId) variables.userId = getMpid()
  return rsnFilter(
    bffPageMapper(
      await GraphQlClient.query<BonanzaPageQuery>(
        {
          query: QueryName.bonanzaPage,
          variables,
        },
        options
      )
    )
  )
}

export default mainRequest
