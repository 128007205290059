/**
 * General Player Actions
 * Note to devs: If you are trying to create a new action here
 * please check first if it's really going to be used by other actions,
 * else create it in a separate file.
 */
import { OmitConflicting, Unpacked } from '../../../../types/global'
import { PlayerError } from '../../../components/error/PlayerError'
import {
  BonanzaPageQuery,
  CoreSdkPlayerFragment,
  SectionFragment,
} from '../../../graphql/generated/types'
import { BffPage } from '../../../graphql/mappers/bffPage'

export enum PlayerStoreActions {
  SET_GEO = 'setGeo',
  SET_EPG = 'setEpg',
  SET_SINGLE_STREAM = 'setSingleStream',
  UPDATE_EPG_CHANNEL_INDEX = 'updateEpgChannel',
  NEXT_EPG_SCHEDULE_INDEX = 'nextEpgScheduleIndex',
  UPDATE_STREAM = 'updateStream',
  UPDATE_PROGRAM = 'updateProgram',
  ERROR = 'error',
  STREAM_ERROR = 'streamError',
  SET_LEMONADE = 'setLemonade',
  CLEAR_LEMONADE = 'clearLemonade',
  SET_PRE_CHECK_DATA = 'setPreCheckData',
  CLEAR_PRE_CHECK_DATA = 'clearPreCheckData',
  CLEAR_STATE = 'clearState',
  SET_PRE_CHECK_LEMONADE = 'setPreCheckLemonade',
  CLEAR_PRE_CHECK_LEMONADE = 'clearPreCheckLemonade',
}

export type CoreSDKPlayerFragment = Extract<
  Unpacked<BffPage['sections']>,
  { CoreSDKPlayerData: any }
>
export type CoreSDKPlayer = OmitConflicting<CoreSDKPlayerFragment['CoreSDKPlayerData']['player']>

export const findCoreSDKPlayerComponent = (
  sections?: BffPage['sections']
): CoreSDKPlayerFragment | undefined => {
  const coreSdkSection = sections?.find(
    (e) => e && 'component' in e && e.component === 'CoreSDKPlayer'
  )
  return (coreSdkSection as CoreSDKPlayerFragment | undefined) || undefined
}

export const findLinkSelectableComponent = (
  sections?: SectionFragment[] | null
): any | undefined => {
  return sections?.find((e) => e && 'component' in e && e?.component === 'LinksSelectableGroup')
}

export const findGridComponent = (sections?: SectionFragment[] | null): any | undefined => {
  return sections?.find((e) => e && 'component' in e && e?.component === 'Grid')
}

export const findMessageComponent = (sections?: SectionFragment[] | null): any | undefined => {
  return sections?.find((e) => e && 'component' in e && e?.component === 'Message')
}

export const findPlaceholderInLinkSelectable = (
  sections?: SectionFragment[] | null
): any | undefined => {
  const LinkSelectableGroup = findLinkSelectableComponent(sections)
  if (LinkSelectableGroup) {
    return LinkSelectableGroup.data.items.find(
      (e: { component: string }) => e?.component === 'Placeholder'
    ).data?.queryVariables
  }
}

type Metadata = OmitConflicting<
  NonNullable<NonNullable<BonanzaPageQuery['bonanzaPage']>['metadata']>
>

export type SingleStream = CoreSDKPlayer & Metadata
export type SingleProgram = CoreSdkPlayerFragment['analytics']
export type LocalizedChannelInfo = {
  channel: string
  geoChannel: string
  homeChannel: string
}
export type GeoLocation = {
  localizedChannelInfo?: LocalizedChannelInfo
}
export const isSingleProgram = (input: any): input is SingleProgram =>
  input && ('pid' in input || 'callSign' in input)
export const isSingleStream = (input: any): input is SingleStream => input && 'airDate' in input

export const setStream = (stream: SingleStream, program: SingleProgram) => ({
  type: PlayerStoreActions.SET_SINGLE_STREAM,
  payload: { stream, program },
})

export const clearPreCheckData = () => ({
  type: PlayerStoreActions.CLEAR_PRE_CHECK_DATA,
  payload: {},
})

export const clearPreCheckLemonade = () => ({
  type: PlayerStoreActions.CLEAR_PRE_CHECK_LEMONADE,
  payload: {},
})

export const setPreCheckData = (payload: any) => {
  const { data } = payload
  const streamData = data && findCoreSDKPlayerComponent(data.sections as any)
  const stream = streamData.CoreSDKPlayerData.player
  const program = streamData.analytics

  return {
    type: PlayerStoreActions.SET_PRE_CHECK_DATA,
    payload: { stream, program },
  }
}

export const setStreamData = (data: BffPage) => async () => {
  const stream = data && findCoreSDKPlayerComponent(data.sections as any)
  if (stream?.analytics?.pid === 0) {
    delete stream?.analytics.pid
  }
  return stream ? setStream(stream.CoreSDKPlayerData.player, stream.analytics) : setUnknownError()
}

export const clearState = () => ({ type: PlayerStoreActions.CLEAR_STATE })

export const clearLemonade = () => ({ type: PlayerStoreActions.CLEAR_LEMONADE })

export const updateStream = (payload: any) => ({
  type: PlayerStoreActions.UPDATE_STREAM,
  payload,
})

export const updateProgram = (payload: any) => ({
  type: PlayerStoreActions.UPDATE_PROGRAM,
  payload,
})

export const setUnknownError = () => ({
  type: PlayerStoreActions.ERROR,
  payload: {
    type: PlayerError.UNKNOWN,
    description: 'Unable to find stream',
    code: '404',
  },
})

export const setStreamError = () => ({
  type: PlayerStoreActions.STREAM_ERROR,
  payload: {
    type: PlayerError.UNKNOWN,
    description: 'Unable to find stream',
    code: '404',
  },
})

export const throwStoreError = (action: any) => ({
  type: PlayerStoreActions.ERROR,
  payload: action,
})
