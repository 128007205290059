import { GqlFetchResult } from '../client/types'
import { FeaturedShowsSectionQuery } from '../generated/types'

const searchFeaturedMapper = (page: GqlFetchResult<FeaturedShowsSectionQuery>) => {
  const data = page.data?.featuredShowsSection
  if (!data) return null
  return { data }
}

export default searchFeaturedMapper
