import { CCStyleEvent } from '../../../../lib/tv-platform/base'

export class BaseCCDelegate {
  _ctx: any
  constructor(ctx: any) {
    this._ctx = ctx
  }

  setCCStyle(options: CCStyleEvent) {}
}
