import mParticle from '@mparticle/web-sdk'
import getErrorAttributes from '../attributes/getErrorAttributes'

const getEvent = () => ({
  getAttributes: getErrorAttributes,
  getCategory: () => mParticle.EventType?.Navigation || 'Navigation',
  type: 'Error',
  injectGlobals: true,
})

export default getEvent
