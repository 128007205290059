import { setDefaultValue } from '../../../../helpers'
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants'

import RouterUtil from '../../../../util/RouterUtil'

export type AuthAttributesParams = {
  videoid?: any
  videoId?: any
  registrationReferrer?: any
}
const getAuthAttributes = (params: AuthAttributesParams = {}) =>
  setDefaultValue(
    {
      ...RouterUtil.getPrevData(),
      'Video ID': params.videoid || params.videoId,
      'Registration Referrer': params.registrationReferrer,
    },
    MPARTICLE_DEFAULT_ATTR_VALUE
  )

export default getAuthAttributes
