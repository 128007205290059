import { COLORS } from '../../constants'
import TextButton from './TextButton'

// @ts-expect-error TS(2417): Class static side 'typeof ModalButton' incorrectly... Remove this comment to see the full error message
export default class ModalButton extends TextButton {
  override _autoWidth: any
  override _fontSize: any
  override _paddingLeft: any
  override _paddingRight: any
  override _radius: any
  override _unfocusBackgroundColor: any
  override _unfocusFontColor: any
  static override _template() {
    return {
      ...super._template(),
      w: 400,
      h: 60,
      mountX: 0.5,
    }
  }
  override _construct() {
    super._construct()
    this._radius = 30
    this._fontSize = 30
    this._unfocusFontColor = COLORS.white
    this._unfocusBackgroundColor = COLORS.black3
    this._autoWidth = false
    this._paddingLeft = 0
    this._paddingRight = 0
  }
}
