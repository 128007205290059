import { Lightning, Router, Utils } from '@lightningjs/sdk'

import Loader from '../components/loader/Loader'

import { getBrandName } from '../helpers'
import { BRAND, COLORS, ROUTE, SCREEN_SIZE } from '../constants'
import { RouterPage } from '../../types/global'

export default class Splash extends Lightning.Component<
  Lightning.Component.TemplateSpecLoose,
  RouterPage
> {
  _returnPath?: ROUTE

  static override _template() {
    return {
      Background: {
        rect: true,
        w: SCREEN_SIZE.width,
        h: SCREEN_SIZE.height,
        color: COLORS.dark2,
      },
      Logo: {
        mountX: 0.5,
        mountY: 1,
        x: 960,
        y: 600,
        src: Utils.asset(`images/logos/logo-${getBrandName()}.png`),
      },
      Shadow: {
        x: 960,
        y: 640,
        mountX: 0.5,
        src: Utils.asset('images/logos/logo-shadow.png'),
      },
      Loader: {
        type: Loader,
        y: 755,
      },
    }
  }

  override _onDataProvided() {
    if (Router.getActiveHash() === ROUTE.boot) Router.resume() // this only is used when we are showing a boot screen
  }

  override set params(params: { returnPath?: ROUTE }) {
    if (params?.returnPath) this._returnPath = params.returnPath
  }

  override _firstActive() {
    const brand = getBrandName() //PL-803 - USA needs a full background so it needs to work differently
    if (brand === BRAND.usa) {
      this.patch({
        Background: {
          src: Utils.asset(`images/logos/logo-${brand}.png`),
          color: COLORS.white,
        },
        Logo: {
          visible: false,
        },
        Shadow: {
          visible: false,
        },
      })
    }
  }

  override _active() {
    if (this._returnPath) Router.navigate(this._returnPath)
  }

  override _inactive() {
    this._returnPath = undefined
    this.tag('Loader').visible = false
  }
}
