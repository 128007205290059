import { createRequestConfig } from '../../lib/useRequest'
import { featuredRequest, mainRequest } from '../../api/requests'
import Variables from '../../graphql/Variables'

export const NetworksLandingRequestConfig = (machineName: string) =>
  createRequestConfig({
    query: mainRequest,
    variables: Variables.networkLandingPageVariables(machineName),
    reject: [(data) => !data?.metadata || !data?.sections?.length],
  })

export const NetworksLandingFeaturedRequestConfig = (machineName: string) =>
  createRequestConfig({
    query: featuredRequest,
    variables: Variables.networkLandingPageVariables(machineName),
    reject: [(data) => !data],
  })
