import { Router } from '@lightningjs/sdk'
import { CONTENT_TYPE, ROUTE } from '../../../constants'
import { pipeSeparate } from '../../../helpers'

export default (tile: any) => {
  if (tile._item.contentType === CONTENT_TYPE.SLE) {
    const isOlympicsRoute = ([ROUTE.olympics, ROUTE.paralympics] as Array<ROUTE>).includes(
      Router.getActiveRoute() as ROUTE
    )
    if (tile._item.isOlympics && isOlympicsRoute) {
      tile.videoTitle = tile._item.title
      if (tile._item.secondaryTitle) tile.secondaryTitle = pipeSeparate(tile._item.secondaryTitle)
    } else {
      tile.videoTitle = tile._item.secondaryTitle ?? ''
      if (tile._item.description) tile.secondaryTitle = pipeSeparate(tile._item.description)
    }
  } else {
    tile.videoTitle = tile._item.videoTitle || tile._item.title || ''
    if (tile._item.secondaryTitle) tile.secondaryTitle = pipeSeparate(tile._item.secondaryTitle)
  }
}
