import { Language, Log } from '@lightningjs/sdk'
import moment from 'moment-timezone'
import AuthenticationSingleton from '../../../authentication/Authentication'
import {
  ALGOLIA_ENTITY_TYPES,
  Resource,
  ResourceObject,
  transformResources,
} from '../../../helpers'
import { getDateFromUnixTimestamp, getUpcomingBadge, MODAL, PROGRAMMING_TYPE } from '../helpers'
import { AlgoliaMappedResults } from '../types'
import { removeWhiteSpace } from '../../../helpers/string'

const CONTENT_TYPE = {
  singleLiveEvents: 'SLE',
  fullEventReplays: 'REPLAY',
}

enum COMPONENT {
  singleLiveEvents = 'UpcomingLiveTile',
  fullEventReplays = 'ReplayTile',
}

const mapAsset = (asset: any) => {
  const { algoliaProperties = {}, brand = {}, event = {}, league = {}, sport = {} } = asset
  const {
    title: brandTitle,
    channelId,
    colorBrandLogo,
    whiteBrandLogo,
    displayTitle,
    staticPreviewImage,
    machineName,
  } = brand
  const {
    ariaLabel,
    customerPlayableDate,
    endDate,
    image,
    labelBadge,
    labelBadgeEs,
    pid,
    title,
    description,
    dismissText,
    countdownDayLabel,
    countdownHourLabel,
    countdownMinutesLabel,
    locked,
  } = event
  const startTime = getDateFromUnixTimestamp(event?.startDate)
  const entityType: keyof (typeof COMPONENT | typeof PROGRAMMING_TYPE) =
    algoliaProperties?.entityType
  const assetTitle = league?.title || sport?.title
  const customerPlayableDateFormatted = getDateFromUnixTimestamp(customerPlayableDate)
  const replayAriaLabel = ariaLabel?.replace(
    '[Date]',
    moment(customerPlayableDateFormatted).format('MMMM Do, YYYY')
  ) // NFL, Los Angeles Rams at Jacksonville Jaguars, Aired on June 10th, 2022
  const upcomingAriaLabel = ariaLabel?.replace(
    '[date]',
    moment(customerPlayableDateFormatted).format('h:mm A dddd MMMM D')
  ) // example: Upcoming on NBC, Live at 2:00 PM Thursday July 21, NFL, Los Angeles Rams at Jacksonville Jaguars
  return {
    component: COMPONENT[entityType],
    analytics: {
      programType: PROGRAMMING_TYPE[entityType],
      title,
      secondaryTitle: assetTitle,
      league: league?.title,
      sport: sport?.title,
      locked,
      brand: {
        title: brandTitle,
      },
    },
    data: {
      ariaLabel:
        COMPONENT[entityType] === COMPONENT.fullEventReplays ? replayAriaLabel : upcomingAriaLabel,
      brandDisplayTitle: brandTitle,
      channelId: channelId,
      colorBrandLogo: colorBrandLogo?.path,
      contentType: CONTENT_TYPE[entityType],
      customerPlayableDate: customerPlayableDateFormatted,
      endTime: getDateFromUnixTimestamp(endDate),
      image: image?.path,
      liveAriaLabel: ariaLabel,
      labelBadge,
      labelBadgeEs,
      liveBadge: Language.translate('live'),
      pid: pid,
      programType: PROGRAMMING_TYPE[entityType],
      secondaryTitle: title,
      shortDescription: description,
      startTime: startTime,
      title: assetTitle,
      upcomingAriaLabel,
      upcomingBadge: getUpcomingBadge(startTime),
      upcomingModal: {
        component: 'UpcomingModal',
        analytics: {
          modalType: `${PROGRAMMING_TYPE[entityType]} ${MODAL}`,
          programTitle: title,
          modalName: Language.translate('continueToLive'),
          dismissText: dismissText,
          brand: {
            title: displayTitle,
          },
        },
        data: {
          backgroundImage: staticPreviewImage?.path,
          channelId: channelId,
          contentType: CONTENT_TYPE[entityType],
          countdownDayLabel: countdownDayLabel,
          countdownHourLabel: countdownHourLabel,
          countdownMinLabel: countdownMinutesLabel,
          customerPlayableDate: getDateFromUnixTimestamp(customerPlayableDate),
          ctaText: Language.translate('continueToLive'),
          description: `${title} ${Language.translate('willBegin')}`,
          dismissText: dismissText,
          lastMinuteModalLifespan: 5,
          machineName: machineName,
          pid: pid,
          resourceId: channelId,
          startTime: startTime,
          title: league?.title || sport?.title,
        },
      },
      whiteBrandLogo: whiteBrandLogo?.path,
    },
  }
}

export const assetsMapper = async (assetsData: any): Promise<AlgoliaMappedResults> => {
  if (!assetsData?.hits) {
    return {
      assets: [],
      totalAssets: 0,
      page: 0,
      totalPages: 0,
    }
  }

  const isMvpd = AuthenticationSingleton.isAuthenticated()
  const mvpdData = await AuthenticationSingleton.getMvpdData()

  let resources: Array<Resource> = []
  let resourceAuthorization: ResourceObject = {}

  if (mvpdData?.preAuthData?.resources) {
    resources = mvpdData.preAuthData.resources
    resourceAuthorization = transformResources(resources)
  }

  Log.info('Preauthorized Resources::', resources, resourceAuthorization)
  const currentDate = moment()
  const filteredAssets = assetsData.hits.filter((asset: any) => {
    if (!asset) return

    if (
      asset.entityType === ALGOLIA_ENTITY_TYPES.SLE ||
      asset.event?.programmingType === PROGRAMMING_TYPE.singleLiveEvents
    ) {
      if (!moment(getDateFromUnixTimestamp(asset.event?.endDate)).isSameOrAfter(currentDate)) return
    }

    // Early exit from the function if mvpd is empty
    if (!isMvpd) return asset

    const resourceId = removeWhiteSpace(asset?.event?.resourceId)

    Log.info(
      'Resource ID, IsRegional, PreAuth, resourceAuth::',
      resourceId,
      resourceAuthorization[resourceId]
    )

    if (isMvpd && resourceId) {
      return asset
    } else {
      Log.info('Binge Hidden Resource Ids::', asset?.event?.resourceId, 'Authorization:')
    }
  })

  return {
    assets: filteredAssets.map(mapAsset),
    totalAssets: assetsData?.nbHits,
    page: assetsData?.page,
    totalPages: assetsData?.nbPages,
  }
}
