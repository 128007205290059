import { Lightning } from '@lightningjs/sdk'
import BasePlayerControls from '../BasePlayerControls'
import { PlayerControlsStates } from '../../../../constants'
import { SkipButtonStates } from '../../../../pages/Player/delegates/SkipButtonDelegate'
import { isUndefined } from 'lodash'
import { Subscription } from 'rxjs'

export const WithSkipButton = <T extends Lightning.Component.Constructor<BasePlayerControls>>(
  base: T
) =>
  class extends base {
    _skipButtonEvents?: Subscription
    _isSkipButtonShown = false
    _skipButtonIndex = -1

    static _states() {
      return [
        // @ts-expect-error super doesn't work with static methods
        ...super._states(),
        class SkipButton extends this {
          override $enter() {
            this.fireAncestors('$selectSkipButton')
          }
        },
      ]
    }

    override _init() {
      super._init()
      this._skipButtonEvents?.unsubscribe()
      this._skipButtonEvents = this.fireAncestors(
        '$subscribeToSkipButtonDelegate',
        this._onSkipButtonEvent
      )
    }

    _onSkipButtonEvent = ({ status }: any) => {
      this._isSkipButtonShown = status !== SkipButtonStates.EMPTY_STATE && !isUndefined(status)
      this._updateKeyMap()
    }

    _updateKeyMap = () => {
      if (this._isSkipButtonShown && this._skipButtonIndex === -1) {
        this._setState(PlayerControlsStates.SkipButton)
        this._skipButtonIndex = this._keyMap.insertAt(1, [
          [],
          [PlayerControlsStates.SkipButton],
          [],
        ])
      } else if (this._skipButtonIndex > -1) {
        this._keyMap.removeAt(this._skipButtonIndex)
        this._skipButtonIndex = -1
        if (this._getState() === PlayerControlsStates.SkipButton)
          this._setState(PlayerControlsStates.PlayOrPause)
      }
    }
  }
