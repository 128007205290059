export default abstract class Announce {
  protected _phrase: string
  protected _notification = false

  constructor(toSpeak: string, notification = false) {
    this._notification = notification
    this._phrase = toSpeak
  }

  get phrase(): string {
    return this._phrase
  }

  get notification(): boolean {
    return this._notification
  }

  static estimateSpeechTime = (
    text: string,
    wordsPerMinute = 140,
    punctuationPause = 500,
    sentencePause = 1000,
    lineBreakPause = 1000,
    emailCharTime = 800, // Time per email character (in milliseconds)
  ): number => {
    const trimmed = text.trim()
    // Split the text into words, ignoring empty strings
    const wordCount = trimmed.split(/\s+/).filter(Boolean).length
    // Count the number of sentences (periods, exclamation marks, question marks)
    const sentenceEndings = (trimmed.match(/[.!?]/g) || []).length
    // Count the number of commas and similar punctuation
    const punctuationCount = (trimmed.match(/[,;:]/g) || []).length
    // Count the number of line breaks
    const lineBreaks = trimmed.split(/\n+/).length - 1
    // Find email addresses
    const emailMatches: Array<string> = trimmed.match(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g) || []
    // Count the number of characters in email addresses
    const emailCharCount = emailMatches.length ? emailMatches.reduce((sum: number, email: string) => sum + email.length, 0) : 0
    // Time for words (in milliseconds)
    const speechTime = Math.ceil((wordCount / wordsPerMinute) * 60) * 1000
    // Time for pauses (sentences, punctuation, line breaks)
    const totalPauseTime =
      sentenceEndings * sentencePause +
      punctuationCount * punctuationPause +
      lineBreaks * lineBreakPause
    // Time for spelling out email characters
    const emailTime = emailCharCount * emailCharTime
    return speechTime + totalPauseTime + emailTime + 1000
  }

  abstract speak(): Promise<void>
}
