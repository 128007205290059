import { Img, Lightning } from '@lightningjs/sdk'
import { FLEX_DIRECTION, FONT_FACE } from '../../../constants'

// @ts-expect-error TS(2417): Class static side 'typeof TitleBlock' incorrectly ... Remove this comment to see the full error message
export default class TitleBlock extends Lightning.Component {
  static override _template() {
    return {
      flex: { direction: FLEX_DIRECTION.column },
      w: 660,
      h: 300,
      y: this.bindProp('positionY'),
      rect: true,
      color: 0x00000000,
      VideoTitle: {
        text: {
          fontSize: 72,
          fontFace: FONT_FACE.light,
          lineHeight: 80,
          maxLines: 2,
          wordWrapWidth: 660,
          textAlign: 'bottom',
          text: this.bindProp('videoTitle'),
        },
      },
      SponsorLogo: {},
      SecondaryTitle: {
        alpha: 0.9,
        text: {
          fontSize: 27,
          fontFace: FONT_FACE.light,
          lineHeight: 36,
          maxLines: 2,
          wordWrapWidth: 600,
          text: this.bindProp('secondaryTitle'),
        },
      },
      SecondaryDescription: {
        text: {
          fontSize: 24,
          fontFace: FONT_FACE.light,
          lineHeight: 36,
          maxLines: 2,
          wordWrapWidth: 600,
          text: this.bindProp('secondaryDescription'),
        },
      },
    }
  }

  set sponsorLogo(sponsorLogo: any) {
    if (sponsorLogo?.path)
      this.tag('SponsorLogo').patch({
        texture: Img(sponsorLogo.path).contain(700, 100),
      })
  }
}
