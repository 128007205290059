import { IPlayerEvent } from './IPlayerEvent'

export class PlayerStatusEvent extends IPlayerEvent {
  _status
  _metadata

  constructor(status: any, metadata: any) {
    super()
    this._status = status
    this._metadata = metadata
  }

  get status() {
    return this._status
  }

  get metadata() {
    return this._metadata ?? {}
  }
}
