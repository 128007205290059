import getAuthAttributes from './getAuthAttributes'

const getIDMConversionAttributes = (params: any) => {
  return {
    ...getAuthAttributes(params),
    'Sign In Type': 'OTT Device',
  }
}

export default getIDMConversionAttributes
