import BasePlayer from './BasePlayer'
import VODPlayerControls from '../../components/player/PlayerControls/VODPlayerControls'
import TVRatingGuide from '../../components/player/TVRatingGuide'

import PlayerStoreSingleton from '../../store/PlayerStore/PlayerStore'

import { AdBreakDataEvent, TimeChangeEvent } from '../../player/model/event'
import { setSmooth } from '../../helpers'
import { CONTENT_TYPE, PROGRAMMING_TYPES } from '../../constants'
import { TrickPlayImageEvent } from '../../player/model/event/TrickPlayImageEvent'
import { WithSeeking } from './hoc/WithSeeking'
import { WithEndCardVOD } from './hoc/WithEndCard/WithEndCardVOD'
import { WithSkipButton } from './hoc/WithSkipButton'
import { WithPauseAds } from './hoc/WithPauseAds'
import { VODAnalyticsDelegate } from './delegates/analytics/VODAnalyticsDelegate'
import { CoreVideoLightningMediaPlayerAdapter } from '../../player/CoreVideoPlayer/CoreVideoLightningMediaPlayerAdapter'
import { WatchProgressDelegate } from './delegates/WatchProgressDelegate'
import { PlayerStatus } from '../../player/model/PlayerStatus'
import { isSingleStream } from '../../store/PlayerStore/actions'
import {
  ProgressHookType,
  useProgress,
} from '../../components/player/PlayerControls/hooks/useProgress'

class VODCorePlayer extends BasePlayer {
  $subscribeToSkipButtonDelegate: any
  _mediaPlayer: CoreVideoLightningMediaPlayerAdapter
  _removePersonalization: any
  _skipButtonDelegate: any
  _skipButtons: any
  _tvGuideRating: any
  _watchProgressDelegate: any
  onSkipButtonStatusChanged: any
  _progress: ProgressHookType
  override _log_tag = 'VOD Core Player'
  override _stream_type = CONTENT_TYPE.VOD
  override _controls: VODPlayerControls

  override get widgets() {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    return this.parent?.widgets // Widgets were only accesible from page
  }

  static override _template() {
    return super._template({
      Controls: { type: VODPlayerControls },
      TVRating: {
        type: TVRatingGuide,
        x: 25,
        y: 25,
      },
    })
  }

  override _init() {
    super._init()
    this._progress = useProgress()
    this._mediaPlayer = new CoreVideoLightningMediaPlayerAdapter()
    this._analyticsDelegate = new VODAnalyticsDelegate(this as any)
    this._tvGuideRating = this.tag('TVRating')
  }

  // @ts-expect-error TS(2416): Property '_active' in type 'VODCorePlayer' is not ... Remove this comment to see the full error message
  _active() {
    super._active()
    this.fireAncestors('$onAttached')
    setSmooth(this.widgets.loader, 'visible', 0)
    this._analyticsDelegate?.firePageLoad()
    this._load()
  }

  override _attach() {
    super._attach()
    this._tvGuideRating?._attachPlayer(this._player)
  }

  // @ts-expect-error TS(2416): Property '_detach' in type 'VODCorePlayer' is not ... Remove this comment to see the full error message
  _detach() {
    super._detach()
    if (this._tvGuideRating) this._tvGuideRating._detach()
  }

  override _playerEventsHandler(event: any) {
    super._playerEventsHandler(event)
    if (event instanceof TimeChangeEvent) {
      this._watchProgressDelegate?.update(event.time)
    }

    if (event instanceof AdBreakDataEvent) {
      this._controls.addAdBreakPoints(event.adBreaks.filter(({ startTime }: any) => startTime > 0))
    }

    if (event instanceof TrickPlayImageEvent) {
      this._controls.addThumbnailVariants(event)
    }
  }

  override _onPlayerStatusChange(status: any) {
    super._onPlayerStatusChange(status)

    switch (status) {
      case PlayerStatus.PAUSED:
        this._watchProgressDelegate?.update(this._progress.value[0], {
          forceReport: true,
        })
    }
  }

  // @ts-expect-error TS(2425): Class 'BasePlayer' defines instance member propert... Remove this comment to see the full error message
  _onStreamEnd() {
    if (!isSingleStream(PlayerStoreSingleton.stream)) return
    this._watchProgressDelegate?.update(this._progress.value[0], {
      forceReport: true,
    })
    this._watchProgressDelegate?.endSession()
    this._analyticsDelegate?.fireContentComplete()
    this._analyticsDelegate?.fireSessionEnd({
      watched: this._progress.value[0],
      duration: PlayerStoreSingleton.stream?.duration,
    })
  }

  override _handleBack(e: any) {
    this._closeMediaPlayer(true)
    e.preventDefault()
    e.stopPropagation()
  }

  override _closeMediaPlayer(forceExit: boolean) {
    if (!this._watchProgressDelegate) {
      //example use case: For Clips, we don't have watch progress
      super._closeMediaPlayer(forceExit)
    }
    this._watchProgressDelegate
      ?.update(this._progress.value[0], {
        forceReport: true,
      })
      ?.then(() => {
        if (this._watchProgressDelegate) {
          this._watchProgressDelegate?.setHasWatchedContent().then(() => {
            super._closeMediaPlayer(forceExit)
          })
        } else {
          super._closeMediaPlayer(forceExit)
        }
      })
      .catch(() => {
        super._closeMediaPlayer(forceExit)
      })
  }

  override _setPlayerInActiveTimeout() {}

  _loadWatchProgressDelegate() {
    const { stream } = PlayerStoreSingleton

    if (!isSingleStream(stream)) return
    const shouldReportProgress =
      (!this._removePersonalization &&
        stream?.programmingType === PROGRAMMING_TYPES.FULL_EPISODE) ||
      stream?.programmingType === PROGRAMMING_TYPES.MOVIE

    if (shouldReportProgress) {
      this._watchProgressDelegate = new WatchProgressDelegate(
        stream?.duration,
        this._mediaPlayer.watchId
      )
    }
  }

  override _handleMediaPause() {
    super._handleMediaPause()
  }

  override async _startStream() {
    const { stream, program } = PlayerStoreSingleton
    if (!isSingleStream(stream) || !program) return
    await super._startStream()
    this._tvGuideRating.assetInfo = program
    this._loadWatchProgressDelegate()
  }
}

export default class extends WithPauseAds(
  WithSkipButton(WithSeeking(WithEndCardVOD(VODCorePlayer)))
) {}
