import Collection from './Collection'

export default class MarketingModuleSection extends Collection {
  constructor(obj: any) {
    super(obj)
    this.obj.items = obj
  }

  override get instanceID(): any {
    return this.obj.marketingModuleData.v4ID
  }
}
