import { Ad, AdBreak } from '@sky-uk-ott/core-video-sdk-js-core'

export class AdBreakTiming {
  accumulated: number
  duration: number
  endTime: number
  startTime: number
  startTimeOnUi: number
  constructor(
    endTime: number,
    startTime: number,
    startTimeOnUi: number,
    duration: number,
    accumulated: number
  ) {
    this.endTime = Math.floor(endTime)
    this.startTime = Math.floor(startTime)
    this.startTimeOnUi = Math.floor(startTimeOnUi) // startTime without previous adbreak time
    this.duration = Math.floor(duration)
    this.accumulated = Math.floor(accumulated)
  }
}

export const reduceAdBreaks = (adbreaks: AdBreak<Ad>[]) => {
  if (!adbreaks) return []
  let accumulated = 0
  return adbreaks.reduce((acc: AdBreakTiming[], pod: AdBreak<Ad>) => {
    const { position = 0, expectedDuration = 0 } = pod
    const startTime = Number(position) + accumulated
    accumulated += expectedDuration
    return [
      ...acc,
      new AdBreakTiming(
        startTime + expectedDuration,
        startTime,
        Number(position),
        expectedDuration,
        accumulated
      ),
    ]
  }, [])
}
