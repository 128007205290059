import StreamNotAvailableButtonGroup from '../StreamNotAvailableButtonGroup'
import StreamAvailableButtonGroup from '../StreamAvailableButtonGroup'
import TextBorderButton from '../../../../components/buttons/TextBorderButton'

import {
  ALIGN_CONTENT,
  COLORS,
  FLEX_DIRECTION,
  FONT_FACE,
  JUSTIFY_CONTENT,
} from '../../../../constants'

const buttonGroupType = {
  streamNotAvailable: 'StreamNotAvailable',
  streamAvailable: 'StreamAvailable',
}

const GROUP_PROPS = {
  flex: {
    direction: FLEX_DIRECTION.column,
    alignContent: ALIGN_CONTENT.center,
    justifyContent: JUSTIFY_CONTENT.center,
  },
}

const BUTTON_PROPS = {
  h: 58,
  w: 420,
  type: TextBorderButton,
  radius: 27,
  fontSize: 30,
  fontFace: FONT_FACE.regular,
  focusFontColor: COLORS.black,
  unfocusFontColor: COLORS.warmGray2,
  focusBackGroundColor: COLORS.white,
  unfocusBackgroundColor: COLORS.transparent,
  focusBorderColor: COLORS.transparent,
  unfocusBorderColor: COLORS.warmGray2,
  padding: 22,
}

const getButtonGroup = (groupType: any, props = {}) => {
  let type
  switch (groupType) {
    case buttonGroupType.streamNotAvailable:
      type = StreamNotAvailableButtonGroup
      break
    case buttonGroupType.streamAvailable:
      type = StreamAvailableButtonGroup
      break
  }
  return { type, ...props }
}

export { buttonGroupType, GROUP_PROPS, BUTTON_PROPS, getButtonGroup }
