import LazyCollection from './LazyCollection'
import Tiles from '../tiles'

export default class ContinuousScroll extends LazyCollection {
  constructor(obj = {}, index = 0) {
    super(obj, index)
  }

  override get items() {
    return this.obj.items.map(
      (item: any, index: any) =>
        new Tiles[item.item.component as keyof typeof Tiles](item.item, this.shelfData, index)
    )
  }

  get groupIds() {
    return this.obj.items.map((item: any) => item.groupId)
  }

  override get listTitle() {
    return this.obj.title
  }

  get next() {
    return this.obj.next
  }

  get previous() {
    return this.obj.previous
  }

  get initialItem() {
    return this.obj.initialItem
  }

  override get shelfData() {
    return {
      playlistMachineName: this.playlistMachineName,
      listTitle: this.listTitle,
      shelfIndex: this.index,
      machineName: this.machineName,
    }
  }
}
