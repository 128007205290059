import { Language } from '@lightningjs/sdk'

import get from 'lodash/get'

import getPageAttributes, { getReferrer } from './getPageAttributes'
import { getAppLaunchType, getMvpdValue, getProfileValue } from '../../../../helpers'
import { getPlatformName, getProduct, getDeviceModel, getMpid } from '../../../../helpers'
import { setDefaultValue } from '../../../../helpers'
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants'
import ModalManager from '../../../ModalManager'

const getGlobalAttributes = (params = {}) => {
  // @ts-expect-error TS(2339): Property 'path' does not exist on type '{}'.
  const { path, pageName, launchType, firstVisit, league } = params
  const page =
    get(pageName, 'current') || getPageAttributes(ModalManager._activeModal || path) || {}

  // const { experiment, variant } = AB.getExperimentList()
  // const { experiment: adExperiment, variant: adVariant, audience: adAudience } = AB.adExperiment

  return setDefaultValue(
    {
      Platform: getPlatformName(),
      Product: getProduct(),
      'Device Model': getDeviceModel(),
      MVPD: getMvpdValue(),
      Profile: getProfileValue(),
      'Page Name': page.name,
      'Page Type': page.type,
      'Referring Page': getReferrer(),
      'Open Type': launchType || getAppLaunchType(),
      Orientation: MPARTICLE_DEFAULT_ATTR_VALUE,
      'Ad Blocker': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Ad Tracking Opt-Out': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Push Opt-Out': MPARTICLE_DEFAULT_ATTR_VALUE,
      'First Visit': firstVisit,
      Experiment: MPARTICLE_DEFAULT_ATTR_VALUE,
      Variant: MPARTICLE_DEFAULT_ATTR_VALUE,
      'Ad Experiment': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Ad Variant': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Ad Audience': MPARTICLE_DEFAULT_ATTR_VALUE,
      Language: Language.get(),
      League: league,
      'Branch Feature': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Branch Campaign': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Branch Channel': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Branch Tag': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Branch mParticle ID': MPARTICLE_DEFAULT_ATTR_VALUE,
      'UTM Source': MPARTICLE_DEFAULT_ATTR_VALUE,
      'UTM Medium': MPARTICLE_DEFAULT_ATTR_VALUE,
      'UTM Campaign': MPARTICLE_DEFAULT_ATTR_VALUE,
      'UTM Term': MPARTICLE_DEFAULT_ATTR_VALUE,
      'UTM Content': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Peacock Account Tier': MPARTICLE_DEFAULT_ATTR_VALUE,
      SEO: MPARTICLE_DEFAULT_ATTR_VALUE,
      'QR Code': 'False',
      mParticleSessionId: getMpid(),
    },
    MPARTICLE_DEFAULT_ATTR_VALUE
  )
}

export default getGlobalAttributes
