import { labelBadgeDecorator } from '../../decorators'

import { getTimerToEndTile } from '../../../../helpers/metadata'

export const getUpcomingStateClass = (context: any) =>
  class UpcomingState extends context {
    $enter() {
      if (this._item.upcomingBadge) {
        this._addDecorators([labelBadgeDecorator(this._item?.upcomingBadge)])
      }

      this._timerChangeToLive = getTimerToEndTile(this._item.startTime, () => {
        this._setState('LiveState')
      })
      this.announce = this._item.upcomingAriaLabel
    }
  }
