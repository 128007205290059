import { Language } from '@lightningjs/sdk'
import BaseComponent from '../../base'
import TextButton from '../../buttons/TextButton'
import { COLORS, FLEX_DIRECTION, FONT_FACE, JUSTIFY_CONTENT } from '../../../constants'

export class LiveBadgeV1 extends BaseComponent {
  static override _template() {
    return {
      flex: {
        direction: FLEX_DIRECTION.column,
        justifyContent: JUSTIFY_CONTENT.center,
      },
      Live: {
        flexItem: {
          marginLeft: 22,
          marginRight: 22,
        },
        h: 40,
        type: TextButton,
        radius: 5,
        fontSize: 26,
        fontFace: FONT_FACE.semiBold,
        focusFontColor: COLORS.white,
        unfocusFontColor: COLORS.white,
        focusBackGroundColor: COLORS.red2,
        unfocusBackgroundColor: COLORS.red2,
        label: this.bindProp('title'),
        autoWidth: true,
        padding: 13,
      },
    }
  }

  override _setTranslation() {
    this.tag('Live').label = Language.translate('live')
  }
}
