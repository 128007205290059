import { Lightning } from '@lightningjs/sdk'
import SecondaryControls, { SecondaryControlButtons } from './SecondaryControls'
import { COLORS, FONT_FACE, OLY_IMPOLICY, VERTICAL_ALIGN } from '../../../../../constants'
import { LiveBadge } from './LiveBadge'
import { FastImg } from '../../../../../helpers'

export class HeadingV2 extends Lightning.Component {
  _secondaryControls: SecondaryControls
  _title: Lightning.Component
  _secondaryTitle: Lightning.Component
  _description: Lightning.Component

  static override _template() {
    return {
      y: 770,
      Logo: {
        y: 30,
        x: 13,
        w: 60,
        mount: 0,
        PlayerControlsBrandLogo: {
          src: '',
          x: 30,
          y: 0,
          mountX: 0.5,
          texture: {
            type: Lightning.textures.ImageTexture,
            src: '',
            resizeMode: { type: 'contain', w: 60, h: 60 },
          },
        },
        LiveBadge: {
          type: LiveBadge,
          x: 30,
          mountX: 0.5,
          y: 85,
        },
      },
      Container: {
        x: 100,
        Title: {
          y: 40,
          rtt: true,
          text: {
            textColor: COLORS.white,
            verticalAlign: VERTICAL_ALIGN.middle,
            fontSize: 34,
            fontFace: FONT_FACE.light,
            maxLines: 1,
            wordWrapWidth: this.bindProp('w'),
          },
        },
        SecondaryTitle: {
          rtt: true,
          y: 96,
          text: {
            textColor: COLORS.white,
            verticalAlign: VERTICAL_ALIGN.middle,
            fontSize: 26,
            fontFace: FONT_FACE.regular,
            maxLines: 1,
            wordWrapWidth: this.bindProp('w'),
          },
        },
        Description: {
          y: 130,
          text: {
            textColor: COLORS.white,
            fontSize: 26,
            lineHeight: 36,
            fontFace: FONT_FACE.regular,
            wordWrapWidth: 1534,
          },
        },
      },
      SecondaryControls: {
        x: 1780,
        y: 40,
        mountX: 1,
        buttons: this.bindProp('buttons'),
        type: SecondaryControls,
      },
    }
  }

  override _init() {
    super._init()
    this._secondaryControls = this.tag('SecondaryControls')
    this._title = this.tag('Title')
    this._secondaryTitle = this.tag('SecondaryTitle')
    this._description = this.tag('Description')
  }

  toggle(button: SecondaryControlButtons, visible: boolean) {
    this._secondaryControls.toggle(button, visible)
  }

  setLabel(button: SecondaryControlButtons, label: string) {
    if (label) this._secondaryControls.setLabel(button, label)
  }

  setTitle(text: string) {
    this._title.patch({ text: { text } })
  }

  setSecondaryTitle(text: string) {
    this._secondaryTitle.patch({ text: { text } })
  }

  setDescription(text: string) {
    this._description.patch({ text: { text } })
  }

  setLogo(src?: string) {
    if (src)
      this.tag('PlayerControlsBrandLogo').patch({
        texture: FastImg(src).contain(60, 60, OLY_IMPOLICY.SQUARE),
      })
  }

  override _getFocused() {
    return this.tag('SecondaryControls')
  }
}
