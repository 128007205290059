import LabelBadge from '../../badges/LabelBadge'
import { COLORS } from '../../../constants'

export default (tile: any) => {
  const durationBadge = tile._item?.durationBadge
  if (durationBadge) {
    tile.patch({
      ImageHolder: {
        LeftBottomBadge: {
          type: LabelBadge,
          label: durationBadge,
          positionX: 10,
          positionY: (h: any) => h - 50,
          backgroundColor: COLORS.black5,
          fontColor: COLORS.white,
          fontSize: 24,
          width: 68,
          height: 36,
        },
      },
    })
  }
}
