export const QOSPanelStateFactory = (base: any) =>
  class QOSPanel extends base {
    _getFocused() {
      return this.tag('QOSPanel') || this
    }

    _handleEnter() {
      this._toggleQOSPanel()
    }
  }
