import { AdobePathErrorMappings, MvpdMetadata, MvpdProviderData, PreAuthData } from '../../helpers'

export default class MvpdData {
  private _adobePassErrorMappings: AdobePathErrorMappings
  private _expires: number
  private _metaData: MvpdMetadata
  private _mvpd: string
  private _mvpdProviderData: MvpdProviderData
  private _userId: string
  private _preAuthData!: PreAuthData
  constructor(
    mvpd: string,
    userId: string,
    expires: number,
    adobePassErrorMappings: AdobePathErrorMappings,
    mvpdProviderData: MvpdProviderData,
    metadata: MvpdMetadata,
    preAuthData: PreAuthData
  ) {
    this._mvpd = mvpd
    this._userId = userId
    this._expires = expires
    this._adobePassErrorMappings = adobePassErrorMappings
    this._mvpdProviderData = mvpdProviderData
    this._metaData = metadata
    this._preAuthData = preAuthData
  }

  set metaData(metaData: MvpdMetadata) {
    this._metaData = metaData
  }

  get zip() {
    return this._metaData?.data?.zip
  }

  get encryptedZip() {
    return this._metaData?.data?.encryptedZip
  }

  get mvpd() {
    return this._mvpd
  }

  get mvpdDisplayName() {
    return this._mvpdProviderData?.mvpd_display_name
  }

  get mvpdAdvertisingKey() {
    return this._mvpdProviderData?.advertisingKey
  }

  get mvpdProviderData() {
    return this._mvpdProviderData
  }

  get expires() {
    return this._expires
  }

  get userId() {
    return this._userId
  }

  get preAuthData() {
    return this._preAuthData
  }

  get adobePassErrorMappings() {
    return this._adobePassErrorMappings
  }
}
