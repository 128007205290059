import { Lightning } from '@lightningjs/sdk'
import moment from 'moment-timezone'

import { DEFAULT_TIME_LIMIT_UPCOMING_ITEM, getTimeDiffAsMilliseconds } from '../../../helpers'
import { BUTTON_PROPS, GROUP_PROPS } from './helpers/buttonGroupHelper'
import RouterUtil from '../../../util/RouterUtil'
import { ROUTE, ROUTE_ENTITY } from '../../../constants'
import ModalManager from '../../../lib/ModalManager'

export default class StreamAvailableButtonGroup extends Lightning.Component {
  _channelId: any
  _ctaText: any
  _dismissText: any
  _pid: any

  static override _template() {
    return {
      ...GROUP_PROPS,
      ContinueButton: {
        ...BUTTON_PROPS,
      },
      BackButton: {
        flexItem: { marginTop: 15 },
        ...BUTTON_PROPS,
      },
    }
  }

  override _init() {
    this._setState('ContinueButton')
  }

  set ctaText(ctaText) {
    this._ctaText = ctaText
    this.tag('ContinueButton').label = ctaText
  }

  get ctaText() {
    return this._ctaText
  }

  set dismissText(dismissText) {
    this._dismissText = dismissText
    this.tag('BackButton').label = dismissText
  }

  get dismissText() {
    return this._dismissText
  }

  set channelId(channelId) {
    this._channelId = channelId
  }

  get channelId() {
    return this._channelId
  }

  set pid(pid) {
    this._pid = pid
  }

  get pid() {
    return this._pid
  }

  static override _states() {
    return [
      class ContinueButton extends this {
        startTime: any

        override _getFocused() {
          return this.tag('ContinueButton') || this
        }

        override _handleDown() {
          this._setState('BackButton')
        }

        override _handleEnter() {
          const remainingTime = getTimeDiffAsMilliseconds(moment(), this.startTime)
          this.fireAncestors('$trackCtaAnalytics', this.ctaText)
          if (remainingTime <= DEFAULT_TIME_LIMIT_UPCOMING_ITEM || this.pid) {
            RouterUtil.navigateToRoute(ROUTE.watch, {
              entity: ROUTE_ENTITY.pid,
              value: this.pid,
            })
          } else {
            RouterUtil.navigateToLive(this.fireAncestors.bind(this), {
              channelId: this.channelId,
              streamAccessName: '',
            })
          }
        }
      },
      class BackButton extends this {
        override _getFocused() {
          return this.tag('BackButton') || this
        }

        override _handleUp() {
          this._setState('ContinueButton')
        }

        override _handleEnter() {
          this.fireAncestors('$trackCtaAnalytics', this.dismissText)
          ModalManager.close()
        }
      },
    ]
  }
}
