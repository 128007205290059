import Main from '../Main/Main'
import { OlympicsHubFeaturedRequestConfig, OlympicsHubRequestConfig } from './request'

export default class OlympicsHub extends Main {
  $getLoadAnnounce(): string {
    return 'All Sports'
  }
  override mainRequestConfig = OlympicsHubRequestConfig
  override featuredRequestConfig = OlympicsHubFeaturedRequestConfig
}
