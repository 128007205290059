import { Lightning, Router, Registry } from '@lightningjs/sdk'
import LaunchDarklyFeatureFlags from '../../../../lib/launchDarkly/LaunchDarklyFeatureFlags'
import LaunchDarklySingleton from '../../../../lib/launchDarkly/LaunchDarkly'
import { HasEndCard, WithEndCard } from './WithEndCard'

import moment from 'moment-timezone'
import { getAdjustedReferringShelf } from '../../../../helpers'
import { END_CARD_SLE_AUTOPLAY_TIME, PROGRAMMING_TYPES, ROUTE } from '../../../../constants'
import { BingeEndcardEvent } from '../../../../player/model/event'
import PlayerStoreSingleton from '../../../../store/PlayerStore/PlayerStore'

export const WithEndCardSLE = <T extends Lightning.Component.Constructor<HasEndCard>>(
  component: T
) =>
  class extends WithEndCard(component) {
    _sleEndcardEnabled: boolean
    _emptyEndcardTimer: number

    override _autoplayTime = END_CARD_SLE_AUTOPLAY_TIME

    override _construct() {
      super._construct()
      this._autoplayTime =
        LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.sleBingeTimer) ||
        END_CARD_SLE_AUTOPLAY_TIME
      this._sleEndcardEnabled = LaunchDarklySingleton.getFeatureFlag(
        LaunchDarklyFeatureFlags.enableSleBinge
      )
    }

    override _onStreamEnd() {
      this._setState('EmptyPlayerEndCardSLE')
      this._endCard.transparentBG = false
      this._mediaPlayerEnded = true
    }

    override _playerEventsHandler(event: any) {
      super._playerEventsHandler(event)
      if (event instanceof BingeEndcardEvent && this._sleEndcardEnabled) {
        const { stream } = PlayerStoreSingleton
        const videoId = stream && 'pid' in stream && stream.pid
        if (videoId) {
          this._endCard.queryEndCardsSLE(videoId, PROGRAMMING_TYPES.SLE, 'SLE').then((endCards) => {
            if (endCards) {
              this._setState('PlayerEndCardSLE')
            }
          })
        }
      }
    }

    _trackOpenEndcard() {
      this._endCardImpressionTimeStamp = moment()

      this._analyticsDelegate.fireEndCardImpression({
        endCardTime: this._autoplayTime,
        ...this._endCard.getAnalyticsData(),
        shelf: getAdjustedReferringShelf(),
      })
    }

    static _states() {
      return [
        // @ts-expect-error can't type a static method in an interface
        ...super._states(),
        class PlayerEndCardSLE extends this {
          override $enter() {
            this._trackOpenEndcard()

            this._additionalUIUpdates({
              endCardBGTransparency: true,
              endCardTransparency: false,
            })
          }

          override $exit() {
            this._endCardImpressionTimeStamp = null
          }

          override _getFocused() {
            return this._endCard || this
          }

          override _captureBack(_: any) {
            this._closeEndCard()
          }

          override _handleUp(): boolean {
            return true
          }

          override _handleDown(): boolean {
            return true
          }
        },
        class EmptyPlayerEndCardSLE extends this {
          _closeSLEEndcard() {
            this._closePlayer()
            this._endCard.alpha = 0
            this._endCardsShown = false
            this._endCard.transparent = false
            this._endCard.transparentBG = false
            this._endCard.SFVOD_BG = false
          }

          override $enter() {
            this._trackOpenEndcard()
            this._closePlayer()

            this._additionalUIUpdates({
              endCardEmptyBGTransparency: true,
              endCardBGTransparency: false,
              endCardTransparency: false,
            })

            this._emptyEndcardTimer = Registry.setTimeout(
              () => this._handleEnter(),
              this._autoplayTime * 1000
            )
          }
          override $exit() {
            this._clearTimer()
            this._endCardImpressionTimeStamp = null
          }

          override _handleEnter() {
            this._closeSLEEndcard()
            Router.navigate(ROUTE.sports)
          }

          override _handleBack() {
            Router.back()
          }

          _clearTimer() {
            if (this._emptyEndcardTimer) Registry.clearTimout(this._emptyEndcardTimer)
          }
        },
      ]
    }
  }
