import { Language, Router } from '@lightningjs/sdk'

import { ActivationStrategy } from './ActivationStrategy'
import { LiveStreamManager } from '../../../../lib/LiveStreamManager'
import PlayerStoreSingleton from '../../../../store/PlayerStore/PlayerStore'
import { setEpgChannel } from '../../../../store/PlayerStore/actions/epg'
import { clearLemonade, setStream } from '../../../../store/PlayerStore/actions'
import { ACTIVATION_TYPE } from '../constants'
import { ROUTE } from '../../../../constants'

export class LinearActivationStrategy extends ActivationStrategy {
  override drawText() {
    const title = Language.translate('live_stream_link_msg')
    this._ctx.tag('Title').text.text = title
    return [title]
  }

  override async success(activationType: ACTIVATION_TYPE): Promise<void> {
    const { channelId, streamAccessName, machineName, callSign } = this._ctx._stream
    LiveStreamManager.set(channelId, streamAccessName, callSign)
    if (PlayerStoreSingleton.epg.streams.length > 0) {
      PlayerStoreSingleton.dispatch(setEpgChannel(channelId, streamAccessName, machineName))
    }
    await PlayerStoreSingleton.dispatch(clearLemonade())
    await PlayerStoreSingleton.dispatch(setStream(this._ctx._stream, this._ctx._program))
    super.success(activationType)
  }

  override async _redirect() {
    if (Router.getActiveHash() !== ROUTE.live) {
      Router.navigate(ROUTE.live)
    }
  }
}
