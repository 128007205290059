import RouterUtil from '../../../../util/RouterUtil'
import pageAttributes from './pageAttributes'
import { MPARTICLE_DEFAULT_ATTR_VALUE, ROUTE } from '../../../../constants'
import { getBrandName } from '../../../../helpers'

type Attribute = { name: string; type: string }
const pageAttributeFallback = {
  name: MPARTICLE_DEFAULT_ATTR_VALUE,
  type: MPARTICLE_DEFAULT_ATTR_VALUE,
}

const getPageAttributes = (
  path: string | null | false | undefined = null,
  current = true
): Attribute => {
  if (!path) path = current ? RouterUtil.current() : RouterUtil.getPrevRoute()
  const page = typeof path === 'string' ? pageAttributes[path as ROUTE] : undefined
  const attributes = page?.[getBrandName() as string] || page?.default
  if (!attributes) return pageAttributeFallback
  if (typeof attributes?.name === 'function') return { ...attributes, name: attributes.name() }
  return attributes as Attribute
}

export const getReferrer = () => {
  return getPageAttributes(null, false).name
}

export const getCurrentPageType = () => {
  return getPageAttributes().type
}

export default getPageAttributes
