import Collection from './Collection'
import { GuideStream, Container } from '../index'

export default class Guide extends Collection {
  constructor(obj = {}) {
    super(obj)
    this.obj.schedules = this.mapSchedules(this.obj.schedules)
    this.obj.streams = this.mapStreams(this.obj.streams)
  }

  mapSchedules(schedules: any) {
    return schedules.map((schedule: any) => {
      return new Container(schedule)
    })
  }

  mapStreams(streams: any) {
    return streams.map((stream: any) => {
      return new GuideStream(stream)
    })
  }

  get slots() {
    return this.obj.slots
  }

  get schedules() {
    return this.obj.schedules
  }

  get streams() {
    return this.obj.streams
  }
}
