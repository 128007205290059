import getVodAttributes from './getVodAttributes'
import {
  getEndCardCommonAttributes,
  getEndCardRecommendationsAttributes,
} from './getEndCardGlobalAttributes'

import { setDefaultValue } from '../../../../helpers/analytics'
import { MPARTICLE_DEFAULT_ATTR_VALUE, ENTITLEMENT } from '../../../../constants'

const END_CARD_OUTCOMES = {
  AUTO_PLAY: 'Auto Play',
  PLAY_NOW: 'Play Now',
  CLOSE: 'Close',
  REPLAY: 'Replay',
}

const END_CARD_SELECTED = {
  DEFAULT: 'Default',
  ALTERNATE_1: 'Alternate 1',
}

type END_CARD_ATTRIBUTES = {
  shelf?: any
  ccLanguage?: any
  previousVideo?: any
  previousVideoType?: any
  video?: any
  destination?: any
  recommendation?: any
  recommendationAlternate1?: any
  endCardTime?: number
  isAutoPlay?: boolean
  isEndCardSelected?: boolean
  isRestarting?: boolean
  isDefault?: boolean
  duration?: any
}

const getEndCardAttributes = (params: END_CARD_ATTRIBUTES = {}) => {
  const {
    shelf,
    ccLanguage,
    previousVideo,
    previousVideoType,
    video,
    destination,
    recommendation,
    recommendationAlternate1,
    endCardTime,
    isAutoPlay,
    isEndCardSelected,
    isRestarting,
    isDefault,
    duration,
  } = params
  const endCardOutcome = isEndCardSelected
    ? isAutoPlay
      ? END_CARD_OUTCOMES.AUTO_PLAY
      : END_CARD_OUTCOMES.PLAY_NOW
    : isRestarting
    ? END_CARD_OUTCOMES.REPLAY
    : END_CARD_OUTCOMES.CLOSE
  const endCardSelected = isEndCardSelected
    ? isDefault
      ? END_CARD_SELECTED.DEFAULT
      : END_CARD_SELECTED.ALTERNATE_1
    : MPARTICLE_DEFAULT_ATTR_VALUE

  return setDefaultValue(
    {
      ...getVodAttributes(video, ccLanguage, previousVideo, previousVideoType),
      ...getEndCardRecommendationsAttributes({
        recommendation,
        recommendationAlternate1,
      }),
      ...getEndCardCommonAttributes({ video, endCardTime, shelf }),
      'End Card Outcome': endCardOutcome,
      'End Card Selected': endCardSelected,
      Duration: duration,
      'Destination Brand': destination?.brand?.title,
      'Destination Show': destination?.series,
      'Destination Sport': destination?.destinationSport,
      'Destination League': destination?.destinationLeague,
      'Destination Video ID': destination?.mpxGuid,
      'Destination Video Type': destination?.programmingType,
      'Destination Episode Title': destination?.title,
      'Destination Entitlement': destination
        ? destination?.locked
          ? ENTITLEMENT.ENTITLED
          : ENTITLEMENT.FREE
        : MPARTICLE_DEFAULT_ATTR_VALUE,
    },
    MPARTICLE_DEFAULT_ATTR_VALUE
  )
}

export default getEndCardAttributes
