import { PlayerStates } from '../../../constants'

export const PlayerMediaOptionsStateFactory = (base: any) =>
  class MediaOptions extends base {
    $enter() {
      this._mediaOptionsPanel._showMediaOptionsMenu(true)
    }

    $exit() {
      this._mediaOptionsPanel._showMediaOptionsMenu(false)
    }

    _getFocused() {
      return this._mediaOptionsPanel || this
    }

    _captureKey() {
      return false
    }

    $ccLanguageUpdated({ value }: any) {
      if (value === 'off') {
        this._analyticsDelegate?.fireClick('Closed Caption Off')
      } else {
        this._analyticsDelegate?.fireClick('Closed Caption On')
      }
      this._player?.enableSubtitles?.(value)
      this._captions._ccLanguageUpdated(value)
    }

    $ccSettingUpdated({ ccSettingItem, selectedValue }: any) {
      this._captions._ccSettingUpdated(ccSettingItem, selectedValue)
    }

    $resetCCSettings() {
      this._captions.updateUI()
    }

    _captureBack(e: any) {
      if (!this._mediaOptionsPanel._handleBackButton()) {
        this._setState(PlayerStates.LPPlayer_Controls)
      }
      e.preventDefault()
      e.stopPropagation()
    }
  }
