import mParticle from '@mparticle/web-sdk'
import getLinearProgramEndAttributes from '../attributes/getLinearProgramEndAttributes'

const getEvent = () => ({
  getAttributes: getLinearProgramEndAttributes,
  getCategory: () => mParticle.EventType?.UserContent,
  type: 'Linear Program End',
  injectGlobals: true,
})

export default getEvent
