import { Lightning, Language } from '@lightningjs/sdk'
import BaseComponent from '../../../../base'
import { COLORS, FONT_FACE, TEXT_ALIGN } from '../../../../../constants'

export class LiveBadge extends BaseComponent {
  static override _template() {
    return {
      w: 60,
      h: 24,
      Container: {},
      Live: {
        x: 30,
        y: 2,
        mount: 0.5,
        text: {
          textColor: COLORS.white,
          textAlign: TEXT_ALIGN.center,
          fontSize: 18,
          fontFace: FONT_FACE.bold,
          color: COLORS.white,
          text: Language.translate('live'),
        },
      },
    }
  }

  override _init() {
    super._init()
    this.tag('Live').on('txLoaded', this._drawContainer.bind(this))
  }

  _drawContainer() {
    const w = this.tag('Live').finalW
    if (!w) return
    this.tag('Container').patch({
      w: w + 14,
      h: 24,
      x: 30,
      mount: 0.5,
      rect: true,
      color: COLORS.warmGray1,
      shader: {
        type: Lightning.shaders.RoundedRectangle,
        radius: 3,
        fillColor: COLORS.red2,
      },
    })
  }

  override _setTranslation() {
    this.tag('Live').patch({ label: Language.translate('live') })
  }
}
