import { Lightning, Registry } from '@lightningjs/sdk'
import { NonLinearAd, PauseAdVariant } from '@sky-uk-ott/core-video-sdk-js'

import {
  toEnterPauseAdsPatch,
  toExitPauseAdsPatch,
  toPauseAdOverlay,
  getPauseAdImageTexture,
} from './pauseAdHelpers'
import { PlayerControlsStates } from '../../../../constants'
import TVPlatform from '../../../../lib/tv-platform'
import { ErrorType } from '../../../../lib/tv-platform/types'

export const PauseAdControlsStateFactory = (base: any) =>
  class PauseAdControls extends base {
    _timeout: number | null | undefined
    _adPlayedCount = 0
    _currentAd: NonLinearAd<PauseAdVariant> | null | undefined
    _pauseAdImage!: Lightning.Element

    $enter() {
      try {
        // Adjust UI to handle Pause Ads layout
        this.patch(toEnterPauseAdsPatch())
        this._pauseAdImage = this.tag('PauseAdImage')
        this._pauseAdImage.once('txLoaded', () => this.patch(toPauseAdOverlay()))
        // begin the process of setting up ad placement and timer for rotating ads
        this.setupAdPlacement()
      } catch (e) {
        TVPlatform.reportError({
          type: ErrorType.OTHER,
          description: `PauseAdControls error $enter: ${e}`,
        })
      }
    }

    $exit() {
      try {
        // This triggers nothing yet but we may end up needing it
        this.fireAncestors('$PauseAdsEnd')
        this.destroy()
      } catch (e) {
        TVPlatform.reportError({
          type: ErrorType.OTHER,
          description: `PauseAdControls error $exit: ${e}`,
        })
      }
    }

    // listen for any key press
    _handleKey() {
      // Make sure we fire the ended event
      this._currentAd?.ended()
      this.clearPauseAdsEventData?.()
      // Return ui to non pause ad ui
      this.patch(toExitPauseAdsPatch())
      this._setState(PlayerControlsStates.PlayOrPause)
    }

    _captureBack() {
      this._handleKey()
    }

    setupAdPlacement() {
      // Make sure we start at 0 played count
      this._adPlayedCount = 0
      // begin updating ad placemen
      this.updatePlacement()
    }

    updatePlacement() {
      const newAd = this.pauseAdEventData?.consume?.()
      // Early return if ad is undefined
      if (!newAd) return this._exitIfEmpty()
      // If ad is not undefined set it to current
      this._currentAd = newAd
      // comes from LD flag, how many times should we show a new ad
      const { refreshMax } = this.pauseAdRefreshData
      // update count
      this._adPlayedCount += 1
      const limitIsNotReached = this._adPlayedCount <= refreshMax
      if (this._currentAd?.variants?.length) {
        this._renderAd(this._currentAd.variants?.[0]?.resourceUri)
        // If we haven't hit the max refresh count
        if (limitIsNotReached) this.startRefresh()
      } else {
        // Call ended(), even if the variants are empty, and then update the ad
        this._currentAd?.ended()
        // If the limit is reached, try to exit the state
        if (!limitIsNotReached) return this._exitIfEmpty()
        this.updatePlacement()
      }
    }

    _renderAd(resourceUri?: string): void {
      if (resourceUri) {
        // Patch with latest img from current variant
        this._pauseAdImage.patch(getPauseAdImageTexture(resourceUri))
      }
      // Call the shown method when the ad is rendered
      this._currentAd?.shown?.()
    }

    _exitIfEmpty(): void {
      if (!this._pauseAdImage.texture) this._handleKey()
    }

    startRefresh() {
      const { refreshRate } = this.pauseAdRefreshData
      // Set timeout with LD refreshRate for pause ad,
      this._timeout = Registry.setTimeout(() => {
        /**
         * Calling the ended() function before switching to the next ad
         */
        this._currentAd?.ended()
        this.updatePlacement()
      }, refreshRate * 1000)
    }

    destroy() {
      if (this._timeout) {
        Registry.clearTimeout(this._timeout)
      }
      this._currentAd?.ended()
      this._timeout = null
      this._adPlayedCount = 0
    }
  }
