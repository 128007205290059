import { setDefaultValue } from '../../../../helpers/analytics'
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants'

type ClickAttributesParams = {
  shelf?: any
  name?: string
  brand?: string
  show?: string
  season?: any
  mvpdBypass?: any
  favorite?: any
  onetrust?: any
  video?: any
}
const getClickAttributes = (params: ClickAttributesParams = {}) => {
  const { shelf = {} } = params
  const shelfAttributes = shelf
    ? {
        'Custom Shelf Title': shelf?.listTitle,
      }
    : { 'Custom Shelf Title': MPARTICLE_DEFAULT_ATTR_VALUE }

  return setDefaultValue(
    {
      'Item Clicked Name': params.name,
      Brand: params.brand,
      Show: params.show,
      Season: params.season,
      'MVPD Bypass': params.mvpdBypass || 'False',
      'Item Add Remove': params.favorite,
      //@Todo: we don't have onetrust yet...
      Toggle: params.onetrust,
      'Video ID': params.video,
      ...shelfAttributes,
    },
    MPARTICLE_DEFAULT_ATTR_VALUE
  )
}

export default getClickAttributes
