export enum OLY_IMPOLICY {
  HERO = 'hero_large_1920_590',
  PLACEHOLDER = 'placeholder_32_18',
  RECT_LARGE_1920_1080 = 'rect_large_1920_1080',
  RECT_MEDIUM_1024_576 = 'rect_medium_1024_576',
  RECT_SMEDIUM_640_360 = 'rect_smedium_640_360',
  RECT_SMALL_384_216 = 'rect_small_384_216',
  SQUARE = 'square_medium_348',
  PORTRAIT = 'vert_large_489_725',
  NONE = 'none',
}

export enum IMAGE_RESIZE_MODE {
  CONTAIN = 'contain',
  COVER = 'cover',
}
