import { PlayerStoreActions, setUnknownError, throwStoreError } from '.'
import { OmitConflicting, Unpacked } from '../../../../types/global'
import { Shelf } from '../../../api/models'
import { EventTile } from '../../../components'
import { BffPage } from '../../../graphql/mappers/bffPage'
import { LiveStreamManager } from '../../../lib/LiveStreamManager'
import { looseStringCompare } from '../../../util/comparison'
import PlayerStoreSingleton from '../PlayerStore'
import { State } from '../types/store'
import { SectionFragment } from '../../../graphql/generated/types'

type BffGuideData = Extract<Unpacked<BffPage['sections']>, { data: { schedules: any } }>['data']
type BffSchedules = BffGuideData['schedules']
type BffEventSchedule = Extract<Unpacked<BffPage['sections']>, { data: { events: any } }>['data']
type BffGuideProgram = NonNullable<
  Unpacked<NonNullable<Unpacked<BffSchedules>>['data']['programs']>
>

export type EpgProgram = Partial<OmitConflicting<BffGuideProgram['guideProgramData']>> &
  OmitConflicting<NonNullable<NonNullable<BffGuideProgram['analytics']>['currentVideo']>> & {
    component?: string
  }
export type EpgStream = NonNullable<Unpacked<BffGuideData['streams']>>['data'] | null
export const isEpgProgram = (input: any): input is EpgProgram => input && 'programmingType' in input
export const isEpgStream = (input: any): input is EpgStream => input && 'brandDisplayTitle' in input

export type EpgSchedule = {
  programs: EpgProgram[]
}

export type Epg = {
  index: number
  programIndex: number
  start: string
  slots: string[]
  streams: EpgStream[]
  schedules: EpgSchedule[]
  events: EventTile[]
  upcoming: Shelf | null
}

const transformStreams = (streams?: BffGuideData['streams']): EpgStream[] =>
  streams ? streams.map((item) => item?.data || null) : []

const transformSchedules = (schedules: BffSchedules): EpgSchedule[] =>
  schedules
    ? schedules.map((item) => ({
        programs:
          item?.data.programs.map((program) => ({
            component: program?.component,
            ...(program?.analytics?.currentVideo || {}),
            ...(program?.guideProgramData || {}),
          })) || [],
      }))
    : []

// Type guards
const isGuide = (input: any): input is BffGuideData =>
  input?.slots && input?.streams && input?.schedules
const isEventSchedule = (input: any): input is BffEventSchedule => input?.events
const isShelf = (input: any): input is Shelf => input?.component === 'Shelf'

const mergeEvents = (models?: BffPage['sections']): Epg | undefined => {
  if (!models || !models.length) return undefined
  return models.reduce(
    (acc, model) => {
      if (model && 'data' in model) {
        const { data } = model
        if (isGuide(data)) {
          const streams = transformStreams(data.streams)
          const { stream } = PlayerStoreSingleton
          const { channelId, accessName } = LiveStreamManager.get()
          const index = streams.findIndex(
            filterByChannelId(
              stream?.channelId || channelId || '',
              stream?.streamAccessName || accessName || '',
              (stream as EpgStream)?.machineName || ''
            )
          )
          acc.slots = [...acc.slots, ...(('slots' in data && data.slots) || [])] as string[]
          acc.streams = [...acc.streams, ...transformStreams(data.streams)]
          acc.schedules = [...acc.schedules, ...transformSchedules(data.schedules)]
          acc.start = data.start
          acc.index = index
        } else if (isEventSchedule(data)) {
          acc.events = [...acc.events, ...(data.events || [])] as EventTile[]
        } else if (isShelf(model)) {
          acc.upcoming = model
        }
      }
      return acc
    },
    {
      index: 0,
      programIndex: 0,
      start: '',
      slots: [],
      streams: [],
      schedules: [],
      events: [],
      upcoming: null,
      progress: 0,
    } as Epg
  )
}

export const filterByChannelId =
  (channelId = '', streamAccessName: string, machineName: string) =>
  (stream: EpgStream) =>
    looseStringCompare(channelId, stream?.channelId) &&
    (!machineName || looseStringCompare(machineName, stream?.machineName)) &&
    (!streamAccessName || stream?.streamAccessName === streamAccessName)

export const setEpgChannel =
  (channelId: string, streamAccessName: string, machineName?: string) => (state: State) => {
    const index = state.epg?.streams.findIndex(
      filterByChannelId(channelId, streamAccessName, machineName || '')
    )
    if (index > -1) {
      return {
        type: PlayerStoreActions.UPDATE_EPG_CHANNEL_INDEX,
        payload: index,
      }
    } else {
      return throwStoreError({
        channelId,
        streamAccessName,
      })
    }
  }

export const setEpgData = (data: BffPage | { sections: SectionFragment[] }) => () => {
  return data
    ? {
        type: PlayerStoreActions.SET_EPG,
        payload: mergeEvents(data.sections as []),
      }
    : setUnknownError()
}

export const nextScheduleIndex = () => ({
  type: PlayerStoreActions.NEXT_EPG_SCHEDULE_INDEX,
})
