import { getTimerToEndTile } from '../../../../helpers'

export const getUpcomingStateClass = (context: any) =>
  class UpcomingState extends context {
    $enter() {
      const tuneInTitle = this._item.tertiaryTitle || this._item.upcomingTuneIn
      if (tuneInTitle) {
        this.tuneInTitle = tuneInTitle
      } else {
        this.tag('Border').alpha = 0
      }
      if (this._item.upcomingCtaText) this.buttonText = this._item.upcomingCtaText.toUpperCase()

      this._timerChangeToLive = getTimerToEndTile(this._item.startTime, () => {
        this._setState('LiveState')
      })
      this.secondaryTitleLines = 2
      this.announce = this._item.upcomingAriaLabel
    }

    _updateButtonColor() {
      if (this._item.upcomingCtaText) {
        this.buttonColor = `0xff${this._item.upcomingCtaColor.replace('#', '')}`
      }
    }
  }
