import { Language, Lightning, Registry, Router, Utils } from '@lightningjs/sdk'
import { ROUTE } from '../../constants'
import { sendMetric } from '../../lib/analytics/Analytics'
import { EVENTS } from '../../lib/analytics/types'

export default class ProfileItem extends Lightning.Component {
  _route: any
  focused: any
  static get route() {
    return 'settings'
  }

  static get activeOn() {
    return [ProfileItem.route, ROUTE.profile]
  }

  static get focusImage() {
    return Utils.asset('images/profile-focus.png')
  }

  static get unfocusImage() {
    return Utils.asset(
      ProfileItem.activeOn.includes(Router.getActiveRoute() || '')
        ? 'images/profile-selected.png'
        : 'images/profile.png'
    )
  }

  static override _template() {
    return {
      src: ProfileItem.unfocusImage,
      announce: `${Language.translate('profile')}, Button`,
    }
  }

  get route() {
    return this._route
  }

  override _focus() {
    this.patch({ src: ProfileItem.focusImage })
  }

  override _unfocus() {
    this.patch({ src: ProfileItem.unfocusImage })
  }

  override _handleDown() {
    Router.restoreFocus()
  }

  override _handleRight() {
    return true
  }

  override _handleUp() {
    return true
  }

  update() {
    this.focused
      ? this.patch({ src: ProfileItem.focusImage })
      : this.patch({ src: ProfileItem.unfocusImage })
  }

  override _handleEnter() {
    this.fireAncestors('$onEnterCallback')
    sendMetric(EVENTS.CLICK, {
      name: Language.translate('profile'),
      shelf: { listTitle: 'Global Nav' },
    })
    if (Router.getActiveRoute() !== ProfileItem.route) {
      Router.navigate(ProfileItem.route, { customShelfTitle: 'Global Nav' })
      Registry.setTimeout(() => Router.focusPage())
    } else {
      Router.restoreFocus()
    }
  }
}
