let styles: any
let compiled = false

const compile = (style: any) => {
  if (!compiled) {
    const tags = Object.keys(style.tags)
    compiled = true

    styles = tags.reduce((a, v) => {
      a.set(v, style.tags[v])
      return a
    }, new Map())
  }

  return styles
}

const getTag = (tag: any) => {
  if (styles.has(tag)) {
    return styles.get(tag)
  }
  return null
}

export default {
  compile,
  getTag,
}
