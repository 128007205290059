import { Language } from '@lightningjs/sdk'

import BaseComponent from '../../../../../base'

import { COLORS, FLEX_DIRECTION } from '../../../../../../constants'
import { throttle } from 'lodash'
import { SecondaryControlButton } from './SecondaryControlButton'

export enum SecondaryControlButtons {
  BackToLiveButton = 'BackToLiveButton',
  LiveGuideButton = 'LiveGuideButton',
  MediaOptions = 'MediaOptions',
  LiveToVod = 'LiveToVod',
}

const buttonMappings: Record<
  SecondaryControlButtons,
  { label: string; icon?: string; ancestor: string; bgColor?: COLORS; defaultVisibility?: boolean }
> = {
  [SecondaryControlButtons.BackToLiveButton]: {
    defaultVisibility: false,
    label: 'go_to_live_v2',
    icon: 'images/player/live.png',
    ancestor: '$backToLive',
    bgColor: COLORS.red,
  },
  [SecondaryControlButtons.LiveGuideButton]: {
    label: 'view_live_guide_v2',
    icon: 'images/player/guide.png',
    ancestor: '$openLiveGuide',
  },
  [SecondaryControlButtons.MediaOptions]: {
    label: 'media_options',
    icon: 'images/player/cc2.png',
    ancestor: '$showMediaOptionsMenu',
  },
  [SecondaryControlButtons.LiveToVod]: {
    defaultVisibility: false,
    label: '',
    ancestor: '$onLiveToVodButtonEnter',
  },
}

export default class SecondaryControls extends BaseComponent {
  _buttonsOrder: SecondaryControlButtons[] = []
  _exclusions: SecondaryControlButtons[] = []
  _focusedIndex = 0

  toggle = throttle(
    (tag: SecondaryControlButtons, visible: boolean) => {
      // Avoid updating the button too frequently
      const el = this.tag(tag)
      if (!el) return
      const currentVisibility = el.visible
      if (currentVisibility === visible) return
      el.visible = visible
      if (!visible) {
        const currentlyFocused =
          this._buttonsOrder.findIndex((i) => i === tag) === this._focusedIndex
        if (currentlyFocused) this._recalculateFocusIndex(0)
      }
    },
    1000,
    { leading: true }
  )

  setLabel(button: SecondaryControlButtons, label: string) {
    this.tag(button)?.patch({ label })
  }

  override _setTranslation() {
    this._buttonsOrder.forEach((button) => {
      this.tag(button).patch({
        label: Language.translate(buttonMappings[button].label),
      })
    })
  }

  set buttons(buttons: SecondaryControlButtons[]) {
    this._buttonsOrder = buttons
    this.patch(
      buttons.reduce(
        (acc, i) => ({
          ...acc,
          [i]: {
            visible: buttonMappings[i].defaultVisibility ?? true,
            flexItem: {
              marginLeft: 20,
            },
            label: Language.translate(buttonMappings[i].label),
            icon: buttonMappings[i].icon,
            type: SecondaryControlButton,
            bgColor: buttonMappings[i].bgColor,
          },
        }),
        {}
      )
    )
  }

  static override _template() {
    return {
      flex: {
        direction: FLEX_DIRECTION.row,
      },
    }
  }

  override _handleLeft() {
    if (this._focusedIndex === 0) return
    this._recalculateFocusIndex(this._focusedIndex - 1, true)
  }

  override _handleRight() {
    if (this._focusedIndex === this._buttonsOrder.length - 1) return
    this._recalculateFocusIndex(this._focusedIndex + 1)
  }

  override _handleEnter() {
    const tag = this._buttonsOrder[this._focusedIndex]
    const ancestor = tag && buttonMappings[tag as keyof typeof buttonMappings].ancestor
    if (ancestor) this.fireAncestors(ancestor as any)
  }

  override _focus() {
    this._recalculateFocusIndex(0)
  }

  override _getFocused() {
    const tag = this._buttonsOrder[this._focusedIndex]
    return tag ? this.tag(tag) : this
  }

  _recalculateFocusIndex(startIndex: number, reverse?: boolean) {
    const els = this.childList.get()
    for (
      let i = startIndex, n = reverse ? 0 : els.length;
      reverse ? i >= n : i < n;
      reverse ? i-- : i++
    ) {
      const el = els[i] as any
      if (el?.visible && el?.label) {
        this._focusedIndex = i
        break
      }
    }
  }
}
