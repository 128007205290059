import { setDefaultValue } from '../../../../helpers'
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants'
import { IMarketingModuleAnalytics } from '../../../../api/types/bffTypes'

export interface IMarketingModuleVideoPreviewParams extends IMarketingModuleAnalytics {
  brand: string | null | undefined
  customShelfPosition: number
  episodeTitle: string | null | undefined
  episodeNumber: number | null | undefined
  seasonNumber: number | null | undefined
  percentComplete: number
  durationWatched: number
  league: string | null | undefined
  locked: boolean
  duration: number
  v4ID: string | null
  previewType: string
  sport: string | null | undefined
}

const getMarketingModuleVideoPreviewAttributes = (params: IMarketingModuleVideoPreviewParams) => {
  const {
    brand,
    customShelfPosition,
    episodeNumber,
    episodeTitle,
    seasonNumber,
    league,
    locked,
    percentComplete,
    durationWatched,
    listTitle,
    previewType,
    sponsorName,
    duration,
    v4ID,
    sport,
  } = params

  return setDefaultValue(
    {
      '% Complete': percentComplete,
      Brand: brand,
      'Content Position': 1,
      'Custom Shelf Position': customShelfPosition,
      'Custom Shelf Title': listTitle,
      'Custom Shelf Type': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Duration Watched': durationWatched,
      Entitlement: locked ? 'Entitled' : 'Free',
      'Episode Number': episodeNumber,
      'Episode Title': episodeTitle,
      League: league,
      'Preview Type': previewType,
      Season: seasonNumber,
      Show: MPARTICLE_DEFAULT_ATTR_VALUE,
      Sponsor: sponsorName,
      Sport: sport,
      'Video Duration': duration,
      'Video ID': v4ID,
    },
    MPARTICLE_DEFAULT_ATTR_VALUE
  )
}

export default getMarketingModuleVideoPreviewAttributes
