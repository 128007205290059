import { COMPONENT_TYPES } from '../../constants'
import { GqlFetchResult } from '../client/types'
import { EndCardPageQuery } from '../generated/types'

const isEvent = (component: any) =>
  [COMPONENT_TYPES.UPCOMING_LIVE_TILE, COMPONENT_TYPES.REPLAY_TILE].includes(component)

const setSource =
  (source: string) =>
  (...components: any) =>
    components.forEach((component: any) => {
      if (component.data.tile) component.data.tile.source = source
    })

const endCardMapper = (page: GqlFetchResult<EndCardPageQuery>, source: string) => {
  const data = page.data?.endCard
  if (!data) return null
  if (
    'tileOne' in data.data &&
    'tileTwo' in data.data &&
    data.data.tileOne?.data.tile &&
    data.data.tileTwo?.data.tile
  ) {
    setSource(source)(data.data.tileOne, data.data.tileTwo)
    if (isEvent(data?.data?.tileOne?.data?.tile?.component)) {
      data.data.tileOne.data.tagLine = 'Watch Next'
    }
    if (isEvent(data?.data?.tileTwo?.data?.tile?.component)) {
      data.data.tileTwo.data.tagLine = ''
    }
  }
  // Adding the source so that we can manage differences of SLE and FER

  return {
    endCardData: data,
  }
}

export default endCardMapper
