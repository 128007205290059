import { CoreVideoPlayer } from '../CoreVideoPlayer/CoreVideoPlayer'
import { PlayerMode, PlayerOptions } from './PlayerInterface'

export enum PLAYER_TYPE {
  MAIN = 'main',
  PREVIEW = 'preview',
  BACKGROUND = 'background',
}

export class PlayerFactory {
  _players: Partial<Record<PLAYER_TYPE, CoreVideoPlayer | null>> = {}

  player(type: PLAYER_TYPE, options?: Partial<PlayerOptions>) {
    if (!type) {
      throw new Error('Player Factory:: Player type must be specified')
    }
    if (!Object.values(PLAYER_TYPE).includes(type)) {
      throw new Error(`Player Factory:: Invalid player type ${type}`)
    }
    if (this._players[type]) {
      return this._players[type]
    }
    //We don't have it so create it
    this._players[type] = new CoreVideoPlayer(
      type === PLAYER_TYPE.MAIN ? PlayerMode.FULL : PlayerMode.MINIMAL,
      options
    )
    return this._players[type]
  }

  getPlayerIfActive(type: PLAYER_TYPE) {
    return this._players[type]
  }

  clearPlayer(type: PLAYER_TYPE) {
    if (this._players[type]) {
      this._players[type]?._detach()
      this._players[type] = null
    }
  }
}

export const PlayerFactorySingleton = new PlayerFactory()
