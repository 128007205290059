import List from './List'
import ItemWrapper from './items/ItemWrapper'
import Loader from './loader/Loader'

import { setSmooth } from '../helpers'
import { END_OF_ROW } from '../constants'
import Announcer from '../lib/tts/Announcer'
import TVPlatform from '../lib/tv-platform'
import { ErrorType } from '../lib/tv-platform/types'

const NUMBER_OF_ITEMS_TO_LOAD_MORE = 4

export default class ContinuousScroll extends List {
  _groupIds: any
  _next: any
  override _noOfItemsToScroll: any
  _previous: any
  _requestInProgress: any
  spawner: any
  static override _template() {
    return {
      ...super._template(),
      Loader: {
        alpha: 0,
        type: Loader,
        y: 100,
      },
    }
  }
  override _init() {
    this._requestInProgress = false
    this._index = this._index || 0
  }
  get next() {
    return this._next
  }

  set next(next) {
    this._next = next
  }

  get previous() {
    return this._previous
  }

  set previous(previous) {
    this._previous = previous
  }

  override set noOfItemsToScroll(noOfItems) {
    this._noOfItemsToScroll = noOfItems
  }

  override get noOfItemsToScroll() {
    return 1
  }

  set initialItem(initialItem: any) {
    if (initialItem) {
      this.setIndex(initialItem)
    }
  }

  set groupIds(groupIds) {
    this._groupIds = groupIds
  }

  get groupIds() {
    return this._groupIds
  }

  override _handleRight() {
    if (this._index < this.tag('Items').children.length - 1) {
      const itemsLeft = this.tag('Items').children.length - this._index
      this.setIndex(this._index + 1)

      if (itemsLeft <= NUMBER_OF_ITEMS_TO_LOAD_MORE && !this._requestInProgress)
        this.getMoreItems()
          .then(() => {
            this._hideLoader()
          })
          .catch(() => {
            this._hideLoader()
          })
    } else {
      if (!this._requestInProgress) Announcer.announce(END_OF_ROW)

      return false
    }
  }

  override _handleLeft() {
    if (this._index > 0) {
      this.setIndex(this._index - 1)

      if (this._index <= NUMBER_OF_ITEMS_TO_LOAD_MORE && !this._requestInProgress) {
        this.getPreviousItems()
          .then(() => {
            this._hideLoader()
          })
          .catch((err) => {
            TVPlatform.reportError({
              type: ErrorType.NETWORK,
              description: 'ContinuousScroll:: Error getting previous data',
              payload: err,
            })
            this._hideLoader()
          })
      }
    } else {
      return false
    }
  }

  _hideLoader() {
    this._requestInProgress = false
    setSmooth(this.tag('Loader'), 'alpha', 0)
  }

  async getMoreItems() {
    if (this.next) {
      setSmooth(this.tag('Loader'), 'alpha', 10)
      this._requestInProgress = true
      const { next, groupIds, items } = await this.spawner.getMoreItems(
        this.next?.data?.queryVariables
      )
      this.next = next
      this.groupIds = [...this.groupIds, ...groupIds]
      this.addItems(items)
    }
  }

  async getPreviousItems() {
    if (this.previous) {
      setSmooth(this.tag('Loader'), 'alpha', 10)
      this._requestInProgress = true
      const { previous, groupIds, items } = await this.spawner.getMoreItems(
        this.previous?.data?.queryVariables
      )
      this.previous = previous
      this.groupIds = [...groupIds, ...this.groupIds]
      this.unshiftItems(items)
    }
  }

  unshiftItems(items: any) {
    this._items = [...items, ...this._items]
    const itemsToAdd = this.createPrevious({ items })

    itemsToAdd.forEach((item: any) => {
      this.tag('Items').childList.addAt(this.stage.c(item), 0)
    })
    this.stage.gc()
    this.setIndex(this._index + items.length)
  }
  override setIndex(index: any) {
    this._index = index
    const noOfItems = this.noOfItemsToScroll || 4
    const newPosition = this.tag('Items').childList.getAt(this._index)?.x
    const groupId = this.groupIds[index]

    setSmooth(this.tag('Items'), 'x', index > noOfItems - 1 ? newPosition * -1 : 0, {
      duration: 0.3,
      delay: 0,
    })

    this.fireAncestors('$scrolledListItemIndex', { index, groupId })
    this._refocus()
  }

  createPrevious({ items }: any) {
    const style = this.getStyle()
    if (!style) {
      console.warn(`List.js ${items} missing style. Check ./src/lib/style.js`)
      return
    }

    const firstElementPosition = this.tag('Items').childList?.first?.x || 0
    this.noOfItemsToScroll = style.noOfListItemsToScroll

    return items.reverse().map((item: any, index: any) => {
      const indexToRender = index
      return {
        type: ItemWrapper,
        itemType: item.itemType,
        item: item.item,
        index: indexToRender,
        x: firstElementPosition - (indexToRender + 1) * style.item.w,
      }
    })
  }
}
