import { Log } from '@lightningjs/sdk'

import FocusScaledButton from './FocusScaledButton'
import {
  LiveToVodButtonStates,
  LiveToVodEvent,
} from '../../pages/Player/delegates/LiveToVodDelegate'
import { Subscription } from 'rxjs'

const LIVE_TO_VOD_TAG = 'LiveToVodButton'

const MAX_STRING_LENGTH_DEFAULT_SIZE = 19
const DEFAULT_UNFOCUS_WIDTH = 269
const DEFAULT_FOCUS_WIDTH = 319
const CUSTOM_UNFOCUS_MULTIPLIER = 15
const CUSTOM_FOCUS_MULTIPLIER = 17

export default class LiveToVodButton extends FocusScaledButton {
  _liveToVodEvents?: Subscription

  override _enterCallback = () => this.fireAncestors('$onLiveToVodButtonEnter')

  static override _template() {
    return {
      ...super._template(),
      y: 700,
      x: 1860,
      mountY: 0.5,
      mountX: 1,
      visible: false,
      unfocusHeight: 55,
      unfocusWidth: DEFAULT_UNFOCUS_WIDTH,
      focusHeight: 64,
      focusWidth: DEFAULT_FOCUS_WIDTH,
      radius: 31,
      fontSize: 28,
      focusFontSize: 32,
      fontFace: 'Regular',
      focusFontColor: 0xff000000,
      unfocusFontColor: 0x80000000,
      focusBackGroundColor: 0xffffffff,
      unfocusBackgroundColor: 0x80ffffff,
      focusBorderColor: 0xffffffff,
      unfocusBorderColor: 0x00ffffff,
      padding: 22,
    }
  }

  override _enable() {
    this._liveToVodEvents?.unsubscribe()
    this._liveToVodEvents = this.fireAncestors('$subscribeToLiveToVodDelegate', this._sync)
  }

  override _disable() {
    this._liveToVodEvents?.unsubscribe()
  }

  _sync = ({ status, label }: LiveToVodEvent) => {
    const messages = {
      [LiveToVodButtonStates.EMPTY_STATE]: `${LIVE_TO_VOD_TAG} empty state, will not show`,
      [LiveToVodButtonStates.PLAY_FROM_BEGINNING]: `${LIVE_TO_VOD_TAG} play from beginning button`,
      [LiveToVodButtonStates.PLAY_NEXT_EPISODE]: `${LIVE_TO_VOD_TAG} play next episode button`,
      [LiveToVodButtonStates.GO_TO_SHOW]: `${LIVE_TO_VOD_TAG} go to show button`,
    }

    Log.info(messages[status])
    const largerThanDefault = label.length > MAX_STRING_LENGTH_DEFAULT_SIZE
    this.unfocusWidth = largerThanDefault
      ? label.length * CUSTOM_UNFOCUS_MULTIPLIER
      : DEFAULT_UNFOCUS_WIDTH
    this.focusWidth = largerThanDefault
      ? label.length * CUSTOM_FOCUS_MULTIPLIER
      : DEFAULT_FOCUS_WIDTH
    this.label = label
    this._updateButton()
  }
}
