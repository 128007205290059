import mParticle from '@mparticle/web-sdk'

const trackSearchResult = () => ({
  getAttributes: (params: any) => params,
  getCategory: () => mParticle.EventType?.UserPreference,
  type: 'Search Result',
  injectGlobals: true,
})

export default trackSearchResult
