import { ALGOLIA_ENTITY_TYPES, formatSecondsAsHhMmSs } from '../../../helpers'
import LabelBadge from '../../badges/LabelBadge'
import { COLORS } from '../../../constants'

export default (tile: any) => {
  if (ALGOLIA_ENTITY_TYPES.SPORTS_VIDEOS !== tile.entityType || !tile.item.video?.duration) return
  const label = formatSecondsAsHhMmSs(tile.item.video?.duration)
  tile.patch({
    Image: {
      VideoDuration: {
        type: LabelBadge,
        label,
        positionX: 10,
        positionY: (h: any) => h - 50,
        backgroundColor: COLORS.black5,
        fontColor: COLORS.white,
        fontSize: 24,
        width: 68,
        height: 36,
      },
    },
  })
}
