import Lightning from '@lightningjs/sdk/src/Lightning'
import TextButton from './TextButton'
import { COLORS, FONT_FACE } from '../../constants'

export default class SkipButton extends Lightning.Component {
  _cuePoint: any
  static override _template() {
    return {
      ButtonContainer: {
        x: 1805,
        y: 906,
        mountX: 1,
        h: 63,
        alpha: this.bindProp('alpha'),
        type: TextButton,
        radius: 31,
        fontSize: 32,
        fontFace: FONT_FACE.regular,
        focusFontColor: COLORS.black,
        unfocusFontColor: COLORS.black4,
        focusBackGroundColor: COLORS.white,
        unfocusBackgroundColor: COLORS.mediumGray3,
        autoWidth: true,
        padding: 22,
        label: this.bindProp('label'),
      },
    }
  }

  override _getFocused() {
    return this.tag('ButtonContainer') || this
  }

  set cuePoint(cuePoint) {
    this._cuePoint = cuePoint
  }

  get cuePoint() {
    return this._cuePoint
  }
}
