import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants'

export const getSmartTileAttributes = (smartTile: any) => {
  const {
    smartTileLabel,
    smartTileLogic,
    smartTileScenario,
    smartTileEpisodeTitle,
    smartTileVideoId,
  } = smartTile || {}

  return {
    'Smart Tile Episode Title': smartTileEpisodeTitle || MPARTICLE_DEFAULT_ATTR_VALUE,
    'Smart Tile Logic': smartTileLogic || MPARTICLE_DEFAULT_ATTR_VALUE,
    'Smart Tile Scenario': smartTileScenario || MPARTICLE_DEFAULT_ATTR_VALUE,
    'Smart Tile Title': smartTileLabel || MPARTICLE_DEFAULT_ATTR_VALUE,
    'Smart Tile Video ID': smartTileVideoId || MPARTICLE_DEFAULT_ATTR_VALUE,
  }
}
