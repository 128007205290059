import { GraphQlClient } from '../../graphql/client'
import {
  UserInteractionsQuery,
  UserInteractionsQueryVariables,
} from '../../graphql/generated/types'
import userInteractionsMapper from '../../graphql/mappers/userInteractions'
import { QueryName } from './bffQuery'

export default async (
  variables: UserInteractionsQueryVariables,
  options?: { middleware?: ((data: any) => void)[] }
) => {
  return await GraphQlClient.query<UserInteractionsQuery>(
    {
      query: QueryName.userInteractions,
      variables,
    },
    options
  ).then(userInteractionsMapper)
}
