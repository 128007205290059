import { Router, Log } from '@lightningjs/sdk'
import mParticle from '@mparticle/web-sdk'
import { get } from 'lodash'
import moment from 'moment-timezone'

import { events } from './events'
import trackEvent from './trackEvent'
import { EVENTS, MparticleEvents } from '../types'
import getLiveStartupAttributes from './attributes/getLiveStartupAttributes'
import { StopWatch } from '../../../util/StopWatch'
import getSearchResultsAttributes from './attributes/getSearchResultsAttributes'
import { getFirstRun, saveFirstRun } from '../firstRun'
import getDefaultUserAttributes from './attributes/getDefaultUserAttributes'
import AppConfigFactorySingleton from '../../../config/AppConfigFactory'
import { getCookieNamesByPrefix, removeCookie } from '../../../helpers'
import mParticleConfig from './mParticleConfig'
import { IMarketingModuleVideoPreviewParams } from './attributes/getMarketingModuleVideoPreviewAttributes'
import { ContentClickParams } from './attributes/getContentClickAttributes'

const USER_READY_TIMEOUT = 1000
const USER_READY_MAX_ATTEMPTS = 10
const MPARTICLE_COOKIE_PREFIX = 'mprtcl-'
export const MPARTICLE_TAG = '[mParticle]'

// Add exclusions for events that are handled internally
type EventsMap = Record<
  Exclude<MparticleEvents, EVENTS.PROGRAM_END | EVENTS.PROGRAM_START>,
  (...params: any[]) => void
>

class mParticleInterface implements EventsMap {
  videoID = null
  videoType = null
  firstVisit = 'False'
  _previousVideo = ''
  _previousVideoType = ''
  _adStartTime = 0
  _authZStopWatch: any
  _initialBufferStopWatch: any
  _playerLoadStopWatch: any

  async initialize(callback: (data: any) => void) {
    Log.info(MPARTICLE_TAG, 'initialize')
    // this is set for compatibility with library references
    const particleSetup = mParticleConfig(callback)
    const { apikey } = AppConfigFactorySingleton.config.mparticle
    mParticle.init(apikey, particleSetup.config)
    // Send track launch since we've obviously launched at this point,
    // but may not be initialized when the normal launch event fires
    if (mParticleInterface.getMpid()) {
      this.trackLaunch()
    } else {
      // User info not loaded into the mParticle SDK, set listener.
      await new Promise<void>((resolve) => {
        let attempts = 0
        const checkIsUserReady = setInterval(() => {
          attempts += 1
          const mpid = mParticleInterface.getMpid()
          if (mpid || attempts === USER_READY_MAX_ATTEMPTS) {
            clearInterval(checkIsUserReady)
            this.trackLaunch()
            resolve()
          }
        }, USER_READY_TIMEOUT)
      })
    }
    this.removeBrandCookies()
  }
  removeBrandCookies = () => {
    // mParticle stores a lot of data, apps crash when more than 3 mParticle cookies exist.
    // remove any cookies associated with other brands.
    // @ts-expect-error TODO: Store is not a valid property in mParticle object according to type definition
    const storageName = mParticle?.Store?.storageName
    const mParticleCookies = getCookieNamesByPrefix(MPARTICLE_COOKIE_PREFIX)
    if (mParticleCookies.length) {
      mParticleCookies.forEach((ck) => {
        if (ck !== storageName) {
          Log.info(MPARTICLE_TAG, `removing cookie associated with another brand: ${ck}`)
          removeCookie(ck, { domain: this.getMparticleCookieDomain() })
        }
      })
    }
  }
  static getCurrentUser = mParticle.Identity.getCurrentUser

  static getMpid = () => mParticleInterface.getCurrentUser()?.getMPID() || ''

  static setAuthAttributes = (mvpd = '', profile = '') => {
    const user = mParticleInterface.getCurrentUser()
    user.setUserAttribute('User MVPD', mvpd)
    user.setUserAttribute('User Profile', profile)
  }

  static setUserLanguageAttribute = (lang = '') => {
    const user = mParticleInterface.getCurrentUser()
    user.setUserAttribute('User Language', lang)
  }

  static setConversionAttribute = (converted = false) => {
    const user = mParticleInterface.getCurrentUser()
    user.setUserAttribute('User Converted', `${converted}`)
  }

  getFirstVisitDate() {
    let runDate = getFirstRun()

    if (!moment(runDate).isValid()) {
      runDate = saveFirstRun()
      this.firstVisit = 'True'
    }

    return runDate
  }

  setDefaultUserAttributes() {
    const firstVisitDate = this.getFirstVisitDate()
    const defaultUserAttributes = getDefaultUserAttributes({ firstVisitDate })
    const user = mParticleInterface.getCurrentUser()
    if (!user) return
    for (const [key, value] of Object.entries(defaultUserAttributes)) {
      user.setUserAttribute(key, value)
    }
  }

  setUserAttribute(key: string, value: string | string[]) {
    const user = mParticleInterface.getCurrentUser()
    if (Array.isArray(value)) {
      user.setUserAttributeList(key, value)
    } else {
      user.setUserAttribute(key, value)
    }
  }

  updateDefaultUserAttributes(tempPassTokenName?: string) {
    const defaultUserAttributes = getDefaultUserAttributes({
      tempPassTokenName,
    })
    const user = mParticleInterface.getCurrentUser()
    for (const [key, value] of Object.entries(defaultUserAttributes)) {
      user.setUserAttribute(key, value)
    }
  }

  addProps = (add: any, source: any) => (prop: any) => (o: any) => {
    if (prop) {
      source[prop]
    } else {
      source[o] = add(o, prop)
    }
  }

  addEvent = (event: keyof typeof events, params: any = {}, type?: any) => {
    trackEvent(events[event](type), {
      ...params,
      firstVisit: params?.firstVisit || this.firstVisit,
    })
    Log.info(`mParticle event ${event}`, params)
  }

  getMparticleDomain = (n: any, t: any) => {
    for (let r, u = 'mptest=cookie', f = t.split('.'), i = f.length - 1; i >= 0; i--)
      if (
        ((r = f.slice(i).join('.')),
        (n.cookie = u + ';domain=.' + r + ';'),
        n.cookie.indexOf(u) > -1)
      )
        return (
          (n.cookie =
            u.split('=')[0] + '=;domain=.' + r + ';expires=Thu, 01 Jan 1970 00:00:01 GMT;'),
          r
        )
    return ''
  }

  getMparticleCookieDomain = () => {
    const i = this.getMparticleDomain(document, location.hostname)
    return i === '' ? '' : '.' + i
  };

  // #region Events
  // General
  [EVENTS.LAUNCH] = () => {
    this.setDefaultUserAttributes()
    this.addEvent(EVENTS.LAUNCH)
  };
  [EVENTS.ERROR] = (metadata: any) => {
    this.addEvent(EVENTS.ERROR, metadata)
  };
  [EVENTS.EXIT] = (params: any) => {
    this.addEvent(EVENTS.EXIT, params)
  };

  // Ads
  [EVENTS.AD_BREAK_START] = (video: any, adBreak: any, isLive: any) => {
    const type = isLive ? 'Linear Ad Pod Start' : 'Ad Pod Start'
    this.addEvent(
      EVENTS.AD_BREAK_START,
      {
        video,
        adBreak,
        previousVideo: this._previousVideo,
        previousVideoType: this._previousVideoType,
        isLive,
      },
      type
    )
  };
  [EVENTS.AD_BREAK_END] = (video: any, adBreak: any, isLive: any) => {
    const type = isLive ? 'Linear Ad Pod End' : 'Ad Pod End'
    this.addEvent(
      EVENTS.AD_BREAK_END,
      {
        video,
        adBreak,
        previousVideo: this._previousVideo,
        previousVideoType: this._previousVideoType,
        isLive,
      },
      type
    )
  };
  [EVENTS.AD_START] = (video: any, ad: any, isLive: any) => {
    const type = isLive ? 'Linear Ad Start' : 'Ad Start'
    this._adStartTime = Date.now()
    this.addEvent(
      EVENTS.AD_START,
      {
        video,
        ad,
        previousVideo: this._previousVideo,
        previousVideoType: this._previousVideoType,
        isLive,
      },
      type
    )
  };
  [EVENTS.AD_END] = (video: any, ad: any, isLive: any) => {
    const type = isLive ? 'Linear Ad End' : 'Ad End'
    const adSecondsWatched = Math.round(Date.now() / 1000 - this._adStartTime / 1000)
    this._adStartTime = 0
    this.addEvent(
      EVENTS.AD_END,
      {
        video,
        ad,
        previousVideo: this._previousVideo,
        previousVideoType: this._previousVideoType,
        isLive,
        adSecondsWatched,
      },
      type
    )
  };

  // Live
  [EVENTS.LIVE_PROGRAM_CHANGE] = (metadata: any, previousMetadata: any) => {
    this._previousVideo =
      get(previousMetadata, 'tmsId') ||
      get(previousMetadata, 'mpxGuid') ||
      get(previousMetadata, 'pid', 'None')
    this._previousVideoType = 'Live'
    this.addEvent(EVENTS.PROGRAM_END, previousMetadata)
    this.addEvent(EVENTS.PROGRAM_START, metadata)
  };
  [EVENTS.LIVE_PLAYER_LOAD] = () => {
    // For measuring video start time performance
    this._playerLoadStopWatch = new StopWatch()
    this._playerLoadStopWatch.start()
  };
  [EVENTS.LIVE_SESSION_START] = (metadata: any) => {
    if (this._previousVideoType && this._previousVideoType !== 'Live') {
      // Clear previous VOD asset.
      this._previousVideo = ''
      this._previousVideoType = ''
    }
    this.addEvent(EVENTS.LIVE_SESSION_START, metadata)
    this.addEvent(EVENTS.PROGRAM_START, metadata)
  };
  [EVENTS.LIVE_SESSION_END] = (metadata: any) => {
    this._previousVideo =
      get(metadata, 'tmsId') || get(metadata, 'mpxGuid') || get(metadata, 'pid', 'None')
    this._previousVideoType = 'Live'
    this.addEvent(EVENTS.PROGRAM_END, metadata)
    this.addEvent(EVENTS.LIVE_SESSION_END, metadata)
  };

  // VOD
  [EVENTS.VOD_SESSION_START] = (metadata: any) => {
    if (this._previousVideoType === 'Live') {
      // Clear previous Live asset.
      this._previousVideo = ''
      this._previousVideoType = ''
    }
    this.addEvent(EVENTS.VOD_SESSION_START, {
      ...metadata,
      previousVideo: this._previousVideo,
      previousVideoType: this._previousVideoType,
    })
  };
  [EVENTS.VOD_SESSION_END] = (metadata: any) => {
    this.addEvent(EVENTS.VOD_SESSION_END, {
      ...metadata,
      previousVideo: this._previousVideo,
      previousVideoType: this._previousVideoType,
    })
    this._previousVideo = metadata?.mpxGuid || metadata.video?.mpxGuid
    this._previousVideoType = metadata?.programmingType || metadata.video?.programmingType
  };

  // Player
  [EVENTS.PLAYER_LOAD_TIME] = (metadata: any) => {
    if (this._playerLoadStopWatch) this._playerLoadStopWatch.stop()
    if (this._initialBufferStopWatch) {
      this._initialBufferStopWatch.stop()
    }
    if (this._playerLoadStopWatch) {
      this.addEvent(
        EVENTS.PLAYER_LOAD_TIME,
        getLiveStartupAttributes({
          ...metadata,
          startUpTime: this._playerLoadStopWatch ? this._playerLoadStopWatch.duration() : null,
          authorizationDuration: this._authZStopWatch ? this._authZStopWatch.duration() : null,
          initialBufferDuration: this._initialBufferStopWatch
            ? this._initialBufferStopWatch.duration()
            : null,
          previousVideo: this._previousVideo,
          previousVideoType: this._previousVideoType,
        })
      )
    }
  };

  // Auth
  [EVENTS.AUTHZ_START] = () => {
    this._authZStopWatch = new StopWatch()
    this._authZStopWatch.start()
  };
  [EVENTS.AUTHZ_COMPLETE] = () => {
    this._authZStopWatch.stop()
    this._initialBufferStopWatch = new StopWatch()
    this._initialBufferStopWatch.start()
  };
  [EVENTS.AUTH_SUCCESS_MVPD] = (metadata: any) => {
    this.addEvent(EVENTS.AUTH_SUCCESS_MVPD, metadata)
  };
  [EVENTS.MVPD_PAGE_ABANDONED] = (metadata: any) => {
    this.addEvent(EVENTS.MVPD_PAGE_ABANDONED, metadata)
  };
  [EVENTS.AUTH_SUCCESS_NBC] = (params: any) => {
    this.addEvent(EVENTS.AUTH_SUCCESS_NBC, params)
  };
  [EVENTS.NBC_PROFILE_SIGN_OUT] = () => {
    this.addEvent(EVENTS.NBC_PROFILE_SIGN_OUT)
  };
  [EVENTS.IDM_CONVERSION] = () => {
    this.addEvent(EVENTS.IDM_CONVERSION)
  };

  // User actions
  [EVENTS.PAGE_LOAD] = (metadata: any) => {
    // Pages with async data live movie, show, live don't have a
    // path yet because they don't come straight from the router.
    if (!metadata.path) {
      // Get event on route change
      const handle = () => {
        if (metadata.name) {
          metadata.path = Router.getActiveRoute()
          this.addEvent(metadata.name, metadata)
        }
        window.removeEventListener('hashchange', handle)
      }
      window.addEventListener('hashchange', handle)
    } else {
      this.addEvent(EVENTS.PAGE_LOAD, metadata)
    }
  };
  [EVENTS.CLICK] = (metadata: any) => {
    this.addEvent(EVENTS.CLICK, metadata)
  };
  [EVENTS.CONTENT_CLICK] = (metadata: ContentClickParams) => {
    this.addEvent(EVENTS.CONTENT_CLICK, metadata)
  };
  [EVENTS.SEARCH_RESULT] = (metadata: any) => {
    this.addEvent(EVENTS.SEARCH_RESULT, getSearchResultsAttributes(metadata))
  };
  [EVENTS.SHELF_IMPRESSION] = (params: any) => {
    this.addEvent(EVENTS.SHELF_IMPRESSION, params)
  };
  [EVENTS.DYNAMIC_LEAD_IMPRESSION] = (params: any) => {
    this.addEvent(EVENTS.DYNAMIC_LEAD_IMPRESSION, params)
  };
  [EVENTS.SHELVES_LOAD] = (params: any) => {
    this.addEvent(EVENTS.SHELVES_LOAD, params)
  };
  [EVENTS.MODAL_LOAD] = (params: any) => {
    this.addEvent(EVENTS.MODAL_LOAD, params)
  };
  [EVENTS.END_CARD_IMPRESSION] = (params: any) => {
    this.addEvent(EVENTS.END_CARD_IMPRESSION, {
      ...params,
      previousVideo: this._previousVideo,
      previousVideoType: this._previousVideoType,
    })
  };
  [EVENTS.END_CARD] = (params: any) => {
    this.addEvent(EVENTS.END_CARD, {
      ...params,
      previousVideo: this._previousVideo,
      previousVideoType: this._previousVideoType,
    })
  };
  [EVENTS.LIVE_TO_VOD_IMPRESSION] = (params: any) => {
    this.addEvent(EVENTS.LIVE_TO_VOD_IMPRESSION, params)
  };
  [EVENTS.MARKETING_MODULE_IMPRESSION] = (params: any) => {
    this.addEvent(EVENTS.MARKETING_MODULE_IMPRESSION, params)
  };
  [EVENTS.MARKETING_MODULE_VIDEO_PREVIEW] = (params: IMarketingModuleVideoPreviewParams) => {
    this.addEvent(EVENTS.MARKETING_MODULE_VIDEO_PREVIEW, params)
  }
  // #endregion
}

export default mParticleInterface
