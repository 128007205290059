import { Lightning, Router } from '@lightningjs/sdk'
import { Subscription } from 'rxjs'
//components
import FERPlayer from './FERPlayer'
import SLEPlayer from './SLEPlayer'
import { openPlayerLoader } from '../../widgets/Modals/playerLoader/PlayerLoader'
//store
import PlayerStoreSingleton, { PlayerStoreEvents } from '../../store/PlayerStore/PlayerStore'
import {
  clearLemonade,
  findLinkSelectableComponent,
  isSingleStream,
  setStreamData,
} from '../../store/PlayerStore/actions'
import { usePreCheckData } from '../../store/utils/preCheckData'
//utils
import { SubscriptionBuilder, SubscriptionSources } from '../../util/SubscriptionBuilder'
import RouterUtil from '../../util/RouterUtil'
//lib
import TVPlatform from '../../lib/tv-platform'
import { ErrorType } from '../../lib/tv-platform/types'
//helpers
import { getBffDataFromPid, setSmooth } from '../../helpers'
//constants
import { PlayerStates, PROGRAMMING_TYPES, ROUTE } from '../../constants'
//types
import { RouterPage } from '../../../types/global'
import { EpgGuideController } from '../../components/EpgGuideV2/EpgGuideController'
import LaunchDarklySingleton from '../../lib/launchDarkly/LaunchDarkly'
import LaunchDarklyFeatureFlags from '../../lib/launchDarkly/LaunchDarklyFeatureFlags'
import { WithSeekingV2 } from './hoc/WithSeekingV2'
import { WithSeeking } from './hoc/WithSeeking'

const reportError = (err?: any) =>
  TVPlatform.reportError({
    type: ErrorType.NETWORK,
    code: 'SLE/FER player',
    description: 'Error Loading BFF data',
    payload: err,
  })

export default class EventsPlayer extends Lightning.Component<
  Lightning.Component.TemplateSpecLoose,
  RouterPage
> {
  _params!: { pid?: string; removePreviousRoute?: boolean }
  _subscription?: Subscription

  static override _states() {
    return [
      class Ready extends this {
        override _getFocused() {
          return this.tag('PlayerComponent') || this
        }
      },
    ]
  }

  override _init() {
    openPlayerLoader()
  }

  override set params(params: any) {
    this._params = params
  }

  override _onMounted() {
    if (this._params.removePreviousRoute) RouterUtil.removePreviousRoute()
  }

  override _active() {
    this._subscription = new SubscriptionBuilder()
      .with(SubscriptionSources.PLAYER_STORE)
      .subscribe(this._onStoreEvent)

    PlayerStoreSingleton.dispatch(clearLemonade())
      .then(() => usePreCheckData())
      .then((isPreCheckData) => {
        if (!isPreCheckData) this._handleBffDataFromPid()
      })
      .catch(() => {
        this._handleBffDataFromPid()
      })
  }

  override _inactive() {
    this._subscription?.unsubscribe()
    this._subscription = undefined
  }

  async _handleBffDataFromPid(): Promise<void> {
    if (!this._params.pid) return
    const data = await getBffDataFromPid(this._params.pid)
    if (data) {
      try {
        await PlayerStoreSingleton.dispatch(setStreamData(data))
        // Since player is injected after data event we need this workaround
      } catch (error) {
        reportError(error)
        setSmooth(this.widgets.loader, 'visible', 0)
        this._patchPlayer()
      }
      EpgGuideController.setTabs(findLinkSelectableComponent(data.sections))
    } else {
      reportError()
      Router.navigate(ROUTE.error)
    }
  }

  _onStoreEvent = async (event: any) => {
    switch (event.type) {
      case PlayerStoreEvents.STREAM_OK:
        setSmooth(this.widgets.loader, 'visible', 0)
        this._patchPlayer()
        break
    }
  }

  _patchPlayer() {
    const isSLE =
      !isSingleStream(PlayerStoreSingleton.stream) ||
      PlayerStoreSingleton.stream?.programmingType !== PROGRAMMING_TYPES.FER
    const isNewPlayerDesign = LaunchDarklySingleton.getFeatureFlag(
      LaunchDarklyFeatureFlags.newPlayerTest
    )
    this.patch({
      PlayerComponent: {
        type: isSLE
          ? isNewPlayerDesign
            ? WithSeekingV2(SLEPlayer)
            : WithSeeking(SLEPlayer)
          : FERPlayer,
        params: this._params,
      },
    })
  }

  $onAttached() {
    this._setState(PlayerStates.Ready)
  }

  _updateActiveCues(cues: any) {
    this.tag('PlayerComponent')?._updateActiveCues?.(cues)
  }
}
