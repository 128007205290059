import { Lightning } from '@lightningjs/sdk'
import { RouterPage } from '../../types/global'

export default class NotFound extends Lightning.Component<
  Lightning.Component.TemplateSpecLoose,
  RouterPage
> {
  static override _template() {
    return {}
  }
}
