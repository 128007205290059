import { labelBadgeDecorator } from '../../decorators'

import { getTimerToEndTile } from '../../../../helpers'

export const getUpcomingStateClass = (context: any) =>
  class UpcomingState extends context {
    $enter() {
      if (this._item.upcomingBadge) {
        this._addDecorators([labelBadgeDecorator(this._item?.upcomingBadge)])
      }

      this._timerChangeToLive = getTimerToEndTile(this._item.startTime, () => {
        this._setState('LiveState')
      })
    }

    get announce() {
      return `${this._item.upcomingAriaLabel}, press OK to select`
    }
  }
