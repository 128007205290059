import 'string.padstart'
import get from 'lodash/get'
import { Registry, Router, Log } from '@lightningjs/sdk'
import { StreamingProtocol } from '@sky-uk-ott/core-video-sdk-js'

import BasePlatform, {
  PlatformSubscriptionType,
  SubscriptionWrapper,
  TV_PLATFORM_TAG,
} from './base'
import { USER_OPT_OUT_PREFERENCE, ROUTE, Keys } from '../../constants'
import RouterUtil from '../../util/RouterUtil'
import { setAppLaunchType } from '../../helpers'
import { AppLaunchTypes } from '../analytics/types'
import { getPersonalPreview } from '../../helpers/preview'
import { APP_IDENTIFIER, ErrorType, IKeyMap, IStageSettings, LEMONADE_PLATFORM } from './types'
import { SupportedPlatforms } from '../../graphql/generated/types'
import AppConfigFactorySingleton from '../../config/AppConfigFactory'

const APP_ID = {
  premium: 'com.samsung.tv.store', // app id for Smart Hub Samsung Application app/view for premium devices.
  standart: 'org.volt.apps', //app id for Smart Hub Samsung Application app/view for standard devices.
}

class TizenSubscriptionWrapper extends SubscriptionWrapper {
  _unsubscribeCb: any
  stale = false

  constructor(unsubscribeCb: any) {
    super()
    this._unsubscribeCb = unsubscribeCb
  }

  override unsubscribe(): void {
    this.stale = true
    this._unsubscribeCb?.()
  }
}

export default class TizenPlatform extends BasePlatform {
  override _platformName = 'Samsung'
  override _lemonadePlatform = LEMONADE_PLATFORM.TIZEN
  override _bffPlatform = SupportedPlatforms.SamsungTv
  override _streamingProtocol = StreamingProtocol.DASH
  override _appIdentifier = APP_IDENTIFIER.TIZEN
  override _subscriptions: TizenSubscriptionWrapper[]
  _previewServiceName = 'NBCTileService'
  isActiveAppStore: any
  _model!: string

  override get capabilities() {
    return {
      externalAppLinking: true,
      concurrentStreams: true,
    }
  }

  override get devicePartnerId() {
    return AppConfigFactorySingleton.config?.identity?.device_type || 'samsung'
  }

  constructor() {
    super()
    this._removeUnnecessaryHTMLElement()
    Registry.addEventListener(document, 'appcontrol', this.handleDeepLink)
  }

  override async init(): Promise<void> {
    this._deviceType = 'Samsung TV'
    if (window.webapis) {
      this._registerRemoteKeys()
      await this.generateDeviceId()
      return
    }
    // Check if webapi is not available
    fetch('$WEBAPIS/webapis/webapis.js')
      .then(async (response) => {
        if (response.status >= 400) await super.generateDeviceId()
      })
      .catch(async () => await super.generateDeviceId())
  }

  /**
   * Сalls the Log.error method with different parameters depending on the passed error.
   * @param e
   * @private
   */
  _handleAdInfoError(e: any): void {
    this.reportError({
      type: ErrorType.OTHER,
      code: TV_PLATFORM_TAG,
      description: e.message.indexOf('undefined') === -1 ? 'Missing privileges' : 'Undefined error',
    })
  }

  /**
   * Checks whether the user has enabled the Limit Ad Tracking feature.
   * @returns {boolean}
   */
  override getUserOptOut() {
    try {
      const storageOptOut = this.getStorageBasedOptOut()
      return window.webapis.adinfo.isLATEnabled() ||
        storageOptOut === USER_OPT_OUT_PREFERENCE.DISALLOW_SALE
        ? USER_OPT_OUT_PREFERENCE.DISALLOW_SALE
        : USER_OPT_OUT_PREFERENCE.ALLOW_SALE
    } catch (e) {
      this._handleAdInfoError(e)
      return super.getUserOptOut()
    }
  }

  /**
   * TIFA is a randomized and non-persistent Samsung Smart TV device identifier that can be reset.
   * https://developer.samsung.com/smarttv/develop/api-references/samsung-product-api-references/adinfo-api.html#AdInfoManager-getTIFA
   * @returns {string}
   */
  override getAdvertiserId() {
    try {
      return window.webapis.adinfo.getTIFA()
    } catch (e) {
      this._handleAdInfoError(e)
      return super.getAdvertiserId()
    }
  }

  override exit(): void {
    try {
      if (window.tizen) {
        this.isActiveAppStore
          ? this._goBackToAppStore()
          : window.tizen.application.getCurrentApplication().exit()
      }
    } catch (e) {
      this.reportError({
        type: ErrorType.OTHER,
        code: TV_PLATFORM_TAG,
        description: 'Tizen API is not available',
        payload: e,
      })
    }
  }

  override exitToPeacock(): void {
    if (!window.tizen) super.exitToPeacock()

    const service = new window.tizen.ApplicationControl(
      'http://tizen.org/appcontrol/operation/view',
      'gzcc4LRFBF.Peacock',
      null,
      null,
      null
    )
    try {
      window.tizen.application.launchAppControl(
        service,
        'gzcc4LRFBF.Peacock',
        function () {
          Log.info('Service launched')
        },
        function () {
          Router.navigate(ROUTE.home)
        },
        null
      )
    } catch (e) {
      this.reportError({
        type: ErrorType.OTHER,
        code: TV_PLATFORM_TAG,
        description: 'launchService exception',
        payload: e,
      })
    }
  }

  override getPlatformKeyMapping(): IKeyMap {
    const numberLetterKeyMapping = this.getAtoZAndNumberKeyMapping(true)
    return {
      ...numberLetterKeyMapping,
      ...this._getExtraKeymap(),
      10009: Keys.BACKSPACE,
      36: Keys.RETURN,
      38: Keys.ARROW_UP,
      40: Keys.ARROW_DOWN,
      37: Keys.ARROW_LEFT,
      39: Keys.ARROW_RIGHT,
      27: Keys.EXIT,
      10182: Keys.EXIT,
      500: Keys.RETURN,
      415: Keys.MEDIA_PLAY,
      417: Keys.MEDIA_FAST_FORWARD,
      412: Keys.MEDIA_REWIND,
      19: Keys.MEDIA_PAUSE,
      10252: Keys.MEDIA_PLAY_PAUSE,
      413: Keys.MEDIA_STOP,
      457: Keys.INFO,
    }
  }

  _getAppInfo() {
    return window.tizen.application.getAppInfo()
  }

  /**
   * Check tv AppControl and use PAYLOAD value as a route
   */
  override handleDeepLink(): void {
    if (!window.tizen) return
    try {
      const reqAppControl = window.tizen.application
        .getCurrentApplication()
        .getRequestedAppControl()
      if (reqAppControl) {
        const actionData = get(reqAppControl, 'appControl.data')
        const payload =
          actionData?.length && actionData.find((item: any) => item.key === 'PAYLOAD' && item.value)
        if (payload) {
          const data = JSON.parse(payload.value)
          if (data?.values) {
            // Remove all leading and trailing slashes
            const route = data.values.replace(/^\/|\/$/g, '')
            if (route !== Router.getActiveHash())
              // For some reason Router.navigate will not work without delay
              Registry.setTimeout(() => {
                Router.navigate(route, !RouterUtil.isPlayerRoute(route))
                setAppLaunchType(AppLaunchTypes.deepLink)
              }, 100)
          }
        }
      }
    } catch (error) {
      this.reportError({
        type: ErrorType.OTHER,
        code: TV_PLATFORM_TAG,
        description: 'Error handling Tizen deeplink',
        payload: error,
      })
    }
  }

  _getExtraKeymap(): IKeyMap {
    const defMap: IKeyMap = {}
    for (let i = 0; i < 91; i++) {
      if (i <= 9) defMap[i + 96] = String(i)
      if (i > 0 && i < 25) defMap[i + 111] = `F${i}`
      if (i >= 48 && i < 91) defMap[i] = String.fromCharCode(i)
    }
    return defMap
  }

  override async generateDeviceId(): Promise<void> {
    try {
      if (!this.deviceId) {
        const deviceId = window.webapis?.productinfo?.getDuid?.()
        if (deviceId) {
          this.deviceId = deviceId
        } else {
          await super.generateDeviceId()
        }
      }
      await this.setPreview()
    } catch (e) {
      // fail silently
    }
  }

  override async getModelNumber(): Promise<string> {
    try {
      if (!this._model) this._model = window.webapis.productinfo.getRealModel()
      return this._model
    } catch (e) {
      return await super.getModelNumber()
    }
  }

  override getFirmware(): string {
    try {
      return window.webapis.productinfo.getFirmware()
    } catch (e) {
      return super.getFirmware()
    }
  }

  override getStageSettings(): IStageSettings {
    return {
      ...super.getStageSettings(),
      readPixelsAfterDraw: true,
      readPixelsAfterDrawThreshold: 0,
      pauseRafLoopOnIdle: true,
    }
  }

  _goBackToAppStore() {
    const app = window.tizen.application.getCurrentApplication()
    const shouldExitTheApp =
      typeof navigator.userAgent === 'string' && navigator.userAgent.includes('Tizen 3.0')

    window.tizen &&
      window.tizen.application.launch(
        APP_ID.premium,
        () => {
          shouldExitTheApp && app.exit()
        },
        () => {
          window.tizen.application.launch(
            APP_ID.standart,
            () => {
              shouldExitTheApp && app.exit()
            },
            () => {
              window.tizen.application.getCurrentApplication().exit()
            }
          )
        }
      )
  }

  override subscribe = (
    evt: PlatformSubscriptionType,
    callback: any
  ): TizenSubscriptionWrapper | undefined => {
    // Filter stale events
    this._subscriptions = this._subscriptions.filter(({ stale }) => !stale)
    if (!window.webapis) return
    try {
      let eventCallback, id, unsubscribe
      switch (evt) {
        case PlatformSubscriptionType.VOICE:
          eventCallback = () =>
            callback(
              Boolean(
                window.webapis.tvinfo.getMenuValue(
                  window.webapis.tvinfo.TvInfoMenuKey.VOICE_GUIDE_KEY
                )
              )
            )
          eventCallback()
          id = window.webapis.tvinfo.addCaptionChangeListener(
            window.webapis.tvinfo.TvInfoMenuKey.VOICE_GUIDE_KEY,
            eventCallback
          )
          unsubscribe = window.webapis.tvinfo.removeCaptionChangeListener(id)
          break
        case PlatformSubscriptionType.CC:
        case PlatformSubscriptionType.BACKGROUND:
        case PlatformSubscriptionType.FOREGROUND:
        default:
          break
      }
      return new TizenSubscriptionWrapper(unsubscribe)
    } catch (e) {
      // Won't TVPlatform.reportError since this is a internal subscription error
      Log.error(TV_PLATFORM_TAG, e)
    }
    return undefined
  }

  _registerRemoteKeys(): void {
    if (window.tizen?.tvinputdevice) {
      try {
        const supportedKeys = window.tizen.tvinputdevice
          .getSupportedKeys()
          .map(({ name }: any) => name)
        window.tizen.tvinputdevice.registerKeyBatch(
          [
            Keys.MEDIA_REWIND,
            Keys.MEDIA_FAST_FORWARD,
            Keys.MEDIA_PLAY,
            Keys.MEDIA_PAUSE,
            Keys.MEDIA_PLAY_PAUSE,
            Keys.MEDIA_STOP,
            Keys.EXIT,
            Keys.INFO,
          ].filter((name) => supportedKeys.includes(name))
        )
      } catch (e) {
        this.reportError({
          type: ErrorType.OTHER,
          code: TV_PLATFORM_TAG,
          description: 'Tizen API is not available',
        })
      }
    }
  }

  override setPreview = async (): Promise<void> => {
    if (!window.tizen) return
    try {
      const { packageId } = this._getAppInfo()
      const previewServiceName = `${packageId}.${this._previewServiceName}`
      await new Promise<void | boolean>((resolve, reject) => {
        window.tizen?.application.launchAppControl(
          new window.tizen.ApplicationControl(
            'http://tizen.org/appcontrol/operation/pick',
            null,
            'imag/jpeg',
            null,
            [new window.tizen.ApplicationControlData('caller', ['ForegroundApp'])]
          ),
          previewServiceName,
          async () => {
            Log.info(`Launch success: ${previewServiceName}`)
            await this._sendServiceMessage(previewServiceName)
            resolve(true)
          },
          function (e: Error) {
            Log.info(`Launch failed: ${e.message}`)
            reject()
          }
        )
      })
    } catch (e) {
      // fail silently
    }
  }

  override checkDeepLinkNavigation = () => this.handleDeepLink()

  _sendServiceMessage = async (serviceName: any) => {
    const value = await getPersonalPreview(this.deviceId)
    if (value) {
      const remotePort = window.tizen.messageport.requestRemoteMessagePort(
        serviceName,
        'PREVIEW_SERVICE'
      )
      if (remotePort)
        remotePort.sendMessage([
          {
            key: 'PREVIEW',
            value,
          },
        ])
    }
  }

  _removeUnnecessaryHTMLElement() {
    const childNode = Array.from(document.body.childNodes).find((node) => {
      return node.textContent && node.textContent.trim() === '<'
    })
    if (childNode) {
      document.body.removeChild(childNode)
    }
  }

  override get deviceInfo() {
    return {
      primaryHardwareType: 'TV', //#restricted
      model: 'None', //#mandatory
      version: 'None',
      manufacturer: 'Samsung',
      vendor: 'Samsung',
      osName: 'Linux', //#mandatory, #restricted
      osFamily: 'Tizen', // #restricted
      osVendor: 'Tizen',
      osVersion: 'None',
      browserName: 'Symbian Browser', //#restricted
      browserVendor: 'Samsung', //#restricted
      browserVersion: 'None',
      userAgent: window.navigator.userAgent,
      displayWidth: window.innerWidth,
      displayHeight: window.innerHeight,
      displayPpi: 0,
      diagonalScreeenSize: 0,
      connectionIp: 'None',
      connectionPort: 0,
      connectionType: 'None',
      connectionSecure: false, //#restricted
      applicationId: 'None',
    }
  }
}

declare global {
  interface Window {
    webapis: any
    tizen: any
  }
}
