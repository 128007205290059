import { CorePlayerStatusEmitter } from './CorePlayerStatusEmitter'
import { PlayerEventEmitter } from './PlayerEventEmitter'
import { SessionController } from '@sky-uk-ott/core-video-sdk-js-core'
import { MutablePlayerEventStream, PlayerStatusEvent, TimeChangeEvent } from '../event'
import { CoreVideoAdReporter } from './CoreVideoAdReporter'

export enum PlayerEventSetup {
  ALL = 'all',
  MINIMAL = 'minimal',
}

export enum AvailableEmitters {
  CORE_PLAYER_STATUS = 'corePlayerStatus',
  CORE_AD_REPORTER = 'coreAdReporter',
}

export class PlayerEventEmitterRegistry {
  emitters: Map<AvailableEmitters, PlayerEventEmitter> = new Map()

  constructor(events = PlayerEventSetup.ALL) {
    const isMinimal = events === PlayerEventSetup.MINIMAL
    if (isMinimal) {
      this.emitters.set(
        AvailableEmitters.CORE_PLAYER_STATUS,
        new CorePlayerStatusEmitter([PlayerStatusEvent, TimeChangeEvent])
      )
    } else {
      this.emitters.set(AvailableEmitters.CORE_PLAYER_STATUS, new CorePlayerStatusEmitter())
      this.emitters.set(AvailableEmitters.CORE_AD_REPORTER, new CoreVideoAdReporter())
    }
  }

  getEmitter(emitterName: AvailableEmitters) {
    return this.emitters.get(emitterName)
  }

  attach(player: SessionController, events: MutablePlayerEventStream) {
    this.emitters.forEach((emitter) => emitter.attach(player, events))
  }

  detach() {
    this.emitters.forEach((emitter) => emitter.detach())
    this.emitters.clear()
  }
}
