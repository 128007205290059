import { SubtitleCue, Track } from '@sky-uk-ott/core-video-sdk-js-core'
import { IPlayerEvent } from './IPlayerEvent'

export class SubtitleEvent extends IPlayerEvent {
  _subtitleData

  constructor(subtitleData: { tracks?: Track[]; cues?: SubtitleCue[] }) {
    super()
    this._subtitleData = subtitleData
  }

  get subtitleData() {
    return this._subtitleData
  }
}
