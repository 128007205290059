import mParticle from '@mparticle/web-sdk'
import getPageLoadAttributes from '../attributes/getPageLoadAttributes'

const trackPageLoad = () => ({
  getAttributes: getPageLoadAttributes,
  getCategory: () => mParticle?.EventType?.Navigation || 'Navigation',
  type: 'Page Load',
  injectGlobals: true,
})

export default trackPageLoad
