import AppConfig from './AppConfig'

/**
 * This is used to create app config instances.
 */
class AppConfigFactory {
  _appConfig: any
  constructor() {
    this._appConfig = new AppConfig()
  }

  get() {
    return this._appConfig
  }

  get config() {
    return this._appConfig.config
  }
}

const AppConfigFactorySingleton = new AppConfigFactory()
Object.freeze(AppConfigFactorySingleton)

export default AppConfigFactorySingleton
