import { Registry } from '@lightningjs/sdk'
import { Subject } from 'rxjs'

export class TempPass {
  _interval: any
  events: any
  expiration: any
  expired: any
  initSession(expiration: any) {
    this.expiration = expiration
    this.expired = false
    this.events = new Subject()
    this._interval = Registry.setInterval(this._tick.bind(this), 1000)
  }

  _tick() {
    if (this.expired || !this.expiration) return
    const delta = this.getDelta()
    if (delta <= 0) {
      this.expired = true
    }
    this.events?.next(delta)
  }

  subscribe(callback: any) {
    return this.events?.subscribe(callback)
  }

  endSession() {
    this.events?.complete()
    this.events = null
    if (this._interval) Registry.clearInterval(this._interval)
    this._interval = null
    this.expiration = null
    this.expired = false
  }

  getDelta() {
    const now = Date.now()
    return Math.floor((this.expiration - now) / 1000)
  }
}

export const TempPassSingleton = new TempPass()
