import Lightning from '@lightningjs/sdk/src/Lightning'
import { CMPPreferenceResponse, CMPPurposeParent } from '../../../lib/cmp/types/preference'
import { CMPGroupIds } from './types'
import { VerticalText } from './VerticalText'
import { AllowForm } from './AllowForm'

export type CMPComponent = {
  key: string
  label: string
  component: Lightning.Component.Constructor
  componentProps: {
    title: string
    content: string | string[]
    [key: string]: any
  }
}

export const cmpComponentFactory = (
  id: string | CMPGroupIds,
  item: CMPPurposeParent,
  context: CMPPreferenceResponse['pcUIData']
) => {
  const response: CMPComponent = {
    key: id,
    label: item.groupName,
    component: VerticalText,
    componentProps: {
      title: item.groupName,
      content: item.groupDescription,
    },
  }

  switch (id) {
    case CMPGroupIds.ALLOW_SALE:
      response.component = AllowForm
      response.componentProps.content = [
        context.purposeTree.styling.itemDetailsConsentCheckboxInfo?.interactionChoiceText || '',
        item.groupDescription,
      ]
      break
    default:
      break
  }

  return response
}
