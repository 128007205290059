import { Lightning, Utils } from '@lightningjs/sdk'

import { COLORS } from '../../constants'

export default class KeyBadge extends Lightning.Component {
  static override _template() {
    return {
      alpha: 1,
      mountX: 1,
      x: this.bindProp('positionX', ({ positionX = 0 }) => positionX),
      w: 80,
      h: 40,
      rect: true,
      color: COLORS.black4,
      LockImage: {
        x: (w: any) => w / 2,
        y: (h: any) => h / 2,
        mount: 0.5,
        w: 40,
        h: 21,
        color: COLORS.white1,
        texture: Lightning.Tools.getSvgTexture(Utils.asset('images/key.svg'), 40, 21),
      },
    }
  }
}
