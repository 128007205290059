import { createRequestConfig } from '../../lib/useRequest'
import { mainRequest } from '../../api/requests'
import Variables from '../../graphql/Variables'

export const OlympicsHighlightsRequestConfig = () =>
  createRequestConfig({
    query: mainRequest,
    variables: Variables.olympicsContentHubPageVariables('highlights'),
    reject: [(data) => !data?.sections],
  })
