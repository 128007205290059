import { IPlayerEvent } from './IPlayerEvent'
export class FPSChangeEvent extends IPlayerEvent {
  _fps

  constructor(fps: any) {
    super()
    this._fps = fps
  }

  get fps() {
    return this._fps
  }
}
