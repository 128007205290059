import moment from 'moment-timezone'

import getVodAttributes, { getResumeAttributes } from '../attributes/getVodAttributes'
import { setDefaultValue } from '../../../../helpers'
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants'

const getVideoEndAttributes = (params: any) => {
  const {
    analytics,
    watched,
    duration,
    ccLanguage,
    tokenType,
    referrer,
    resumeTime,
    previousVideo,
    previousVideoType,
    endType,
  } = params
  // Unclear where/how we'd get a referrer since it's exit of video
  // To Do: maybe another ticket to track where the entry came from and store.
  const watchedDuration = moment.duration(watched, 'seconds')
  return setDefaultValue(
    {
      ...getVodAttributes(params, ccLanguage, previousVideo, previousVideoType, analytics),
      ...getResumeAttributes({ resumeTime }),
      'Token Type': analytics?.authFunnel?.tokenType || tokenType || MPARTICLE_DEFAULT_ATTR_VALUE,
      'Video End Type': endType || 'Close',
      'Duration Watched': Math.round(watchedDuration.asMilliseconds()),
      '% Complete': Math.round((watched / duration) * 100),
      Casting: MPARTICLE_DEFAULT_ATTR_VALUE,
      Sponsor: referrer?.components?.shelf?.sponsorName,
      'Video Duration': params.durationInMilliseconds,
    },
    MPARTICLE_DEFAULT_ATTR_VALUE
  )
}

export default getVideoEndAttributes
