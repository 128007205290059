import mParticle from '@mparticle/web-sdk'
import getLiveToVodImpressionAttributes from '../attributes/getShelfImpressionAttributes'

const trackLiveToVodImpression = (type: any) => ({
  getAttributes: getLiveToVodImpressionAttributes,
  getCategory: () => mParticle.EventType?.UserContent || 'UserContent',
  type: 'Live to VOD Impression',
  injectGlobals: true,
})

export default trackLiveToVodImpression
