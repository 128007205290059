import { Lightning, Registry } from '@lightningjs/sdk'
import { HasEndCard, WithEndCard } from './WithEndCard'
import moment from 'moment-timezone'
import {
  END_CARD_AUTOPLAY_TIME,
  SCREEN_SIZE,
  PlayerStates,
  PROGRAMMING_TYPES,
} from '../../../../constants'
import { getAdjustedReferringShelf } from '../../../../helpers'
import PlayerStoreSingleton from '../../../../store/PlayerStore/PlayerStore'
import { isSingleProgram } from '../../../../store/PlayerStore/actions'
import { CoreVideoLightningMediaPlayerAdapter } from '../../../../player/CoreVideoPlayer/CoreVideoLightningMediaPlayerAdapter'

class HasEndCardVOD extends HasEndCard {
  _mediaPlayer: CoreVideoLightningMediaPlayerAdapter
}
const SFVODEndcardTimer = 2000

export const WithEndCardVOD = <T extends Lightning.Component.Constructor<HasEndCardVOD>>(
  component: T
) =>
  class extends WithEndCard(component) {
    _shouldUseSFVODEndcard = false
    _isSFVOD = false

    override _autoplayTime = END_CARD_AUTOPLAY_TIME

    // Making the BFF call to get the end card for VOD since there wont be any event trigger from the player
    override async _startStream() {
      await super._startStream()
      const { stream } = PlayerStoreSingleton

      // @ts-expect-error - stream type  missing programmingType
      if (stream?.programmingType === PROGRAMMING_TYPES.SFVOD && this?._params?.isOlympicsVideo) {
        //Nested in case we need to expand to other svod video types
        this._shouldUseSFVODEndcard = true
        this._autoplayTime = SFVODEndcardTimer
      } else if (stream && 'mpxGuid' in stream && 'programmingType' in stream) {
        this._endCard
          .queryEndCards(stream?.mpxGuid, stream?.programmingType as PROGRAMMING_TYPES)
          .then((endCards) => {
            this._hasEndCards = endCards
          })
      }
    }

    override _onStreamEnd() {
      super._onStreamEnd?.()
      this._image.alpha = 1
      this._mediaPlayerEnded = true

      if (!this._hasEndCards) {
        return this._closeMediaPlayer(true)
      }

      if (this._shouldUseSFVODEndcard) {
        return this._setState(PlayerStates.SFVODEndCard)
      }

      return this._setState(PlayerStates.PlayerEndCard)
    }

    static _states() {
      return [
        // @ts-expect-error TODO fix super not working with HOCs
        ...super._states(),
        class SFVODEndCard extends this {
          _closeSFVODEndcard() {
            this._closeMediaPlayer(true)
            this._closeEndCard()
          }

          override $enter() {
            this._hideUI()

            this._additionalUIUpdates({
              endCardOlyTransparency: true,
            })

            Registry.setTimeout(() => {
              this._closeSFVODEndcard()
            }, this._autoplayTime)
          }

          override _handleKey() {
            this._closeSFVODEndcard()
          }
        },
        class PlayerEndCard extends this {
          override $enter() {
            this._endCardImpressionTimeStamp = moment()
            this._analyticsDelegate.fireEndCardImpression({
              endCardTime: this._autoplayTime,
              ...this._endCard.getAnalyticsData(),
              shelf: getAdjustedReferringShelf(),
            })

            this._mediaPlayer._setVideoArea([90, 90, 530, 326])
            this._additionalUIUpdates({
              endCardBGTransparency: false,
              endCardTransparency: true,
            })
          }

          override _detach() {
            super._detach()

            this._closeEndCard()
          }

          override $exit() {
            this._endCardImpressionTimeStamp = null
            this._endCard.alpha = 0
            this._image.alpha = 0
            this._endCard.transparent = false
          }

          override _updateActiveCues() {
            //Nothing do here
          }

          override _getFocused() {
            return this._endCard || this
          }

          override _handleUp() {
            this._setState(PlayerStates.PlayerEndCard_MiniPlayer)
          }

          static override _states() {
            return [
              class MiniPlayer extends PlayerEndCard {
                override $enter() {
                  this._miniPlayerFocusRect.alpha = 1
                }

                override $exit() {
                  this._miniPlayerFocusRect.alpha = 0
                }

                override _handleDown() {
                  this._setState(PlayerStates.PlayerEndCard)
                }

                override _handleEnter() {
                  const { stream, program } = PlayerStoreSingleton
                  if (isSingleProgram(program)) {
                    this._trackEndCard({
                      isRestarting: true,
                      // @ts-expect-error TS(2322): Type '{ brand: any; series: any; destinationSport:... Remove this comment to see the full error message
                      destination: {
                        brand: program?.brand,
                        series: program?.series,
                        destinationSport: program?.sport,
                        destinationLeague: program?.league,
                        mpxGuid: program?.mpxGuid,
                        programmingType: program?.programmingType,
                        title: program?.title,
                        locked: program?.locked,
                      },
                    })
                  }
                  if (this._mediaPlayerEnded) {
                    this._load()
                  } else {
                    this._mediaPlayer._setVideoArea([0, 0, SCREEN_SIZE.width, SCREEN_SIZE.height])
                    this._setState(PlayerStates.LPPlayer, [{ hideControls: true }])
                  }
                }

                // @ts-expect-error Type error on returned component Need to resolve soon
                //Property '_getFocused' in type 'MiniPlayer' is not assignable to the same property in base type
                override _getFocused() {
                  return this
                }

                override _captureBack(_: any) {
                  this._trackBack(_)
                }
              },
            ]
          }
        },
      ]
    }
  }
