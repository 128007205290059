import List from './List'
import ItemWrapper from './items/ItemWrapper'
import { END_OF_ROW, FONT_FACE } from '../constants'
import { setSmooth, templateDeepMerge } from '../helpers'
import Announcer from '../lib/tts/Announcer'

export default class Stack extends List {
  _initialYPosition: number

  override _init() {
    super._init()
    this._initialYPosition = this._itemsHolder?.y || 0
  }

  static override _template() {
    const template = super._template()
    return templateDeepMerge(template, {
      TitleHolder: {
        Title: {
          text: { fontFace: FONT_FACE.regular, fontSize: 40 },
        },
      },
      Items: {
        y: 115,
      },
    })
  }

  override create({ items }: any, initialIndex = 0) {
    const style = this.getStyle()
    if (!style) {
      console.warn(`List.js ${items} missing style. Check ./src/lib/style.js`)
      return
    }
    this.noOfItemsToScroll = style.noOfListItemsToScroll
    return items.map((item: any, index: any) => {
      const indexToRender = index + initialIndex
      return {
        type: ItemWrapper,
        itemType: item.itemType,
        item: item.item,
        index: indexToRender,
        y: indexToRender * style.item.h,
      }
    })
  }

  override setIndex(index: any) {
    this._index = index
    const style = this.getStyle()

    setSmooth(this._itemsHolder, 'y', this._initialYPosition - index * style.item.h, {
      duration: 0.3,
      delay: 0,
    })
    this.fireAncestors('$scrolledListItemIndex', { index })
    this._refocus()
  }

  changeTitleAlpha(value: number) {
    setSmooth(this._titleHolder, 'alpha', value)
  }

  override _handleUp() {
    if (this._index > 0) {
      this.setIndex(this._index - 1)
    } else {
      return false
    }
  }

  override _handleDown() {
    if (this._index < this._itemsHolder.children.length - 1) {
      this.setIndex(this._index + 1)
    } else {
      Announcer.announce(END_OF_ROW)
      return false
    }
  }

  override _handleLeft(): boolean {
    return false
  }
  override _handleRight() {
    return false
  }
}
