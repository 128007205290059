import { Lightning } from '@lightningjs/sdk'

import LetterSpacingText from '../LetterSpacingText'

import {
  ALIGN_CONTENT,
  ALIGN_SELF,
  COLORS,
  FLEX_DIRECTION,
  FONT_FACE,
  JUSTIFY_CONTENT,
  TEXT_ALIGN,
} from '../../constants'

export default class TimerItem extends Lightning.Component {
  static override _template() {
    return {
      flex: {
        direction: FLEX_DIRECTION.column,
        alignContent: ALIGN_CONTENT.center,
        justifyContent: JUSTIFY_CONTENT.center,
      },
      w: 132,
      Number: {
        flexItem: { alignSelf: ALIGN_SELF.center },
        h: 120,
        text: {
          fontFace: FONT_FACE.light,
          fontSize: 100,
          lineHeight: 100,
          verticalAlign: 'top',
          textAlign: TEXT_ALIGN.center,
          textColor: COLORS.white,
          text: this.bindProp('value'),
        },
      },
      Label: {
        flexItem: { alignSelf: ALIGN_SELF.center },
        type: LetterSpacingText,
        space: 3,
        fontSize: 21,
        textColor: COLORS.white,
        value: this.bindProp('label'),
      },
    }
  }
}
