import { Hook, createHook, createHookEvent } from '../../../../lib/Hook'

export type ProgressHookEvent = [
  currentTime: number,
  duration: number,
  seekableRange: [start: number, end: number] | undefined,
  seekAdjust: number,
]
export type ProgressHookType = Hook<ProgressHookEvent>
export const useProgress = createHook<ProgressHookEvent>([0, 0, undefined, 0])
type Progress = {
  currentTime: number
  duration: number
  seekableRange: { end: number; start: number }
  seekAdjust: number
}
export const createProgressHookEvent = createHookEvent(useProgress, (hook: ProgressHookType) => {
  return (data: number | Partial<Progress>) => {
    const current = hook.value
    if (typeof data === 'number') {
      current[0] = data
    } else {
      if (data.currentTime !== undefined) current[0] = data.currentTime
      if (data.duration !== undefined) current[1] = data.duration
      if (data.seekableRange !== undefined)
        current[2] = [data.seekableRange.start, data.seekableRange.end]
      if (data.seekAdjust !== undefined) current[3] = data.seekAdjust
    }
    return current
  }
})
