import moment from 'moment-timezone'

import FavoritesApi from '../../../api/Favorites'
import { UserFavorite, UserWatch } from '../types'
import { getMpid } from '../../../helpers'
import UserInteractionsStoreSingleton from '../index'
import { useRequest } from '../../../lib/useRequest'
import { UserInteractionsRequestConfig } from './request'

export const UserInteractionsStoreActions = Object.freeze({
  SET_USER_INTERACTIONS: 'setUserInteractions',
  SET_WATCHES: 'setWatches',
  SET_WATCH: 'setWatch',
  SET_LIVE_WATCHES: 'setLiveWatches',
  SET_FAVORITES: 'setFavorites',
  SET_FAVORITE: 'setFavorite',
  REMOVE_FAVORITE: 'removeFavorite',
  CLEAR_STATE: 'clearState',
  ERROR: 'error',
  NO_CALL: 'noCall',
})

export const clearState = () => ({ type: UserInteractionsStoreActions.CLEAR_STATE })

const setError = (errorCode: any) => ({
  type: UserInteractionsStoreActions.ERROR,
  payload: {
    type: errorCode,
    description: 'Unable to fetch data',
    code: errorCode,
  },
})

export const fetchUserInteractions =
  (forceCall = false) =>
  () => {
    if (UserInteractionsStoreSingleton.lastCall && !forceCall)
      return Promise.resolve({ type: UserInteractionsStoreActions.NO_CALL, payload: {} })

    return useRequest(UserInteractionsRequestConfig())
      .fetch()
      .then((data) => {
        return {
          type: UserInteractionsStoreActions.SET_USER_INTERACTIONS,
          payload: data,
        }
      })
      .catch(setError)
  }

export const setWatch = (userWatch: UserWatch) => ({
  type: UserInteractionsStoreActions.SET_WATCH,
  payload: userWatch,
})

export const setLiveWatches = (userLiveWatch: any) => ({
  type: UserInteractionsStoreActions.SET_LIVE_WATCHES,
  payload: userLiveWatch,
})

export const addFavorite =
  (userFavorite: UserFavorite, dataType: string) => (): Promise<{ payload: any; type: string }> =>
    FavoritesApi.post({
      mpid: getMpid(),
      attributes: {
        created: moment().format(),
      },
      dataType,
      v4ID: userFavorite.titleId,
    })
      .then((response) => {
        return {
          type: UserInteractionsStoreActions.SET_FAVORITE,
          payload: { ...userFavorite, id: response?.data?.id },
        }
      })
      .catch(setError)

export const removeFavorite =
  (userFavorite: UserFavorite | undefined) => (): Promise<{ payload: any; type: string }> => {
    return FavoritesApi.delete(getMpid(), userFavorite?.id)
      .then(() => ({
        type: UserInteractionsStoreActions.REMOVE_FAVORITE,
        payload: userFavorite?.titleId,
      }))
      .catch(setError)
  }
