import { Img, Lightning, Utils } from '@lightningjs/sdk'

import LinearGradientShader from '../../../shaders/LinearGradientShader'
import { COLORS, SCREEN_SIZE } from '../../../constants'
import TVPlatform from '../../../lib/tv-platform'

// @ts-expect-error TS(2417): Class static side 'typeof ImageHolder' incorrectly... Remove this comment to see the full error message
export default class ImageHolder extends Lightning.Component {
  _image: any

  static override _template() {
    const timingFunction = 'cubic-bezier(0.20, 1.00, 0.80, 1.00)'

    return {
      type: Lightning.components.FastBlurComponent,
      amount: 0,
      scale: 1.02,
      w: SCREEN_SIZE.width,
      h: SCREEN_SIZE.height,
      transitions: {
        scale: { duration: 1.2, timingFunction },
      },
      Content: {
        Image: {
          alpha: 0.001,
          w: SCREEN_SIZE.width,
          h: SCREEN_SIZE.height,
          transitions: {
            alpha: { duration: 0.6, timingFunction },
          },
        },
      },
    }
  }

  override _init() {
    this._image = this.tag('Image')

    // attach linear gradient
    if (TVPlatform.getAllowLinearGradient()) this.attachShader()
    else this.attachFallbackGradient()
    // always attach bottom gradient
    this.attachBottomGradient()

    this._image.on('txLoaded', () => {
      this._image.setSmooth('alpha', 1)
      this.focusImage(true)
    })

    this._image.on('txError', () => {
      this._image.src = Utils.asset('images/background.png')
      this.focusImage(true)
    })
  }

  set image(image: any) {
    // Null check to avoid the patch with null path which breaks the app.
    if (image) {
      this._image.patch({
        texture: Img(image?.path).cover(SCREEN_SIZE.width, SCREEN_SIZE.height),
      })
    }
  }

  focusImage(v: any) {
    this.patch({
      smooth: { scale: v ? 1 : 1.02 },
    })
  }

  attachShader() {
    this.patch({
      Content: {
        Gradients: {
          Left: {
            w: 1128,
            h: SCREEN_SIZE.height,
            rect: true,
            shader: {
              type: LinearGradientShader,
              colors: [COLORS.black, COLORS.black2, COLORS.transparent],
              stops: [1.0, 0.71, 0.0],
              angle: -90,
            },
          },
        },
      },
    })
  }

  attachFallbackGradient() {
    this.patch({
      Content: {
        Gradients: {
          Left: {
            w: 1128,
            h: SCREEN_SIZE.height,
            rect: true,
            colorLeft: COLORS.black,
            colorRight: COLORS.transparent,
          },
        },
      },
    })
  }

  attachBottomGradient() {
    this.patch({
      Content: {
        Gradients: {
          Bottom: {
            w: SCREEN_SIZE.width,
            h: 540,
            y: 540,
            rect: true,
            colorBottom: COLORS.black,
            colorTop: COLORS.transparent,
          },
        },
      },
    })
  }
}
