import { Lightning } from '@lightningjs/sdk'
import BasePlayer from '../BasePlayer'

export const WithBackToLive = <T extends Lightning.Component.Constructor<BasePlayer>>(
  component: T
) =>
  class extends component {
    $backToLive() {
      this._player?.seekToLiveEdge()
      this._player?.play()
    }
  }
