import { IPlayerEvent } from './IPlayerEvent'

export class AdBreakEvent extends IPlayerEvent {
  _adBreak

  constructor(adBreak: any) {
    super()
    this._adBreak = adBreak
  }

  get adBreak() {
    return this._adBreak
  }
}
