import { Language, Lightning, Storage, Utils } from '@lightningjs/sdk'
import { Subscription } from 'rxjs'
import RouterUtil from '../util/RouterUtil'

import Item from '../components/items/Item'
import TextButton from './buttons/TextButton'
import ProgressBar from './progressBar/progressBar'
import SponsorBadge from './badges/SponsorBadge'
import LiveBadge from './badges/LiveBadge'
import ModalManager, { ModalTypes } from '../lib/ModalManager'
import { LiveStreamManager } from '../lib/LiveStreamManager'

import {
  ALIGN_CONTENT,
  ALIGN_ITEMS,
  ALIGN_SELF,
  COLORS,
  ENTITY_TYPES,
  FLEX_DIRECTION,
  FONT_FACE,
  JUSTIFY_CONTENT,
  SCREEN_SIZE,
  STORAGE_KEYS,
  VERTICAL_ALIGN,
  WORD_WRAP_WIDTH,
} from '../constants'
import { EVENTS } from '../lib/analytics/types'
import { PLAYER_TYPE } from '../player/core/PlayerFactory'
import { IMarketingModuleAnalytics } from '../api/types/bffTypes'

import {
  createXumoHoleShader,
  isSecondaryPlayerLoadObject,
  SecondaryPlayerLoadObject,
  SecondaryPlayerSingleton,
} from '../lib/SecondaryPlayer'
import { sendMetric } from '../lib/analytics/Analytics'

import {
  fadeComponent,
  FastImg,
  getCurrentProgressWidth,
  getHexColor,
  isOlympicsPage,
  isXclass,
  setSmooth,
} from '../helpers'
import TVPlatform from '../lib/tv-platform'
import { lockDecorator } from './items/decorators'

import LaunchDarklySingleton from '../lib/launchDarkly/LaunchDarkly'
import LaunchDarklyFeatureFlags from '../lib/launchDarkly/LaunchDarklyFeatureFlags'
import { OLY_IMPOLICY } from '../constants/images'

type ImpressionMetrics = {
  customShelfPosition: number
  itemClickedName: string | undefined
  itemClickedType: string | undefined
  destinationType: string | undefined
  isOlympics?: boolean
}

export default class MarketingModule extends Item {
  override _item: any
  _analytics: IMarketingModuleAnalytics
  _parentY: number
  _subscription?: Subscription

  static override _template() {
    const template = super._template()
    const timingFunction = 'cubic-bezier(0.20, 1.00, 0.80, 1.00)'
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2340: Only public and protected methods of the base class are accessible via the 'super' keyword.
    const duration = super.duration

    return {
      ...template,
      w: SCREEN_SIZE.width,
      h: SCREEN_SIZE.height,
      transitions: {
        y: { duration, timingFunction },
        scale: { duration, timingFunction },
        alpha: { duration, timingFunction },
        amount: { duration, timingFunction },
      },
      ImageHolder: {
        scale: 1.02,
        w: SCREEN_SIZE.width,
        h: SCREEN_SIZE.height,
        transitions: {
          scale: { duration: 1.2, timingFunction },
        },
        Background: {
          alpha: 0.001,
          w: SCREEN_SIZE.width,
          h: SCREEN_SIZE.height,
          transitions: {
            alpha: { duration: 0.6, timingFunction },
          },
          linearGradient: 'rgba(255, 0, 0, 0.45)',
        },
        Gradients: {
          alpha: 0.7,
          w: SCREEN_SIZE.width,
          h: SCREEN_SIZE.height,
          rect: true,
          color: COLORS.black,
        },
        shader: null,
      },
    }
  }

  override _handleDown(): boolean {
    if (!this.fireAncestors('$isLastIndex', this)) setSmooth(this, 'alpha', 0)
    return false
  }

  override _init() {
    this._addDecorators([lockDecorator])
    this.tag('Background').on('txLoaded', () => {
      this.tag('Background').setSmooth('alpha', 1)
      this.focusImage(true)
    })

    this.tag('Background').on('txError', () => {
      this.tag('Background').src = Utils.asset('images/background.png')
      this.focusImage(true)
    })
    this.patch({ x: this.parent?.parent?.x ? -this.parent.parent.x : 0 })
    this._subscription = SecondaryPlayerSingleton.subscribe(({ activated, type }) => {
      const element = this.tag(type === PLAYER_TYPE.BACKGROUND ? 'ImageHolder' : 'Image')
      const fadeEndValue = Number(!activated)
      if (element && element.alpha !== fadeEndValue) {
        fadeComponent(element, fadeEndValue)
      }
      if (isXclass() && type === PLAYER_TYPE.PREVIEW) {
        this.tag('ImageHolder').shader = createXumoHoleShader(activated)
      }
    })
  }

  override _detach() {
    this._subscription?.unsubscribe()
    this._subscription = undefined
  }

  override _focus() {
    if (this.alpha === 0) this.alpha = 1
    // Impression called here because Active fires early to
    // trigger the transitions but doesnt reflect true guest impression
    this._fireImpressionMetric()

    const { mainPreview, backgroundPreview } = this._item
    if (this.parent?.parent && this.parent.parent.y > 0) {
      this._parentY = this.parent.parent.y
      this.parent.parent.y = 0
      this.tag('SecondaryElementsHolder').y += this._parentY / 2
      this.tag('Image').y += this._parentY / 2
    }

    const players: SecondaryPlayerLoadObject[] = [
      {
        playbackUrl: mainPreview,
        type: PLAYER_TYPE.PREVIEW,
      },
      {
        playbackUrl: backgroundPreview,
        type: PLAYER_TYPE.BACKGROUND,
      },
    ]
    const items = players.filter((item) => isSecondaryPlayerLoadObject(item))

    if (!TVPlatform.capabilities.concurrentStreams && items.length > 1) {
      items.splice(1)
    }

    items.forEach(async (item) => {
      // Adding a delay so we don't overwhelm the device loading two streams at once
      if (LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.marketingModulePreview)) {
        SecondaryPlayerSingleton.load(item as SecondaryPlayerLoadObject)
      }
    })
    this.fireAncestors('$hideMenuBackground')
  }

  override _unfocus() {
    if (this.parent?.parent && this._parentY) {
      this.parent.parent.y = this._parentY
      this.tag('SecondaryElementsHolder').y -= this._parentY / 2
      this.tag('Image').y -= this._parentY / 2
    }
    this.fireAncestors('$showMenuBackground')
    this._setState('ButtonPrimary')
    if (SecondaryPlayerSingleton.isPreviewPlayerActive) {
      this._fireVideoPreview()
    }
    SecondaryPlayerSingleton.unload()
  }

  set analytics(analytics: IMarketingModuleAnalytics) {
    this._analytics = analytics
  }

  set items(v: any) {
    if (this._item) return
    this._item = v
    const { backgroundFallbackImage, mainImage, logo, logoAltText, primaryCTA, secondaryCTA } =
      this._item
    const primaryCtaColor = primaryCTA.data.color
      ? getHexColor(primaryCTA.data.color)
      : COLORS.lightGray10

    const currentProgressWidth = getCurrentProgressWidth({
      startTime: v.startDate,
      endTime: v.endDate,
      totalProgressWidth: 460,
    })

    this.patch({
      ImageHolder: {
        alpha: 0.7,
        Background: {
          texture: FastImg(backgroundFallbackImage?.path).cover(
            backgroundFallbackImage?.width,
            backgroundFallbackImage?.height,
            OLY_IMPOLICY.RECT_MEDIUM_1024_576
          ),
        },
      },
      Image: {
        y: 100,
        x: 80,
        w: 960,
        h: 580,
        texture: FastImg(mainImage?.path).contain(450, 100, OLY_IMPOLICY.RECT_SMEDIUM_640_360),
      },
      SecondaryElementsHolder: {
        y: 105,
        x: 1120,
        w: 700,
        h: 580,
        flex: {
          direction: FLEX_DIRECTION.column,
          justifyContent: JUSTIFY_CONTENT.center,
        },
        LogoHolder: {
          w: 462,
          h: 154,
          flex: {
            direction: FLEX_DIRECTION.row,
            alignContent: ALIGN_CONTENT.center,
          },
          Logo: {
            flexItem: {
              alignSelf: logo?.path ? ALIGN_SELF.center : ALIGN_SELF.flexEnd,
            },
            texture: logo?.path
              ? FastImg(logo.path).contain(462, 154)
              : {
                  type: Lightning.textures.TextTexture,
                  text: logoAltText,
                  maxLines: 2,
                  wordWrapWidth: WORD_WRAP_WIDTH,
                },
          },
        },
        SponsorshipArea: {
          h: 77,
          w: 600,
          visible: v.isSponsored,
          flex: {
            direction: FLEX_DIRECTION.row,
            alignItems: ALIGN_ITEMS.center,
          },
          flexItem: { marginTop: 10, marginBottom: 25 },
          PresentedByText: {
            text: {
              text: `${Language.translate('presented_by')}`,
              fontSize: 26,
              fontFace: FONT_FACE.light,
              lineHeight: 30,
            },
          },
          SponsorIcon: {
            h: 26,
            flexItem: { marginLeft: 20 },
            type: SponsorBadge,
            sponsorLogo: v.sponsorLogo?.path,
            sponsorFallbackText: v.sponsorLogoAltText ?? v.sponsorName,
            sponsorLogoHeight: 26,
            sponsorLogoWidth: 300,
          },
        },
        LiveBadgeContainer: {
          flex: {},
          fontSize: 25,
          h: 36,
          label: Language.translate('live'),
          type: LiveBadge,
          visible: this.showLiveBadge,
        },
        LockContainer: {
          flex: {},
        },
        PlaylistContainer: {
          visible: primaryCTA.data.isPlaylist,
          flex: {
            direction: FLEX_DIRECTION.row,
            alignItems: ALIGN_ITEMS.center,
          },
          flexItem: { marginBottom: 10 },
          PlaylistImage: {
            w: 52,
            h: 26,
            src: Utils.asset('images/playlist-icon.png'),
          },
          PlaylistInfo: {
            flexItem: { marginLeft: 10, marginTop: 2 },
            text: {
              text: Language.translate('mm-playlist', primaryCTA.data?.playlistCount),
              fontSize: 24,
              fontFace: FONT_FACE.semiBold,
              lineHeight: 30,
              verticalAlign: VERTICAL_ALIGN.middle,
            },
          },
        },
        Title: {
          flexItem: { marginTop: 10 },
          alpha: 0.87,
          w: 600,
          h: 100,
          text: {
            fontSize: 32,
            fontFace: FONT_FACE.light,
            lineHeight: 40,
            maxLines: 2,
            text: v.description,
          },
        },
        LiveProgressBarContainer: {
          y: 610,
          w: 460,
          visible: false,
          LiveProgressBar: {
            type: ProgressBar,
            h: 10,
            totalProgressWidth: 460,
            totalProgressColor: COLORS.charcoalGrey,
            currentProgressColor: COLORS.red,
            currentProgressWidth,
          },
        },
        ButtonHolder: {
          w: 600,
          h: 60,
          flexItem: { marginTop: 30 },
          flex: { direction: FLEX_DIRECTION.row },
          ButtonPrimary: {
            flexItem: {
              marginRight: 20,
            },
            h: 58,
            type: TextButton,
            radius: 29,
            fontSize: 28,
            fontFace: FONT_FACE.regular,
            focusFontColor: COLORS.white,
            unfocusFontColor: COLORS.white,
            focusBackGroundColor: primaryCtaColor,
            unfocusBackgroundColor: COLORS.transparent,
            strokeWidth: 1,
            strokeColor: primaryCtaColor,
            autoWidth: true,
            padding: 22,
            label: primaryCTA.data.text,
          },
        },
      },
    })

    if (secondaryCTA?.data) {
      const secondaryButtonText =
        secondaryCTA.data.text.length > 10
          ? `${secondaryCTA.data.text.substring(0, 10)}...`
          : secondaryCTA.data.text
      const secondaryCtaColor = secondaryCTA.data.color
        ? getHexColor(secondaryCTA.data.color)
        : COLORS.lightGray10
      this.tag('SecondaryElementsHolder.ButtonHolder').patch({
        ButtonSecondary: {
          h: 58,
          type: TextButton,
          radius: 29,
          fontSize: 28,
          fontFace: FONT_FACE.regular,
          focusFontColor: COLORS.white,
          unfocusFontColor: COLORS.white,
          focusBackGroundColor: secondaryCtaColor,
          unfocusBackgroundColor: COLORS.transparent,
          strokeWidth: 1,
          strokeColor: secondaryCtaColor,
          autoWidth: true,
          padding: 22,
          label: secondaryButtonText,
        },
      })
    }

    this._setState('ButtonPrimary')
  }

  focusImage(v: any) {
    this.patch({
      ImageHolder: {
        smooth: { scale: v ? 1 : 1.02 },
      },
    })
  }

  override trackContentClick(item: any) {
    if (!this._item) return

    this.trackContentPosition()

    const { analytics } = item

    analytics.itemClickedName = this._getItemClickedName(item)

    const shelf = {
      tileIndex: 1,
      shelfIndex: this._getPosition(),
      listTitle: 'Marketing Module',
      playlistMachineName: analytics.machineName,
      playlistPosition: analytics.playlistPosition,
      machineName: this._analytics?.machineName,
      customShelfType: this._analytics?.shelfType,
    }
    const params = {
      name: item.data.text,
      entity: {
        entityType: ENTITY_TYPES.MARKETING_MODULE,
        analytics,
      },
      shelf,
    }

    if (isOlympicsPage()) {
      params.entity.analytics.isOlympics = true
    }

    Storage.set(STORAGE_KEYS.REFERRING_SHELF, shelf)
    // Sending the events
    sendMetric(EVENTS.CLICK, params)
    sendMetric(EVENTS.CONTENT_CLICK, params)
  }

  _getPosition(): number {
    const index = this.parent?.children.indexOf(this)
    return index && index > -1 ? index + 1 : 1
  }

  _getItemClickedName(cta = this._item.primaryCTA): string | undefined {
    const { analytics: ctaAnalytics } = cta

    let item

    switch (ctaAnalytics.destinationType) {
      case 'series':
        item = ctaAnalytics?.series
        break
      case 'video':
        item = ctaAnalytics?.videoTitle
        break
      case 'internalPage':
      case 'externalPage':
      case 'playlist':
        item = ctaAnalytics?.ctaTitle
    }

    return item
  }

  _getItemClickedType(cta = this._item.primaryCTA) {
    const { analytics: ctaAnalytics } = cta

    return ctaAnalytics?.programmingType || ctaAnalytics?.programType
  }

  _getDestinationType(cta = this._item.primaryCTA) {
    const { analytics: ctaAnalytics } = cta
    return ctaAnalytics.destinationType
  }

  _fireImpressionMetric(): void {
    const params: ImpressionMetrics = {
      customShelfPosition: this._getPosition(),
      itemClickedName: this._getItemClickedName(),
      itemClickedType: this._getItemClickedType(),
      destinationType: this._getDestinationType(),
      ...this._analytics,
    }

    if (isOlympicsPage()) {
      params.isOlympics = true
    }

    sendMetric(EVENTS.MARKETING_MODULE_IMPRESSION, params)
  }

  _fireVideoPreview() {
    const metadata = SecondaryPlayerSingleton.getPreviewVideoAnalytics()
    const isLive = this._item?.primaryCTA?.analytics?.isLive
    const isTrailer = this._item?.primaryCTA?.analytics?.isTrailer
    const destinationType = this._item?.primaryCTA?.analytics?.destinationType || ''
    const video = (isLive && 'Live') || (isTrailer && 'Sneak Peek') || 'Full Episode'
    const previewType =
      {
        video,
        playlist: video,
        series: 'Show',
        movie: 'Movie',
        internalPage: 'Internal',
        externalPage: 'External',
        externalAppLink: 'External',
        trial: 'External',
        vote: 'External',
      }[destinationType as string] || 'Internal'

    const params = {
      ...this._analytics,
      brand: this._item?.primaryCTA?.analytics?.brand?.title,
      customShelfPosition: this._getPosition(),
      listTitle: 'Marketing Module',
      percentComplete: metadata.percentWatched,
      durationWatched: metadata.position,
      customShelfType: '',
      duration: metadata.duration,
      v4ID: this._item.v4ID,
      league: this._item?.primaryCTA?.analytics?.league,
      locked: this._item?.locked,
      episodeTitle: this._item?.primaryCTA?.analytics?.videoTitle,
      episodeNumber: this._item?.primaryCTA?.analytics?.episodeNumber,
      seasonNumber: this._item?.primaryCTA?.analytics?.seasonNumber,
      previewType,
      sport: this._item?.primaryCTA?.analytics?.sport,
    }
    sendMetric(EVENTS.MARKETING_MODULE_VIDEO_PREVIEW, params)
  }

  static override get tag() {
    return 'MarketingModule'
  }

  get showLiveBadge() {
    return this._item?.badge === 'LIVE'
  }

  get isLocked() {
    return this._item?.locked
  }

  get lockContainer() {
    return this.tag('LockContainer')
  }

  get lockTransparent() {
    return true
  }

  get keyBadgePosition() {
    return 60
  }

  static override _states() {
    return [
      class ButtonPrimary extends this {
        override _getFocused() {
          return this.tag('SecondaryElementsHolder.ButtonPrimary')
        }

        override _handleRight(): void {
          if (this._item?.secondaryCTA) this._setState('ButtonSecondary')
        }

        override _handleEnter(): void {
          this.trackContentClick(this._item?.primaryCTA)

          if (this._item?.primaryCTA?.data?.qrModal) {
            const qrModalData = this._item.primaryCTA.data.qrModal
            ModalManager.open(ModalTypes.QR_MODAL, qrModalData)
          } else {
            if (this._item?.primaryCTA?.data?.isLive) {
              LiveStreamManager.set('', this._item?.primaryCTA?.data?.destination, '')
            }
            RouterUtil.navigateByDestinationType(this._item?.primaryCTA?.data)
          }
          if (SecondaryPlayerSingleton.isPreviewPlayerActive) {
            this._fireVideoPreview()
          }
        }
      },
      class ButtonSecondary extends this {
        override _getFocused() {
          return this.tag('SecondaryElementsHolder.ButtonSecondary')
        }

        override _handleLeft(): void {
          if (this._item?.secondaryCTA) this._setState('ButtonPrimary')
        }

        override _handleEnter(): void {
          this.trackContentClick(this._item?.secondaryCTA)

          if (this._item?.secondaryCTA?.data?.qrModal) {
            const qrModalData = this._item.secondaryCTA.data.qrModal
            ModalManager.open(ModalTypes.QR_MODAL, qrModalData)
          } else {
            if (this._item?.secondaryCTA?.data?.isLive) {
              LiveStreamManager.set('', this._item?.secondaryCTA?.data?.destination, '')
            }
            RouterUtil.navigateByDestinationType(this._item?.secondaryCTA?.data)
          }
        }
      },
    ]
  }
}
