import { createRequestConfig } from '../../lib/useRequest'
import { mainRequest } from '../../api/requests'
import Variables from '../../graphql/Variables'
import { COMPONENT_TYPES } from '../../constants'

export const ShowsPageRequestConfig = () =>
  createRequestConfig({
    query: mainRequest,
    variables: Variables.showsPageVariables(),
    reject: [
      (data) => !data,
      (data) =>
        !!data?.sections?.[0] &&
        'component' in data.sections[0] &&
        data.sections[0].component !== COMPONENT_TYPES.BRAND_SELECTABLE_GROUP,
    ],
  })
