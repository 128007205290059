import { IStorageFactory } from './IStorageFactory'
import { LSStorage } from './LSStorage'

class StorageFactory extends IStorageFactory {
  _storage = new LSStorage()

  override get() {
    return this._storage
  }
}

export const StorageFactorySingleton = new StorageFactory()
