export enum PARK_ENTITY_TYPES {
  'series' = 'series',
  'movie' = 'movies',
}

//These were taken from the generated graphql. They should be removed if we re-implement that
export enum QueryNames {
  BecauseYouWatchedShelf = 'becauseYouWatchedShelf',
  BonanzaPage = 'bonanzaPage',
  BrandSeriesGroupedByCategory = 'brandSeriesGroupedByCategory',
  BrandTitleCategories = 'brandTitleCategories',
  ComponentsForPlaceholders = 'componentsForPlaceholders',
  ContinueWatchingShelf = 'continueWatchingShelf',
  DaySchedule = 'daySchedule',
  EditorialShelf = 'editorialShelf',
  EndCard = 'endCard',
  FavoriteInteractions = 'favoriteInteractions',
  FavoritesShelf = 'favoritesShelf',
  FeaturedSection = 'featuredSection',
  FeaturedShowsSection = 'featuredShowsSection',
  GlobalNavigation = 'globalNavigation',
  MixedEditorialShelf = 'mixedEditorialShelf',
  NeuralNetShelf = 'neuralNetShelf',
  NewEpisodesAvailableShelf = 'newEpisodesAvailableShelf',
  OnAirNowShelf = 'onAirNowShelf',
  OnboardingBrandCategories = 'onboardingBrandCategories',
  OnboardingCategoryFavorites = 'onboardingCategoryFavorites',
  Page = 'page',
  PaginatedComponent = 'paginatedComponent',
  PaginatedShelf = 'paginatedShelf',
  PopularTitlesShelf = 'popularTitlesShelf',
  RecentEpisodes = 'recentEpisodes',
  RecentlyUnlockedShelf = 'recentlyUnlockedShelf',
  ScrollFromSeason = 'scrollFromSeason',
  SearchShelf = 'searchShelf',
  SeasonShelfGroup = 'seasonShelfGroup',
  TitleV2AboutSection = 'titleV2AboutSection',
  TitleV2HomeSections = 'titleV2HomeSections',
  TitleV2MoreSections = 'titleV2MoreSections',
  UserFavorites = 'userFavorites',
  UserInteractions = 'userInteractions',
  UserLiveWatches = 'userLiveWatches',
  UserWatches = 'userWatches',
  VideosSection = 'videosSection',
}

export enum EntityPageType {
  BrandLanding = 'BRAND_LANDING',
  Browse = 'BROWSE',
  LiveSchedule = 'LIVE_SCHEDULE',
  Page = 'PAGE',
  Playlist = 'PLAYLIST',
  Schedule = 'SCHEDULE',
  Series = 'SERIES',
  SportLanding = 'SPORT_LANDING',
  Stream = 'STREAM',
  Title = 'TITLE',
  TitleV2 = 'TITLE_V2',
  TitleV2About = 'TITLE_V2_ABOUT',
  TitleV2Bio = 'TITLE_V2_BIO',
  TitleV2Cast = 'TITLE_V2_CAST',
  TitleV2Contestants = 'TITLE_V2_CONTESTANTS',
  TitleV2Episodes = 'TITLE_V2_EPISODES',
  TitleV2More = 'TITLE_V2_MORE',
  Video = 'VIDEO',
  OlympicsPage = 'OLYMPICS_PAGE',
}
