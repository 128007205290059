import { Launch } from '@lightningjs/sdk'

import App from './App'
import './moment'
import TVPlatform from './lib/tv-platform'
import settings from '../settings.json'
import metadata from '../metadata.json'
import {
  ENV,
  getInspectorFromUrl,
  getProxyPath,
  loadLightningInspector,
  migrateLocalStorage,
} from './helpers'
import { IPlatformSettings } from '../types/global'
import { DEFAULT_STAGE_STYLES } from './constants'

const {
  appSettings,
  platformSettings,
  appData = {},
}: {
  appSettings: any
  platformSettings: any
  appData?: any
} = settings || {}
const formatAppSettings = (appSettings: any) => ({
  ...appSettings,
  stage: { ...appSettings.stage, ...TVPlatform.getStageSettings() },
  keys: TVPlatform.getPlatformKeyMapping(),
  ...metadata,
})

const formatPlatformSettings = (platformSettings: IPlatformSettings) => {
  /**
   * Production index.html doesn't have window.PROXY_PATH, so we use global PROXY_PATH
   */
  platformSettings.path = getProxyPath()
  if (NODE_ENV === ENV.PRODUCTION) {
    platformSettings.log = false
    platformSettings.inspector = false
  }
  return platformSettings
}

const adaptedAppSettings = formatAppSettings(appSettings)

const adaptedPlatformSettings = formatPlatformSettings(platformSettings)

const initializeCanvas = () => {
  const app = Launch(App, adaptedAppSettings, adaptedPlatformSettings, appData)
  if (platformSettings.inspector || getInspectorFromUrl()) loadLightningInspector()
  migrateLocalStorage()
  TVPlatform.init()
  const style = document.createElement('style')
  document.head.appendChild(style)
  style?.sheet?.insertRule(DEFAULT_STAGE_STYLES)
  document.body.appendChild(app.stage.getCanvas())
}

// Override the TextTexture setter with a setter that has an input value check for null and undefined.
// @ts-expect-error TS2551: Property 'textures' does not exist on type 'Application<TemplateSpecLoose, TypeConfig>'. Did you mean 'texture'?
Object.defineProperty(window.lng.textures.TextTexture.prototype, 'text', {
  set(text) {
    text = typeof text !== 'undefined' && text !== null ? text : ''
    if (this._text !== text) {
      this._text = String(text)
      this._changed()
    }
  },
})
initializeCanvas()
TVPlatform.initializeFonts()
