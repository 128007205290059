import { Router } from '@lightningjs/sdk'

import { preCheckByCallSign, preCheckRoutes } from './precheck'
import { ROUTE } from '../../../constants'
import { LiveStreamManager } from '../../../lib/LiveStreamManager'
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore'
import { setEpgChannel } from '../../../store/PlayerStore/actions/epg'
import TVPlatform from '../../../lib/tv-platform'
import { Metrics } from '@firebolt-js/sdk'
import ErrorType = Metrics.ErrorType

export const navigateLinearItem = async ({ stream, routerBackDisabled }: any) => {
  try {
    const { channelId, streamAccessName, callSign, machineName } = stream
    if (preCheckRoutes.includes(<ROUTE>Router.getActiveRoute())) {
      const payload = await preCheckByCallSign(callSign, routerBackDisabled)
      if (payload) {
        LiveStreamManager.set(channelId, streamAccessName, callSign)
        await PlayerStoreSingleton.dispatch(setEpgChannel(channelId, streamAccessName, machineName))
      }
    }
  } catch (error) {
    if (error)
      TVPlatform.reportError({
        type: ErrorType.NETWORK,
        code: 'Player - EPG',
        description: 'unable to load stream data',
        payload: stream?.callSign,
      })
    throw error
  }
}
