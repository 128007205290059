import BasePlayer from './BasePlayer'

export default class BaseEventsPlayer extends BasePlayer {
  override _analyticsDelegate: any
  override pid: any
  override set params(params: any) {
    this.pid = params.pid
  }

  override get widgets() {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    return this.parent.widgets
  }

  override _attach() {
    super._attach()
    this.fireAncestors('$onAttached')
    this._analyticsDelegate?.firePageLoad()
    this._load()
  }

  override _setPlayerInActiveTimeout() {}
}
