import Announce from './Announce'
import { Registry } from '@lightningjs/sdk'

export default class VizioAnnounce extends Announce {
  async speak(): Promise<void> {
    return new Promise<void>((resolve) => {
      window.VIZIO.Chromevox?.play(this.phrase)
      Registry.setTimeout(() => resolve(), Announce.estimateSpeechTime(this.phrase))
    })
  }
}
