import { Lightning } from '@lightningjs/sdk'
import { Paragraph } from '../index'
import { updateElementH } from '../../helpers/template'

export default class AboutTextComponent extends Lightning.Component {
  contentStyle: any
  titleStyle: any
  static override _template() {
    return {
      Title: {
        type: Paragraph,
        paragraph: {},
        h: 0,
      },
      Body: {
        type: Paragraph,
        paragraph: {},
        h: 0,
      },
      Items: {
        h: 0,
      },
    }
  }

  constructor(stage: any) {
    super(stage)
  }

  set title(v: any) {
    const titleStyle = Object.assign({}, this.titleStyle)
    this.tag('Title').paragraph = {
      text: v,
      w: this.w,
      ...titleStyle,
    }
    updateElementH(this)
  }

  set content(v: any) {
    const contentStyle = Object.assign({}, this.contentStyle)
    this.tag('Body').paragraph = {
      text: v,
      w: this.w,
      ...contentStyle,
    }
    updateElementH(this)
  }

  set items(v: any) {
    const items = this.tag('Items')
    if (v === undefined) {
      items.children = []
      return
    }

    items.children = v.map((item: any) => {
      return {
        w: this.w,
        type: AboutTextComponent,
        contentStyle: this.contentStyle,
        content: item,
      }
    })
    updateElementH(items)
    updateElementH(this)
  }
}
