import VideoTile from '../VideoTile/VideoTile'
import { getLiveStateClass, getUpcomingStateClass } from './states'
import {
  audioLanguageDecorator,
  freeBadgeDecorator,
  liveBadgeDecorator,
  lockDecorator,
  olympicsMedalDecorator,
} from '../decorators'

import {
  COLORS,
  FONT_FACE,
  ENTITY_TYPES,
  MAX_LINES_SUFFIX,
  FLEX_DIRECTION,
  OLY_IMPOLICY,
} from '../../../constants'
import { navigateUpcomingLiveItems } from '../helpers/upcomingItems'
import { FastImg } from '../../../helpers'
import { TrackParams } from '../../types'
import TVPlatform from '../../../lib/tv-platform'

// @ts-expect-error TS(2417): Class static side 'typeof UpcomingLiveTile' incorr... Remove this comment to see the full error message
export default class UpcomingLiveTile extends VideoTile {
  override set item(v: any) {
    this._item = v
    this.patch({
      ImageHolder: {
        w: 420,
        h: 235,
        Image: { texture: FastImg(v.image).contain(420, 235, OLY_IMPOLICY.RECT_SMEDIUM_640_360) },
      },
      Content: {
        TitleCard: {
          ShortTitleContainer: {
            ShortTitle: {
              text: {
                text: this._getMainTitle(),
                maxLines: 1,
                maxLinesSuffix: MAX_LINES_SUFFIX,
              },
            },
          },
          Title: {
            text: {
              text: this._getSubTitle(),
              maxLines: 2,
              maxLinesSuffix: MAX_LINES_SUFFIX,
            },
          },
          SmartTimeStamp: {
            flex: {},
            flexItem: { marginTop: -8 },
            ShortTitle: {
              flexItem: { marginRight: 8 },
              text: {
                text: this._item?.tertiaryTitle || '',
                fontSize: 24,
                textColor: COLORS.white,
                fontFace: FONT_FACE.light,
                maxLines: 1,
                wordWrapWidth: 280,
                maxLinesSuffix: MAX_LINES_SUFFIX,
                lineHeight: 24,
              },
            },
            AudioLanguageContainer: {
              flex: { direction: FLEX_DIRECTION.row },
            },
          },
        },
        Logo: { texture: FastImg(v.logo).contain(60, 40, OLY_IMPOLICY.RECT_SMALL_384_216) },
      },
    })

    if (v.isUpcoming) this._setState('UpcomingState')
    // The expired tiles should remain with the live bar and badges, so the items that are not upcoming are Live and Expired items
    if (!v.isUpcoming) this._setState('LiveState')
  }

  override _init() {
    super._init()
    this._addDecorators([
      lockDecorator,
      olympicsMedalDecorator(5),
      liveBadgeDecorator(this._item?.liveBadge, 184),
      audioLanguageDecorator,
      freeBadgeDecorator(),
    ])
  }

  override _getMainTitle(): string {
    return this._item?.title
  }

  override _getSubTitle(): string {
    return this._item?.secondaryTitle
  }

  override _handleEnter(): void {
    this.trackContentPosition()
    this.trackContentClick(ENTITY_TYPES.UPCOMING_LIVE_TILE)
    navigateUpcomingLiveItems({
      item: this._item,
      fireAncestors: this.fireAncestors.bind(this),
      preventClickMetric: true,
    })
  }

  override _inactive() {
    if (TVPlatform.getForceGC()) this.stage.gc()
  }

  override _getTrackParams(): TrackParams {
    return {
      isUpcoming: this._item.isUpcoming,
      isLive: this._item.isLive,
    }
  }

  override get announce() {
    return `${this._item.liveAriaLabel} press OK to select`
  }

  get olympicsMedalContainer() {
    return this.tag('ShortTitleContainer')
  }

  static override _states() {
    return [getUpcomingStateClass(this), getLiveStateClass(this)]
  }

  static override get tag(): string {
    return 'UpcomingLiveTile'
  }
}
