import { Language } from '@lightningjs/sdk'
import { get } from 'lodash'

export const isSmartTile = (section: any, mpxGuid: any) =>
  get(section, 'component') === 'SmartTile' && get(section, 'data.tile.data.mpxGuid') === mpxGuid

export const isVideoHomeShelf = (section: any) =>
  get(section, 'component') === 'LinksSelectableGroup' && get(section, 'data.items.0.data.items')

export const updateCTA = (cta = '') => {
  // Check if CTA is uppercase or capitalized.
  const lastCTAChar = cta.charAt(cta.length)
  const isCTAUpperCase = lastCTAChar === lastCTAChar.toUpperCase()

  // Lowercase values for comparison.
  const tileCTA = cta.toLowerCase()
  const startWatchingCta = Language.translate('startWatching').toLowerCase()
  const watchLatestEpisodeCta = Language.translate('watchLatestEpisode').toLowerCase()

  switch (tileCTA) {
    case startWatchingCta: {
      const continueWatchingCta = Language.translate('continueWatching')
      return isCTAUpperCase ? continueWatchingCta.toUpperCase() : continueWatchingCta
    }
    case watchLatestEpisodeCta: {
      const continueEpisodeCta = Language.translate('continueEpisode')
      return isCTAUpperCase ? continueEpisodeCta.toUpperCase() : continueEpisodeCta
    }
    default:
      return cta
  }
}

export const getContinueWatchingTile = (
  { seriesShortTitle, metadata }: any,
  percentViewed: any
) => ({
  data: {
    ...metadata,
    percentViewed,
    lastWatched: '',
    lastModified: '',
    darkPrimaryColor: '',
    movieShortTitle: '',
    colorBrandLogo: '',
    mpxAdPolicy: '',
    instanceID: `${Math.random()}`,
    secondaryTitle: seriesShortTitle,
    id: `${Math.random()}`,
    __typename: 'VideoItem',
  },

  ...{
    component: 'VideoTile',
    treatments: [],
    meta: null,
    analytics: null,
    __typename: 'VideoTile',
  },
})

export const getContinueWatchingShelf = (tile: any) => ({
  component: 'Shelf',
  format: 'collection',
  logicName: 'continueWatchingShelf',
  key: 'continue-watching-shelf',
  treatment: 'default',
  treatments: [],
  numItemsVisible: 4,
  deepLinkHandle: null,
  meta: { machineName: 'continue-watching-shelf' },

  data: {
    instanceID: `${Math.random()}`,
    lastModified: null,
    listTitle: Language.translate('continueWatching'),
    items: [tile],
    __typename: 'TileList',
  },

  id: `${Math.random()}`,
  __typename: 'Shelf',
})
