import { Lightning } from '@lightningjs/sdk'

import Item from '../Item'
import Slide from '../Slide'
import { labelBadgeDecorator, lockDecorator, progressDecorator } from '../decorators'
import { clipDataDecorator, movieDataDecorator, fullEventReplayDecorator } from './decorators'

import { COLORS, ENTITY_TYPES, FONT_FACE, OLY_IMPOLICY } from '../../../constants'
import { FastImg } from '../../../helpers'
import TVPlatform from '../../../lib/tv-platform'
import { LiveStreamManager } from '../../../lib/LiveStreamManager'

export default class SlideTile extends Item {
  buttonText: any
  secondaryTitle: any
  title: any
  videoTitle: any

  override _init() {
    super._init()
    this.containerHeight = 670
    this.containerWidth = 741
  }

  static override _template() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2340: Only public and protected methods of the base class are accessible via the 'super' keyword.
    const duration = super.duration
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2340: Only public and protected methods of the base class are accessible via the 'super' keyword.
    const timingFunction = super.timingFunction
    const template = super._template()

    return {
      ...template,
      ShadowBlur: {
        mount: 0.5,
        x: 370,
        y: 208,
        w: 841,
        h: 516,
        alpha: 0.8,
        type: Lightning.components.FastBlurComponent,
        amount: 1,
        content: {
          Shadow: {
            color: COLORS.black,
            mount: 0.5,
            x: 420,
            y: 258,
            texture: Lightning.Tools.getRoundRect(741, 416, 0),
          },
        },
        transitions: {
          y: { duration, timingFunction },
          scale: { duration, timingFunction },
          alpha: { duration, timingFunction },
          amount: { duration, timingFunction },
        },
      },
      ImageHolder: {
        rect: true,
        colorLeft: COLORS.brightGrey,
        colorRight: COLORS.thunder2,
        Image: {
          pivotX: 0,
          transitions: {
            scale: { duration, timingFunction },
          },
        },
      },
      Content: {
        y: 426,
        TitleCard: {
          ButtonText: {
            text: {
              fontFace: FONT_FACE.regular,
              fontSize: 36,
              textColor: COLORS.lightGray8,
              maxLines: 1,
              wordWrapWidth: 660,
              text: this.bindProp('buttonText'),
            },
          },
          Border: {
            y: 66,
            w: 50,
            h: 2,
            rect: true,
            color: COLORS.mediumGray3,
          },
          Title: {
            y: 80,
            text: {
              fontFace: FONT_FACE.semiBold,
              fontSize: 28,
              textColor: COLORS.mediumGray1,
              maxLines: 1,
              wordWrapWidth: 660,
              text: this.bindProp('title'),
            },
          },
          VideoTitle: {
            y: 122,
            text: {
              fontFace: FONT_FACE.regular,
              fontSize: 26,
              textColor: COLORS.mediumGray1,
              maxLines: 1,
              wordWrapWidth: 660,
              text: this.bindProp('videoTitle'),
            },
          },
          SecondaryTitle: {
            y: 164,
            text: {
              fontFace: FONT_FACE.regular,
              fontSize: 26,
              textColor: COLORS.lightGray8,
              maxLines: 1,
              wordWrapWidth: 660,
              text: this.bindProp('secondaryTitle'),
            },
          },
          transitions: {
            y: { duration, timingFunction },
          },
        },
        Logo: {
          y: 4,
          mountX: 1,
          x: 720,
          transitions: {
            y: { duration, timingFunction },
          },
        },
      },
    }
  }

  set item(v: any) {
    this._item = v
    this.patch({
      ImageHolder: {
        h: 416,
        w: 741,
        Image: { texture: FastImg(v.image).contain(741, 416, OLY_IMPOLICY.RECT_MEDIUM_1024_576) },
      },
      Content: {
        Logo: {
          texture: FastImg(v.whiteBrandLogo).contain(60, 40, OLY_IMPOLICY.RECT_SMALL_384_216),
        },
      },
    })

    if (v.buttonText) this.buttonText = v.buttonText
    if (v.title) this.title = v.title

    this.videoTitle = ((v.showClipData || !v.videoTitle) && v.secondaryTitle) || v.videoTitle || ''
    if (!v.showClipData && v.videoTitle && v.secondaryTitle) {
      this.secondaryTitle = v.secondaryTitle || ''
    } else if (!v.showClipData) {
      this.videoTitle = v.description
      this.tag('VideoTitle').patch({
        text: {
          maxLines: 2,
        },
      })
    }
    this.tag('VideoTitle').on('txLoaded', (obj: any) => {
      if (this.tag('Date'))
        this.tag('Date').y = obj._source.renderInfo.lines.length === 2 ? 189 : 164
    })

    this._addDecorators([
      labelBadgeDecorator(this._item?.labelBadge),
      movieDataDecorator,
      clipDataDecorator,
      progressDecorator(416),
      fullEventReplayDecorator,
    ])
  }

  override _active() {
    this._addDecorators([lockDecorator])
  }

  override _inactive() {
    if (TVPlatform.getForceGC()) this.stage.gc()
  }

  override _handleEnter() {
    this.trackContentPosition()
    this.trackContentClick(ENTITY_TYPES.SLIDE)
    if (Slide.isLiveSlide(this._item)) {
      // When the streamaccessname is not avialable setting it to destination
      LiveStreamManager.set(
        this._item.destination,
        this._item.streamAccessName || this._item.destination,
        ''
      )
    }
    Slide.navigateDestination(this._item)
  }

  static override get tag() {
    return 'SlideTile'
  }

  get isLocked() {
    return this._item.isLocked
  }

  get lockContainer() {
    return this.tag('Image')
  }

  static get width() {
    return 741
  }

  get keyBadgePosition() {
    return this.containerWidth
  }
}
