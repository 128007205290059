import { checkContentGating } from '../../../../helpers'
import { StreamLoaderErrors } from '../error'
import { StreamRequest } from '../request'
import { TOKEN_TYPE } from '../../../../constants'
import { Stream, Program } from '../../../../store/PlayerStore/PlayerStore'
import { SingleStream } from '../../../../store/PlayerStore/actions'

export const contentGating = async (
  request: StreamRequest,
  stream: Stream,
  program: Program,
  _: any
) => {
  try {
    const wasContentGatingShown = await checkContentGating(stream as SingleStream, program)
    if (wasContentGatingShown) request.tokenType = TOKEN_TYPE.FREE_CONTENT_GATING
    return request
  } catch (_) {
    request.error = {
      handled: true,
      detail: StreamLoaderErrors.USER_CANCELLED,
      data: undefined,
    }
    throw request
  }
}
