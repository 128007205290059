import {
  CCColors,
  CCEdgeStyles,
  CCFontSizes,
  CCFonts,
  CCOpacities,
  getClosestColorFromHex,
} from '../../ClosedCaptions/ClosedCaptionsUtils'
import { CCStyleEvent } from '../base'

// Using our own type since Firebolt's Accesibility.ClosedCaptionsStyles only defines types as string or number
export type ComcastCCEvent = {
  fontFamily:
    | 'default'
    | 'monospaced_serif'
    | 'proportional_serif'
    | 'monospaced_sanserif'
    | 'proportional_sanserif'
    | 'casual'
    | 'cursive'
    | 'smallcaps'
  fontSize: 0.5 | 1 | 1.5 | 2
  fontColor: string
  fontEdge: 'raised' | 'depressed' | 'uniform' | 'drop_shadow_left' | 'drop_shadow_right' | 'none'
  fontEdgeColor: string
  fontOpacity: 0.5 | 1
  backgroundColor: string
  backgroundOpacity: 0.5 | 1
  textAlign: string
  textAlignVertical: string
}

export const comcastFormatCC = (e: ComcastCCEvent): CCStyleEvent => {
  return {
    fontStyle: {
      default: CCFonts['default'],
      monospaced_serif: CCFonts['monospaced-serif'],
      proportional_serif: CCFonts['proportional-serif'],
      monospaced_sanserif: CCFonts['monospaced-sans-serif'],
      proportional_sanserif: CCFonts['proportional-sans-serif'],
      casual: CCFonts['casual'],
      cursive: CCFonts['cursive'],
      smallcaps: CCFonts['small-capitals'],
    }[e.fontFamily],
    fontSize: {
      0.5: CCFontSizes['small'],
      1: CCFontSizes['medium'],
      1.5: CCFontSizes['large'],
      2: CCFontSizes['large'], // This is supported by Xclass but not sure if on other platforms
    }[e.fontSize],
    fontColor: getClosestColorFromHex(e.fontColor),
    edgeStyle: {
      raised: CCEdgeStyles['raised'],
      depressed: CCEdgeStyles['depressed'],
      uniform: CCEdgeStyles['uniform'],
      drop_shadow_left: CCEdgeStyles['shadow'],
      drop_shadow_right: CCEdgeStyles['shadow'],
      none: CCEdgeStyles['none'],
    }[e.fontEdge],
    edgeOpacity: CCOpacities['100%'],
    edgeColor: getClosestColorFromHex(e.fontEdgeColor),
    fontOpacity: {
      0.5: CCOpacities['50%'],
      1: CCOpacities['100%'],
    }[e.fontOpacity],
    backgroundColor: getClosestColorFromHex(e.backgroundColor),
    backgroundOpacity: {
      0.5: CCOpacities['50%'],
      1: CCOpacities['100%'],
    }[e.backgroundOpacity],
    // Xumo TV isn't providing window styles in the payload, which is weird
    // since you can find them in the TV settings.
    // We should investigate if other Comcast devices are providing it,
    // for now we have to send a fallback value.
    windowColor: CCColors.black,
    windowOpacity: CCOpacities['0%'],
  }
}
