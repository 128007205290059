import AuthenticationSingleton from '../../../../authentication/Authentication'
import { CONTENT_TYPES } from '../../../../authentication/authConstants'
import { CONTENT_TYPE, ENTITLEMENT, PROGRAMMING_TYPES } from '../../../../constants'
import PlayerStoreSingleton from '../../../../store/PlayerStore/PlayerStore'
import { fetchGeo } from '../../../../store/PlayerStore/actions/geo'
import { geoError, unauthenticatedError } from '../error'
import { StreamAuthorizationType, StreamRequest } from '../request'

const _getEntitlementId = (stream: any) => {
  switch (stream?.programmingType) {
    case PROGRAMMING_TYPES.SLE:
    case PROGRAMMING_TYPES.FER:
      return stream?.regionEntitlementId
    default:
      return null
  }
}

export const geo = async (request: StreamRequest, stream: any) => {
  try {
    const channelId = stream?.channelId || stream?.resourceId
    const channelName = stream?.streamAccessName

    if (channelId) {
      const contentType: string =
        CONTENT_TYPES[stream?.programmingType as keyof typeof CONTENT_TYPES] || stream?.contentType
      const result = await PlayerStoreSingleton.dispatch(
        fetchGeo(channelId, channelName, {
          contentType: contentType === CONTENT_TYPE.LINEAR ? CONTENT_TYPES.live : contentType,
          failOnRestrictionError: true,
          entitlementId: _getEntitlementId(stream),
        })
      )
      const linear = stream?.contentType === CONTENT_TYPE.LINEAR
      if (result.payload?.authKill || stream?.entitlement === ENTITLEMENT.FREE) {
        request.authType = StreamAuthorizationType.UNLOCKED
      } else if (linear && !AuthenticationSingleton.isAuthenticated()) {
        request.error = unauthenticatedError({})
        throw request
      }
    }
    return request
  } catch (error) {
    if (!request.error) {
      request.error = geoError(error)
    }
    throw request
  }
}
