import { Language, Lightning, Router } from '@lightningjs/sdk'
import { RouterPage } from '../../../types/global'
import { setSmooth } from '../../helpers'
import { COLORS, FONT_FACE, TEXT_ALIGN, VERTICAL_ALIGN } from '../../constants'
export const WithRequestErrorState = 'RequestError'

export const WithRequestError = <
  T extends Lightning.Component.Constructor<Lightning.Component<any, RouterPage>>,
>(
  component: T
) =>
  class extends component {
    // @ts-expect-error todo
    static override _template() {
      return {
        // @ts-expect-error todo
        ...super._template(),
        RequestError: {
          alpha: 0,
          x: 960,
          mountX: 0.5,
          y: 650,
          text: {
            textColor: COLORS.white,
            verticalAlign: VERTICAL_ALIGN.middle,
            textAlign: TEXT_ALIGN.center,
            fontSize: 48,
            fontFace: FONT_FACE.light,
            text: Language.translate('error_filters_title'),
          },
        },
      }
    }

    // @ts-expect-error this member cannot have an 'override' modifier because it is not declared in the base class 'Component<any, RouterPage>'
    static override _states() {
      return [
        // @ts-expect-error super doesn't work with static methods
        ...(super._states() || []),
        class RequestError extends this {
          override $enter() {
            setSmooth(this.widgets.loader, 'visible', 0)
            setSmooth(this.tag('RequestError'), 'alpha', 1)
          }

          override _getFocused() {
            return this
          }

          override _handleUp() {
            Router.focusWidget('Menu')
          }
        },
      ]
    }
  }
