import {
  CCColors,
  CCEdgeStyles,
  CCFontSizes,
  CCFonts,
  CCOpacities,
} from '../../../../lib/ClosedCaptions/ClosedCaptionsUtils'
import { CCStyleEvent } from '../../../../lib/tv-platform/base'
import { BaseCCDelegate } from './BaseCCDelegate'

export class AAMPCCDelegate extends BaseCCDelegate {
  override _ctx: any
  override setCCStyle(options: CCStyleEvent) {
    // AAMP code reference
    // Fonts: https://github.com/rdkcmf/rdk-aamp/blob/3e572144bdce84e518f484a53b88e6ea866f04c2/closedcaptions/AampCCManager.cpp
    // Colors: https://github.com/rdkcmf/rdk-aamp/blob/3e572144bdce84e518f484a53b88e6ea866f04c2/closedcaptions/subtec/SubtecConnector.h
    const sizeMappings: Record<CCFontSizes, string> = {
      56: 'large',
      45: 'medium',
      33: 'small',
    }
    const fontMappings: Record<CCFonts, string> = {
      Default: 'default',
      Arial: 'proportional_sanserif',
      Helvetica: 'monospaced_sanserif',
      Coronet: 'cursive',
      TimesNewRoman: 'proportional_serif',
      Courier: 'monospaced_serif',
      Impress: 'casual',
      Copperplate: 'smallcaps',
    }
    const opacityMappings: Record<CCOpacities, string> = {
      ff: 'solid',
      '40': 'translucent',
      '80': 'translucent',
      bf: 'translucent',
      '00': 'transparent',
    }
    const colorsMappings: Record<CCColors, string> = {
      Black: 'black',
      Blue: 'blue',
      Green: 'green',
      Magenta: 'magenta',
      Cyan: 'cyan',
      Teal: 'green',
      Red: 'red',
      Purple: 'magenta',
      Yellow: 'yellow',
      White: 'white',
    }
    const edgeStyleMappings: Record<CCEdgeStyles, string> = {
      none: 'none',
      uniform: 'uniform',
      raised: 'raised',
      depressed: 'depressed',
      shadow: 'drop_shadow_right',
    }
    const backgroundOpacity = opacityMappings[options.backgroundOpacity]

    const optionsObject = {
      penItalicized: false,
      textEdgeStyle: edgeStyleMappings[options.edgeStyle],
      textEdgeColor: colorsMappings[options.edgeColor],
      penSize: sizeMappings[options.fontSize],
      windowFillColor: colorsMappings[options.windowColor],
      fontStyle: fontMappings[options.fontStyle],
      textForegroundColor: colorsMappings[options.fontColor],
      windowFillOpacity: opacityMappings[options.windowOpacity],
      textForegroundOpacity: opacityMappings[options.fontOpacity],
      textBackgroundColor:
        backgroundOpacity !== 'transparent' ? colorsMappings[options.backgroundColor] : 'auto',
      // Transparent background hides text completely, which should be bad user experience
      textBackgroundOpacity: backgroundOpacity !== 'transparent' ? backgroundOpacity : 'solid',
      windowBorderEdgeStyle: 'none',
      windowBorderEdgeColor: colorsMappings[options.windowColor],
      penUnderline: false,
    }
    this._ctx?._coreVideoController?.engine?.playerItem?.player?.setTextStyleOptions(
      JSON.stringify(optionsObject)
    )
  }
}
