import { bootApp } from '../../api/Api'
import { QueryName, getQuerySchema } from '../../api/requests/bffQuery'
import AppConfigFactorySingleton from '../../config/AppConfigFactory'
import { EntityPageType } from '../../constants'
import { QueryExtensions } from './types'

export const getBff = () => {
  const bff = () => {
    const val = AppConfigFactorySingleton.get().config?.bff
    return val ? Promise.resolve(val) : Promise.reject()
  }
  return bff().catch(() => bootApp().then(bff))
}

export const getHeaders = () => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
})

export const getExtensions = (hash?: string): QueryExtensions => ({
  persistedQuery: {
    version: 1,
    sha256Hash: hash,
  },
})

export const getQueryName = (query: QueryName, variables: Record<string, any>) => {
  const cacheableQueries: [name: QueryName, type: EntityPageType][] = [
    [QueryName.featuredSection, EntityPageType.OlympicsPage],
  ]
  const shouldUseCached = !!cacheableQueries.find(([q, t]) => query === q && variables.type === t)
  return `${query}${shouldUseCached ? '_cached' : ''}`
}

export const makeGetUrl = (
  uri: string,
  variables: Record<string, any>,
  extensions: Record<string, any>
) =>
  `${uri}?variables=${encodeURIComponent(JSON.stringify(variables))}&extensions=${JSON.stringify(
    extensions
  )}`

export const persistedQueryUnavailable = (json: any) =>
  !!json.errors?.find(({ message }: Error) => message === 'PersistedQueryNotFound')

export const postFallback = async (
  uri: string,
  headers: Record<string, any>,
  query: any,
  variables: Record<string, any>,
  extensions: Record<string, any>
) =>
  await (await fetch(uri, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query: getQuerySchema(query),
      variables,
      extensions,
    }),
  })).json()
