import { Language, Lightning, Registry } from '@lightningjs/sdk'
import TempPassCTA from '../../../components/player/PlayerControls/TempPassCTA'
import { TempPassSingleton } from '../../../lib/TempPass'
import AuthenticationSingleton, {
  AuthenticationEvents,
} from '../../../authentication/Authentication'
import { activateTempPassRedirect } from '../StreamLoader/actions/redirect'
import BasePlayer, { TEMP_PASS_REDIRECT } from '../BasePlayer'
import { StreamAuthorizationType } from '../StreamLoader/request'
import LaunchDarklySingleton from '../../../lib/launchDarkly/LaunchDarkly'
import LaunchDarklyFeatureFlags from '../../../lib/launchDarkly/LaunchDarklyFeatureFlags'
import { SCREEN_SIZE, COLORS } from '../../../constants'
import { checkSetMvpdLogo, getRemainingAnnounce, isNBCProfileLinked } from '../../../helpers'
import LinearGradientShader from '../../../shaders/LinearGradientShader'
import {
  ModalManagerEvent,
  ModalTypes,
  ModalManagerEventTypes,
  CloseReason,
} from '../../../lib/ModalManager'
import Announcer from '../../../lib/tts/Announcer'
import { Subscription } from 'rxjs'
import { ACTIVATION_TYPE } from '../../../widgets/Modals/activation/constants'
import TVPlatform from '../../../lib/tv-platform'

const MVPD_SUCCESS_TIMELIMIT = 5 * 60 * 1000 // 5 min limit
export const WithTempPass = <T extends Lightning.Component.Constructor<BasePlayer>>(component: T) =>
  class extends component {
    _tempPassPlayerOverlay: any
    _tempPassPlayerOverlayBg: any
    _tempPassSubscription: any
    initialTempPassName: string
    identityRequiredForSecondary: boolean

    override _construct() {
      super._construct()
      const { initialTempPassName = '', identityRequiredForSecondary = false } =
        LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.tempPass) || {}

      this.initialTempPassName = initialTempPassName
      this.identityRequiredForSecondary = identityRequiredForSecondary
    }

    override async _detach() {
      super._detach()
      if (this._tempPassSubscription) {
        this._tempPassSubscription.unsubscribe()
        TempPassSingleton.endSession()
      }
      if (this?._authnTimeout) Registry.clearTimeout(this._authnTimeout)
    }

    override _handleBack(e: any) {
      this.resolveTempPass().then(() => {
        super._handleBack(e)
      })
    }

    override _closeMediaPlayer(forceExit = false) {
      this.resolveTempPass().then(() => {
        super._closeMediaPlayer(forceExit)
      })
    }

    async resolveTempPass() {
      // If we have a temp pass overlay, logout
      return AuthenticationSingleton.isMvpdTempPass()
        ? await AuthenticationSingleton.logout()
        : Promise.resolve({})
    }

    override async _startStream() {
      if (this.authtype === StreamAuthorizationType.TEMP_PASS) {
        this._startTempPass()
      }
      await super._startStream()
    }

    _startTempPass = () => {
      const { initialTempPassName } = LaunchDarklySingleton.getFeatureFlag(
        LaunchDarklyFeatureFlags.tempPass
      )
      this._analyticsDelegate?.updateUserDefaultAttributes(initialTempPassName)
      this._patchTempPassTemplate()
      TempPassSingleton.initSession(AuthenticationSingleton.getMvpdTokenExpiration())
      this._announceTempPass(TempPassSingleton.getDelta(), true)
      this._tempPassPlayerOverlay.update(TempPassSingleton.getDelta())
      this._tempPassSubscription = TempPassSingleton.subscribe(this._onTempPassTick.bind(this))
    }

    _patchTempPassTemplate() {
      if (TVPlatform.getAllowLinearGradient()) {
        this.patch({
          TempPassBg: {
            w: SCREEN_SIZE.width,
            h: 300,
            rect: true,
            shader: {
              type: LinearGradientShader,
              colors: [COLORS.black2, COLORS.transparent],
              stops: [1.0, 0.0],
              angle: 0,
            },
          },
        })
      }

      this.patch({
        TempPass: {
          type: TempPassCTA,
        },
      })

      this._tempPassPlayerOverlay = this.tag('TempPass')
      this._tempPassPlayerOverlayBg = this.tag('TempPassBg')

      // @ts-expect-error - still need a better way to handle the separate controls for sle and fer
      this._controls.patchTempPassTemplate()
    }

    _onTempPassTick = (secondsLeft: number) => {
      this._tempPassPlayerOverlay.update(secondsLeft)
      // @ts-expect-error - still need a better way to handle the separate controls for sle and fer
      this._controls.updateTempPassCountdown(secondsLeft)
      if (secondsLeft <= 0) {
        this._analyticsDelegate?.updateUserDefaultAttributes('Unauthenticated')
        this.resolveTempPass().then(() => {
          activateTempPassRedirect(
            (this.identityRequiredForSecondary && isNBCProfileLinked()) ||
              !this.identityRequiredForSecondary
              ? TEMP_PASS_REDIRECT.TEMP_PASS_CONCLUDED
              : TEMP_PASS_REDIRECT.TEMP_PASS_NBC_ACCOUNT
          )
        })
      }
      this._announceTempPass(secondsLeft)
    }

    _announceTempPass(secondsLeft: any, force = false) {
      // Announce TempPass remaining time on 5min/30sec or force when player loading
      if ([300, 30].includes(secondsLeft) || force) {
        Announcer.announce([
          Language.translate('tempPass-expires-in'),
          getRemainingAnnounce(secondsLeft),
        ])
      }
    }

    _hideTempPass(): void {
      this._tempPassPlayerOverlay?.patch({ alpha: 0 })
      this._tempPassPlayerOverlayBg?.patch({ alpha: 0 })
      this._controls.tag('TempPass')?.patch({ alpha: 0 })
    }

    _authnSubscription: Subscription
    _authnTimeout?: any
    _checkMvpdSuccess() {
      this._authnSubscription?.unsubscribe()
      this._authnSubscription = AuthenticationEvents.subscribe((type: ACTIVATION_TYPE) => {
        if (type === ACTIVATION_TYPE.MVPD && this) {
          this._tempPassSubscription?.unsubscribe()
          this._hideTempPass()
          // Update the mvpd logo inside the control component when mvpd is connected
          checkSetMvpdLogo(this._controls)
          this._authnSubscription.unsubscribe()
          this.resolveTempPass()
          Registry.clearTimeout(this._authnTimeout)
        } else {
          this._checkMvpdSuccess()
        }
      })
      AuthenticationEvents.pollAdobe()
    }

    _modalOpen() {
      this._tempPassSubscription?.unsubscribe()
      this._player?.clearPreviousSession()
      this._endMediaSession()
      this._checkMvpdSuccess()
      this._authnTimeout = Registry.setTimeout(() => {
        this?._authnSubscription.unsubscribe()
      }, MVPD_SUCCESS_TIMELIMIT)
    }

    _modalClose(e: ModalManagerEvent) {
      if (e.payload === CloseReason.DEFAULT && e.type === ModalTypes.TEMPPASS_CONCLUDED) {
        this._handleBack({})
      } else if (
        e.payload === CloseReason.DEFAULT ||
        (e.payload === CloseReason.CANCELLED && this._controls?.tempPassCtaClicked)
      ) {
        if (this._params?.preauth) this._params.preauth = false
        this._controls.tag('TempPass')?.patch({ alpha: 0 })
        this._load()
        if (this._controls?.tempPassCtaClicked) this._controls.tempPassCtaClicked = false
      }
    }
    override _onModalEvent(e: ModalManagerEvent) {
      if (
        [
          ModalTypes.ACTIVATION,
          ModalTypes.ACTIVATION_TEMPPASS_MVPD,
          ModalTypes.ACTIVATION_TEMPPASS_NBC,
          ModalTypes.ACTIVATION_TEMPPASS_NBC,
          ModalTypes.TEMPPASS_CONCLUDED,
        ].includes(e.type)
      ) {
        if (e.event === ModalManagerEventTypes.OPEN) {
          this._modalOpen()
        } else if (e.event === ModalManagerEventTypes.CLOSE) {
          this._modalClose(e)
        }
      }
      super._onModalEvent(e)
    }
  }
