import { Lightning, Registry } from '@lightningjs/sdk'
import { Subject, Subscription } from 'rxjs'
import { PlaybackType, StreamingProtocol } from '@sky-uk-ott/core-video-sdk-js-core'

import { PLAYER_TYPE, PlayerFactorySingleton } from '../player/core/PlayerFactory'
import { PlayerStatusEvent } from '../player/model/event/PlayerStatusEvent'
import { PlayerStatus } from '../player/model/PlayerStatus'
import { TimeChangeEvent } from '../player/model/event/TimeChangeEvent'
import { PlatcoPlayerEvents } from '../player/model/event'
import { PlayerData } from '../player/CoreVideoPlayer/getCoreVideoSdkConfig'
import { PROGRAMMING_TYPES, PLAYER_SIZE } from '../constants'

import LaunchDarklySingleton from '../lib/launchDarkly/LaunchDarkly'
import LaunchDarklyFeatureFlags from '../lib/launchDarkly/LaunchDarklyFeatureFlags'
import { PlayerOptions } from '../player/core/PlayerInterface'
import TVPlatform from './tv-platform'

export type SecondaryPlayerLoadObject = {
  playbackUrl: string
  type: PLAYER_TYPE.BACKGROUND | PLAYER_TYPE.PREVIEW
}

enum PlayerDOMId {
  preview = 'preview-player',
  background = 'background-player',
}

const SecondaryPlayerBoundaries = {
  x: TVPlatform.scaleVideoProperty(80, PLAYER_SIZE.SECONDARY),
  y: TVPlatform.scaleVideoProperty(190, PLAYER_SIZE.SECONDARY),
  w: TVPlatform.scaleVideoProperty(960, PLAYER_SIZE.SECONDARY),
  h: TVPlatform.scaleVideoProperty(580, PLAYER_SIZE.SECONDARY),
}

export const createXumoHoleShader = (activated: boolean) =>
  activated
    ? {
        type: Lightning.shaders.Hole,
        ...SecondaryPlayerBoundaries,
      }
    : null

export const isSecondaryPlayerLoadObject = (
  input: SecondaryPlayerLoadObject
): input is SecondaryPlayerLoadObject =>
  !!input?.playbackUrl &&
  !!input?.playbackUrl?.match?.(/http/) &&
  [PLAYER_TYPE.BACKGROUND, PLAYER_TYPE.PREVIEW].includes(input.type)

enum PlayerInstanceState {
  IDLE,
  ACTIVE,
}

type Player = {
  type: PLAYER_TYPE
  status: PlayerInstanceState
  duration?: number
  position: number
  subscription?: Subscription
}

class SecondaryPlayer {
  events = new Subject<{ activated: boolean; type: PLAYER_TYPE }>()
  _timeouts: NodeJS.Timeout[] = []
  _players: Partial<{ [key in PLAYER_TYPE]: Player }> = {}
  _loadDelay = 2000
  _destroyTime = 15000

  get isPreviewPlayerActive() {
    return !!this._players[PLAYER_TYPE.PREVIEW]
  }

  private _loadSource = (
    { playbackUrl, type }: SecondaryPlayerLoadObject,
    playerOptions: Partial<PlayerOptions>
  ): void => {
    const player = PlayerFactorySingleton.player(type, playerOptions)

    player?.load(this._createMockPlayerObject(playbackUrl))
    const subscription = player?.normalizedPlayerEvents
      .events()
      .subscribe(this._handlePlayerEvent(type))
    this._players[type] = {
      type,
      status: PlayerInstanceState.ACTIVE,
      duration: 0,
      position: 0,
      subscription,
    }
  }

  load({ playbackUrl, type }: SecondaryPlayerLoadObject): void {
    this._clearTimeout()
    const playerIndex = Object.values(this._players)
      .filter((player) => player.status === PlayerInstanceState.ACTIVE)
      .findIndex((player) => player.type === type)
    const delay = playerIndex > 0 ? playerIndex * this._loadDelay : 0
    this._players[type]?.subscription?.unsubscribe()
    const playerOptions =
      type === PLAYER_TYPE.PREVIEW
        ? {
            left: SecondaryPlayerBoundaries.x,
            top: SecondaryPlayerBoundaries.y,
            width: SecondaryPlayerBoundaries.w,
            height: SecondaryPlayerBoundaries.h,
            zIndex: 2,
            domId: PlayerDOMId.preview,
          }
        : { domId: PlayerDOMId.background }
    this._timeouts.push(
      Registry.setTimeout(() => this._loadSource({ playbackUrl, type }, playerOptions), delay)
    )
  }

  getPreviewVideoAnalytics() {
    const player = this._players[PLAYER_TYPE.PREVIEW]
    const position = Math.max(0, player?.position || 0)
    const duration = Math.max(0, player?.duration || position)
    const percentWatched = (position * 100) / duration
    return {
      duration,
      percentWatched: percentWatched > 100 ? 100 : percentWatched,
      position,
    }
  }

  private _handlePlayerEvent(type: PLAYER_TYPE) {
    const player = PlayerFactorySingleton.getPlayerIfActive(type)
    return (event: PlatcoPlayerEvents) => {
      if (event instanceof PlayerStatusEvent) {
        if (event.status === PlayerStatus.FINISHED) {
          player?.setVisibility(false)
          this.events.next({ activated: false, type })
        } else if (event.status === PlayerStatus.PLAYING) {
          if (player) {
            const isPreview = type === PLAYER_TYPE.PREVIEW
            if (isPreview) player.forceVideoSize?.()
            /**
             * https://nbcdigital.atlassian.net/browse/PL-2326?focusedCommentId=870999
             * LaunchDarklyFeatureFlags.marketingModuleAudio Flag is a boolean type
             * When the flag value returns true, enable audio on the Marketing Module foreground video (video preview)
             * When the flag value returns false, disable audio on the Marketing Module foreground video (video preview)
             */
            if (
              !isPreview ||
              !LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.marketingModuleAudio)
            ) {
              player.setMute(true)
            }
            player.setVisibility(true)
          }
          this.events.next({ activated: true, type })
        }
      } else if (event instanceof TimeChangeEvent && this._players[type]) {
        const target = this._players[type] as Player
        target.position = event.time
        // seekableRange refers to the available seekable time in the manifest.
        // Being optimistic it should equal to duration in short videos,
        // since we don't receive that data from the backend.
        target.duration = event.seekableRange?.end
      }
    }
  }

  private _createMockPlayerObject(playbackUrl: string): PlayerData {
    const now = Date.now().toString()
    return {
      stream: {
        duration: 0,
        externalAdvertiserId: '123456',
        programmingType: PROGRAMMING_TYPES.CLIP,
        v4ID: '',
        channelId: 'nbc',
        title: '',
        permalink: '',
        secondaryTitle: '',
        pid: 0,
        airDate: now,
        endTime: now,
      },
      program: {
        callSign: '',
        duration: 0,
        airDate: now,
        locked: false,
        league: '',
        sport: '',
        clipCategory: '',
        language: '',
        adobeVideoResearchTitle: '',
        dayPart: '',
        title: '',
        programmingType: PROGRAMMING_TYPES.CLIP,
        videoType: PlaybackType.Preview,
      },
      lemonade: {
        type: playbackUrl.includes('m3u') ? StreamingProtocol.HLS : StreamingProtocol.DASH,
        playbackUrl,
        playbackUrls: [],
      },
    }
  }

  private _clearSubscription(type: PLAYER_TYPE) {
    if (this._players[type]) {
      const target = this._players[type] as Player
      if (target.subscription) {
        target.subscription.unsubscribe()
        target.subscription = undefined
      }
    }
  }

  unload() {
    for (const key in this._players) {
      const type = key as PLAYER_TYPE
      const target = this._players[type] as Player
      this._clearSubscription(type)
      if (target.status === PlayerInstanceState.ACTIVE) {
        this.events.next({ activated: false, type })
        const player = PlayerFactorySingleton.player(type)
        player?.pause()
        player?.setVisibility(false)
        target.status = PlayerInstanceState.IDLE
      }
    }
    this._setDestroyTimeout()
  }

  destroy = (): void => {
    this._clearTimeout()
    for (const key in this._players) {
      const type = key as PLAYER_TYPE
      if (this._players[type]?.status === PlayerInstanceState.ACTIVE) {
        this.events.next({ activated: false, type })
      }
      this._clearSubscription(type)
      PlayerFactorySingleton.clearPlayer(type)
    }
    this._players = {}
  }

  subscribe(cb: (event: { activated: boolean; type: PLAYER_TYPE }) => void) {
    return this.events.subscribe(cb as (value: unknown) => void)
  }

  _setDestroyTimeout(): void {
    this._clearTimeout()
    this._timeouts.push(Registry.setTimeout(this.destroy, this._destroyTime))
  }

  _clearTimeout(): void {
    if (!this._timeouts.length) return
    this._timeouts.forEach((timeout, index) => {
      Registry.clearTimeout(timeout)
      this._timeouts.splice(index)
    })
  }
}

export const SecondaryPlayerSingleton = new SecondaryPlayer()
