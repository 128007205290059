import { Registry } from '@lightningjs/sdk'
import { Subscription } from 'rxjs'

import BaseCollection from './BaseCollection'
import ItemWrapper from './items/ItemWrapper'
import Bullet from './Bullet'

import { sendMetric } from '../lib/analytics/Analytics'
import { EVENTS } from '../lib/analytics/types'
import Announcer, { AnnouncerEvent } from '../lib/tts/Announcer'
import { SubscriptionBuilder, SubscriptionSources } from '../util/SubscriptionBuilder'

import { getAnalyticsObjectByComponent } from '../helpers'
import { COMPONENT_TYPES } from '../constants'

const CAROUSEL_TIMEOUT = 5000

// @ts-expect-error TS(2417): Class static side 'typeof Carousel' incorrectly ex... Remove this comment to see the full error message
export default class Carousel extends BaseCollection {
  _impressions: any
  _nextSlideTimeout: any
  _stayVisible: any
  _totalSlides: any
  _ttsSubscription?: Subscription
  static override _template() {
    return {
      Items: {
        forceZIndexContext: true,
        boundsMargin: [500, 100, 500, 100],
      },
      Bullets: {
        y: 759,
        x: 83,
      },
      transitions: {
        alpha: { duration: 0.15, timingFunction: 'cubic-bezier(0.20, 1.00, 0.80, 1.00)' },
      },
    }
  }

  override _init() {
    this._index = 0
    this._stayVisible = false
    // Basically same as _index however can't/shouldn't be reset.
    this._impressions = 0
  }

  override _active(): void {
    super._active()
    this.fireAncestors('$hideMenuBackground')
  }

  override _inactive() {
    this._clearAutoPlay()
    this.fireAncestors('$showMenuBackground')
  }

  override set items(v: any) {
    this._items = v
    this._totalSlides = this._items.length
    this.tag('Items').patch({
      children: this.create({ items: this._items }),
    })
    // Make sure the first item gets sent.
    // Takes a moment for analytics to become avail.
    // Wait 50ms for this to happen.
    const analyticsTimeout = Registry.setTimeout(() => {
      this.totalSlides && this._fireImpressionMetric(this._items[0])
      Registry.clearTimeout(analyticsTimeout)
    }, 50)

    this._createBullets(this._items.length)
  }

  get totalSlides() {
    return this._totalSlides
  }

  override create({ items }: any) {
    return items.map((item: any, index: any) => {
      return {
        type: ItemWrapper,
        itemType: item.itemType,
        item: item.item,
        alpha: index === 0 ? 1 : 0,
        index,
      }
    })
  }

  _createBullets(amount: any) {
    new Array(amount).fill({}).forEach((bullet, index) => {
      this._addBullet(index)
    })
  }

  _addBullet(index: any) {
    const el = this.stage.c({
      type: Bullet,
      x: index * 30,
      scale: index === 0 ? 1 : 0.66,
      alpha: index === 0 ? 1 : 0.4,
    })

    this.tag('Bullets').childList.add(el)
  }

  override _handleUp() {
    this._stayVisible = true
    return false
  }

  override _handleLeft() {
    if (this._index > 0) {
      this.setIndex(-1)
    }
  }

  override _handleRight() {
    this._nextSlide()
  }

  override _handleBack() {
    this._stayVisible = true
    this._clearAutoPlay()
    return false
  }

  setIndex(direction: any, reset?: any) {
    this._clearAutoPlay()
    this._selectSlide(false)
    this._index = reset ? 0 : (this._index += direction)
    this._selectSlide(true)
    this.totalSlides && this._fireImpressionMetric(this._items[this._index])
    if (!Announcer.enabled) this._setAutoPlay()
  }

  setSlideByIndex(index: any) {
    this._selectSlide(false)
    this._index = index >= this._totalSlides || index < 0 ? 0 : index
    this._selectSlide(true)
    this.totalSlides && this._fireImpressionMetric(this._items[this._index])
    if (!Announcer.enabled) this._setAutoPlay()
  }

  _setAutoPlay = (event: any = undefined) => {
    if (!event || event.type === AnnouncerEvent.TTS_END) {
      this._clearAutoPlay()
      this._nextSlideTimeout = Registry.setTimeout(() => {
        this._nextSlideTimeout = null
        this._nextSlide()
      }, CAROUSEL_TIMEOUT)
    }
  }

  _clearAutoPlay() {
    if (this._nextSlideTimeout) Registry.clearTimeout(this._nextSlideTimeout)
    this._nextSlideTimeout = null
  }

  _nextSlide = () => {
    if (this._index < this.tag('Items').children.length - 1) {
      this.setIndex(1)
    } else {
      this.setIndex(0, true)
    }
  }

  _selectSlide(v: any) {
    if (this.tag('Bullets').children[this._index]) {
      this.tag('Bullets').children[this._index].setFocus(v)
      this.tag('Items').children[this._index].setSmooth('alpha', v ? 1 : 0)
    }
  }

  override _focus() {
    if (this._stayVisible) {
      this.tag('Items')?.children[this._index]?.child?.setInactive(true)
      this._stayVisible = false
    }

    this.patch({
      smooth: { alpha: 1 },
    })

    this._clearAutoPlay()
    if (Announcer.enabled) {
      this._ttsSubscription = new SubscriptionBuilder()
        .with({
          type: SubscriptionSources.TTS_ANNOUNCER,
          events: [AnnouncerEvent.TTS_END],
          handler: this._setAutoPlay.bind(this),
        })
        .subscribe()
    } else {
      this._setAutoPlay()
    }
  }

  override _unfocus() {
    if (!this._stayVisible) {
      this.patch({
        smooth: { alpha: 0 },
      })
    } else {
      this.tag('Items')?.children[this._index]?.child?.setInactive(false)
    }

    this._ttsSubscription?.unsubscribe()
    if (this.attached) this._setAutoPlay()
  }

  $firstItemCreated() {
    this._refocus()
  }

  _fireImpressionMetric(slide: any) {
    this._impressions++

    const params = {
      entity: {
        notificationTitle: slide?.item?.obj?.data?.cta?.data?.title,
        analytics: getAnalyticsObjectByComponent(slide?.item),
        dynamicSmartLeadLogic: slide?.item?.dynamicSmartLeadLogic,
      },
      shelf: {
        machineName: slide?.item?.shelf?.machineName,
        tileIndex: slide?.item?.shelf?.tileIndex + 1,
        shelfIndex: 1,
        listTitle: 'Dynamic Lead',
        isUpcoming: slide?.item?.isUpcoming,
        isLive: slide?.item?.isLive,
      },
      tileParams:
        slide?.item?.component === COMPONENT_TYPES.UPCOMING_LIVE_SLIDE
          ? {
              isUpcoming: slide?.item?.isUpcoming,
              isLive: slide?.item?.isLive,
            }
          : {},
    }

    sendMetric(EVENTS.DYNAMIC_LEAD_IMPRESSION, params)
    // For the first item fire shelf impression metric as if it were the first shelf.
    if (this._impressions === 1) {
      const shelfParams = {
        'Content Position': 1,
        'Custom Shelf Position': 1, // DL is always first item on the page
        'Custom Shelf Title': slide?.item?.shelf?.machineName,
        Sponsor: slide?.item?.obj?.analytics?.sponsorName,
      }
      sendMetric(EVENTS.SHELF_IMPRESSION, shelfParams)
      // Send msg so the 2nd shelf (first tray after dl) knows to fire.
      this.fireAncestors('$firstShelfImpression')
    }
  }
}
