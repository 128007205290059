import whiteLabelBrand from '../whiteLabelBrand'
import { BRAND, CALL_SIGNS, WHITE_LABEL_BRANDS } from '../constants'
import AppConfigFactorySingleton from '../config/AppConfigFactory'
import { CHANNEL_IDS, telemundoAffiliateName } from '../api/Live'

export const getBrandName = (): BRAND => window.WHITE_LABEL_BRAND || (whiteLabelBrand as BRAND)

export const getConfigName = () => WHITE_LABEL_BRANDS[getBrandName()]?.configName || getBrandName()

export const getDefaultChannelId = () => {
  const brandName = getBrandName() as string
  if (brandName)
    return (
      CHANNEL_IDS[brandName as keyof typeof CHANNEL_IDS] ||
      WHITE_LABEL_BRANDS[brandName as BRAND]?.configName ||
      brandName
    )

  return getConfigName()
}

export const getDefaultCallSign = () => {
  const brand = getBrandName()
  if (brand === BRAND.telemundo && telemundoAffiliateName()) return telemundoAffiliateName()
  return WHITE_LABEL_BRANDS[brand]?.liveCallSign || CALL_SIGNS.nbcnews
}

export const getAppId = () =>
  (window as any).APP_ID || AppConfigFactorySingleton.config?.identity?.app_id || ''
