import { Registry } from '@lightningjs/sdk'
import { switchState, setSmooth } from '../../../helpers'
import { PlayerStates } from '../../../constants'

export const PlayerControlsStateFactory = (base: any) =>
  class Controls extends base {
    $enter(_: any, args: any) {
      this._switchStateWithDelay(args?.timeoutLength)
      setSmooth(this._controls, 'alpha', 1)
      setSmooth(this._captions, 'y', 700)
      if (this._skipButton) setSmooth(this._skipButton, 'y', -200)
    }

    $exit() {
      this._clearTimeOut()
      if (this._skipButton && this._skipButton.alpha > 0) return
      setSmooth(this._controls, 'alpha', 0)
      setSmooth(this._captions, 'y', 855)
      if (this._skipButton) setSmooth(this._skipButton, 'y', 0)
    }

    $toggleQOSPanel() {
      setSmooth(this._qosPanel, 'alpha', !this._qosPanel.alpha)
    }

    $showMediaOptionsMenu() {
      this._mediaOptionsPanel?._createLanguageList()
      this._setState(PlayerStates.LPPlayer_MediaOptions)
      this._analyticsDelegate?.fireClick('Closed Captions')
    }

    $startSeeking() {
      this._clearTimeOut()
      this._setState(PlayerStates.LPPlayer_Controls_Seeking)
    }

    $endSeeking() {
      this._setState(PlayerStates.LPPlayer_Controls)
      this._switchStateWithDelay(5000, true)
    }

    _switchToPlayerState() {
      this._clearTimeOut()
      switchState(this, [
        { state: PlayerStates.LPPlayer_LiveToVod, if: () => this._showLiveToVodFixed?.() },
        { state: PlayerStates.LPPlayer_SkipButton, if: () => this._showSkipButton?.() },
        {
          state: PlayerStates.LPPlayer_TempPass,
          if: () =>
            this._tempPassPlayerOverlay?.remaining && this._tempPassPlayerOverlay.remaining <= 30,
        },
        { state: PlayerStates.LPPlayer, if: () => true },
      ])
    }

    _switchStateWithDelay(timeoutLength = 5000, bypassPauseAds = false) {
      this._clearTimeOut()
      this._timeout = Registry.setTimeout(() => {
        !bypassPauseAds && this._controls.shouldShowPauseAds?.()
          ? this._controls.setShowPauseAds?.()
          : this._switchToPlayerState()
      }, timeoutLength)
    }

    _clearTimeOut() {
      if (this._timeout) {
        Registry.clearTimeout(this._timeout)
        this._timeout = null
      }
    }

    _captureBack() {
      this._controls?._captureBack?.()
      this._switchToPlayerState()
      return true
    }

    _captureKey() {
      this._switchStateWithDelay()
      return false
    }

    _getFocused() {
      return this._controls || this
    }

    static _states() {
      return [
        class Seeking extends this {
          override _captureKey() {
            return false
          }
        },
      ]
    }
  }
