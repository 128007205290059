import LongScrollPage from './LongScrollPage'
import { EpgGuideV2 } from '../components'
import { setSmooth } from '../helpers'
import { PlayerStoreEvents } from '../store/PlayerStore/PlayerStore'
import { EPG_SLOT_WIDTH, EPG_SLOT_PADDING } from '../constants'
import { Subscription } from 'rxjs'
import { SubscriptionBuilder, SubscriptionSources } from '../util/SubscriptionBuilder'

export default class EpisodeGuide extends LongScrollPage {
  _epg: any
  _log_tag: any
  _subscription?: Subscription
  static override _template() {
    return {
      Epg: {
        type: EpgGuideV2,
        programImageWidth: 380,
        itemHeight: 190,
        itemWidth: EPG_SLOT_WIDTH,
        itemPadding: EPG_SLOT_PADDING,
        visible: false,
      },
    }
  }

  override _init() {
    this._epg = this.tag('Epg')
    this._epg.visible = true
    this._epg.load()
  }

  override _attach() {
    this._subscription = new SubscriptionBuilder()
      .with(SubscriptionSources.PLAYER_STORE)
      .subscribe(this._onStoreEvent)
    this._fetchLiveGuide()
  }

  override _detach() {
    this._subscription?.unsubscribe()
  }

  private _fetchLiveGuide = (): void => {
    this._epg.fetchData()
  }

  override _inactive() {
    this._subscription?.unsubscribe()
    this._subscription = undefined
  }

  _onStoreEvent = (event: any) => {
    const { type } = event
    switch (type) {
      case PlayerStoreEvents.EPG_OK:
        setSmooth(this.widgets.loader, 'visible', 0)
        break
      default:
        break
    }
  }

  override _handleDown() {
    this._epg._handleDown()
  }

  override _handleUp() {
    this._epg._handleUp()
  }

  override _handleRight() {
    this._epg._handleRight()
  }

  override _handleLeft() {
    this._epg._handleLeft()
  }

  override _handleEnter() {
    this._epg._handleEnter()
  }

  static override _states() {
    return [
      class Epg extends this {
        override _getFocused() {
          return this._epg || this
        }

        override _handleDown() {
          this._epg._handleDown()
        }
      },
    ]
  }
}
