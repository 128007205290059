import { Storage, Settings } from '@lightningjs/sdk'

export default class Preferences {
  static store(property: any, value: any) {
    Storage.set(Preferences.prefix + property, value)
  }

  static get(property: any) {
    return Storage.get(Preferences.prefix + property)
  }

  static remove(property: any) {
    Storage.remove(Preferences.prefix + property)
  }

  static get prefix() {
    return Settings.get('platform', 'config.appName', 'nbc').toUpperCase() + '.'
  }

  static ACCESS_TOKEN = 'accessToken'
  static DEVICE_ID = 'deviceId'
  static CLOSED_CAPTION = 'closedCaptions'
  static CLOSED_CAPTION_FONT_STYLE = 'CCFontStyle'
  static CLOSED_CAPTION_FONT_OPACITY = 'CCFontOpacity'
  static CLOSED_CAPTION_FONT_SIZE = 'CCFontSize'
  static CLOSED_CAPTION_FONT_COLOR = 'CCFontColor'
  static CLOSED_CAPTION_BACKGROUND_OPACITY = 'CCBackgroundOpacity'
  static CLOSED_CAPTION_BACKGROUND_COLOR = 'CCBackgroundColor'
  static CLOSED_CAPTION_WINDOW_OPACITY = 'CCWindowOpacity'
  static CLOSED_CAPTION_WINDOW_COLOR = 'CCWindowColor'
  static CLOSED_CAPTION_EDGE_OPACITY = 'CCEdgeOpacity'
  static CLOSED_CAPTION_EDGE_STYLE = 'CCEdgeStyle'
  static CLOSED_CAPTION_EDGE_COLOR = 'CCEdgeColor'
}
