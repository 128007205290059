import { IPlayerEvent } from './IPlayerEvent'
export class ErrorEvent extends IPlayerEvent {
  _error

  constructor(error: any) {
    super()
    this._error = error
  }

  get error() {
    return this._error
  }
}
