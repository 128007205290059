import { Language, Registry } from '@lightningjs/sdk'
import BasePlayer from '../BasePlayer'
import { PlayerStates } from '../../../constants'

export const PlayerInactiveStateFactory = (base: any) =>
  class PlayerInActive extends base {
    _updateTranslations() {
      this.tag('InActive').patch({
        InActiveMsg: {
          text: {
            text: Language.translate('still_watching_live_stream'),
          },
        },
        YesBtn: {
          label: Language.translate('yes').toUpperCase(),
        },
        NoBtn: {
          label: Language.translate('no').toUpperCase(),
        },
      })
    }

    $enter() {
      this._updateTranslations()
      this._inActive.alpha = 1
      // After a minute of inactivity, assume close out.
      this.closeTimer()
    }

    $exit() {
      this._inActive.alpha = 0
    }

    closeTimer() {
      this._closeModalTimeout = Registry.setTimeout(() => {
        this._closeMediaPlayer(true)
      }, BasePlayer.INACTIVE_MODAL_CLOSE_TIME_OUT)
    }

    clearTimeout() {
      Registry.clearTimeout(this._closeModalTimeout)
    }

    static _states() {
      return [
        class Yes extends PlayerInActive {
          _getFocused() {
            return this._yesBtn || this
          }

          _handleEnter() {
            this.clearTimeout()
            this._setState(PlayerStates.LPPlayer)
          }

          _handleDown() {
            this._setState(PlayerStates.PlayerInActive_No)
          }
        },
        class No extends PlayerInActive {
          _getFocused() {
            return this._noBtn || this
          }

          _handleEnter() {
            this.clearTimeout()
            this._closeMediaPlayer(true)
          }

          _handleUp() {
            this._setState(PlayerStates.PlayerInActive_Yes)
          }
        },
      ]
    }
  }
