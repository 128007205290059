export enum AppLaunchTypes {
  default = 'Launch',
  background = 'Open',
  deepLink = 'Deep Link',
}

export enum EVENTS {
  AD_BREAK_START = 'trackAdBreakStart',
  AD_BREAK_END = 'trackAdBreakEnd',
  AD_START = 'trackAdStart',
  AD_END = 'trackAdEnd',
  LIVE_SESSION_START = 'trackLiveSessionStart',
  LIVE_SESSION_END = 'trackLiveSessionEnd',
  VOD_SESSION_START = 'trackVodSessionStart',
  VOD_SESSION_END = 'trackVodSessionEnd',
  PAGE_LOAD = 'trackPageLoad',
  ERROR = 'trackError',
  CONTENT_CLICK = 'trackContentClick',
  AUTH_SUCCESS_MVPD = 'trackMvpdAuthSuccess',
  AUTH_SUCCESS_NBC = 'trackNbcAuthSuccess',
  MVPD_PAGE_ABANDONED = 'trackMvpdPageAbandoned',
  IDM_CONVERSION = 'trackIDMConversion',
  NBC_PROFILE_SIGN_OUT = 'trackNbcProfileSignOut',
  CLICK = 'trackClick',
  SEARCH_RESULT = 'trackSearchResult',
  SHELF_IMPRESSION = 'trackShelfImpression',
  SHELVES_LOAD = 'trackShelvesLoad',
  DYNAMIC_LEAD_IMPRESSION = 'trackDynamicLeadImpression',
  MODAL_LOAD = 'trackModalLoad',
  END_CARD_IMPRESSION = 'trackEndCardImpression',
  END_CARD = 'trackEndCard',
  LIVE_TO_VOD_IMPRESSION = 'trackLiveToVodImpression',
  EXIT = 'trackExit',

  // Mparticle
  LAUNCH = 'trackLaunch',
  PLAYER_LOAD_TIME = 'trackPlayerLoadTime',
  LIVE_PLAYER_LOAD = 'trackLivePlayerLoad',
  PROGRAM_START = 'trackProgramStart',
  PROGRAM_END = 'trackProgramEnd',

  // Singleton Class
  PLAYER_READY = 'trackPlayerReady',
  AUTHZ_START = 'trackAuthZStart',
  AUTHZ_COMPLETE = 'trackAuthZComplete',
  ON_BUFFER_START = 'trackBufferStart',
  ON_BUFFER_COMPLETE = 'trackBufferComplete',
  SEEK_START = 'trackSeekStart',
  SEEK_END = 'trackSeekEnd',
  LIVE_SESSION_INITIALIZED = 'trackLiveSessionInitialized',
  LIVE_PROGRAM_CHANGE = 'trackLiveProgramChange',
  MARKETING_MODULE_IMPRESSION = 'trackMarketingModuleImpression',
  MARKETING_MODULE_VIDEO_PREVIEW = 'trackMarketingModuleVideoPreview',
}

// All events minus mParticle class events
export type AnalyticsSingletonEvents = Exclude<
  EVENTS,
  | EVENTS.LAUNCH
  | EVENTS.PLAYER_LOAD_TIME
  | EVENTS.LIVE_PLAYER_LOAD
  | EVENTS.PROGRAM_START
  | EVENTS.PROGRAM_END
>

// All events minus Analytics Singleton class events
export type MparticleEvents = Exclude<
  EVENTS,
  | EVENTS.PLAYER_READY
  | EVENTS.AUTHZ_START
  | EVENTS.AUTHZ_COMPLETE
  | EVENTS.ON_BUFFER_START
  | EVENTS.ON_BUFFER_COMPLETE
  | EVENTS.SEEK_START
  | EVENTS.SEEK_END
  | EVENTS.LIVE_SESSION_INITIALIZED
  | EVENTS.LIVE_PROGRAM_CHANGE
>
