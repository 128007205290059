import { Lightning, Log } from '@lightningjs/sdk'
import { throttle } from 'lodash'

import { setSmooth } from '../../helpers/template'
import {
  CCEdgeStyles,
  CCFontSizes,
  CCFonts,
  ClosedCaptionsUtils,
} from '../../lib/ClosedCaptions/ClosedCaptionsUtils'
import Preferences from '../../lib/Preferences'
import { FLEX_DIRECTION, FONT_FACE } from '../../constants'
import { CCStyleEvent } from '../../lib/tv-platform/base'
import { PlayerInterface } from '../../player/core/PlayerInterface'

const PLAYER_CAPTIONS_TAG = 'PlayerCaptions'

export default class PlayerCaptions extends Lightning.Component {
  static CAPTION_ITEM_MARGIN_LEFT = 14
  static CAPTION_ITEM_MARGIN_RIGHT = 48
  _container: any
  windowColor: number
  windowWidth: number
  windowHeight: number

  static override _template() {
    return {
      Window: {
        x: -25,
        y: -25,
        rect: true,
        color: this.bindProp('windowColor'),
        w: this.bindProp('windowWidth'),
        h: this.bindProp('windowHeight'),
      },
      CaptionsContainer: {
        flex: { direction: FLEX_DIRECTION.column, wrap: false },
      },
    }
  }

  _reset() {
    this._container.childList.clear()
  }

  override _init() {
    this._container = this.tag('CaptionsContainer')
    this.updateUI()
  }

  syncCCSettings(deviceSettings?: CCStyleEvent) {
    if (deviceSettings)
      Object.entries(deviceSettings).forEach(([key, value]) => Preferences.store(key, value))
    this.updateUI(deviceSettings)
  }

  get ccStyle(): CCStyleEvent {
    return {
      fontStyle: ClosedCaptionsUtils.getValue(Preferences.CLOSED_CAPTION_FONT_STYLE),
      fontOpacity: ClosedCaptionsUtils.getValue(Preferences.CLOSED_CAPTION_FONT_OPACITY),
      fontSize: ClosedCaptionsUtils.getValue(Preferences.CLOSED_CAPTION_FONT_SIZE),
      fontColor: ClosedCaptionsUtils.getValue(Preferences.CLOSED_CAPTION_FONT_COLOR),
      backgroundOpacity: ClosedCaptionsUtils.getValue(
        Preferences.CLOSED_CAPTION_BACKGROUND_OPACITY
      ),
      backgroundColor: ClosedCaptionsUtils.getValue(Preferences.CLOSED_CAPTION_BACKGROUND_COLOR),
      edgeStyle: ClosedCaptionsUtils.getValue(Preferences.CLOSED_CAPTION_EDGE_STYLE),
      edgeColor: ClosedCaptionsUtils.getValue(Preferences.CLOSED_CAPTION_EDGE_COLOR),
      edgeOpacity: ClosedCaptionsUtils.getValue(Preferences.CLOSED_CAPTION_EDGE_OPACITY),
      windowColor: ClosedCaptionsUtils.getValue(Preferences.CLOSED_CAPTION_WINDOW_COLOR),
      windowOpacity: ClosedCaptionsUtils.getValue(Preferences.CLOSED_CAPTION_WINDOW_OPACITY),
    }
  }

  _setActiveCues(activeCues: any) {
    if (ClosedCaptionsUtils.getCCType() === 'off' || activeCues.length === 0) {
      setSmooth(this, 'alpha', 0)
    } else {
      setSmooth(this, 'alpha', 1)
      // Cues are returned in reverse order.
      this._container.children = [].map
        .call(activeCues, (activeCue: { text: string; endTime: any }) => {
          return {
            flexItem: {},
            type: CaptionItem,
            settings: this.ccStyle,
            captionText: activeCue.text,
            endTime: activeCue.endTime,
          }
        })
        .reverse()
    }
  }

  _ccLanguageUpdated(value: any) {
    ClosedCaptionsUtils.setCCType(value)
    setSmooth(this, 'alpha', value === 'off' ? 0 : 1)
  }

  _ccSettingUpdated(key: keyof CCStyleEvent, value: any) {
    if (ClosedCaptionsUtils.setValue(key, value)) this.updateUI()
  }

  updateUI(style: CCStyleEvent = this.ccStyle) {
    const _player = (this.parent as any)?._player
    const interfacePlayer = _player as PlayerInterface
    interfacePlayer?.setCCStyle?.(style)
    this._container.children.forEach((child: CaptionItem) => {
      if (child) child.settings = style
    })
    this.windowColor = ClosedCaptionsUtils.getColorHex(style.windowColor, style.windowOpacity)
  }

  _cleanUp = throttle(
    (time) => {
      this._container.children.forEach(
        (item: any) => item.endTime <= time && this._container.childList.remove(item)
      )
    },
    1000,
    { leading: false }
  )

  $onWidth(w: number) {
    this.windowWidth = w + 50
  }
  $onHeight(w: number) {
    this.windowHeight = w + 50
  }
}

class CaptionItem extends Lightning.Component {
  _endTime: number
  backgroundColor: number
  fontColor: number
  fontFace: CCFonts | FONT_FACE
  fontSize: CCFontSizes
  shadow: boolean
  shadowColor: number
  shadowOffsetX: number
  shadowOffsetY: number
  shadowBlur: number

  static override _template() {
    const onChange = (prop: string) => (ctx: any) => {
      Log.info(
        PLAYER_CAPTIONS_TAG,
        `Caption item color: ${ctx.color}, fontColor: ${ctx.fontColor}, fontFace: ${ctx.fontFace}, fontSize: ${ctx.fontSize}`
      )
      return ctx[prop]
    }
    return {
      flex: {},
      rect: true,
      color: this.bindProp('backgroundColor', onChange('backgroundColor')),
      CaptionsItem: {
        x: PlayerCaptions.CAPTION_ITEM_MARGIN_LEFT,
        text: {
          textColor: this.bindProp('fontColor', onChange('fontColor')),
          fontFace: this.bindProp('fontFace', onChange('fontFace')),
          fontSize: this.bindProp('fontSize', onChange('fontSize')),
          shadow: this.bindProp('shadow', onChange('shadow')),
          shadowBlur: this.bindProp('shadowBlur', onChange('shadowBlur')),
          shadowColor: this.bindProp('shadowColor', onChange('shadowColor')),
          shadowOffsetX: this.bindProp('shadowOffsetX', onChange('shadowOffsetX')),
          shadowOffsetY: this.bindProp('shadowOffsetY', onChange('shadowOffsetY')),
        },
      },
    }
  }

  override _init() {
    this.tag('CaptionsItem').on('txLoaded', () => {
      const { renderWidth, renderHeight } = this.tag('CaptionsItem')
      const w =
        renderWidth +
        PlayerCaptions.CAPTION_ITEM_MARGIN_LEFT +
        PlayerCaptions.CAPTION_ITEM_MARGIN_RIGHT
      this.w = w
      this.fireAncestors('$onWidth', w)
      this.fireAncestors('$onHeight', renderHeight)
    })
  }

  get endTime() {
    return this._endTime
  }

  set endTime(endTime) {
    this._endTime = endTime
  }

  set captionText(v: any) {
    this.tag('CaptionsItem').text.text = v
  }

  set settings({
    fontStyle,
    fontOpacity,
    fontSize,
    fontColor,
    backgroundOpacity,
    backgroundColor,
    edgeStyle,
    edgeColor,
    edgeOpacity,
  }: CCStyleEvent) {
    this.backgroundColor = ClosedCaptionsUtils.getColorHex(backgroundColor, backgroundOpacity)
    this.fontColor = ClosedCaptionsUtils.getColorHex(fontColor, fontOpacity)
    this.fontSize = fontSize
    this.fontFace = fontStyle === CCFonts.default ? FONT_FACE.courier : fontStyle
    this.shadow = edgeStyle !== CCEdgeStyles.none
    this.shadowColor = ClosedCaptionsUtils.getColorHex(edgeColor, edgeOpacity)
    const edgeStyles: Record<CCEdgeStyles, [number, number]> = {
      [CCEdgeStyles.none]: [0, 0],
      [CCEdgeStyles.depressed]: [0, -3],
      [CCEdgeStyles.raised]: [0, 3],
      [CCEdgeStyles.shadow]: [3, 3],
      [CCEdgeStyles.uniform]: [0, 0],
    }
    const currentEdgeStyle = edgeStyles[edgeStyle]
    this.shadowOffsetX = currentEdgeStyle[0]
    this.shadowOffsetY = currentEdgeStyle[1]
    this.shadowBlur = [CCEdgeStyles.shadow, CCEdgeStyles.uniform].includes(edgeStyle) ? 4 : 0
    Log.info(PLAYER_CAPTIONS_TAG, 'Settings set in caption item')
  }
}
