import { Language } from '@lightningjs/sdk'
import { ACTIVATION_TYPE } from '../constants'
import ModalManager, { CloseReason } from '../../../../lib/ModalManager'
import { BaseActivation } from '../Activation'

export const NOTIFICATION_TIMEOUT = 3000

export class ActivationStrategy {
  _ctx: BaseActivation
  _activationType: ACTIVATION_TYPE | undefined
  _timeout: NodeJS.Timeout | number | undefined

  constructor(ctx: BaseActivation) {
    this._ctx = ctx
  }

  drawText(): any[] {
    const title = Language.translate(this._ctx.titleTextKey)
    this._ctx.tag('Title').text.text = title
    return [title]
  }

  destroy(): void {
    clearTimeout(this._timeout)
  }

  success(activationType: ACTIVATION_TYPE): void {
    this._activationType = activationType
    // Wait for events to dispatch at notifyUser function
    this._timeout = setTimeout(() => this._endNotification(), NOTIFICATION_TIMEOUT)
  }

  _endNotification(): void {
    clearTimeout(this._timeout)
    this._redirect()
  }

  _redirect(): void {
    ModalManager.close(CloseReason.COMPLETED)
  }
}
