import { Language, Lightning, Utils } from '@lightningjs/sdk'

import { COLORS, FONT_FACE, SCREEN_SIZE, TEXT_ALIGN, VERTICAL_ALIGN } from '../constants'

export default class Notification extends Lightning.Component {
  static override _template() {
    return {
      zIndex: 3,
      w: SCREEN_SIZE.width,
      h: SCREEN_SIZE.height,
      rect: true,
      color: COLORS.black2,
      Icon: {
        x: (w: any) => w / 2,
        mountX: 0.5,
        y: 388,
      },
      Success: {
        x: (w: any) => w / 2,
        mountX: 0.5,
        y: 578,
        alpha: 0,
        text: {
          textColor: COLORS.white,
          fontFace: FONT_FACE.light,
          fontSize: 90,
          maxLines: 1,
          wordWrapWidth: 1120,
          verticalAlign: VERTICAL_ALIGN.middle,
          textAlign: TEXT_ALIGN.center,
          text: Language.translate('success'),
        },
      },
      Message: {
        x: (w: any) => w / 2,
        mountX: 0.5,
        y: 588,
        text: {
          textColor: COLORS.white,
          fontFace: FONT_FACE.light,
          fontSize: 64,
          maxLines: 3,
          wordWrapWidth: 1120,
          verticalAlign: VERTICAL_ALIGN.middle,
          textAlign: TEXT_ALIGN.center,
        },
      },
    }
  }

  _setNotificationMsg(iconPath: any, isSuccessNotification: any, notificationMsg: any) {
    this.patch({
      Icon: {
        src: iconPath ? Utils.asset(iconPath) : null,
      },
      Success: {
        alpha: isSuccessNotification,
        text: Language.translate('success'),
      },
      Message: {
        y: isSuccessNotification ? 698 : 588,
        text: {
          text: notificationMsg,
        },
      },
    })
  }

  override _inactive() {
    this._setNotificationMsg(null, false, '')
  }
}
