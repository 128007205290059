import { Lightning } from '@lightningjs/sdk'

import { COLORS, FONT_FACE } from '../../constants'

export default class Separator extends Lightning.Component {
  static override _template() {
    return {
      w: 21,
      text: {
        fontFace: FONT_FACE.light,
        fontSize: 100,
        lineHeight: 100,
        textColor: COLORS.white,
        text: ':',
      },
    }
  }
}
