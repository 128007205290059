import { Language } from '@lightningjs/sdk'

import LiveBadge from '../../badges/LiveBadge'

export default (label: string, positionY: number) => {
  return (tile: any) => {
    if (!tile._item.isUpcoming) {
      tile.patch({
        ImageHolder: {
          LiveBadge: {
            x: 10,
            y: positionY,
            h: 36,
            fontSize: 25,
            type: LiveBadge,
            label: label || Language.translate('live'),
          },
        },
      })
    }
  }
}
