import { GqlFetchResult } from '../client/types'
import { MixedEditorialShelfQuery } from '../generated/types'

const mixedEditorialShelf = (page: GqlFetchResult<MixedEditorialShelfQuery>) => {
  const data = page.data?.mixedEditorialShelf
  if (!data) return null
  return { data }
}

export default mixedEditorialShelf
