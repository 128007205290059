import mParticle from '@mparticle/web-sdk'
import getLinearStartAttributes from '../attributes/getLinearStartAttributes'

const getEvent = () => ({
  getAttributes: getLinearStartAttributes,
  getCategory: () => mParticle.EventType?.UserContent,
  type: 'Linear Start',
  injectGlobals: true,
})

export default getEvent
