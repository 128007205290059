import { Log } from '@lightningjs/sdk'

export const generateJitterDelay = (min: number, max: number): number => {
  if (min < 0 || max < 0 || min > max) {
    throw new Error('Invalid jitter range')
  }

  // When both min and max are zero, return zero delay.
  if (min === 0 && max === 0) {
    return 0
  }

  // Generate random delay in the range [min, max]
  const delayInSeconds = Math.random() * (max - min) + min

  // return in milliseconds
  return delayInSeconds * 1000
}

export const executeAfterJitterDelay = (callback: () => void, min: number, max: number): void => {
  const delay = generateJitterDelay(min, max)
  Log.info('Binge Delay in Sec', delay / 1000)
  setTimeout(callback, delay)
}
