import { Language } from '@lightningjs/sdk'
import { ROUTE, ROUTE_ENTITY } from '../../../../constants'
import RouterUtil from '../../../../util/RouterUtil'
import { ActivationStrategy } from './ActivationStrategy'
import ModalManager, { CloseReason } from '../../../../lib/ModalManager'

export class EventActivationStrategy extends ActivationStrategy {
  override drawText() {
    const title = Language.translate('live_stream_link_msg')
    this._ctx.tag('Title').text.text = title
    return [title]
  }
  override _redirect() {
    if (this._ctx._streamId) {
      ModalManager.close(CloseReason.COMPLETED)
      RouterUtil.navigateToRoute(ROUTE.watch, {
        entity: ROUTE_ENTITY.pid,
        value: this._ctx._streamId,
      })
    } else ModalManager.close(CloseReason.DEFAULT)
  }
}
