import { Language } from '@lightningjs/sdk'

import BaseComponent from '../../base'
import TextButton from '../../buttons/TextButton'

import { COLORS, FONT_FACE, VERTICAL_ALIGN, RATINGS } from '../../../constants'

export default class TVRating extends BaseComponent {
  _tvRating: any
  static override _template() {
    return {
      h: 189,
      w: 189,
      rect: true,
      colorTop: COLORS.black,
      colorBottom: COLORS.black,
      TVRating: {
        type: TextButton,
        announce: Language.translate('rating'),
        radius: 0,
        verticalAlign: VERTICAL_ALIGN.middle,
        fontSize: 100,
        fontFace: FONT_FACE.helvetica,
      },
      ContentDescriptors: {
        type: TextButton,
        announce: Language.translate('rating'),
        radius: 0,
        verticalAlign: VERTICAL_ALIGN.middle,
        fontSize: 100,
        fontFace: FONT_FACE.helvetica,
      },
    }
  }

  override _init() {
    this._tvRating = this.tag('TVRating')
  }

  setup(program: any) {
    const rating = program?.rating
    if (rating && rating !== RATINGS.NO_RATING) {
      this._tvRating.label = program?.rating
      this.visible = true
    } else {
      this.visible = false
    }
  }
}
