import { createRequestConfig } from '../lib/useRequest'
import {
  componentsForPlaceholders,
  endCardRequest,
  mainRequest,
  mixEditorialShelfRequest,
  onAirNowShelfRequest,
} from '../api/requests'
import Variables from '../graphql/Variables'
import { CALL_SIGNS } from '../constants'
import {
  ComponentsForPlaceholdersQueryVariables,
  EndCardPageQueryVariables,
  VideoType,
} from '../graphql/generated/types'
import rsnFilter from '../graphql/filter/rsnFilter'
import componentsForPlaceholdersMapper from '../graphql/mappers/componentsForPlaceholders'
import onAirNowShelfMapper from '../graphql/mappers/onAirNowShelf'
import mixedEditorialShelf from '../graphql/mappers/mixedEditorialShelf'
import endCardMapper from '../graphql/mappers/endCard'

export const SlePageRequestConfig = (pid: string | number) =>
  createRequestConfig({
    query: mainRequest,
    variables: Variables.slePageVariables(pid),
    reject: [(data) => !data?.sections],
  })

export const LiveScheduleRequestConfig = () =>
  createRequestConfig({
    query: mainRequest,
    variables: Variables.liveSchedulePageVariables(),
    reject: [(data) => !data?.sections],
  })

export const StreamRequestConfig = (callSign: CALL_SIGNS, isGlobalNavigation = false) =>
  createRequestConfig({
    query: mainRequest,
    variables: Variables.streamPageVariables(isGlobalNavigation ? 'live' : callSign, callSign),
    reject: [(data) => !data?.sections],
  })

export const OlympicsStreamRequestConfig = (videoId: string) =>
  createRequestConfig({
    query: mainRequest,
    variables: Variables.olympicsVideoPageVariables(videoId),
    reject: [(data) => !data?.sections],
  })

export const VideoPageRequestConfig = (videoId: string) =>
  createRequestConfig({
    query: mainRequest,
    variables: Variables.videoPageVariables(videoId),
    reject: [(data) => !data?.sections],
  })

export const ComponentsForPlaceholdersConfig = (
  variables: ComponentsForPlaceholdersQueryVariables
) =>
  createRequestConfig({
    query: componentsForPlaceholders,
    mapper: (data: any) => rsnFilter(componentsForPlaceholdersMapper(data)),
    variables: Variables.componentsForPlaceholdersVariables(variables),
  })

export const onAirNowShelfConfig = (variables: ComponentsForPlaceholdersQueryVariables) =>
  createRequestConfig({
    query: onAirNowShelfRequest,
    mapper: (data: any) => rsnFilter(onAirNowShelfMapper(data)),
    variables: Variables.componentsForPlaceholdersVariables(variables),
  })

export const EndCardConfig = (type: VideoType, videoId: string) =>
  createRequestConfig({
    query: endCardRequest,
    variables: Variables.endCardVariables(type, videoId) as EndCardPageQueryVariables,
    mapper: (data) => endCardMapper(data, ''),
  })

export const EndCardSleConfig = (type: VideoType, videoId: number, source: string) =>
  createRequestConfig({
    query: endCardRequest,
    variables: Variables.endCardSLEVariables(type, videoId) as EndCardPageQueryVariables,
    mapper: (data) => endCardMapper(data, source),
  })

export const MixedEditorialConfig = (variables: any) =>
  createRequestConfig({
    query: mixEditorialShelfRequest,
    variables: Variables.mixedEditorialShelfVariables(variables),
    mapper: (data) => rsnFilter(mixedEditorialShelf(data)),
  })
