import { Router } from '@lightningjs/sdk'

export const ResultsStateFactory = (base: any) =>
  class Results extends base {
    _handleUp() {
      this._setState('Filters')
    }

    _captureBack(e: any) {
      if (this.tag('Results').index === 0) {
        this.widgets.menu.setSmooth('alpha', 1)
        Router.focusWidget('Menu')
      } else {
        this.tag('Results').setIndex(0)
      }
      e.preventDefault()
      e.stopPropagation()
    }

    _getFocused() {
      return this.tag('Results') || this
    }
    $scrolledRow(rowId: any) {
      const hideElements = rowId < 2
      this.widgets.menu.visible = hideElements
      this.tag('Filters').patch({ visible: hideElements })
    }
  }
