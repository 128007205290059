export enum MAPPED_CHANNELS {
  sports = 'nbcsn',
  nbcsports = 'nbcsn',
  nbcsbayarea = 'nbcsn',
  nbcsboston = 'nbcsn',
  nbcscalifornia = 'nbcsn',
  nbcschicago = 'nbcsn',
  nbcsphiladelphia = 'nbcsn',
  nbcswashington = 'nbcsn',
}

export const CONTENT_TYPES = {
  linear: 'live',
  live: 'live',
  'Single Live Event': 'sle',
  'Full Event Replay': 'fer',
  Highlights: 'vod',
} as const

export const TOKEN_VERIFIER_URLS = {
  sle: {
    hostKey: 'tokenVerifier',
    endpoint: 'tokenverifier/entitlement/verifier',
  },
  linear: {
    hostKey: 'tokenVerifier',
    endpoint: 'tokenverifier/entitlement/verifier',
  },
}

export const TEMP_PASS_ERROR = Object.freeze({
  UNKNOWN: 'Unknown error',
  ATTEMPTS_EXCEEDED: 'TempPass creation attempts exceeded',
  TEMP_PASS_ACTIVE: 'TempPass already active',
  SESSION_EXPIRED: 'Session Expired',
  LAUNCH_DARKLY_ERROR:
    'No initial TempPass name / TempPass disabled from LaunchDarkly / Error fetching LaunchDarkly data',
})
