import { DocumentNode, FieldNode, FragmentDefinitionNode, visit } from 'graphql'
import { isDevelopmentMode } from '../../helpers'
import {
  MatchCase,
  applyToNodeSelectionSet,
  removeFieldFromNodeSelectionSet,
  removeFragmentSpreadFromNodeSelectionSet,
  removeFragmentsFromNode,
} from '.'

const prodDisabledFragments = [
  'stackGroup',
  'olympicsVideoPageAnalyticsAttributes',
  'olympicsHighlightsHubPageAnalyticsAttributes',
  'olympicsReplaysHubPageAnalyticsAttributes',
  'componentsForPlaceholdersShelf',
  'componentsForPlaceholdersOnAirNowShelf',
  'stackList',
  'lazyStackGroup',
  'componentsForPlaceholdersTileList',
  'componentsForPlaceholdersFeatureTile',
  'componentsForPlaceholdersFeatureItem',
  'componentsForPlaceholdersReplayTile',
  'componentsForPlaceholdersUpcomingLiveTile',
  'componentsForPlaceholdersUpcomingLiveItem',
  'componentsForPlaceholdersUpcomingModal',
  'componentsForPlaceholdersNotification',
  'componentsForPlaceholdersCtaLink',
  'componentsForPlaceholdersCtaData',
  'componentsForPlaceholdersOnAirNowTile',
  'componentsForPlaceholdersOnAirNowItem',
  'componentsForPlaceholdersOnAirNowList',
  'qrModal',
  'qrModalData',
  'itemLabelsConfigItem',
]

const transformCoreSDKPlayerFragment = (node: FragmentDefinitionNode): FragmentDefinitionNode => {
  const matchCases: MatchCase[] = []
  // Return if matchCases are empty.
  if (matchCases.length === 0) return node
  // Using for loops so we can break from the loop if we find the fragment we need
  for (let i = 0, n = node.selectionSet.selections.length; i < n; i++) {
    const current = node.selectionSet.selections[i]
    if (current && current.kind === 'Field' && current.alias?.value === 'CoreSDKPlayerData') {
      applyToNodeSelectionSet(current, matchCases)
      break
    }
  }
  return node
}

const transformComponentForPlaceholdersFragment = (node: FieldNode) => {
  applyToNodeSelectionSet(node, [
    {
      kind: 'Field',
      name: 'components',
      callback: (field: any) =>
        removeFragmentSpreadFromNodeSelectionSet(field, ['qrModal', 'qrModalData']),
    },
  ])
  return node
}

const debugProd = localStorage?.getItem('debug.env') || ''
// Using a RegExp to avoid localStorage serializing inconsistencies

const prod = () => new RegExp('Production|Acc').test(debugProd) || !isDevelopmentMode()

export const featureTransformer = (doc: DocumentNode, override?: boolean): DocumentNode =>
  // Using window.localStorage since DebugControllerSingleton and LightningJS Storage class aren't initialized at this point
  prod() || override
    ? visit(doc, {
        enter(node) {
          if (node.kind === 'FragmentDefinition' && node.name.value === 'coreSDKPlayer') {
            return transformCoreSDKPlayerFragment(node)
          }
          if (node.kind === 'Field' && node.name.value === 'componentsForPlaceholders') {
            return transformComponentForPlaceholdersFragment(node)
          }
          if (node.kind === 'FragmentDefinition' && node.name.value === 'videoItem') {
            return removeFieldFromNodeSelectionSet(node, 'isOlympics')
          }
          if (node.kind === 'FragmentDefinition' && node.name.value === 'ctaData') {
            return removeFieldFromNodeSelectionSet(node, 'qrModal')
          }
          if (node.kind === 'FragmentDefinition' && node.name.value === 'upcomingLiveSlideData') {
            return removeFieldFromNodeSelectionSet(node, [
              'language',
              'slideHeader',
              'isMedalSession',
              'tertiaryTitle',
            ])
          }
          if (node.kind === 'FragmentDefinition' && node.name.value === 'slideItem') {
            return removeFieldFromNodeSelectionSet(node, ['isMedalSession', 'language'])
          }
          if (node.kind === 'FragmentDefinition' && node.name.value === 'upcomingLiveItem') {
            return removeFieldFromNodeSelectionSet(node, 'isMedalSession')
          }
          if (
            node.kind === 'FragmentDefinition' &&
            (node.name.value === 'stringSelectableComponentList' ||
              node.name.value === 'nestedStringSelectableComponentList')
          ) {
            return removeFieldFromNodeSelectionSet(node, 'itemLabelsConfig')
          }

          return removeFragmentsFromNode(node, prodDisabledFragments)
        },
      })
    : doc
