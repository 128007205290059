import { Lightning } from '@lightningjs/sdk'

import TextButton from '../buttons/TextButton'

import { COLORS, FLEX_DIRECTION, FONT_FACE } from '../../constants'

export default class LiveBadge extends Lightning.Component {
  static override _template() {
    return {
      flex: {
        direction: FLEX_DIRECTION.row,
      },
      flexItem: {
        marginRight: 4,
      },
      LiveText: {
        h: this.bindProp('h'),
        type: TextButton,
        radius: 5,
        fontSize: this.bindProp('fontSize'),
        fontFace: FONT_FACE.semiBold,
        focusFontColor: COLORS.white,
        unfocusFontColor: COLORS.white,
        focusBackGroundColor: COLORS.red,
        unfocusBackgroundColor: COLORS.red,
        autoWidth: true,
        padding: 17,
        label: this.bindProp('label'),
      },
    }
  }
}
