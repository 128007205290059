import LinksSelectableGroup from './LinksSelectableGroup'
//For now we don't have a need for any extra extensability. This is more for creating consistency where we get
//a groupedContinuousScroll object from bff and so we create one.
//But it has similar functionality to the LinksSelectableGroup
export default class GroupedContinuousScroll extends LinksSelectableGroup {
  override _selectedIndex: any
  $scrolledListItemIndex({ groupId }: any) {
    const labelsList = this.tag('LabelsList')
    const items = labelsList?.items

    // Null check to avoid page goes blank
    if (Array.isArray(items)) {
      const currentItem = items[this._selectedIndex]

      if (currentItem?.label !== groupId) {
        const newSelectedIndex = items.findIndex((item: any) => item.label === groupId)

        if (newSelectedIndex !== -1) {
          this._selectedIndex = newSelectedIndex
          labelsList.selectItem(newSelectedIndex)
        }
      }
    }
  }
}
