import { Lightning } from '@lightningjs/sdk'

import RouterUtil from '../../../util/RouterUtil'
import { COLORS, FONT_FACE, MAX_LINES_SUFFIX } from '../../../constants'

export default (tile: any) => {
  if (tile._item?.showClipData) {
    const isShowHomePage = RouterUtil.isShowHomePage()

    tile.patch({
      Content: {
        TitleCard: {
          Title: {
            text: {
              maxLines: 1,
              maxLinesSuffix: MAX_LINES_SUFFIX,
              fontFace: isShowHomePage ? 'SemiBold' : 'Light',
            },
          },
          ShowTitle: {
            // Should only be visible on Global Clips UI
            visible: !isShowHomePage,
            text: { maxLines: 1, maxLinesSuffix: MAX_LINES_SUFFIX },
          },
          Clip: {
            flex: {},
            flexItem: { marginTop: -8 },
            Icon: {
              flexItem: { marginRight: 8, marginTop: 3 },
              texture: Lightning.Tools.getRoundRect(
                54,
                26,
                0,
                1,
                COLORS.mediumGray4,
                true,
                COLORS.transparent
              ),
              Label: {
                mount: 0.5,
                x: 29,
                y: 16,
                text: { text: 'CLIP', fontFace: FONT_FACE.light, fontSize: 24 },
              },
            },
            Date: {
              text: {
                text: tile._item.date,
                fontFace: FONT_FACE.light,
                fontSize: 24,
              },
            },
          },
        },
      },
    })
  }
}
