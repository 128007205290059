import { IPlayerEvent } from './IPlayerEvent'

type SeekableRange = { start: number; end: number; startDate?: Date; endDate?: Date }
export class TimeChangeEvent extends IPlayerEvent {
  _time: number
  _seekableRange?: SeekableRange
  _isAtLiveEdge?: boolean

  constructor(time: number, seekableRange?: SeekableRange, isAtLiveEdge?: boolean) {
    super()
    this._time = time
    this._seekableRange = seekableRange
    this._isAtLiveEdge = isAtLiveEdge
  }

  get time() {
    return this._time
  }

  get seekableRange() {
    return this._seekableRange
  }

  get isAtLiveEdge() {
    return this._isAtLiveEdge
  }
}
