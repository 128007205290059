import AuthenticationSingleton from '../../../../authentication/Authentication'
import { isVod } from '../../../../components/player/helpers/metadata'
import { CONTENT_TYPE, PROGRAMMING_TYPES } from '../../../../constants'
import ModalManager, { ModalTypes } from '../../../../lib/ModalManager'
import {
  ACTIVATION_LANDING,
  ACTIVATION_REFERRER,
} from '../../../../widgets/Modals/activation/constants'
import { TEMP_PASS_REDIRECT } from '../../BasePlayer'
import { StreamLoaderError, StreamLoaderErrors } from '../error'

export type ActivationParams = {
  landing?: ACTIVATION_LANDING
  referrer?: ACTIVATION_REFERRER
  program?: any
  stream?: any
  id?: string
  videoId?: string
}

const getParams = (e: StreamLoaderError, stream: any, program: any) => {
  const result: ActivationParams = {
    landing: ACTIVATION_LANDING.MVPD,
    program,
    stream,
  }
  const programmingType = program.programmingType

  if (e.detail === StreamLoaderErrors.NO_CREDITS) {
    if (isVod(programmingType)) result.referrer = ACTIVATION_REFERRER.VOD
    return result
  } else if ([PROGRAMMING_TYPES.FER, PROGRAMMING_TYPES.SLE].includes(programmingType)) {
    result.id = program?.pid
    result.referrer = ACTIVATION_REFERRER.EVENT
  } else if (isVod(programmingType) && stream?.contentType !== CONTENT_TYPE.LINEAR) {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(result.id = stream?.mpxGuid), (result.referrer = ACTIVATION_REFERRER.VOD)
    result.landing = ACTIVATION_LANDING.FORK
  } else {
    result.referrer = ACTIVATION_REFERRER.LINEAR
  }
  return result
}

const getTempPassParams = (param: TEMP_PASS_REDIRECT) => {
  switch (param) {
    case TEMP_PASS_REDIRECT.TEMP_PASS_MVPD:
    case TEMP_PASS_REDIRECT.TEMP_PASS_CONCLUDED:
      return {
        referrer: ACTIVATION_REFERRER.TEMPPASS,
        landing: ACTIVATION_LANDING.MVPD,
      }
    case TEMP_PASS_REDIRECT.TEMP_PASS_NBC_ACCOUNT:
      return {
        referrer: ACTIVATION_REFERRER.TEMPPASS,
        landing: ACTIVATION_LANDING.NBC,
      }
    default:
      return {}
  }
}

export const activateTempPassRedirect = async (redirect: TEMP_PASS_REDIRECT) => {
  const map = {
    [TEMP_PASS_REDIRECT.TEMP_PASS_MVPD]: ModalTypes.ACTIVATION_TEMPPASS_MVPD,
    [TEMP_PASS_REDIRECT.TEMP_PASS_NBC_ACCOUNT]: ModalTypes.ACTIVATION_TEMPPASS_NBC,
    [TEMP_PASS_REDIRECT.TEMP_PASS_CONCLUDED]: ModalTypes.TEMPPASS_CONCLUDED,
  }
  const route = map[redirect]
  await AuthenticationSingleton.logout()
  if (route) {
    ModalManager.open(route, getTempPassParams(redirect))
  }
}

export const activateRedirect = async (error: StreamLoaderError, stream: any, program: any) => {
  ModalManager.open(ModalTypes.ACTIVATION, getParams(error, stream, program))
}
