import { GraphQlClient } from '../../graphql/client'
import {
  OnAirNowShelfPageQuery,
  OnAirNowShelfPageQueryVariables,
} from '../../graphql/generated/types'
import { QueryName } from './bffQuery'

export default async (variables: OnAirNowShelfPageQueryVariables) => {
  return await GraphQlClient.query<OnAirNowShelfPageQuery>({
    query: QueryName.onAirNowShelf,
    variables,
  })
}
