import { Discovery } from '@firebolt-js/sdk'
import { NavigationEvent } from '../base'
import { ROUTE } from '../../../constants'
import { Log } from '@lightningjs/sdk'

const isInRouteEnum = (input?: string): input is ROUTE =>
  input ? (Object.values(ROUTE) as string[]).includes(input) : false

export const comcastFormatNavigation = (e: Discovery.NavigationIntent): NavigationEvent => {
  // FIXME: Saving this for when Firebolt team allows debugging of our app, remove afterwards
  Log.info(`Navigation Event: ${e}`)
  switch (e.action) {
    case 'entity':
      if (e.data.assetId) {
        return {
          route: ROUTE.video,
          entity: {
            entityType: ':videoId',
            value: e.data.entityId,
          },
        }
      }
      if (
        [
          Discovery.ProgramType.EPISODE,
          Discovery.ProgramType.SEASON,
          Discovery.ProgramType.SERIES,
        ].includes((e.data as any).programType)
      ) {
        return {
          route: ROUTE.showHome,
          entity: {
            entityType: ':urlAlias',
            value: e.data.entityId,
          },
        }
      }
      if ((e.data as any).programType === Discovery.ProgramType.MOVIE) {
        return {
          route: ROUTE.movieHome,
          entity: {
            entityType: ':itemName',
            value: e.data.entityId,
          },
        }
      }
      return {
        route: ROUTE.search,
        entity: {
          entityType: ':query?',
          value: e.data.entityId,
        },
      }
    case 'section':
      return {
        route: (isInRouteEnum(e.data?.sectionName) && e.data?.sectionName) || ROUTE.home,
      }
    case 'tune':
      return {
        route: ROUTE.live,
      }
    case 'playback':
      if (e.data.assetId) {
        return {
          route: ROUTE.video,
          entity: {
            entityType: ':videoId',
            value: e.data.assetId,
          },
        }
      }
      if (e.data.programType === Discovery.ProgramType.EPISODE) {
        return {
          route: ROUTE.showHome,
          entity: {
            entityType: ':urlAlias',
            value: e.data.entityId,
          },
        }
      }
      if (e.data.programType === Discovery.ProgramType.MOVIE) {
        return {
          route: ROUTE.movieHome,
          entity: {
            entityType: ':itemName',
            value: e.data.entityId,
          },
        }
      }
      return {
        route: ROUTE.search,
        entity: {
          entityType: ':query?',
          value: e.data.entityId,
        },
      }
    case 'search':
      if (!e.data?.query)
        return {
          route: ROUTE.search,
        }
      return {
        route: ROUTE.search,
        entity: {
          entityType: ':query?',
          value: e.data?.query,
        },
      }
    // Not sure how to handle launch app intent (shouldn't this intent handled by the device?)
    case 'launch':
    case 'home':
    default:
      return { route: ROUTE.home }
  }
}
