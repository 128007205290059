import LaunchDarklySingleton from '../lib/launchDarkly/LaunchDarkly'
import LaunchDarklyFeatureFlags from '../lib/launchDarkly/LaunchDarklyFeatureFlags'
import {
  List,
  Carousel,
  Grid,
  BrandTile,
  MovieTile,
  SeriesTile,
  VideoTile,
  Slide,
  UpcomingLiveSlide,
  UpcomingLiveSlideTile,
  SlideTile,
  UpcomingLiveTile,
  SportsLiveGuideTile,
  BrandItemLabel,
  EndCardAlternate,
  EndCardAlternateSLE,
  OnAirNowTile,
  EventTile,
  ReplayTile,
  ContinuousScroll,
  Placeholder,
  MarketingModule,
  Stack,
} from './index'

export const collectionComponents = new Map()

collectionComponents.set('Slideshow', Carousel)
collectionComponents.set('Shelf', List)
collectionComponents.set('Stack', Stack)
collectionComponents.set('OnAirNowShelf', List)
collectionComponents.set('Grid', Grid)
collectionComponents.set('SmartTile', List)
collectionComponents.set('EndTiles', List)
collectionComponents.set('GroupedContinuousScroll', List)
collectionComponents.set('ContinuousScroll', ContinuousScroll)
collectionComponents.set('Placeholder', Placeholder)
collectionComponents.set('MarketingModule', MarketingModule)

export const createItems = (items: any, activeIndex: number | undefined = undefined) => {
  if (
    !tileComponentMap.has('ReplayTile') &&
    LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.enableFERContent)
  ) {
    tileComponentMap.set('ReplayTile', ReplayTile)
  }
  return items
    .map((item: any, index: any) => {
      if (!item) return
      const itemType = tileComponentMap.get(item?.component)
      item.active = index === activeIndex
      return itemType
        ? {
            itemType,
            item,
          }
        : null
    })
    .filter(Boolean)
}

const tileComponentMap = new Map()

tileComponentMap.set('BrandTile', BrandTile)
tileComponentMap.set('MovieTile', MovieTile)
tileComponentMap.set('SeriesTile', SeriesTile)
tileComponentMap.set('VideoTile', VideoTile)

tileComponentMap.set('Slide', Slide)
tileComponentMap.set('UpcomingLiveSlide', UpcomingLiveSlide)
tileComponentMap.set('UpcomingLiveSlideTile', UpcomingLiveSlideTile)
tileComponentMap.set('SlideTile', SlideTile)
tileComponentMap.set('UpcomingLiveTile', UpcomingLiveTile)
tileComponentMap.set('SportsLiveGuideTile', SportsLiveGuideTile)
tileComponentMap.set('BrandItemLabel', BrandItemLabel)
tileComponentMap.set('EndCardAlternate', EndCardAlternate)
tileComponentMap.set('EndCardAlternateSLE', EndCardAlternateSLE)
tileComponentMap.set('OnAirNowTile', OnAirNowTile)
tileComponentMap.set('EventTile', EventTile)
