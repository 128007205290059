export default class Metadata {
  _data: any
  constructor(data: any) {
    this._data = data
  }

  get firstRow() {
    return this._data.textRow1
  }

  get secondRow() {
    return this._data.textRow2
  }
}
