import { Log } from '@lightningjs/sdk'
import mParticle from '@mparticle/web-sdk'

import ParkAPI, { USERS_VERSION } from './ParkApi'
import WatchesLib from './Watches'
import FavoritesLib from './Favorites'
import { setMpid, getMpid } from '../helpers/analytics'
import { sendMetric } from '../lib/analytics/Analytics'
import { EVENTS } from '../lib/analytics/types'
import AppConfigFactorySingleton from '../config/AppConfigFactory'

class MParticleApi {
  static MPARTICLE_API_TAG = 'mParticle API'
  static DEFAULT_MPARTICLE_ID = '0'
  /**
   * Should Migrate User - check if anonymous mpid has favorites or watches
   * @returns {boolean}
   */
  static shouldMigrateUser = async (mpid: any) => {
    try {
      const favorites = await FavoritesLib.get(mpid)
      const watches = await WatchesLib.get(mpid)
      if (favorites?.meta?.count > 0 || watches?.meta?.count > 0) {
        return false
      }
    } catch (error) {
      return false
    }
    return true
  }

  /**
   * Migrate User - sync anonymous user watches with NBC profile
   * @returns {Promise}
   */
  static migrateUser = async (anonymousMpid: any, mpid: any) => {
    const url = `users/${anonymousMpid}/migrate/${mpid}`
    const result = await ParkAPI.post(url, 'POST', {}, { version: USERS_VERSION })
    return result
  }

  /**
   * Login identity profile with mParticle
   * @returns {Promise}
   */
  static login = (identity: any) =>
    new Promise<void>((resolve, reject) => {
      if (!identity) reject()

      if (identity) {
        sendMetric(EVENTS.IDM_CONVERSION)
        const { mail: email, id: customerid } = identity
        mParticle.Identity.login(
          { userIdentities: { email, customerid } },
          async (response: any) => {
            const nbcProfileMPID = response.getUser().getMPID()
            const anonymousMPID =
              response?.getPreviousUser()?.getMPID() || MParticleApi.DEFAULT_MPARTICLE_ID

            // Update localStorage with new mpid.
            setMpid(nbcProfileMPID)

            const shouldMigrateUser = await MParticleApi.shouldMigrateUser(nbcProfileMPID)
            if (shouldMigrateUser) {
              Log.info(
                MParticleApi.MPARTICLE_API_TAG,
                'NBCUniversal Profile has no existing favorites/watch data. Migrating existing favorites/watches.'
              )
              await MParticleApi.migrateUser(anonymousMPID, nbcProfileMPID)
            } else {
              Log.info(
                MParticleApi.MPARTICLE_API_TAG,
                'NBCUniversal Profile has existing favorites/watch data. Favorites/watches were not migrated.'
              )
            }
            resolve()
          }
        )
      }
    })

  /**
   * Logout identity profile with mParticle
   * @returns {Promise}
   */
  static logout = () =>
    new Promise<void>((resolve) => {
      mParticle.Identity.logout({ userIdentities: {} }, (response) => {
        // Reset to previous mpid.
        if (response?.getPreviousUser) {
          const previousMpid =
            response?.getPreviousUser()?.getMPID() || MParticleApi.DEFAULT_MPARTICLE_ID
          // Return localStorage to previous mpid or 0.
          setMpid(previousMpid)
        }
        resolve()
      })
    })

  /**
   * Fetch mParticle audience ids
   * @returns {Promise}
   */
  static getAudiences = async () => {
    const url = AppConfigFactorySingleton.get().config.mparticle.audienceSearchUrl
    const mpid = getMpid()
    try {
      const response = await fetch(`${url}?mpid=${mpid}`)
      const audienceJSON = await response.json()
      const audiences = audienceJSON?.audiences || []
      Log.info(MParticleApi.MPARTICLE_API_TAG, 'audience ids found for this user', audiences)
      return audiences
    } catch {
      Log.info(MParticleApi.MPARTICLE_API_TAG, 'no audience ids found for this user')
      return []
    }
  }

  static getSessionId = () => mParticle?.sessionManager?.getSession() || ''
}

export default MParticleApi
