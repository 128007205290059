import AuthenticationSingleton from '../../../authentication/Authentication'
import { COLORS } from '../../../constants'
import KeyBadge from '../../badges/KeyBadge'

export default (item: any) => {
  if (item.isLocked) {
    if (AuthenticationSingleton.isAuthenticated() && !AuthenticationSingleton.isTempPassActive()) {
      item.lockContainer?.patch({
        LockBackground: {
          alpha: 0,
        },
      })
    } else {
      item.lockContainer?.patch({
        LockBackground: {
          type: KeyBadge,
          positionX: item.keyBadgePosition,
          color: item.lockTransparent ? COLORS.transparent : COLORS.black4,
        },
      })
    }
  }
}
