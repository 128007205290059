export const FeaturedStateFactory = (base: any) =>
  class Featured extends base {
    $enter() {
      this._resetPosition()
    }
    _getFocused() {
      return this.tag('Featured') || this
    }

    _handleDown() {
      this._setState('Scroller')
    }

    _handleRight() {
      this._setState('ItemMenu')
    }
  }
