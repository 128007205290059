import { PLATFORM_TYPES } from '../../helpers'
import { CVSDK_PLATFORMS } from './getCoreVideoSdkPlatform'
import { getCoreVideoSdkPlatform } from './getCoreVideoSdkPlatform'

const DeviceNameOverride = new Map<string, string>([
  [CVSDK_PLATFORMS[PLATFORM_TYPES.XCLASS], 'platcottv'],
  [CVSDK_PLATFORMS[PLATFORM_TYPES.KEPLER], 'firetv'],
])

export const getDRMDeviceName = () => {
  const cvsdkPlatform = getCoreVideoSdkPlatform()
  const deviceName = DeviceNameOverride.get(cvsdkPlatform)
  return deviceName || cvsdkPlatform
}
