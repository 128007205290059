import { Log } from '@lightningjs/sdk'
import AuthenticationSingleton from '../../authentication/Authentication'
import { ResourceObject, transformResources } from '../../helpers'
import { EpgSchedule, EpgStream } from '../../store/PlayerStore/actions/epg'

export const filterEPGStreamsByRSN = (streams: EpgStream[]): EpgStream[] => {
  const resources = AuthenticationSingleton.getMvpdData()?.preAuthData?.resources
  if (!resources) return streams
  return streams.filter((stream: EpgStream) => stream)
}

export const filterScheduleByRSN = (schedules: EpgSchedule[]): EpgSchedule[] => {
  const resources = AuthenticationSingleton.getMvpdData()?.preAuthData?.resources
  if (!resources) return schedules
  return schedules
    .map(({ programs }: EpgSchedule) => ({
      programs: programs.filter((program) => program),
    }))
    .filter(({ programs }: EpgSchedule) => programs.length)
}

const rsnFilter = <T>(page: T): T => {
  const resources = AuthenticationSingleton.getMvpdData()?.preAuthData?.resources
  // If we don't have our preauth data, just return the page right away. No filtering needed.
  if (!resources || !page) {
    return page
  }
  // Check both sections and featured
  // @ts-expect-error Add types
  const { sections, featured } = page
  const resourceAuthorization = transformResources(resources)
  // Filter the data for both sections and featured
  const filteredSections = filterData(sections, resourceAuthorization)

  // Handling lazy loader - placeholder components for featured DLS
  let filteredFeatured
  //@ts-expect-error TS(2339): component is not in property
  if (page?.component === 'Slideshow' && !featured) {
    filteredFeatured = filterData([page], resourceAuthorization)
  } else {
    filteredFeatured = filterData(featured, resourceAuthorization)
  }

  //Return the page with new sections and featured
  return {
    ...page,
    featured: filteredFeatured,
    sections: filteredSections,
  }
}

// TODO: replace any for the real type.
const filterData = (sections: any, resourceAuthorization: ResourceObject) => {
  return (
    sections?.map?.((section: any) => {
      const filtered = section?.data?.items?.filter((item: any) => {
        //TODO: Check mapping, see if we can get this to always be data rather than these other data types
        const data =
          item?.data ||
          item?.replayTileData ||
          item?.smartTileData ||
          item?.eventTileData ||
          item?.item?.data ||
          item?.onAirNowTileData

        if (!data) {
          Log.info('rsnFilter missing data:', item)
          return false
        }
        return true
      })
      //Placeholder sections don't have items
      if (section?.data?.items) {
        section.data.items = filtered
      }
      return section
    }) || sections
  )
}

export default rsnFilter
