import { Lightning, Storage } from '@lightningjs/sdk'

import BaseComponent from '../../base'
import { lockDecorator } from '../decorators'

import { sendMetric } from '../../../lib/analytics/Analytics'

import {
  COLORS,
  ENTITY_TYPES,
  FLEX_DIRECTION,
  FONT_FACE,
  PROGRAMMING_TYPES,
  ROUTE,
  ROUTE_ENTITY,
  STORAGE_KEYS,
} from '../../../constants'
import { EVENTS } from '../../../lib/analytics/types'
import RouterUtil from '../../../util/RouterUtil'
import { CtaItem } from '../../types'
import { getVideoId } from '../../../api/ParkUtils'
import UserInteractionsStoreSingleton from '../../../store/UserInteractions'

export default class CtaBlock extends BaseComponent<Lightning.Component.TypeConfig> {
  _analytics: any
  _ctaData: CtaItem | undefined
  _isSmart = false
  buttonColor = ''
  buttonText = ''
  ctaSubTitle = ''
  ctaTitle = ''
  customPosition: number | undefined
  position: number | undefined

  static override _template() {
    return {
      flex: { direction: FLEX_DIRECTION.column },
      rect: true,
      color: 0x00000000,
      y: this.bindProp('positionY', (context) => Number.parseInt(context.positionY)),
      Highlights: {
        flex: {},
        flexItem: { marginBottom: 20 },
        LabelContainer: {
          flex: {},
          rect: true,
          color: COLORS.white1,
          Label: {
            rect: true,
            flexItem: { marginLeft: 10, marginRight: 10 },
            color: COLORS.dark,
            text: {
              fontFace: FONT_FACE.regular,
              fontSize: 18,
            },
          },
        },
      },
      LockContainer: {
        flex: {},
        flexItem: { marginBottom: 10 },
      },
      CtaTitle: {
        text: {
          fontSize: 32,
          fontFace: FONT_FACE.regular,
          lineHeight: 32,
          maxLines: 1,
          wordWrapWidth: 660,
          text: this.bindProp('ctaTitle'),
        },
      },
      CtaSubTitle: {
        text: {
          fontSize: 32,
          fontFace: FONT_FACE.light,
          lineHeight: 32,
          maxLines: 1,
          wordWrapWidth: 660,
          text: this.bindProp('ctaSubTitle'),
        },
      },
      Progress: {
        visible: false,
        h: 10,
        flexItem: {
          marginTop: 25,
          marginBottom: 20,
        },
        TotalProgress: {
          h: (h: any) => h,
          w: 420,
          rect: true,
          color: COLORS.mediumGray4,
        },
        CurrentProgress: {
          h: (h: any) => h,
          rect: true,
          color: COLORS.white,
        },
      },
      ButtonHolder: {
        flex: {},
        flexItem: {
          marginTop: 10,
        },
        Button: {
          flex: {},
          rtt: true,
          h: 58,
          rect: true,
          color: this.bindProp('buttonColor'),
          shader: { type: Lightning.shaders.RoundedRectangle, radius: 29 },
          Label: {
            flexItem: { marginLeft: 50, marginRight: 50 },
            mountY: 0.5,
            y: 31,
            color: COLORS.white1,
            text: {
              fontSize: 30,
              fontFace: FONT_FACE.regular,
              text: this.bindProp('buttonText'),
            },
          },
        },
      },
    }
  }

  set ctaData(ctaData: any) {
    if (!ctaData) return

    this._ctaData = ctaData
    const v4ID = getVideoId(this._ctaData?.mpxGuid)
    const percentViewed = UserInteractionsStoreSingleton.getPercentViewed(v4ID)

    if (ctaData?.title) this.ctaTitle = ctaData?.title
    if (ctaData?.secondaryTitle) this.ctaSubTitle = ctaData?.secondaryTitle
    if (ctaData?.labelBadge) {
      this.tag('Highlights').patch({
        LabelContainer: {
          h: 28,
          Label: {
            text: ctaData?.labelBadge || 'Highlights',
          },
        },
      })
    }

    this.buttonText = ctaData?.label || ctaData?.text || ''

    if (ctaData?.gradientStart) this.buttonColor = `0xff${ctaData?.gradientStart.replace('#', '')}`
    if (percentViewed) {
      this.tag('Progress').patch({
        visible: true,
        CurrentProgress: {
          w: this.tag('Progress.TotalProgress').w * percentViewed,
        },
      })
    }

    this._addDecorators([lockDecorator])
  }

  set analytics(analytics: any) {
    this._analytics = analytics
  }

  set isSmart(isSmart) {
    if (isSmart) this._setState('SmartTileCTA')
    else this._setState('PrimaryCta')
    this._isSmart = isSmart
  }

  get isSmart() {
    return this._isSmart
  }

  override _unfocus() {
    this.tag('ButtonHolder.Button').patch({
      smooth: { alpha: 0.45 },
    })
  }

  override _focus() {
    this.tag('ButtonHolder.Button').patch({
      smooth: { alpha: 1 },
    })
  }

  get isLocked() {
    return this._ctaData?.locked
  }

  get lockContainer() {
    return this.tag('LockContainer')
  }

  get keyBadgePosition() {
    return 55
  }

  static override _states() {
    return [
      class PrimaryCta extends this {
        override _handleEnter() {
          const analytics = this._analytics
          const params = {
            entity: {
              analytics: analytics,
              entityType: ENTITY_TYPES.CTA_SMART_TILES,
            },
            shelf: { position: this.position, customPosition: this.customPosition },
          }
          sendMetric(EVENTS.CONTENT_CLICK, params)
          RouterUtil.navigateByDestinationType(this._ctaData)
        }
      },
      class SmartTileCTA extends this {
        override _handleEnter() {
          const { programmingType, mpxGuid } = this._ctaData || {}
          const analytics = this._analytics
          const params = {
            entity: {
              analytics: analytics,
              entityType: ENTITY_TYPES.CTA_SMART_TILES,
            },
            shelf: { position: this.position, customPosition: this.customPosition },
          }
          Storage.set(STORAGE_KEYS.SMART_TILE, {
            smartDynamicCTA: this._isSmart,
            smartTileLabel: analytics.smartTileLabel,
            smartTileLogic: analytics.smartTileLogic,
            smartTileScenario: analytics.smartTileScenario,
            smartTileEpisodeTitle: analytics.title,
            smartTileVideoId: analytics.mpxGuid,
          })
          sendMetric(EVENTS.CONTENT_CLICK, params)
          switch (programmingType) {
            case PROGRAMMING_TYPES.SFVOD:
            case PROGRAMMING_TYPES.EXCERPT:
            case PROGRAMMING_TYPES.FULL_EPISODE:
            case PROGRAMMING_TYPES.MOVIE:
            case PROGRAMMING_TYPES.CLIP:
            case PROGRAMMING_TYPES.HIGHLIGHT:
            case PROGRAMMING_TYPES.TEASER_TRAILER:
            case PROGRAMMING_TYPES.SNEAK_PEAK:
            case PROGRAMMING_TYPES.WEB_EXCLUSIVE:
              RouterUtil.navigateToRoute(
                ROUTE.video,
                { entity: ROUTE_ENTITY.videoId, value: mpxGuid },
                { preventClickMetric: true }
              )
              break
            default:
              break
          }
        }
      },
    ]
  }
}
