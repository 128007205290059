import componentTypes from '../../types/componentTypes'
import Tiles from '../tiles'
import Collection from './Collection'

export default class Grid extends Collection {
  override mapItems(items: any) {
    return (
      items
        .filter(
          (item: any) => item != null && Object.values(componentTypes).indexOf(item.component) > -1
        )
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        .map((item: any) => new Tiles[item.component](item))
    )
  }
}
