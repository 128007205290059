import { Router, Storage } from '@lightningjs/sdk'
// @ts-expect-error TS(7016): Could not find a declaration file for module '@lig... Remove this comment to see the full error message
import { app } from '@lightningjs/sdk/src/Router/utils/router'

import {
  CONTENT_TYPE,
  DESTINATION_TYPES,
  PROGRAMMING_TYPES,
  REGISTRATION_REFERRERS,
  ROUTE,
  ROUTE_ENTITY,
  STORAGE_KEYS,
} from '../constants'
import { CtaItem } from '../components/types'
import { LiveStreamManager } from '../lib/LiveStreamManager'

class RouterUtil {
  static current() {
    return Router.getActiveRoute()
  }
  static isShowHomePage() {
    return RouterUtil.current() === ROUTE.showHome
  }
  static isHomePage() {
    return RouterUtil.current() === ROUTE.home
  }
  static isShowPage() {
    return RouterUtil.current() === ROUTE.shows
  }
  static getPrevRoute() {
    const history = Router.getHistory()
    if (history?.length === 0) return false
    return history[history.length - 1]?.hash
  }

  static getInternalPageParams(item: any) {
    let routeParams

    if (item.isLive) {
      return (routeParams = { assetMetadata: item?.obj?.data })
    }

    switch (item.destination) {
      case ROUTE.live:
        routeParams = { assetMetadata: item?.obj?.data }
        break
      default:
        routeParams = {}
        break
    }
    return routeParams
  }

  static isComingFromHomePage() {
    return this.getPrevRoute() === ROUTE.home
  }

  // Get data from the show home page (after navigating away) about selected video.
  static getPrevData() {
    const data = { Brand: 'None', Show: 'None', Season: 'None' }
    const history = Router.getHistory()
    if (history.length > 0) {
      const withBrand = history.filter((item) => item?.state?.Brand)
      if (withBrand.length > 0) {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        for (const [key, value] of Object.entries(withBrand[0].state)) {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          if (value) data[key] = value
        }
      }
    }
    return data
  }

  static checkGetRegistrationReferrer() {
    const prev = Router.getActiveRoute()
    if (!prev) return 'None'
    switch (prev) {
      case ROUTE.profile:
        return REGISTRATION_REFERRERS.MVPD
      // @ts-expect-error TS(2678): Type 'boolean' is not comparable to type 'string'.
      case prev.includes('activation'):
        return REGISTRATION_REFERRERS.VOD
      case ROUTE.live:
        return REGISTRATION_REFERRERS.LIVE
      // case 'favorites cta' What is this? Todo
      default:
        return 'None'
    }
  }
  static showsBrandRoutingData(brand: any, prevBrand: any, isCat = false, brandForCat = null) {
    const data = {
      path: ROUTE.shows,
      brand: isCat ? brandForCat : brand,
      category: isCat ? brand : null,
      pageName: {
        current: {
          name: `All Shows : ${brand}`,
          type: 'Shows',
        },
      },
    }
    if (prevBrand) {
      // @ts-expect-error TS(2339): Property 'previous' does not exist on type '{ curr... Remove this comment to see the full error message
      data.pageName.previous = {
        name: `All Shows : ${prevBrand}`,
        type: 'Shows',
      }
    }
    return data
  }

  static setAppState = (state = '') => {
    typeof app?._setState === 'function' && app._setState(state)
  }

  static navigateByDestinationType(item: CtaItem | undefined) {
    if (!item) return

    switch (item.destinationType) {
      case DESTINATION_TYPES.video:
        RouterUtil.navigateToRoute(
          ROUTE.video,
          {
            entity: ROUTE_ENTITY.videoId,
            value: item.destination,
          },
          { isOlympicsVideo: item?.isOlympics }
        )
        break
      case DESTINATION_TYPES.series:
        RouterUtil.navigateToRoute(ROUTE.showHome, {
          entity: ROUTE_ENTITY.urlAlias,
          value: item.urlAlias,
        })
        break
      case DESTINATION_TYPES.internalPage: {
        const params = item.isLive
          ? [ROUTE.live, RouterUtil.getInternalPageParams(item)]
          : [item.destination, RouterUtil.getInternalPageParams(item)]
        // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
        Router.navigate(...params)
        break
      }
      case DESTINATION_TYPES.playlist: {
        Storage.set(STORAGE_KEYS.PLAYLIST_MACHINE_NAME, item.playlistMachineName)
        RouterUtil.navigateToRoute(ROUTE.video, {
          entity: ROUTE_ENTITY.videoId,
          value: item.destination,
        })
        break
      }
      case DESTINATION_TYPES.singleEvent: {
        if (item.contentType === CONTENT_TYPE.FER || item.contentType === PROGRAMMING_TYPES.FER)
          RouterUtil.navigateToRoute(ROUTE.watch, {
            entity: ROUTE_ENTITY.pid,
            value: item.pid || item.destination,
          })
        break
      }
      default:
        break
    }
  }

  static navigateToLive = (
    fireAncestors: any,
    {
      channelId,
      streamAccessName,
      callSign = '',
    }: {
      channelId: string
      streamAccessName: string
      callSign?: string
    },
    preventClickMetric = false
  ) => {
    Storage.remove(STORAGE_KEYS.PREVIOUS_STREAM)
    LiveStreamManager.set(channelId, streamAccessName, callSign)
    if (Router.getActiveRoute() === ROUTE.live) fireAncestors('$showSLE')
    else Router.navigate(ROUTE.live, { preventClickMetric })
  }

  static navigateToRoute(route: any, { entity, value }: any, params = {}) {
    if (!entity || !value) return
    const currentHash = Router.getActiveHash()
    const newHash = route.replace(entity, value)
    Router.navigate(newHash, { ...params, reload: newHash === currentHash })
  }

  /**
   * Сheck if the first part of the string contains a name similar to the URL used by the player.
   * @param path
   * @returns {boolean}
   */
  static isPlayerRoute(path: any) {
    return /^(video|movie|show|fer|sle)\//.test(path)
  }

  static removePreviousRoute() {
    const previousHistory = Router.getHistory()
    previousHistory.pop()
    Router.setHistory(previousHistory)
  }
}

export default RouterUtil
