import { PlayerError } from '../../../components/error/PlayerError'

export enum StreamLoaderErrors {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  NO_CREDITS = 'NO_CREDITS',
  MISSING_DATA = 'MISSING_DATA',
  GEO = 'GEO',
  BLACKLIST = 'BLACKLIST',
  RETRANS = 'RETRANS',
  UNKNOWN = 'UNKNOWN',
  TEMP_PASS_REQUEST_FAILED = 'TEMP_PASS_REQUEST_FAILED',
  TEMP_PASS_EXPIRED = 'TEMP_PASS_EXPIRED',
  PARENTAL_CONTROLS = 'PARENTAL_CONTROLS',
  USER_CANCELLED = 'USER_CANCELLED',
}

export type StreamLoaderError = {
  handled?: boolean
  detail?: StreamLoaderErrors | PlayerError
  data?: any
}

const streamLoaderErrorFactory = (detail: StreamLoaderErrors, data: any) => ({ detail, data })

export const unauthenticatedError = (data = {}) =>
  streamLoaderErrorFactory(StreamLoaderErrors.UNAUTHENTICATED, data)

export const unauthorizedError = (data = {}) =>
  streamLoaderErrorFactory(StreamLoaderErrors.UNAUTHORIZED, data)

export const parentalControlsError = (data = {}) =>
  streamLoaderErrorFactory(StreamLoaderErrors.PARENTAL_CONTROLS, data)

export const tempPassExpiredError = streamLoaderErrorFactory(
  StreamLoaderErrors.TEMP_PASS_EXPIRED,
  {}
)

export const tempPassFailedError = streamLoaderErrorFactory(
  StreamLoaderErrors.TEMP_PASS_REQUEST_FAILED,
  {}
)

export const geoError = (error: any = {}) => streamLoaderErrorFactory(StreamLoaderErrors.GEO, error)

export const noCreditsError = (videoId?: string) =>
  streamLoaderErrorFactory(StreamLoaderErrors.NO_CREDITS, { videoId })

export const missingDataError = (error?: string) =>
  streamLoaderErrorFactory(StreamLoaderErrors.MISSING_DATA, error)

export const userCancelled = () =>
  streamLoaderErrorFactory(StreamLoaderErrors.USER_CANCELLED, undefined)

export const StreamLoaderPlayerErrorMap = {
  [StreamLoaderErrors.GEO]: PlayerError.GEO,
  [StreamLoaderErrors.BLACKLIST]: PlayerError.AUTHZ,
  [StreamLoaderErrors.RETRANS]: PlayerError.RETRANS,
  [StreamLoaderErrors.UNAUTHORIZED]: PlayerError.AUTHZ,
  [StreamLoaderErrors.PARENTAL_CONTROLS]: PlayerError.PARENTAL_CONTROLS,
  [StreamLoaderErrors.UNKNOWN]: PlayerError.UNKNOWN,
}
