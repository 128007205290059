import LabelsList from './LabelsList'

import { EVENTS } from '../../lib/analytics/types'
import { sendMetric } from '../../lib/analytics/Analytics'

export default class FilterLabelsList extends LabelsList {
  override selectFocused() {
    super.selectFocused()
    this.trackClick()
  }

  trackClick() {
    const name = this._strategy?.activeItem?.label
    sendMetric(EVENTS.CLICK, {
      name,
      shelf: { listTitle: 'None' },
    })
  }
}
