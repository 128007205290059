import mParticle from '@mparticle/web-sdk'

const trackLaunch = () => ({
  getAttributes: () => {},
  getCategory: () => mParticle.EventType?.UserPreference || 'UserPreference',
  type: 'Launch',
  injectGlobals: true,
})

export default trackLaunch
