import { Lightning, Utils } from '@lightningjs/sdk'

import Keyboard from '../keyboard/Keyboard'

import { COLORS, FONT_FACE } from '../../constants'

export default class SearchKeyboard extends Keyboard {
  setValue(val: any, action: any, reset: any) {
    this.changeValue(val, action, reset)
  }
}

class Key extends Lightning.Component {
  _label: any

  static override _template(): Record<string, any> {
    return {
      Background: {
        rect: true,
        w: (w: any) => w,
        h: (h: any) => h,
        color: COLORS.transparent,
      },
      Label: {
        mountX: 0.5,
        x: (w: any) => w / 2,
        mountY: 0.4,
        y: (h: any) => h / 2 - 3,
        text: { fontFace: FONT_FACE.roboto, fontSize: 40 },
      },
    }
  }

  set label(str) {
    this._label = str
    if (this.active) {
      this.tag('Label').text.text = str
    }
  }
  get label() {
    return this._label
  }

  _update() {
    if (!this.active) {
      return
    }
    this.patch({
      Label: { text: this._label },
    })
  }

  override _firstActive() {
    this._update()
  }

  override _focus() {
    this.tag('Background').patch({ color: COLORS.dark8 })
  }

  override _unfocus() {
    this.tag('Background').patch({ color: 'transparent' })
  }

  static get width() {
    return 40
  }
  static get height() {
    return 53
  }
}

class ImageKey extends Key {
  _img: any
  override _label: any
  static override _template() {
    return {
      ...super._template(),
      Img: {
        mountX: 0.5,
        x: (w: any) => w / 2,
        mountY: 0.4,
        y: (h: any) => h / 2 - 3,
      },
    }
  }

  override _update() {
    if (!this.active) {
      return
    }
    this.patch({
      Img: {
        // x: 5,
        src: Utils.asset(this._img),
      },
      Label: {
        alpha: 0,
        text: this._label,
      },
    })
  }

  set img(path: any) {
    this._img = path
  }

  static override get width() {
    return 62 + 10
  }
}

class SpaceKey extends ImageKey {
  static override _template() {
    return {
      ...super._template(),
      Img: {
        mountX: 0.5,
        x: (w: any) => w / 2,
        mountY: 0.4,
        y: (h: any) => h / 2 + 6,
      },
    }
  }
  static override get width() {
    return 100
  }
}

class ActionKey extends Key {
  static override _template() {
    return {
      Background: {
        w: (w: any) => w + 10,
        h: (h: any) => h,
        color: COLORS.transparent,
        rect: true,
      },
      Box: {
        w: 61,
        h: 32,
        y: 11,
        x: 5,
        color: COLORS.warmGray1,
        rect: true,
        shader: { type: Lightning.shaders.RoundedRectangle, radius: 3 },
      },
      Label: {
        mountX: 0.5,
        x: (w: any) => w / 2 + 5,
        mountY: 0.4,
        y: (h: any) => h / 2 - 1,
        color: COLORS.dark,
        text: { fontFace: FONT_FACE.light, fontSize: 23, letterSpacing: 3 },
      },
    }
  }

  override _focus() {
    this.patch({
      Background: {
        color: COLORS.dark8,
      },
      Label: {
        color: COLORS.mediumGray2,
      },
    })
  }

  override _unfocus() {
    this.patch({
      Background: {
        color: 'transparent',
      },
      Label: {
        color: COLORS.dark,
      },
    })
  }

  static override get width() {
    return 62
  }
}

export const searchConfig = {
  layouts: {
    ABC: [
      [
        'onSpace',
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
        'onDelete',
        'onClear:CLR',
        'onLayout:123',
      ],
    ],
    '123': [
      [
        'onSpace',
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '!',
        '@',
        '#',
        '.',
        '$',
        '%',
        '^',
        '&',
        '*',
        '(',
        ')',
        ':',
        ';',
        ',',
        '_',
        '-',
        'onDelete',
        'onClear:CLR',
        'onLayout:ABC',
      ],
    ],
  },
  offsets: {
    buttonLeft: 4,
    buttonTop: 10,
  },
  buttonTypes: {
    default: {
      type: Key,
      img: null,
    },
    onDelete: {
      type: ImageKey,
      img: 'images/keyboard/delete.png',
      marginRight: 10,
      marginLeft: 30,
    },
    onLayout: {
      type: ActionKey,
      marginRight: 16,
    },
    onSpace: {
      type: SpaceKey,
      img: 'images/keyboard/spacebar.png',
      marginRight: 30,
    },
    onClear: {
      type: ActionKey,
      img: 'images/keyboard/clearn-btn.png',
      marginRight: 16,
    },
  },
}
