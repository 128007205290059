import getVideoEndAttributes from '../attributes/getVideoEndAttributes'
import mParticle from '@mparticle/web-sdk'

const getEvent = () => ({
  getAttributes: getVideoEndAttributes,
  getCategory: () => mParticle.EventType.UserContent,
  type: 'Video End',
  injectGlobals: true,
})

export default getEvent
