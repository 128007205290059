import { Language } from '@lightningjs/sdk'

import { COLORS } from '../../../constants'
import LabelBadge from '../../badges/LabelBadge'

const FREE_VALUE = 'Free'

const wrapText = (words: string[], maxLength: number) => {
  let finalWords = Array(15).fill('\xa0').join('')
  let lines = 1
  words.forEach((word, i) => {
    if (
      finalWords.length + word.length + 1 < maxLength ||
      (lines > 1 && (finalWords.length + word.length + 1) % maxLength < maxLength)
    ) {
      finalWords += word + ' '
    } else if (i > 0 && word.length + 1 < maxLength) {
      finalWords += '\n' + word + ' '
      lines++
    } else {
      finalWords +=
        word.substring(0, maxLength - finalWords.length) +
        '\n' +
        word.substring(maxLength - finalWords.length) +
        ' '
      lines++
    }
  })

  return finalWords
}

export default (marginTop = 0) => {
  return (tile: any) => {
    const isFree = tile._item?.analytics?.liveEntitlement === FREE_VALUE
    if (isFree) {
      const titleWords = tile._getSubTitle()?.split(' ')

      tile.patch({
        Content: {
          TitleCard: {
            Title: {
              text: {
                wordWrap: true,
                maxLines: 2,
                text: wrapText(titleWords, 46),
                wordWrapWidth: 400,
              },
              Badge: {
                y: marginTop,
                type: LabelBadge,
                label: Language.translate('livePlayer-streamBadge-free'),
                backGroundColor: COLORS.white1,
                fontColor: COLORS.dark,
                fontSize: 18,
                widthSize: 64,
                heightSize: 20,
                heightSizeContainer: 30,
              },
            },
          },
        },
      })
    }
  }
}
