import { Track } from '@sky-uk-ott/core-video-sdk-js-core'
import { setSmooth } from '../../../../helpers'
import { ClosedCaptionsUtils } from '../../../../lib/ClosedCaptions/ClosedCaptionsUtils'

export const MainStateFactory = (base: any) =>
  class Main extends base {
    _main: any
    _captionsSettingsContainer: any

    $enter() {
      setSmooth(this._main, 'alpha', 1)
      setSmooth(this._captionsSettingsContainer, 'alpha', 0)
    }

    static _states() {
      return [
        class CCLanguagesList extends Main {
          _captionsAppearanceButton: any
          _audioLanguageList: any
          _captionsLanguageList: any
          _languageListIndex: number

          get focusItem() {
            return this.getChildItem(this._languageListIndex)
          }

          getChildItem(index = 0) {
            return this._captionsLanguageList.children[index]
          }

          _getFocused() {
            return this.focusItem || this
          }

          _handleUp() {
            if (this._languageListIndex > 0) {
              this._languageListIndex--
            }
          }

          _handleDown() {
            if (this._languageListIndex < this._captionsLanguageList.children.length - 1) {
              this._languageListIndex++
            } else if (this._audioLanguageList?.children?.length) {
              this._setState('Main.AudioLanguagesList')
            } else if (this._captionsAppearanceButton.alpha) {
              this._setState('Main.CaptionAppearanceButton')
            }
          }

          $valueChanged(value: any) {
            if (ClosedCaptionsUtils.getCCType !== value) {
              ClosedCaptionsUtils.setCCType(value)
              this._captionsLanguageList.children.forEach((button: any) => {
                button.selected = button.optionValue === value
              })
              this.fireAncestors('$ccLanguageUpdated', { value })
            }
          }
        },
        class AudioLanguagesList extends this {
          _audioLanguageList: any
          _captionsAppearanceButton: any
          _audioLanguageListIndex: number

          get focusItem() {
            return this.getChildItem(this._audioLanguageListIndex)
          }

          getChildItem(index = 0) {
            return this._audioLanguageList.children[index]
          }

          _getFocused() {
            return this.focusItem || this
          }

          _handleUp() {
            if (this._audioLanguageListIndex > 0) {
              this._audioLanguageListIndex--
            } else {
              this._setState('Main.CCLanguagesList')
            }
          }

          _handleDown() {
            if (this._audioLanguageListIndex < this._audioLanguageList.children.length - 1) {
              this._audioLanguageListIndex++
            } else if (this._captionsAppearanceButton.alpha) {
              this._setState('Main.CaptionAppearanceButton')
            }
          }

          $valueChanged(track: Track) {
            this._updateAudioLanguage(track)
          }
        },
        class CaptionAppearanceButton extends Main {
          _captionsAppearanceButton: any
          _audioLanguageList: any

          _getFocused() {
            return this._captionsAppearanceButton || this
          }

          _handleUp() {
            if (this._audioLanguageList?.children?.length) {
              this._setState('Main.AudioLanguagesList')
            } else {
              this._setState('Main.CCLanguagesList')
            }
          }

          _handleEnter() {
            this._setState('CaptionsSettings.Appearance.List')
          }
        },
      ]
    }
  }
