import { Language, Lightning, Router, Utils } from '@lightningjs/sdk'
import { COLORS, FONT_FACE } from '../../constants'
import RouterUtil from '../../util/RouterUtil'
import { RouterPage } from '../../../types/global'
import { getSettingsPageData } from '../../api/Api'
import { WithRequestError, WithRequestErrorState } from '../hoc/withRequestError'

class FAQ extends Lightning.Component<Lightning.Component.TemplateSpecLoose, RouterPage> {
  _data: any
  announce: any
  static override _template() {
    return {
      x: 80,
      y: 200,
      w: 1760,
      h: 810,
      rect: true,
      color: COLORS.dark5,

      PageTitleHolder: {
        x: 100,
        y: 78,
        Icon: {
          h: 40,
          w: 40,
          src: Utils.asset('images/settings/faq.png'),
        },
        Title: {
          x: 60,
          text: {
            text: Language.translate('faq'),
            fontSize: 30,
            fontFace: FONT_FACE.light,
          },
        },
      },

      Content: {
        y: 178,
        x: 233,

        Paragraph: {
          text: {
            fontFace: FONT_FACE.light,
            fontSize: 30,
          },
        },
      },
    }
  }

  async load() {
    try {
      const data = await getSettingsPageData('faq')
      this.apiData = data
      RouterUtil.setAppState('')
      this.active &&
        this.widgets.menu.collapse({
          subCategoryItem: this.data.title || Language.translate('faq'),
          hideProfile: true,
        })
    } catch (e) {
      RouterUtil.setAppState('')
      this._setState(WithRequestErrorState)
    }
  }

  override _firstActive() {
    RouterUtil.setAppState('Loading')
  }

  override _init() {
    this.stage.setClearColor(COLORS.dark)
  }

  override _inactive() {
    this.widgets.menu.expand()
  }

  override _handleBack(e: Event) {
    e.preventDefault()
    Router.back()
  }

  get data() {
    return this._data
  }

  set apiData(v: any) {
    this._data = v
    this.tag('Title').text.text = this.data.title || Language.translate('faq')
    this.tag('Paragraph').text.text = this.data.content[0].paragraph || ''
    this.announce = [this.tag('Title').text.text, this.tag('Paragraph').text.text]
  }

  static override _states() {
    return []
  }
}

export default WithRequestError(FAQ)
