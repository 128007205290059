import MParticleInterface from '../mParticleInterface'
import getAuthAttributes from '../attributes/getAuthAttributes'
import mParticle from '@mparticle/web-sdk'

export const preTrackEvent = () => (properties: any) =>
  MParticleInterface.setAuthAttributes(properties)

const getEvent = () => ({
  getAttributes: getAuthAttributes,
  getCategory: () => mParticle.EventType.UserPreference,
  type: 'MVPD Page Abandoned',
  injectGlobals: true,
  preTrackEvent: preTrackEvent(),
})

export default getEvent
