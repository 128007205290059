import { GqlFetchResult } from '../client/types'
import { VideosSectionPageQuery } from '../generated/types'

const videoSectionMapper = (page: GqlFetchResult<VideosSectionPageQuery> | null) => {
  const data = page?.data?.videosSection
  if (!data) return null
  return { data }
}

export default videoSectionMapper
