import { Lightning } from '@lightningjs/sdk'

export type Block = { w: number; h: number }
export type BlocksArray = Block[][]
const isBlocksArray = (input: any): input is BlocksArray => typeof input?.[0]?.[0] === 'object'

export default class ShimmerLoader extends Lightning.Component {
  _shimmer: Lightning.Component
  _loader: Lightning.types.Animation

  static override _template() {
    return {
      Shimmer: {},
    }
  }

  override _setup() {
    this._shimmer = this.tag('Shimmer')
    this._loader = this._shimmer.animation({
      duration: 2,
      repeat: -1,
      actions: [{ p: 'alpha', v: { 0: 0.8, 0.5: 0.4, 1: 0.8 } }],
    })
  }

  set item(blocks: BlocksArray | Block) {
    const getElement = (w: number, h: number, x: number, y: number) => ({
      mount: 0,
      x,
      y,
      w,
      h,
      alpha: 0.8,
      amount: 1,
      rect: true,
      shader: { type: Lightning.shaders.RoundedRectangle, fillColor: 0xff32343d, radius: 4 },
    })
    if (isBlocksArray(blocks)) {
      const gap = 2
      let rowY = 0
      let itemX = 0
      this.tag('Shimmer').patch(
        blocks.reduce(
          (acc, row, index) => ({
            ...acc,
            [`Row${index}`]: row.reduce((r: any, { w, h }, i) => {
              r[`Item${i}`] = getElement(w, h, itemX, rowY)
              if (i === row.length - 1) {
                itemX = 0
                rowY += h + gap
              } else {
                itemX += w + gap
              }
              return r
            }, {}),
          }),
          {}
        )
      )
    } else {
      this.tag('Shimmer').patch(getElement(blocks.w, blocks.h, 0, 0))
    }
  }

  override _active() {
    this._loader.start()
  }

  override _inactive() {
    this._loader.stop()
  }
}
