import { Language, Lightning } from '@lightningjs/sdk'
import TextButton from '../../../components/buttons/TextButton'
import {
  ALIGN_ITEMS,
  COLORS,
  FLEX_DIRECTION,
  FONT_FACE,
  JUSTIFY_CONTENT,
  SCREEN_SIZE,
  TEXT_ALIGN,
  VERTICAL_ALIGN,
} from '../../../constants'
import { EVENTS } from '../../../lib/analytics/types'
import { sendMetric } from '../../../lib/analytics/Analytics'

import { FastImg } from '../../../helpers'
import { QrModal as QrModalTypes } from '../../../graphql/generated/types'
import ModalManager from '../../../lib/ModalManager'
import { OLY_IMPOLICY } from '../../../constants/images'

export default class QrModal extends Lightning.Component {
  // @ts-expect-error'QrModalTypes' refers to a value, but is being used as a type here.
  _data: QrModalTypes['data']
  // @ts-expect-error'QrModalTypes' refers to a value, but is being used as a type here.
  _analytics: QrModalTypes['analytics']
  announce: string | undefined

  override _attach() {}
  // @ts-expect-error'QrModalTypes' refers to a value, but is being used as a type here.
  set data(data: QrModalTypes['data']) {
    this._data = data
  }

  get data() {
    return this._data
  }
  // @ts-expect-error'QrModalTypes' refers to a value, but is being used as a type here.
  set analytics(analytics: QrModalTypes['analytics']) {
    this._analytics = analytics
  }

  override _active() {
    this._trackModalLoad()
  }

  static override _template() {
    return {
      w: SCREEN_SIZE.width,
      h: SCREEN_SIZE.height,
      visible: false,
      rect: true,
      color: COLORS.dark,
      Background: {
        w: (w: any) => w,
        h: (h: any) => h,
      },
      Logo: {
        w: (w: any) => w,
        h: 225,
        y: 140,
        flex: {
          direction: FLEX_DIRECTION.column,
          alignItems: ALIGN_ITEMS.center,
        },
        ImageHolder: {
          w: 157,
          h: 170,
        },
      },
      Options: {
        w: (w: any) => w,
        h: 465,
        y: 350,
        Content: {
          w: (w: any) => w,
          h: 465,
          x: SCREEN_SIZE.width / 2,
          mountX: 0.5,
          ContentContainer: {
            w: (w: any) => w,
            h: (h: any) => h,
            flex: {
              direction: FLEX_DIRECTION.column,
              alignItems: ALIGN_ITEMS.center,
              justifyContent: JUSTIFY_CONTENT.center,
            },
            Image: {
              w: 225,
              h: 225,
              strokeWidth: 5,
              strokeColor: COLORS.white,
              rect: true,
              ImageHolder: {
                x: 5,
                y: 5,
              },
            },
            SubText: {
              y: 20,
              text: {
                fontSize: 24,
                textAlign: TEXT_ALIGN.center,
                fontFace: FONT_FACE.bold,
              },
            },
            Title: {
              y: 20,
              text: {
                textAlign: TEXT_ALIGN.center,
                fontSize: 40,
                fontFace: FONT_FACE.light,
                textColor: COLORS.white,
                verticalAlign: VERTICAL_ALIGN.middle,
                text: '',
                wordWrap: true,
                wordWrapWidth: 650,
              },
            },
            DismissLinkButton: {
              alpha: 1,
              w: 600,
              h: 65,
              y: 40,
              type: TextButton,
              radius: 29,
              fontSize: 26,
              fontFace: FONT_FACE.regular,
              focusFontColor: COLORS.dark,
              unfocusFontColor: COLORS.lightGray3,
              focusBackGroundColor: COLORS.lightGray3,
              unfocusBackgroundColor: COLORS.transparent,
              autoWidth: false,
              padding: 10,
              strokeWidth: 2,
              strokeColor: COLORS.white,
            },
          },
        },
      },
    }
  }

  override _init() {}
  // @ts-expect-error'QrModalTypes' refers to a value, but is being used as a type here.
  set modalData({ analytics, data }: QrModalTypes) {
    this.analytics = analytics
    this.data = data
    this._updateAccessibility()
    this._updateUi()
  }

  _updateAccessibility() {
    this.announce = this?.data?.description || undefined
  }

  _updateUi() {
    const data = this.data

    this.patch({
      alpha: 1,
      Background: {
        alpha: 1,
        texture: FastImg(data.backgroundImage).cover(1920, 1080, OLY_IMPOLICY.RECT_LARGE_1920_1080),
      },
      Logo: {
        ImageHolder: {
          texture: FastImg(data.logoImage).cover(157, 170, OLY_IMPOLICY.SQUARE),
        },
      },
      Options: {
        Content: {
          ContentContainer: {
            Title: {
              text: data.description,
            },
            Image: {
              ImageHolder: {
                texture: FastImg(data.qrCodeImage).cover(215, 215),
              },
            },
            SubText: {
              text: {
                text: Language.translate('olympics-qr-modal-helper') || data.qrCodeImageAltText,
              },
            },
            DismissLinkButton: {
              label: Language.translate('olympics-qr-modal-dismiss') || data?.dismissText,
            },
          },
        },
      },
    })
  }

  _trackModalLoad() {
    sendMetric(EVENTS.MODAL_LOAD, {
      modalName: 'Olympics Schedule QR',
      modalType: 'Olympics Schedule QR',
    })
  }

  _trackClick(trackName: string) {
    sendMetric(EVENTS.CLICK, {
      name: trackName,
    })
  }

  override _handleDown() {
    return
  }

  override _handleUp() {
    return
  }

  override _handleLeft() {
    return
  }

  override _handleRight() {
    return
  }

  override _handleBack() {
    ModalManager.close()
  }

  override _handleEnter() {
    const trackingName = 'No Thanks'

    this._trackClick(trackingName)
    ModalManager.close()
  }

  override _getFocused() {
    return this.tag('Options.Content.ContentContainer.DismissLinkButton') || this
  }
}
