import Lightning from '@lightningjs/sdk/src/Lightning'
import { RouterPage } from '../../../../types/global'
import { IShows } from '../types'
import { setSmooth } from '../../../helpers'
import ContentPosition from '../../../util/contentPosition'
import { ShowsStates } from '../../../constants'

export const LinksSelectableStateFactory = (
  base: Lightning.Component.Constructor<Lightning.Component<any, RouterPage> & IShows>
) =>
  class LinksSelectableGroup extends base {
    override _handleUp() {
      this._setState(ShowsStates.BrandLabelsList)
      ContentPosition.clearPositionForCurrentPage()
    }

    override _handleBack(e: any) {
      this.$focusedData(false)
      this._setState(ShowsStates.BrandLabelsList)
      e.preventDefault()
      e.stopPropagation()
    }

    override _getFocused() {
      return this.linksSelectableGroup || this
    }

    $focusedData(v: any) {
      this.widgets.menu.visible = !v
      setSmooth(this, 'y', v ? -230 : 0, {
        duration: 0.3,
        delay: 0,
      })
    }
    $scrolledRow(rowId: any) {
      if (rowId === 1) {
        setSmooth(this.brandLabelsList, 'alpha', 0, {
          duration: 0.3,
          delay: 0,
        })
        this.linksSelectableGroup.showItemLabels(0)
      } else if (rowId === 0) {
        setSmooth(this.brandLabelsList, 'alpha', 1, {
          duration: 0.3,
          delay: 0,
        })
        this.linksSelectableGroup.showItemLabels(1)
      } else if (rowId > 1 && this.brandLabelsList?.alpha === 1) {
        setSmooth(this.brandLabelsList, 'alpha', 0, {
          duration: 0,
          delay: 0,
        })
        this.linksSelectableGroup.showItemLabels(0)
      }
    }
  }
