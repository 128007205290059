import { Container } from '../../models'
import { COMPONENT_TYPES } from '../../../constants'
import { collectionComponents, createItems } from '../../../components/componentMaps'
import { lowercaseFirst } from '../../../helpers'

const createGrid = (stage: any, models: any) => {
  const grid = new Container({
    component: COMPONENT_TYPES.GRID,
    data: { items: models },
  })

  if (grid.data && grid.data.items.length) {
    return stage.c({
      type: collectionComponents.get(grid.component),
      tag: `${lowercaseFirst(grid.data.items[0].component)}`,
      items: createItems(grid.data.items),
      title: grid.title,
      meta: grid.meta,
      sponsorName: grid.data.sponsorName || '',
      ariaLabel: grid.ariaLabel,
    })
  }

  return null
}

export default async (stage: any, assets: any) => {
  return createGrid(stage, assets)
}
