import { COLORS, FONT_FACE, PROGRAMMING_TYPES } from '../../../../constants'

export default (tile: any) => {
  if (tile._item.programmingType !== PROGRAMMING_TYPES.FER || !tile._item.tertiaryTitle) return
  tile.patch({
    Content: {
      TitleCard: {
        Date: {
          y: 164,
          text: {
            text: tile._item.tertiaryTitle,
            fontFace: FONT_FACE.regular,
            fontSize: 26,
            textColor: COLORS.lightGray8,
            maxLines: 1,
            wordWrapWidth: 660,
          },
        },
      },
    },
  })
}
