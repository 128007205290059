import { Utils } from '@lightningjs/sdk'

import TextButton from './TextButton'

import { FONT_FACE } from '../../constants'

// @ts-expect-error TS(2417): Class static side 'typeof CCTextButton' incorrectl... Remove this comment to see the full error message
export default class CCTextButton extends TextButton {
  override _focusFontColor: any
  override _unfocusFontColor: any
  static override _template() {
    return {
      ...super._template(),
      Label: {
        x: 20,
        y: 11,
      },
      Icon: {
        mountX: 1,
        x: (w: any) => w - 20,
        y: 20,
      },
      Description: {
        x: 20,
        y: (h: any) => h - 9,
        mountY: 1,
      },
    }
  }

  set icon(icon: any) {
    this.patch({
      Icon: {
        src: Utils.asset(icon),
      },
    })
  }

  set description(v: any) {
    this.patch({
      Description: {
        text: {
          text: v,
          fontFace: FONT_FACE.light,
          fontSize: 20,
        },
      },
    })
  }

  override _focus() {
    super._focus()

    this.tag('Icon').patch({
      color: this._focusFontColor,
    })

    this.tag('Description').patch({
      color: this._focusFontColor,
    })
  }

  override _unfocus() {
    super._unfocus()

    this.tag('Icon').patch({
      color: this._unfocusFontColor,
    })

    this.tag('Description').patch({
      color: this._unfocusFontColor,
    })
  }
}
