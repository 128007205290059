import Variables from '../../graphql/Variables'
import { featuredRequest, mainRequest } from '../../api/requests'
import { createRequestConfig } from '../../lib/useRequest'

export const OlympicsHubRequestConfig = () =>
  createRequestConfig({
    query: mainRequest,
    variables: Variables.olympicsPageVariables(),
    reject: [(data) => !data?.sections],
  })

export const OlympicsHubFeaturedRequestConfig = () =>
  createRequestConfig({
    query: featuredRequest,
    variables: Variables.olympicsPageVariables(),
    reject: [(data) => !data],
  })
