import { COLORS, FONT_FACE } from '../../../../constants'

export const addSponsorsBadge = (item: any) => {
  if (item._item.sponsorLogo || item._item.sponsorLogoAltText) item._addSponsorBadge()
}

export const setBadge = (item: any) => {
  const badgeText = item.tag('Badge.BadgeText')
  badgeText.on('txLoaded', (texture: any) =>
    item.tag('Badge').patch({
      w: texture.source.w / texture.precision + 27,
      h: texture.source.h / texture.precision + 12,
    })
  )

  badgeText.patch({
    x: (w: any) => w / 2,
    y: (h: any) => h / 2,
    mount: 0.5,
    text: {
      fontFace: FONT_FACE.regular,
      textColor: COLORS.black,
      fontSize: 18,
      lineHeight: 18,
    },
  })

  item.tag('Badge').patch({
    rect: true,
    color: COLORS.white,
  })
}
