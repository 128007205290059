import { GraphQlClient } from '../../graphql/client'
import { EndCardPageQuery, EndCardPageQueryVariables } from '../../graphql/generated/types'
import { QueryName } from './bffQuery'

export default async (variables: EndCardPageQueryVariables) => {
  return await GraphQlClient.query<EndCardPageQuery>({
    query: QueryName.endCard,
    variables, // todo: maybe merge with default variables for this page
  })
}
