import { Language, Lightning } from '@lightningjs/sdk'

import SpinnerLoader from '../../../components/loader/SpinnerLoader'

import {
  addBaseTitleContainer,
  addSFVODTitleContainer,
  getSubtitleForLive,
  getTitles,
} from '../../../components/player/helpers/metadata'
import { checkAddClipToVideoTitles, FastImg } from '../../../helpers'
import {
  COLORS,
  CONTENT_TYPE,
  FONT_FACE,
  PROGRAMMING_TYPES,
  SCREEN_SIZE,
  VERTICAL_ALIGN,
} from '../../../constants'
import { Program } from '../../../store/PlayerStore/PlayerStore'
import { SingleStream } from '../../../store/PlayerStore/actions'
import { OLY_IMPOLICY } from '../../../constants'
import ModalManager, { ModalTypes } from '../../../lib/ModalManager'
import { PLAYER_TYPE, PlayerFactorySingleton } from '../../../player/core/PlayerFactory'

export const openPlayerLoader = (stream?: any, program?: any, buffering = false) => {
  if (ModalManager._activeModal && ModalManager._activeModal === ModalTypes.UPCOMING)
    ModalManager.close()
  if (ModalManager._activeModal && ModalManager._activeModal !== ModalTypes.PLAYER_LOADER) return

  const isBuffering = buffering || PlayerFactorySingleton.player(PLAYER_TYPE.MAIN)?.isBuffering
  const dataModal = {
    data: {
      program: program,
      stream: stream,
    },
    loaderText: Language.translate(isBuffering ? 'buffering' : 'loading'),
  }

  if (ModalManager._activeModal) ModalManager.update(ModalTypes.PLAYER_LOADER, dataModal)
  else ModalManager.open(ModalTypes.PLAYER_LOADER, dataModal)
}

export default class PlayerLoader extends Lightning.Component {
  static override _template() {
    return {
      w: SCREEN_SIZE.width,
      h: SCREEN_SIZE.height,
      rect: true,
      color: COLORS.black,
      Image: {
        alpha: 0.6,
      },
      ImageOverLay: {
        w: SCREEN_SIZE.width,
        h: SCREEN_SIZE.height,
        rect: true,
        colorTop: COLORS.transparent,
        colorLeft: COLORS.transparent,
        colorRight: COLORS.black1,
        colorBottom: COLORS.black2,
      },
      Loader: {
        x: (w: any) => w / 2,
        y: (h: any) => h / 2,
        mount: 0.5,
        type: SpinnerLoader,
      },
      TitleContainer: {
        x: 115,
        y: 898,
        Title: {
          text: {
            textColor: COLORS.white,
            verticalAlign: VERTICAL_ALIGN.middle,
            fontSize: 58,
            fontFace: FONT_FACE.light,
            maxLines: 1,
            wordWrapWidth: 1750,
          },
        },
        Clip: {
          flex: {},
          flexItem: { marginTop: 4 },
          y: 76,
          Icon: {},
        },
        SecondaryTitle: {
          y: 76,
          text: {
            textColor: COLORS.white,
            verticalAlign: VERTICAL_ALIGN.middle,
            fontSize: 26,
            fontFace: FONT_FACE.regular,
            maxLines: 1,
            wordWrapWidth: 1534,
          },
        },
      },
    }
  }

  applyData(title: any, secondaryTitle: any, data: any) {
    const titlesData = {
      Image: {
        texture: FastImg(data.image).contain(
          SCREEN_SIZE.width,
          SCREEN_SIZE.height,
          OLY_IMPOLICY.RECT_LARGE_1920_1080
        ),
      },
      TitleContainer: (data.programmingType === PROGRAMMING_TYPES.SFVOD
        ? addSFVODTitleContainer
        : addBaseTitleContainer)({ title, secondaryTitle }, data),
    }
    this.patch(checkAddClipToVideoTitles(data, titlesData))
  }

  clear() {
    this.patch({
      Image: {
        texture: null,
      },
      TitleContainer: {
        Logo: undefined,
        Title: {
          text: {
            text: '',
          },
        },
        SecondaryTitle: {
          text: {
            text: '',
          },
        },
        Description: {
          text: {
            text: '',
          },
        },
        Clip: {
          Icon: undefined,
        },
      },
    })
  }

  set modalData(data: Record<string, any>) {
    this.clear()
    Object.keys(data).forEach((key) => {
      // @ts-expect-error Assigning values programatically
      this[key] = data[key]
    })
  }

  set loaderText(loaderText: any) {
    this.tag('Loader').loaderText = loaderText
  }

  set data(data: { program: Program; stream: SingleStream }) {
    if (!data) return
    this.clear()
    let title
    let secondaryTitle
    if (data.stream?.contentType === CONTENT_TYPE.VOD) {
      const vodTitles = getTitles(data.stream)
      title = vodTitles.title
      secondaryTitle = vodTitles.secondaryTitle
    } else if (data.stream?.programmingType === PROGRAMMING_TYPES.FER) {
      title = data.stream?.headerTitle || Language.translate('program_unavailable')
      secondaryTitle = data.stream.title || ''
    } else if (data.stream?.programmingType === PROGRAMMING_TYPES.SLE) {
      title = data.stream.title || Language.translate('program_unavailable')
      secondaryTitle = data.program?.league || data.program?.sport || ''
    } else {
      title =
        data.stream?.title ||
        data.program?.programTitle ||
        Language.translate('program_unavailable')
      secondaryTitle = getSubtitleForLive(data.program, data.stream) || ''
    }
    if (data.stream) this.applyData(title || '', secondaryTitle || '', data.stream)
  }
}
