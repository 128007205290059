import Styler from '../lib/Styler'
import ItemWrapper from './items/ItemWrapper'
import BaseCollection from './BaseCollection'

import { setSmooth } from '../helpers'
import ContentPosition, { ScrollElementType } from '../util/contentPosition'
import { COMPONENT_TYPES } from '../constants'
import { getTimerToRemoveItemWhenEnd } from '../helpers'
import { debounce } from 'lodash'

export default class Grid extends BaseCollection {
  _itemsPerRow: any
  _moreItems: any
  _noOfRowsToScroll: any
  override _index = 0
  sideFilter = false
  _rows: number[] = [] // Mapping of item indexes to row values
  _getMoreItems = debounce((data) => this.fireAncestors('$getMoreItems', data), 500)

  static override _template() {
    return {
      Items: {
        forceZIndexContext: true,
        boundsMargin: [500, 100, 500, 100],
      },
    }
  }

  override _active() {
    const position = ContentPosition.getPositionForCurrentPage()
    if (position?.type === ScrollElementType.GRID) {
      this.setIndex(position.content)
      this.fireAncestors('$scrollRequested', { position })
    }
  }

  override create({ items = [] }, initialIndex = 0) {
    const style = this.getStyle()
    this.itemsPerRow = style.itemsPerGridRow
    this.noOfRowsToScroll = style.noOfGridRowsToScroll
    this.sideFilter = style.sideFilter || false

    const children = items?.map((item: any, index) => {
      const indexToRender = index + initialIndex
      const row = Math.floor(indexToRender / this.itemsPerRow)
      const indexInRow = Math.floor(indexToRender % this.itemsPerRow)
      this._rows[indexToRender] = row

      return {
        type: ItemWrapper,
        itemType: item.itemType,
        indexToRender,
        item: item.item,
        x: indexInRow * style.item.w,
        y: row * style.item.h,
      }
    })

    children.forEach((item: any, index: any) => {
      if (item.item.component !== COMPONENT_TYPES.UPCOMING_LIVE_TILE) {
        return
      }
      getTimerToRemoveItemWhenEnd(
        item.item.endTime,
        this._removeUpcomingLiveSlideWhenEnds.bind(this),
        {
          itemToRemove: item,
          index,
        }
      )
    })

    return children
  }

  set itemsPerRow(itemsPerRow) {
    this._itemsPerRow = itemsPerRow
  }

  get itemsPerRow() {
    return this._itemsPerRow || 5
  }

  set noOfRowsToScroll(noOfRows) {
    this._noOfRowsToScroll = noOfRows
  }

  get noOfRowsToScroll() {
    return this._noOfRowsToScroll || 1
  }

  get lastRowNumber() {
    return this._rows[this._rows.length - 1]
  }

  set moreItems(moreItems: any) {
    if (
      (moreItems && this._rows.length < this.itemsPerRow * 2) ||
      this._rows[this?._index] === this._rows[this._rows.length - 1]
    ) {
      // Load enough items for at least 2 full rows
      // or Load more if index is on the last row
      // - some results don't have a full rows worth  of data and we still end up focused on the last row
      // - so lets fetch again until we have additional rows of tiles
      this._getMoreItems(moreItems?.data?.queryVariables)
    }
    this._moreItems = moreItems
  }

  override get items(): any {
    return this._items
  }

  override set items(v: any) {
    this.tag('Items').childList.clear()
    this.stage.gc()
    this._items = v
    this.tag('Items').patch({
      children: this.create({ items: this._items }),
    })
  }

  _removeUpcomingLiveSlideWhenEnds({ itemToRemove, index }: any) {
    const itemFromArray = this._items[index]
    const indexToDelete = this._items.indexOf(itemFromArray)
    this._removeItemAt(itemToRemove)
    this._items.splice(indexToDelete, 1)
  }

  _removeItemAt(itemToRemove: any) {
    this.tag('Items').childList.remove(itemToRemove)
    this._repositionItems()
    this.setIndex(this.index)
  }

  _repositionItems() {
    const style = this.getStyle()
    this.tag('Items').childList.forEach((item: any, index: any) => {
      const row = Math.floor(index / this.itemsPerRow)
      const indexInRow = Math.floor(index % this.itemsPerRow)
      item.patch({
        x: indexInRow * style.item.w,
        y: row * style.item.h,
      })
    })
  }

  override _handleLeft() {
    if (this._index === 0) return false
    if (this.sideFilter && this._index % this._itemsPerRow === 0) return false
    this._index = this._index - 1
    this.setIndex(this._index)
  }

  override _handleRight() {
    if (this._index === this.items.length - 1) return
    this._index = this._index + 1
    this.setIndex(this._index)
  }

  override _handleUp() {
    let index = this._index

    if (index - this.itemsPerRow >= 0) {
      index -= this.itemsPerRow
    } else {
      return false
    }
    this.setIndex(index)
  }

  override _handleDown() {
    let index = this._index
    if (index + this.itemsPerRow > this._items.length - 1) {
      return false
    } else {
      index += this.itemsPerRow
    }

    if (
      this?.lastRowNumber &&
      this._moreItems &&
      Math.ceil((index + 1) / this.itemsPerRow) >= this.lastRowNumber
    ) {
      this._getMoreItems(this._moreItems?.data?.queryVariables)
    }

    this.setIndex(index)
  }
  override _handleBack() {
    this.setIndex(0)
    return false
  }

  override _getFocused() {
    return this.activeItemWrapper || this
  }

  override get activeItemWrapper() {
    return this.tag('Items').children[this._index]
  }

  setIndex(index: any) {
    //if the row isnt available, reset index to 0 to avoid getting stuck on a row we don't have
    if (this._rows[index] !== undefined) this._index = index
    else this._index = 0
    this._scrollToRow(this._rows[this._index])
  }

  _scrollToRow(row: any) {
    const style = Styler.getTag(this.getTag(this))
    const noOfRowsToScroll = this._noOfRowsToScroll

    setSmooth(
      this.tag('Items'),
      'y',
      row > noOfRowsToScroll - 1 ? (row - (noOfRowsToScroll - 1)) * -style.item.h : 0,
      {
        duration: 0.3,
        delay: 0,
      }
    )

    this.fireAncestors('$scrolledRow', row)
  }

  $getCurrentScrollPosition() {
    return {
      type: ScrollElementType.GRID,
      row: this._rows[this._index],
      content: this._index,
    }
  }
}
