import algolia from '../algolia/algolia'

import { FALLBACK_TIMEZONE } from '../../constants'

export const getFERDates = async (page: any) => {
  try {
    let result = await algolia.replays.getDates(null, page)
    // Fallback if users device timezone is not supported
    if (!result.dates) result = await algolia.replays.getDates(FALLBACK_TIMEZONE, page)
    return result
  } catch (_) {
    // fail silently
  }
}
