import { GraphQlClient } from '../../graphql/client'
import { PaginatedShelfQuery, PaginatedShelfQueryVariables } from '../../graphql/generated/types'
import paginatedShelf from '../../graphql/mappers/paginatedShelf'
import rsnFilter from '../../graphql/filter/rsnFilter'
import { QueryName } from './bffQuery'

export default async (variables: PaginatedShelfQueryVariables) => {
  return await GraphQlClient.query<PaginatedShelfQuery>({
    query: QueryName.paginatedShelf,
    variables,
  })
    .then(paginatedShelf)
    .then(rsnFilter)
}
