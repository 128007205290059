import { IPlayerEvent } from './IPlayerEvent'

export class AudioTrackEvent extends IPlayerEvent {
  private readonly _trackId: string | number
  constructor(trackId: string | number) {
    super()
    this._trackId = trackId
  }

  get trackId(): string | number {
    return this._trackId
  }
}
