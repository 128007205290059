import mParticle from '@mparticle/web-sdk'
import getExitAttributes from '../attributes/getExitAttributes'

const trackExit = () => ({
  getAttributes: getExitAttributes,
  getCategory: () => mParticle.EventType?.Navigation || 'Navigation',
  type: 'Exit',
  injectGlobals: true,
})

export default trackExit
