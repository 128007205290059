import { MPARTICLE_DEFAULT_ATTR_VALUE, ENTITLEMENT } from '../../../../constants'

export const getEndCardRecommendationsAttributes = ({
  recommendation,
  recommendationAlternate1,
}: any) => {
  return {
    'Recommendation Show': recommendation?.show,
    'Recommendation Sport': recommendation?.sport,
    'Recommendation League': recommendation?.league,
    'Recommendation Video ID': recommendation?.mpxGuid,
    'Recommendation Video Type': recommendation?.videoType,
    'Recommendation Episode Title': recommendation?.episodeTitle,
    'Recommendation Entitlement': recommendation?.locked ? ENTITLEMENT.ENTITLED : ENTITLEMENT.FREE,
    'Recommendation Brand': recommendation?.brand,
    'End Card Recommendation Type': recommendation?.recommendationType,
    'Alt 1 Recommendation Show': recommendationAlternate1?.show,
    'Alt 1 Recommendation Sport': recommendationAlternate1?.sport,
    'Alt 1 Recommendation League': recommendationAlternate1?.league,
    'Alt 1 Recommendation Video ID': recommendationAlternate1?.mpxGuid,
    'Alt 1 Recommendation Video Type': recommendationAlternate1?.videoType,
    'Alt 1 Recommendation Episode Title': recommendationAlternate1?.episodeTitle,
    'Alt 1 Recommendation Entitlement': recommendationAlternate1?.locked
      ? ENTITLEMENT.ENTITLED
      : ENTITLEMENT.FREE,
    'Alt 1 Recommendation Brand': recommendationAlternate1?.brand,
    'Alt 1 End Card Recommendation Type': recommendationAlternate1?.recommendationType,
    'Alt 2 Recommendation Show': MPARTICLE_DEFAULT_ATTR_VALUE,
    'Alt 2 Recommendation Sport': MPARTICLE_DEFAULT_ATTR_VALUE,
    'Alt 2 Recommendation League': MPARTICLE_DEFAULT_ATTR_VALUE,
    'Alt 2 Recommendation Video ID': MPARTICLE_DEFAULT_ATTR_VALUE,
    'Alt 2 Recommendation Video Type': MPARTICLE_DEFAULT_ATTR_VALUE,
    'Alt 2 Recommendation Episode Title': MPARTICLE_DEFAULT_ATTR_VALUE,
    'Alt 2 Recommendation Entitlement': MPARTICLE_DEFAULT_ATTR_VALUE,
    'Alt 2 Recommendation Brand': MPARTICLE_DEFAULT_ATTR_VALUE,
    'Alt 2 End Card Recommendation Type': MPARTICLE_DEFAULT_ATTR_VALUE,
  }
}

export const getEndCardCommonAttributes = ({ video, endCardTime, shelf }: any) => {
  return {
    'Video Duration': video.durationInMilliseconds,
    'End Card Time': endCardTime,
    'Playlist Name': shelf?.playlistMachineName,
    'Playlist Position': shelf?.playlistPosition,
  }
}
