import TextBorderButton from './TextBorderButton'

export default class FocusScaledButton extends TextBorderButton {
  _focusFontSize: any
  _focusHeight: any
  _focusWidth: any
  override _fontSize: any
  override _isFocus: any
  override _label: any
  _unfocusHeight: any
  _unfocusWidth: any
  override announce: any
  _enterCallback = () => {}

  set focusFontSize(focusFontSize: any) {
    this._focusFontSize = focusFontSize
  }

  set focusHeight(focusHeight: any) {
    this.h = focusHeight
    this._focusHeight = focusHeight
  }

  set focusWidth(focusWidth: any) {
    this.w = focusWidth
    this._focusWidth = focusWidth
  }

  set unfocusHeight(unfocusHeight: any) {
    this.h = unfocusHeight
    this._unfocusHeight = unfocusHeight
  }

  set unfocusWidth(unfocusWidth: any) {
    this.w = unfocusWidth
    this._unfocusWidth = unfocusWidth
  }

  set enterCallback(enterCallback: any) {
    if (typeof enterCallback !== 'function') return
    this._enterCallback = enterCallback
  }

  override _handleEnter() {
    this._enterCallback()
  }

  override _captureEnter() {
    this._enterCallback()
  }

  override _setAnnounce() {
    this.announce = `${this._label}, Button`
  }

  override _updateButton() {
    const [height, fontSize] = this._isFocus
      ? [this._focusHeight, this._focusFontSize]
      : [this._unfocusHeight, this._fontSize]
    if (!this._autoWidth || (this._focusWidth && this._unfocusWidth)) {
      this.w = this._isFocus ? this._focusWidth : this._unfocusWidth
    }
    this.h = height
    super._updateButton()

    this.patch({
      Label: {
        text: {
          fontSize: fontSize,
        },
      },
    })
  }
}
