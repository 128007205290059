import mParticle from '@mparticle/web-sdk'
import getMarketingModuleVideoPreviewAttributes from '../attributes/getMarketingModuleVideoPreviewAttributes'

const trackMarketingModuleVideoPreview = () => ({
  getAttributes: getMarketingModuleVideoPreviewAttributes,
  getCategory: () => mParticle.EventType?.UserContent,
  type: 'Video Preview',
  injectGlobals: true,
})

export default trackMarketingModuleVideoPreview
