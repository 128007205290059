import { Lightning, Router } from '@lightningjs/sdk'

import LongScrollPage from '../LongScrollPage'
import { LongScroll } from '../../components'

import { FeaturedSpawner, SectionsSpawner } from '../../api/spawners'
import { EVENTS } from '../../lib/analytics/types'
import { COLORS, MainStates } from '../../constants'
import { sendMetric } from '../../lib/analytics/Analytics'
import { BffPage } from '../../graphql/mappers/bffPage'
import { ScrollRequestedArgs } from '../../components/longScroll/types'
import { EmptyResponse, FailedResponse, RejectedResponse, useRequest } from '../../lib/useRequest'
import { MainPageFeaturedRequestConfig, MainPageRequestConfig } from './request'
import { WithRequestError, WithRequestErrorState } from '../hoc/withRequestError'
import { setUserAttributes } from '../../lib/analytics/Amplitude/Amplitude'
import { getMpid } from '../../helpers'

enum SCROLLER_POSITIONS {
  FEATURED = 801,
  SCROLLER = 175,
}

class Main extends LongScrollPage {
  sections?: BffPage['sections']
  protected mainRequestConfig = MainPageRequestConfig
  protected featuredRequestConfig = MainPageFeaturedRequestConfig

  static override _template() {
    return {
      ...super._template(),
      Featured: {
        type: Lightning.Component,
      },
      Scroller: {
        type: LongScroll,
        scrollFocus: 0.5,
        x: 83,
        y: SCROLLER_POSITIONS.FEATURED,
        transitions: {
          y: {
            duration: 0.3,
            timingFunction: 'cubic-bezier(0.20, 1.00, 0.80, 1.00)',
          },
        },
      },
    }
  }

  override _setup() {
    super._build()
    this.widgets.loader.setSmooth('alpha', 1)
  }

  override _attach() {
    this.stage.setClearColor(COLORS.dark)
  }

  $firstShelfImpression() {
    this.tag('Scroller').fireFirstShelfImpression()
  }

  async load(): Promise<void> {
    try {
      const data = (await useRequest(this.mainRequestConfig()).fetch()) as BffPage
      this.sections = data.sections
      await this.loadFeatured()
      this.containers = await SectionsSpawner(this.stage, data.sections)
      // Make Amplitude call
      setUserAttributes(getMpid(), data?.ldStateFlag?.user_properties?.['Server Feature Flags'])
      sendMetric(EVENTS.SHELVES_LOAD, {
        params: data.sections,
        featured: data.featured,
      })
    } catch (err) {
      if (err instanceof RejectedResponse || err instanceof FailedResponse) {
        this._setState(WithRequestErrorState)
      }
      if (err instanceof EmptyResponse) {
        return
      }
    }
  }

  async loadFeatured() {
    try {
      const featured = await useRequest(this.featuredRequestConfig()).fetch()
      const spawnedFeatured = FeaturedSpawner(this.stage, featured)
      if (!spawnedFeatured) return
      this.patch({ Featured: spawnedFeatured })
      if (this._getState() !== MainStates.Scroller) {
        this._setState(MainStates.Featured, [{ immediate: true }])
      } else {
        this.hideFeatured()
      }
    } catch {
      this._setState(MainStates.Scroller, [{ immediate: true }])
      this.removeFeatured()
    } finally {
      this.widgets.loader.setSmooth('alpha', 0)
    }
  }

  get featured() {
    return this.tag('Featured')
  }

  set containers(v: any) {
    this.tag('Scroller').add(v)
  }

  removeFeatured(): void {
    this.childList.remove(this.tag('Featured'))
  }

  hideFeatured() {
    this.stage.setClearColor(COLORS.dark)
    this.widgets.loader.setSmooth('alpha', 0)
    this.tag('Featured').alpha = 0
  }

  override $scrollRequested(args: ScrollRequestedArgs) {
    if (this.tag('Featured')) this.tag('Featured').alpha = 0

    if (
      this._getState() === MainStates.Scroller &&
      this.tag('Scroller')?.y !== SCROLLER_POSITIONS.SCROLLER
    )
      this.tag('Scroller').patch({ y: SCROLLER_POSITIONS.SCROLLER })

    super.$scrollRequested(args)
  }

  $setFeaturedIndex(index: any) {
    if (this.tag('Featured')?.totalSlides) this.tag('Featured').setSlideByIndex(index)
  }

  $hideMenuBackground(): void {
    this.widgets.menu?.hideBackground()
  }

  $showMenuBackground(): void {
    this.widgets.menu?.showBackground()
  }

  static override _states() {
    return [
      class Featured extends this {
        override $enter(_states: { prevState: string }, args: { immediate?: boolean }) {
          this._setBackgroundColor(COLORS.black)
          if (args?.immediate) this.tag('Scroller').patch({ y: SCROLLER_POSITIONS.FEATURED })
          else this.tag('Scroller').setSmooth('y', SCROLLER_POSITIONS.FEATURED)

          this.tag('Featured').alpha = 1
          this.widgets.menu.setSmooth('alpha', 1)
        }
        override _handleUp() {
          Router.focusWidget('Menu')
        }
        override _handleBack(e: any) {
          Router.focusWidget('Menu')
          e.preventDefault()
          e.stopPropagation()
        }
        override _handleDown() {
          if (this.tag('Scroller').itemsLength) {
            this._setState(MainStates.Scroller)
          }
        }
        override _getFocused() {
          return this.tag('Featured') || this
        }
      },
      class Scroller extends this {
        override $enter(_states: { prevState: string }, args: { immediate?: boolean }) {
          this._setBackgroundColor(COLORS.dark)
          const scroller = this.tag('Scroller')
          // for some reason if we use setSmooth with 0 value it invokes with delay and works wrong
          if (args?.immediate) scroller.patch({ y: SCROLLER_POSITIONS.SCROLLER })
          else scroller.setSmooth('y', SCROLLER_POSITIONS.SCROLLER)
          // Fire shelf impression for the first time.
          this.tag('Scroller')._impressionIncrement(1)
          !this.featured ?? this.widgets.menu.setSmooth('alpha', 0)
        }
        override _inactive() {
          this.widgets.menu.setSmooth('alpha', 1)
        }
        override _handleUp() {
          this.featured ? this._setState(MainStates.Featured) : Router.focusWidget('Menu')
        }
        override _getFocused() {
          return this.tag('Scroller') || this
        }
        override _handleBack(e: any) {
          this.tag('Scroller').setIndex(0)
          this._setState(MainStates.Featured)
          e.preventDefault()
          e.stopPropagation()
        }
      },
    ]
  }
}

export default WithRequestError(Main)
