import { COLORS } from '../../../constants'
import UserInteractionsStoreSingleton from '../../../store/UserInteractions'

export default (initialPositionY = 0, hideIfWatched = false) => {
  return (tile: any) => {
    const percentViewed = UserInteractionsStoreSingleton.getPercentViewed(tile._item.v4ID)
    const hasProgress: boolean = percentViewed < 0.95 && percentViewed > 0.0
    const isWatched: boolean = !hideIfWatched && percentViewed >= 0.95
    const width = tile.constructor?.width

    if (hasProgress || isWatched) {
      const currentProgressWidth = isWatched ? width : width * percentViewed

      tile._addProgress({
        y: initialPositionY - 12,
        totalProgressWidth: width,
        totalProgressColor: COLORS.charcoalGrey,
        currentProgressWidth,
        currentProgressColor: COLORS.white,
      })
    }
  }
}
