import Announce from './Announce'

export default class BaseAnnounce extends Announce {
  private _utterance!: SpeechSynthesisUtterance

  async speak(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._utterance = new SpeechSynthesisUtterance(this._phrase)
      this._utterance.onend = (): void => resolve()
      this._utterance.onerror = (e): void => {
        switch (e.error) {
          case 'interrupted':
          case 'canceled':
            // Cancel or interrupt error (ignore)
            break
          default:
            reject(e)
        }
      }
      window.speechSynthesis.speak(this._utterance)
      window.speechSynthesis.resume()
    })
  }
}
