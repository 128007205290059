import { BehaviorSubject } from 'rxjs'
import { PlayerStatus } from '../PlayerStatus'
import { PlayerStatusEvent } from './PlayerStatusEvent'
import { PlatcoPlayerEvents } from '.'

export class MutablePlayerEventStream {
  _eventRelay = new BehaviorSubject<PlatcoPlayerEvents>(
    new PlayerStatusEvent(PlayerStatus.UNKNOWN, null)
  )

  publish(value: PlatcoPlayerEvents) {
    this._eventRelay.next(value)
  }

  events(): BehaviorSubject<PlatcoPlayerEvents> {
    return this._eventRelay
  }
}
