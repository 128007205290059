import { Language, Lightning } from '@lightningjs/sdk'
import ModalManager, { ModalTypes } from '../../../../../src/lib/ModalManager'

import GuideShimmerLoader from '../ChannelsGuide/GuideShimmerLoader'
import Filters from './Filters'
import Content from './Content'
import { COMPONENT_TYPES, FiltersStates } from '../../../../constants'
import {
  ComponentFragment,
  ComponentsForPlaceholdersQueryVariables,
  ItemLabelsConfigItemFragment,
  MessageFragment,
  PlaceholderSectionFragment,
} from '../../../../graphql/generated/types'

import { createViewAllCta } from './filtersViewAllCta'
import { RejectedResponse, useRequest } from '../../../../lib/useRequest'
import { SectionsSpawner } from '../../../../api/spawners'
import { FiltersGuideRequestConfig } from './request'
import { Maybe } from 'graphql/jsutils/Maybe'

export const FilterDelegateTypes = {
  replayTile: 'replayTileLiveGuide',
}

export default class FiltersGuide extends Lightning.Component {
  _shimmerLoader: GuideShimmerLoader
  _filters: Filters
  _content: Content
  _items: Maybe<ComponentFragment>[]

  static override _template() {
    return {
      clipping: true,
      w: (w: number) => w,
      h: 868,
      ShimmerLoader: {
        type: GuideShimmerLoader,
        x: 80,
      },
      FiltersHolder: {
        y: 20,
        w: 376,
        type: Filters,
      },
      ContentHolder: {
        visible: false,
        x: 520,
        y: 20,
        type: Content,
      },
    }
  }

  override _init() {
    this._shimmerLoader = this.tag('ShimmerLoader')
    this._filters = this.tag('FiltersHolder')
    this._content = this.tag('ContentHolder')
  }

  set items(items: Maybe<ComponentFragment>[]) {
    this._items = items
    this.loadFilterData()
  }

  get items() {
    return this._items
  }

  set filters(filters: ItemLabelsConfigItemFragment[] | null | undefined) {
    if (!filters) return
    this._filters.filters = filters
    this._filters?.selectFocused()
    this._shimmerLoader.visible = false
    this._setState(FiltersStates.Content)
  }

  override _getFocused() {
    return this._filters || this
  }

  resetFilterGuide() {
    this._content?._container?.setIndex(0)
    this._shimmerLoader.visible = true
    this._content.visible = false
  }

  async loadFilterData() {
    this.resetFilterGuide()
    try {
      const currentItem = this.items[this._filters.currentItem] as PlaceholderSectionFragment
      const currentItemData = currentItem.data
      const variables = currentItemData.queryVariables as ComponentsForPlaceholdersQueryVariables
      const data = await useRequest(FiltersGuideRequestConfig(variables)).fetch()
      const component = data?.components[0]
      if (component && component?.component === COMPONENT_TYPES.STACK_GROUP) {
        const items = 'items' in component.data && component.data.items
        const viewAllCtaData = 'viewAllCta' in component.data && component?.data?.viewAllCta?.data
        if (!items) return
        const sections = await SectionsSpawner(this.stage, items as any, FilterDelegateTypes)
        if (!sections?.length) throw data
        if (!!viewAllCtaData) {
          const cta = this.stage.c(this._createViewAllCta(viewAllCtaData))
          sections.push(cta)
        }
        this._content.sections = sections
      }
    } catch (e) {
      if (e instanceof RejectedResponse) {
        const d = (e.data?.components[0] as MessageFragment | undefined)?.data
        const title = d ? d.textRow1 : Language.translate('error_filters_title')
        const message = d ? d.textRow2 : Language.translate('error_filters_subtitle')
        const buttonText = d ? d.cta?.data?.text : Language.translate('error_filters_subtitle')
        if (d?.cta) {
          this._content._nullStateCallback =
            e.data?.shelfName?.includes('sports') || d.cta.data?.destination?.includes('sports')
              ? this.selectFirstFilter.bind(this)
              : this._goToFullScheduleQrModal(d.cta.data)
        }
        this._content.error = {
          title: title || '',
          message: message || '',
          buttonText: buttonText || '',
        }
      } else {
        this._content.error = {
          title: Language.translate('error_filters_title'),
          message: Language.translate('error_filters_subtitle'),
          buttonText: '',
          hideButton: true,
        }
      }
      this._content.showErrorState()
    }
    this._shimmerLoader.visible = false
    this._content.visible = true
  }

  selectFirstFilter() {
    this._filters?.selectItem(this._filters?.initiallySelected)
    this._filters?.selectFocused()
    this.loadFilterData()
  }

  _goToFullScheduleQrModal = (ctaData: any) => {
    const { qrModal } = ctaData

    return () => ModalManager.open(ModalTypes.QR_MODAL, qrModal)
  }

  _createViewAllCta(ctaData: any) {
    return createViewAllCta({
      ctaData,
      cb: this._goToFullScheduleQrModal(ctaData),
    })
  }

  static override _states() {
    return [
      class FiltersList extends this {
        override _getFocused() {
          return this._filters || this
        }

        override _handleRight() {
          if (!this._content._errorContainer.hideButton) this._setState(FiltersStates.Content)
        }

        override _handleEnter() {
          this._content._container.setIndex(0)
          this.loadFilterData()
        }
      },
      class Content extends this {
        override _getFocused() {
          return this.tag('ContentHolder') || this
        }

        override _handleLeft() {
          this._setState(FiltersStates.FiltersList)
        }
      },
    ]
  }
}
