import ParkApi from './ParkApi'
import { nationalBroadcastType, CHANNEL_IDS } from './Live'
import moment from 'moment'

const getCallSign = (attributes: any) => {
  // This value is allowed only for nbc and telemundo.
  // For all other brands callSign is not allowed

  if (
    attributes.brandMachineName === CHANNEL_IDS.nbc ||
    attributes.machineName === CHANNEL_IDS.nbc ||
    attributes.brandMachineName === CHANNEL_IDS.telemundo ||
    attributes.machineName === CHANNEL_IDS.telemundo
  ) {
    return true
  }
}

export const post = async (mpid: any, attributes: any) =>
  ParkApi.post(`users/${mpid}/liveWatches`, 'POST', {
    data: {
      type: 'liveWatches',
      attributes: {
        brandMachineName: attributes.machineName,
        ...(getCallSign(attributes) && { callSign: attributes.callSign }),
        nationalBroadcastType: nationalBroadcastType(),
        streamDateTimeWatched: moment().format(),
      },
      relationships: {
        brand: {
          data: {
            type: 'brands',
            id: attributes.v4ID,
          },
        },
        user: {
          data: {
            type: 'users',
            id: mpid,
          },
        },
      },
    },
  })

export default { post }
