import { Lightning } from '@lightningjs/sdk'

import { COLORS, FONT_FACE } from '../../../../constants'

export default (tile: any) => {
  if (tile._item?.showClipData) {
    tile.patch({
      Content: {
        TitleCard: {
          SecondaryTitle: {
            flex: {},
            flexItem: { marginTop: -8 },
            Icon: {
              flexItem: { marginRight: 8, marginTop: 3 },
              texture: Lightning.Tools.getRoundRect(
                58,
                28,
                0,
                1,
                COLORS.mediumGray4,
                true,
                COLORS.transparent
              ),
              Label: {
                mount: 0.5,
                x: 29,
                y: 16,
                text: {
                  text: 'CLIP',
                  fontFace: FONT_FACE.regular,
                  fontSize: 26,
                  textColor: COLORS.lightGray8,
                },
              },
            },
            Date: {
              text: {
                text: tile._item.date,
                fontFace: FONT_FACE.regular,
                fontSize: 26,
                textColor: COLORS.lightGray8,
              },
            },
          },
        },
      },
    })
  }
}
