import VideoTile from './VideoTile'
import SeriesTile from './SeriesTile'
import BrandTile from './BrandTile'
import MovieTile from './MovieTile'
import BrandItemLabel from './BrandItemLabel'
import SlideTile from './SlideTile'
import UpcomingLiveTile from './UpcomingLiveTile'
import UpcomingLiveSlideTile from './UpcomingLiveSlideTile'
import EndCardAlternate from './EndCardAlternate'
import OnAirNowTile from './OnAirNowTile'
import EventTile from './EventTile'
import ReplayTile from './ReplayTile'
import SmartTile from './SmartTile'

export default {
  VideoTile,
  SeriesTile,
  BrandTile,
  MovieTile,
  BrandItemLabel,
  SlideTile,
  UpcomingLiveTile,
  UpcomingLiveSlideTile,
  EndCardAlternate,
  OnAirNowTile,
  EventTile,
  ReplayTile,
  SmartTile,
}
