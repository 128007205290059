import { Lightning, Utils } from '@lightningjs/sdk'

export default class Sprite extends Lightning.Component {
  _fps: any
  _index: any
  _loopIsRunning: any
  _selectedAnimationKey: any
  _spriteAnimationKeys: any
  _spriteAnimationSets: any
  _totalColumns: any
  _totalLoops: any
  _totalLoopsDone: any
  _totalRows: any
  _xJumpInPixels: any
  _yJumpInPixels: any
  _interval: any
  static override _template() {
    return {
      Sprite: { src: undefined },
    }
  }

  override _init() {
    this._fps = 0
    this._selectedAnimationKey = ''
    this._index = 0
    this._totalLoops = -1
    this._totalLoopsDone = 0
    this._totalColumns = 0
    this._totalRows = 0
    this._xJumpInPixels = 0
    this._yJumpInPixels = 0
    this._loopIsRunning = false
    this._interval = null
  }

  // Start sprite animation
  start(animationName: any) {
    if (this._spriteAnimationKeys.includes(animationName)) {
      this._selectedAnimationKey = animationName
      this._totalLoops = this._spriteAnimationSets[animationName].repeat
      this.startCountdown()
    } else {
      console.warn(`Sprite: .start(${animationName}) - key does not exist`)
      this.stop()
      this._reset()
      return
    }
    this._reset()
    this._loopIsRunning = true
  }

  // Stop sprite animation instantaneous
  stop() {
    this._loopIsRunning = false
    if (this._interval) clearInterval(this._interval)
  }
  resume() {
    this._loopIsRunning = true
    this.startCountdown()
  }
  _reset() {
    this._index = 0
    this._totalLoopsDone = 0
    this._updateSpriteToIndex(0)
  }

  startCountdown() {
    if (this._interval) clearInterval(this._interval)
    this._interval = setInterval(() => {
      if (
        this?._selectedAnimationKey &&
        this._index <= this._spriteAnimationSets[this._selectedAnimationKey].end &&
        this._loopIsRunning
      ) {
        this._updateSpritePosition()
        if (Math.floor(this._index % this._fps) === 0) this.emit('updateInterval')
      }
    }, 1000 / this._fps)
  }

  override _detach() {
    this._fps = undefined
    this._index = undefined
    this._totalLoopsDone = undefined
    this._spriteAnimationSets = undefined
    this._spriteAnimationKeys = undefined
    this._selectedAnimationKey = undefined
    this._totalColumns = undefined
    this._totalRows = undefined
    this._totalLoops = undefined
    this._interval = null
  }

  /*
    spriteSheet: spriteSheet location
    totalColumns: total columns of spriteSheet
    totalRows: total rows of spriteSheet
    spriteAnimationSets: Object with animations { animationName: { start: startFrame, end: endFrame, repeat: (-1 equals infinite) }, etc. }
    fps: frames per second ( 0 to 60 )
    Emitters:
    'spriteLoopCycleStart': every time a loop starts
    'spriteLoopCycleEnd': every time a loop ends
    'spriteLoopFinished': when reaching end of loops, and sprite animation ends
   */
  setSpriteAnimation({
    spriteSheet = '',
    totalColumns = 0,
    totalRows = 0,
    spriteAnimationSets = {},
    fps = 1,
  }) {
    this.stop()
    this._fps = Math.min(60, Math.max(0, fps))
    this._spriteAnimationSets = spriteAnimationSets
    this._spriteAnimationKeys = Object.keys(spriteAnimationSets).map((key) => key)
    this._selectedAnimationKey = this._spriteAnimationKeys.length
      ? this._spriteAnimationKeys[0]
      : ''
    this._totalColumns = totalColumns
    this._totalRows = totalRows
    this._totalLoops = this._spriteAnimationSets[this._selectedAnimationKey].repeat
    this.tag('Sprite').src = Utils.asset(spriteSheet)
    this.tag('Sprite').on('txLoaded', () => {
      this._xJumpInPixels = this.tag('Sprite').finalW / totalColumns
      this._yJumpInPixels = this.tag('Sprite').finalH / totalRows
      this.patch({
        w: this._xJumpInPixels,
        h: this._yJumpInPixels,
        clipping: true,
      })
    })
  }

  _updateSpritePosition() {
    this._updateSpriteToIndex(this._index)
    if (this._index === 0) this.emit('spriteLoopCycleStart')
    this._index++
    if (this._index > this._spriteAnimationSets[this._selectedAnimationKey].end) {
      if (this._totalLoops >= 0) this._totalLoopsDone++
      if (this._totalLoopsDone > this._totalLoops && this._totalLoops >= 0) {
        this.emit('spriteLoopFinished')
        this.stop()
      }
      this._index = this._spriteAnimationSets[this._selectedAnimationKey].start
      this.emit('spriteLoopCycleEnd')
    }
  }

  _updateSpriteToIndex(index: number) {
    const currentColumn = index % this._totalColumns
    const currentRow = Math.floor(index / this._totalColumns)
    this.tag('Sprite').patch({
      x: -(currentColumn * this._xJumpInPixels),
      y: -(currentRow * this._yJumpInPixels),
    })
  }
}
