import { get } from 'lodash'
import { Lightning } from '@lightningjs/sdk'

import { Container } from '../models'
import { lowercaseFirst } from '../../helpers'

import { collectionComponents, createItems } from '../../components/componentMaps'
import { Message } from '../../components'
import LinksSelectableGroup from '../../components/LinksSelectableGroup'
import GroupedContinuousScroll from '../../components/GroupedContinuousScroll'
import ContinuousScroll from '../../components/ContinuousScroll'
import NestedLinksSelectableGroup from '../../components/NestedLinksSelectableGroup'

import LaunchDarklySingleton from '../../lib/launchDarkly/LaunchDarkly'
import LaunchDarklyFeatureFlags from '../../lib/launchDarkly/LaunchDarklyFeatureFlags'

import {
  GroupedContinuousScrollSpawner,
  LinksSelectableSpawner,
  ContinuousScrollSpawner,
  NestedLinksSelectableSpawner,
} from '.'
import { Section } from '../../graphql/generated/types'
import { BffPage } from '../../graphql/mappers/bffPage'
import TVPlatform from '../../lib/tv-platform'
import { ErrorType } from '../../lib/tv-platform/types'
import { PlaceholderReferrer } from '../../components/Placeholder'

export const ContainerDelegateTypes = {
  EPG: 'EPG',
}

const SECTIONS_TAG = 'sections'

const createLoaderContainer = (
  stage: Lightning.Stage,
  referrer: PlaceholderReferrer,
  queryVariables: any
) => {
  return stage.c({
    type: collectionComponents.get('Placeholder'),
    tag: 'videoTile',
    title: '',
    referrer,
    queryVariables,
  })
}

const containerFactory = (item: typeof Section, index: number): Container | false => {
  const supportedCollections = [
    'Shelf',
    'SmartTile',
    'LinksSelectableGroup',
    'NestedLinksSelectableGroup',
    'Grid',
    'EndTiles',
    'Guide',
    'OnAirNowShelf',
    'LazyOnAirNowShelf',
    'LazyShelf',
    'EventSchedule',
    'Message',
    'GroupedContinuousScroll',
    'ContinuousScroll',
    'PlaceholderSection',
    'MarketingModule',
    'Stack',
    'StackGroup',
  ]
  // @ts-expect-error Property 'component' does not exist on type 'DocumentNode'.
  return item && supportedCollections.indexOf(item.component) > -1 && new Container(item, index)
}

const createModels = (items: any[] | null): Container[] =>
  items?.reduce((acc, item, index) => {
    if (!item) return acc
    const container = containerFactory(item as typeof Section, index)
    return container ? [...acc, container] : acc
  }, [] as Container[]) || []

const EmptyCollectionError = {
  type: ErrorType.OTHER,
  description: `${SECTIONS_TAG} Unable to create grouped continuous scroll (likely episodes shelf) due to no items returned`,
}

const createCollections = async (
  stage: Lightning.Stage,
  models: Container[] = [],
  delegates: { [index: string]: any } = {}
) => {
  models = !Array.isArray(models) ? [models] : models

  const collections = []
  for (const model of models) {
    let collection
    switch (model.component) {
      case 'LinksSelectableGroup':
        collection = stage.c({
          type: LinksSelectableGroup,
          spawner: new LinksSelectableSpawner(model),
          tag: `${lowercaseFirst(
            model.data.items[model.data.initiallySelected].data.items[0].component
          )}`,
          data: model,
        })
        break
      case 'NestedLinksSelectableGroup':
        collection = stage.c({
          type: NestedLinksSelectableGroup,
          spawner: new NestedLinksSelectableSpawner(model),
          tag: `${lowercaseFirst(
            model.data.items[model.data.initiallySelected].data.items[0].component
          )}`,
          data: model,
        })
        break
      case 'EventSchedule':
      case 'Guide':
        break
      case 'Message':
        collection = stage.c({
          type: Message,
          tag: `${lowercaseFirst(model.component)}`,
          componentProps: {
            firstRow: model.data.firstRow,
            secondRow: model.data.secondRow,
          },
        })
        break
      case 'LazyOnAirNowShelf': {
        collection = createLoaderContainer(
          stage,
          PlaceholderReferrer.ON_AIR,
          model?.data?.queryVariables
        )
        break
      }
      case 'LazyShelf': {
        collection = createLoaderContainer(
          stage,
          PlaceholderReferrer.MIX_EDITORIAL,
          model?.data?.queryVariables
        )
        break
      }
      case 'PlaceholderSection': {
        collection = createLoaderContainer(
          stage,
          PlaceholderReferrer.PLACEHOLDER,
          model.data?.queryVariables
        )
        break
      }

      case 'MarketingModule': {
        // if the flag is OFF then dont show the section
        if (!LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.enableMarketingModule)) {
          break
        }

        if (!model?.obj) return

        const { marketingModuleData, analytics } = model.obj
        collection = stage.c({
          type: collectionComponents.get('MarketingModule'),
          tag: 'slide',
          title: 'Marketing Module',
          items: marketingModuleData,
          analytics,
          ariaLabel: marketingModuleData?.ariaLabel,
        })

        break
      }
      case 'GroupedContinuousScroll': {
        const component = model?.data?.items?.[0]?.component
        if (!component) {
          TVPlatform.reportError(EmptyCollectionError)
          return
        }
        const tag = `${lowercaseFirst(model.data.items[0].component)}`
        collection = stage.c({
          type: GroupedContinuousScroll,
          spawner: new GroupedContinuousScrollSpawner(model, (stage as any).urlAlias),
          tag: delegates[tag] || tag,
          data: model,
          ariaLabel: model.ariaLabel,
        })
        break
      }
      case 'ContinuousScroll': {
        const component = model?.data?.items?.[0]?.component
        if (!component) {
          TVPlatform.reportError(EmptyCollectionError)
          return
        }
        const tag = `${lowercaseFirst(model.data.items[0].component)}`
        const items = createItems(model.data.items)
        collection = stage.c({
          type: ContinuousScroll,
          spawner: new ContinuousScrollSpawner(model, (stage as any).urlAlias),
          tag: delegates[tag] || tag,
          items,
          groupIds: model.data.groupIds,
          initialItem: model.data.initialItem,
          title: model.title,
          data: model,
          next: model.data.next,
          previous: model.data.previous,
          ariaLabel: model.ariaLabel,
        })
        break
      }
      default: {
        if (get(model, 'data.items.0')) {
          const items = createItems(model.data.items)
          const tag = `${lowercaseFirst(model.data.items[0].component)}`
          //Let's not create a shelf with 0 items
          if (items?.length > 0) {
            collection = stage.c({
              type: collectionComponents.get(model.component),
              tag: delegates[tag] || tag,
              viewAllCta: model.data?.viewAllCta,
              items,
              title: model.title,
              meta: model.meta,
              component: model.component,
              sponsorName: model.data.sponsorName || '',
              ariaLabel: model.ariaLabel,
            })
            if (model.data.sponsorLogo) {
              collection.sponsorLogo = model.data.sponsorLogo
            }
          }
        }
        break
      }
    }
    if (collection) {
      collection.instanceID = model.data.instanceID
      collections.push(collection)
    }
  }
  return collections
}

export default async (
  stage: Lightning.Stage,
  sections: BffPage['sections'] | (typeof Section)[],
  delegates: NonNullable<unknown> = {}
) => {
  return await createCollections(stage, createModels(sections), delegates)
}
