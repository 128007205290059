import { GraphQlClient } from '../../graphql/client'
import {
  MixedEditorialShelfQuery,
  MixedEditorialShelfQueryVariables,
} from '../../graphql/generated/types'
import { QueryName } from './bffQuery'

export default async (variables: MixedEditorialShelfQueryVariables) => {
  return await GraphQlClient.query<MixedEditorialShelfQuery>({
    query: QueryName.mixedEditorialShelf,
    variables,
  })
}
