import { Lightning } from '@lightningjs/sdk'
import { HasEndCard, WithEndCard } from './WithEndCard'

import moment from 'moment-timezone'
import { getAdjustedReferringShelf } from '../../../../helpers'
import { END_CARD_FER_AUTOPLAY_TIME, PROGRAMMING_TYPES } from '../../../../constants'
import { BingeEndcardEvent } from '../../../../player/model/event/BingeEndcardEvent'
import PlayerStoreSingleton from '../../../../store/PlayerStore/PlayerStore'

export const WithEndCardFER = <T extends Lightning.Component.Constructor<HasEndCard>>(
  component: T
) =>
  class extends WithEndCard(component) {
    override _autoplayTime = END_CARD_FER_AUTOPLAY_TIME

    override _construct() {
      super._construct()
      this._autoplayTime = END_CARD_FER_AUTOPLAY_TIME
    }

    override _onStreamEnd() {
      super._onStreamEnd?.()
      this._endCard.transparentBG = false
      this._mediaPlayerEnded = true
    }

    override _playerEventsHandler(event: any) {
      super._playerEventsHandler(event)
      if (event instanceof BingeEndcardEvent) {
        const { stream } = PlayerStoreSingleton
        const videoId = stream && 'pid' in stream && stream.pid
        if (videoId) {
          this._endCard.queryEndCardsSLE(videoId, PROGRAMMING_TYPES.SLE, 'FER').then((endCards) => {
            if (endCards) this._setState('PlayerEndCardFER')
          })
        }
      }
    }

    static _states() {
      return [
        // @ts-expect-error can't type a static method in an interface
        ...super._states(),
        class PlayerEndCardFER extends this {
          override $enter() {
            this._endCardImpressionTimeStamp = moment()

            this._analyticsDelegate?.fireEndCardImpression({
              endCardTime: this._autoplayTime,
              ...this._endCard.getAnalyticsData(),
              shelf: getAdjustedReferringShelf(),
            })

            this._additionalUIUpdates({
              endCardBGTransparency: true,
              endCardTransparency: false,
            })
          }

          override $exit() {
            this._endCardImpressionTimeStamp = null
          }

          override _getFocused() {
            return this._endCard || this
          }

          override _captureBack(_: any) {
            this._closeEndCard()
          }

          override _handleUp(): boolean {
            return true
          }

          override _handleDown(): boolean {
            return true
          }
        },
      ]
    }
  }
