import { Language } from '@lightningjs/sdk'

import { Subscription } from 'rxjs'
import LongScrollPage from '../LongScrollPage'
import MessageContainer from '../../components/error/MessageContainer'
import { SectionsSpawner } from '../../api/spawners'
import { COLORS, EventsWithFiltersStates } from '../../constants'
import { setSmooth } from '../../helpers'
import { findGridComponent, findLinkSelectableComponent } from '../../store/PlayerStore/actions'
import NestedLinksSelectable from '../../api/spawners/NestedLinkSelectable'
import LabelsList, { LabelsListRenderingType } from '../../components/labelsList/LabelsList'
import { createItems } from '../../components/componentMaps'
import { Container } from '../../api/models'
import { KeyMap, KeyMapState } from '../../lib/KeyMap'
import { merge } from 'lodash'
import FilterPosition from '../../util/filterPosition/filterPosition'
import ContentPosition from '../../util/contentPosition'
import { useRequest } from '../../lib/useRequest'
import { PaginatedComponentRequestConfig } from '../../components/LinksSelectableGroup/request'
import { EventsWithFiltersRequestConfig } from './request'

export const GridDelegateTypes = {
  replayTile: 'replayTileOlympicsContentHub',
  videoTile: 'videoTileOlympicsContentHub',
}

interface EventsWithFiltersParams {
  loadAnnounce?: string
}

enum LoadingState {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

const resultStyle = { x: 536, y: 200, w: 1454 }

export default class EventsWithFilters extends LongScrollPage {
  label = 'Sports Filter menu'
  announceTitle = ''

  _params?: EventsWithFiltersParams
  _subscription?: Subscription
  _keyMap: KeyMapState<EventsWithFiltersStates> = KeyMap(this, [
    [
      [EventsWithFiltersStates.Filters],
      EventsWithFiltersStates.Results,
      [EventsWithFiltersStates.Error],
    ],
  ])
  _container = this.tag('Container')
  _spawnerFilters: NestedLinksSelectable | undefined
  _parentRoute = ''
  _pageTitle = ''
  _cursors: any = {}

  static override _template() {
    return {
      Filters: {
        x: 0,
        y: 200,
        w: 456,
        type: LabelsList,
        sectionSize: 700,
        mode: LabelsListRenderingType.VERTICAL,
      },
      Container: {
        visible: false,
        Results: {},
        Error: {
          x: 450,
          y: 400,
          type: MessageContainer,
          title: Language.translate('olympics-empty-title'),
          message: Language.translate('olympics-empty-subtitle'),
          buttonText: Language.translate('olympics-all-sports-button'),
        },
      },
    }
  }

  // #region Lifecycle
  override _init() {
    this.stage.setClearColor(COLORS.dark)
  }

  override _active() {
    this.stage.setClearColor(COLORS.dark)
    setSmooth(this.widgets.loader, 'visible', 0)
  }

  override _onMounted() {
    this._setTitlePage()
  }

  override _disable() {
    this._keyMap.reset()
    this.widgets.menu.expand()
  }

  override _detach() {
    super._detach()
    this._subscription?.unsubscribe()
  }
  // #endregion

  // #region Getters/setters
  override set params(params: { loadAnnounce?: string }) {
    this._params = params
  }

  get announce(): string {
    return this._params?.loadAnnounce ? `${this.announceTitle}, ${this._params.loadAnnounce}` : ''
  }

  set results(v: any) {
    if (v) {
      const announce = `In a ${v.lastRowNumber + 1} by ${
        v.itemsPerRow
      } grid, press left, right, down for items`
      v = merge(v, resultStyle, { title: announce })
    }
    this.tag('Container').patch({ Results: v })
  }

  get results() {
    return this._container.tag('Results')
  }

  set filters(filtersContainer) {
    if (filtersContainer && filtersContainer.getDataElement) {
      this._spawnerFilters = filtersContainer.getDataElement()?.spawner
      this.filters.items = this._spawnerFilters?.createItemLabels(null, COLORS.yellow2)
      this._setState(EventsWithFiltersStates.Filters)
      //if we are reselecting a previous filter, load the items associated with the filter
      if (FilterPosition.getPositionForCurrentPage() > 0) this._getItems()
    }
  }

  get filters() {
    return this.tag('Filters')
  }

  get error() {
    return this.tag('Error')
  }

  set loadingState(state: LoadingState) {
    if (state === LoadingState.LOADING) {
      this._container.visible = false
      return
    }
    const isSuccess = state === LoadingState.SUCCESS
    this._container.patch({
      visible: true,
      Results: {
        visible: isSuccess,
      },
      Error: {
        visible: !isSuccess,
      },
    })
  }
  // #endregion

  // #region Data fetching
  $getMoreItems(data: any) {
    if (!this._cursors[data.cursor]) this._cursors[data.cursor] = 0
    this._cursors[data.cursor]++
    if (this._cursors[data.cursor] > 1) return

    useRequest(PaginatedComponentRequestConfig(data))
      .fetch()
      .then((result: any) => {
        const { data = {} } = new Container(result.data)
        this.results.addItems(createItems(data?.items))
        this.results.moreItems = data?.moreItems
      })
      .catch(() => {
        // fail silently
      })
  }

  set apiData(apiData: any) {
    const gridSection = findGridComponent(apiData.sections)
    const filtersSection = findLinkSelectableComponent(apiData.sections)
    this._parentRoute = filtersSection?.data.itemLabels[0]?.toLowerCase()
    this._pageTitle = gridSection?.data.listTitle
    this._setTitlePage()
    //if we are loading a previous filter instead of default, just load the filters
    //when the filters are set, we initate _getItems to get the results for the filter
    if (FilterPosition.getPositionForCurrentPage() > 0) {
      SectionsSpawner(this.stage, [gridSection, filtersSection], GridDelegateTypes).then(
        this._renderFiltersOnly(gridSection)
      )
    } else {
      //otherwise, load the default results for the page (all filter)
      SectionsSpawner(this.stage, [gridSection, filtersSection], GridDelegateTypes).then(
        this._renderResult(gridSection)
      )
    }
  }

  async _fetchPlaceholderData(queryVariables: any) {
    try {
      const response = await useRequest(EventsWithFiltersRequestConfig(queryVariables)).fetch()
      const gridSection = findGridComponent(response.components as any)
      if (gridSection) {
        const result = await SectionsSpawner(this.stage, [gridSection], GridDelegateTypes)
        this._renderResult(gridSection)(result)
      }
    } catch (e: any) {
      const message = e?.data?.components?.[0]?.data || {}
      if (message) {
        this._container.patch({
          Error: {
            title: message.textRow1 ? message.textRow1 : Language.translate('olympics-empty-title'),
            message: message.textRow2
              ? message.textRow2
              : Language.translate('olympics-empty-subtitle'),
            buttonText: message.cta?.data?.text
              ? message.cta?.data?.text
              : Language.translate('olympics-all-sports-button'),
          },
        })
      }
      this._renderError()
    }
  }

  _getItems() {
    let index = FilterPosition.getPositionForCurrentPage()
    if (this._spawnerFilters?.getItemLabelsConfig()[index]) {
      index =
        this._spawnerFilters?.getItemLabelsConfig()[index].index !== null
          ? this._spawnerFilters?.getItemLabelsConfig()[index].index
          : index
    }
    const selectedElement = this._spawnerFilters?.getItems()[index]
    this.loadingState = LoadingState.LOADING
    if (selectedElement) this._fetchPlaceholderData(selectedElement.data.queryVariables)
  }
  // #endregion

  // #region UI
  _setTitlePage() {
    this.widgets.menu.collapse({
      parentRoute: this._parentRoute,
      subCategoryItem: this._pageTitle,
      hideProfile: true,
    })
  }

  _renderResult = (gridSection: any) => {
    return ([results, filters]: any[] = []) => {
      if (results) {
        this.results = results
        if (gridSection?.data?.moreItems) {
          this.results.moreItems = gridSection?.data?.moreItems
        }
      }
      if (filters) this.filters = filters
      this.loadingState = LoadingState.SUCCESS
    }
  }

  _renderFiltersOnly = (gridSection: any) => {
    return ([results, filters]: any[] = []) => {
      if (filters) this.filters = filters
    }
  }

  _renderError = () => {
    this._container.patch({
      Results: undefined,
    })
    this.loadingState = LoadingState.ERROR
  }
  // #endregion

  override _handleLeft() {
    this._keyMap.left()
  }

  override _handleRight() {
    this._keyMap.right()
  }

  static override _states() {
    return [
      class Filters extends this {
        override _getFocused() {
          return this.filters
        }

        override _handleEnter() {
          ContentPosition.clearPositionForCurrentPage()
          FilterPosition.setPositionForCurrentPage(this.filters.activeIndex)
          //reset cursor map on filter change
          this._cursors = {}
          this._getItems()
        }
      },
      class Results extends this {
        override _getFocused() {
          return this.results
        }
      },
      class Error extends this {
        override _getFocused() {
          return this.error
        }
        override _handleEnter() {
          const initialSelection = this._spawnerFilters?.initiallySelected || 0
          this.filters?.selectItem(initialSelection)
          this._setState(EventsWithFiltersStates.Filters)
          this._keyMap.reset()
          ContentPosition.clearPositionForCurrentPage()
          FilterPosition.setPositionForCurrentPage(initialSelection)
          this._getItems()
        }
      },
    ]
  }

  override _getFocused() {
    return this.filters || this
  }
}
