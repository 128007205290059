// @ts-expect-error TS(7016): Could not find a declaration file for module 'uuid... Remove this comment to see the full error message
import { v4 as uuidv4 } from 'uuid'
import Preferences from './Preferences'
import { Profile } from '@lightningjs/sdk'

export const generateDeviceID = async () => {
  const deviceID = Preferences.get(Preferences.DEVICE_ID)

  return !deviceID ? storeDeviceId(await Profile.uid()) : deviceID
}

export const storeDeviceId = (deviceID: any) => {
  //Generate new deviceId for local development purpose
  if (deviceID === DEFAULT_DEVICE_ID || deviceID === '123') {
    deviceID = uuidv4()
  }
  Preferences.store(Preferences.DEVICE_ID, deviceID)

  return deviceID
}

export const getDeviceId = () => {
  return Preferences.get(Preferences.DEVICE_ID)
}

const DEFAULT_DEVICE_ID = 'ee6723b8-7ab3-462c-8d93-dbf61227998e'
