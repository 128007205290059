import { GqlFetchResult } from '../client/types'
import { GlobalNavigationQuery } from '../generated/types'

const globalNavigationMapper = (page: GqlFetchResult<GlobalNavigationQuery>) => {
  const data = page.data?.globalNavigation?.data
  if (!data) return null
  return data
}

export default globalNavigationMapper
