import Slide from '../index'
import { addSponsorsBadge, setBadge } from './helpers'
type SportSlideDecoratorData = {
  _item: {
    isSportsVideoSlide?: boolean
    destination?: string
  }
  headerTitle: string
}

export const sportSlideDecorator = (item: SportSlideDecoratorData) => {
  if (
    (!item._item?.isSportsVideoSlide && item._item?.destination !== 'sports') ||
    !(item instanceof Slide) ||
    !item._item
  )
    return
  setBadge(item)

  if (!item._item.titleLogo) {
    const title = item._item.slideHeader || item._item.title
    if (title) item.headerTitle = title
  }

  addSponsorsBadge(item)
}
