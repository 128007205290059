import { GraphQlClient } from '../../graphql/client'
import featuredSectionMapper from '../../graphql/mappers/featuredSection'
import { getMpid } from '../../helpers'
import { FeaturedSectionQuery, FeaturedSectionQueryVariables } from '../../graphql/generated/types'
import rsnFilter from '../../graphql/filter/rsnFilter'
import { QueryName } from './bffQuery'
import { AsyncRequestOptions } from '../../lib/useRequest'

const featuredRequest = async (
  variables: FeaturedSectionQueryVariables,
  options?: AsyncRequestOptions
) => {
  return rsnFilter(
    featuredSectionMapper(
      await GraphQlClient.query<FeaturedSectionQuery>(
        {
          query: QueryName.featuredSection,
          variables: {
            ...variables,
            ...(!variables.userId ? { userId: getMpid() } : {}),
          }, // todo: maybe merge with default variables for this page
        },
        options
      )
    )
  )
}

export default featuredRequest
