import { IPlayerEvent } from './IPlayerEvent'
import {
  NonLinearAd,
  NonLinearAdEvent as INonLinearAdEvent,
} from '@sky-uk-ott/core-video-sdk-js-core/lib/addons/adverts/non-linear-adverts/non-linear-ad-types'

export class NonLinearAdEvent extends IPlayerEvent {
  _ad!: INonLinearAdEvent

  constructor(ad: INonLinearAdEvent) {
    super()
    this._ad = ad
  }

  consume(): NonLinearAd | null | undefined {
    return this._ad.consume()
  }
}
