import { Log } from '@lightningjs/sdk'
import { sendMetric } from '../../../lib/analytics/Analytics'

import AppConfigFactorySingleton from '../../../config/AppConfigFactory'
import { DebugControllerSingleton } from '../../../util/debug/DebugController'
import { getAdParams, getAdPodParams } from '../../CoreVideoPlayer/normalizeAds'

import { AdBreakDataEvent } from '../event/AdBreakDataEvent'
import { reduceAdBreaks } from '../../../api/models/AdBreakTiming'
import { PROGRAMMING_TYPES } from '../../../constants'
import { AdBreakStartEvent } from '../event/AdBreakStartEvent'
import { AdBreakEndEvent } from '../event/AdBreakEndEvent'
import { AdStartEvent } from '../event/AdStartEvent'
import { AdEndEvent } from '../event/AdEndEvent'
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore'
import { isVod } from '../../../components/player/helpers/metadata'
import { PlayerEventEmitter } from './PlayerEventEmitter'
import { Ad, AdBreak, SessionController } from '@sky-uk-ott/core-video-sdk-js-core'
import { MutablePlayerEventStream } from '../event/MutablePlayerEventStream'
import { EVENTS } from '../../../lib/analytics/types'
import { NonLinearAdEvent as INonLinearAdEvent } from '@sky-uk-ott/core-video-sdk-js-core/lib/addons/adverts/non-linear-adverts/non-linear-ad-types'
import { NonLinearAdEvent } from '../event/NonLinearAdEvent'

const TAG = 'CoreVideoAdReporter'

export class CoreVideoAdReporter extends PlayerEventEmitter {
  _adBreakIsPlaying?: boolean
  _currentAd?: Ad
  _currentAdBreak?: AdBreak<Ad>
  _isAdPlaying?: boolean

  get isAdPlaying() {
    return this._isAdPlaying
  }

  override attach(player: SessionController, eventStream: MutablePlayerEventStream) {
    super.attach(player, eventStream)
    const { program } = PlayerStoreSingleton
    // Ad event callbacks
    // Adbreaks (only for FERs since ad breaks aren't known on live)
    if (
      program &&
      'programmingType' in program &&
      (program?.programmingType === PROGRAMMING_TYPES.FER || isVod(program?.programmingType))
    ) {
      player?.onAdBreakDataReceived(this._onAdBreakDataReceived)
    }
    // User entering/exiting ad break
    player?.onAdBreakStarted(this._onAdBreakStarted)
    player?.onAdBreakFinished(this._onAdBreakFinished)
    // User entering/exiting one ad within the ad break
    player?.onAdStarted(this._onAdStarted)
    player?.onAdFinished(this._onAdFinished)

    player?.onPauseAd?.(this._onPauseAd)

    // Disable ads through the config if needed
    // Long term I'd like to make a debug screen where we could toggle this on and off
    DebugControllerSingleton.adsDisabled = AppConfigFactorySingleton.config.adsDisabled
  }
  _onAdBreakDataReceived = (adbreaks: AdBreak<Ad>[]) => {
    const mappedAdBreaks = reduceAdBreaks(adbreaks)
    this._normalizedPlayerEvents?.publish(new AdBreakDataEvent(mappedAdBreaks))
  }
  _onAdBreakStarted = (adBreak: AdBreak<Ad>) => {
    this._adBreakIsPlaying = true
    this._currentAdBreak = getAdPodParams(adBreak)
    Log.info(`${TAG} ad break started`, this._currentAdBreak)

    sendMetric(EVENTS.AD_BREAK_START, this._currentAdBreak)
    this._normalizedPlayerEvents?.publish(new AdBreakStartEvent(adBreak))
  }
  _onAdBreakFinished = () => {
    this._adBreakIsPlaying = false
    Log.info(`${TAG} ad break finished`)

    sendMetric(EVENTS.AD_BREAK_END, this._currentAdBreak)
    this._normalizedPlayerEvents?.publish(new AdBreakEndEvent({}))
  }
  _onAdStarted = async (ad: Ad) => {
    this._isAdPlaying = true
    this._currentAd = await getAdParams(ad)
    const index = ad.adBreak.ads.findIndex((ad) => ad.id === this._currentAd?.id)
    Log.info(`${TAG} ad started`, this._currentAd)

    sendMetric(EVENTS.AD_START, this._currentAd)
    this._normalizedPlayerEvents?.publish(
      new AdStartEvent(ad?.adBreak, { ...this._currentAd, index })
    )
  }
  _onAdFinished = () => {
    this._isAdPlaying = false
    Log.info(`${TAG} ad finished`)

    sendMetric(EVENTS.AD_END, this._currentAd)
    this._normalizedPlayerEvents?.publish(new AdEndEvent(this._currentAdBreak, this._currentAd))
  }

  _onPauseAd = (ad: INonLinearAdEvent) => {
    Log.info(`${TAG} onPauseAd`)
    this._normalizedPlayerEvents?.publish(new NonLinearAdEvent(ad))
  }
}
