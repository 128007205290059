export const isObject = (v: any) => {
  return typeof v === 'object' && v !== null
}

export const arrayMergeSplit = (array: any, num: any) => {
  const ret = []
  for (let i = 0; i < array.length; i += num) {
    ret.push(array.slice(i, i + num).join('\n'))
  }
  return ret
}
