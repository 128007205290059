import Lightning from '@lightningjs/sdk/src/Lightning'
import isUndefined from 'lodash/isUndefined'

import { RouterPage } from '../../../../types/global'
import { IShows } from '../types'
import { setSmooth } from '../../../helpers'
import { Container } from '../../../api/models'
import { LinksSelectableSpawner } from '../../../api/spawners'
import { brandTilesCategories } from '../../../api/requests'
import { ShowsStates } from '../../../constants'
import ContentPosition from '../../../util/contentPosition'
import { WithRequestErrorState } from '../../hoc/withRequestError'

export const LoadSelectedBrandStateFactory = (
  base: Lightning.Component.Constructor<Lightning.Component<any, RouterPage> & IShows>
) =>
  class LoadSelectedBrandData extends base {
    override $enter() {
      setSmooth(this.widgets.loader, 'visible', 1)
      setSmooth(this.tag('LinksSelectableGroup'), 'alpha', 0)
      setSmooth(this.tag('ErrorMessage'), 'alpha', 0)
      this.loadData().then((success) => {
        if (success) {
          this._setState(ShowsStates.BrandLabelsList)
          setSmooth(this.tag('LinksSelectableGroup'), 'alpha', 1)
          const position = ContentPosition.getPositionForCurrentPage()
          if (!isUndefined(position)) {
            this._setState(ShowsStates.LinksSelectableGroup)
            this.linksSelectableGroup.initialFocusState = 'Data'
          } else {
            this.linksSelectableGroup?.getDataElement()?.setIndex(0)
          }
        } else {
          this._setState(WithRequestErrorState)
        }
      })
    }

    override $exit() {
      setSmooth(this.widgets.loader, 'visible', 0)
    }

    override _getFocused() {
      return this.brandLabelsList || this
    }

    override set linksSelectableGroup(v: any) {
      this.linksSelectableGroup.initialFocusState = 'LabelsList'
      v.obj.fontSize = 35
      if (this._initialCategory) {
        const categoryIndex = v.data.obj.itemLabels.findIndex(
          (category: string) =>
            this._parseCategory(category) === this._parseCategory(this._initialCategory)
        )
        if (categoryIndex > -1) v.data.obj.initiallySelected = categoryIndex
      }
      this.linksSelectableGroup.spawner = new LinksSelectableSpawner(v)
      this.linksSelectableGroup.data = v
    }

    async loadData() {
      const brandData = this._brandSelectableSpawner?.getBrandData(this._selectedBrandIndex)
      if (brandData?.component === 'LazyLinksSelectableGroup') {
        //in case of LazyLinksSelectableGroup
        //Check if already data is queried for that network then reuse that data
        if (brandData.queryData) {
          this.linksSelectableGroup = brandData.queryData
        } else {
          const queryData = await brandTilesCategories(brandData.data.queryVariables)
          if (queryData && queryData.data) {
            brandData.queryData = new Container(queryData.data, 0)
            this.linksSelectableGroup = brandData.queryData
          } else {
            return false
          }
        }
      } else {
        //in case of LinkSelectableGroup
        this.linksSelectableGroup = brandData
      }
      return true
    }
  }
