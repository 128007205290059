export default {
  bravo: {
    displayName: 'Bravo',
  },
  nbc: {
    displayName: 'NBC',
  },
  e: {
    displayName: 'E!',
  },
  oxygen: {
    displayName: 'Oxygen',
  },
  syfy: {
    displayName: 'Syfy',
  },
  telemundo: {
    displayName: 'Telemundo',
  },
  usa: {
    displayName: 'USA',
  },
  nbcsports: {
    displayName: 'NBC Sports',
  },
}
