import { IPlayerEvent } from './IPlayerEvent'

export class TrickPlayImageEvent extends IPlayerEvent {
  _thumbnailVariants

  constructor(thumbnailVariants: any) {
    super()
    this._thumbnailVariants = thumbnailVariants
  }

  get thumbnailVariants() {
    return this._thumbnailVariants
  }
}
