import mParticle from '@mparticle/web-sdk'
import getEndCardImpressionAttributes from '../attributes/getEndCardImpressionAttributes'

const trackEndCardImpression = (type: any) => {
  return {
    getAttributes: getEndCardImpressionAttributes,
    getCategory: () => mParticle.EventType?.UserContent,
    type: 'End Card Impression',
    injectGlobals: true,
  }
}

export default trackEndCardImpression
