import { IPlayerEvent } from './IPlayerEvent'

export class LoadSourceEvent extends IPlayerEvent {
  _sourceUrl
  _metadata

  constructor(sourceUrl: any, metadata: any) {
    super()
    this._sourceUrl = sourceUrl
    this._metadata = metadata
  }

  get sourceUrl() {
    return this._sourceUrl
  }

  get metadata() {
    return this._metadata ?? JSON.parse('{}')
  }
}
