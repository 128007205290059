import { Lightning } from '@lightningjs/sdk'

import LongScroll from './longScroll'
import { StyleType } from '../lib/style'
import { COLORS, FONT_FACE, SCREEN_SIZE } from '../constants'
import { Dimension } from './longScroll/types'
import { setSmooth } from '../helpers'

// @ts-expect-error TS(7016): Override template
export default class StackGroup extends LongScroll {
  _currentTitle: Lightning.Element
  static override _template() {
    return {
      CurrentTitle: {
        zIndex: 10,
        x: 0,
        y: 20,
        Gradient: {
          x: 0,
          y: -5,
          w: SCREEN_SIZE.width,
          h: 220,
          rect: true,
          colorBottom: COLORS.transparent,
          colorTop: COLORS.black,
          visible: true,
        },
        Title: {
          x: 20,
          y: -30,
          alpha: 0.87,
          text: { fontFace: FONT_FACE.regular, fontSize: 40, text: 'On now' },
        },
      },
      Container: {
        clipping: true,
        w: 1400,
        h: 900,
        y: 30,
        x: 0,
        Wrapper: {
          x: 20,
          y: 0,
          zIndex: 1,
          alpha: 1,
          children: [],
        },
      },
    }
  }

  override _setup() {
    this._currentTitle = this.tag('CurrentTitle.Title')
    super._setup()
  }

  override update(
    child: Lightning.Element.PatchTemplate,
    style: StyleType,
    tag: string,
    index: number
  ) {
    if (index === this._index) {
      this._currentTitle.text = child.title
      child.changeTitleAlpha(0)
    }
    const { h = 0, margin = 0 } = style
    const dims = this.dimensions
    let last: Dimension = { y: 0, h: 0, hideHeight: 0, hidePositionY: 0 }

    if (!h) {
      throw new Error(`Specify a 'h' property for tag: ${tag}`)
    }

    if (dims.length) {
      last = dims[dims.length - 1] || last
    }

    const lastHideHeight = last?.hideHeight || 0
    const lastHidePositionY = last?.hidePositionY || 0
    const lastHasMoreThanOneItem = lastHideHeight > h
    const currentHasMoreThanOneItem = child.items?.length > 1
    const current: Dimension = {
      h: h || 0,
      y: last.y + last.h + margin,
      hideHeight: currentHasMoreThanOneItem ? h * 2 : h,
      hidePositionY: lastHasMoreThanOneItem
        ? lastHidePositionY + lastHideHeight + margin
        : last.y + last.h + margin || 0,
    }

    dims.push(current)
    this.dimensions = dims
    child.y = current.hidePositionY
  }

  _changeTitleAlpha(target: any, alpha: number) {
    if (target?.changeTitleAlpha) {
      target.changeTitleAlpha(alpha)
    }
  }

  override setIndex(index = this._index, immediate = false) {
    const nextStack = this._wrapper.childList.getAt(index)

    if (nextStack && !nextStack.isCta) {
      this._changeTitleAlpha(nextStack, 0)
      this._currentTitle.text = nextStack.title || ''
      this._changeTitleAlpha(this._wrapper.childList.getAt(this._index), 1)
    }

    super.setIndex(index, immediate)
  }

  $scrolledListItemIndex({ index }: { index: number }) {
    const nextStack = this._wrapper.childList.getAt(this._index + 1)
    const currentStack = this._wrapper.childList.getAt(this._index)

    if (nextStack) {
      const isPenultimateOrNexSelected = index >= currentStack.items.length - 2
      const isLastItemSelected = index >= currentStack.items.length - 1
      const nextStackDimension = this._dimensions[this._index + 1]
      nextStack.patch({ visible: isPenultimateOrNexSelected })

      if (isLastItemSelected)
        setSmooth(nextStack, 'y', nextStackDimension?.y, {
          duration: 0.3,
        })
      else
        setSmooth(nextStack, 'y', nextStackDimension?.hidePositionY, {
          duration: 0.3,
        })
    }
  }
}
