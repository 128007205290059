import TVPlatform from '../lib/tv-platform'
import { SCREEN_SIZE } from '../constants'

const calculateRenderPrecision = (): number => {
  return (screen?.width || window.innerWidth) / SCREEN_SIZE.width
}

export const getRenderPrecision = (): number =>
  TVPlatform.getStageSettings().precision || calculateRenderPrecision()

export const setHeightOfChildrenItems = (element: any): void => {
  if (element.children?.length) {
    element.h = element.children.reduce((acc: number, item: any) => acc + item.h, 0)
  } else {
    element.visible = false
  }
}
