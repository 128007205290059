import TVPlatformModel from './TVPlatformModel'

/**
 * TVPlatform Singleton
 */
class TVPlatformFactory {
  _tvPlatformModel: any
  constructor() {
    this._tvPlatformModel = new TVPlatformModel()
  }

  get() {
    return this._tvPlatformModel
  }
}

const TVPlatformSingleton = new TVPlatformFactory()
export default TVPlatformSingleton
