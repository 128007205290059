import { componentsForPlaceholders } from '../../api/requests'
import Variables from '../../graphql/Variables'
import rsnFilter from '../../graphql/filter/rsnFilter'
import componentsForPlaceholdersMapper from '../../graphql/mappers/componentsForPlaceholders'
import { createRequestConfig } from '../../lib/useRequest'

export const EventsWithFiltersRequestConfig = (queryVariables: any) =>
  createRequestConfig({
    query: componentsForPlaceholders,
    mapper: (data: any) => rsnFilter(componentsForPlaceholdersMapper(data)),
    variables: Variables.componentsForPlaceholdersVariables(queryVariables),
    reject: [
      (data) => data?.components?.[0]?.component === 'Message',
      (data) => !data?.components?.find((e) => e?.component === 'Grid'),
    ],
  })
