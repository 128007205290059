import Variables from '../../graphql/Variables'
import { createRequestConfig } from '../../lib/useRequest'
import { mainRequest } from '../../api/requests'

export const CreditsPageRequestConfig = (videoId: string) =>
  createRequestConfig({
    query: mainRequest,
    variables: Variables.videoPageVariables(videoId),
    reject: [(data) => !data?.metadata],
  })
