import getSlideAttributes, { isSLE } from '../attributes/getSlideAttributes'
import { getIsOlympics, setDefaultValue } from '../../../../helpers/analytics'
import {
  ENTITY_TYPES,
  MPARTICLE_DEFAULT_ATTR_VALUE,
  OLYMPICS_FALLBACK_VALUE,
} from '../../../../constants'

type DynamicLeadImpressionAttributesParams = {
  analytics?: any
  entity?: any
  shelf?: any
  tileParams?: any
}
export const getDynamicLeadImpressionAttributes = (
  params: DynamicLeadImpressionAttributesParams = {}
) => {
  const analytics = params.analytics || params.entity?.analytics || {}
  const shelf = params.shelf || {}
  const tileParams = params.tileParams || {}

  return setDefaultValue(
    {
      Brand: MPARTICLE_DEFAULT_ATTR_VALUE,
      Show: getIsOlympics(analytics) ? OLYMPICS_FALLBACK_VALUE : MPARTICLE_DEFAULT_ATTR_VALUE,
      'Content Position': shelf.position || shelf.tileIndex,
      'Custom Shelf Position': shelf.customPosition || shelf.shelfIndex,
      Sponsor: analytics.sponsorName,
      'Destination URL': analytics.destination,
      // @ts-expect-error TS(2774): This condition will always return true since this ... Remove this comment to see the full error message
      'Destination Type': isSLE ? ENTITY_TYPES.VIDEO : analytics.destinationType,
      'Notification Title': params?.entity?.notificationTitle || analytics.videoTitle,
      'Dynamic Lead Logic': params?.entity?.dynamicSmartLeadLogic,
      ...getSlideAttributes(analytics, shelf, tileParams),
    },
    MPARTICLE_DEFAULT_ATTR_VALUE
  )
}

export default getDynamicLeadImpressionAttributes
