import moment from 'moment-timezone'

import {
  CONTENT_TYPE,
  MPARTICLE_DEFAULT_ATTR_VALUE,
  PROGRAMMING_TYPES,
  TOKEN_TYPE,
  XY_FALLBACK_TYPES,
} from '../../../../constants'
import { setDefaultValue } from '../../../../helpers'
import { Language } from '@lightningjs/sdk'
import LaunchDarklySingleton from '../../../launchDarkly/LaunchDarkly'
import LaunchDarklyFeatureFlags from '../../../launchDarkly/LaunchDarklyFeatureFlags'
import AuthenticationSingleton from '../../../../authentication/Authentication'
import { StreamAuthorizationType } from '../../../../pages/Player/StreamLoader/request'

export const getCallsign = (data: any) =>
  data.callSign || data.assetInfo?.callSign || data.geoChannel?.toUpperCase()

const getEpisodeTitle = (data: any) => data.episodeTitle || data.videoTitle || data.programTitle

const getVideoType = (params: LinearAttributesParams): string | PROGRAMMING_TYPES | undefined => {
  return !params.videoType && params.adobeContentType === CONTENT_TYPE.LINEAR
    ? 'Live'
    : params.videoType || params.programmingType
}

export const getStreamType = (xyFallback: any) => {
  if (!xyFallback) return MPARTICLE_DEFAULT_ATTR_VALUE
  const hasXyFallback = xyFallback === XY_FALLBACK_TYPES.x || xyFallback === XY_FALLBACK_TYPES.y
  return hasXyFallback ? xyFallback : MPARTICLE_DEFAULT_ATTR_VALUE
}

const getTokenType = (authKill: any, authType: any) => {
  if (authType === StreamAuthorizationType.TEMP_PASS) return TOKEN_TYPE.TEMP_PASS

  const mvpd = AuthenticationSingleton.isAuthenticated()
  return (mvpd && TOKEN_TYPE.MVPD) || (authKill && TOKEN_TYPE.AUTH_KILL)
}

const getMvpdId = (authType: any, initialTempPassRequest?: boolean) => {
  if (authType === StreamAuthorizationType.TEMP_PASS) {
    const { initialTempPassName, secondaryTempPassName } = LaunchDarklySingleton.getFeatureFlag(
      LaunchDarklyFeatureFlags.tempPass
    )
    return initialTempPassRequest ? initialTempPassName : secondaryTempPassName
  }
  return AuthenticationSingleton.getMvpdDataMvpdId()
}

export const getLinearDuration = (length = 0) => moment.duration(length, 'seconds').asMinutes()

type LinearAttributesParams = {
  casting?: any
  brand?: any
  programTitle?: any
  seasonNumber?: any
  episodeNumber?: any
  pid?: any
  tmsId?: any
  mpxGuid?: any
  videoType?: any
  programmingType?: any
  authKill?: any
  entitlement?: any
  liveEntitlement?: any
  listOfGenres?: any
  genre?: any
  authType?: any
  initialTempPassRequest?: boolean
  geoChannel?: string
  homeChannel?: string
  ccLang?: any
  xyFallback?: any
  sport?: string
  shelf?: any
  league?: any
  adobeContentType?: any
}
const getLinearAttributes = (params: LinearAttributesParams = {}) => {
  if (!params) return {}
  return setDefaultValue(
    {
      Casting: params.casting,
      Brand: params.brand?.title,
      Show: params.programTitle,
      Season: params.seasonNumber,
      'Episode Title': getEpisodeTitle(params),
      'Episode Number': params.episodeNumber,
      'Video ID': params.pid || params.tmsId || params.mpxGuid,
      'Video Type': getVideoType(params),
      Entitlement: params.authKill ? 'Auth Kill' : params.entitlement || params.liveEntitlement,
      Genre: params.listOfGenres || params.genre,
      'Token Type': getTokenType(params.authKill, params.authType),
      'Geo Station': params.geoChannel?.toUpperCase(),
      'Home Station': params.homeChannel?.toUpperCase(),
      Callsign: getCallsign(params),
      'CC Language': params.ccLang,
      'Stream Type': getStreamType(params?.xyFallback),
      Sport: params.sport || MPARTICLE_DEFAULT_ATTR_VALUE,
      Sponsor: params.shelf?.sponsorName || MPARTICLE_DEFAULT_ATTR_VALUE,
      League: params.league || MPARTICLE_DEFAULT_ATTR_VALUE,
      Language: Language.get(),
      MVPD: getMvpdId(params.authType, params.initialTempPassRequest),
    },
    MPARTICLE_DEFAULT_ATTR_VALUE
  )
}

export default getLinearAttributes
