import Variables from '../../graphql/Variables'
import { createRequestConfig } from '../../lib/useRequest'
import { mainRequest } from '../../api/requests'

export const NetworksPageRequestConfig = () =>
  createRequestConfig({
    query: mainRequest,
    variables: Variables.networksPageVariables(),
    reject: [(data) => !data?.sections],
  })
