import List from './List'

export default class TrickPlayImageList extends List {
  override _init() {
    super._init()
    this._index = 3
  }

  override _handleLeft() {
    return false
  }

  override _handleRight() {
    return false
  }

  override _handleBack(e: any) {
    return false
  }
}
