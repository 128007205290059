import { Language } from '@lightningjs/sdk'
import { COLORS, FONT_FACE, ROUTE, ROUTE_ENTITY, VERTICAL_ALIGN } from '../../../../constants'
import RouterUtil from '../../../../util/RouterUtil'
import { ACTIVATION_TYPE } from '../constants'
import { ActivationStrategy } from './ActivationStrategy'
import { getUserProfile } from '../../../../api/Identity'
import ModalManager, { CloseReason } from '../../../../lib/ModalManager'

export class VODActivationStrategy extends ActivationStrategy {
  override drawText() {
    const userProfile = getUserProfile()
    const outOfCredits = userProfile && !userProfile.episodeCount

    if (outOfCredits) {
      const outOfCreditsL1 = Language.translate('out_of_credits_msg1')
      const outOfCreditsL2 = Language.translate('out_of_credits_msg2')

      const style = {
        fontSize: 58,
        textColor: COLORS.white,
        verticalAlign: VERTICAL_ALIGN.middle,
        wordWrapWidth: 1384,
      }
      this._ctx.tag('ActivationScreen').patch({
        Title: {
          text: '',
        },
        Title1: {
          text: {
            text: outOfCreditsL1,
            fontWeight: '600',
            maxLines: 1,
            ...style,
          },
        },
        Title2: {
          y: 78,
          text: {
            text: outOfCreditsL2,
            fontFace: FONT_FACE.light,
            maxLines: 2,
            ...style,
          },
        },
      })
      return [outOfCreditsL1, outOfCreditsL2]
    } else {
      const defaultMessage = Language.translate('profile_link_msg')
      this._ctx.tag('Title').text.text = defaultMessage
      return [defaultMessage]
    }
  }

  override destroy(): void {
    if (this._ctx.tag('Title1')) this._ctx.tag('Title1').texture = null
    if (this._ctx.tag('Title2')) this._ctx.tag('Title2').texture = null
    super.destroy()
  }

  override _redirect() {
    if (this._ctx._streamId) {
      const userProfile = getUserProfile()
      const shouldGoToCredits =
        this._activationType === ACTIVATION_TYPE.NBC &&
        !userProfile?.isVideoAlreadyPlayed(this._ctx._streamId)

      if (shouldGoToCredits)
        RouterUtil.navigateToRoute(ROUTE.credits, {
          entity: ROUTE_ENTITY.videoId,
          value: this._ctx._streamId,
        })
      else ModalManager.close(CloseReason.COMPLETED)
    }
  }
}
