import { createRequestConfig } from '../../../lib/useRequest'
import { userInteractionsRequest } from '../../../api/requests'
import Variables from '../../../graphql/Variables'

export const UserInteractionsRequestConfig = () =>
  createRequestConfig({
    query: userInteractionsRequest,
    variables: Variables.userInteractionsVariables(),
    reject: [(data) => !data],
  })
