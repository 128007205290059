import Tile from './Tile'

const DEFAULT_LIVE_TO_VOD_DURATION = 10

export default class GuideProgram extends Tile {
  override obj: any
  get guideProgramData() {
    return this.obj.guideProgramData || {}
  }

  get slotSpan() {
    return this.guideProgramData.slotSpan
  }

  get startSlot() {
    return this.guideProgramData.startSlot
  }

  get endSlot() {
    return this.guideProgramData.endSlot
  }

  get programTitle() {
    return this.guideProgramData.programTitle
  }

  get programDescription() {
    if (this.guideProgramData.seasonNumber && this.guideProgramData.episodeNumber) {
      const seasonInfo = `S${this.guideProgramData.seasonNumber} E${this.guideProgramData.episodeNumber}  |  `
      return `${seasonInfo}${
        this.guideProgramData.episodeTitle || this.guideProgramData.programDescription || ''
      }`
    } else {
      return this.guideProgramData.programDescription || ''
    }
  }

  get ratingWithAdvisories() {
    return this.guideProgramData.ratingWithAdvisories || ''
  }

  get audioDescription() {
    return this.guideProgramData.audioDescription
  }

  get image() {
    return this.guideProgramData.image
  }

  get startTime() {
    return this.guideProgramData.startTime
  }

  get endTime() {
    return this.guideProgramData.endTime
  }

  get channelId() {
    return this.guideProgramData.channelId
  }

  get mpxGuid() {
    return this.guideProgramData.mpxGuid
  }

  get seriesUrlAlias() {
    return this.guideProgramData.seriesUrlAlias
  }

  get nextEpisodeMpxGuid() {
    return this.guideProgramData.nextEpisodeMpxGuid
  }

  get nextEpisodeRelativePath() {
    return this.guideProgramData.nextEpisodeRelativePath
  }

  get allowLiveToVodButton() {
    return this.guideProgramData.allowLiveToVodButton
  }

  get liveToVodButtonDuration() {
    return (this.guideProgramData.liveToVodButtonDuration || DEFAULT_LIVE_TO_VOD_DURATION) * 1000
  }
}
