import { Utils } from '@lightningjs/sdk'
import SettingsButton from './SettingsButton'

export default class SelectButton extends SettingsButton {
  _focusSelectedIcon: any
  _optionValue: any
  _selected: any
  _ancestor = '$valueChanged'
  override _construct() {
    this.selected = false
  }

  /*  _init() {
    this.changeIcon()
  }*/

  set selected(v) {
    this._selected = v
    this.changeIcon()
  }

  get selected() {
    return this._selected
  }

  set ancestor(ancestor: any) {
    this._ancestor = ancestor
  }

  set optionValue(v) {
    this._optionValue = v
  }

  get optionValue() {
    return this._optionValue
  }

  set focusSelectedIcon(v: any) {
    this._focusSelectedIcon = v
  }

  changeIcon() {
    if (!this.tag('Image')) return
    this.tag('Image').patch({
      src: Utils.asset(`images/settings/${this.selected ? 'selected' : 'selection'}.png`),
    })
  }

  override _handleEnter() {
    // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    this.fireAncestors(this._ancestor, this.optionValue)
  }
}
