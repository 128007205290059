import { Log } from '@lightningjs/sdk'
import AuthenticationSingleton from '../../../../authentication/Authentication'
import { StreamAuthorizationType, StreamRequest } from '../request'
import { tempPassExpiredError, tempPassFailedError, unauthenticatedError } from '../error'
import { TEMP_PASS_ERROR } from '../../../../authentication/authConstants'
import { CONTENT_TYPE, PROGRAMMING_TYPES } from '../../../../constants'
import LaunchDarklySingleton from '../../../../lib/launchDarkly/LaunchDarkly'
import LaunchDarklyFeatureFlags from '../../../../lib/launchDarkly/LaunchDarklyFeatureFlags'
import { getUserProfile } from '../../../../api/Identity'

const tempPassRequest = async (initialRequest: boolean) => {
  const auth: {
    error: any
    authType: any
  } = {
    error: false,
    authType: null,
  }

  try {
    await AuthenticationSingleton.createTempPass({ initialRequest })
    auth.authType = StreamAuthorizationType.TEMP_PASS
  } catch (err) {
    Log.warn('LinearSLEFERLoader :: runTempPassFlow initial request failed', err)
    auth.error =
      err === TEMP_PASS_ERROR.SESSION_EXPIRED ? tempPassExpiredError : tempPassFailedError
  }
  return auth
}

const tempPassAuthenticate = async (
  request: StreamRequest,
  program: any,
  stream: any,
  initialRequest = true
): Promise<StreamRequest> => {
  if (!AuthenticationSingleton.isProgramTempPassEligible(program, stream)) {
    request.error = unauthenticatedError('Program not eligible for temp pass')
    throw request
  }

  const { identityRequiredForSecondary = true } = LaunchDarklySingleton.getFeatureFlag(
    LaunchDarklyFeatureFlags.tempPass
  )

  if (!AuthenticationSingleton.isMvpdTempPass()) {
    const tempPassResponse = await tempPassRequest(initialRequest)

    if (tempPassResponse.error) {
      if (identityRequiredForSecondary || !initialRequest) {
        if (identityRequiredForSecondary && initialRequest) {
          return tempPassAuthenticate(request, program, stream, false)
        }
        request.error = tempPassResponse.error
        throw request
      } else {
        return tempPassAuthenticate(request, program, stream, false)
      }
    }
    request.authType = tempPassResponse.authType
    request.initialRequest = initialRequest
  } else {
    request.authType = StreamAuthorizationType.TEMP_PASS
    request.initialRequest = initialRequest
  }
  return request
}

const vodAuthenticate = async (request: StreamRequest): Promise<StreamRequest> => {
  const userProfile = getUserProfile()
  if (!userProfile) {
    request.error = unauthenticatedError({})
    throw request
  }
  return request
}

export const authenticate = (request: StreamRequest, stream: any, program: any) => {
  if (
    program?.offerType?.toLowerCase?.() === 'free' ||
    program?.entitlement?.toLowerCase?.() === 'free'
  ) {
    request.authType = StreamAuthorizationType.UNLOCKED
  } else if (AuthenticationSingleton.isAuthenticated()) {
    if (AuthenticationSingleton.isMvpdTempPass()) {
      return tempPassAuthenticate(request, program, stream)
    }
    request.authType = StreamAuthorizationType.MVPD
  } else {
    const linear = stream?.contentType === CONTENT_TYPE.LINEAR
    if (
      program.programmingType === PROGRAMMING_TYPES.SLE ||
      program.programmingType === PROGRAMMING_TYPES.FER
    ) {
      return tempPassAuthenticate(request, program, stream)
    }
    if (!linear && program?.mpxGuid) {
      return vodAuthenticate(request)
    }

    if (linear) {
      // Don't fail yet, check if geo request has auth kill
      return Promise.resolve(request)
    }

    request.error = unauthenticatedError({})
    return Promise.reject(request)
  }
  return Promise.resolve(request)
}
