import { Lightning } from '@lightningjs/sdk'

import LabelsList, { LabelsListRenderingType } from '../../../labelsList/LabelsList'
import { COLORS, MENU_ITEM_TYPE } from '../../../../constants'
import { ItemLabelsConfigItemFragment } from '../../../../graphql/generated/types'

const SECTION_HEIGHT = 500

export default class Filters extends Lightning.Component {
  _filters: Array<ItemLabelsConfigItemFragment>
  _filterList: LabelsList

  static override _template() {
    return {
      GradientTop: {
        x: 0,
        y: -5,
        w: 490,
        h: 60,
        rect: true,
        colorBottom: COLORS.transparent,
        colorTop: COLORS.black,
        zIndex: 10,
        visible: false,
      },
      FiltersList: {
        clipping: true,
        x: 0,
        y: 0,
        w: 490,
        h: SECTION_HEIGHT,
        type: LabelsList,
        sectionSize: SECTION_HEIGHT,
        mode: LabelsListRenderingType.VERTICAL,
        signals: {
          changeScrollState: '_changeScrollState',
        },
      },
      GradientBottom: {
        x: 0,
        y: 640,
        w: 490,
        h: 80,
        rect: true,
        colorTop: COLORS.transparent,
        colorBottom: COLORS.black,
        zIndex: 10,
      },
    }
  }

  set filters(filters: Array<ItemLabelsConfigItemFragment>) {
    this._filters = filters
    this.tag('FiltersList').items = this._filters?.length
      ? this._filters.map((item: any, index: number) => {
          const isDivider = item.menuItemType === MENU_ITEM_TYPE.DIVIDER
          return {
            autoWidth: false,
            label: item.itemLabel,
            active: index === 0,
            fontSize: isDivider ? 30 : 35,
            focusBackgroundColor: isDivider ? null : COLORS.white,
            fontColor: isDivider ? COLORS.yellow2 : null,
            isDivider,
          }
        })
      : []
  }

  get currentItem() {
    return this._filters?.[this._filterList.activeIndex]?.index || 0
  }

  get initiallySelected() {
    let initallySelected = 0
    if (this._filters?.[initallySelected]?.menuItemType === MENU_ITEM_TYPE.DIVIDER) {
      initallySelected += 1
    }
    return initallySelected
  }

  override _init() {
    this._filterList = this.tag('FiltersList')
  }

  override _getFocused() {
    return this.tag('FiltersList') || this
  }

  selectItem(index: number) {
    this._filterList?.selectItem(index)
  }

  selectFocused() {
    this._filterList?.selectFocused()
  }

  _changeScrollState(isScrolled: boolean) {
    this.patch({
      GradientTop: {
        visible: isScrolled,
      },
    })
  }
}
