import {
  ASTNode,
  FieldNode,
  FragmentDefinitionNode,
  FragmentSpreadNode,
  SelectionNode,
} from 'graphql'

export const removeFieldFromNodeSelectionSet = (
  node: FragmentDefinitionNode | FieldNode,
  name: string | string[]
) => {
  const selections = node.selectionSet?.selections
  const isNotIncluded = Array.isArray(name)
    ? (item: SelectionNode) => !(item.kind === 'Field' && name.includes(item.name.value))
    : (item: SelectionNode) => !(item.kind === 'Field' && item.name.value === name)
  if (selections) {
    node.selectionSet.selections = selections.filter(isNotIncluded)
  }
  return node
}

export const removeFragmentSpreadFromNodeSelectionSet = (
  node: FragmentDefinitionNode | FieldNode,
  name: string | string[]
) => {
  const selections = node.selectionSet?.selections
  const isNotIncluded = Array.isArray(name)
    ? (item: SelectionNode) => !(item.kind === 'FragmentSpread' && name.includes(item.name.value))
    : (item: SelectionNode) => !(item.kind === 'FragmentSpread' && item.name.value === name)
  if (selections) {
    node.selectionSet.selections = selections.filter(isNotIncluded)
  }
  return node
}
export type MatchCase = {
  kind: 'Field' | 'FragmentSpread'
  name: string
  callback: (item: any) => any
}
export const applyToNodeSelectionSet = (node: FieldNode, matchCases: MatchCase[]) => {
  const selections = node.selectionSet?.selections
  if (selections) {
    selections.forEach((field) => {
      matchCases.forEach((condition) => {
        if (field.kind === condition.kind && field.name.value === condition.name) {
          condition.callback(field)
        }
      })
    })
  }
  return node
}

export const removeFragmentsFromNode = (node: ASTNode, fragments: string[]) => {
  const disabledKinds = ['FragmentSpread', 'FragmentDefinition']
  const matches =
    disabledKinds.includes(node.kind) &&
    fragments.includes((node as FragmentSpreadNode | FragmentDefinitionNode).name?.value)
  return matches ? null : undefined
}
