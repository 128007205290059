import { PLATFORM_TYPES } from '../../helpers'
import ComcastPlatform from './comcast'
import TizenPlatform from './tizen'
import XboxPlatform from './xbox'
import VizioPlatform from './vizio'
import KeplerPlatform from './kepler'

const getDevicePlatform = () => {
  switch (DEVICE_PLATFORM) {
    case PLATFORM_TYPES.TIZEN:
      return new TizenPlatform()
    case PLATFORM_TYPES.XBOX:
      return new XboxPlatform()
    case PLATFORM_TYPES.VIZIO:
      return new VizioPlatform()
    case PLATFORM_TYPES.KEPLER:
      return new KeplerPlatform()
    default:
      return new ComcastPlatform()
  }
}

const TVPlatform = getDevicePlatform()
export default TVPlatform
