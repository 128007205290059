import { BehaviorSubject, Subject } from 'rxjs'
import { COMPONENT_TYPES, EPG_VIEWS } from '../../constants'
import {
  LinksSelectableGroupFragment,
  NestedLinksSelectableGroupFragment,
  PlaceholderFragment,
} from '../../graphql/generated/types'
import moment from 'moment-timezone'
import { cloneDeep } from 'lodash'

export const isNestedLinksSelectableGroup = (
  input: any
): input is NestedLinksSelectableGroupFragment =>
  typeof input === 'object' && !!input?.['data']?.items

export enum EpgGuideEvents {
  PROGRESS = 'progress',
  VIEW_CHANGE = 'viewChange',
  TAB_DATA = 'tabData',
}

export type EpgGuideSubscription =
  | {
      type: EpgGuideEvents.PROGRESS
      handler: (progress: number) => void
    }
  | {
      type: EpgGuideEvents.VIEW_CHANGE
      handler: (view: EPG_VIEWS) => void
    }
  | {
      type: EpgGuideEvents.TAB_DATA
      handler: (data: LinksSelectableGroupFragment) => void
    }

const defaultTabData = {
  component: 'LinksSelectableGroup',
  data: { instanceID: '', initiallySelected: 0, itemLabels: [], items: [] },
  treatment: '',
  treatments: [],
}

/**
 * Controller for Live Guide (EPG) view
 * Handles tab and program progress data/events
 */
class GuideController {
  // Tab properties
  private _tabIndex = -1
  private _tabDataSubject: BehaviorSubject<LinksSelectableGroupFragment> | undefined =
    new BehaviorSubject<LinksSelectableGroupFragment>(cloneDeep(defaultTabData))
  private _viewSubject: Subject<EPG_VIEWS> | undefined = new Subject<EPG_VIEWS>()
  // Progress properties
  private _startTime?: moment.Moment
  private _updateTimer: NodeJS.Timeout | number | null
  private _progressSubject: BehaviorSubject<number> | undefined = new BehaviorSubject<number>(0)
  private _updateIntervalTime = 10000 // ms
  private _programUpdateInterval = 1800 // seconds

  subscribe({ type, handler }: EpgGuideSubscription) {
    const subject = this.getSubject(type)
    return (subject as any)?.subscribe(handler)
  }

  getSubject(type: EpgGuideEvents): Subject<any> | BehaviorSubject<any> | undefined {
    switch (type) {
      case EpgGuideEvents.PROGRESS:
        if (!this._progressSubject) this._progressSubject = new BehaviorSubject<number>(0)
        return this._progressSubject
      case EpgGuideEvents.VIEW_CHANGE:
        if (!this._viewSubject) this._viewSubject = new Subject<EPG_VIEWS>()
        return this._viewSubject
      case EpgGuideEvents.TAB_DATA:
        if (!this._tabDataSubject)
          this._tabDataSubject = new BehaviorSubject<LinksSelectableGroupFragment>(
            cloneDeep(defaultTabData)
          )
        return this._tabDataSubject
      default:
        return undefined
    }
  }

  clear() {
    if (this._tabDataSubject) {
      this._tabDataSubject?.complete()
      this._tabDataSubject = undefined
    }
    if (this._viewSubject) {
      this._viewSubject?.complete()
      this._viewSubject = undefined
    }
    if (this._progressSubject) {
      this._progressSubject?.complete()
      this._progressSubject = undefined
    }
  }

  // #region Tab methods/getters
  get tabs() {
    return this._tabDataSubject?.value.data?.items as unknown as (
      | PlaceholderFragment
      | NestedLinksSelectableGroupFragment
    )[]
  }

  get currentTab() {
    return this.tabs?.[this._tabIndex]
  }

  get currentEpgView() {
    const tab = this.tabs?.[this._tabIndex]
    switch (tab?.component) {
      case COMPONENT_TYPES.NESTED_LINK_SELECTABLE_GROUP:
        return EPG_VIEWS.filters
      case COMPONENT_TYPES.PLACEHOLDER_ELEMENT:
      default:
        return EPG_VIEWS.channels
    }
  }

  setTabs(tabs: LinksSelectableGroupFragment) {
    if (!tabs) return
    this._tabDataSubject?.next(tabs)
  }

  setActiveTab(index: number) {
    if (index === this._tabIndex || index >= this.tabs?.length || index < 0) return
    this._tabIndex = index
    this.getSubject(EpgGuideEvents.VIEW_CHANGE)?.next(this.currentEpgView)
  }
  // #endregion

  // #region Progress methods/getters
  get progress() {
    return this._progressSubject?.value || 0
  }

  setupTabIndex() {
    this._tabIndex = -1
  }

  setupProgress(startTime: string) {
    this.resetProgress()
    this._startTime = moment(startTime)
    this.getSubject(EpgGuideEvents.PROGRESS)?.next(0)
    this._startProgress()
  }

  resetProgress() {
    this._clearProgressUpdateInterval()
  }

  private _startProgress(): void {
    this._updateProgress()
    this._updateTimer = setInterval(this._updateProgress, this._updateIntervalTime)
  }

  private _updateProgress = (): void => {
    const subject = this.getSubject(EpgGuideEvents.PROGRESS)
    if ((subject as BehaviorSubject<number>)?.value < 100) {
      subject?.next((moment().diff(this._startTime, 'seconds') * 100) / this._programUpdateInterval)
    }
  }

  private _clearProgressUpdateInterval(): void {
    if (this._updateTimer) {
      clearInterval(this._updateTimer)
      this._updateTimer = null
    }
  }
  // #endregion
}

export const EpgGuideController = new GuideController()
