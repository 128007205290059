import Lightning from '@lightningjs/sdk/src/Lightning'
import { EPG_VIEWS, LiveGuideV2States } from '../../../constants'
import EpgGuideV2 from '..'
import { EpgGuideController } from '../EpgGuideController'

export const TabsStateFactory = (base: Lightning.Component.Constructor<EpgGuideV2>) =>
  class Tabs extends base {
    override _getFocused() {
      return this._tabs || super._getFocused()
    }

    override _handleLeft() {
      this._tabs?.changeIndex(-1)
    }

    override _handleRight() {
      this._tabs?.changeIndex(1)
    }

    override _handleEnter() {
      this._tabs?.selectCurrent()
    }

    private _restoreFocus() {
      if (this._channelsGuide.loaded || this._filtersGuide.items?.length) {
        const view = EpgGuideController.currentEpgView
        this._setState(
          view === EPG_VIEWS.channels ? LiveGuideV2States.Channels : LiveGuideV2States.Filters
        )
      } else {
        this._setState(LiveGuideV2States.Tabs)
      }
    }

    override _handleUp() {
      this._restoreFocus()
      this._setState(LiveGuideV2States.AssetInfo)
      this._channelsGuide.changeBrandIndex(-1)
    }

    override _handleDown() {
      this._restoreFocus()
      if (EpgGuideController.currentEpgView == EPG_VIEWS.channels) {
        this._channelsGuide.changeBrandIndex(0)
        this._channelsGuide?.streams?.children[0]?.setChildProps({
          focused: true,
          channelId: this._channelsGuide?.streams.children[0]._item.channelId,
        })
      }
    }
  }
