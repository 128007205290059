import { Language } from '@lightningjs/sdk'

import IPlayerControlsStrategy from './IPlayerControlsStrategy'

import { formatTime } from '../../../../helpers'
import { PLAYER_TYPE, PlayerFactorySingleton } from '../../../../player/core/PlayerFactory'
import { TimeChangeEvent } from '../../../../player/model/event'
import { createProgressHookEvent } from '../hooks/useProgress'
import { SeekableRange } from '@sky-uk-ott/core-video-sdk-js'
import SLEPlayerControls from '../SLEPlayerControls'

export default class SLEStrategy extends IPlayerControlsStrategy {
  override parent: SLEPlayerControls
  _startDateTime: number
  _endDateTime: number
  override init(stream: any) {
    this._startDateTime = new Date(stream.airDate).getTime()
    this._endDateTime = new Date(stream.endTime).getTime()
    this._totalTime = (this._endDateTime - this._startDateTime) / 1000
    this.parent.setTitle(
      stream.league || stream.sports || Language.translate('program_unavailable')
    )
    this.parent.setSecondaryTitle(stream.title)

    this.parent._timer.aTime = formatTime(stream.airDate)
    this.parent._timer.bTime = formatTime(stream.endTime)
    this._progress.set(
      createProgressHookEvent({
        currentTime: 0,
        duration: this._totalTime,
      })
    )
  }

  override update(time: TimeChangeEvent) {
    if (!PlayerFactorySingleton.player(PLAYER_TYPE.MAIN)?.isPlaying()) return

    /* The way the player sends us time change events, we receive a time that
       doesn't fit on our seek bar, which is zero based and calculates up to the duration

       Instead, the player sends a seekable range.  It's best to think of us positioning
       an entire bar instead of a scrubber.  The bar, since it's a range, can be behind our
       scrubber.

       The code below calculates where our scrubber should be based on the range, making
       the currentTime zero based (instead of the time being passed by the change event which doesnt relate)

       Finally, the seekAdjust is passed to the hook.  This will be used when seeking to add back
       in the values needed to actually perform the seek since the player is operating on a non-zero relative start
    */

    let adjustedTime = 0
    let adjustedSeekableRange: SeekableRange = { start: 0, end: 0 }
    let seekAdjust = 0

    if (time.seekableRange) {
      // use the start date and the time start date to determine our zero location
      // find the difference in time between the current time event start date and the original start date
      const startDateTime = time.seekableRange.startDate?.getTime() || 0
      const startTimeDifference = Math.round((startDateTime - this._startDateTime) / 1000)
      // get the seekable range size in seconds
      const seekableRangeDifference = Math.round(time.seekableRange.end - time.seekableRange.start)
      // get the difference between the seekable range end time and the current time in seconds
      const endTimeDifference = time.seekableRange.end - time.time
      // adjusted time is the scrubber position.  we are finding the position on our bar in seconds
      adjustedTime = startTimeDifference + seekableRangeDifference - endTimeDifference
      // check edge cases to ensure that the total time is adjusted if sle goes past scheduled end date/time
      const currentDateTime = Date.now()

      if (currentDateTime > this._endDateTime) {
        this._totalTime = (currentDateTime - this._startDateTime) / 1000
        this.parent._timer.bTime = formatTime()
      } else if (adjustedTime > this._totalTime) {
        this._totalTime = adjustedTime + endTimeDifference
      }
      // adjust the seekable range to work with our seeking components
      adjustedSeekableRange = {
        ...time.seekableRange,
        start: Math.max(0, startTimeDifference),
        end: Math.min(adjustedTime + endTimeDifference, this._totalTime),
      }
      // seekAdjust is passed to our progress hook so that we know how much to add in to any
      // seek value for the player so the player value aligns properly with the sle ranges
      seekAdjust = time.time - adjustedTime
    }

    this._progress.set(
      createProgressHookEvent({
        currentTime: adjustedTime,
        seekableRange: adjustedSeekableRange,
        duration: this._totalTime,
        seekAdjust: seekAdjust,
      })
    )
  }
}
