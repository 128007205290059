import mParticle from '@mparticle/web-sdk'
import getLinearProgramStartAttributes from '../attributes/getLinearProgramStartAttributes'

const getEvent = () => ({
  getAttributes: getLinearProgramStartAttributes,
  getCategory: () => mParticle.EventType?.UserContent,
  type: 'Linear Program Start',
  injectGlobals: true,
})

export default getEvent
