import { Language, Router } from '@lightningjs/sdk'

import ConfirmationDialog from './ConfirmationDialog'
import { getBrandName } from '../helpers'
import BuildScheme from '../lib/BuildScheme'
import { sendMetric } from '../lib/analytics/Analytics'
import { EVENTS } from '../lib/analytics/types'
import ModalManager, { isBlockingModalOpen } from '../lib/ModalManager'
import { ModalTypeTagMap } from './Modals'

export default class ExitDialog extends ConfirmationDialog {
  _isTempPass: any
  _exitAppMessage: any
  _program: any
  static override _template() {
    return super._template()
  }

  set isTempPass(isTempPass: any) {
    this._isTempPass = isTempPass
  }

  set program(program: any) {
    this._program = program
  }

  override _active() {
    this._exitAppMessage = this._isTempPass
      ? Language.translate(
          'tempPass-exitDialog-exitApp',
          BuildScheme[getBrandName() as keyof typeof BuildScheme].displayName
        )
      : Language.translate(
          'exit_app_msg',
          BuildScheme[getBrandName() as keyof typeof BuildScheme].displayName
        )
    super._setMessage(
      this._exitAppMessage,
      Language.translate('cancel'),
      Language.translate('exit')
    )
    this._setState('Cancel')
  }

  stopEventBubbling(e: any) {
    e.preventDefault()
    e.stopPropagation()
  }

  // @ts-expect-error TS(2416): Property '_handleBack' in type 'ExitDialog' is not... Remove this comment to see the full error message
  _handleBack(e: any) {
    Router.focusWidget('Menu')
    this.visible = false
    this._isTempPass = false
    this.stopEventBubbling(e)
  }

  // @ts-expect-error TS(2416): Property '_handleUp' in type 'ExitDialog' is not a... Remove this comment to see the full error message
  _handleUp(e: any) {
    this.stopEventBubbling(e)
  }

  // @ts-expect-error TS(2416): Property '_handleDown' in type 'ExitDialog' is not... Remove this comment to see the full error message
  _handleDown(e: any) {
    this.stopEventBubbling(e)
  }

  override _handleLeft(e: any) {
    this.stopEventBubbling(e)
  }

  override _handleRight(e: any) {
    this.stopEventBubbling(e)
  }

  static override _states() {
    return [
      class Exit extends this {
        override _isTempPass: any
        override _getFocused() {
          return this.tag('ConfirmButton') || this
        }

        override _handleLeft() {
          this._setState('Cancel')
        }

        override _handleEnter() {
          if (this._isTempPass) {
            const params = {
              name: 'TempPass - Stream on Peacock',
              destinationURL: 'Peacock',
              destinationType: 'Peacock',
              notificationTitle: this._exitAppMessage,
              brand: this._program?.brand.title,
              show: this._program?.programTitle,
              season: this._program?.seasonNumber,
            }
            sendMetric(EVENTS.EXIT, params)
          }
          this.fireAncestors(this._isTempPass ? '$exitToPeacock' : '$close')
          this.visible = false
          this._isTempPass = false
        }
      },
      class Cancel extends this {
        override _isTempPass: any
        override _getFocused() {
          return this.tag('CancelButton') || this
        }

        override _handleRight() {
          this._setState('Exit')
        }

        override _handleEnter() {
          if (this._isTempPass) {
            if (isBlockingModalOpen()) {
              const activeModal = ModalManager.getActiveModal()
              if (activeModal) {
                const { key } = ModalTypeTagMap[activeModal]
                Router.focusWidget(key)
              }
            } else Router.focusPage()
          } else Router.focusWidget('Menu')

          this._isTempPass = false
          this.visible = false
        }
      },
    ]
  }
}
