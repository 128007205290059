export enum ACTIVATION_LANDING {
  NBC = 'nbcFirst', // nbc login
  MVPD = 'mvpdFirst', // linking tv provider
  FORK = 'fork', // mpvd or nbc forked sign-in flow
}

export enum ACTIVATION_REFERRER {
  LINEAR = 'linear',
  EVENT = 'event',
  VOD = 'vod',
  TEMPPASS = 'temppass',
  CONTENT_GATING = 'contentGating',
}

export enum ACTIVATION_TYPE {
  NBC = 'NBC',
  MVPD = 'MVPD',
  TEMPPASS = 'TEMPPASS',
}

export const ACTIVATION_SCREEN_FONT_OVERFLOW_MULTIPLIER = 2.6
