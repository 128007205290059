import { IPlayerEvent } from './IPlayerEvent'
export class BufferLengthChangeEvent extends IPlayerEvent {
  _length

  constructor(length: any) {
    super()
    this._length = length
  }

  get length() {
    return this._length
  }
}
