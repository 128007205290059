import { TimeChangeEvent } from '../../../../player/model/event'
import { SingleStream } from '../../../../store/PlayerStore/actions'
import { Program } from '../../../../store/PlayerStore/PlayerStore'
import BasePlayerControls from '../BasePlayerControls'
import { useProgress } from '../hooks/useProgress'

/**
 * Controls how the Player Controls are updated
 */
export default class IPlayerControlsStrategy {
  _totalTime = 0
  _progress = useProgress()
  parent: BasePlayerControls

  constructor(parent: BasePlayerControls) {
    // Reference to the PlayerControls component
    this.parent = parent
  }

  init(stream: SingleStream, program: Program) {
    // This should be overridden
  }

  // Should be called on TimeChange events
  update(time: TimeChangeEvent) {}
}
