import { Lightning, Log } from '@lightningjs/sdk'
import SeekablePlayerControlsV2 from './SeekablePlayerControlsV2'

import LinearStrategy from './strategies/LinearStrategy'

import { KeyMap } from '../../../lib/KeyMap'

import IPlayerControlsStrategy from './strategies/IPlayerControlsStrategy'
import { TimeChangeEvent } from '../../../player/model/event'
import { COLORS, PlayerControlsStates } from '../../../constants'
import { ProgressHookEvent } from './hooks/useProgress'
import moment from 'moment-timezone'
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore'
import { SingleStream } from '../../../store/PlayerStore/actions'
import { TimerDesign } from './components/Timer'
import { HeadingV2 } from './components/HeadingV2'
import { SecondaryControlButtons } from './components/HeadingV2/SecondaryControls'
import {
  LiveToVodButtonStates,
  LiveToVodEvent,
} from '../../../pages/Player/delegates/LiveToVodDelegate'
import { TitleContainerStateFactory } from './states/TitleContainer'

const LIVE_TO_VOD_TAG = 'LiveToVod::'

export default class LinearPlayerControlsV2 extends SeekablePlayerControlsV2 {
  _liveToVodEvents: any
  _heading: HeadingV2

  override _strategy: IPlayerControlsStrategy = new LinearStrategy(this)
  static override PROGRESSBAR_TOTAL_WIDTH = 1400

  override _keyMap = KeyMap(this, [
    [[], PlayerControlsStates.TitleContainer, []],
    [[], PlayerControlsStates.PlayOrPause, []],
  ])

  static override _template() {
    return super._template({
      PlayerControls: {
        x: 70,
        SettingsContainer: undefined,
        TitleContainer: {
          type: HeadingV2,
          buttons: [
            SecondaryControlButtons.LiveToVod,
            SecondaryControlButtons.BackToLiveButton,
            SecondaryControlButtons.LiveGuideButton,
            SecondaryControlButtons.MediaOptions,
          ],
          HeadingContainer: undefined,
          SecondaryTitle: undefined,
          Description: undefined,
        },
        ControlsWrapper: {
          y: 960,
          x: 28,
          PlayOrPause: {
            y: 8,
          },
          Progress: {
            y: 10,
            x: 70,
            CurrentBarBg: {
              w: 0,
              h: 8,
              y: 7,
              rect: true,
              alpha: 0.5,
              color: COLORS.red,
            },
            CurrentBar: {
              color: COLORS.red,
            },
            SeekBar: {
              zIndex: 10,
              BigCircle: undefined,
              SmallCircle: undefined,
              Shader: {
                rect: true,
                w: 24,
                h: 24,
                x: 0,
                y: 0,
                mountX: 0.5,
                shader: {
                  type: Lightning.shaders.RoundedRectangle,
                  strokeColor: COLORS.white,
                  fillColor: COLORS.red,
                  stroke: 3,
                  radius: 12,
                },
              },
            },
          },
          Timer: {
            x: 0,
            y: 0,
            w: 1740,
            design: TimerDesign.V2,
          },
        },
      },
    })
  }

  override _init() {
    super._init()
    this._heading = this.tag('TitleContainer')
  }

  override _enable() {
    super._enable()
    this._liveToVodEvents?.unsubscribe()
    this._liveToVodEvents = this.fireAncestors('$subscribeToLiveToVodDelegate', this._sync)
    this._setState(PlayerControlsStates.PlayOrPause)
  }

  override _disable() {
    super._disable()
    this._liveToVodEvents?.unsubscribe()
  }

  setTitle(title: string) {
    this._heading.setTitle(title)
  }

  setSecondaryTitle(title: string) {
    this._heading.setSecondaryTitle(title)
  }

  override get seekingAllowed() {
    return true
  }

  _sync = ({ status, label }: LiveToVodEvent) => {
    const messages = {
      [LiveToVodButtonStates.EMPTY_STATE]: `${LIVE_TO_VOD_TAG} empty state, will not show`,
      [LiveToVodButtonStates.PLAY_FROM_BEGINNING]: `${LIVE_TO_VOD_TAG} play from beginning button`,
      [LiveToVodButtonStates.PLAY_NEXT_EPISODE]: `${LIVE_TO_VOD_TAG} play next episode button`,
      [LiveToVodButtonStates.GO_TO_SHOW]: `${LIVE_TO_VOD_TAG} go to show button`,
    }
    Log.info(messages[status])

    this._heading.toggle(
      SecondaryControlButtons.LiveToVod,
      status !== LiveToVodButtonStates.EMPTY_STATE
    )
    this._heading.setLabel(SecondaryControlButtons.LiveToVod, label)
  }

  override _updateProgress(progress: ProgressHookEvent) {
    const [currentTime, duration] = progress
    // Workaround for race condition issue on program boundary change
    if (duration && currentTime > duration) {
      const stream = PlayerStoreSingleton.stream as SingleStream
      const program = PlayerStoreSingleton.program
      if (moment().isBefore(moment(stream?.endTime))) {
        this._strategy.init(stream, program)
      }
    } else {
      super._updateProgress(progress)
    }
  }

  override _playerSubscriptionHandler(event: any) {
    super._playerSubscriptionHandler(event)
    if (event instanceof TimeChangeEvent) {
      /**
       * Toggle "back to live" button based on isAtLiveEdge property from player
       * Sometimes isAtLiveEdge is undefined even if we are on the live edge, so check it for the boolean type.
       */
      if (this.active && typeof event._isAtLiveEdge === 'boolean') {
        this._heading.toggle(SecondaryControlButtons.BackToLiveButton, !event._isAtLiveEdge)
      }
    }
  }

  static override _states() {
    return [
      ...super._states(),
      class LiveToVod extends this {
        override _getFocused() {
          return this.tag('LiveToVod') || this
        }
      },
      TitleContainerStateFactory(this),
    ]
  }

  override _setRating() {
    this._tvRating.visible = false
  }

  override _setLogo(src?: string) {
    this._heading.setLogo(src)
  }

  // AdControls not used for linear
  override showAdControls() {}
}
