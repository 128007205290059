import { Lightning } from '@lightningjs/sdk'

import { COLORS } from '../../constants'

export default class Button extends Lightning.Component {
  _autoWidth: any
  _explicitWidth: any
  _focusBackGroundColor: any
  _isFocus: any
  _paddingLeft: any
  _paddingRight: any
  _radius: any
  _unfocusBackgroundColor: any
  static override _template() {
    return {
      w: (w: any) => w,
      h: (h: any) => h,
      Shadow: {
        alpha: 0,
        color: COLORS.black7,
        x: -10,
        y: -10,
      },
      BG: {},
    }
  }

  override _construct() {
    this._radius = 40
    this._focusBackGroundColor = COLORS.lightGray3
    this._unfocusBackgroundColor = COLORS.dark
    this._autoWidth = false
    this._paddingLeft = 30
    this._paddingRight = 30
    this._explicitWidth = 30
  }

  override _init() {
    if (this._autoWidth) {
      this._calculateButtonWidth()
    } else {
      this._updateButton()
    }
  }

  override _firstActive() {
    //     if (this._autoWidth) {
    //     } else {
    //     }
  }

  set radius(v: any) {
    this._radius = v
  }

  set focusBackGroundColor(focusBackGroundColor: any) {
    this._focusBackGroundColor = focusBackGroundColor
  }

  set unfocusBackgroundColor(unfocusBackgroundColor: any) {
    this._unfocusBackgroundColor = unfocusBackgroundColor
  }

  set autoWidth(v: any) {
    this._autoWidth = v
  }

  set padding(v: any) {
    this._paddingLeft = v
    this._paddingRight = v
  }

  set paddingLeft(v: any) {
    this._paddingLeft = v
  }

  set paddingRight(v: any) {
    this._paddingRight = v
  }

  _calculateButtonWidth() {
    this.w = this._explicitWidth + this._paddingLeft + this._paddingRight
    this._updateButton()
  }

  _updateButton() {
    const bgColor = this._isFocus ? this._focusBackGroundColor : this._unfocusBackgroundColor
    this.patch({
      Shadow: {
        alpha: 0,
      },
      BG: {
        texture: Lightning.Tools.getRoundRect(this.w, this.h, this._radius, 0, 0, true, bgColor),
        mount: 0.5,
      },
    })

    if (this._isFocus) {
      this.tag('Shadow').patch({
        alpha: 1,
        w: this.w + 20,
        h: this.h + 20,
        texture: Lightning.Tools.getShadowRect(this.w + 20, this.h + 20, 10, 40, 30),
        mount: 0.5,
      })
    }
  }

  override _focus() {
    this._isFocus = true
    this._updateButton()
  }

  override _unfocus() {
    this._isFocus = false
    this._updateButton()
  }
}
