import { Language } from '@lightningjs/sdk'
import moment from 'moment-timezone'

import { ALGOLIA_ENTITY_TYPES, getTimezone } from '../../../helpers'
import { adjustTimestampByOffset, isCurrentDay } from '../helpers'

const checkExpiredSle = (date: any) => {
  const currentDate = moment().format('YYYY-MM-DD')
  return moment(date?.algoliaProperties?.eventDate).isSameOrAfter(moment(currentDate))
}

const reduceDates = (dates: any, page: any) => {
  const zone = moment.tz.zone(getTimezone())
  const currentTimeZoneOffset = zone?.parse(Date.now())

  return dates?.reduce(
    (acc: any, date: any) => {
      if (
        !date?.algoliaProperties ||
        (date?.algoliaProperties.entityType === ALGOLIA_ENTITY_TYPES.SLE && checkExpiredSle(date))
      ) {
        return acc
      }
      const { eventDate } = date?.algoliaProperties || {}
      let { localStartTimestamp, localEndTimestamp } = date?.algoliaProperties || {}

      if (zone && currentTimeZoneOffset) {
        const dateTimezoneOffset = zone.parse(localStartTimestamp * 1000)
        if (dateTimezoneOffset !== currentTimeZoneOffset) {
          const offsetTime = dateTimezoneOffset - currentTimeZoneOffset
          localStartTimestamp = adjustTimestampByOffset(localStartTimestamp, offsetTime)
          localEndTimestamp = adjustTimestampByOffset(localEndTimestamp, offsetTime)
        }
      }

      const formattedDate = isCurrentDay(eventDate)
        ? Language.translate('today')
        : moment(eventDate).format('ddd MM/DD')
      return [
        ...acc,
        {
          label: formattedDate,
          value: eventDate,
          localStartTimestamp,
          localEndTimestamp,
        },
      ]
    },
    page === 0
      ? [
          {
            label: Language.translate('all'),
            value: 'all',
          },
        ]
      : []
  )
}

export const dateMapper = (datesData: any) => ({
  dates: reduceDates(datesData?.hits, datesData?.page),
  page: datesData?.page,
  totalPages: datesData?.nbPages,
})
