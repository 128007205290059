import mParticle from '@mparticle/web-sdk'
import getClickAttributes from '../attributes/getClickAttributes'

const trackClick = () => ({
  getAttributes: getClickAttributes,
  getCategory: () => mParticle.EventType?.Navigation || 'Navigation',
  type: 'Click Action',
  injectGlobals: true,
})

export default trackClick
