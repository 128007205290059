import {
  getAssetAuthAnalytics,
  getPlatformName,
  getSignInType,
  setDefaultValue,
} from '../../../../helpers'
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants'

import getAuthAttributes, { AuthAttributesParams } from './getAuthAttributes'
import getPageAttributes from './getPageAttributes'
import ModalManager from '../../../ModalManager'

type NbcAuthSuccessAttributesParams = AuthAttributesParams & {
  userProfile?: any
  path?: any
  program?: string
}
export const getNbcAuthSuccessAttributes = (params: NbcAuthSuccessAttributesParams = {}) =>
  setDefaultValue(
    {
      ...getAuthAttributes(params),
      'Sign In Type': getSignInType(params.userProfile),
      'Referring Page': getPageAttributes(ModalManager._activeModal || params.path).name,
      'Registration Source': getPlatformName(),
      ...getAssetAuthAnalytics(params.program),
    },
    MPARTICLE_DEFAULT_ATTR_VALUE
  )

export default getNbcAuthSuccessAttributes
