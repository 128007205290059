export default class IAuthenticationService {
  /** Returns the user data
   * @return {Promise<Response>} The user data.
   */
  getUserMetadata() {}
  /**
   * Returns the Multichannel Video Programming Distrbutor (MVPD) information
   * for the authenticated user, e.g. (Comcast, Dish, AT&T)
   * @return {MvpdData | null | undefined } The MVPD data.
   */
  getMvpdData() {}
  /**
   * Returns whether the user is authenticated.
   * @return {boolean} The authentication result.
   */
  isAuthenticated() {}
  /**
   * Returns the registration code for authentication.
   * @return {Promise<JSON>} The registration code for authentication.
   */
  getRegCode() {}
  /**
   * Return whether the program is eligible for temporary pass. Eligibility: MVPD-Locked Live Non-RSN Sports SLE.
   * @param {object} program The program to check.
   * @return {boolean} temp pass eligibility.
   */
  isProgramTempPassEligible(program: any, stream: any): any {}
  /**
   * @return {Promise<SuccessStatus|ErrorStatus>} The success or error of a temporary pass creation request.
   ** 204: Success
   ** Other: Error
   */
  createTempPass() {}
  /**
   * Runs a series of steps to check for and create a temporary pass for this device.
   * @return {Promise<Success|Error>} The response or error of a temporary pass deletion request.
   */
  runTempPassFlow() {}
  /**
   * @param {string} tempPassKey 'TempPass-OneApp-{1|2|5|10|15|20|25|30}min'
   * @return {Promise<SuccessStatus|ErrorStatus>} The Response status code:
   ** 204: Success
   ** 400: Incorrect Request
   ** 401: ApiKey was not specified
   ** 403: ApiKey is invalid
   */
  resetTempPass(tempPassKey: any) {}
  /**
   * Activates polling authentication service for authentication status.
   * @return {Promise<void>} The result of activting polling
   */
  pollStatus() {}
  /**
   * Check status of authentication.
   * @return {Promise<Json>} The result of checking authentication status
   */
  checkStatus() {}
  /**
   * Logout of authentication service.
   * @return {Promise<void>}
   */
  logout() {}
  /**
   * Check authorization of video asset.
   * @param asset Video asset
   * @return {Promise<string | Response>} The media token required for playback
   */
  authorize(asset: any) {}
}
