import { GraphQlClient } from '../../graphql/client'
import {
  PaginatedComponentQuery,
  PaginatedComponentQueryVariables,
} from '../../graphql/generated/types'
import paginatedComponentMapper from '../../graphql/mappers/paginatedComponent'
import rsnFilter from '../../graphql/filter/rsnFilter'
import { QueryName } from './bffQuery'

export default async (variables: PaginatedComponentQueryVariables) => {
  return await GraphQlClient.query<PaginatedComponentQuery>({
    query: QueryName.paginatedComponent,
    variables,
  })
    .then(paginatedComponentMapper)
    .then(rsnFilter)
}
