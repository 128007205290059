import {
  COLORS,
  FONT_FACE,
  PROGRAMMING_TYPES,
  VOD_PROGRAMMING_TYPES,
  MAX_LINES_SUFFIX,
} from '../../../constants'
import { pipeSeparate, formatDate, getFirstNonNull, getAsString } from '../../../helpers'
import { FastImg, isAClip } from '../../../helpers'
import PlayerStoreSingleton, { Program } from '../../../store/PlayerStore/PlayerStore'
import { SingleStream, isSingleProgram } from '../../../store/PlayerStore/actions'
import { EpgProgram, EpgStream } from '../../../store/PlayerStore/actions/epg'
import { OLY_IMPOLICY } from '../../../constants'
import { isNumberLike } from '../../../util/comparison'

const MAX_SIZE_SUBTITLE = 120

export const getTitles = (data: any) => {
  let title, secondaryTitle
  if (data.programmingType && data.programmingType !== PROGRAMMING_TYPES.NONE) {
    switch (data.programmingType) {
      case PROGRAMMING_TYPES.MOVIE:
        title = data.title
        secondaryTitle = pipeSeparate([data.rating, data.secondaryTitle], ' | ', ' | ')
        break
      case PROGRAMMING_TYPES.CLIP:
      case PROGRAMMING_TYPES.HIGHLIGHT:
      case PROGRAMMING_TYPES.EXCERPT:
      case PROGRAMMING_TYPES.WEB_EXCLUSIVE:
      case PROGRAMMING_TYPES.SNEAK_PEAK:
      case PROGRAMMING_TYPES.SFVOD:
      case PROGRAMMING_TYPES.PREVIEW:
        title = data.title
        secondaryTitle = data.secondaryTitle
        break
      case PROGRAMMING_TYPES.FULL_EPISODE: {
        const titlesArray = data.title?.split('|')
        const seasonAndNumber = titlesArray && titlesArray[0]

        title = data.seriesShortTitle || data.series
        secondaryTitle = seasonAndNumber
          ? seasonAndNumber + ' · ' + data.secondaryTitle
          : data.videoTitle +
            ' | S' +
            data.seasonNumber +
            ' · E' +
            data.episodeNumber +
            ' ' +
            data.episodeTitle
        break
      }
      default: {
        if (isAClip(data.programmingType)) {
          const titleArray = data?.title?.split('|')
          title = data.secondaryTitle
          secondaryTitle = titleArray && (titleArray[1] || titleArray[0])
        } else {
          secondaryTitle = data.secondaryTitle || data.brandDisplayTitle
          title = data.title || data.videoTitle
        }
      }
    }
  } else {
    if (data.episodeNumber && data.seasonNumber) {
      if (data.episodeNumber === '0' || data.seasonNumber === '0') {
        secondaryTitle = pipeSeparate(formatDate(data.airDate))
      } else {
        title = data.seriesShortTitle || data.title
        secondaryTitle =
          data.secondaryTitle ||
          `S${data.seasonNumber} E${data.episodeNumber} · ${data.shortDescription}`
      }
    } else {
      title = data.programTitle || data.secondaryTitle || data.brandDisplayTitle
      secondaryTitle = data.shortDescription || pipeSeparate(data.title) || data.videoTitle
    }
  }
  if (title === secondaryTitle) {
    secondaryTitle = ''
  }
  return { title, secondaryTitle }
}

export const addBaseTitleContainer = (titles: any, data: any, showDescription = false) => {
  const hasSecondLine = titles?.title?.length > 66 // FIXME: We should find a better method for checking if it's going to be two lines
  return {
    Logo: {
      x: 0,
      y: hasSecondLine ? -44 : 10,
      w: 100,
      h: 100,
      flex: { direction: 'row', alignItems: 'center' },
      LogoImage: {
        x: 0,
        y: 0,
        texture: FastImg(data.whiteBrandLogo).contain(100, 100),
      },
    },
    Title: {
      x: 143,
      y: hasSecondLine ? -66 : 0,
      text: {
        // Ensure it's going to wrap by setting a lesser width than 1670 default
        wordWrapWidth: hasSecondLine ? 1600 : 1920,
        maxLines: 2,
        maxLinesSuffix: MAX_LINES_SUFFIX,
        fontSize: 58,
        fontFace: FONT_FACE.light,
        textColor: COLORS.white4,
        text: titles.title,
      },
    },
    SecondaryTitle: {
      x: 143,
      y: 66,
      text: {
        fontSize: 28,
        fontFace: FONT_FACE.light,
        textColor: COLORS.white4,
        text: titles.secondaryTitle,
      },
    },
    Description: showDescription
      ? {
          x: 143,
          text: {
            fontSize: 28,
            textColor: COLORS.white4,
            text: data.description,
          },
        }
      : {},
  }
}

export const addSFVODTitleContainer = (titles: any, data: any, showDescription = false) => {
  return {
    Logo: {
      x: 0,
      y: 10,
      texture: FastImg(data.whiteBrandLogo).contain(100, 100, OLY_IMPOLICY.SQUARE),
    },
    Title: {
      x: 143,
      text: {
        fontSize: 28,
        text: titles.title,
      },
    },
    SecondaryTitle: {
      x: 143,
      y: 30,
      text: {
        fontSize: 58,
        text: titles.secondaryTitle,
      },
    },
    Description: showDescription
      ? {
          x: 143,
          text: {
            fontSize: 28,
            text: data.description,
          },
        }
      : {},
  }
}

export const isVod = (programmingType: any) => VOD_PROGRAMMING_TYPES.has(programmingType)

export const getSubtitleForLive = (
  program: Program | EpgProgram | any,
  stream: SingleStream | EpgStream | any
): string => {
  if (!program) return ''

  const {
    seasonNumber,
    episodeNumber,
    videoTitle,
    episodeTitle,
    programDescription,
    programTitle,
    rating,
  } = program
  const { shortDescription } = stream

  const isGuideProgram = program.component === 'GuideProgram'
  const secondaryTitle = !isGuideProgram && (stream.streamSecondaryTitle || stream.secondaryTitle)
  const isSeries = [seasonNumber, episodeNumber].every(isNumberLike)

  const notInProgramTitle = (str?: string): boolean => (str ? !programTitle?.includes(str) : false)

  const hasSecondaryTitle = (() => {
    if (!secondaryTitle || !programTitle) return false
    if (!isSeries) return true
    return !secondaryTitle.includes(programTitle)
  })()

  if (isSeries) {
    return getSeriesSubtitle(
      seasonNumber,
      episodeNumber,
      secondaryTitle,
      isGuideProgram,
      hasSecondaryTitle,
      program,
      stream,
      episodeTitle,
      videoTitle,
      programDescription,
      shortDescription,
      rating,
      notInProgramTitle
    )
  }

  return getNonSeriesSubtitle(
    hasSecondaryTitle,
    secondaryTitle,
    programDescription,
    shortDescription,
    rating
  )
}

const getSeriesSubtitle = (
  seasonNumber: string,
  episodeNumber: string,
  secondaryTitle: string | undefined,
  isGuideProgram: boolean,
  hasSecondaryTitle: boolean,
  program: any,
  stream: any,
  episodeTitle: string,
  videoTitle: string,
  programDescription: string,
  shortDescription: string,
  rating: string,
  notInProgramTitle: (str?: string) => boolean
): string => {
  const seasonAndEpisode = `S${seasonNumber} E${episodeNumber} · `
  const subtitle = secondaryTitle && secondaryTitle?.split(' · ')?.[1]
  const titleProperties = ['episodeTitle', 'programDescription', 'shortDescription']
  const defaultTitle = getFirstNonNull(isGuideProgram ? program : stream, titleProperties)

  let result = (() => {
    const fallback = getAsString(defaultTitle || episodeTitle || videoTitle)
    if (hasSecondaryTitle && subtitle && notInProgramTitle(subtitle)) {
      return secondaryTitle
    } else if (fallback && notInProgramTitle(fallback)) {
      return fallback
    } else {
      return getAsString(programDescription) || getAsString(shortDescription) || ''
    }
  })()

  if (result && !result.includes(seasonAndEpisode)) {
    result = seasonAndEpisode + result
  }

  return result + getAsString(rating, ' | ')
}

const getNonSeriesSubtitle = (
  hasSecondaryTitle: boolean,
  secondaryTitle: string | undefined,
  programDescription: string,
  shortDescription: string,
  rating: string
): string => {
  const subtitle = hasSecondaryTitle ? shortDescription : programDescription || secondaryTitle || ''
  const finalSubtitle =
    (subtitle ?? '').length > MAX_SIZE_SUBTITLE
      ? (subtitle ?? '').slice(0, MAX_SIZE_SUBTITLE - 3) + MAX_LINES_SUFFIX
      : subtitle ?? ''
  return finalSubtitle + getAsString(rating, ' | ') || ''
}

export const getControlsAnalytics = () => {
  const { program, stream } = PlayerStoreSingleton
  if (!program || !stream) return {}

  const singleProgram = isSingleProgram(program)

  return {
    video: program?.tmsId || program?.mpxGuid || (singleProgram && program?.pid) || '',
    season: program?.seasonNumber,
    show: singleProgram ? program?.series : '',
    brand: singleProgram ? program?.brand?.title : program.channelId,
  }
}
