import { Language } from '@lightningjs/sdk'
import { FastImg } from '../../../../helpers'
import { OLY_IMPOLICY, SCREEN_SIZE } from '../../../../constants'

// Template patch for when entering state in PauseAdControl
export const toEnterPauseAdsPatch = function () {
  return {
    PlayerControls: {
      PauseAdTitleContainer: {
        Description: {
          text: {
            text: Language.translate('pause_ad_info'),
          },
        },
      },
    },
    PauseAdImage: {
      zIndex: 0,
      w: SCREEN_SIZE.width,
      h: SCREEN_SIZE.height,
      texture: null,
    },
  }
}

export const toPauseAdOverlay = () => {
  return {
    PlayerControls: {
      PauseAdOverlayContainer: {
        alpha: 1,
      },
      PauseAdTitleContainer: {
        alpha: 1,
      },
      SettingsContainer: {
        alpha: 0,
      },
      TitleContainer: {
        alpha: 0,
      },
    },
  }
}

// Template patch for when entering state in PauseAdControl
export const toExitPauseAdsPatch = function () {
  return {
    PlayerControls: {
      SettingsContainer: {
        alpha: 1,
      },
      PauseAdOverlayContainer: {
        alpha: 0,
      },
      TitleContainer: {
        alpha: 1,
      },
      PauseAdTitleContainer: {
        alpha: 0,
      },
    },
    PauseAdImage: {
      texture: null,
    },
  }
}

// Template patch for adding new pause ad image
export const getPauseAdImageTexture = function (imgSrc: string): Record<string, any> {
  return {
    texture: FastImg(imgSrc)?.contain(
      SCREEN_SIZE.width,
      SCREEN_SIZE.height,
      OLY_IMPOLICY.RECT_LARGE_1920_1080
    ),
  }
}
