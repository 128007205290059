import Collection from './Collection'
import { Log } from '@lightningjs/sdk'
import { SlideTile, UpcomingLiveSlideTile } from '../index'
import { COMPONENT_TYPES } from '../../../constants'

const SLIDESHOW_TAG = 'api/models/collections/Slideshow'

export default class Slideshow extends Collection {
  override mapItems(items: any) {
    const shelfData = this.shelfData
    return items.map((item: any, index: any) => {
      if (item?.data) {
        let tile

        switch (item.component) {
          case COMPONENT_TYPES.UPCOMING_LIVE_SLIDE:
            tile = new UpcomingLiveSlideTile(item, shelfData, index)
            break
          case COMPONENT_TYPES.SLIDE:
          default:
            tile = new SlideTile(item, shelfData, index)
        }

        return tile
      } else {
        Log.warn(SLIDESHOW_TAG, `data missing from ${item}`)
      }
    })
  }
}
