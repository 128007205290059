import { Language, Lightning } from '@lightningjs/sdk'
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../constants'

/**
 * BaseComponent class that allows us to extend the functionality of the Lightning.Component methods
 */
export default class BaseComponent<
  T extends Lightning.Component.TypeConfig = Lightning.Component.TypeConfig,
> extends Lightning.Component<any, T> {
  static override _template(
    override?: any
  ): Lightning.Component.Template<Lightning.Component.TemplateSpecLoose> {
    return {}
  }

  get attributes(): { name: string; type: string } {
    return {
      name: MPARTICLE_DEFAULT_ATTR_VALUE,
      type: MPARTICLE_DEFAULT_ATTR_VALUE,
    }
  }

  _prevLang!: string
  _setTranslation(): void {}
  /**
   * Check the language that was used in the init of the component,
   * if the language has changed, execute the _setTranslation method,
   * which replaces the translation elements
   * @protected
   */
  override _enable() {
    const currentLang = Language.get()
    if (typeof this._setTranslation === 'function' && this._prevLang !== currentLang) {
      this._prevLang = currentLang
      this._setTranslation()
    }
  }

  /**
   * Private method meant to be called from inside the component
   */
  protected _addDecorators(decorators: any[]) {
    decorators.forEach((decorator: any) => {
      decorator(this)
    })
  }
}
