import { Lightning } from '@lightningjs/sdk'

import {
  COLORS,
  FLEX_DIRECTION,
  FONT_FACE,
  SCREEN_SIZE,
  TEXT_ALIGN,
  VERTICAL_ALIGN,
} from '../../../../constants'
import ModalButton from '../../../../components/buttons/ModalButton'
import { FastImg, isExternalLinkingAllowed } from '../../../../helpers'
import { EVENTS } from '../../../../lib/analytics/types'
import { sendMetric } from '../../../../lib/analytics/Analytics'
import TVPlatform from '../../../../lib/tv-platform'
import { OLY_IMPOLICY } from '../../../../constants'
import { BaseActivation } from '../Activation'
import { AuthenticationEvents } from '../../../../authentication/Authentication'

export const WithPeacockModal = <T extends Lightning.Component.Constructor<BaseActivation>>(
  base: T
) =>
  class extends base {
    _peacockModal: Lightning.Component | undefined
    _peacockModalOpenButton: ModalButton | undefined
    _peacockModalDismissButton: ModalButton | undefined
    _peacockModalButtonIndex = 0

    static _template() {
      return {
        // @ts-expect-error TODO: fix super not working on HOCs
        ...super._template(),
        PeacockModal: {
          visible: false,
          alpha: 0.9,
          w: SCREEN_SIZE.width,
          h: SCREEN_SIZE.height,
          rect: true,
          color: COLORS.black,
          Logo: {
            y: 300,
            w: 405,
            x: 960,
            mountX: 0.5,
          },
          Title: {
            y: 480,
            x: 960,
            mountX: 0.5,
            text: {
              fontSize: 54,
              fontFace: FONT_FACE.light,
              lineHeight: 70,
              textColor: COLORS.white,
              verticalAlign: VERTICAL_ALIGN.middle,
              maxLines: 2,
              textAlign: TEXT_ALIGN.center,
              text: '',
            },
          },
          Subtitle: {
            y: 570,
            x: 960,
            mountX: 0.5,
            text: {
              fontSize: 36,
              fontFace: FONT_FACE.light,
              lineHeight: 70,
              textColor: COLORS.white,
              verticalAlign: VERTICAL_ALIGN.middle,
              maxLines: 2,
              textAlign: TEXT_ALIGN.center,
              text: 'Go to peacocktv.com to get the full experience.',
            },
          },
          Buttons: {
            flex: {
              direction: FLEX_DIRECTION.row,
            },
            x: 960,
            y: 720,
            OpenPeacock: {
              type: ModalButton,
              label: 'Go to Peacock',
              visible: false,
            },
            Dismiss: {
              type: ModalButton,
              label: 'OK',
            },
          },
        },
      }
    }

    static _states() {
      return [
        // @ts-expect-error TODO: fix super not working on HOCs
        ...super._states(),
        class PeacockModal extends this {
          override $enter() {
            this._clearTimeout()
            AuthenticationEvents.stopPolling()
            if (this._peacockModal) {
              this._peacockModal.visible = true
            }
          }

          override $exit() {
            this._peacockModal?.patch({
              visible: false,
            })
          }

          override _handleEnter(e: any) {
            if (this._peacockModalOpenButton?._isFocus) {
              TVPlatform.exitToPeacock()
            } else {
              this._trackPeacockModalDismiss()
              super._handleBack(e)
            }
          }

          override _handleLeft() {
            this._peacockModalButtonIndex = 0
          }

          override _handleRight() {
            this._peacockModalButtonIndex = 1
          }

          override _getFocused(): Lightning.Component {
            return (
              (this._peacockModalOpenButton?.visible && this._peacockModalButtonIndex === 0
                ? this._peacockModalOpenButton
                : this._peacockModalDismissButton) || this
            )
          }
        },
      ]
    }

    override _init() {
      super._init()
      this._peacockModal = this.tag('PeacockModal')
      this._peacockModalOpenButton = this.tag('PeacockModal.OpenPeacock')
      this._peacockModalDismissButton = this.tag('PeacockModal.Dismiss')
      if (TVPlatform.capabilities.externalAppLinking && isExternalLinkingAllowed()) {
        this.tag('PeacockModal.Buttons').x -= (this._peacockModalOpenButton?.finalW || 0) / 2
        if (this._peacockModalOpenButton) this._peacockModalOpenButton.visible = true
      }
    }

    override _disable() {
      super._disable()
      this._setState('NewCode')
    }

    override _onCodeSuccess(expires: number) {
      if (this._getState() === 'PeacockModal') {
        return
      }
      super._onCodeSuccess(expires)
    }

    override _handleBack(e: any) {
      if (
        this._program &&
        'notification' in this._program &&
        this._program?.notification?.data &&
        !this._peacockModal?.visible
      ) {
        this._patchPeacockModal()
        this._trackPeacockModalLoad()
        this._setState('PeacockModal')
        return
      }
      super._handleBack?.(e)
    }

    _patchPeacockModal() {
      if (!this._program || !('notification' in this._program)) return
      const { cta, headline, logo, message, dismissText } = this._program?.notification?.data || {}
      if (logo) {
        this.tag('PeacockModal.Logo').texture = FastImg(logo).contain(
          405,
          124,
          OLY_IMPOLICY.RECT_SMEDIUM_640_360
        )
      }
      if (headline) {
        this.tag('PeacockModal.Title').text.text = headline
      }
      if (message) {
        this.tag('PeacockModal.Subtitle').text.text = message
      }
      if (dismissText && this._peacockModalDismissButton) {
        this._peacockModalDismissButton.label = dismissText
      }
      if (cta && this._peacockModalOpenButton) {
        this._peacockModalOpenButton.label = cta
      }
    }

    _trackPeacockModalLoad() {
      const message =
        (this._program &&
          'notification' in this._program &&
          this._program.notification?.data?.message) ||
        ''
      sendMetric(EVENTS.MODAL_LOAD, {
        modalName: message,
        modalType: 'Peacock Live Stream Modal',
      })
    }

    _trackPeacockModalDismiss() {
      const { brand, programTitle, seasonNumber } = this._program || {}
      const dismissText =
        (this._program &&
          'notification' in this._program &&
          this._program.notification?.data?.dismissText) ||
        ''
      sendMetric(EVENTS.CLICK, {
        brand: brand?.title,
        show: programTitle,
        season: seasonNumber,
        name: dismissText,
      })
    }
  }
