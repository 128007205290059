import { Lightning } from '@lightningjs/sdk'

import { COLORS, FONT_FACE } from '../../../constants'

export default (tile: any) => {
  if (tile._item?.showMovieData) {
    tile.patch({
      Content: {
        TitleCard: {
          SecondaryTitle: {
            flex: {},
            flexItem: { marginTop: 8, marginBottom: 8 },
            Icon: {
              flexItem: { marginRight: 8, marginTop: 3 },
              texture: Lightning.Tools.getRoundRect(
                70,
                32,
                0,
                1,
                COLORS.white,
                false,
                COLORS.transparent
              ),
              Label: {
                mount: 0.5,
                x: 35,
                y: 22,
                text: {
                  text: tile._item.rating,
                  fontSize: 24,
                  textColor: COLORS.white,
                  fontFace: FONT_FACE.light,
                  lineHeight: 30,
                },
              },
            },
            ShortTitle: {
              text: {
                text: tile._item.secondaryTitle,
                fontSize: 24,
                textColor: COLORS.white,
                fontFace: FONT_FACE.light,
                lineHeight: 30,
              },
            },
          },
        },
      },
    })
  }
}
