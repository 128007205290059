/**
 * formatSeasonEpisode - Takes seasonNumber and episodeNumber and returns a formatted string.
 *
 * @param  {number} seasonNumber  season number
 * @param  {number} episodeNumber episode number
 * @return {string}               S# E#
 */
export default function formatSeasonEpisode(seasonNumber: number, episodeNumber: number): string {
  return [seasonNumber && `S${seasonNumber}`, episodeNumber && `E${episodeNumber}`]
    .filter((exists) => exists)
    .join(' ')
}
