import { Language } from '@lightningjs/sdk'
import ProgressBar from '../../progressBar/progressBar'

import { COLORS } from '../../../constants'
import { ALGOLIA_ENTITY_TYPES, getCurrentProgressWidth } from '../../../helpers'
import LiveBadge from '../../badges/LiveBadge'

export default (tile: any) => {
  if (tile.entityType === ALGOLIA_ENTITY_TYPES.SLE && tile.item.data.isLive) {
    const currentProgressWidth = getCurrentProgressWidth({
      startTime: tile.item.data.startDate,
      endTime: tile.item.data.endDate,
      totalProgressWidth: 460,
    })
    tile.patch({
      LiveBadgeContainer: {
        x: 20,
        y: 220,
        type: LiveBadge,
        h: 30,
        fontSize: 20,
        label: Language.translate('live'),
      },
      LiveProgressBarContainer: {
        x: 13,
        y: 259,
        w: 460,
        visible: true,
        LiveProgressBar: {
          type: ProgressBar,
          h: 10,
          totalProgressWidth: 460,
          totalProgressColor: COLORS.charcoalGrey,
          currentProgressColor: COLORS.red,
          currentProgressWidth: currentProgressWidth,
        },
      },
    })
  }
}
