import OlympicsEventsWithFilters from '../OlympicsEventsWithFilters'
import { useRequest } from '../../lib/useRequest'
import { OlympicsReplaysRequestConfig } from './request'
import { WithRequestError, WithRequestErrorState } from '../hoc/withRequestError'

class OlympicsReplays extends OlympicsEventsWithFilters {
  override announceTitle = 'Olympics Replays'

  async load(): Promise<void> {
    try {
      this.apiData = await useRequest(OlympicsReplaysRequestConfig()).fetch()
    } catch (err) {
      this._setState(WithRequestErrorState)
    }
  }
}

export default WithRequestError(OlympicsReplays)
