import { Language } from '@lightningjs/sdk'
import AuthenticationSingleton from '../../../../authentication/Authentication'
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants'
import { getWhiteLabelBrand, getPlatFromNameOmniture } from '../../../../helpers'

const getDefaultUserAttributes = ({
  firstVisitDate,
  tempPassTokenName,
}: {
  firstVisitDate?: string
  tempPassTokenName?: string
}) => {
  const mvpd = AuthenticationSingleton.getMvpdDataMvpdId()
  return {
    'User Converted': 'False',
    'User Episode Credits Left': MPARTICLE_DEFAULT_ATTR_VALUE,
    'User First Visit Date': firstVisitDate,
    'User MVPD': tempPassTokenName || mvpd || 'Unauthenticated',
    'User Profile': 'Unauthenticated',
    'User Registration Date': MPARTICLE_DEFAULT_ATTR_VALUE,
    'User Sign In Type': MPARTICLE_DEFAULT_ATTR_VALUE,
    'User Show Favorited': MPARTICLE_DEFAULT_ATTR_VALUE,
    'User Genre': MPARTICLE_DEFAULT_ATTR_VALUE,
    'User Product': getWhiteLabelBrand().app,
    'User Platform': getPlatFromNameOmniture(),
    'User Registration Referrer': MPARTICLE_DEFAULT_ATTR_VALUE,
    'User Registration Source': MPARTICLE_DEFAULT_ATTR_VALUE,
    'User VPPA Opt In': MPARTICLE_DEFAULT_ATTR_VALUE,
    'User VPPA Opt In Date': MPARTICLE_DEFAULT_ATTR_VALUE,
    'User VPPA Opt In Product': MPARTICLE_DEFAULT_ATTR_VALUE,
    'User VPPA Opt In Platform': MPARTICLE_DEFAULT_ATTR_VALUE,
    'User Email Status': MPARTICLE_DEFAULT_ATTR_VALUE,
    'User Peacock Registration Source': MPARTICLE_DEFAULT_ATTR_VALUE,
    'User Peacock Registration Referrer': MPARTICLE_DEFAULT_ATTR_VALUE,
    'User Peacock Registration Sign In Type': MPARTICLE_DEFAULT_ATTR_VALUE,
    'User Peacock Registration Date': MPARTICLE_DEFAULT_ATTR_VALUE,
    'User Peacock Account Tier': MPARTICLE_DEFAULT_ATTR_VALUE,
    'Adobe Language Preference': MPARTICLE_DEFAULT_ATTR_VALUE,
    'Adobe Max Rating': MPARTICLE_DEFAULT_ATTR_VALUE,
    'Adobe HBA Status': MPARTICLE_DEFAULT_ATTR_VALUE,
    'Adobe Upstream ID': MPARTICLE_DEFAULT_ATTR_VALUE,
    'User Language': Language.get() || MPARTICLE_DEFAULT_ATTR_VALUE,
    'User Not Allowed Brands': MPARTICLE_DEFAULT_ATTR_VALUE,
    'User Allowed Brands': MPARTICLE_DEFAULT_ATTR_VALUE,
    'User Rec Shelf': MPARTICLE_DEFAULT_ATTR_VALUE,
    'User Cross App': MPARTICLE_DEFAULT_ATTR_VALUE,
    'User Social Network': MPARTICLE_DEFAULT_ATTR_VALUE,
    'User MVPD Referrer': MPARTICLE_DEFAULT_ATTR_VALUE,
  }
}

export default getDefaultUserAttributes
