enum RequestState {
  INIT = 'init',
  FETCHING = 'fetching',
  SUCCESS = 'success',
  FAIL = 'fail',
}

export type OKResponse<T extends (variables: any) => Promise<any>> = Awaited<ReturnType<T>>

export class RejectedResponse<T extends (variables: any) => Promise<any>> extends Error {
  data: OKResponse<T>
  constructor(data: OKResponse<T>) {
    super('Rejected Response')
    // Needed because of babel es5 compilation issues
    Object.setPrototypeOf(this, RejectedResponse.prototype)
    this.data = data
  }
}

export class EmptyResponse extends Error {
  constructor() {
    super('Empty Response')
    // Needed because of babel es5 compilation issues
    Object.setPrototypeOf(this, EmptyResponse.prototype)
  }
}

export class FailedResponse extends Error {
  err: Error
  constructor(err: Error) {
    super('Failed Response')
    this.err = err
    // Needed because of babel es5 compilation issues
    Object.setPrototypeOf(this, FailedResponse.prototype)
  }
}

export type AsyncRequestOptions = { middleware?: ((data: any) => any)[] }

export type AsyncRequest<
  T extends (variables: any, options?: AsyncRequestOptions) => Promise<any>,
  U extends (data: OKResponse<T>) => any,
> = {
  query: T
  variables: Parameters<T>[0]
  options?: Parameters<T>[1]
  mapper?: U // Function that transforms request data
  reject?: U extends (data: OKResponse<T>) => infer R
    ? ((data: R) => boolean)[]
    : ((data: OKResponse<T>) => boolean)[] // An array of reject conditions
}

export const createRequestConfig = <
  T extends (variables: any) => Promise<any>,
  U extends (data: OKResponse<T>) => any,
>(
  config: AsyncRequest<T, U>
) => config

export type RequestReturnType<C extends AsyncRequest<any, any>> = C extends AsyncRequest<
  infer T,
  infer U
>
  ? NonNullable<U extends (data: any) => infer R ? R : OKResponse<T>>
  : never

export const useRequest = <
  T extends (variables: any, options?: any) => Promise<any>,
  U extends (data: OKResponse<T>) => any,
>(
  config: AsyncRequest<T, U>
) => {
  let state = RequestState.INIT
  return {
    get state() {
      return state
    },
    async fetch(): Promise<RequestReturnType<typeof config>> {
      try {
        if (!config.query || !config.variables) {
          throw new Error('Unable to make request due to missing parameters')
        }
        state = RequestState.FETCHING
        const response: OKResponse<T> = await config.query(config.variables, config.options)
        const data = config.mapper ? config.mapper(response) : response
        if (config.reject?.length) {
          for (let i = 0; i < config.reject.length; i++) {
            if (config.reject[i]?.(data)) {
              throw new RejectedResponse(data)
            }
          }
        }
        if (!data) throw new EmptyResponse()
        state = RequestState.SUCCESS
        return data
      } catch (err: any) {
        state = RequestState.FAIL
        if (err instanceof RejectedResponse || err instanceof EmptyResponse) throw err
        throw new FailedResponse(err)
      }
    },
  }
}
