import Announce from './Announces/Announce'
import TVPlatform from '../tv-platform'
import { AnnouncementType } from './types'
import { PLATFORM_TYPES, splitTextToFitLength } from '../../helpers'

class SpeechController {
  private _active = false
  private _voiceOutDisabled = false
  private _announces: Announce[] = []
  private _tts = TVPlatform.tts()

  get active(): boolean {
    return this._active
  }

  get voiceOutDisabled(): boolean {
    return this._voiceOutDisabled
  }

  append(toSpeak: AnnouncementType, notification = false): void {
    const seriesArray = this._normalize(Array.isArray(toSpeak) ? toSpeak : [toSpeak])
    for (const phrase of seriesArray) {
      this._announces.push(this._tts.speak(phrase, notification))
    }
  }

  cancel(): void {
    this._announces = []
    this._tts.cancel()
    this._active = false
    this._voiceOutDisabled = false
  }

  async speak(series: AnnouncementType, notification = false): Promise<void> {
    this._active = true
    this.append(series, notification)
    await this._speakSeries()
  }

  private async _speakSeries(): Promise<void> {
    while (this._announces.length) {
      const series = this._announces.shift()
      if (!series) continue
      this._voiceOutDisabled = series.notification
      await series.speak()
    }
    this._voiceOutDisabled = false
    this._active = false
  }

  private _normalize(phrasesArray: Array<string | (() => string)>): Array<string> {
    let normalizedPhrasesArray: string[] = []
    for (const string of phrasesArray) {
      const phrase = typeof string === 'function' ? string() : string
      const result = DEVICE_PLATFORM === PLATFORM_TYPES.XCLASS && phrase.length > 200
        ? splitTextToFitLength(phrase)
        : phrase
      if (Array.isArray(result)) {
        normalizedPhrasesArray.push(...result)
      } else {
        normalizedPhrasesArray.push(result)
      }
    }
    return normalizedPhrasesArray
  }
}

export default new SpeechController()
