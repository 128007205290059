import LazyCollection from './LazyCollection'
import ContinuousScroll from './ContinuousScroll'
type GroupedContinuousScrollDataObj = {
  items?: any
  continuousScroll?: any
}
export default class GroupedContinuousScroll extends LazyCollection {
  _continuousScroll: any
  constructor(obj: GroupedContinuousScrollDataObj = {}, index = 0) {
    obj.items = obj.continuousScroll.data.items
    super(obj, index)
    this._continuousScroll = new ContinuousScroll(obj.continuousScroll?.data)
  }

  get continuousScroll() {
    return this._continuousScroll
  }

  override get items() {
    return this._continuousScroll.items
  }

  get itemsComponentType() {
    return this.obj.continuousScroll.component
  }

  override get listTitle() {
    return this.obj.title
  }

  get groups() {
    return this.obj.groups
  }

  get groupId() {
    return this.obj.groups?.[this.index]?.id
  }

  override get shelfData() {
    return {
      playlistMachineName: this.playlistMachineName,
      listTitle: this.listTitle,
      shelfIndex: this.index,
      machineName: this.machineName,
    }
  }

  get itemLabels() {
    return this.obj.itemLabels
  }
}
