import BasePlayer from '../BasePlayer'
import { PlayerMediaOptionsStateFactory } from './Player.MediaOptions'
import { PlayerControlsStateFactory } from './Player.Controls'
import { PlayerSkipButtonStateFactory } from './Player.SkipButton'
import { switchState } from '../../../helpers'
import { PlayerTempPassStateFactory } from './Player.TempPass'
import { PlayerLiveToVodStateFactory } from './Player.LiveToVod'
import { COLORS, PlayerStates } from '../../../constants'
import TVPlatform from '../../../lib/tv-platform'
import { Log } from '@lightningjs/sdk'
import { isBlockingModalOpen } from '../../../lib/ModalManager'
import { PlayerStatus } from '../../../player/model/PlayerStatus'

export const CorePlayerStateFactory = (base: any) =>
  class LPPlayer extends base {
    _pinTempPass: boolean

    init() {
      this._pinTempPass = false
    }

    $enter() {
      if (isBlockingModalOpen()) return
      this._hideUI()
      this.stage.setClearColor(COLORS.transparent)
      if (this._player?.status === PlayerStatus.UNKNOWN) this._load()
      try {
        if (TVPlatform.getForceGC()) this.stage.gc()
        if (!this._isBuffering) {
          // Start with controls active (hiding after timeout of given length).
          this._setState(PlayerStates.LPPlayer_Controls, [
            { timeoutLength: BasePlayer.INITIAL_CONTROLS_TIMEOUT },
          ])
        } else this._isBuffering = false

        this._setPlayerInActiveTimeout()
        this._controls?.createTimeOut?.()

        switchState(this, [
          {
            state: 'LPPlayer.LiveToVod',
            if: () => this._showLiveToVodFixed?.(),
          },
          {
            state: 'LPPlayer.SkipButton',
            if: () => this._showSkipButton?.(),
          },
          {
            state: 'LPPlayer.TempPass',
            if: () => this._tempPassPlayerOverlay,
          },
        ])
      } catch (e) {
        Log.error(`PlayerCore error $enter: ${e}`)
      }
    }

    $exit() {
      try {
        this._clearPlayerInActiveTimeOut()
      } catch (e) {
        Log.error(`PlayerCore error $exit: ${e}`)
      }
    }

    _inactive() {
      this._clearPlayerInActiveTimeOut()
    }

    $playOrPause() {
      return this._playOrPause()
    }

    $isPlaying() {
      return this._player.isPlaying()
    }

    $showTempPassTimer({ pin = false }: { pin: boolean }) {
      const currState = this._getState()
      //Don't do anything if we are already focused on controls or temp pass
      if (
        this._pinTempPass &&
        [PlayerStates.LPPlayer_TempPass, PlayerStates.LPPlayer_Controls].includes(currState)
      )
        return

      this._pinTempPass = pin
      this._setState(PlayerStates.LPPlayer_TempPass)
    }

    $hideTempPassTimer() {
      if (this._pinTempPass) return
      if (this._getState() === PlayerStates.LPPlayer_TempPass) {
        this._setState(PlayerStates.LPPlayer)
      }
    }

    _captureBack() {
      this._closeMediaPlayer(this._forceExit)
    }

    _captureEnter() {
      this._setPlayerInActiveTimeout()
      if (this._getState() !== PlayerStates.LPPlayer_MediaOptions) {
        this._setState(PlayerStates.LPPlayer_Controls)
      }
      return false
    }

    _captureKey() {
      this._setPlayerInActiveTimeout()
      if (this._getState() !== PlayerStates.LPPlayer_MediaOptions) {
        this._setState(PlayerStates.LPPlayer_Controls)
      }
    }

    static _states() {
      return [
        PlayerTempPassStateFactory(LPPlayer),
        PlayerLiveToVodStateFactory(LPPlayer),
        PlayerSkipButtonStateFactory(LPPlayer),
        PlayerControlsStateFactory(LPPlayer),
        PlayerMediaOptionsStateFactory(LPPlayer),
      ]
    }
  }
