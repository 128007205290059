import { Router } from '@lightningjs/sdk'
import { EVENTS } from '../../../lib/analytics/types'
import { ROUTE } from '../../../constants'
import { sendMetric } from '../../../lib/analytics/Analytics'

export const ItemMenuStateFactory = (base: any) =>
  class ItemMenu extends base {
    $enter() {
      this._resetPosition()
    }
    _handleLeft() {
      !this._contentIsNotAvailable && this._setState(this._initialState)
    }
    _handleDown() {
      if (!this._contentIsNotAvailable) {
        this._setState('Scroller')
      }
    }
    _getFocused() {
      return this.tag('SubNav') || this
    }
    _handleEnter() {
      const index = this.tag('SubNav').activeIndex
      const params = {
        brand: this.brand,
        series: this.series,
        show: this.series,
      }
      if (index === 0) {
        this._updateFavorite()
      } else if (index === 1) {
        this._setState('About')
        sendMetric(EVENTS.CLICK, {
          ...params,
          name: 'About',
        })
      } else if (index === 2) {
        sendMetric(EVENTS.CLICK, {
          ...params,
          name: 'Shows',
        })
        Router.navigate(`${ROUTE.shows}/all`)
      }
    }
  }
