import { IPlayerEvent } from './IPlayerEvent'

export class AdBreakDataEvent extends IPlayerEvent {
  _adBreaks

  constructor(adBreaks: any) {
    super()
    this._adBreaks = adBreaks
  }

  get adBreaks() {
    return this._adBreaks
  }
}
