import mParticle from '@mparticle/web-sdk'
import getAdEndAttributes from '../attributes/getAdEndAttributes'

const trackAdEnd = (type: any) => ({
  getAttributes: getAdEndAttributes,
  getCategory: () => mParticle.EventType?.UserContent || 'UserContent',
  type,
  injectGlobals: true,
})

export default trackAdEnd
