import { Lightning } from '@lightningjs/sdk'
import SeekablePlayerControlsV1 from '../SeekablePlayerControlsV1'
import { AdBreakMarkersDelegate } from '../delegates/AdBreakMarkersDelegate'

export const WithAdBreaks = <T extends Lightning.Component.Constructor<SeekablePlayerControlsV1>>(
  base: T
) =>
  class extends base {
    _adbreakMarkersDelegate = new AdBreakMarkersDelegate(this)

    addAdBreakPoints(adbreaks: any) {
      this._adbreakMarkersDelegate.draw(adbreaks, this._strategy._totalTime)
    }
  }
