import { Lightning } from '@lightningjs/sdk'

import { FastImg, sponsorBadgeText } from '../../helpers'
import { ALIGN_SELF } from '../../constants'
import { OLY_IMPOLICY } from '../../constants/images'

export default class SponsorBadge extends Lightning.Component {
  sponsorFallbackText: any
  sponsorLogo: any
  sponsorLogoWidth = 310
  sponsorLogoHeight = 82
  static override _template() {
    return {
      w: 310,
      h: 82,
      SponsorLogo: {
        flexItem: {
          alignSelf: ALIGN_SELF.center,
        },
      },
      SponsorFallbackText: {
        flexItem: {
          alignSelf: ALIGN_SELF.center,
        },
        visible: false,
      },
    }
  }

  override _init() {
    const logoTag = this.tag('SponsorLogo')
    logoTag.on('txError', () => {
      logoTag.visible = false
      this.tag('SponsorFallbackText').patch({
        visible: true,
        text: sponsorBadgeText(this.sponsorFallbackText),
      })
    })
    logoTag.patch({
      texture: FastImg(this.sponsorLogo).contain(
        this.sponsorLogoWidth,
        this.sponsorLogoHeight,
        OLY_IMPOLICY.RECT_SMALL_384_216
      ),
    })
  }
}
