import { Log, Storage } from '@lightningjs/sdk'
import { get, merge, cloneDeep } from 'lodash'

import { modify as modifyCache, getHomepage } from '../../cache'
import { findSeriesTile, getNewFavoritesShelf } from '../../../helpers/cache'
import { FAVORITES_TAG, SHELF_LOGIC_NAME } from './constants'
import { STORAGE_KEYS } from '../../../constants'

class FavoriteInteraction {
  _hasSavedFavorite: any
  constructor() {
    this.hasSavedFavorite = false
  }

  set hasSavedFavorite(value) {
    this._hasSavedFavorite = value
    if (value) {
      Storage.set(STORAGE_KEYS.IS_NOT_DAY_ZERO, true)
    }
  }

  get hasSavedFavorite() {
    return this._hasSavedFavorite
  }

  addOrRemoveFavorite(urlAlias: any, isFavorite: any) {
    if (isFavorite && !this.hasSavedFavorite) {
      this.hasSavedFavorite = true
    }
    // Update the favorites shelf in the homepage cache.
    const homepageCache = getHomepage()
    const homepageCacheId = get(homepageCache, 'data.bonanzaPage.id')
    if (homepageCacheId)
      // @ts-expect-error TS(2345): Argument of type '(data: any, { urlAlias, isFavori... Remove this comment to see the full error message
      modifyCache(homepageCacheId, this._updateHomepageFavoritesShelf, {
        urlAlias,
        isFavorite,
      })
  }

  _updateHomepageFavoritesShelf = (data: any, { urlAlias, isFavorite }: any) => {
    if (!get(data, 'sections.length')) return

    // Get favorites shelf index, can be different with/without continue watching.
    const favoritesShelfIndex = data.sections.findIndex(
      (shelf: any) => shelf.logicName === SHELF_LOGIC_NAME.favoritesShelf
    )
    const favoritesShelf = data.sections[favoritesShelfIndex]
    const seriesTile = findSeriesTile(data.sections, urlAlias)

    // Added to favorites.
    if (isFavorite) {
      if (favoritesShelfIndex > -1) {
        Log.info(
          FAVORITES_TAG,
          'add tile to favorites shelf',
          data,
          favoritesShelfIndex,
          seriesTile
        )
        return this._addTileToFavoritesShelf(data, favoritesShelfIndex, seriesTile)
      }
      Log.info(FAVORITES_TAG, 'add new favorites shelf')
      return this._createFavoritesShelf(data, seriesTile)
    }
    // Removed from favorites.
    if (favoritesShelf.data.items.length > 1) {
      Log.info(FAVORITES_TAG, 'remove tile from favorites shelf')
      return this._removeTileFromFavoritesShelf(data, favoritesShelfIndex, seriesTile)
    }
    Log.info(FAVORITES_TAG, 'remove favorites shelf')
    return this._removeFavoritesShelf(data, favoritesShelfIndex)
  }

  _addTileToFavoritesShelf(data: any, shelfIndex: any, seriesTile: any) {
    // Clone and sort favorites shelf items.
    const updatedShelfItems = [...data.sections[shelfIndex].data.items, seriesTile].sort((a, b) =>
      a.data.title.localeCompare(b.data.title)
    )
    return merge({}, data, {
      sections: { [shelfIndex]: { data: { items: updatedShelfItems } } },
    })
  }

  _createFavoritesShelf(data: any, seriesTile: any) {
    const clonedData = cloneDeep(data)
    const newShelfIndex =
      data.sections.findIndex((shelf: any) => shelf.logicName === 'continueWatchingShelf') + 1
    const favoritesShelf = getNewFavoritesShelf(seriesTile)
    clonedData.sections.splice(newShelfIndex, 0, favoritesShelf)
    return clonedData
  }

  _removeTileFromFavoritesShelf(data: any, shelfIndex: any, seriesTile: any) {
    const clonedData = cloneDeep(data)
    const favoriteItemIndex = clonedData.sections[shelfIndex].data.items.findIndex(
      (item: any) => item.data.seriesName === seriesTile.data.seriesName
    )
    if (favoriteItemIndex === -1) return data
    clonedData.sections[shelfIndex].data.items.splice(favoriteItemIndex, 1)
    return clonedData
  }

  _removeFavoritesShelf(data: any, shelfIndex: any) {
    const clonedData = cloneDeep(data)
    clonedData.sections.splice(shelfIndex, 1)
    return clonedData
  }
}

export default FavoriteInteraction
