import IPlayerControlsStrategy from './IPlayerControlsStrategy'

import { formatSecondsAsHhMmSs } from '../../../../helpers'
import { getTitles } from '../../helpers/metadata'
import { PROGRAMMING_TYPES } from '../../../../constants'
import { checkAddClipToVideoTitles } from '../../../../helpers'
import { addSFVODTitleContainer } from '../../helpers/metadata'
import { addBaseTitleContainer } from '../../helpers/metadata'
import { TimeChangeEvent } from '../../../../player/model/event'
import { createProgressHookEvent } from '../hooks/useProgress'

export default class VODStrategy extends IPlayerControlsStrategy {
  override init(stream: any) {
    this._totalTime = stream.duration
    this.parent.tag('PlayerControlsBrandLogoWrapper')?.patch({
      y: 90,
    })

    const args = [getTitles(stream), stream, true] as const
    const title = {
      TitleContainer:
        stream.programmingType === PROGRAMMING_TYPES.SFVOD
          ? addSFVODTitleContainer(...args)
          : addBaseTitleContainer(...args),
    }
    checkAddClipToVideoTitles(stream, title)
    this.parent.tag('PlayerControls').patch(title)

    this.parent._timer.bTime = formatSecondsAsHhMmSs(stream.duration)
    this._progress.set(
      createProgressHookEvent({
        currentTime: 0,
        duration: this._totalTime,
      })
    )
  }

  override update(timeChangeEvent: TimeChangeEvent) {
    const { time, seekableRange } = timeChangeEvent
    const endTimeFromEvent = Math.floor(<number>seekableRange?.end)

    if (endTimeFromEvent !== this._totalTime) {
      this._totalTime = endTimeFromEvent
      this.parent._timer.bTime = formatSecondsAsHhMmSs(this._totalTime)
      this._progress.set(createProgressHookEvent({ currentTime: time, duration: this._totalTime }))
    } else {
      this._progress.set(createProgressHookEvent(time))
    }
  }
}
