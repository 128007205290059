import { createRequestConfig } from '../../lib/useRequest'
import { mainRequest, searchFeaturedRequest } from '../../api/requests'
import Variables from '../../graphql/Variables'
import { SHOWS_SHELF_TITLE } from '../../constants'

export const SearchFeaturedRequestConfig = () =>
  createRequestConfig({
    query: searchFeaturedRequest,
    variables: Variables.searchPageVariables(),
    reject: [(response) => !response?.data],
  })

export const SearchFallbackRequestConfig = () =>
  createRequestConfig({
    query: mainRequest,
    variables: Variables.homePageVariables(),
    reject: [
      (data) => data?.errors?.length,
      (data) =>
        !data?.sections?.some(
          (section: any) =>
            section &&
            'data' in section &&
            'listTitle' in section.data &&
            typeof section.data.listTitle === 'string' &&
            section.data.listTitle.includes(SHOWS_SHELF_TITLE)
        ),
    ],
  })
