import { Lightning } from '@lightningjs/sdk'
import { COLORS, EPG_SLOT_PADDING } from '../../../../constants'
import { InverseRoundedCornerTexture } from '../../../../shaders/InverseRoundedCornerTexture'

// This is a texture we overlay over the programs so
// clipped GuideProgram components retain round corners
export class InverseRoundedRectangleOverlay extends Lightning.Component {
  static override _template() {
    return {
      x: 0,
      y: 59,
      zIndex: 10,
    }
  }

  set itemHeight(height: number) {
    this.children = Array(4)
      .fill(null)
      .map((_, index) => {
        return {
          x: 0,
          y: height * index + EPG_SLOT_PADDING * index,
          rect: true,
          texture: {
            type: InverseRoundedCornerTexture,
            fillColor: COLORS.black,
            radius: 6.375,
            corners: {
              topLeft: { x: 0, y: 0 },
              bottomLeft: { x: 0, y: 190 },
            },
          },
        }
      })
  }
}
