import { Lightning } from '@lightningjs/sdk'
import { VerticalTabs } from './VerticalTabs'
import { CMPPageSelectedTabIndex } from './hook'
import { ConsentManagement } from '../../../lib/cmp/ConsentManagement'
import { VerticalText } from './VerticalText'
import { CMPPurposeParent } from '../../../lib/cmp/types/preference'
import { CMPComponent, cmpComponentFactory } from './components'

enum CMPWrapperComponents {
  VerticalTabs = 'VerticalTabs',
  Content = 'Content',
}

export class Wrapper extends Lightning.Component {
  _focusedComponent = CMPWrapperComponents.VerticalTabs
  _items: CMPComponent[] = []
  _tabIndex = CMPPageSelectedTabIndex(this._onTabChange.bind(this))

  static override _template() {
    return {
      VerticalTabs: {
        y: 152,
        x: 0,
        type: VerticalTabs,
        signals: {
          onTabSelected: '_onTabSelected',
        },
      },
      Content: {},
    }
  }

  override async _init() {
    try {
      const response = (await ConsentManagement.fetchPreferenceCenter()).pcUIData
      const { text: textQRCode, url } = response.links.policyLink
      this._items = [
        {
          key: 'summary',
          label: response.summary.title.text,
          component: VerticalText,
          componentProps: {
            title: response.summary.title.text,
            content: response.summary.description.text,
            textQRCode: textQRCode,
            urlQRCode: url,
          },
        },
        ...(response.purposeTree.purposes as any[]).reduce(
          (acc, item: CMPPurposeParent) => [
            ...acc,
            cmpComponentFactory(item.groupId, item, response),
          ],
          []
        ),
      ]
      this.tag('VerticalTabs').tabs = this._items
      this._tabIndex.set(0)
    } catch (_) {
      // no op - network errors already handled at the ConsentManagement class
    }
  }

  _onTabChange(index: number) {
    this.patch({ Content: undefined })
    const item = this._items[index]
    if (item) {
      this.patch({
        Content: {
          y: 152,
          x: 674,
          type: item?.component,
          ...item?.componentProps,
        },
      })
    }
  }

  override _handleLeft() {
    if (this._focusedComponent === CMPWrapperComponents.Content) {
      this._focusedComponent = CMPWrapperComponents.VerticalTabs
    }
  }

  override _handleRight() {
    if (
      this._focusedComponent === CMPWrapperComponents.VerticalTabs &&
      this.tag('Content').focusable
    ) {
      this._focusedComponent = CMPWrapperComponents.Content
    }
  }

  override _getFocused() {
    return this.tag(this._focusedComponent)
  }
}
