import { COLORS } from '../../../../constants'

const AD_BREAKPOINT_WIDTH = 9

export class AdBreakMarkersDelegate {
  _controls: any
  _availableWidth = 0
  constructor(controls: any) {
    this._controls = controls
  }

  set currentTimeText(time: any) {
    this._controls.tag('CurrentTime').text.text = time
  }

  set totalTimeText(time: any) {
    this._controls.tag('TotalTime').text.text = time
  }

  get totalWidth() {
    return this._controls.tag('TotalBar')?.w || 0
  }

  draw(adbreaks: any, duration: any) {
    const component = this._controls.tag('AdBreakPoints')
    this._availableWidth = this.totalWidth

    if (component && adbreaks.length > 0) {
      component.children = adbreaks.map(({ startTimeOnUi }: any) => {
        // Adding an offset for the width of each breakpoint
        const x = (startTimeOnUi / duration) * (this._availableWidth - AD_BREAKPOINT_WIDTH)

        return {
          x,
          w: AD_BREAKPOINT_WIDTH,
          h: 8,
          rect: true,
          color: COLORS.white,
        }
      })
    }
  }

  getBarWidth(currentTime: number, duration: number) {
    if (!this._availableWidth) this._availableWidth = this.totalWidth
    return duration ? (currentTime * this._availableWidth) / duration : 0
  }
}
