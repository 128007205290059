import { GraphQlClient } from '../../graphql/client'
import globalNavigationMapper from '../../graphql/mappers/globalNavigation'
import {
  GlobalNavigationQuery,
  GlobalNavigationQueryVariables,
} from '../../graphql/generated/types'
import { QueryName } from './bffQuery'

export default (variables: GlobalNavigationQueryVariables) =>
  GraphQlClient.query<GlobalNavigationQuery>({
    query: QueryName.globalNavigation,
    variables,
  }).then(globalNavigationMapper)
