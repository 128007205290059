import { Lightning } from '@lightningjs/sdk'

export default class TransparentShader extends Lightning.shaders.WebGLDefaultShader {
  beforeUsage() {
    this.gl.disable(this.gl.BLEND)
  }

  afterUsage() {
    this.gl.enable(this.gl.BLEND)
  }
}
