import { GraphQlClient } from '../client'
import Variables from '../Variables'
import { BonanzaPage } from '../generated/types'

const getHomepageCache = () => {
  try {
    const variables = Variables.homePageVariables()
    return {
      data: GraphQlClient.readQuery({ query: BonanzaPage, variables }),
      variables,
    }
  } catch (err) {
    return {}
  }
}

export default getHomepageCache
