import { Lightning, Router } from '@lightningjs/sdk'
import { RouterPage } from '../../../../types/global'
import { IShows } from '../types'

import RouterUtil from '../../../util/RouterUtil'
import { sendMetric } from '../../../lib/analytics/Analytics'
import { pushHash } from '../../../helpers/navigation'

import { EVENTS } from '../../../lib/analytics/types'
import { ENTITY_TYPES, ROUTE, ShowsStates } from '../../../constants'
import ContentPosition from '../../../util/contentPosition'

export const BrandsLabelsStateFactory = (
  base: Lightning.Component.Constructor<Lightning.Component<any, RouterPage> & IShows>
) =>
  class BrandLabelsList extends base {
    override _handleUp() {
      Router.focusWidget('Menu')
    }

    override _handleBack(e: any) {
      Router.focusWidget('Menu')
      e.preventDefault()
      e.stopPropagation()
    }

    override _handleDown() {
      this._setState(ShowsStates.LinksSelectableGroup)
    }

    override _getFocused() {
      return this.brandLabelsList || this
    }

    trackContentClick(newIndex: any) {
      const analytics = { brand: {} }
      const params = {
        entity: { analytics: analytics, entityType: ENTITY_TYPES.BRAND },
        shelf: {
          position: newIndex + 1,
          customPosition: 1,
          analytics: {},
        },
      }
      sendMetric(EVENTS.CONTENT_CLICK, params)
    }

    override _handleEnter() {
      const newIndex = this.brandLabelsList?.index
      if (typeof newIndex === 'number' && newIndex !== this._selectedBrandIndex) {
        const hash = Router.getActiveHash() || ''
        const [base = ''] = hash.split('/')
        if (base) {
          pushHash(`${base}/${this.currentBrand}`)
          ContentPosition.clearPositionForCurrentPage()
        }
        // Store a copy because it'll get overwritten with the new index.
        this.trackContentClick(newIndex)
        const prevIndex = this._selectedBrandIndex
        this._selectedBrandIndex = newIndex
        this._setState(ShowsStates.LoadSelectedBrandData)
        // Fire mparticle event as if it were a page view.
        const brand = this._brandSelectableSpawner?.getBrandLabel(newIndex)
        const prevBrand = this._brandSelectableSpawner?.getBrandLabel(prevIndex)
        sendMetric(EVENTS.PAGE_LOAD, {
          ...RouterUtil.showsBrandRoutingData(brand, prevBrand),
          path: ROUTE.shows,
        })
        sendMetric(EVENTS.CLICK, {
          name: 'Network Logo Click',
          brand: brand,
        })
      }
    }
  }
