import { GraphQlClient } from '../../graphql/client'
import {
  FeaturedShowsSectionQuery,
  FeaturedShowsSectionQueryVariables,
} from '../../graphql/generated/types'
import searchFeaturedMapper from '../../graphql/mappers/searchFeatured'
import rsnFilter from '../../graphql/filter/rsnFilter'
import { QueryName } from './bffQuery'

export default async (
  variables: FeaturedShowsSectionQueryVariables,
  options?: { middleware?: ((data: any) => any)[] }
) => {
  return rsnFilter(
    searchFeaturedMapper(
      await GraphQlClient.query<FeaturedShowsSectionQuery>(
        {
          query: QueryName.featuredShowsSection,
          variables,
        },
        options
      )
    )
  )
}
