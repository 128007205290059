import { COLORS, FONT_FACE, MAX_LINES_SUFFIX } from '../../../constants'

export default (tile: any) => {
  if (tile._item?.showSfvodData) {
    tile.patch({
      Content: {
        TitleCard: {
          ShortTitle: {
            text: {
              maxLines: 2,
              maxLinesSuffix: MAX_LINES_SUFFIX,
            },
          },
          SmartTimeStamp: {
            flex: {},
            flexItem: { marginTop: -8 },
            ShortTitle: {
              flexItem: { marginTop: 20 },
              visible: false,
              text: {
                text: tile._item?.tertiaryTitle || tile._item?.description || '',
                fontSize: 24,
                textColor: COLORS.white,
                fontFace: FONT_FACE.light,
                lineHeight: 30,
                maxLines: 3,
                maxLinesSuffix: MAX_LINES_SUFFIX,
                wordWrapWidth: 360,
              },
            },
          },
        },
      },
    })
  }
}
