import { Language, Log } from '@lightningjs/sdk'
import moment from 'moment-timezone'
import { TIME_TTS } from '../constants'

export const formatDate = (date: any) => {
  const d = new Date(date && typeof date === 'number' ? date * 1000 : date)
  const month = d.getMonth() > 8 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)
  const day = d.getDate() > 9 ? d.getDate() : '0' + d.getDate()
  const year = d.getFullYear()

  return `${month}/${day}/${year}`
}

/**
 * Convert Date to unix timestamp
 * @return {number} unix timestamp
 * @param dateObj
 */
export const getUnixDate = (dateObj: string | null | undefined = null): number => {
  const time = (dateObj ? new Date(dateObj) : new Date()).getTime()
  return Math.round(time / 1000)
}

export const getTimeDiff = (startTime: any, endTime: any) => {
  return Math.round(moment.duration(moment(endTime).diff(moment(startTime))).asMinutes())
}

export const getTimeDiffAsMilliseconds = (startTime: any, endTime: any) => {
  return Math.round(moment.duration(moment(endTime).diff(moment(startTime))).asMilliseconds())
}

export const getRemaining = (remaining: any) => {
  const hours = Math.round(remaining / 60)
  const minutes = Math.round(remaining % 60)
  return `${hours ? `${hours} ${Language.translate('hours')} ` : ''}${minutes} ${Language.translate(
    'minsLeft'
  )}`
}

export const formatTime = (time?: any) => {
  const d = time ? new Date(time && typeof time === 'number' ? time * 1000 : time) : new Date()
  let hours = d.getHours()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  const mins = `${d.getMinutes()}`.padStart(2, '0')

  hours = hours % 12
  hours = hours ? hours : 12

  return `${hours}:${mins} ${ampm}`
}

export const getRemainingAnnounce = (time: any) => {
  let hours
  if (time >= 3600) {
    hours = `${Math.floor(time / 3600)}:`
    time = time % 3600
  }
  const minutes = Math.floor(time / 60)
  const seconds = Math.floor(time % 60)
  // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  let resultString = hours ? `${Math.round(hours)} hrs. ` : ''
  resultString += minutes ? `${Math.round(minutes)} min. ` : ''
  resultString += seconds ? `${Math.round(seconds)} sec.` : ''

  return resultString
}

const getHoursSecondsAndMinutes = (time: number) => {
  let hours
  if (time >= 3600) {
    hours = `${Math.floor(time / 3600)}:`
    time = time % 3600
  }
  const minutes = `${Math.floor(time / 60)}`.padStart(2, '0')
  const seconds = `${Math.floor(time % 60)}`.padStart(2, '0')

  return {
    hours,
    minutes,
    seconds,
  }
}

export const formatSecondsAsHhMmSs = (time: number) => {
  const { hours, minutes, seconds } = getHoursSecondsAndMinutes(time)
  return `${hours || ''}${minutes}:${seconds}`
}

export const formatSecondsAsHhMmSSForTTS = (time: number) => {
  const { hours, minutes, seconds } = getHoursSecondsAndMinutes(time)
  return `${hours ? `${hours} ${TIME_TTS.hours},` : ''} 
  ${minutes} ${TIME_TTS.minutes}, 
  ${seconds}${TIME_TTS.seconds}`
}

export const getPassedHoursFromNow = (time: any) => {
  const nowTime = moment()
  const duration = moment.duration(nowTime.diff(time))

  return Math.floor(duration.asHours())
}

export const getTimezone = () => {
  // Workaround for moment's timezone guess function not working on XClass
  // https://github.com/moment/moment-timezone/issues/441
  const { Intl } = window
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'typeof... Remove this comment to see the full error message
  window.Intl = undefined
  const timezone = moment.tz.guess(true)
  window.Intl = Intl
  return timezone
}

export const getFormattedTimeZone = (timezone: string) => {
  return encodeURIComponent(moment.tz(timezone || getTimezone()).format('Z'))
}

export const isInUSByTimezone = (): boolean => {
  const timezone = moment.tz.guess(true)
  Log.info('isInUSByTimezone::timezone', timezone, moment.tz.guess())

  const usTimezones: readonly string[] = [
    'America/New_York',
    'America/Chicago',
    'America/Denver',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Adak',
    'America/Phoenix',
    'Pacific/Honolulu',
    'America/Detroit',
    'America/Indiana/Indianapolis',
  ] as const

  return usTimezones.includes(timezone)
}
