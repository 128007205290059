import { OUTGOING_KEPLER_MESSAGE_TYPE, KeplerMessage } from '../../tv-platform/kepler/types'
import Announce from './Announce'

export default class KeplerAnnounce extends Announce {
  async speak(): Promise<void> {
    return new Promise<void>((resolve) => {
      const message: KeplerMessage<string> = {
        type: OUTGOING_KEPLER_MESSAGE_TYPE.TTS_SPEAK,
        data: this.phrase,
      }
      window?.ReactNativeWebView?.postMessage(JSON.stringify(message))
      resolve()
    })
  }
}
