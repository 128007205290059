import { Lightning } from '@lightningjs/sdk'
import { get, throttle } from 'lodash'

import Styler from '../../lib/Styler'
import { setSmooth } from '../../helpers'
import { EVENTS } from '../../lib/analytics/types'
import { sendMetric } from '../../lib/analytics/Analytics'
import { COLORS, MPARTICLE_DEFAULT_ATTR_VALUE } from '../../constants'
import ContentPosition, { ScrollElementType } from '../../util/contentPosition'

import { Dimension } from './types'
import { StyleType } from '../../lib/style'

export default class LongScroll extends Lightning.Component {
  _impressionIndex = 0
  _index = 0
  _instanceID!: string
  _interaction = false
  _margin: number
  _wrapper: any // Lightning.Element type is not correctly published
  _dimensions: Array<Dimension> = []

  static override _template() {
    return {
      Wrapper: {
        alpha: 1,
        children: [],
      },
    }
  }

  override _construct() {
    this.stage.setClearColor(COLORS.transparent)
  }

  override _setup() {
    this._wrapper = this.tag('Wrapper')
    this.stage.setClearColor(COLORS.transparent)
  }

  override add(childList: Lightning.Element.PatchTemplate<any> = []) {
    const iterable = Array?.isArray(childList) ? childList : [childList]
    this._wrapper.children = iterable.map((child, index) => {
      const tag = this.getTag(child)
      const style = Styler.getTag(tag)
      if (!style) {
        throw new Error(`No syle defined for tag: $
          {child.tag}`)
      }
      this.update(child, style, tag, index)
      child[Symbol.for('style')] = style
      return child
    })
    this._attemptScroll()
    return this._wrapper.childList
  }

  _attemptScroll = (): void => {
    // We must request scroll when the screen is mounted
    // but sometimes screen gets mounted before child items are initialized
    // and other times items are added before screen is mounted
    if (!this._wrapper?.childList?.length) return
    const position = ContentPosition.getPositionForCurrentPage()
    if (position?.type) {
      switch (position.type) {
        case ScrollElementType.LIST:
          this.fireAncestors('$scrollRequested', { position, immediate: true })
          break
        case ScrollElementType.FEATURED:
          this.fireAncestors('$setFeaturedIndex', position?.content || 0)
          break
      }
    }
  }

  $onEmptyWrapperList(child: any) {
    const currentIndex = this._wrapper.childList.getIndex(child)
    this._dimensions.splice(currentIndex, 1)
    this._wrapper.childList.remove(child)
    this._repositionItems(currentIndex)
  }

  $onPlaceholderShelfChange(child: any, newChildren: any) {
    const currentIndex = this._wrapper.childList.getIndex(child)

    newChildren.forEach((newChild: any, index: number) => {
      if (index > 0) this._wrapper.childList.addAt(newChild, currentIndex + index)
      else this._wrapper.childList.setAt(newChild, currentIndex)
      this._repositionItems(currentIndex)
    })
  }

  $isLastIndex(item: any): boolean {
    return this._wrapper.childList.getIndex(item) === this._wrapper.children.length - 1
  }

  _repositionItems(startIndex: any) {
    let last = this.dimensions[startIndex - 1] || { y: 0, h: 0 }
    const dimensions = this.dimensions
    this._wrapper.childList.forEach((child: any, index: any) => {
      if (index >= startIndex) {
        const tag = this.getTag(child)
        const style = Styler.getTag(tag)
        const { margin = 0 } = style
        last = {
          h: style.h,
          y: last.y + last.h + margin,
        }
        dimensions[index] = last
        child.patch({
          y: last.y,
        })
      }
    })
    this.dimensions = dimensions
    this._attemptScroll()
  }

  getTag(child: any) {
    const tags = child && child.getTags()
    if (!get(tags, 'length')) {
      throw new Error(`Specify a 'tag' for: ${child}`)
    }
    return tags[0]
  }

  update(child: Lightning.Element.PatchTemplate, style: StyleType, tag: string, index?: number) {
    const { h = 0, margin = 0 } = style
    const dims = this.dimensions
    let last = { y: 0, h: 0 }

    if (!h) {
      throw new Error(`Specify a 'h' property for tag: ${tag}`)
    }

    if (dims.length) {
      last = dims[dims.length - 1] || last
    }
    const current: Dimension = {
      h: style.h || 0,
      y: last.y + last.h + margin,
    }
    dims.push(current)
    this.dimensions = dims
    child.y = current.y
  }

  _reset() {
    if (this._wrapper?.childList?.length) {
      this._wrapper.childList.clear()
      this.dimensions = []
    }
  }

  clear() {
    this.dimensions = []
    this._index = 0
    this._impressionIndex = 0
    this._wrapper.childList.clear()
  }

  get margin() {
    return this._margin || 0
  }

  set margin(v) {
    this._margin = v
  }

  get dimensions(): Array<Dimension> {
    return this._dimensions
  }

  set dimensions(dimensions: Array<Dimension>) {
    this._dimensions = dimensions
  }

  /**
   * Capture interaction with the page
   * to prevent unneeded repositioning
   * when going back from another page
   * @param _e
   */
  override _captureKey(_e: KeyboardEvent): boolean {
    this._interaction = true
    return false
  }

  /**
   * We only intercept up and down
   * and leave the other keys for the
   * component to handle
   * @private
   */
  override _handleUp() {
    const newIndex = this._index - 1
    if (newIndex < 0) return false
    this.setIndex(newIndex)
  }

  override _handleDown() {
    const newIndex = this._index + 1
    if (newIndex >= this.items.length) return

    this.setIndex(newIndex)
    this._impressionIncrement()
  }

  _impressionIncrement() {
    ++this._impressionIndex
    this._fireMetrics(this.items[this._impressionIndex])
  }

  private setIndexThrottled = throttle((index = this._index, immediate = false) => {
    if (!this._wrapper.children[index]) {
      return
    }

    // update index
    this._index = index
    const current = this.scrolledItem
    this._instanceID = current.instanceID
    const style = Styler.getTag(this.getTag(current))

    if (!style.preventScroll) {
      const wrapper = this.tag('Wrapper')
      const position = this.dimensions[index]?.y || 0
      const y = !index ? 0 : ~~position * -1
      if (wrapper.y === y) return
      setSmooth(wrapper, 'y', y, { duration: !immediate ? 0.3 : 0 })
    }

    this._refocus()
    this.fireAncestors('$indexChanged', { index })
  }, 150)

  setIndex(index = this._index, immediate = false): void {
    this.setIndexThrottled(index, immediate)
  }

  /**
   * Use the current index
   * if the user has already interacted with the page
   * or false if instanceID is not defined
   * in other cases, search for the index by instanceID.
   * @param instanceID
   */
  getIndexByInstanceID(instanceID?: string): number | boolean {
    const id = this._interaction ? this._instanceID : instanceID

    if (!id) return false

    const index = this._wrapper.children.findIndex((child: any) => child.instanceID === id)
    return index > -1 ? index : false
  }

  _fireMetrics(item: any) {
    sendMetric(EVENTS.SHELF_IMPRESSION, {
      // We should probably change this when shelf position tracking is implemented.
      // For now it's not possible for this to be other than 1 (always restarts)
      'Content Position': 1,
      // Account for 0 based index and first item will always be the dynamic lead.
      'Custom Shelf Position': this._impressionIndex + 2,
      'Custom Shelf Title': item?.meta?.machineName ?? MPARTICLE_DEFAULT_ATTR_VALUE,
      Sponsor: item?.sponsorName ?? MPARTICLE_DEFAULT_ATTR_VALUE,
    })
  }

  get items() {
    return this._wrapper.children
  }

  get itemsLength() {
    return this.items.length
  }

  get scrolledItem() {
    return this.items[this._index]
  }

  get index() {
    return this._index
  }

  $getCurrentScrollPosition() {
    return {
      type: ScrollElementType.LIST,
      row: this._index,
      instanceID: this._instanceID,
    }
  }

  override _getFocused() {
    return this.scrolledItem || this
  }

  getStyleProperty() {}

  fireFirstShelfImpression() {
    this._fireMetrics(this.items[0])
  }
}
