export default {
  brandLanding: 'BRAND_LANDING',
  liveSchedule: 'LIVE_SCHEDULE',
}

export interface IMarketingModuleAnalytics {
  entityTitle: string
  isSponsoredMarketingModule: boolean
  itemsList: string[]
  listTitle: string
  machineName: string
  sponsorName: string | null
  shelfType?: string | null
}

export enum ContentGatingType {
  HARD = 'Hard',
  SOFT = 'Soft',
  NOT_APPLICABLE = 'N/A',
}
