import mParticle from '@mparticle/web-sdk'
import getAdAttributes from '../attributes/getAdAttributes'

const trackAdStart = (type: any) => ({
  getAttributes: getAdAttributes,
  getCategory: () => mParticle.EventType?.UserContent || 'UserContent',
  type,
  injectGlobals: true,
})

export default trackAdStart
