import Slide from '../Slide'
import ProgressBar from '../../progressBar/progressBar'

import { FastImg } from '../../../helpers'
import { getLiveStateClass, getUpcomingStateClass } from './states'
import { navigateUpcomingLiveItems } from '../helpers/upcomingItems'
import { COLORS, ENTITY_TYPES, OLY_IMPOLICY, SCREEN_SIZE } from '../../../constants'
import { TrackParams } from '../../types'
import { slideHeaderDecorator } from '../Slide/decorators'
import {
  audioLanguageDecorator,
  olympicsMedalDecorator,
  upcomingLiveTitleDecorator,
} from '../decorators'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default class UpcomingLiveSlide extends Slide {
  _updateButtonColor: any
  videoTitle: any

  static override DestinationTypes = {
    video: 'video',
    series: 'series',
    internalPage: 'internalPage',
    playlist: 'playlist',
  }

  static override _template() {
    const template = super._template()

    return {
      ...template,
      Content: {
        ...template.Content,
        WatchProgress: undefined,
        ProgressBar: undefined,
        LiveProgressBarContainer: {
          y: 610,
          w: UpcomingLiveSlide.liveProgressBarWidth,
          visible: false,
          LiveProgressBar: {
            type: ProgressBar,
            h: 10,
            totalProgressWidth: UpcomingLiveSlide.liveProgressBarWidth,
            totalProgressColor: COLORS.charcoalGrey,
            currentProgressColor: COLORS.red,
          },
        },
      },
    }
  }

  override set item(v: any) {
    this._item = v
    this._initSlide()

    if (v.isUpcoming) {
      this._setState('UpcomingState')
    } else {
      this._setState('LiveState')
    }

    this._updateButtonColor()

    this._addDecorators([
      slideHeaderDecorator,
      olympicsMedalDecorator(10),
      audioLanguageDecorator,
      upcomingLiveTitleDecorator,
    ])
  }

  _initSlide() {
    this.patch({
      ImageHolder: {
        Image: {
          texture: FastImg(this._item.image).cover(
            SCREEN_SIZE.width,
            SCREEN_SIZE.height,
            OLY_IMPOLICY.RECT_LARGE_1920_1080
          ),
        },
      },
      Content: {
        LogoArea: {
          Logo: { texture: FastImg(this._item.titleLogo).contain(600, 160) },
        },
        BrandLogoHolder: {
          BrandLogo: {
            texture: FastImg(this._item.whiteBrandLogo).contain(120, 50),
          },
        },
      },
    })

    if (this._item.sponsorName) this._addSponsorBadge()

    this.tag('Border').alpha = 0.45
    this.buttonFontColor = COLORS.lightGray3
  }

  override _onLogoTxError() {
    super._onLogoTxError()
  }

  override _handleEnter() {
    this.trackContentPosition()
    this.trackContentClick()
    navigateUpcomingLiveItems({
      item: this._item,
      fireAncestors: this.fireAncestors.bind(this),
      preventClickMetric: true,
    })
  }

  override _getAnalytics() {
    return this._item.analytics
  }

  override _getTrackParams(): TrackParams {
    return {
      isLive: this._item.isLive,
      isUpcoming: this._item.isUpcoming,
    }
  }

  override _getEntityType() {
    return ENTITY_TYPES.UPCOMING_LIVE_SLIDE
  }

  static override get tag() {
    return 'UpcomingLiveSlide'
  }

  override get audioLanguageContainer() {
    return this.tag('AudioLanguageContainer') || this.tag('LiveAudioLanguageContainer')
  }

  static get liveProgressBarWidth() {
    return 370
  }

  static override _states() {
    return [getUpcomingStateClass(this), getLiveStateClass(this)]
  }
}
