import { setDefaultValue } from '../../../../helpers/analytics'
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants'
import OnAirNowShelf from '../../../../api/models/collections/OnAirNowShelf'

const getTitle = (item: any) => {
  if (!item) return ''

  if (item.data?.title) return item.data.title

  // OnAirNowShelf Tile
  if (item.onAirNowTileData?.title) return item.onAirNowTileData.title

  // BrandTile
  if (item.data?.displayTitle) return item.data.displayTitle

  // PremiumShelf or unknown (has no known title)
  return item.analytics?.entityTitle ?? MPARTICLE_DEFAULT_ATTR_VALUE
}

const getShelfTitle = (data: any) => {
  let title = ''
  if (data.listTitle) title += `${data.listTitle},`
  return !data.machineName ? title : title + data.machineName
}
const makeShelfItem = (shelves: any, number: any, val: any, isTitle = true) =>
  (shelves[`Custom Shelf ${isTitle ? 'Title' : 'Items'} ${number}`] = val)

const makeShelfItems = (shelves: any, number: any, data: any) => {
  makeShelfItem(shelves, number, getShelfTitle(data) || MPARTICLE_DEFAULT_ATTR_VALUE, true)
  makeShelfItem(shelves, number, getItemTitles(data.items) || MPARTICLE_DEFAULT_ATTR_VALUE, false)
}

const getItemTitles = (items: any) =>
  Object.keys(items)
    .map((k) => getTitle(items[k]))
    .join(',')

const getShelvesTitleData = ({ params, featured }: any) => {
  const shelves = {}
  // Start the shelf count at 1
  let shelfNumber = 1
  //Check for a separate feature shelf (dynamic lead shelf)
  if (featured) {
    makeShelfItems(shelves, 1, featured.data)
    shelfNumber++
  }

  if (params) {
    Object.keys(params).forEach((key) => {
      // key/item have been null, protect from this.
      if (params[key]) {
        const { data } = params[key]
        // Data can sometimes be undefined if for instance the firstVisit is passed
        if (data?.items) {
          // On air now shelf needs to be reordered to follow tiles.
          const _data =
            data.__typename === 'OnAirNowList'
              ? {
                  ...data,
                  items: OnAirNowShelf.getFilteredSortedItems(data.items),
                }
              : data
          makeShelfItems(shelves, shelfNumber, _data)
          shelfNumber++
        }
      }
    })
  }
  return shelves
}
const getShelvesLoadAttributes = (params: any) => {
  if (!params) {
    return {}
  }
  const shelves = getShelvesTitleData(params)
  return setDefaultValue(shelves, MPARTICLE_DEFAULT_ATTR_VALUE)
}

export default getShelvesLoadAttributes
