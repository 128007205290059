export enum LiveControlButtons {
  BackToLiveButton = 'BackToLiveButton',
  LiveGuideButton = 'LiveGuideButton',
}

export const LiveControlsStateFactory = (base: any, parentTag = 'PlayerControls.LiveControls') =>
  class LiveControls extends base {
    _focusedButton: LiveControlButtons

    _getFocused() {
      return this.tag(parentTag)
    }

    get focusedButton() {
      return this._focusedButton
    }

    set focusedButton(buttonToFocus: LiveControlButtons) {
      this._clearFocus(this.focusedButton)
      this._focusedButton = buttonToFocus
      this._updateUI()
    }

    $enter() {
      this.focusedButton = this.tag(parentTag).tag(LiveControlButtons.BackToLiveButton).visible
        ? LiveControlButtons.BackToLiveButton
        : LiveControlButtons.LiveGuideButton
    }

    $exit() {
      this._clearFocus(this.focusedButton)
    }

    _handleEnter() {
      if (this._focusedButton === LiveControlButtons.LiveGuideButton) {
        this.fireAncestors('$openLiveGuide')
      } else if (this._focusedButton === LiveControlButtons.BackToLiveButton) {
        this.fireAncestors('$backToLive')
      }
    }

    _handleLeft() {
      if (
        this._focusedButton === LiveControlButtons.LiveGuideButton &&
        this.tag(parentTag).tag(LiveControlButtons.BackToLiveButton).visible
      ) {
        this.focusedButton = LiveControlButtons.BackToLiveButton
      }
    }

    _handleRight() {
      if (this.focusedButton === LiveControlButtons.BackToLiveButton) {
        this.focusedButton = LiveControlButtons.LiveGuideButton
      }
    }

    _updateUI() {
      this.tag(parentTag).announce = this.tag(parentTag).tag(this.focusedButton).label
      this.tag(parentTag).tag(this.focusedButton)._focus()
    }

    _clearFocus(buttonToUnfocus: LiveControlButtons | undefined) {
      if (!buttonToUnfocus) return

      this.tag(parentTag).tag(buttonToUnfocus)._unfocus()
    }
  }
