import { Log } from '@lightningjs/sdk'

import VideoTile from './VideoTile/VideoTile'
import {
  audioLanguageDecorator,
  labelBadgeDecorator,
  lockDecorator,
  olympicsMedalDecorator,
} from './decorators'

import { FastImg } from '../../helpers'
import {
  COLORS,
  ENTITY_TYPES,
  FLEX_DIRECTION,
  FONT_FACE,
  MAX_LINES_SUFFIX,
  ROUTE,
  ROUTE_ENTITY,
  OLY_IMPOLICY,
} from '../../constants'
import RouterUtil from '../../util/RouterUtil'
import { DebugControllerSingleton } from '../../util/debug/DebugController'

export default class ReplayTile extends VideoTile {
  override _item: any
  override set item(v: any) {
    this._item = v

    this._item.obj.analytics.pid = v.obj?.replayTileData?.pid?.toString?.()

    this.patch({
      ImageHolder: {
        w: 420,
        h: 235,
        Image: { texture: FastImg(v.image).contain(420, 235, OLY_IMPOLICY.RECT_SMEDIUM_640_360) },
      },
      Content: {
        TitleCard: {
          ShortTitleContainer: {
            ShortTitle: {
              text: {
                text: this._getMainTitle(),
                maxLines: 1,
                maxLinesSuffix: MAX_LINES_SUFFIX,
              },
            },
          },
          Title: {
            text: {
              text: this._getSubTitle(),
              maxLines: 1,
              maxLinesSuffix: MAX_LINES_SUFFIX,
            },
          },
          SmartTimeStamp: {
            flex: {},
            flexItem: { marginTop: -8 },
            ShortTitle: {
              flexItem: { marginRight: 8 },
              text: {
                text: this._item?.tertiaryTitle,
                fontSize: 24,
                textColor: COLORS.white,
                fontFace: FONT_FACE.light,
                lineHeight: 24,
              },
            },
            AudioLanguageContainer: {
              flex: { direction: FLEX_DIRECTION.row },
            },
          },
        },
        Logo: { texture: FastImg(v.logo).contain(60, 40) },
      },
    })
  }

  override _init() {
    super._init()
    this._addDecorators([
      labelBadgeDecorator(this._item?.labelBadge),
      lockDecorator,
      olympicsMedalDecorator(5),
      audioLanguageDecorator,
    ])
  }

  override _getMainTitle() {
    return this._item.title
  }

  override _getSubTitle() {
    return this._item.secondaryTitle
  }

  override _handleEnter() {
    Log.info('ReplayTileItem', this._item)
    this.trackContentPosition()
    this.trackContentClick(ENTITY_TYPES.REPLAY)
    DebugControllerSingleton.streamUrl = this._item.HARDCODEDSTREAM
    RouterUtil.navigateToRoute(ROUTE.watch, {
      entity: ROUTE_ENTITY.pid,
      value: this._item.pid,
    })
  }

  get olympicsMedalContainer() {
    return this.tag('ShortTitleContainer')
  }

  static override get tag() {
    return 'ReplayTile'
  }
}
