import { Lightning } from '@lightningjs/sdk'

import Item from './Item'

import {
  COLORS,
  ENTITY_TYPES,
  FLEX_DIRECTION,
  FONT_FACE,
  ROUTE,
  ROUTE_ENTITY,
} from '../../constants'
import { FastImg } from '../../helpers'
import RouterUtil from '../../util/RouterUtil'
import { OLY_IMPOLICY } from '../../constants/images'

export default class BrandTile extends Item {
  override _item: any

  override _init() {
    super._init()
    this.containerHeight = 308
    this.containerWidth = 428
  }

  static override _template() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2340: Only public and protected methods of the base class are accessible via the 'super' keyword.
    const duration = super.duration
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2340: Only public and protected methods of the base class are accessible via the 'super' keyword.
    const timingFunction = super.timingFunction
    const template = super._template()

    return {
      ...template,
      ShadowBlur: {
        mount: 0.5,
        x: 210,
        y: 120,
        w: 526,
        h: 300,
        alpha: 0.8,
        type: Lightning.components.FastBlurComponent,
        amount: 1,
        content: {
          Shadow: {
            color: COLORS.black,
            mount: 0.5,
            x: 263,
            y: 150,
            texture: Lightning.Tools.getRoundRect(426, 240, 0),
          },
        },
        transitions: {
          y: { duration, timingFunction },
          scale: { duration, timingFunction },
          alpha: { duration, timingFunction },
          amount: { duration, timingFunction },
        },
      },
      ImageHolder: {
        pivotX: 0,
        w: 426,
        h: 240,
        rect: true,
        color: COLORS.dark7,
        White: {
          mount: 0.5,
          x: 213,
          y: 120,
          transitions: {
            alpha: { duration, timingFunction },
          },
        },
        Colored: {
          mount: 0.5,
          x: 213,
          y: 120,
          alpha: 0,
          transitions: {
            alpha: { duration, timingFunction },
          },
        },
        transitions: {
          scale: { duration, timingFunction },
          color: { duration, timingFunction },
        },
      },
      Content: {
        y: 250,
        TitleCard: {
          flex: { direction: FLEX_DIRECTION.column },
          ShortTitle: {
            text: {
              fontFace: FONT_FACE.semiBold,
              fontSize: 26,
              lineHeight: 32,
              maxLines: 3,
              wordWrapWidth: 360,
            },
          },
          transitions: {
            y: { duration, timingFunction },
          },
        },
      },
    }
  }

  set item(v: any) {
    this._item = v
    this.patch({
      ImageHolder: {
        White: {
          texture: FastImg(v.whiteBrandLogo).contain(230, 120, OLY_IMPOLICY.RECT_SMALL_384_216),
        },
        Colored: {
          texture: FastImg(v.colorBrandLogo).contain(230, 120, OLY_IMPOLICY.RECT_SMALL_384_216),
        },
      },
      Content: {
        TitleCard: {
          ShortTitle: {
            text: { text: v.shortTitle },
          },
        },
      },
    })
  }

  override _handleEnter() {
    this.trackContentPosition()
    this.trackContentClick(ENTITY_TYPES.BRAND)
    RouterUtil.navigateToRoute(ROUTE.networkLanding, {
      entity: ROUTE_ENTITY.machineName,
      value: this._item.machineName,
    })
  }

  override _focusAnimation(v: boolean) {
    this.patch({
      smooth: { alpha: v ? 1 : 0.7 },
      ShadowBlur: {
        smooth: {
          alpha: v ? 0.2 : 0.8,
        },
      },
      ImageHolder: {
        smooth: { color: v ? COLORS.white : COLORS.dark7 },
        White: {
          smooth: { alpha: v ? 0 : 1 },
        },
        Colored: {
          smooth: { alpha: v ? 1 : 0 },
        },
      },
    })
    super._focusAnimation(v)
  }

  static override get tag() {
    return 'BrandTile'
  }
}
