import { Lightning } from '@lightningjs/sdk'
import { COLORS } from '../constants'

type Corner = { x: number; y: number }
enum Directions {
  TopLeft = 'topLeft',
  TopRight = 'topRight',
  BottomLeft = 'bottomLeft',
  BottomRight = 'bottomRight',
}
const DirectionCornerMap = {
  [Directions.TopLeft]: 180,
  [Directions.TopRight]: 270,
  [Directions.BottomLeft]: 90,
  [Directions.BottomRight]: 0,
} as const

const getXAndY = (
  direction: Directions,
  x: number,
  y: number,
  radius: number
): [number, number] => {
  const offset = radius
  switch (direction) {
    case Directions.TopLeft:
      return [x + offset, y + offset]
    case Directions.TopRight:
      return [x - offset, y + offset]
    case Directions.BottomLeft:
      return [x + offset, y - offset]
    case Directions.BottomRight:
      return [x - offset, y - offset]
    default:
      return [0, 0]
  }
}

const idPrefix = 'InverseRoundedCorner'

export class InverseRoundedCornerTexture extends Lightning.textures.StaticCanvasTexture {
  _corners?: Partial<Record<Directions, Corner>>
  _fillColor?: COLORS
  _radius = 3

  set fillColor(color: COLORS) {
    this._fillColor = color
  }

  set radius(radius: number) {
    this._radius = radius
  }

  set corners(corners: Partial<Record<Directions, Corner>>) {
    this._corners = corners
    const factory = (cb: any, stage: Lightning.Stage) => {
      cb(null, this.createShader(stage))
    }
    this.content = { factory, lookupId: this.internalId }
  }

  get internalId() {
    if (!this._corners) return idPrefix
    return Object.keys(this._corners).reduce((acc, direction) => {
      const corner = this._corners?.[direction as Directions]
      return corner ? acc + `${corner.x}${corner.y}${this._radius}` : acc
    }, idPrefix)
  }

  _reduceKey(key: keyof Corner): number[] {
    if (!this._corners) return [this._radius * 2]
    return [
      ...Object.keys(this._corners).map(
        (k: string) => this._corners?.[k as Directions]?.[key] || 0
      ),
      this._radius * 2,
    ]
  }

  createShader = (stage: Lightning.Stage) => {
    const canvas = stage.platform.getDrawingCanvas()
    const ctx = canvas.getContext('2d')
    if (!ctx || !this._corners) return canvas
    ctx.imageSmoothingEnabled = true

    canvas.width = Math.max(...this._reduceKey('x'))
    canvas.height = Math.max(...this._reduceKey('y'))

    Object.keys(this._corners).forEach((key) => {
      const corner = this._corners?.[key as Directions]
      if (!corner) return
      const { x, y } = corner
      const startAngleDeg = DirectionCornerMap[key as Directions]
      const startAngle = startAngleDeg * (Math.PI / 180)
      const endAngle = startAngle + Math.PI * 0.5
      const [arcX, arcY] = getXAndY(key as Directions, x, y, this._radius)
      ctx.beginPath()
      ctx.moveTo(x, y)
      ctx.arc(arcX, arcY, this._radius, startAngle, endAngle, false)
      if (this._fillColor) ctx.fillStyle = Lightning.StageUtils.getRgbaString(this._fillColor)
      ctx.closePath()
      ctx.fill()
    })
    return canvas
  }
}
