import { GraphQlClient } from '../../graphql/client'
import {
  BrandTitleCategoriesQuery,
  BrandTitleCategoriesQueryVariables,
} from '../../graphql/generated/types'
import brandTitleCategoriesMapper from '../../graphql/mappers/brandTitleCategories'
import rsnFilter from '../../graphql/filter/rsnFilter'
import { QueryName } from './bffQuery'

export default async (variables: BrandTitleCategoriesQueryVariables) => {
  return await GraphQlClient.query<BrandTitleCategoriesQuery>({
    query: QueryName.brandTitleCategories,
    variables,
  })
    .then(brandTitleCategoriesMapper)
    .then(rsnFilter)
}
