import OnboardingActivation from './OnboardingActivation'

import { EVENTS } from '../../../lib/analytics/types'
import { sendMetric } from '../../../lib/analytics/Analytics'
import { LoadingStateFactory } from './states/LoadingStateFactory'
import { NewCodeStateFacrory } from './states/NewCodeStateFacrory'

export default class ContentGatingActivation extends OnboardingActivation {
  override _titleTextKey = 'content_gating_activation_title'
  override _routerBackDisabled = false

  static override _states() {
    return [
      LoadingStateFactory(this),
      NewCodeStateFacrory(this),
      class Cancel extends this {
        override _getFocused() {
          return this.tag('CancelButton')
        }
        override _handleEnter(): void {
          sendMetric(EVENTS.CLICK, {
            name: 'Maybe Later',
          })
          super._handleBack()
        }
        override _handleUp() {
          this._setState('NewCode')
        }
      },
    ]
  }
}
