export default class UpcomingModal {
  _analytics: any
  data: any
  isOlympics = false
  constructor(obj: any, isOlympics: boolean) {
    this._analytics = obj?.analytics || {}
    this.data = obj?.data || {}
    this.isOlympics = isOlympics
  }

  get backgroundImage() {
    return this.data.backgroundImage
  }

  get channelId() {
    return this.data.channelId
  }

  get ctaText() {
    return this.data.ctaText
  }

  get description() {
    return this.data.description
  }

  get dismissText() {
    return this.data.dismissText
  }

  get title() {
    return this.data.title
  }

  get startTime() {
    return this.data.startTime
  }

  get modalName() {
    return this._analytics.modalName
  }

  get modalType() {
    return this._analytics.modalType
  }

  get countdownDayLabel() {
    return this.data.countdownDayLabel
  }

  get countdownHourLabel() {
    return this.data.countdownHourLabel
  }

  get countdownMinLabel() {
    return this.data.countdownMinLabel
  }

  get customerPlayableDate() {
    return this.data.customerPlayableDate
  }

  get pid() {
    return this.data.pid
  }
}
