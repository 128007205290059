import { paginatedComponent, paginatedShelfRequest, videoSectionRequest } from '../../api/requests'
import rsnFilter from '../../graphql/filter/rsnFilter'
import { VideosSectionPageQueryVariables } from '../../graphql/generated/types'
import videoSectionMapper from '../../graphql/mappers/videoSection'
import { createRequestConfig } from '../../lib/useRequest'

export const PaginatedShelfRequestConfig = (variables: any) =>
  createRequestConfig({
    query: paginatedShelfRequest,
    variables,
    reject: [],
  })

export const PaginatedComponentRequestConfig = (variables: any) =>
  createRequestConfig({
    query: paginatedComponent,
    variables,
    reject: [],
  })

export const VideoSectionRequestConfig = (variables: VideosSectionPageQueryVariables) =>
  createRequestConfig({
    query: videoSectionRequest,
    variables,
    mapper: (data) => rsnFilter(videoSectionMapper(data as any)),
  })
