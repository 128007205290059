import { Language, Router } from '@lightningjs/sdk'
import {
  COLORS,
  FLEX_DIRECTION,
  FONT_FACE,
  ROUTE,
  ROUTE_ENTITY,
  VERTICAL_ALIGN,
  TEXT_ALIGN,
} from '../../../../constants'
import { TempPassSingleton } from '../../../../lib/TempPass'
import RouterUtil from '../../../../util/RouterUtil'
import {
  ACTIVATION_LANDING,
  ACTIVATION_SCREEN_FONT_OVERFLOW_MULTIPLIER,
  ACTIVATION_TYPE,
} from '../constants'
import { ActivationStrategy } from './ActivationStrategy'
import LaunchDarklySingleton from '../../../../lib/launchDarkly/LaunchDarkly'
import LaunchDarklyFeatureFlags from '../../../../lib/launchDarkly/LaunchDarklyFeatureFlags'
import { parseMarkdownBold } from '../../../../helpers'
import { Subscription } from 'rxjs'
import { DebugControllerSingleton } from '../../../../util/debug/DebugController'
import ModalManager, { CloseReason } from '../../../../lib/ModalManager'

enum RedirectState {
  IDLE,
  CONTEXT_UPDATED,
  REDIRECTED,
}

export class TempPassActivationStrategy extends ActivationStrategy {
  _ldRedirectState = RedirectState.IDLE
  _ldSubscription: Subscription | undefined

  get isSecondaryTempPass() {
    return this._ctx._landing === ACTIVATION_LANDING.NBC
  }

  override drawText() {
    let texts: string[] = []

    if (this.isSecondaryTempPass) {
      const debugSecondary = DebugControllerSingleton.tempPassName?.[1]
      const { initialTempPassName = '', secondaryTempPassName = '' } =
        LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.tempPass)
      const tempPassDuration =
        (debugSecondary || secondaryTempPassName || initialTempPassName)?.replace(/\D/g, '') || 30
      texts = ['tempPass-activation-header', 'tempPass-activation-description'].map(
        (t) => Language.translate(t, tempPassDuration) as string
      )

      const containerWidth = 1500
      const titleOverflowStartLength = 60
      const subtitleOverflowStartLength = 100
      const title = texts[0]
      const subtitle = texts[1]

      const titleOverflows = (title?.length || 0) >= titleOverflowStartLength
      const subtitleOverflows = (subtitle?.length || 0) > subtitleOverflowStartLength
      const subtitleMargin = subtitleOverflows ? 5 : 8
      const commonStyles = {
        fontSize: subtitleOverflows
          ? (containerWidth / Math.round(subtitle?.length || 0)) *
            ACTIVATION_SCREEN_FONT_OVERFLOW_MULTIPLIER
          : 42,
        textColor: COLORS.white,
        verticalAlign: VERTICAL_ALIGN.middle,
      }
      const regularObject = {
        flexItem: { marginRight: subtitleMargin },
        text: {
          ...commonStyles,
          fontFace: FONT_FACE.light,
        },
      }
      const boldObject = {
        flexItem: { marginRight: subtitleMargin },
        text: {
          ...commonStyles,
          fontFace: FONT_FACE.semiBold,
        },
      }
      const baseObject = {
        x: containerWidth / 2 + 10,
        mountX: 0.5,
        y: 96,
        color: COLORS.white,
        flex: { direction: FLEX_DIRECTION.row },
      }
      this._ctx.tag('ActivationScreen').patch({
        Title: {
          x: containerWidth / 2,
          mountX: 0.5,
          text: {
            ...(titleOverflows
              ? {
                  fontSize:
                    (containerWidth / Math.round(title?.length || 0)) *
                    ACTIVATION_SCREEN_FONT_OVERFLOW_MULTIPLIER,
                  maxLines: 1,
                  wordWrapWidth: 0,
                }
              : {}),
            text: title,
            textAlign: TEXT_ALIGN.center,
          },
        },
        Subtitle: parseMarkdownBold(texts[1], regularObject, boldObject, baseObject),
      })
      ;(texts as any)[1].replace('**', '') // Replace markdown markers for announce
    } else {
      const title = Language.translate('live_stream_link_msg')
      this._ctx.tag('Title').text.text = title
      texts = [title]
    }
    return texts
  }

  override destroy() {
    super.destroy()
    this._cleanListeners()
  }

  _cleanListeners() {
    if (this._ldSubscription) {
      this._ldSubscription.unsubscribe()
      this._ldSubscription = undefined
    }
  }

  _setContextUpdated() {
    this._ldRedirectState = RedirectState.CONTEXT_UPDATED
  }

  _listenToLaunchDarkly() {
    this._ldSubscription = LaunchDarklySingleton.events.subscribe(() => {
      this._setContextUpdated()
      this._redirect()
    })
  }

  override _endNotification() {
    if (this.isSecondaryTempPass) {
      const flag = LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.tempPass)
      const contextAlreadyUpdated = flag && !flag.secondaryTempPassName

      if (contextAlreadyUpdated) {
        this._setContextUpdated()
      } else {
        this._listenToLaunchDarkly()
      }
    }
    super._endNotification()
  }

  override _redirect() {
    if (
      this._activationType &&
      ![ACTIVATION_TYPE.NBC, ACTIVATION_TYPE.MVPD].includes(this._activationType)
    ) {
      return
    }
    ModalManager.close(CloseReason.COMPLETED)
    TempPassSingleton.endSession()
    if (this.isSecondaryTempPass && this._ldRedirectState === RedirectState.CONTEXT_UPDATED) {
      this._cleanListeners()
      const page = Router.getActivePage() as any
      if (page?.restartTempPass) {
        page.restartTempPass()
        return
      }
    }
    if (this._ctx._streamId) {
      RouterUtil.navigateToRoute(ROUTE.watch, {
        entity: ROUTE_ENTITY.pid,
        value: this._ctx._streamId,
      })
      return
    }
    RouterUtil.navigateToRoute(ROUTE.home, {})
  }
}
