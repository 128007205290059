import { EVENTS, MparticleEvents } from '../../types'

import trackError from './trackError'
import trackPageLoad from './trackPageLoad'
import trackLaunch from './trackLaunch'
import trackLiveSessionStart from './trackLiveSessionStart'
import trackContentClick from './trackContentClick'
import trackVodSessionStart from './trackVodSessionStart'
import trackMvpdAuthSuccess from './trackMvpdAuthSuccess'
import trackMvpdPageAbandoned from './trackMvpdPageAbandoned'
import trackPlayerLoadTime from './trackPlayerLoadTime'
import trackNbcAuthSuccess from './trackNbcAuthSuccess'
import trackNbcProfileSignOut from './trackNbcProfileSignOut'
import trackProgramStart from './trackLinearProgramStart'
import trackProgramEnd from './trackLinearProgramEnd'
import trackLiveSessionEnd from './trackLiveSessionEnd'
import trackSearchResult from './trackSearchResult'
import trackClick from './trackClick'
import trackVodSessionEnd from './trackVodSessionEnd'
import trackIDMConversion from './trackIDMConversion'
import trackAdStart from './trackAdStart'
import trackAdEnd from './trackAdEnd'
import trackAdBreakStart from './trackAdBreakStart'
import trackAdBreakEnd from './trackAdBreakEnd'
import trackShelfImpression from './trackShelfImpression'
import trackShelvesLoad from './trackShelvesLoad'
import trackDynamicLeadImpression from './trackDynamicLeadImpression'
import trackModalLoad from './trackModalLoad'
import trackEndCardImpression from './trackEndCardImpression'
import trackEndCard from './trackEndCard'
import trackLiveToVodImpression from './trackLiveToVodImpression'
import trackExit from './trackExit'
import trackMarketingModuleImpression from './trackMarketingModuleImpression'
import trackMarketingModuleVideoPreview from './trackMarketingModuleVideoPreview'

// Add exclusions for events that are only handled at the interface
export const events: Record<
  Exclude<MparticleEvents, EVENTS.LIVE_PLAYER_LOAD>,
  (...params: any[]) => any
> = {
  trackError,
  trackPageLoad,
  trackLaunch,
  trackLiveSessionStart,
  trackContentClick,
  trackVodSessionStart,
  trackMvpdAuthSuccess,
  trackMvpdPageAbandoned,
  trackPlayerLoadTime,
  trackNbcAuthSuccess,
  trackNbcProfileSignOut,
  trackProgramStart,
  trackProgramEnd,
  trackLiveSessionEnd,
  trackSearchResult,
  trackClick,
  trackVodSessionEnd,
  trackIDMConversion,
  trackAdStart,
  trackAdEnd,
  trackAdBreakStart,
  trackAdBreakEnd,
  trackShelfImpression,
  trackShelvesLoad,
  trackDynamicLeadImpression,
  trackModalLoad,
  trackEndCardImpression,
  trackEndCard,
  trackLiveToVodImpression,
  trackExit,
  trackMarketingModuleImpression,
  trackMarketingModuleVideoPreview,
}
