import AnalyticsSingleton, { sendMetric } from '../../../../lib/analytics/Analytics'
import { EVENTS } from '../../../../lib/analytics/types'
import BasePlayer from '../../BasePlayer'
import { AnalyticsWithEndCardDelegate } from './AnalyticsWithEndCardDelegate'

export class LinearSLEAnalyticsDelegate extends AnalyticsWithEndCardDelegate<BasePlayer> {
  fireProgramChange() {
    sendMetric(EVENTS.LIVE_PROGRAM_CHANGE, this._getMetricsData())
  }

  updateUserDefaultAttributes(tempPassTokenName: any) {
    AnalyticsSingleton.updateUserDefaultAttributes(tempPassTokenName)
  }
}
