import { Lightning, Utils } from '@lightningjs/sdk'

import { VideoTile } from '../index'
import {
  clipDataDecorator,
  durationBadgeDecorator,
  labelBadgeDecorator,
  liveBadgeDecorator,
  lockDecorator,
  movieDataDecorator,
  sfvodDataDecorator,
} from './decorators'
import Sprite from '../Sprite'

import {
  COLORS,
  COMPONENT_TYPES,
  CONTENT_TYPE,
  END_CARD_AUTOPLAY_TIME,
  END_CARD_SLE_AUTOPLAY_TIME,
  END_CARD_FER_AUTOPLAY_TIME,
  BASE_FPS,
  FONT_FACE,
  PROGRAMMING_TYPES,
  ROUTE,
  ROUTE_ENTITY,
  TEXT_ALIGN,
  VERTICAL_ALIGN,
} from '../../constants'
import { FastImg } from '../../helpers'
import RouterUtil from '../../util/RouterUtil'

import LaunchDarklyFeatureFlags from '../../lib/launchDarkly/LaunchDarklyFeatureFlags'
import LaunchDarklySingleton from '../../lib/launchDarkly/LaunchDarkly'
import { OLY_IMPOLICY } from '../../constants/images'

export default class EndCardAlternate extends VideoTile {
  override _item: any
  _CountDown: any
  _TimerPause: any
  _analytics: any
  _index: any
  _isAnimationsStarted: any
  _isDefault: any
  _loader: any
  _tileComponentType: any
  _countdownInterval = END_CARD_AUTOPLAY_TIME
  _animEndDuration = 55
  _isSLE = false
  _fps = 11

  static override _template() {
    const template = super._template()

    return {
      ...template,
      TagLine: {
        y: -88,
        text: {
          fontFace: FONT_FACE.regular,
          textColor: COLORS.lightGray3,
          fontSize: 40,
          lineHeight: 50,
          verticalAlign: VERTICAL_ALIGN.middle,
        },
      },
      Timer: {
        alpha: 0,
        Ovel: {
          x: 167,
          y: 69,
          texture: Lightning.Tools.getRoundRect(
            106,
            106,
            53,
            0,
            COLORS.transparent,
            true,
            COLORS.black10
          ),
          CountDown: {
            y: -20,
            w: 106,
            h: 106,
            text: {
              fontFace: FONT_FACE.semiBold,
              textAlign: TEXT_ALIGN.center,
              textColor: COLORS.lightGray3,
              fontSize: 72,
              lineHeight: 126,
              verticalAlign: VERTICAL_ALIGN.middle,
            },
          },
          TimerPause: {
            x: 39,
            y: 35,
            alpha: 0,
            src: Utils.asset('images/timer-pause.png'),
          },
        },
        Progress: {
          x: 147,
          y: 49,
          type: Sprite,
          scale: 0.926,
        },
      },
    }
  }

  override _init() {
    super._init()
    this.containerHeight = 380
    this.containerWidth = 420
    this._loader = this.tag('Progress')
    this._TimerPause = this.tag('TimerPause')
    this._CountDown = this.tag('CountDown')

    const source = this?._item?.obj?.source
    switch (source) {
      case 'FER':
        this._countdownInterval = END_CARD_FER_AUTOPLAY_TIME
        this._fps = BASE_FPS / this._countdownInterval
        this._animEndDuration = this._countdownInterval * this._fps
        break
      case 'SLE':
        this._countdownInterval =
          LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.sleBingeTimer) ||
          END_CARD_SLE_AUTOPLAY_TIME
        this._fps = BASE_FPS / this._countdownInterval
        this._animEndDuration = this._countdownInterval * this._fps
        break
      default:
        this._countdownInterval = END_CARD_AUTOPLAY_TIME
    }

    this._CountDown.patch({
      text: {
        text: this._countdownInterval,
      },
    })

    this._loader.setSpriteAnimation({
      spriteSheet: 'images/spriteSheets/final_endtimer7x8.png',
      totalColumns: 8,
      totalRows: 7,
      spriteAnimationSets: {
        animation: { start: 0, end: this._animEndDuration, repeat: 0 },
      },
      fps: this._fps,
    })
    this._loader.on('spriteLoopCycleEnd', () => {
      this._handleEnter({}, true)
      this._stopAnimation()
    })
    this._loader.on('updateInterval', () => {
      if (this._countdownInterval > 0) {
        this._countdownInterval--
        this._CountDown.text.text = this._countdownInterval
      }
    })
  }

  override _inactive() {
    this._stopAnimation()
  }

  set index(index: any) {
    this._index = index
    this.patch({
      Timer: {
        alpha: this._index === 0 ? 1 : 0,
      },
    })
  }

  override set item(v) {
    this._tileComponentType = v.tileComponent
    this._item = v.tile
    this._analytics = v.analytics
    this._isDefault = v._isDefault

    this.patch({
      ImageHolder: {
        w: 420,
        h: 235,
        Image: { texture: FastImg(this._item.image).contain(420, 420, OLY_IMPOLICY.SQUARE) },
      },
      Content: {
        Logo: {
          texture: FastImg(this._item.logo).contain(60, 40, OLY_IMPOLICY.RECT_SMALL_384_216),
        },
      },
      TagLine: {
        text: { text: v.tagLine },
      },
    })

    if (
      [
        COMPONENT_TYPES.VIDEO_TILE,
        COMPONENT_TYPES.UPCOMING_LIVE_TILE,
        COMPONENT_TYPES.REPLAY_TILE,
      ].includes(this._tileComponentType)
    ) {
      this.patch({
        Content: {
          TitleCard: {
            ShortTitleContainer: {
              ShortTitle: {
                text: { text: this._getMainTitle(this._item) },
              },
            },
            Title: {
              text: { text: this._getSubTitle(this._item) },
            },
          },
        },
      })

      movieDataDecorator(this)
      sfvodDataDecorator(this)
      clipDataDecorator(this)
      if (this._tileComponentType === COMPONENT_TYPES.UPCOMING_LIVE_TILE) {
        this._addDecorators([liveBadgeDecorator(this._item?.liveBadge, 184)])
      }
    } else {
      this.patch({
        Content: {
          TitleCard: {
            ShortTitleContainer: {
              ShortTitle: {
                text: { text: this._item.title },
              },
            },
          },
        },
      })
    }
    this._addDecorators([labelBadgeDecorator(this._item?.labelBadge), durationBadgeDecorator])

    this._updateUI(this._isSLE)
  }

  override get item() {
    return this._item
  }

  override _getSubTitle(v: any) {
    switch (v.programmingType) {
      case PROGRAMMING_TYPES.MOVIE:
        return ''
      case PROGRAMMING_TYPES.FULL_EPISODE:
        return v.showMetadata
      case PROGRAMMING_TYPES.SFVOD:
      default:
        return v.secondaryTitle
    }
  }

  override _active() {
    if (
      this._tileComponentType === COMPONENT_TYPES.VIDEO_TILE ||
      this._tileComponentType === COMPONENT_TYPES.UPCOMING_LIVE_TILE
    ) {
      if (this._item && this._item.isLocked) {
        this._addDecorators([lockDecorator])
      }
    }
  }

  override _handleEnter(event?: any, isAutoPlay = false) {
    this._setState('ItemSelected')

    //add updated tracking here
    this.fireAncestors('$trackEndCardSelection', this._analytics, this._isDefault, isAutoPlay)

    switch (this._tileComponentType) {
      case 'VideoTile':
        RouterUtil.navigateToRoute(ROUTE.video, {
          entity: ROUTE_ENTITY.videoId,
          value: this._item.mpxGuid,
        })
        break
      case 'SeriesTile':
        RouterUtil.navigateToRoute(ROUTE.showHome, {
          entity: ROUTE_ENTITY.urlAlias,
          value: this._item.urlAlias,
        })
        break
      case 'MovieTile':
        RouterUtil.navigateToRoute(ROUTE.movieHome, {
          entity: ROUTE_ENTITY.itemName,
          value: this._item.urlAlias,
        })
        break
      case 'ReplayTile':
      case 'UpcomingLiveTile':
        RouterUtil.navigateToRoute(
          ROUTE.watch,
          {
            entity: ROUTE_ENTITY.pid,
            value: this._item.data.pid || this._item.obj.replayTileData.pid,
          },
          { forceAnnounce: this._getForceAnnounce() }
        )
        break
    }
  }

  override _focus() {
    super._focus()
    if (this._index === 0) {
      this._doAnimate()
    }
    this._updateUI(true)
  }

  override _unfocus() {
    super._unfocus()
    if (this._index === 0) {
      this._pauseAnimation()
    }
    this._updateUI(false)
  }

  _updateUI(v: any) {
    this.patch({
      ImageHolder: {
        alpha: v ? 1 : 0.5,
      },
      Content: {
        alpha: v ? 1 : 0.4,
      },
      TagLine: {
        alpha: v ? 1 : 0.7,
      },
    })
  }

  static override get tag() {
    return 'EndCardAlternate'
  }

  _doAnimate() {
    this._loader.patch({ alpha: 1 })
    this._TimerPause.setSmooth('alpha', 0)
    this._CountDown.setSmooth('alpha', 1)

    if (this._isAnimationsStarted) {
      this._loader.resume()
    } else {
      this._loader.start('animation')
    }
    this._isAnimationsStarted = true
  }

  _pauseAnimation() {
    this._loader.stop()
    this._CountDown.setSmooth('alpha', 0)
    this._TimerPause.setSmooth('alpha', 1)
  }

  _stopAnimation() {
    this._loader.stop()
    this._loader.setSmooth('alpha', 0)
  }

  _getForceAnnounce(): string[] | undefined {
    const title = `${this._item.analytics.league || this._item.analytics.sport}, ${
      this._item.analytics.title
    }`
    if (this._item.contentType === CONTENT_TYPE.SLE) {
      return ['Currently Streaming', title, `LIVE on ${this._item.analytics.brand.title}`]
    } else if (this._item.programmingType === PROGRAMMING_TYPES.FER) {
      return [title, `Aired on ${this._item.tertiaryTitle}`]
    }
  }

  static override _states() {
    return [
      class ItemSelected extends this {
        override $enter() {
          this._stopAnimation()
        }
        override _captureKey() {
          //Nothing happened
        }
      },
    ]
  }
}
