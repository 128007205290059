import FocusScaledButton from '../../../buttons/FocusScaledButton'
import { COLORS, FONT_FACE } from '../../../../constants'

const ViewAllCtaTag = 'viewAllCta'

type CreateViewAllCta = {
  ctaData: any
  cb: () => void
}

export function createViewAllCta({ ctaData, cb }: CreateViewAllCta) {
  const label = ctaData.title

  return {
    x: 450,
    unfocusHeight: 50,
    unfocusWidth: 257,
    focusHeight: 50,
    focusWidth: 257,
    type: FocusScaledButton,
    radius: 25,
    fontSize: 26,
    focusFontSize: 26,
    fontFace: FONT_FACE.regular,
    focusFontColor: COLORS.black,
    unfocusFontColor: COLORS.lightGray4,
    focusBackGroundColor: COLORS.white,
    unfocusBackgroundColor: COLORS.transparent,
    focusBorderColor: COLORS.transparent,
    unfocusBorderColor: COLORS.lightGray4,
    tag: ViewAllCtaTag,
    label: label,
    enterCallback: cb,
    isCta: true,
  }
}
