import { collectionComponents, createItems } from '../../components/componentMaps'
import { lowercaseFirst } from '../../helpers'
import Container from '../models/Container'
import Variables from '../../graphql/Variables'
import NestedLinksSelectableGroup from '../../components/NestedLinksSelectableGroup'
import { NestedLinksSelectableSpawner } from './index'
import { useRequest } from '../../lib/useRequest'
import {
  PaginatedComponentRequestConfig,
  PaginatedShelfRequestConfig,
  VideoSectionRequestConfig,
} from '../../components/LinksSelectableGroup/request'

export default class LinksSelectable {
  _linksSelectableGroup: any
  constructor(linksSelectableGroup: any) {
    this._linksSelectableGroup = linksSelectableGroup
  }

  createItemLabels(fontSize = null) {
    const items =
      this._linksSelectableGroup.data?.itemLabelsConfig ||
      this._linksSelectableGroup.data?.itemLabels
    const activeIndex = this.initiallySelected
    return items.map((item: any, index: any) => {
      return {
        label: item.itemLabel || item,
        active: index === activeIndex,
        fontSize,
      }
    })
  }

  async createData(stage: any, index: any) {
    let dataComponent = this._linksSelectableGroup.data.items[index].component
    let dataItem = this._linksSelectableGroup.data.items[index].data
    if (dataComponent === 'LazyShelf') {
      //Query data in case of Lazy Shelf component
      let queryData
      const variables = Variables.getMergedVariables(dataItem.queryVariables)
      switch (dataItem.entryField) {
        case 'paginatedShelf':
          queryData = await useRequest(PaginatedShelfRequestConfig(variables)).fetch()
          break
        default:
          queryData = await useRequest(VideoSectionRequestConfig(variables)).fetch()
      }

      if (queryData && queryData.data) {
        const containerData = new Container(queryData.data)
        this._linksSelectableGroup.data.items[index] = containerData
        dataComponent = containerData.component
        dataItem = containerData.data
      } else {
        return
      }
    }

    if (dataComponent === 'NestedLinksSelectableGroup') {
      const selectedItem = dataItem.items[dataItem.initiallySelected]
      const tag =
        selectedItem.component === 'Placeholder'
          ? selectedItem.component
          : selectedItem.data.items[0].component

      return stage.c({
        type: NestedLinksSelectableGroup,
        spawner: new NestedLinksSelectableSpawner(this._linksSelectableGroup.data.items[index]),
        initialFocusState: 'LabelsList',
        tag: `${lowercaseFirst(tag)}`,
        data: dataItem,
      })
    }

    if (dataItem && dataItem.items) {
      return stage.c({
        type: collectionComponents.get(dataComponent),
        tag: `${lowercaseFirst(dataItem.items[0].component)}`,
        items: createItems(dataItem.items),
        title: '', //Title isn't required for shelf component if it is child component of LinkSelectable Group
        moreItems: dataItem.moreItems,
      })
    }
  }

  async getMoreItems(queryVariables: any) {
    const moreItemsData = await useRequest(PaginatedComponentRequestConfig(queryVariables)).fetch()
    const { data = {} } = new Container(moreItemsData?.data)
    return {
      items: createItems(data?.items),
      moreItems: data?.moreItems,
    }
  }

  get initiallySelected() {
    return this._linksSelectableGroup.data.initiallySelected
  }

  get itemLabelsTitle() {
    return this._linksSelectableGroup.data.itemLabelsTitle
  }

  getLabelByIndex(index: any) {
    return this._linksSelectableGroup.obj.data.obj.itemLabels[index]
  }
}
